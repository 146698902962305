import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { God, gods } from "src/app/models/GodModel";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
import { HistoryPlayer } from "../home/home.component";
import { data, allData } from "src/app/models/data";
import { PasswordQuestion, passwordQuestions } from "src/app/models/Password";
import { Question, questions } from "src/app/models/SPL";
import { VGS, vgsLines } from "src/app/models/VGS";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
})
export class PasswordComponent implements OnInit, AfterViewInit {
  allQuestions: PasswordQuestion[] = JSON.parse(
    JSON.stringify(passwordQuestions)
  );
  splQuestions: Question[] = JSON.parse(JSON.stringify(questions));
  vgsLines: VGS[] = JSON.parse(JSON.stringify(vgsLines));

  validators: ValidatorFn[] = [];
  counter = -1;
  passwordCtrl = new FormControl("");
  passwordRulesDisplayed: PasswordQuestion[] = [
    // this.allQuestions[0],
    // this.allQuestions[1],
  ];

  // filteredGods: Observable<God[]>;

  Gods: God[] = JSON.parse(JSON.stringify(gods));

  displayedColumns: string[] = ["rule1"];

  tauntAudio = new Audio();
  NJAudio = new Audio();

  currentGod: God;
  currentTaunt: number = 0;

  dataSource = new MatTableDataSource<PasswordQuestion>(
    this.passwordRulesDisplayed
  );

  isCompleted: boolean = false;
  fullState: string = "uncompleted";

  cryptKey = environment.cryptKey;
  currentD = localStorage.getItem("currentD");
  getDataTries: number = 0;
  isError: boolean = false;

  isSoundPlaying: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  todayGodName;
  todayAbility;
  todayVGS;
  todaySPL;

  ngAfterViewInit() {
    this.paginator.pageSize = 5;
    this.dataSource.paginator = this.paginator;

    // let alreadyGuessedGodNames: string[] = [];

    // try {
    //   alreadyGuessedGodNames = JSON.parse(
    //     localStorage.getItem("guessQuoteNames")
    //   );
    // } catch (error) {
    //   localStorage.removeItem("guessQuoteNames");
    //   localStorage.removeItem("quoteState");
    // }

    // if (alreadyGuessedGodNames !== null && alreadyGuessedGodNames.length > 0) {
    //   alreadyGuessedGodNames.forEach((godName) => {
    //     let godData = this.Gods.find((god) => god.name === godName);
    //     if (godData !== undefined) {
    //       this.GodsDisplayed.push(godData);
    //       let index = this.Gods.indexOf(godData);
    //       this.Gods.splice(index, 1);
    //     }
    //   });
    //   this.dataSource.data = this.GodsDisplayed;
    //   this.dataSource.paginator.length = this.GodsDisplayed.length;
    //   this.oldDisplayed = JSON.parse(JSON.stringify(this.GodsDisplayed));
    //   this.cdRef.detectChanges();
    // }
    // if (document.getElementById("f6")) {
    //   document.getElementById("f6").addEventListener("click", () => {
    //     this.isCompleted = true;
    //     this.fullState = "surrender";
    //     localStorage.setItem(
    //       "quoteState",
    //       CryptoJS.AES.encrypt(
    //         JSON.stringify("surrender"),
    //         this.cryptKey
    //       ).toString()
    //     );
    //     this.setHistory();
    //     this.godCtrl.setValue("");
    //     document.getElementById("f6").remove();
    //   });
    //   if (this.isCompleted) {
    //     document.getElementById("f6").remove();
    //   }
    // }
    // this.NJAudio.src = this.currentGod.NJ_Voiceline!;
    // this.NJAudio.volume = 0.5;

    // this.NJAudio.load();
  }

  constructor(private router: Router) {
    // this.filteredGods = this.godCtrl.valueChanges.pipe(
    //   startWith(""),
    //   map((god) => (god ? this.filterGods(god) : []))
    // );
    // this.decryptTodayQuote();
    this.decrypt();
  }

  ngOnInit() {
    this.fullState = "uncompleted";
    let quoteState = localStorage.getItem("quoteState");
    if (quoteState) {
      const bytes = CryptoJS.AES.decrypt(quoteState, this.cryptKey);
      if (bytes.toString()) {
        let state = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (state === "completed" || state === "surrender") {
          this.isCompleted = true;
          this.fullState = state;
        } else {
          this.isCompleted = false;
        }
      } else {
        this.isCompleted = false;
      }
    } else {
      this.isCompleted = false;
    }
  }

  addValidator(validator: ValidatorFn) {
    this.validators.push(validator);
  }

  updateFieldValidation(control: AbstractControl) {
    control.setValidators(this.validators);
    control.updateValueAndValidity();
  }

  // hasNumber(myString) {
  //   return /\d/.test(myString);
  // }
  addDatasource() {
    this.passwordRulesDisplayed.unshift(this.allQuestions[this.counter]);
    this.dataSource.data = this.passwordRulesDisplayed;
    this.dataSource.paginator.length = this.passwordRulesDisplayed.length;
  }

  testCss(el: PasswordQuestion) {
    let value = this.passwordCtrl.value;
    switch (el.condition) {
      case 1:
        if (this.counter >= 0 && value.length >= 5) return true;
        else return false;
      case 2:
        if (this.counter >= 1 && this.testNumber(value)) return true;
        else return false;
      case 3:
        if (this.counter >= 2 && this.testUpperCase(value)) return true;
        else return false;
      case 4:
        if (this.counter >= 3 && this.testSpecialCharacter(value)) return true;
        else return false;
      case 5:
        if (this.counter >= 4 && this.test667(value)) return true;
        else return false;
      case 6:
        if (this.counter >= 5 && this.testTodayGod(value)) return true;
        else return false;
      case 7:
        if (this.counter >= 6 && this.testTodayAbility(value)) return true;
        else return false;
      case 8:
        if (this.counter >= 7 && this.testTodayVGS(value)) return true;
        else return false;
      case 9:
        if (this.counter >= 8 && this.testTodaySPL(value)) return true;
        else return false;
      default:
        return false;
    }
  }

  passwordChange(value) {
    console.log(value);
    switch (this.counter) {
      case -1:
        this.addValidator(Validators.minLength(5));
        this.updateFieldValidation(this.passwordCtrl);
        this.counter++;
        this.addDatasource();
        break;
      case 0:
        if (value.length >= 5 && !this.passwordCtrl.errors) {
          this.addValidator(this.includeNumber());
          this.updateFieldValidation(this.passwordCtrl);
          this.counter++;
          this.addDatasource();
          if (this.testNumber(value) && !this.passwordCtrl.errors) {
            this.passwordChange(value);
          }
        }
        break;
      case 1:
        if (this.testNumber(value) && !this.passwordCtrl.errors) {
          this.addValidator(this.includeUpperCase());
          this.updateFieldValidation(this.passwordCtrl);
          this.counter++;
          this.addDatasource();
          if (this.testUpperCase(value) && !this.passwordCtrl.errors) {
            this.passwordChange(value);
          }
        }
        break;
      case 2:
        if (this.testUpperCase(value) && !this.passwordCtrl.errors) {
          this.addValidator(this.includeSpecialCharacter());
          this.updateFieldValidation(this.passwordCtrl);
          this.counter++;
          this.addDatasource();
          if (this.testSpecialCharacter(value) && !this.passwordCtrl.errors) {
            this.passwordChange(value);
          }
        }
        break;
      case 3:
        if (this.testSpecialCharacter(value) && !this.passwordCtrl.errors) {
          this.addValidator(this.include667());
          this.updateFieldValidation(this.passwordCtrl);
          this.counter++;
          this.addDatasource();
          if (this.test667(value) && !this.passwordCtrl.errors)
            this.passwordChange(value);
        }
        break;
      case 4:
        if (this.test667(value) && !this.passwordCtrl.errors) {
          this.addValidator(this.includeTodayGod());
          this.updateFieldValidation(this.passwordCtrl);
          this.counter++;
          this.addDatasource();
          if (this.testTodayGod(value) && !this.passwordCtrl.errors)
            this.passwordChange(value);
        }
        break;

      case 5:
        if (this.testTodayGod(value) && !this.passwordCtrl.errors) {
          this.addValidator(this.includeTodayGod());
          this.updateFieldValidation(this.passwordCtrl);
          this.counter++;
          this.addDatasource();
          if (this.testTodayAbility(value) && !this.passwordCtrl.errors)
            this.passwordChange(value);
        }
        break;

      case 6:
        if (this.testTodayAbility(value) && !this.passwordCtrl.errors) {
          this.addValidator(this.includeTodayGod());
          this.updateFieldValidation(this.passwordCtrl);
          this.counter++;
          this.addDatasource();
          if (this.testTodayVGS(value) && !this.passwordCtrl.errors)
            this.passwordChange(value);
        }
        break;

      case 7:
        if (this.testTodayVGS(value) && !this.passwordCtrl.errors) {
          this.addValidator(this.includeTodayGod());
          this.updateFieldValidation(this.passwordCtrl);
          this.counter++;
          this.addDatasource();
          if (this.testTodaySPL(value) && !this.passwordCtrl.errors)
            this.passwordChange(value);
        }
        break;

      case 8:
        if (this.testTodaySPL(value) && !this.passwordCtrl.errors) {
          this.addValidator(this.includeTodayGod());
          this.updateFieldValidation(this.passwordCtrl);
          this.counter++;
          this.addDatasource();
        }
        break;

      default:
        break;
    }
    // console.log(this.passwordCtrl.validator);
    // // console.log(this.passwordCtrl.errors.hasSpecialCharacter);
    // console.log(this.passwordCtrl.errors.hasSpecialCharacter === undefined);
    if (this.passwordCtrl.errors) {
      // console.log(this.passwordCtrl.errors.minlength);
      // console.log(this.passwordCtrl.errors.hasNumber);
      // console.log(this.passwordCtrl.errors.hasUpperCase);
      // console.log(this.passwordCtrl.errors.hasSpecialCharacter);
    }
  }

  testNumber(value) {
    return /\d/.test(value);
  }

  testUpperCase(value) {
    return /[A-Z]+/.test(value);
  }

  testSpecialCharacter(value) {
    const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    return (
      nameRegexp.test(value) ||
      value.toUpperCase().includes("ARCHIE") ||
      value.toUpperCase().includes("KIT")
    );
  }

  test667(value) {
    const numbersArray = value.match(/\d+/g);

    if (numbersArray) return numbersArray.reduce((a, b) => a * b) === 667;
    return false;
  }

  testTodayGod(value) {
    return value
      .toUpperCase()
      .includes(this.todayGodName.toUpperCase().replace(/\s/g, ""));
  }

  testTodayAbility(value) {
    return value
      .toUpperCase()
      .includes(this.todayAbility.toUpperCase().replace(/\s/g, ""));
  }

  testTodayVGS(value) {
    return value
      .toUpperCase()
      .includes(this.todayVGS.toUpperCase().replace(/\s/g, ""));
  }

  testTodaySPL(value) {
    return value
      .toUpperCase()
      .includes(this.todaySPL.toUpperCase().replace(/\s/g, ""));
  }

  // Question 2
  includeNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (/\d/.test(value)) {
        return null;
      }
      return { hasNumber: false };
    };
  }

  // Question 3
  includeUpperCase(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (/[A-Z]+/.test(value)) {
        return null;
      }
      return { hasUpperCase: false };
    };
  }

  // Question 4
  includeSpecialCharacter(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (
        nameRegexp.test(value) ||
        value.toUpperCase().includes("ARCHIE") ||
        value.toUpperCase().includes("KIT")
      ) {
        return null;
      }

      return { hasSpecialCharacter: false };
    };
  }

  // Question 5
  include667(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      const numbersArray = value.match(/\d+/g);

      if (numbersArray)
        if (numbersArray.reduce((a, b) => a * b) === 667) return null;

      return { has667: false };
    };
  }

  // Question 6
  includeTodayGod(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (
        value
          .toUpperCase()
          .includes(this.todayGodName.toUpperCase().replace(/\s/g, ""))
      )
        return null;
      return { hasTodayGod: false };
    };
  }

  // Question 7
  includeTodayAbilitySound(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (
        value
          .toUpperCase()
          .includes(this.todayAbility.toUpperCase().replace(/\s/g, ""))
      )
        return null;
      return { hasTodayAbilitySound: false };
    };
  }

  // Question 8
  includeTodayVGS(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (
        value
          .toUpperCase()
          .includes(this.todayVGS.toUpperCase().replace(/\s/g, ""))
      )
        return null;
      return { hasTodayVGS: false };
    };
  }

  // Question 9
  includeTodaySPL(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (
        value
          .toUpperCase()
          .includes(this.todaySPL.toUpperCase().replace(/\s/g, ""))
      )
        return null;
      return { hasTodaySPL: false };
    };
  }

  decrypt() {
    let todayGod = localStorage.getItem("gods_today");
    let todayAbilitySounds = localStorage.getItem("abilitySounds_today");
    let todayVGS = localStorage.getItem("vgs_today");
    let todaySpl = localStorage.getItem("spl_today");

    if (todayGod) {
      const bytes = CryptoJS.AES.decrypt(todayGod, this.cryptKey);
      if (bytes.toString()) {
        this.todayGodName = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    }

    if (todayAbilitySounds) {
      const bytes = CryptoJS.AES.decrypt(todayAbilitySounds, this.cryptKey);
      if (bytes.toString()) {
        let todayGodAbilities = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.todayAbility = todayGodAbilities[1].split("_")[0];
      }
    }

    if (todayVGS) {
      const bytes = CryptoJS.AES.decrypt(todayVGS, this.cryptKey);
      if (bytes.toString()) {
        let todayVGS = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // this.todayVGS = todayVGS[2];

        let vgs = vgsLines.find((vgs) => vgs.command === todayVGS[2]);
        this.todayVGS = vgs.commandPS;
      }
    }

    if (todaySpl) {
      const bytes = CryptoJS.AES.decrypt(todaySpl, this.cryptKey);
      if (bytes.toString()) {
        let splIndexes = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let todaySPLData = this.splQuestions[splIndexes[3]];
        this.todaySPL = todaySPLData.answers[todaySPLData.correctAnswer - 1];
      }
    }

    console.log(this.todayGodName);
    console.log(this.todayAbility);
    console.log(this.todayVGS);
    console.log(this.todaySPL);
  }
  decryptTodayQuote() {
    let todayQuote = localStorage.getItem("quote_today");
    if (todayQuote) {
      const bytes = CryptoJS.AES.decrypt(todayQuote, this.cryptKey);
      if (bytes.toString()) {
        let todayQuoteData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        this.currentGod = this.Gods.find(
          (god) => god.name === todayQuoteData.god
        );
        if (this.currentGod === undefined) {
          this.getDataTries++;
        } else {
          let todayTaunt = parseInt(todayQuoteData.taunt);
          if (todayTaunt !== 0 && todayTaunt !== 1)
            this.currentTaunt = Math.floor(
              Math.random() * this.currentGod.taunts.length
            );
          else this.currentTaunt = parseInt(todayQuoteData.taunt);
          this.tauntAudio.src =
            this.currentGod.taunts[this.currentTaunt].voiceline;
          this.tauntAudio.volume = 0.5;
          this.tauntAudio.loop = true;
        }
      } else {
        this.getDataTries++;
      }
    } else {
      this.getDataTries++;
    }

    if (this.getDataTries > 0) {
      if (this.getDataTries >= 2) this.isError = true;
      else this.getTodayQuote();
    }
  }
  getTodayQuote() {
    // this.store
    //   .collection("gamemodes")
    //   .doc(this.currentD)
    //   .ref.get()
    //   .then((doc) => {
    //     let data = doc.data();
    //     localStorage.setItem("quote_today", data.quote);
    //     this.decryptTodayQuote();
    //   });

    let allDat: data[] = JSON.parse(JSON.stringify(allData));
    let todayData: data = allDat.filter((d) => {
      return d.day === this.currentD;
    })[0];

    localStorage.setItem("quote_today", todayData.quote);
    this.decryptTodayQuote();
  }

  oldIncludes(god: God) {
    // return this.oldDisplayed.map((oldGod) => oldGod.name).includes(god.name);
  }

  private filterGods(value: string): God[] {
    const filterValue = value.toLowerCase();

    return this.Gods.filter((god) =>
      god.name.toLowerCase().startsWith(filterValue)
    );
  }

  selectedGod(event) {
    // if (event.isUserInput) {
    //   let searchedGodName = event.source.value;
    //   let searchedGod: God = this.Gods.find(
    //     (god) => god.name === searchedGodName
    //   );
    //   this.GodsDisplayed.unshift(searchedGod);
    //   let index = this.Gods.indexOf(searchedGod);
    //   this.Gods.splice(index, 1);
    //   this.dataSource.data = this.GodsDisplayed;
    //   this.godCtrl.setValue("");
    //   this.dataSource.paginator.length = this.GodsDisplayed.length;
    //   let storageGodNames = this.GodsDisplayed.map((god) => god.name);
    //   localStorage.setItem("guessQuoteNames", JSON.stringify(storageGodNames));
    //   if (searchedGodName === this.currentGod.name) {
    //     this.fullState = "completed";
    //     localStorage.setItem(
    //       "quoteState",
    //       CryptoJS.AES.encrypt(
    //         JSON.stringify("completed"),
    //         this.cryptKey
    //       ).toString()
    //     );
    //     this.setHistory();
    //   }
    //   setTimeout(() => {
    //     if (searchedGodName === this.currentGod.name) {
    //       this.tauntAudio.pause();
    //       this.tauntAudio.currentTime = 0;
    //       this.isCompleted = true;
    //       this.fullState = "completed";
    //       this.NJAudio.play();
    //       if (document.getElementById("f6"))
    //         document.getElementById("f6").remove();
    //     }
    //   }, 1100);
    // }
  }

  paginationTest(event) {
    // this.oldDisplayed = JSON.parse(JSON.stringify(this.GodsDisplayed));
  }

  @HostListener("window:beforeunload")
  ngOnDestroy(): void {
    // let storageGodNames = this.GodsDisplayed.map((god) => god.name);
    // localStorage.setItem("guessQuoteNames", JSON.stringify(storageGodNames));
  }

  playSound() {
    if (this.isSoundPlaying) {
      this.isSoundPlaying = false;
      this.tauntAudio.pause();
    } else {
      this.tauntAudio.play();
      this.isSoundPlaying = true;
    }
  }

  navigate() {
    this.router.navigateByUrl("/ability-sounds");
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "F6" && !this.isCompleted) {
      this.isCompleted = true;
      this.fullState = "surrender";
      localStorage.setItem(
        "quoteState",
        CryptoJS.AES.encrypt(
          JSON.stringify("surrender"),
          this.cryptKey
        ).toString()
      );
      this.setHistory();
      // this.godCtrl.setValue("");
    }
  }

  setHistory() {
    let history: HistoryPlayer[] = [];
    let historyStorage;

    historyStorage = localStorage.getItem("history");

    if (historyStorage) {
      const bytes = CryptoJS.AES.decrypt(historyStorage, this.cryptKey);
      if (bytes.toString()) {
        history = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let currentDate = localStorage.getItem("currentD");
        let currentHistoryDate = history.find(
          (hist) => hist.date === currentDate
        );
        if (currentHistoryDate) {
          currentHistoryDate.quote = this.fullState === "completed" ? "W" : "L";
        } else {
          history.unshift({
            date: currentDate,
            gods: "-",
            quote: this.fullState === "completed" ? "W" : "L",
            abilitySounds: "-",
            abilityText: "-",
            vgs: "-",
            spl: "-",
            feud: "-",
          });
        }
        localStorage.setItem(
          "history",
          CryptoJS.AES.encrypt(
            JSON.stringify(history),
            this.cryptKey
          ).toString()
        );
      } else {
        localStorage.removeItem("history");
      }
    } else {
      let currentDate = localStorage.getItem("currentD");

      history.push({
        date: currentDate,
        gods: "-",
        quote: this.fullState === "completed" ? "W" : "L",
        abilitySounds: "-",
        abilityText: "-",
        vgs: "-",
        spl: "-",
        feud: "-",
      });
      localStorage.setItem(
        "history",
        CryptoJS.AES.encrypt(JSON.stringify(history), this.cryptKey).toString()
      );
    }
  }
}
