import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { God, gods } from "src/app/models/GodModel";
import { startWith, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";
import { AngularFirestore } from "@angular/fire/firestore";
import { HistoryPlayer } from "../home/home.component";
import { allData, data } from "src/app/models/data";

@Component({
  selector: "app-ability-text",
  templateUrl: "./ability-text.component.html",
  styleUrls: ["./ability-text.component.scss"],
})
export class AbilityTextComponent implements OnInit, AfterViewInit {
  godCtrl = new FormControl("");
  filteredGods: Observable<God[]>;
  Gods: God[] = JSON.parse(JSON.stringify(gods));
  abilitiesDisplayed: string[] = [];
  selectedAbilties: string[] = [];

  displayedColumns: string[] = [
    "Ability 1",
    "Ability 2",
    "Ability 3",
    "Ability 4",
  ];

  counter = 0;
  todayGodAbilities: string[] = [];
  fullResults: any[] = [];
  todayAbilities: any[] = [];

  completedGodAbilities: string[] = [];

  currentGodAbility: string;
  searchedGodName: string = "";
  selectedNumber = 0;

  isCompleted: boolean = false;

  fullState: string = "uncompleted";

  cryptKey = environment.cryptKey;
  currentD = localStorage.getItem("currentD");
  getDataTries: number = 0;
  isError: boolean = false;
  isSelecting: boolean = false;

  ngAfterViewInit() {
    if (document.getElementById("f6")) {
      document.getElementById("f6").addEventListener("click", () => {
        this.isCompleted = true;
        this.fullState = "surrender";
        localStorage.setItem(
          "abilityTextState",
          CryptoJS.AES.encrypt(
            JSON.stringify("surrender"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
        this.selectedAbilties = [];
        this.godCtrl.setValue("");
        this.selectedNumber = 0;
        this.abilitiesDisplayed = [];
        document.getElementById("f6").remove();
      });
      if (this.isCompleted) {
        document.getElementById("f6").remove();
      }
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private store: AngularFirestore
  ) {
    this.filteredGods = this.godCtrl.valueChanges.pipe(
      startWith(""),
      map((god) => (god ? this.filterGods(god) : []))
    );

    this.decryptTodayAbilityText();
    let alreadyCompleted: string[] = [];

    try {
      alreadyCompleted = JSON.parse(
        localStorage.getItem("completedGodAbilitiesText")
      );
    } catch (error) {
      localStorage.removeItem("completedGodAbilitiesText");
      localStorage.removeItem("abilityTextState");
    }

    this.todayGodAbilities.forEach((todayGodAbility, index) => {
      var fields = todayGodAbility.split("_");
      let godName = fields[0];
      let abilityNumber = fields[1];

      if (godName === "Chang'e") {
        godName = "Change";
        todayGodAbility = godName + "_" + abilityNumber;
        this.todayGodAbilities[index] = todayGodAbility;
      }
      let god = this.Gods.filter((god) => {
        let test = god.name.replace(/ +/g, "");
        return test.replace("'", "").toLowerCase() === godName.toLowerCase();
      })[0];
      this.fullResults.push({
        img: god.image,
        ability: "Ability " + abilityNumber,
        name: todayGodAbility,
        tries: 0,
      });
      this.todayAbilities.push({
        text: god.abilities[parseInt(abilityNumber) - 1].name,
        name: todayGodAbility,
      });
    });
    if (alreadyCompleted !== null && alreadyCompleted.length > 0) {
      this.completedGodAbilities = alreadyCompleted;
      if (
        this.todayGodAbilities.every((tga) =>
          this.completedGodAbilities.includes(tga)
        )
      ) {
        this.isCompleted = true;
        localStorage.setItem(
          "abilityTextState",
          CryptoJS.AES.encrypt(
            JSON.stringify("completed"),
            this.cryptKey
          ).toString()
        );
      } else {
        for (let i = 0; i < 5; i++) {
          if (!this.completedGodAbilities.includes(this.todayGodAbilities[i])) {
            this.counter = i;
            break;
          }
        }
      }
    }

    this.fullResults.forEach((result, index) => {
      let alreadySelectedAbilitiesCounter: string[] = [];
      let tries: number = 0;
      try {
        alreadySelectedAbilitiesCounter = JSON.parse(
          localStorage.getItem("selectedAbilitiesText_" + (index + 1))
        );
      } catch (error) {
        localStorage.removeItem("selectedAbilitiesText_" + (index + 1));
        localStorage.removeItem("abilityTextState");
        if (this.completedGodAbilities.includes(result.name)) {
          let index = this.completedGodAbilities.indexOf(result.name);
          this.completedGodAbilities.splice(index, 1);
          localStorage.setItem(
            "completedGodAbilitiesText",
            JSON.stringify(this.completedGodAbilities)
          );
        }
      }

      if (
        alreadySelectedAbilitiesCounter !== null &&
        alreadySelectedAbilitiesCounter.length > 0
      ) {
        tries = alreadySelectedAbilitiesCounter.length;
        result.tries = tries;
      }
    });

    let alreadySelectedAbilities: string[] = [];

    try {
      alreadySelectedAbilities = JSON.parse(
        localStorage.getItem("selectedAbilitiesText_" + (this.counter + 1))
      );
    } catch (error) {
      localStorage.removeItem("selectedAbilitiesText_" + (this.counter + 1));
      localStorage.removeItem("abilityTextState");
    }

    if (
      alreadySelectedAbilities !== null &&
      alreadySelectedAbilities.length > 0
    )
      this.selectedAbilties = alreadySelectedAbilities;

    this.currentGodAbility = this.todayGodAbilities[this.counter];
  }
  ngOnInit() {
    this.fullState = "uncompleted";
    let abilityTextState = localStorage.getItem("abilityTextState");
    if (abilityTextState) {
      const bytes = CryptoJS.AES.decrypt(abilityTextState, this.cryptKey);
      if (bytes.toString()) {
        let state = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (state === "completed" || state === "surrender") {
          this.isCompleted = true;
          this.fullState = state;
        } else {
          this.isCompleted = false;
        }
      } else {
        this.isCompleted = false;
      }
    } else {
      this.isCompleted = false;
    }
  }

  decryptTodayAbilityText() {
    let todayAbilityText = localStorage.getItem("abilityText_today");
    if (todayAbilityText) {
      const bytes = CryptoJS.AES.decrypt(todayAbilityText, this.cryptKey);
      if (bytes.toString()) {
        this.todayGodAbilities = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        while (true) {
          if (
            this.todayGodAbilities.length ===
              new Set(this.todayGodAbilities).size &&
            this.todayGodAbilities.length === 5
          )
            break;
          else {
            this.todayGodAbilities = Array.from(
              new Set(this.todayGodAbilities)
            );

            const random = Math.floor(Math.random() * this.Gods.length);
            let randomGodName = this.Gods[random].name;
            randomGodName = randomGodName.replace(/ +/g, "");

            let numbers = ["1", "2", "3", "4"];

            let randomNumber = Math.floor(Math.random() * numbers.length);

            this.todayGodAbilities.push(randomGodName + "_" + randomNumber);
          }
        }
      } else {
        this.getDataTries++;
      }
    } else {
      this.getDataTries++;
    }

    if (this.getDataTries > 0) {
      if (this.getDataTries >= 2) this.isError = true;
      else this.getTodayAbilityText();
    }
  }
  getTodayAbilityText() {
    // this.store
    //   .collection("gamemodes")
    //   .doc(this.currentD)
    //   .ref.get()
    //   .then((doc) => {
    //     let data = doc.data();
    //     localStorage.setItem("abilityText_today", data.abilityText);
    //     this.decryptTodayAbilityText();
    //   });

    let allDat: data[] = JSON.parse(JSON.stringify(allData));
    let todayData: data = allDat.filter((d) => {
      return d.day === this.currentD;
    })[0];

    localStorage.setItem("abilityText_today", todayData.abilityText);
    this.decryptTodayAbilityText();
  }

  oldIncludes(number) {
    return this.selectedAbilties.includes(this.searchedGodName + "_" + number);
  }

  correctGuess(number) {
    if (
      this.currentGodAbility ===
        this.searchedGodName + "_" + this.selectedNumber &&
      this.selectedNumber === number
    )
      return "correctCss";
    else if (
      this.selectedAbilties.includes(this.searchedGodName + "_" + number)
    )
      return "incorrectCss";
    return "normalCss";
  }

  private filterGods(value: string): God[] {
    const filterValue = value.toLowerCase();

    return this.Gods.filter((god) =>
      god.name.toLowerCase().startsWith(filterValue)
    );
  }

  selectedGod(event) {
    if (event.isUserInput) {
      let searchedGodName = event.source.value;

      this.selectedNumber = 0;
      searchedGodName = searchedGodName.replace(/ +/g, "");
      searchedGodName = searchedGodName.replace("'", "");
      this.searchedGodName = searchedGodName;
      this.abilitiesDisplayed = [
        "assets/ability_images/" + searchedGodName + "_1.png",
        "assets/ability_images/" + searchedGodName + "_2.png",
        "assets/ability_images/" + searchedGodName + "_3.png",
        "assets/ability_images/" + searchedGodName + "_4.png",
      ];

      this.godCtrl.setValue("");
    }
  }

  @HostListener("window:beforeunload")
  ngOnDestroy(): void {
    localStorage.setItem(
      "completedGodAbilitiesText",
      JSON.stringify(this.completedGodAbilities)
    );

    let selectedAbiltiesCounter = "selectedAbilitiesText_" + (this.counter + 1);
    localStorage.setItem(
      selectedAbiltiesCounter,
      JSON.stringify(this.selectedAbilties)
    );
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "F6" && !this.isCompleted) {
      this.isCompleted = true;
      this.fullState = "surrender";
      localStorage.setItem(
        "abilityTextState",
        CryptoJS.AES.encrypt(
          JSON.stringify("surrender"),
          this.cryptKey
        ).toString()
      );
      this.setHistory();
      this.selectedAbilties = [];
      this.godCtrl.setValue("");
      this.selectedNumber = 0;
      this.abilitiesDisplayed = [];
    }
  }

  selectedAbility(number) {
    if (!this.completedGodAbilities.includes(this.currentGodAbility)) {
      this.isSelecting = true;
      let selectedAbi = this.searchedGodName + "_" + number;

      this.selectedNumber = number;
      if (!this.selectedAbilties.includes(selectedAbi))
        this.selectedAbilties.unshift(selectedAbi);
      this.cdRef.detectChanges();

      let selectedAbiltiesCounter =
        "selectedAbilitiesText_" + (this.counter + 1);
      localStorage.setItem(
        selectedAbiltiesCounter,
        JSON.stringify(this.selectedAbilties)
      );

      this.fullResults.find(
        (result) => result.name === this.currentGodAbility
      ).tries = this.selectedAbilties.length;

      if (this.currentGodAbility === selectedAbi) {
        this.completedGodAbilities.unshift(this.currentGodAbility);

        if (
          this.todayGodAbilities.every((tga) =>
            this.completedGodAbilities.includes(tga)
          )
        ) {
          this.fullState = "completed";
          localStorage.setItem(
            "abilityTextState",
            CryptoJS.AES.encrypt(
              JSON.stringify("completed"),
              this.cryptKey
            ).toString()
          );
          this.setHistory();
        }

        setTimeout(() => {
          if (
            this.todayGodAbilities.every((tga) =>
              this.completedGodAbilities.includes(tga)
            )
          ) {
            this.isCompleted = true;
            if (document.getElementById("f6"))
              document.getElementById("f6").remove();
          } else {
            while (true) {
              this.counter++;
              if (this.counter > 4) this.counter = 0;
              if (
                !this.completedGodAbilities.includes(
                  this.todayGodAbilities[this.counter]
                )
              )
                break;
            }

            this.currentGodAbility = this.todayGodAbilities[this.counter];
            this.selectedAbilties = [];
            this.godCtrl.setValue("");
            this.selectedNumber = 0;
            this.abilitiesDisplayed = [];

            let alreadySelectedAbilities: string[] = [];

            try {
              alreadySelectedAbilities = JSON.parse(
                localStorage.getItem(
                  "selectedAbilitiesText_" + (this.counter + 1)
                )
              );
            } catch (error) {
              localStorage.removeItem(
                "selectedAbilitiesText_" + (this.counter + 1)
              );
              localStorage.removeItem("abilityTextState");
            }
            if (
              alreadySelectedAbilities !== null &&
              alreadySelectedAbilities.length > 0
            )
              this.selectedAbilties = alreadySelectedAbilities;
          }
          this.isSelecting = false;
        }, 1500);
      } else {
        this.isSelecting = false;
      }
    }
  }

  changeText(event) {
    let selectedAbiltiesCounter = "selectedAbilitiesText_" + (this.counter + 1);
    localStorage.setItem(
      selectedAbiltiesCounter,
      JSON.stringify(this.selectedAbilties)
    );
    if (event === "next") {
      this.counter++;
      if (this.counter > 4) this.counter = 0;
    } else if (event === "previous") {
      this.counter--;
      if (this.counter < 0) this.counter = 4;
    }

    this.currentGodAbility = this.todayGodAbilities[this.counter];
    this.selectedAbilties = [];
    let alreadySelectedAbilities: string[] = [];

    try {
      alreadySelectedAbilities = JSON.parse(
        localStorage.getItem("selectedAbilitiesText_" + (this.counter + 1))
      );
    } catch (error) {
      localStorage.removeItem("selectedAbilitiesText_" + (this.counter + 1));
      localStorage.removeItem("abilityTextState");
      if (this.completedGodAbilities.includes(this.currentGodAbility)) {
        let index = this.completedGodAbilities.indexOf(this.currentGodAbility);
        this.completedGodAbilities.splice(index, 1);
        localStorage.setItem(
          "completedGodAbilitiesText",
          JSON.stringify(this.completedGodAbilities)
        );
      }
    }

    if (
      alreadySelectedAbilities !== null &&
      alreadySelectedAbilities.length > 0
    )
      this.selectedAbilties = alreadySelectedAbilities;

    this.godCtrl.setValue("");
    this.selectedNumber = 0;
    this.abilitiesDisplayed = [];
    // this.isSoundPlaying = false;
    if (this.completedGodAbilities.includes(this.currentGodAbility)) {
      this.godCtrl.disable();
      var fields = this.currentGodAbility.split("_");
      let godName = fields[0];
      let number = fields[1];
      this.searchedGodName = godName;
      this.selectedNumber = parseInt(number);
      this.abilitiesDisplayed = [
        "assets/ability_images/" + godName + "_1.png",
        "assets/ability_images/" + godName + "_2.png",
        "assets/ability_images/" + godName + "_3.png",
        "assets/ability_images/" + godName + "_4.png",
      ];
    } else this.godCtrl.enable();
  }

  navigate() {
    this.router.navigateByUrl("/vgs");
  }

  setHistory() {
    let history: HistoryPlayer[] = [];
    let historyStorage;

    historyStorage = localStorage.getItem("history");

    if (historyStorage) {
      const bytes = CryptoJS.AES.decrypt(historyStorage, this.cryptKey);
      if (bytes.toString()) {
        history = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let currentDate = localStorage.getItem("currentD");
        let currentHistoryDate = history.find(
          (hist) => hist.date === currentDate
        );
        if (currentHistoryDate) {
          currentHistoryDate.abilityText =
            this.fullState === "completed" ? "W" : "L";
        } else {
          history.unshift({
            date: currentDate,
            gods: "-",
            quote: "-",
            abilitySounds: "-",
            abilityText: this.fullState === "completed" ? "W" : "L",
            vgs: "-",
            spl: "-",
            feud: "-",
          });
        }
        localStorage.setItem(
          "history",
          CryptoJS.AES.encrypt(
            JSON.stringify(history),
            this.cryptKey
          ).toString()
        );
      } else {
        localStorage.removeItem("history");
      }
    } else {
      let currentDate = localStorage.getItem("currentD");

      history.push({
        date: currentDate,
        gods: "-",
        quote: "-",
        abilitySounds: "-",
        abilityText: this.fullState === "completed" ? "W" : "L",
        vgs: "-",
        spl: "-",
        feud: "-",
      });
      localStorage.setItem(
        "history",
        CryptoJS.AES.encrypt(JSON.stringify(history), this.cryptKey).toString()
      );
    }
  }
}
