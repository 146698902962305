import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
// import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from "express";
import { DeviceDetectorService } from "ngx-device-detector";
import { isPlatformServer } from "@angular/common";

@Injectable()
export class UniversalDeviceDetectorService extends DeviceDetectorService {
  constructor(@Inject(PLATFORM_ID) platformId: any) {
    super(platformId);
    // if (isPlatformServer(platformId)) {
    //   super.setDeviceInfo((request.headers['user-agent'] as string) || '');
    // }
  }
}
