export interface Question {
  question: string;
  answers: string[];
  correctAnswer: number;
}
const questions: Question[] = [
  {
    question:
      "During the World Championship 2015 (Season 1), who was the jungler for the team Titan ?",
    answers: ["Cherryo", "CaptainTwig", "Repikas", "Xaliea"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2015 (Season 1), who was the ADC player for the team Titan ?",
    answers: ["Realzx", "Ataraxia", "emilitoo", "Snoopy"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2015 (Season 1), for which team did the player Maniakk play for ?",
    answers: ["SK Gaming", "Paradigm", "Cloud9", "Titan"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2015 (Season 1), which role did Badgah play for his team SK Gaming?",
    answers: ["Support", "Mid", "ADC", "Jungle"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2015 (Season 1), which role did Confrey play for his team Titan?",
    answers: ["Jungle", "Solo", "ADC", "Support"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2015 (Season 1), which role did Eonic play for his team COG Red?",
    answers: ["Mid", "Solo", "Support", "Jungle"],
    correctAnswer: 3,
  },
  {
    question:
      "Which of these teams has not participated in the World Championship 2015 (Season 1) ?",
    answers: ["Titan", "Oh My God", "SK Gaming", "Fnatic"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2015 (Season 1), which player was the MVP of the tournament?",
    answers: ["Adapting", "Divios", "MLCSt3alth", "DaGarz"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), which player was the MVP of the tournament?",
    answers: ["Adapting", "Yammyn", "BaRRaCCuDDa", "Andinster"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), who was the Mid laner for team Cloud9 ?",
    answers: ["MLCSt3alth", "Xenotronics", "Khaos", "Snoopy"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), which role did Xaliea play for his team Paradigm ?",
    answers: ["Jungle", "Solo", "ADC", "Support"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), which role did Realzx play for his team Fnatic?",
    answers: ["ADC", "Mid", "Solo", "Support"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), who was the ADC for the team Enemy?",
    answers: ["Vetium", "Realzx", "Zapman", "PandaCat"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), who was the Jungler for the team Enemy?",
    answers: ["Masked", "Adjust", "Incon", "Weak3n"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), the team Paradigm placed : ",
    answers: ["1st", "2nd", "3-4 (Semifinalist)", "5-8 (QuarterFinalist)"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), the team Enemy placed : ",
    answers: ["1st", "2nd", "3-4 (Semifinalist)", "5-8 (QuarterFinalist)"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), Which team was the Oceania representative?",
    answers: ["Isurus Gaming", "Pain Gaming", "Avant Garde", "Fnatic"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2017 (Season 3), who was the ADC for Team Eager?",
    answers: ["Zapman", "PandaCat", "MetYankey", "Snoopy"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2017 (Season 3), which role did Oceans play for his team Team Allegiance?",
    answers: ["Solo", "ADC", "Mid", "Jungle"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2017 (Season 3), for which team did the player PolarBearMike play for?",
    answers: ["Team Eager", "Enemy", "eUnited", "Team Allegiance"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2017 (Season 3), the Team Eager placed : ",
    answers: ["1st", "2nd", "3-4 (Semifinalist)", "5-8 (QuarterFinalist)"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2017 (Season 3), which player was the MVP of the tournament?",
    answers: ["Adapting", "Yammyn", "Variety", "emilitoo"],
    correctAnswer: 4,
  },
  {
    question: "Who won the World Championship in 2017 (Season 3)?",
    answers: ["NRG Esports", "Obey Alliance", "Luminosity", "eUnited"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2017 (Season 3), who was the Solo laner for the team Luminosity?",
    answers: ["Aquarius", "Baskin", "ScaryD", "Omega"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2017 (Season 3), who was the Mid laner for the team Soar Gaming?",
    answers: ["Andinster", "TheBest", "Baskin", "Snoopy"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2018 (Season 4), who was the Support for the team Obey Alliance?",
    answers: ["EmilZy", "iRaffer", "PANDALIKE", "KaLas"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2018 (Season 4), which role did Dardez play for his Team Elevate?",
    answers: ["ADC", "Support", "Mid", "Solo"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2018 (Season 4), the team Obey Alliance placed : ",
    answers: ["1st", "2nd", "3-4 (Semifinalist)", "5-8 (QuarterFinalist)"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2018 (Season 4), who was the Solo Laner for the team Luminosity?",
    answers: ["Baskin", "Benji", "ScaryD", "Aquarius"],
    correctAnswer: 4,
  },
  {
    question: "Who won the World Championship in 2018 (Season 4)?",
    answers: ["NRG Esports", "Team Rival", "Obey Alliance", "eUnited"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2018 (Season 4), who was the Mid laner for Team Rival?",
    answers: ["Wlfy", "PrettyPrime", "Yammyn", "Vote"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2018 (Season 4), which player was the MVP of the tournament?",
    answers: ["Deathwalker", "Venenu", "Benji", "PandaCat"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2019 (Season 5), who was the Mid laner for the team eUnited ?",
    answers: ["Venenu", "Andinster", "Baskin", "PandaCat"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2019 (Season 5), who was the Solo laner for the team Dignitas?",
    answers: ["Variety", "Xaliea", "Maniakk", "Cyclonespin"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2019 (Season 5), which player was the MVP of the tournament?",
    answers: ["Divios", "Cyno", "CycloneSpin", "Moswal"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2019 (Season 5), for which team did the player TrixTank play for ?",
    answers: ["Team Rival", "Trifecta", "Obey Alliance", "Dignitas"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2019 (Season 5), the team NRG Esports placed : ",
    answers: ["1st", "2nd", "3-4 (Semifinalist)", "5-8 (QuarterFinalist)"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2019 (Season 5), which role did CycloneSpin play for his Team Splyce?",
    answers: ["ADC", "Solo", "Jungle", "Mid"],
    correctAnswer: 1,
  },
  {
    question: "Who won the World Championship in 2019 (Season 5)?",
    answers: ["Dignitas", "NRG Esports", "Splyce", "Team Rival"],
    correctAnswer: 3,
  },
  {
    question: "Who won the World Championship in 2020 (Season 6)?",
    answers: ["SK Gaming", "Renegades", "Team Rival", "Splyce"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2020 (Season 6), which player was the MVP of the tournament?",
    answers: ["Zapman", "sam4soccer2", "Paul", "NeilMah"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2020 (Season 6), which role did PandaCat play for Team Rival?",
    answers: ["ADC", "Mid", "Solo", "Jungle"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2020 (Season 6), for which team did the player Arkkyl play for ?",
    answers: ["Dignitas", "SK Gaming", "Team Rival", "Pittsburgh Knights"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2020 (Season 6), which role did Deathwalker play for his team Pittsburgh Knights?",
    answers: ["Mid", "Solo", "Support", "Jungle"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2020 (Season 6), for which team did the player Vote play for ?",
    answers: ["Pittsburgh Knights", "Team Rival", "Dignitas", "Renegades"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2020 (Season 6), who was the ADC for the team eUnited?",
    answers: ["PandaCat", "Arkkyl", "emilitoo", "Ataraxia"],
    correctAnswer: 3,
  },
  {
    question: "Who won the World Championship in 2021 (Season 7)?",
    answers: ["SK Gaming", "Pittsburgh Knights", "Splyce", "Renegades"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2021 (Season 7), which player was the MVP of the tournament?",
    answers: ["Paul", "sam4soccer2", "NeilMah", "Zapman"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2021 (Season 7), which role did CaptainTwig play for Ghost Gaming?",
    answers: ["Mid", "Jungle", "Solo", "Support"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2021 (Season 7), for which team did the player BigManTingz play for ?",
    answers: ["Pittsburgh Knights", "Renegades", "Ghost Gaming", "Radiance"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2021 (Season 7), who was the Jungler for the team Pittsburgh Knights?",
    answers: ["Adapting", "sam4soccer2", "CaptainTwig", "QvoFred"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2021 (Season 7), who was the ADC for the team Renegades?",
    answers: ["CycloneSpin", "BaRRaCCuDDa", "Netrioid", "Zapman"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2021 (Season 7), for which team did the player Sheento play for ?",
    answers: ["Renegades", "Ghost Gaming", "Radiance", "Sanguine"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2022 (Season 8), the team Leviathans placed : ",
    answers: ["1st", "2nd", "3-4 (Semifinalist)", "5-8 (QuarterFinalist)"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2022 (Season 8), which player was the MVP of the tournament?",
    answers: ["Paul", "Sheento", "Ronngyu", "Jarcorrr"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2022 (Season 8), who was the ADC for the team Leviathans?",
    answers: ["Netrioid", "Jarcorrr", "Zapman", "PandaCat"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2022 (Season 8), the team Tartarus Titans placed : ",
    answers: ["1st", "2nd", "3-4 (Semifinalist)", "5-8 (QuarterFinalist)"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2022 (Season 8), for which team did the player fineOkay play for ?",
    answers: ["Leviathans", "Solar Scarabs", "Team Rival", "Jade Dragons"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2022 (Season 8), for which team did the player SoloOrTroll play for ?",
    answers: ["Oni Warriors", "Valkyries", "Solar Scarabs", "Winner Dogs"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2023 (Season 9), the team Camelot Kings placed : ",
    answers: ["1st", "2nd", "3-4 (Semifinalist)", "5-8 (QuarterFinalist)"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2023 (Season 9), which player was the MVP of the tournament?",
    answers: ["CaptainTwig", "Variety", "BigManTingz", "Genetics"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2023 (Season 9), who was the ADC for the team Camelot Kings?",
    answers: ["BaRRaCCuDDa", "Jarcorr", "PandaCat", "Netrioid"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2023 (Season 9), which role did Angry play for Highland Ravens?",
    answers: ["Mid", "Solo", "ADC", "Support"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2023 (Season 9), for which team did the player Dzoni play for ?",
    answers: [
      "Highland Ravens",
      "Solar Scarabs",
      "Eldritch Hounds",
      "Hex Mambo",
    ],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2023 (Season 9), for which team did the player Screammmmm play for ?",
    answers: ["Oni Warriors", "Leviathans", "Olympus Bolts", "Jade Dragons"],
    correctAnswer: 4,
  },
  {
    question:
      "Which player has been the first to win the Smite World Championship in two different roles?",
    answers: ["Zapman", "Adapting", "Jarcorr", "CaptainTwig"],
    correctAnswer: 3,
  },
  {
    question:
      "Which player has been the first to win the Smite World Championship three times?",
    answers: ["Zapman", "Adapting", "emilitoo", "sam4soccer2"],
    correctAnswer: 1,
  },
  {
    question:
      "Which role has the most MVP's in the Smite World Championship history (2015-2023) ?",
    answers: ["ADC", "Support", "Mid", "Solo"],
    correctAnswer: 3,
  },
  {
    question:
      "From which region is the player who has gotten the first ever first blood in the Smite World Championship ?",
    answers: ["NA", "China", "NA", "Brazil"],
    correctAnswer: 2,
  },
  {
    question:
      "Which player has played in every Smite World Championship (2015-2023) ?",
    answers: ["BaRRaCCuDDa", "Adapting", "Aror", "CaptainTwig"],
    correctAnswer: 4,
  },
  {
    question:
      "Which of these players has failed to win a Smite World Championship?",
    answers: ["fineOkay", "ScaryD", "BigManTingz", "Aror"],
    correctAnswer: 1,
  },
  {
    question:
      "Which of these teams has failed to win a Smite World Championship?",
    answers: ["SK Gaming", "Tartarus Titans", "eUnited", "Pittsburgh Knights"],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), the NA region has been represented by two teams which are Cloud9 and : ",
    answers: ["Team Eager", "Team Allegiance", "EnvyUs", "Enemy"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2017 (Season 3), the EU region has been represented by two teams which are NRG Esports and : ",
    answers: ["Obey Alliance", "Dignitas", "Fnatic", "Orbit eSport"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2018 (Season 4), the NA region has been represented by two teams which are eUnited and :",
    answers: [
      "Spacestation Gaming",
      "Luminosity",
      "Radiance",
      "Pittsburgh Knights",
    ],
    correctAnswer: 2,
  },
  {
    question:
      "During the World Championship 2015 (Season 1), COG Prime has faced which team in the finals ?",
    answers: ["COG Red", "SK Gaming", "Titan", "Epsilon Esports"],
    correctAnswer: 3,
  },
  {
    question:
      "During the World Championship 2016 (Season 2), Epsilon Esports has faced which team in the finals ?",
    answers: ["Cloud9", "Obey Alliance", "Team Rival", "Enemy"],
    correctAnswer: 4,
  },
  {
    question:
      "During the World Championship 2017 (Season 3), NRG Esports has faced which team in the finals ?",
    answers: ["Obey Alliance", "Luminosity", "Team Rival", "Team Eager"],
    correctAnswer: 1,
  },
  {
    question:
      "During the World Championship 2018 (Season 4), eUnited has faced which team in the finals ?",
    answers: ["Obey Alliance", "Team Rival", "NRG Esports", "Splyce"],
    correctAnswer: 2,
  },
  {
    question:
      "Which of these players has NOT won the MVP award at a Smite World Championship (2015-2023)?",
    answers: ["Adapting", "Cyno", "Yammyn", "Venenu"],
    correctAnswer: 1,
  },
  {
    question: "Who won the Smite Launch Tournament in 2014 ?",
    answers: ["COG Red", "COG Prime", "Team SoloMid", "SK Gaming"],
    correctAnswer: 3,
  },
  {
    question:
      "Which player got a penta kill on Vamana in the Smite Launch Tournament in 2014 ?",
    answers: ["Anatoliy", "Omega", "Wolfy2032", "Gamehunter"],
    correctAnswer: 4,
  },
  {
    question: "Which of these teams has the player Zapman NOT played for ?",
    answers: ["SK Gaming", "Luminosity", "Spacestation Gaming", "Trifecta"],
    correctAnswer: 2,
  },
  {
    question:
      "Which of these teams has the player BaRRaCCuDDa NOT played for ?",
    answers: ["Renegades", "Spacestation Gaming", "Trifecta", "Luminosity"],
    correctAnswer: 3,
  },
  {
    question: "Which of these teams has the player Adapting NOT played for ?",
    answers: ["Radiance", "Pittsburgh Knights", "Panthera", "Ghost Gaming"],
    correctAnswer: 4,
  },
  {
    question:
      "Which of these teams has the player PolarBearMike NOT played for ?",
    answers: ["Splyce", "eUnited", "Team Eager", "Team Allegiance"],
    correctAnswer: 1,
  },
  {
    question:
      "Which of these teams has the player CaptainTwig NOT played for ?",
    answers: ["Paradigm", "Fnatic", "Team Rival", "Obey Alliance"],
    correctAnswer: 1,
  },
  {
    question: "Which of these teams has the player JeffHindla NOT played for ?",
    answers: ["Cloud9", "Luminosity", "Renegades", "Spacestation Gaming"],
    correctAnswer: 3,
  },
  {
    question: "Which of these teams has the player Baskin NOT played for ?",
    answers: ["eUnited", "Cloud9", "Spacestation Gaming", "Trifecta"],
    correctAnswer: 4,
  },
  {
    question: "Which of these teams has the player iRaffer NOT played for ?",
    answers: ["Obey Alliance", "Renegades", "Spacestation Gaming", "Trifecta"],
    correctAnswer: 4,
  },
  {
    question: "Which of these teams has the player EmilZy NOT played for ?",
    answers: [
      "Team Rival",
      "Pittsburgh Knights",
      "NRG Esports",
      "Obey Alliance",
    ],
    correctAnswer: 1,
  },
  {
    question: "Which of these teams has the player Cherryo NOT played for ?",
    answers: ["Sanguine", "Spacestation Gaming", "Elevate", "Obey Alliance"],
    correctAnswer: 4,
  },
  {
    question:
      "Which of these teams has the player CycloneSpin NOT played for ?",
    answers: ["Team Allegiance", "Radiance", "Splyce", "Renegades"],
    correctAnswer: 4,
  },
  {
    question: "Which of these teams has the player Aror NOT played for ?",
    answers: ["Team Allegiance", "Team Eager", "Ghost Gaming", "Splyce"],
    correctAnswer: 1,
  },
  {
    question: "Which of these teams has the player Paul NOT played for ?",
    answers: [
      "Trifecta",
      "Ghost Gaming",
      "Tartarus Titans",
      "Pittsburgh Knights",
    ],
    correctAnswer: 2,
  },
  {
    question: "Which of these teams has the player Vote NOT played for ?",
    answers: ["Team Rival", "Spacestation Gaming", "Elevate", "Oni Warriors"],
    correctAnswer: 3,
  },
  {
    question: "Which of these teams has the player QvoFred NOT played for ?",
    answers: ["Pittsburgh Knights", "Dignitas", "Paradigm", "Fnatic"],
    correctAnswer: 4,
  },
  {
    question: "Which of these teams has the player Dardez NOT played for ?",
    answers: ["Fnatic", "Renegades", "Elevate", "Oni Warriors"],
    correctAnswer: 1,
  },
  {
    question: "Which of these teams has the player PandaCat NOT played for ?",
    answers: [
      "Ghost Gaming",
      "Spacestation Gaming",
      "Team Rival",
      "Jade Dragons",
    ],
    correctAnswer: 2,
  },
  {
    question: "Which of these teams has the player Cyno NOT played for ?",
    answers: ["Obey Alliance", "Splyce", "SK Gaming", "Trifecta"],
    correctAnswer: 3,
  },
  {
    question: "Which of these teams has the player Zyrhoes NOT played for ?",
    answers: ["Paradigm", "Fnatic", "Pittsburgh Knights", "Dignitas"],
    correctAnswer: 1,
  },
  {
    question: "Which of these teams has the player Variety NOT played for ?",
    answers: ["Team Rival", "eUnited", "Renegades", "Obey Alliance"],
    correctAnswer: 1,
  },
  {
    question: "Which of these teams has the player Weak3n NOT played for ?",
    answers: [
      "Trifecta",
      "Spacestation Gaming",
      "Luminosity",
      "Team Allegiance",
    ],
    correctAnswer: 2,
  },
  {
    question: "Which of these teams has the player ScaryD NOT played for ?",
    answers: ["Luminosity", "SK Gaming", "Cloud9", "Trifecta"],
    correctAnswer: 3,
  },
  {
    question: "Which of these teams has the player maniaKK NOT played for ?",
    answers: ["Fnatic", "NRG Esports", "Obey Alliance", "Paradigm"],
    correctAnswer: 4,
  },
  {
    question: "Which of these teams has the player emilitoo NOT played for ?",
    answers: ["Obey Alliance", "Valkyries", "eUnited", "NRG Esports"],
    correctAnswer: 1,
  },
  {
    question: "Which of these teams has the player NeilMah NOT played for ?",
    answers: [
      "SK Gaming",
      "Spacestation Gaming",
      "Team Allegiance",
      "Trifecta",
    ],
    correctAnswer: 2,
  },
  {
    question: "Which of these teams did Hayzer NOT coach ?",
    answers: ["NRG Esports", "Cyclone", "Obey Alliance", "Renegades"],
    correctAnswer: 1,
  },
  {
    question:
      "Which of these coaches has NOT participated in a Smite World Championship as a player ?",
    answers: ["Hayzer", "Biggy", "Cherryo", "Oxiledeon"],
    correctAnswer: 1,
  },
  {
    question: "Which of these teams has the player fineOkay NOT played for ?",
    answers: ["Renegades", "Team Rival", "Ghost Gaming", "Jade Dragons"],
    correctAnswer: 1,
  },
  {
    question: "Which of these teams has the player Netrioid NOT played for ?",
    answers: ["Camelot Kings", "Jade Dragons", "Sanguine", "Oni Warriors"],
    correctAnswer: 2,
  },
  {
    question:
      "Which of these teams has the player Screammmmmm NOT played for ?",
    answers: ["eUnited", "Splyce", "Team Rival", "Solar Scarabs"],
    correctAnswer: 3,
  },
  {
    question: "Which of these teams has the player Allied NOT played for ?",
    answers: ["EnVyUs", "AFK Gaming", "COGnitive Gaming", "Denial eSports"],
    correctAnswer: 4,
  },
  {
    question:
      "Which of these players has been suspended from playing in the league before ?",
    answers: ["Masked", "Weak3n", "Incon", "Zapman"],
    correctAnswer: 1,
  },
  {
    question:
      "Which of these players has NOT played for the team Sanguine eSports",
    answers: ["Nika", "Zapman", "Panitom", "Arkkyl"],
    correctAnswer: 2,
  },
  /* here */
  {
    question:
      "Which of these players has NOT played for the team Atlantis Leviathans",
    answers: ["Haddix", "Panitom", "Inbowned", "Jarcorr"],
    correctAnswer: 3,
  },
  {
    question: "Which of these players has NOT played for the team Jade Dragons",
    answers: ["Screammmmmm", "Hurriwind", "fineOkay", "CaptainTwig"],
    correctAnswer: 4,
  },
  {
    question: "Which of these players has NOT played for the team Fnatic",
    answers: ["Zyrhoes", "maniaKK", "Badgah", "PrettyPrime"],
    correctAnswer: 4,
  },
  {
    question: "Which of these teams has the player Xaliea NOT played for ?",
    answers: ["Obey Alliance", "Paradigm", "Elevate", "Trig eSports"],
    correctAnswer: 3,
  },
  {
    question: "Which of these teams has the player Duck3y NOT played for ?",
    answers: ["Valkyries", "Paradigm", "Obey Alliance", "Trifecta"],
    correctAnswer: 2,
  },
  /* here */
  {
    question: "Which of these teams has the player Nika NOT played for ?",
    answers: ["Oni Warriors", "eUnited", "Sanguine", "Spacestation Gaming"],
    correctAnswer: 2,
  },
  {
    question: "Which of these teams has the player Snoopy NOT played for ?",
    answers: ["Team Eager", "eUnited", "Luminosity", "Soar Gaming"],
    correctAnswer: 1,
  },
  {
    question: "Which of these players has NOT played for the team Luminosity",
    answers: ["MLCSt3alth", "Aquarius", "Andinster", "Baskin"],
    correctAnswer: 1,
  },
  {
    question: "Which of these players has NOT played for the team Allegiance",
    answers: ["MLCSt3alth", "Zapman", "PolarBearMike", "CycloneSpin"],
    correctAnswer: 2,
  },
  {
    question: "Which of these players has NOT played for the team Splyce",
    answers: ["Venenu", "Aror", "SoloOrTroll", "PolarBearMike"],
    correctAnswer: 4,
  },

  {
    question: "Which of these teams has the player Incon NOT played for ?",
    answers: [
      "Team Allegiance",
      "Team Eager",
      "FlashPoint eSports",
      "AFK Gaming",
    ],
    correctAnswer: 2,
  },
  {
    question: "Which of these teams has the player Masked NOT played for ?",
    answers: ["Trifecta", "Dignitas", "Luminosity", "Armada eSports"],
    correctAnswer: 2,
  },
  {
    question: "Which of these players has NOT played for the team Renegades",
    answers: ["Barraccudda", "Venenu", "iRaffer", "Andinster"],
    correctAnswer: 4,
  },
  {
    question:
      "Which of these players has NOT played for the team Obey Alliance",
    answers: ["Inbowned", "CaptainTwig", "Zyrhoes", "EmilZy"],
    correctAnswer: 3,
  },
  {
    question: "Which of these players has NOT played for the team Oni Warriors",
    answers: ["Pegon", "QvoFred", "Duck3y", "NeilMah"],
    correctAnswer: 3,
  },

  {
    question: "Which of these teams has the player Venenu NOT played for ?",
    answers: ["Renegades", "Splyce", "Spacestation Gaming", "Enemy"],
    correctAnswer: 4,
  },
  {
    question: "Which of these teams has the player Hurriwind NOT played for ?",
    answers: ["Soar Gaming", "Luminosity", "eUnited", "Trifecta"],
    correctAnswer: 2,
  },
  {
    question: "Which of these players has NOT played for the team Dignitas",
    answers: ["CaptainTwig", "Arkkyl", "Variety", "Duck3y"],
    correctAnswer: 1,
  },
  {
    question: "Which of these players has NOT played for the team eUnited",
    answers: ["Variety", "Emilitoo", "PandaCat", "Aror"],
    correctAnswer: 4,
  },
  {
    question:
      "Which of these players has NOT played for the team Spacestation Gaming",
    answers: ["BaRRaCCuDDa", "Screammmmmm", "Nika", "Dardez"],
    correctAnswer: 2,
  },

  {
    question: "Which of these teams has the player Lassiz NOT played for ?",
    answers: ["Team Allegiance", "EnvyUs", "Team Eager", "Dignitas"],
    correctAnswer: 2,
  },
  {
    question: "Which of these teams has the player 0mega NOT played for ?",
    answers: ["Luminosity", "Cloud9", "EnvyUs", "Team Eager"],
    correctAnswer: 1,
  },
  {
    question:
      "Which of these players has NOT played for the team Pittsburgh Knights",
    answers: ["Adapting", "NeilMah", "Zapman", "BigManTingz"],
    correctAnswer: 4,
  },
  {
    question: "Which of these players has NOT played for the team SK Gaming",
    answers: ["Cyno", "Duck3y", "Funballer", "CaptainTwig"],
    correctAnswer: 1,
  },
  {
    question: "Which of these players has NOT played for the Team Rival",
    answers: ["sam4soccer2", "Arkkyl", "Variety", "Wlfy"],
    correctAnswer: 3,
  },

  {
    question: "Which of these teams has the player Wlfy NOT played for ?",
    answers: ["Elevate", "Obey Alliance", "Team Rival", "Cyclone"],
    correctAnswer: 1,
  },
  {
    question: "Which of these teams has the player Arkkyl NOT played for ?",
    answers: ["Pittsburgh Knights", "Team Rival", "Sanguine", "Dignitas"],
    correctAnswer: 1,
  },
  {
    question: "Which of these players has NOT played for the Team Elevate",
    answers: ["Cherryo", "Dardez", "Lasbra", "Jermain"],
    correctAnswer: 3,
  },
  {
    question: "Which of these players has NOT played for the Team Trifecta",
    answers: ["Paul", "Snoopy", "Aquarius", "Masked"],
    correctAnswer: 3,
  },
  {
    question: "Which of these players has NOT played for the Team Paradigm",
    answers: ["Variety", "TrixTank", "Funballer", "Xaliea"],
    correctAnswer: 1,
  },

  // saturday
  {
    question: "Which of these teams has the player Kalas NOT played for ?",
    answers: ["Team Rival", "eUnited", "Cyclone", "Obey Alliance"],
    correctAnswer: 4,
  },
  {
    question: "Which of these teams has the player TrixTank NOT played for ?",
    answers: ["Paradigm", "Dignitas", "Fnatic", "Trig eSports"],
    correctAnswer: 3,
  },
  {
    question: "Which of these teams has the player Andinster NOT played for ?",
    answers: [
      "SoaR Gaming",
      "Luminosity",
      "Pittsburgh Knights",
      "Spacestation Gaming",
    ],
    correctAnswer: 3,
  },
  {
    question:
      "Which of these players has NEVER been suspended from playing in the league before ?",
    answers: ["Weak3n", "Julio", "Masked", "Haddix"],
    correctAnswer: 1,
  },
  {
    question:
      "Which of these players has NOT played in different roles in the league ?",
    answers: ["Benji", "Andinster", "Venenu", "Deathwalker"],
    correctAnswer: 1,
  },

  // Sunday
  {
    question: "Which of these teams has the player Funballer NOT played for ?",
    answers: ["SK Gaming", "Obey Alliance", "Renegades", "Fnatic"],
    correctAnswer: 2,
  },
  {
    question: "Which of these teams has the player Divios NOT played for ?",
    answers: ["Splyce", "eUnited", "Team Eager", "Team SoloMid"],
    correctAnswer: 2,
  },
  {
    question: "Which of these teams has the player Eonic NOT played for ?",
    answers: ["Trifecta", "Spacestation Gaming", "eUnited", "Luminosity"],
    correctAnswer: 2,
  },
  {
    question:
      "Which of these players has NOT played in different roles in the league ?",
    answers: ["Incon", "ScaryD", "CycloneSpin", "Variety"],
    correctAnswer: 4,
  },
  {
    question:
      "After winning the World Championship 2020 (Season 6), which god got the SK Gaming skin ?",
    answers: ["Scylla", "Chronos", "Jormungandr", "Merlin"],
    correctAnswer: 4,
  },

  // Monday
  {
    question: "Which of these teams has the player EmilZy NOT played for ?",
    answers: ["Pittsburgh Knights", "Obey Alliance", "NRG Esports", "Trifecta"],
    correctAnswer: 4,
  },
  // {
  //   question:
  //     "During the World Championship 2017 (Season 3), the EU region has been represented by two teams which are NRG Esports and : ",
  //   answers: ["Elevate", "Dignitas", "Fnatic", "Obey Alliance"],
  //   correctAnswer: 4,
  // },
];

export { questions };
