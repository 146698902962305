import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";
import { AngularFirestore } from "@angular/fire/firestore";
import { HistoryPlayer } from "../home/home.component";
import { Question, questions } from "src/app/models/SPL";
import { allData, data } from "src/app/models/data";

@Component({
  selector: "app-spl",
  templateUrl: "./spl.component.html",
  styleUrls: ["./spl.component.scss"],
})
export class SplComponent implements OnInit, AfterViewInit {
  questions: Question[] = JSON.parse(JSON.stringify(questions));
  todayQuestions: Question[] = [];
  selectedAnswer: number = null;
  completedQuestions: string[] = [];
  currentQuestion: Question;
  correctAnswers: string[] = [];

  counter = 0;

  fullResults: any[] = [];

  selectedNumber = 0;

  isCompleted: boolean = false;

  fullState: string = "uncompleted";

  cryptKey = environment.cryptKey;
  currentD = localStorage.getItem("currentD");
  getDataTries: number = 0;
  isError: boolean = false;
  isSelecting: boolean = false;

  ngAfterViewInit() {
    if (document.getElementById("f6")) {
      document.getElementById("f6").addEventListener("click", () => {
        this.isCompleted = true;
        this.fullState = "surrender";
        localStorage.setItem(
          "splState",
          CryptoJS.AES.encrypt(
            JSON.stringify("surrender"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
        this.selectedNumber = 0;
        document.getElementById("f6").remove();
      });
      if (this.isCompleted) {
        document.getElementById("f6").remove();
      }
    }
  }

  isInt(value): boolean {
    return Number.isInteger(value);
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private store: AngularFirestore
  ) {
    this.decryptTodayQuestions();
    // this.todayQuestions.push(this.questions[108]);
    // this.todayQuestions.push(this.questions[107]);
    // this.todayQuestions.push(this.questions[58]);
    // this.todayQuestions.push(this.questions[21]);
    // this.todayQuestions.push(this.questions[75]);

    let alreadyCompleted: string[] = [];

    try {
      alreadyCompleted = JSON.parse(localStorage.getItem("completedSPL"));
      if (alreadyCompleted !== null && alreadyCompleted !== undefined)
        alreadyCompleted.forEach((comp) => {
          if (!this.isInt(parseInt(comp))) {
            throw new Error();
          }
        });
    } catch (error) {
      alreadyCompleted = [];
      localStorage.removeItem("completedSPL");
      localStorage.removeItem("splState");
      for (let i = 1; i <= 5; i++) localStorage.removeItem("splQuestion_" + i);
    }

    this.todayQuestions.forEach((todayQuestion) => {
      let question = this.questions.filter((question) => {
        return question.question === todayQuestion.question;
      })[0];
      this.fullResults.push(question);
    });

    if (alreadyCompleted !== null && alreadyCompleted.length > 0) {
      this.completedQuestions = alreadyCompleted;
      for (let i = 0; i < this.completedQuestions.length; i++) {
        let alreadyCompletedAnswer: string;

        try {
          alreadyCompletedAnswer = JSON.parse(
            localStorage.getItem("splQuestion_" + this.completedQuestions[i])
          );
          if (![1, 2, 3, 4].includes(parseInt(alreadyCompletedAnswer))) {
            alreadyCompletedAnswer = null;
            localStorage.removeItem(
              "splQuestion_" + this.completedQuestions[i]
            );
            localStorage.removeItem("splState");
            this.completedQuestions.splice(i, 1);
            localStorage.setItem(
              "completedSPL",
              JSON.stringify(this.completedQuestions)
            );
          }
        } catch (error) {
          localStorage.removeItem("splQuestion_" + this.completedQuestions[i]);
          localStorage.removeItem("splState");
        }

        if (alreadyCompletedAnswer !== null) {
          let numb = parseInt(this.completedQuestions[i]) - 1;
          if (
            parseInt(alreadyCompletedAnswer) ===
            this.todayQuestions[numb].correctAnswer
          ) {
            this.correctAnswers.push(this.completedQuestions[i]);
          }
        }
      }
      if (
        ["1", "2", "3", "4", "5"].every((q) =>
          this.completedQuestions.includes(q)
        )
      ) {
        this.isCompleted = true;
      } else {
        for (let i = 0; i < 5; i++) {
          if (!this.completedQuestions.includes((i + 1).toString())) {
            this.counter = i;
            break;
          }
        }
      }
    } else {
      for (let i = 0; i < 5; i++) {
        let alreadyCompletedAnswer: string;

        try {
          alreadyCompletedAnswer = JSON.parse(
            localStorage.getItem("splQuestion_" + (i + 1))
          );
        } catch (error) {
          localStorage.removeItem("splQuestion_" + (i + 1));
          localStorage.removeItem("splState");
        }

        if (alreadyCompletedAnswer !== null) {
          localStorage.removeItem("splQuestion_" + (i + 1));
          localStorage.removeItem("splState");
        }
      }
    }

    let alreadyCompletedAnswer: string;

    try {
      alreadyCompletedAnswer = JSON.parse(
        localStorage.getItem("splQuestion_" + (this.counter + 1))
      );
    } catch (error) {
      localStorage.removeItem("splQuestion_" + (this.counter + 1));
      localStorage.removeItem("splState");
    }

    this.currentQuestion = this.todayQuestions[this.counter];
    if (alreadyCompletedAnswer !== null)
      this.selectedAnswer = parseInt(alreadyCompletedAnswer);
  }
  ngOnInit() {
    // let hellotest = ["VXC", "VBJ1", "VSBB", "VVVG", "VVGG"];
    // let tttt = CryptoJS.AES.encrypt(
    //   JSON.stringify(hellotest),
    //   this.cryptKey
    // ).toString();
    // console.log(tttt);
    // let numb1 = Math.floor(Math.random() * (107 - 0 + 1) + 0);
    // let numb2 = Math.floor(Math.random() * (107 - 0 + 1) + 0);
    // let numb3 = Math.floor(Math.random() * (107 - 0 + 1) + 0);
    // let numb4 = Math.floor(Math.random() * (107 - 0 + 1) + 0);
    // let numb5 = Math.floor(Math.random() * (107 - 0 + 1) + 0);

    // let splindexes = [numb1, numb2, numb3, numb4, numb5];

    // splindexes.forEach((splIndex) => {
    //   console.log(this.questions[splIndex]);
    // });

    // let tttt = CryptoJS.AES.encrypt(
    //   JSON.stringify(splindexes),
    //   this.cryptKey
    // ).toString();
    // console.log(tttt);

    // let ok =
    //   "U2FsdGVkX18pH4n/DtGIk+3dtQ4q0amI2RveHqLYaS5C2S588jPMe7R5SrVySKkMdZTEjvVF8kwu0zNB2AzcRdmZnhwe/VQne0NQKw+lG9Q=";
    // let test =
    //   "U2FsdGVkX1/GFwhcOX2F8JQnW3xm+GZz61H1/VH8Fvau8cvxWrfomGDZcT+ft7UjJij1iRAPMY2HG3IJ1tQkl7twDgWBl2PEstqzjNoTnJ8=";

    // let spl1 =
    //   "U2FsdGVkX191Wf8S1680tooatyKKhdFod0/5Pp9GrxmnErmhYUkeCXjCHYPH7+Ab";
    // let spl2 =
    //   "U2FsdGVkX18/F/bofvRtpcRJw7Wp2MyGAcHRrSJAWOdois47xX8ZI8N0n8Sbbo8y";
    // let spl3 =
    //   "U2FsdGVkX18mzHNibOuF/dOCDuVvwzPTG/SXutmPOaec6XtMU24MJucNL4Nwlzzn";
    // let spl4 =
    //   "U2FsdGVkX19M8pvqcGiXSZ1cSyP1Rx9Dyd67F+AGf9AryatMpAKdTz6HfMcidwHL";

    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(spl1, this.cryptKey).toString(CryptoJS.enc.Utf8)
    //   )
    // );
    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(spl2, this.cryptKey).toString(CryptoJS.enc.Utf8)
    //   )
    // );
    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(spl3, this.cryptKey).toString(CryptoJS.enc.Utf8)
    //   )
    // );
    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(spl4, this.cryptKey).toString(CryptoJS.enc.Utf8)
    //   )
    // );

    // let firstArray: number[] = [];
    // let secondArray: number[] = [];
    // let alreadydone = [
    //   108,
    //   45,
    //   84,
    //   0,
    //   75,
    //   65,
    //   97,
    //   53,
    //   31,
    //   17,
    //   87,
    //   28,
    //   93,
    //   59,
    //   46,
    //   94,
    //   16,
    //   31,
    //   24,
    //   98,
    // ];

    // console.log(questions.length);
    // console.log(questions[80]);
    // for (let i = 0; i <= 79; i++) {
    //   if (!alreadydone.includes(i)) firstArray.push(i);
    // }
    // for (let i = 80; i <= 109; i++) {
    //   if (!alreadydone.includes(i)) secondArray.push(i);
    // }

    // console.log(firstArray.length);
    // console.log(secondArray.length);

    // for (let i = 0; i <= 10; i++) {
    //   let list = [];
    //   for (let j = 0; j <= 2; j++) {
    //     let index = Math.floor(Math.random() * firstArray.length);
    //     list.push(firstArray[index]);
    //     firstArray.splice(index, 1);
    //   }
    //   for (let j = 0; j <= 1; j++) {
    //     let index = Math.floor(Math.random() * secondArray.length);
    //     list.push(secondArray[index]);
    //     secondArray.splice(index, 1);
    //   }
    //   console.log("List " + (i + 1));
    //   console.log(list);
    //   let tttt = CryptoJS.AES.encrypt(
    //     JSON.stringify(list),
    //     this.cryptKey
    //   ).toString();
    //   console.log(tttt);
    //   // console.log("List " + (i + 1) + " : " + list);
    // }

    // ("U2FsdGVkX1/m82Md+modCW+0A1fDlRlsd/GrgC1WBdwt/I0EdPrraP5HSkVd5teC");
    // ("U2FsdGVkX1+qf/OyiRxmWy7iyi3Eb6KAwo5W8XtDGRs2usr/0924y5dwSw3wFyYm");
    // ("U2FsdGVkX18rNkf6mlTn2SlMvTcIWJof1bTiONkn3DT3PckUYLvv7nZCQW44YDwa");
    // let ttt =
    //   "U2FsdGVkX1/zZQvQ+I4MYuAQfFun61JqVUGupbBgYo5RMBPDPIzIQH4qeCbLXXm6";

    // ("U2FsdGVkX19yo7rryioUrT99iNrwuuO6cA/tUQ+MCkFjzYzRMPeNQtHigxP3ftkJ");
    // ("U2FsdGVkX1+DlMQDcgIsm9GYFWP8Nj2PzHwouMhZvH++STHpZgBN4Y/3x74JhPbZ");
    // ("U2FsdGVkX191AB6lkzc6J2xqc3JImGYAOvLeOfNCoEs=");
    // ("U2FsdGVkX19HNlSw6LsXBhd5OW7CXtskT9pBSKYJy50");
    // ("U2FsdGVkX18CMBBXGReSgxLwAKwcmeKFxFAEA8uAv/pAbwPvZuClvQU91UOji40V");
    // ("U2FsdGVkX19YY79U1vtDsjqFgYsXC59ATz5bedu1/JyUkOsy3Af4Ostn3Gu+NHwc");
    // ("U2FsdGVkX1+uAUHI5MHLQEerLhhb771SH4iTXrcAcLnbwygslg9psDs0vxIFbu3J");

    // console.log(firstArray.length);
    // console.log(secondArray.length);

    // let hellotest = ["VC3", "VCJ", "VSAG", "VEJ", "VVVA"];
    // let tttt = CryptoJS.AES.encrypt(
    //   JSON.stringify(hellotest),
    //   this.cryptKey
    // ).toString();
    // console.log(tttt);

    // ** Decrypt stuff ** \\

    // let ttt =
    //   "U2FsdGVkX1//ykLbgxqQZdhiLUlhlOtKJIQOHzpTObBsqP8yrHlcGzgidPfjYDpH1a34OZ8N8meGaDq1EaLiBg==";
    // const bytes = CryptoJS.AES.decrypt(ttt, this.cryptKey);
    // // if (bytes.toString()) {
    // console.log(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));

    // let feud1 = "U2FsdGVkX198tMseeE/aO52bbJR2vzNQIZb6qsnFJ2Q=";
    // let feud2 = "U2FsdGVkX19S/eSOp7akh/buNPtyUnJ/dJCxLwt/mk8=";
    // let feud3 = "U2FsdGVkX19AG1mNv7xbgjZGC39I8SjgTPyMe9dbYvk=";
    // ("U2FsdGVkX1/LE1vF1nyxxTIrRGi30uobZj7gPLmVKWE=");
    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(feud1, this.cryptKey).toString(CryptoJS.enc.Utf8)
    //   )
    // );
    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(feud2, this.cryptKey).toString(CryptoJS.enc.Utf8)
    //   )
    // );
    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(feud3, this.cryptKey).toString(CryptoJS.enc.Utf8)
    //   )
    // );

    // let tttt = CryptoJS.AES.encrypt(
    //   JSON.stringify("Ao Kuang"),
    //   this.cryptKey
    // ).toString();
    // console.log(tttt);

    // let tttt2 = CryptoJS.AES.encrypt(
    //   JSON.stringify([26, 91, 17, 99, 78]),
    //   this.cryptKey
    // ).toString();
    // console.log(tttt2);

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([25, 49, 16, 100, 79]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([24, 48, 15, 101, 0]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([23, 47, 14, 102, 1]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([22, 46, 12, 103, 2]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([21, 45, 11, 104, 3]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([20, 43, 10, 105, 4]),
    //     this.cryptKey
    //   ).toString()
    // );

    // let test = CryptoJS.AES.encrypt(
    //   JSON.stringify(13),
    //   this.cryptKey
    // ).toString();
    // console.log(test);

    // let test2 = CryptoJS.AES.encrypt(
    //   JSON.stringify(15),
    //   this.cryptKey
    // ).toString();
    // console.log(test2);

    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(
    //       "U2FsdGVkX1/TgdZTsjo6VVvtgzO3LA754v+1sEBQnJ8=",
    //       this.cryptKey
    //     ).toString(CryptoJS.enc.Utf8)
    //   )
    // );
    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(
    //       "U2FsdGVkX1/nsk1ZwyEI/TjC50kPfbvg56F4ZBQIq+o=",
    //       this.cryptKey
    //     ).toString(CryptoJS.enc.Utf8)
    //   )
    // );
    // console.log(new Date().getUTCDay());
    // console.log(this.questions[151]);
    // console.log(this.questions[152]);
    // console.log(this.questions[153]);
    // console.log(this.questions[154]);
    // console.log(this.questions[155]);

    /* Thursday : [63, 73, 82, 51, 89] */
    /* Friday : [110, 13, 77, 44, 33] */
    /* Saturday : [66, 69, 80, 98, 7] */
    /* Sunday : [65, 70, 81, 50, 9] */

    /* Monday : [67, 74, 83, 52, 92] */
    /* Tuesday : [68, 75, 84, 53, 93] */
    /* Wednesday : [62, 76, 85, 56, 94] */
    /* Thursday : [61, 30, 86, 57, 95] */
    /* Friday : [60, 29, 87, 58, 98] */
    /* Saturday : [59, 28, 88, 19, 97] */
    /* Sunday : [71, 27, 90, 18, 96] */

    /* Monday : [72, 26, 91, 17, 99] */
    /* Tuesday : [78, 25, 49, 16, 100] */
    /* Wednesday : [79, 24, 48, 15, 101] */
    /* Thursday : [0, 23, 47, 14, 102] */
    /* Friday : [1, 22, 46, 12, 103] */
    /* Saturday : [2, 21, 45, 11, 104] */
    /* Sunday : [3, 20, 43, 10, 105] */

    /* Monday : [4, 31, 42, 8, 106] */
    /* Tuesday : [5, 32, 41, 9, 109] */
    /* Wednesday : [6, 34, 40, 64, 110] */
    /* Thursday : [7, 35, 39, 63, 111] */
    /* Friday : [9, 36, 38, 51, 112] */
    /* Saturday : [37, 54, 73, 89, 113] */
    /* Sunday : [55, 66, 77, 13, 114] */

    /* Monday : [115, 116, 117, 118, 119] */

    // new arrangement //

    /* Tuesday : [82, 44, 33, 69, 80] */
    /* Wednesday : [98, 65, 70, 81, 50] */
    /* Thursday : [67, 75, 85, 57, 95] */
    /* Friday : [74, 84, 56, 68, 93] */
    /* Saturday : [83, 53, 94, 61, 29] */
    /* Sunday : [52, 92, 62, 30, 86] */

    /* Monday : [76, 60, 87, 58, 28] */
    /* Tuesday : [59, 88, 19, 97, 71] */
    /* Wednesday : [27, 90, 18, 96, 72] */
    /* Thursday : [26, 91, 17, 99, 78] */
    /* Friday : [25, 49, 16, 100, 79] */
    /* Saturday : [24, 48, 15, 101, 0] */
    /* Sunday : [23, 47, 14, 102, 1] */

    /* Monday : [22, 46, 12, 103, 2] */
    /* Tuesday : [21, 45, 11, 104, 3] */
    /* Wednesday : [20, 43, 10, 105, 4] */
    /* Thursday : [31, 42, 8, 106, 5] */
    /* Friday : [32, 41, 9, 109, 6] */
    /* Saturday : [34, 40, 64, 110, 7] */
    /* Sunday : [35, 39, 63, 111, 9] */

    /* Monday : [36, 38, 51, 112, 37] */
    /* Tuesday : [54, 73, 89, 113, 55] */
    /* Wednesday : [66, 77, 13, 114, 115] */
    /* Thursday : [116, 117, 118, 119, 120] */
    /* Friday : [121, 122, 123, 124, 125] */
    /* Saturday : [126, 127, 128, 129, 130] */
    /* Sunday : [131, 132, 133, 134, 135] */

    /* Monday : [136, 137, 138, 139, 140] */
    /* Tuesday : [141, 142, 143, 144, 145] */
    /* Wednesday : [146, 147, 148, 149, 150] */
    /* Thursday : [151, 152, 153, 154, 155] */

    // new arrangement //

    /* Friday : [82, 44, 33, 69, 80] */
    /* Saturday : [98, 65, 70, 81, 50] */
    /* Sunday : [67, 75, 85, 57, 95] */

    /* Monday : [74, 84, 56, 68, 93] */
    /* Tuesday : [83, 53, 94, 61, 29] */
    /* Wednesday : [52, 92, 62, 30, 86] */
    /* Thursday : [76, 60, 87, 58, 28] */
    /* Friday : [59, 88, 19, 97, 71] */
    /* Saturday : [27, 90, 18, 96, 72] */
    /* Sunday : [26, 91, 17, 99, 78] */

    /* Monday : [25, 49, 16, 100, 79] */
    /* Tuesday : [24, 48, 15, 101, 0] */
    /* Wednesday : [23, 47, 14, 102, 1] */
    /* Thursday : [22, 46, 12, 103, 2] */
    /* Friday : [21, 45, 11, 104, 3] */
    /* Saturday : [20, 43, 10, 105, 4] */
    /* Sunday : [31, 42, 8, 106, 5] */

    /* Monday : [32, 41, 9, 109, 6] */
    /* Tuesday : [34, 40, 64, 110, 7] */
    /* Wednesday : [35, 39, 63, 111, 9] */
    /* Thursday : [36, 38, 51, 112, 37] */
    /* Friday : [54, 73, 89, 113, 55] */
    /* Saturday : [66, 77, 13, 114, 115] */
    /* Sunday : [116, 117, 118, 119, 120] */

    /* Monday : [121, 122, 123, 124, 125] */
    /* Tuesday : [126, 127, 128, 129, 130] */
    /* Wednesday : [131, 132, 133, 134, 135] */
    /* Thursday : [136, 137, 138, 139, 140] */
    /* Friday : [141, 142, 143, 144, 145] */
    /* Saturday : [146, 147, 148, 149, 150] */
    /* Sunday : [151, 152, 153, 154, 155] */

    // new arrangement //

    /* Monday : [82, 44, 33, 69, 80] */
    /* Tuesday : [98, 65, 70, 81, 50] */
    /* Wednesday : [67, 75, 85, 57, 95] */
    /* Thursday : [74, 84, 56, 68, 93] */
    /* Friday : [83, 53, 94, 61, 29] */
    /* Saturday : [52, 92, 62, 30, 86] */
    /* Sunday : [76, 60, 87, 58, 28] */

    /* Monday : [59, 88, 19, 97, 71] */
    /* Tuesday : [27, 90, 18, 96, 72] */
    /* Wednesday : [26, 91, 17, 99, 78] */
    /* Thursday : [25, 49, 16, 100, 79] */
    /* Friday : [24, 48, 15, 101, 0] */
    /* Saturday : [23, 47, 14, 102, 1] */
    /* Sunday : [22, 46, 12, 103, 2] */

    /* Monday : [21, 45, 11, 104, 3] */
    /* Tuesday : [20, 43, 10, 105, 4] */
    /* Wednesday : [31, 42, 8, 106, 5] */
    /* Thursday : [32, 41, 9, 109, 6] */
    /* Friday : [34, 40, 64, 110, 7] */
    /* Saturday : [35, 39, 63, 111, 9] */
    /* Sunday : [36, 38, 51, 112, 37] */

    /* Monday : [54, 73, 89, 113, 55] */
    /* Tuesday : [66, 77, 13, 114, 115] */
    /* Wednesday : [116, 117, 118, 119, 120] */
    /* Thursday : [121, 122, 123, 124, 125] */
    /* Friday : [126, 127, 128, 129, 130] */
    /* Saturday : [131, 132, 133, 134, 135] */
    /* Sunday : [136, 137, 138, 139, 140] */

    /* Monday : [141, 142, 143, 144, 145] */
    /* Tuesday : [146, 147, 148, 149, 150] */
    /* Wednesday : [151, 152, 153, 154, 155] */
    /* Thursday : [82, 44, 33, 69, 80] */
    /* Friday : [98, 65, 70, 81, 50] */
    /* Saturday : [67, 75, 85, 57, 95] */
    /* Sunday : [74, 84, 56, 68, 93] */

    /* Monday : [83, 53, 94, 61, 29] */
    /* Tuesday : [52, 92, 62, 30, 86] */
    /* Wednesday : [76, 60, 87, 58, 28] */
    /* Thursday : [59, 88, 19, 97, 71] */
    /* Friday : [27, 90, 18, 96, 72] */
    /* Saturday : [26, 91, 17, 99, 78] */
    /* Sunday : [25, 49, 16, 100, 79] */

    /* Monday : [24, 48, 15, 101, 0] */
    /* Tuesday : [23, 47, 14, 102, 1] */
    /* Wednesday : [22, 46, 12, 103, 2] */
    /* Thursday : [21, 45, 11, 104, 3] */
    /* Friday : [20, 43, 10, 105, 4] */
    /* Saturday : [31, 42, 8, 106, 5] */
    /* Sunday : [32, 41, 9, 109, 6] */

    /* Monday : [34, 40, 64, 110, 7] */
    /* Tuesday : [35, 39, 63, 111, 9] */
    /* Wednesday : [36, 38, 51, 112, 37] */
    /* Thursday : [54, 73, 89, 113, 55] */
    /* Friday : [66, 77, 13, 114, 115] */
    /* Saturday : [116, 117, 118, 119, 120] */
    /* Sunday : [121, 122, 123, 124, 125] */

    /* Monday : [126, 127, 128, 129, 130] */
    /* Tuesday : [131, 132, 133, 134, 135] */
    /* Wednesday : [136, 137, 138, 139, 140] */
    /* Thursday : [141, 142, 143, 144, 145] */
    /* Friday : [146, 147, 148, 149, 150] */
    /* Saturday : [151, 152, 153, 154, 155] */
    /* Sunday : [82, 44, 33, 69, 80] */

    /* Monday : [98, 65, 70, 81, 50] */
    /* Tuesday : [67, 75, 85, 57, 95] */
    /* Wednesday : [74, 84, 56, 68, 93] */
    /* Thursday : [83, 53, 94, 61, 29] */
    /* Friday : [52, 92, 62, 30, 86] */
    /* Saturday : [76, 60, 87, 58, 28] */
    /* Sunday : [59, 88, 19, 97, 71] */

    /* Monday : [27, 90, 18, 96, 72] */
    /* Tuesday : [26, 91, 17, 99, 78] */
    /* Wednesday : [25, 49, 16, 100, 79] */
    /* Thursday : [24, 48, 15, 101, 0] */
    /* Friday : [23, 47, 14, 102, 1] */
    /* Saturday : [22, 46, 12, 103, 2] */
    /* Sunday : [21, 45, 11, 104, 3] */

    /* Monday : [20, 43, 10, 105, 4] */
    /* Tuesday : [31, 42, 8, 106, 5] */
    /* Wednesday : [32, 41, 9, 109, 6] */
    /* Thursday : [34, 40, 64, 110, 7] */
    /* Friday : [35, 39, 63, 111, 9] */
    /* Saturday : [36, 38, 51, 112, 37] */
    /* Sunday : [54, 73, 89, 113, 55] */

    /* Monday : [66, 77, 13, 114, 115] */
    /* Tuesday : [116, 117, 118, 119, 120] */
    /* Wednesday : [121, 122, 123, 124, 125] */
    /* Thursday : [126, 127, 128, 129, 130] */
    /* Friday : [131, 132, 133, 134, 135] */
    /* Saturday : [136, 137, 138, 139, 140] */
    /* Sunday : [141, 142, 143, 144, 145] */

    /* Monday : [146, 147, 148, 149, 150] */
    /* Tuesday : [151, 152, 153, 154, 155] */
    /* Wednesday : [82, 44, 33, 69, 80] */
    /* Thursday : [98, 65, 70, 81, 50] */
    /* Friday : [67, 75, 85, 57, 95] */
    /* Saturday : [74, 84, 56, 68, 93] */
    /* Sunday : [83, 53, 94, 61, 29] */

    /* Monday : [52, 92, 62, 30, 86] */
    /* Tuesday : [76, 60, 87, 58, 28] */
    /* Wednesday : [59, 88, 19, 97, 71] */
    /* Thursday : [27, 90, 18, 96, 72] */
    /* Friday : [26, 91, 17, 99, 78] */
    /* Saturday : [25, 49, 16, 100, 79] */
    /* Sunday : [24, 48, 15, 101, 0] */

    /* Monday : [23, 47, 14, 102, 1] */
    /* Tuesday : ? */
    /* Wednesday : [22, 46, 12, 103, 2] */
    /* Thursday : [21, 45, 11, 104, 3] */
    /* Friday : [20, 43, 10, 105, 4] */
    /* Saturday : [31, 42, 8, 106, 5] */
    /* Sunday : [32, 41, 9, 109, 6] */

    /* Monday : [34, 40, 64, 110, 7] */
    /* Tuesday : [35, 39, 63, 111, 9] */
    /* Wednesday : [36, 38, 51, 112, 37] */
    /* Thursday : [54, 73, 89, 113, 55] */
    /* Friday : [66, 77, 13, 114, 115] */
    /* Saturday : [116, 117, 118, 119, 120] */
    /* Sunday : [121, 122, 123, 124, 125] */

    /* Monday : [126, 127, 128, 129, 130] */
    /* Tuesday : [131, 132, 133, 134, 135] */
    /* Wednesday : [136, 137, 138, 139, 140] */
    /* Thursday : [141, 142, 143, 144, 145] */
    /* Friday : [146, 147, 148, 149, 150] */
    /* Saturday : [151, 152, 153, 154, 155] */
    /* Sunday : [82, 44, 33, 69, 80] */

    /* Monday : [98, 65, 70, 81, 50] */
    /* Tuesday : [67, 75, 85, 57, 95] */
    /* Wednesday : [74, 84, 56, 68, 93] */
    /* Thursday : [83, 53, 94, 61, 29] */
    /* Friday : [52, 92, 62, 30, 86] */
    /* Saturday : [76, 60, 87, 58, 28] */
    /* Sunday : [59, 88, 19, 97, 71] */

    /* Monday : [27, 90, 18, 96, 72] */
    /* Tuesday : [26, 91, 17, 99, 78] */
    /* Wednesday : [25, 49, 16, 100, 79] */
    /* Thursday : [24, 48, 15, 101, 0] */
    /* Friday : [23, 47, 14, 102, 1] */
    /* Saturday : [22, 46, 12, 103, 2] */
    /* Sunday : [21, 45, 11, 104, 3] */

    /* Monday : [20, 43, 10, 105, 4] */
    /* Tuesday : [31, 42, 8, 106, 5] */
    /* Wednesday : [32, 41, 9, 109, 6] */
    /* Thursday : [34, 40, 64, 110, 7] */
    /* Friday : [35, 39, 63, 111, 9] */
    /* Saturday : [36, 38, 51, 112, 37] */
    /* Sunday : [54, 73, 89, 113, 55] */

    /* Monday : [66, 77, 13, 114, 115] */
    /* Tuesday : [116, 117, 118, 119, 120] */
    /* Wednesday : [121, 122, 123, 124, 125] */
    /* Thursday : [126, 127, 128, 129, 130] */
    /* Friday : [131, 132, 133, 134, 135] */
    /* Saturday : [136, 137, 138, 139, 140] */
    /* Sunday : [141, 142, 143, 144, 145] */

    /* Monday : [146, 147, 148, 149, 150] */
    /* Tuesday : [151, 152, 153, 154, 155] */
    /* Wednesday : [82, 44, 33, 69, 80] */
    /* Thursday : [98, 65, 70, 81, 50] */
    /* Friday : [67, 75, 85, 57, 95] */
    /* Saturday : [74, 84, 56, 68, 93] */
    /* Sunday : [83, 53, 94, 61, 29] */

    /* Monday : [52, 92, 62, 30, 86] */
    /* Tuesday : [76, 60, 87, 58, 28] */
    /* Wednesday : [59, 88, 19, 97, 71] */
    /* Thursday : [27, 90, 18, 96, 72] */
    /* Friday : [26, 91, 17, 99, 78] */
    /* Saturday : [25, 49, 16, 100, 79] */
    /* Sunday : [24, 48, 15, 101, 0] */

    /* Monday : [23, 47, 14, 102, 1] */
    /* Tuesday : [22, 46, 12, 103, 2] */
    /* Wednesday : [21, 45, 11, 104, 3] */
    /* Thursday : [20, 43, 10, 105, 4] */
    /* Friday : [31, 42, 8, 106, 5] */
    /* Saturday : [32, 41, 9, 109, 6] */
    /* Sunday : [34, 40, 64, 110, 7] */

    /* Monday : [35, 39, 63, 111, 9] */
    /* Tuesday : [36, 38, 51, 112, 37] */
    /* Wednesday : [54, 73, 89, 113, 55] */
    /* Thursday : [66, 77, 13, 114, 115] */
    /* Friday : [116, 117, 118, 119, 120] */
    /* Saturday : [121, 122, 123, 124, 125] */
    /* Sunday : [126, 127, 128, 129, 130] */

    /* Monday : [131, 132, 133, 134, 135] */
    /* Tuesday : [136, 137, 138, 139, 140] */
    /* Wednesday : [141, 142, 143, 144, 145] */
    /* Thursday : [146, 147, 148, 149, 150] */
    /* Friday : [151, 152, 153, 154, 155] */
    /* Saturday : [82, 44, 33, 69, 80] */ // U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L
    /* Sunday : [98, 65, 70, 81, 50] */ // U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG

    /* Monday : [67, 75, 85, 57, 95] */ // U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce
    /* Tuesday : [74, 84, 56, 68, 93] */ // U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx
    /* Wednesday : [83, 53, 94, 61, 29] */ // U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh
    /* Thursday : [52, 92, 62, 30, 86] */ // U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW
    /* Friday : [76, 60, 87, 58, 28] */ // U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND
    /* Saturday : [59, 88, 19, 97, 71] */ // U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU
    /* Sunday : [27, 90, 18, 96, 72] */ // U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC

    /* Monday : [26, 91, 17, 99, 78] */ // U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7
    /* Tuesday : [25, 49, 16, 100, 79] */ // U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs
    /* Wednesday : [24, 48, 15, 101, 0] */ // U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR
    /* Thursday : [23, 47, 14, 102, 1] */ // U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb
    /* Friday : [22, 46, 12, 103, 2] */ // U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY
    /* Saturday : [21, 45, 11, 104, 3] */ // U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X
    /* Sunday : [20, 43, 10, 105, 4] */ // U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5

    /* Monday : [31, 42, 8, 106, 5] */ // U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=
    /* Tuesday : [32, 41, 9, 109, 6] */ // U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=
    /* Wednesday : [34, 40, 64, 110, 7] */ // U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo
    /* Thursday : [35, 39, 63, 111, 9] */ // U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB
    /* Friday : [36, 38, 51, 112, 37] */ // U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm
    /* Saturday : [54, 73, 89, 113, 55] */ // U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL
    /* Sunday : [66, 77, 13, 114, 115] */ // U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE

    /* Monday : [116, 117, 118, 119, 120] */ // U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS
    /* Tuesday : [121, 122, 123, 124, 125] */ // U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT
    /* Wednesday : [126, 127, 128, 129, 130] */ // U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ
    /* Thursday : [131, 132, 133, 134, 135] */ // U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih
    /* Friday : [136, 137, 138, 139, 140] */ // U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B
    /* Saturday : [141, 142, 143, 144, 145] */ // U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR
    /* Sunday : [146, 147, 148, 149, 150] */ // U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y

    /* Monday : [151, 152, 153, 154, 155] */ // U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s
    /* Tuesday : [82, 44, 33, 69, 80] */ // U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L
    /* Wednesday : [98, 65, 70, 81, 50] */ // U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG
    /* Thursday : [67, 75, 85, 57, 95] */ // U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce
    /* Friday : [74, 84, 56, 68, 93] */ // U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx
    /* Saturday : [83, 53, 94, 61, 29] */ // U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh
    /* Sunday : [52, 92, 62, 30, 86] */ // U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW

    /* Monday : [76, 60, 87, 58, 28] */ // U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND
    /* Tuesday : [59, 88, 19, 97, 71] */ // U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU
    /* Wednesday : [27, 90, 18, 96, 72] */ // U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC
    /* Thursday : [26, 91, 17, 99, 78] */ // U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7
    /* Friday : [25, 49, 16, 100, 79] */ // U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs
    /* Saturday : [24, 48, 15, 101, 0] */ // U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR
    /* Sunday : [23, 47, 14, 102, 1] */ // U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb

    /* Monday : [22, 46, 12, 103, 2] */ // U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY
    /* Tuesday : [21, 45, 11, 104, 3] */ // U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X
    /* Wednesday : [20, 43, 10, 105, 4] */ // U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5
    /* Thursday : [31, 42, 8, 106, 5] */ // U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=
    /* Friday : [32, 41, 9, 109, 6] */ // U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=
    /* Saturday : [34, 40, 64, 110, 7] */ // U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo
    /* Sunday : [35, 39, 63, 111, 9] */ // U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB

    /* Monday : [36, 38, 51, 112, 37] */ // U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm
    /* Tuesday : [54, 73, 89, 113, 55] */ // U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL
    /* Wednesday : [66, 77, 13, 114, 115] */ // U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE
    /* Thursday : [116, 117, 118, 119, 120] */ // U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS
    /* Friday : [121, 122, 123, 124, 125] */ // U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT
    /* Saturday : [126, 127, 128, 129, 130] */ // U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ
    /* Sunday : [131, 132, 133, 134, 135] */ // U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih

    /* Monday : [136, 137, 138, 139, 140] */ // U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B
    /* Tuesday : [141, 142, 143, 144, 145] */ // U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR
    /* Wednesday : [146, 147, 148, 149, 150] */ // U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y
    /* Thursday : [151, 152, 153, 154, 155] */ // U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s
    /* Friday : [82, 44, 33, 69, 80] */ // U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L
    /* Saturday : [98, 65, 70, 81, 50] */ // U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG
    /* Sunday : [67, 75, 85, 57, 95] */ // U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce

    /* Monday : [74, 84, 56, 68, 93] */ // U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx
    /* Tuesday : [83, 53, 94, 61, 29] */ // U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh
    /* Wednesday : [52, 92, 62, 30, 86] */ // U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW
    /* Thursday : [76, 60, 87, 58, 28] */ // U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND
    /* Friday : [59, 88, 19, 97, 71] */ // U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU
    /* Saturday : [27, 90, 18, 96, 72] */ // U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC
    /* Sunday : [26, 91, 17, 99, 78] */ // U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7

    /* Monday : [25, 49, 16, 100, 79] */ // U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs
    /* Tuesday : [24, 48, 15, 101, 0] */ // U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR
    /* Wednesday : [23, 47, 14, 102, 1] */ // U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb
    /* Thursday : [22, 46, 12, 103, 2] */ // U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY
    /* Friday : [21, 45, 11, 104, 3] */ // U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X
    /* Saturday : [20, 43, 10, 105, 4] */ // U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5
    /* Sunday : [31, 42, 8, 106, 5] */ // U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=

    /* Monday : [32, 41, 9, 109, 6] */ // U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=
    /* Tuesday : [34, 40, 64, 110, 7] */ // U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo
    /* Wednesday : [35, 39, 63, 111, 9] */ // U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB
    /* Thursday : [36, 38, 51, 112, 37] */ // U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm
    /* Friday : [54, 73, 89, 113, 55] */ // U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL
    /* Saturday : [66, 77, 13, 114, 115] */ // U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE
    /* Sunday : [116, 117, 118, 119, 120] */ // U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS

    /* Monday : [121, 122, 123, 124, 125] */ // U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT
    /* Tuesday : [126, 127, 128, 129, 130] */ // U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ
    /* Wednesday : [131, 132, 133, 134, 135] */ // U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih
    /* Thursday : [136, 137, 138, 139, 140] */ // U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B
    /* Friday : [141, 142, 143, 144, 145] */ // U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR
    /* Saturday : [146, 147, 148, 149, 150] */ // U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y
    /* Sunday : [151, 152, 153, 154, 155] */ // U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s

    /* Monday : [82, 44, 33, 69, 80] */ // U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L
    /* Tuesday : [98, 65, 70, 81, 50] */ // U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG
    /* Wednesday : [67, 75, 85, 57, 95] */ // U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce
    /* Thursday : [74, 84, 56, 68, 93] */ // U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx
    /* Friday : [83, 53, 94, 61, 29] */ // U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh
    /* Saturday : [52, 92, 62, 30, 86] */ // U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW
    /* Sunday : [76, 60, 87, 58, 28] */ // U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND

    /* Monday : [59, 88, 19, 97, 71] */ // U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU
    /* Tuesday : [27, 90, 18, 96, 72] */ // U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC
    /* Wednesday : [26, 91, 17, 99, 78] */ // U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7
    /* Thursday : [25, 49, 16, 100, 79] */ // U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs
    /* Friday : [24, 48, 15, 101, 0] */ // U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR
    /* Saturday : [23, 47, 14, 102, 1] */ // U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb
    /* Sunday : [22, 46, 12, 103, 2] */ // U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY

    /* Monday : [21, 45, 11, 104, 3] */ // U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X
    /* Tuesday : [20, 43, 10, 105, 4] */ // U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5
    /* Wednesday : [31, 42, 8, 106, 5] */ // U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=
    /* Thursday : [32, 41, 9, 109, 6] */ // U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=
    /* Friday : [34, 40, 64, 110, 7] */ // U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo
    /* Saturday : [35, 39, 63, 111, 9] */ // U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB
    /* Sunday : [36, 38, 51, 112, 37] */ // U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm

    /* Monday : [54, 73, 89, 113, 55] */ // U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL
    /* Tuesday : [66, 77, 13, 114, 115] */ // U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE
    /* Wednesday : [116, 117, 118, 119, 120] */ // U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS
    /* Thursday : [121, 122, 123, 124, 125] */ // U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT
    /* Friday : [126, 127, 128, 129, 130] */ // U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ
    /* Saturday : [131, 132, 133, 134, 135] */ // U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih
    /* Sunday : [136, 137, 138, 139, 140] */ // U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B

    /* Monday : [141, 142, 143, 144, 145] */ // U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR
    /* Tuesday : [146, 147, 148, 149, 150] */ // U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y
    /* Wednesday : [151, 152, 153, 154, 155] */ // U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s
    /* Thursday : [82, 44, 33, 69, 80] */ // U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L
    /* Friday : [98, 65, 70, 81, 50] */ // U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG
    /* Saturday : [67, 75, 85, 57, 95] */ // U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce
    /* Sunday : [74, 84, 56, 68, 93] */ // U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx

    /* Monday : [83, 53, 94, 61, 29] */ // U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh
    /* Tuesday : [52, 92, 62, 30, 86] */ // U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW
    /* Wednesday : [76, 60, 87, 58, 28] */ // U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND
    /* Thursday : [59, 88, 19, 97, 71] */ // U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU
    /* Friday : [27, 90, 18, 96, 72] */ // U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC
    /* Saturday : [26, 91, 17, 99, 78] */ // U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7
    /* Sunday : [25, 49, 16, 100, 79] */ // U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs

    /* Monday : [24, 48, 15, 101, 0] */ // U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR
    /* Tuesday : [23, 47, 14, 102, 1] */ // U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb
    /* Wednesday : [22, 46, 12, 103, 2] */ // U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY
    /* Thursday : [21, 45, 11, 104, 3] */ // U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X
    /* Friday : [20, 43, 10, 105, 4] */ // U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5
    /* Saturday : [31, 42, 8, 106, 5] */ // U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=
    /* Sunday : [32, 41, 9, 109, 6] */ // U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=

    /* Monday : [34, 40, 64, 110, 7] */ // U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo
    /* Tuesday : [35, 39, 63, 111, 9] */ // U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB
    /* Wednesday : [36, 38, 51, 112, 37] */ // U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm
    /* Thursday : [54, 73, 89, 113, 55] */ // U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL
    /* Friday : [66, 77, 13, 114, 115] */ // U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE
    /* Saturday : [116, 117, 118, 119, 120] */ // U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS
    /* Sunday : [121, 122, 123, 124, 125] */ // U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT

    /* Monday : [126, 127, 128, 129, 130] */ // U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ
    /* Tuesday : [131, 132, 133, 134, 135] */ // U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih
    /* Wednesday : [136, 137, 138, 139, 140] */ // U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B
    /* Thursday : [141, 142, 143, 144, 145] */ // U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR
    /* Friday : [146, 147, 148, 149, 150] */ // U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y
    /* Saturday : [151, 152, 153, 154, 155] */ // U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s
    /* Sunday : [82, 44, 33, 69, 80] */ // U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L

    /* Monday : [98, 65, 70, 81, 50] */ // U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG
    /* Tuesday : [67, 75, 85, 57, 95] */ // U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce
    /* Wednesday : [74, 84, 56, 68, 93] */ // U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx
    /* Thursday : [83, 53, 94, 61, 29] */ // U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh
    /* Friday : [52, 92, 62, 30, 86] */ // U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW
    /* Saturday : [76, 60, 87, 58, 28] */ // U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND
    /* Sunday : [59, 88, 19, 97, 71] */ // U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU

    /* Monday : [27, 90, 18, 96, 72] */ // U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC
    /* Tuesday : [26, 91, 17, 99, 78] */ // U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7
    /* Wednesday : [25, 49, 16, 100, 79] */ // U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs
    /* Thursday : [24, 48, 15, 101, 0] */ // U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR
    /* Friday : [23, 47, 14, 102, 1] */ // U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb
    /* Saturday : [22, 46, 12, 103, 2] */ // U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY
    /* Sunday : [21, 45, 11, 104, 3] */ // U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X

    /* Monday : [20, 43, 10, 105, 4] */ // U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5
    /* Tuesday : [31, 42, 8, 106, 5] */ // U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=
    /* Wednesday : [32, 41, 9, 109, 6] */ // U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=
    /* Thursday : [34, 40, 64, 110, 7] */ // U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo
    /* Friday : [35, 39, 63, 111, 9] */ // U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB
    /* Saturday : [36, 38, 51, 112, 37] */ // U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm
    /* Sunday : [54, 73, 89, 113, 55] */ // U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL

    /* Monday : [66, 77, 13, 114, 115] */ // U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE
    /* Tuesday : [116, 117, 118, 119, 120] */ // U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS
    /* Wednesday : [121, 122, 123, 124, 125] */ // U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT
    /* Thursday : [126, 127, 128, 129, 130] */ // U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ
    /* Friday : [131, 132, 133, 134, 135] */ // U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih
    /* Saturday : [136, 137, 138, 139, 140] */ // U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B
    /* Sunday : [141, 142, 143, 144, 145] */ // U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR

    /* Monday : [146, 147, 148, 149, 150] */ // U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y
    /* Tuesday : 
    /* Wednesday : 
    /* Thursday : 
    /* Friday : 
    /* Saturday : 
    /* Sunday :  */

    // ** SPL ** /

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([121, 122, 123, 124, 125]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([126, 127, 128, 129, 130]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([131, 132, 133, 134, 135]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([136, 137, 138, 139, 140]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([141, 142, 143, 144, 145]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([146, 147, 148, 149, 150]),
    //     this.cryptKey
    //   ).toString()
    // );

    // console.log(
    //   CryptoJS.AES.encrypt(
    //     JSON.stringify([151, 152, 153, 154, 155]),
    //     this.cryptKey
    //   ).toString()
    // );

    // ** Feud ** //

    // 39 : U2FsdGVkX1+uLeYu6I7sy+9II/lqF6fmZe61DgG9IQI=
    // 40 : U2FsdGVkX1+MMF3qnJzfh0eUrgOvQMWlCuteA6+dTg8=
    // 5 : U2FsdGVkX1+1mz/XfoKEKZTcuMKMCwgVhUOwYdwkjUQ=
    // 6 : U2FsdGVkX1/CruV5KmVe0OgC0e++rkiYYM4GGrqswIc=
    // 7 : U2FsdGVkX19Q0q09MAeyuoHhvyyOm9m40aDWNhbhJHY=
    // 8 : U2FsdGVkX186wvN6Rta+m2K/CkA3k2CZ6B6UOkExCA0=
    // 9 : U2FsdGVkX18hcwnA2XYQo6jZRGZgSQIBwMBE3Xi01I8=
    // 10 : U2FsdGVkX18Szz4+LCcovcaDm1s5qIPdOa9vE9XbnZE=
    // 11 : U2FsdGVkX19+cLCrNpjOJnc8O/Szn4LEpVi3Ibri1BY=
    // 12 : U2FsdGVkX18gYZCF8SDBFcTnFl0YLrVS/6miu80iMr8=
    // 13 : U2FsdGVkX194ado+hAm4hhpYISHO3C9ZbHLCUcwJBco=
    // 14 : U2FsdGVkX1/0oTdnp6lN2gUIm73innqOhzLWClIGeK8=
    // 15 : U2FsdGVkX18N6MxjcDd2CHPHx+cHjoXm5u2VhBKjMjQ=
    // 16 : U2FsdGVkX1/qeD5c03Ca+euCATiJSl4bSAixEIbHJaE=
    // 17 : U2FsdGVkX1+mjYAbmxeMawrJrW/7CeGWd4dgjB5F/uM=
    // 18 : U2FsdGVkX18xXQZB38QzNgRL5tt1t1OQ2RlkrYn6bAk=
    // 19 : U2FsdGVkX1+6Q5PD+DJCHrbTqiswH12B1kupOfmDVcI=
    // 20 : U2FsdGVkX18aLh8amRlZCfnDvHMPpvRxNdq9/8lOpTo=
    // 21 : U2FsdGVkX18CJzrOSvNqwfZflw4LQspOqpTkmfhOxic=
    // 22 : U2FsdGVkX18iNSwGPpv1GhCrcLY7b8PGTtYqNvNrhNs=
    // 23 : U2FsdGVkX18WdL+nq3zuzJNN5eIpfrQuG0LBOu86+6U=
    // 24 : U2FsdGVkX1/wM58tf5TG5/4JQaT6KqWF4fjpGiSgs44=
    // 25 : U2FsdGVkX1/BKaOROfUKSEAlpHgn83JxSCzrCd1yJHU=
    // 26 : U2FsdGVkX1/rMpkHd9bjz+r5FbB+LPu4R767UdUQZ7k=
    // 27 : U2FsdGVkX19fTdrrwG9eFj+uAk0SBGsIeftZ1bAXwa0=
    // 28 : U2FsdGVkX19grzLRNoW5/vNAHNxg7yXCjfHJW7iJURk=
    // 31 : U2FsdGVkX19Jc2uU2WLNPDfFmTPsZa5Zj5iiXVIZy+Y=
    // 32 : U2FsdGVkX1/dH/P8cTnq10KQZmlAOBv+kH32RNikuQ8=
    // 33 : U2FsdGVkX1/CIoi2AXgAaOT6pE/qwYoES1B57u770vk=
    // 34 : U2FsdGVkX1+oPP5OjXgcTJXuHeuGqUdY5LFaePIH2ow=
    // 35 : U2FsdGVkX18ehNIbBu/i+DBgwr9gRLFYzr8jKxGKozw=
    // 36 : U2FsdGVkX1+9tTL+w8OsBefWDAvq5e92EGPZJIxT/Jk=
    // 37 : U2FsdGVkX1/MJlMiA8meknvHrcPH/zX7pwukJin2rLU=
    // 38 : U2FsdGVkX1/dMPiPLNx6UygQR/RaS0vgIg/CzLx4sQc=
    // 0 : U2FsdGVkX18STJIASTojaI6HUvePLE9Ma002KkcuiQo=
    // 1 : U2FsdGVkX19T4L7IThWXkUMhHDaVj5j7iikOMG4koZI=
    // 2 : U2FsdGVkX1+msZLETCPVMBOdq4F+WVG4U88DKShZVz0=
    // 3 : U2FsdGVkX1/WqULMTIVC0Bk7YOGXA/UpVRthQK1uCaI=

    // let test = CryptoJS.AES.encrypt(
    //   JSON.stringify(2),
    //   this.cryptKey
    // ).toString();
    // console.log(test);

    // let test2 = CryptoJS.AES.encrypt(
    //   JSON.stringify(3),
    //   this.cryptKey
    // ).toString();
    // console.log(test2);

    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(
    //       "U2FsdGVkX19grzLRNoW5/vNAHNxg7yXCjfHJW7iJURk=",
    //       this.cryptKey
    //     ).toString(CryptoJS.enc.Utf8)
    //   )
    // );

    /* -------------------------------------------------- */

    // let tttt3 = CryptoJS.AES.encrypt(
    //   JSON.stringify(["VSBN", "VSBB", "VR3", "VSQQ", "VR3"]),
    //   this.cryptKey
    // ).toString();
    // console.log(tttt3);

    // let todayVGSCommands = JSON.parse(
    //   CryptoJS.AES.decrypt(
    //     "U2FsdGVkX1/+KZsA2tzQEv4FeIBzAJbD61b5n8QJqtAPzgTfpwMlb5Cl5i51+N6FPq6D6YvnHTLBTRecYVAKyQ==",
    //     this.cryptKey
    //   ).toString(CryptoJS.enc.Utf8)
    // );
    // console.log(newtest);

    // let todayVGSCommands = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // let tttt4 = CryptoJS.AES.encrypt(
    //   JSON.stringify({ god: "Martichoras", taunt: 0 }),
    //   this.cryptKey
    // ).toString();
    // console.log(tttt4);

    // let tttt5 = CryptoJS.AES.encrypt(
    //   JSON.stringify([
    //     "Merlin_2",
    //     "Change_2",
    //     "Cernunnos_3",
    //     "Amaterasu_1",
    //     "HeBo_4",
    //   ]),
    //   this.cryptKey
    // ).toString();
    // console.log(tttt5);

    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(
    //       "U2FsdGVkX1/3zGQWi9Ag5jQPt/EmowzXNM1sNei7COM=",
    //       this.cryptKey
    //     ).toString(CryptoJS.enc.Utf8)
    //   )
    // );

    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(
    //       "U2FsdGVkX1/lsCE/jxDM8wA+dMR/r5HjRe8wR+fMph0=",
    //       this.cryptKey
    //     ).toString(CryptoJS.enc.Utf8)
    //   )
    // );

    // let list = [
    //   "U2FsdGVkX1+le4yIQA3BupGMTnQ7o/d12tf9+Ev1nznnECqjC3rPFn09kW3+KV/bp2teEnyvB9PWDq0XLK6Y9UPphSL/EGGjWQX4eyKUgEc=",
    //   "U2FsdGVkX18ZLSqJ8DpGPmzfm3vf7Xbudqsd6Hw2wTDE5ViD/Qy3E/U1UUoETeGyZ0T28L8Ygp6w88jgvpO7eeLtMgEip4kUE5hhXrQLNkE=",
    //   "U2FsdGVkX194i5uAHElZQLe5+glLyKTLcOkjlwg9/jJbrdDw5RR2SrfNBC0HQ225",
    //   "U2FsdGVkX190yLW1eJ7j1TyFzWYuGAmDLk1TTC+ZawRA2BdeR8evahfjE+U4zh5DjmX5ZJf8nYZ6CmHnF+fwpA==",
    // ];
    // list.forEach((hash) => {
    //   console.log(
    //     JSON.parse(
    //       CryptoJS.AES.decrypt(hash, this.cryptKey).toString(CryptoJS.enc.Utf8)
    //     )
    //   );
    // });
    // console.log(
    //   JSON.parse(
    //     CryptoJS.AES.decrypt(tttt4, this.cryptKey).toString(CryptoJS.enc.Utf8)
    //   )
    // );
    // console.log(this.questions[110]);
    // console.log(this.questions[114]);
    // console.log(new Date().getUTCDay());
    // ** ** \\

    this.fullState = "uncompleted";
    let questionsState = localStorage.getItem("splState");
    if (questionsState) {
      const bytes = CryptoJS.AES.decrypt(questionsState, this.cryptKey);
      if (bytes.toString()) {
        let state = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (
          state === "completed" ||
          state === "surrender" ||
          state === "failed"
        ) {
          this.isCompleted = true;
          this.fullState = state;
        } else {
          this.isCompleted = false;
        }
      } else {
        this.isCompleted = false;
      }
    } else {
      this.isCompleted = false;
    }
  }

  decryptTodayQuestions() {
    let todaySpl = localStorage.getItem("spl_today");
    if (todaySpl) {
      const bytes = CryptoJS.AES.decrypt(todaySpl, this.cryptKey);
      if (bytes.toString()) {
        let splIndexes = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        splIndexes.forEach((splIndex) => {
          if (this.questions[splIndex] === undefined) {
            this.isError = true;
          }
          this.todayQuestions.push(this.questions[splIndex]);
        });
      } else {
        this.getDataTries++;
      }
    } else {
      this.getDataTries++;
    }
    if (this.getDataTries > 0) {
      if (this.getDataTries >= 2) this.isError = true;
      else this.getTodayQuestions();
    }
  }
  getTodayQuestions() {
    // this.store
    //   .collection("gamemodes")
    //   .doc(this.currentD)
    //   .ref.get()
    //   .then((doc) => {
    //     let data = doc.data();
    //     localStorage.setItem("spl_today", data.spl);
    //     this.decryptTodayQuestions();
    //   });

    let allDat: data[] = JSON.parse(JSON.stringify(allData));
    let todayData: data = allDat.filter((d) => {
      return d.day === this.currentD;
    })[0];

    localStorage.setItem("spl_today", todayData.spl);
    this.decryptTodayQuestions();
  }

  @HostListener("window:beforeunload")
  ngOnDestroy(): void {
    // localStorage.setItem(
    //   "completedGodAbilitiesText",
    //   JSON.stringify(this.completedGodAbilities)
    // );
    // let selectedAbiltiesCounter = "selectedAbilitiesText_" + (this.counter + 1);
    // localStorage.setItem(
    //   selectedAbiltiesCounter,
    //   JSON.stringify(this.selectedAbilties)
    // );
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "F6" && !this.isCompleted) {
      this.isCompleted = true;
      this.fullState = "surrender";
      localStorage.setItem(
        "splState",
        CryptoJS.AES.encrypt(
          JSON.stringify("surrender"),
          this.cryptKey
        ).toString()
      );
      this.setHistory();
      this.selectedNumber = 0;
    }
  }

  guessAnswer(number: number) {
    if (!this.completedQuestions.includes("" + (this.counter + 1))) {
      this.completedQuestions.push("" + (this.counter + 1));

      this.isSelecting = true;

      this.selectedNumber = number;

      localStorage.setItem(
        "splQuestion_" + (this.counter + 1),
        JSON.stringify(number)
      );

      localStorage.setItem(
        "completedSPL",
        JSON.stringify(this.completedQuestions)
      );

      if (number === this.currentQuestion.correctAnswer) {
        if (!this.correctAnswers.includes("" + (this.counter + 1))) {
          this.correctAnswers.push("" + (this.counter + 1));
        }
      }

      this.selectedAnswer = number;
      this.cdRef.detectChanges();
      setTimeout(() => {
        if (
          ["1", "2", "3", "4", "5"].every((q) =>
            this.completedQuestions.includes(q)
          )
        ) {
          if (this.correctAnswers.length === 5) {
            this.fullState = "completed";
            localStorage.setItem(
              "splState",
              CryptoJS.AES.encrypt(
                JSON.stringify("completed"),
                this.cryptKey
              ).toString()
            );
          } else {
            this.fullState = "failed";
            localStorage.setItem(
              "splState",
              CryptoJS.AES.encrypt(
                JSON.stringify("failed"),
                this.cryptKey
              ).toString()
            );
          }

          this.setHistory();
          this.isCompleted = true;
          if (document.getElementById("f6"))
            document.getElementById("f6").remove();
        } else {
          while (true) {
            this.counter++;
            if (this.counter > 4) this.counter = 0;
            if (!this.completedQuestions.includes("" + (this.counter + 1)))
              break;
          }

          this.currentQuestion = this.todayQuestions[this.counter];
          this.selectedAnswer = null;
          this.selectedNumber = 0;
        }
        this.isSelecting = false;
      }, 1500);
    }
  }

  changeQuestion(event) {
    if (event === "next") {
      this.counter++;
      if (this.counter > 4) this.counter = 0;
    } else if (event === "previous") {
      this.counter--;
      if (this.counter < 0) this.counter = 4;
    }

    this.currentQuestion = this.todayQuestions[this.counter];
    this.selectedAnswer = null;

    let alreadySelectedAnswer: string;

    try {
      alreadySelectedAnswer = JSON.parse(
        localStorage.getItem("splQuestion_" + (this.counter + 1))
      );
      if (![1, 2, 3, 4].includes(parseInt(alreadySelectedAnswer))) {
        alreadySelectedAnswer = null;
        this.selectedAnswer = null;
        localStorage.removeItem("splQuestion_" + (this.counter + 1));
        localStorage.removeItem("splState");
        let ind = this.completedQuestions.indexOf("" + (this.counter + 1));
        if (ind !== -1) this.completedQuestions.splice(ind, 1);
        let ind2 = this.correctAnswers.indexOf("" + (this.counter + 1));
        if (ind2 !== -1) this.correctAnswers.splice(ind2, 1);

        localStorage.setItem(
          "completedSPL",
          JSON.stringify(this.completedQuestions)
        );
      }
    } catch (error) {
      alreadySelectedAnswer = null;
      this.selectedAnswer = null;
      localStorage.removeItem("splQuestion_" + (this.counter + 1));
      localStorage.removeItem("splState");
      if (this.completedQuestions.includes("" + (this.counter + 1))) {
        let index = this.completedQuestions.indexOf("" + (this.counter + 1));
        this.completedQuestions.splice(index, 1);
        localStorage.setItem(
          "completedSPL",
          JSON.stringify(this.completedQuestions)
        );
      }
      let ind2 = this.correctAnswers.indexOf("" + (this.counter + 1));
      if (ind2 !== -1) this.correctAnswers.splice(ind2, 1);
    }

    if (alreadySelectedAnswer !== null)
      this.selectedAnswer = parseInt(alreadySelectedAnswer);

    this.selectedNumber = 0;
  }

  navigate() {
    this.router.navigateByUrl("/feud");
  }

  setHistory() {
    let history: HistoryPlayer[] = [];
    let historyStorage;
    historyStorage = localStorage.getItem("history");
    if (historyStorage) {
      const bytes = CryptoJS.AES.decrypt(historyStorage, this.cryptKey);
      if (bytes.toString()) {
        history = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let currentDate = localStorage.getItem("currentD");
        let currentHistoryDate = history.find(
          (hist) => hist.date === currentDate
        );
        if (currentHistoryDate) {
          currentHistoryDate.spl = this.fullState === "completed" ? "W" : "L";
        } else {
          history.unshift({
            date: currentDate,
            gods: "-",
            quote: "-",
            abilitySounds: "-",
            abilityText: "-",
            vgs: "-",
            spl: this.fullState === "completed" ? "W" : "L",
            feud: "-",
          });
        }
        localStorage.setItem(
          "history",
          CryptoJS.AES.encrypt(
            JSON.stringify(history),
            this.cryptKey
          ).toString()
        );
      } else {
        localStorage.removeItem("history");
      }
    } else {
      let currentDate = localStorage.getItem("currentD");
      history.push({
        date: currentDate,
        gods: "-",
        quote: "-",
        abilitySounds: "-",
        abilityText: "-",
        vgs: "-",
        spl: this.fullState === "completed" ? "W" : "L",
        feud: "-",
      });
      localStorage.setItem(
        "history",
        CryptoJS.AES.encrypt(JSON.stringify(history), this.cryptKey).toString()
      );
    }
  }
}
