import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
  Renderer2,
  ElementRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { God, gods } from "src/app/models/GodModel";
import { feudQuestions, FeudQuestion } from "src/app/models/Feud";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { startWith, map } from "rxjs/operators";
import * as CryptoJS from "crypto-js";
import { AngularFirestore } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";
import { HistoryPlayer } from "../home/home.component";
import { allData, data } from "src/app/models/data";

@Component({
  selector: "app-feud",
  templateUrl: "./feud.component.html",
  styleUrls: ["./feud.component.scss"],
})
export class FeudComponent implements OnInit, AfterViewInit {
  godCtrl = new FormControl("");
  filteredGods: Observable<God[]>;
  Gods: God[] = JSON.parse(JSON.stringify(gods));
  FeudQuestions: FeudQuestion[] = JSON.parse(JSON.stringify(feudQuestions));

  alreadySelectedGods: string[] = [];

  todayFeud: FeudQuestion;
  todayGodNames: string[];
  totalStrikes = 0;
  correctAnswers = 0;

  isCompleted: boolean = false;
  fullState: string = "uncompleted";

  cryptKey = environment.cryptKey;
  currentD = localStorage.getItem("currentD");
  getDataTries: number = 0;
  isError: boolean = false;

  isSoundPlaying: boolean = false;

  correctSound = new Audio();
  incorrectSound = new Audio();

  isWeekend: boolean = false;

  ngAfterViewInit() {
    if (!this.isWeekend) {
      let alreadyGuessedGodNames: string[] = [];

      try {
        alreadyGuessedGodNames = JSON.parse(
          localStorage.getItem("guessFeudNames")
        );
      } catch (error) {
        localStorage.removeItem("guessFeudNames");
        localStorage.removeItem("feudState");
      }

      if (
        alreadyGuessedGodNames !== null &&
        alreadyGuessedGodNames.length > 0
      ) {
        alreadyGuessedGodNames.forEach((godName) => {
          let godData = this.Gods.find((god) => god.name === godName);
          if (godData !== undefined) {
            let index = this.Gods.indexOf(godData);
            this.Gods.splice(index, 1);
            if (!this.todayGodNames.includes(godName)) {
              this.totalStrikes++;
            }
          } else {
            let index = alreadyGuessedGodNames.indexOf(godName);
            alreadyGuessedGodNames.splice(index, 1);
          }
        });

        this.alreadySelectedGods = alreadyGuessedGodNames;

        this.cdRef.detectChanges();
      }

      if (document.getElementById("f6")) {
        document.getElementById("f6").addEventListener("click", () => {
          this.isCompleted = true;
          this.fullState = "surrender";
          localStorage.setItem(
            "feudState",
            CryptoJS.AES.encrypt(
              JSON.stringify("surrender"),
              this.cryptKey
            ).toString()
          );
          this.setHistory();
          this.godCtrl.setValue("");

          this.todayFeud.answers.forEach((answer, index) => {
            var button1 = document.getElementById("button" + index);
            var votes1 = document.getElementById("vote" + index);

            button1.addEventListener("mouseover", function (e) {
              votes1.style.visibility = "visible";
            });

            button1.addEventListener("mouseout", function (e) {
              votes1.style.visibility = "hidden";
            });
          });
          this.cdRef.detectChanges();
          document.getElementById("f6").remove();
        });
        if (this.isCompleted) {
          document.getElementById("f6").remove();
        }
      }

      if (this.alreadySelectedGods.length > 0 || this.isCompleted) {
        this.todayFeud.answers.forEach((answer, index) => {
          if (
            this.alreadySelectedGods.includes(answer.answer) ||
            this.isCompleted
          ) {
            var button1 = document.getElementById("button" + index);
            var votes1 = document.getElementById("vote" + index);

            button1.addEventListener("mouseover", function (e) {
              votes1.style.visibility = "visible";
            });

            button1.addEventListener("mouseout", function (e) {
              votes1.style.visibility = "hidden";
            });
            if (this.alreadySelectedGods.includes(answer.answer))
              this.correctAnswers++;
          }
        });
        this.cdRef.detectChanges();
      }
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private store: AngularFirestore
  ) {
    this.filteredGods = this.godCtrl.valueChanges.pipe(
      startWith(""),
      map((god) => (god ? this.filterGods(god) : []))
    );
    // this.todayFeud = this.FeudQuestions[3];
    // this.todayGodNames = this.todayFeud.answers.map((answer) => answer.answer);
    this.correctSound.src = "../../../assets/Correct.mp3";
    this.correctSound.volume = 0.5;

    this.incorrectSound.src = "../../../assets/Incorrect.mp3";
    this.incorrectSound.volume = 0.5;

    if (
      new Date().getUTCDay() === 0 ||
      new Date().getUTCDay() === 6
      // new Date().getUTCDay() === 5 ||
      // new Date().getUTCDay() === 4
    ) {
      this.decryptTodayFeud();
    } else this.isWeekend = true;

    // this.decryptTodayFeud();
    // this.isWeekend = false;
  }
  ngOnInit() {
    this.fullState = "uncompleted";
    let quoteState = localStorage.getItem("feudState");
    if (quoteState) {
      const bytes = CryptoJS.AES.decrypt(quoteState, this.cryptKey);
      if (bytes.toString()) {
        let state = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (
          state === "completed" ||
          state === "surrender" ||
          state === "failed"
        ) {
          this.isCompleted = true;
          this.fullState = state;
        } else {
          this.isCompleted = false;
        }
      } else {
        this.isCompleted = false;
      }
    } else {
      this.isCompleted = false;
    }
  }

  decryptTodayFeud() {
    let todayFeud = localStorage.getItem("feud_today");
    if (todayFeud) {
      const bytes = CryptoJS.AES.decrypt(todayFeud, this.cryptKey);
      if (bytes.toString()) {
        let indexFeud = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.todayFeud = this.FeudQuestions[indexFeud];
        this.todayGodNames = this.todayFeud.answers.map(
          (answer) => answer.answer
        );
      } else {
        this.getDataTries++;
      }
    } else {
      this.getDataTries++;
    }
    if (this.getDataTries > 0) {
      if (this.getDataTries >= 2) this.isError = true;
      else this.getTodayFeud();
    }
  }

  getTodayFeud() {
    // this.store
    //   .collection("gamemodes")
    //   .doc(this.currentD)
    //   .ref.get()
    //   .then((doc) => {
    //     let data = doc.data();
    //     localStorage.setItem("feud_today", data.feud);
    //     this.decryptTodayFeud();
    //   });

    let allDat: data[] = JSON.parse(JSON.stringify(allData));
    let todayData: data = allDat.filter((d) => {
      return d.day === this.currentD;
    })[0];

    localStorage.setItem("feud_today", todayData.feud);
    this.decryptTodayFeud();
  }

  private filterGods(value: string): God[] {
    const filterValue = value.toLowerCase();

    return this.Gods.filter((god) =>
      god.name.toLowerCase().startsWith(filterValue)
    );
  }

  selectedGod(event) {
    if (event.isUserInput) {
      let searchedGodName = event.source.value;
      let searchedGod: God = this.Gods.find(
        (god) => god.name === searchedGodName
      );

      this.alreadySelectedGods.unshift(searchedGodName);

      localStorage.setItem(
        "guessFeudNames",
        JSON.stringify(this.alreadySelectedGods)
      );

      let index = this.Gods.indexOf(searchedGod);
      this.Gods.splice(index, 1);

      this.godCtrl.setValue("");

      let checker = (arr, target) => target.every((v) => arr.includes(v));

      if (checker(this.alreadySelectedGods, this.todayGodNames)) {
        this.fullState = "completed";
        localStorage.setItem(
          "feudState",
          CryptoJS.AES.encrypt(
            JSON.stringify("completed"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
      }

      if (!this.todayGodNames.includes(searchedGodName)) {
        this.totalStrikes++;
        this.incorrectSound.load();
        this.incorrectSound.play();
      } else {
        this.correctSound.load();
        this.correctSound.play();

        let index = this.todayGodNames.indexOf(searchedGodName);
        var button = document.getElementById("button" + index);
        var votes = document.getElementById("vote" + index);

        button.addEventListener("mouseover", function (e) {
          votes.style.visibility = "visible";
        });

        button.addEventListener("mouseout", function (e) {
          votes.style.visibility = "hidden";
        });

        this.correctAnswers++;

        this.cdRef.detectChanges();

        if (this.totalStrikes < 5 && this.totalStrikes > 0) this.totalStrikes--;
      }

      if (this.totalStrikes >= 5) {
        this.isCompleted = true;
        this.fullState = "failed";
        localStorage.setItem(
          "feudState",
          CryptoJS.AES.encrypt(
            JSON.stringify("failed"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
        this.godCtrl.setValue("");

        this.todayFeud.answers.forEach((answer, index) => {
          var button1 = document.getElementById("button" + index);
          var votes1 = document.getElementById("vote" + index);

          button1.addEventListener("mouseover", function (e) {
            votes1.style.visibility = "visible";
          });

          button1.addEventListener("mouseout", function (e) {
            votes1.style.visibility = "hidden";
          });
        });
        this.cdRef.detectChanges();
      }

      if (this.correctAnswers === 8) {
        this.isCompleted = true;
        this.fullState = "completed";

        localStorage.setItem(
          "feudState",
          CryptoJS.AES.encrypt(
            JSON.stringify("completed"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
        this.godCtrl.setValue("");

        if (document.getElementById("f6"))
          document.getElementById("f6").remove();
      }
    }
  }

  getBackground(number) {
    if (
      this.alreadySelectedGods.includes(
        this.todayFeud.answers[number].answer
      ) ||
      this.isCompleted
    ) {
      let god = this.todayFeud.answers[number].answer.replace(/\s/g, "");

      if (god === "Chang'e") god = "Change";
      if (god === "HeBo") god = "Hebo";
      if (god === "DaJi") god = "Daji";
      let imgSrc = "../../../assets/icons/" + god;
      if (
        this.alreadySelectedGods.includes(this.todayFeud.answers[number].answer)
      )
        return {
          "background-image": "url(" + imgSrc + ".png)",
          "border-color": "rgba(0,192,61,255)",
        };
      else
        return {
          "background-image": "url(" + imgSrc + ".png)",
          "border-color": "rgba(220,12,25,255)",
        };
    }
    return {
      "background-color": "#070606",
      "border-color": "rgba(220,12,25,255)",
    };
  }

  @HostListener("window:beforeunload")
  ngOnDestroy(): void {
    // let storageGodNames = this.GodsDisplayed.map((god) => god.name);
    // localStorage.setItem("guessQuoteNames", JSON.stringify(storageGodNames));
  }

  navigate() {
    this.router.navigateByUrl("/");
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "F6" && !this.isCompleted) {
      this.isCompleted = true;
      this.fullState = "surrender";
      localStorage.setItem(
        "feudState",
        CryptoJS.AES.encrypt(
          JSON.stringify("surrender"),
          this.cryptKey
        ).toString()
      );
      this.setHistory();
      this.godCtrl.setValue("");

      this.todayFeud.answers.forEach((answer, index) => {
        var button1 = document.getElementById("button" + index);
        var votes1 = document.getElementById("vote" + index);

        button1.addEventListener("mouseover", function (e) {
          votes1.style.visibility = "visible";
        });

        button1.addEventListener("mouseout", function (e) {
          votes1.style.visibility = "hidden";
        });
      });
      this.cdRef.detectChanges();
    }
  }

  setHistory() {
    let history: HistoryPlayer[] = [];
    let historyStorage;

    historyStorage = localStorage.getItem("history");

    if (historyStorage) {
      const bytes = CryptoJS.AES.decrypt(historyStorage, this.cryptKey);
      if (bytes.toString()) {
        history = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let currentDate = localStorage.getItem("currentD");
        let currentHistoryDate = history.find(
          (hist) => hist.date === currentDate
        );
        if (currentHistoryDate) {
          currentHistoryDate.feud = this.fullState === "completed" ? "W" : "L";
        } else {
          history.unshift({
            date: currentDate,
            gods: "-",
            quote: "-",
            abilitySounds: "-",
            abilityText: "-",
            vgs: "-",
            spl: "-",
            feud: this.fullState === "completed" ? "W" : "L",
          });
        }
        localStorage.setItem(
          "history",
          CryptoJS.AES.encrypt(
            JSON.stringify(history),
            this.cryptKey
          ).toString()
        );
      } else {
        localStorage.removeItem("history");
      }
    } else {
      let currentDate = localStorage.getItem("currentD");

      history.push({
        date: currentDate,
        gods: "-",
        quote: "-",
        abilitySounds: "-",
        abilityText: "-",
        vgs: "-",
        spl: "-",
        feud: this.fullState === "completed" ? "W" : "L",
      });
      localStorage.setItem(
        "history",
        CryptoJS.AES.encrypt(JSON.stringify(history), this.cryptKey).toString()
      );
    }
  }
}
