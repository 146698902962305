import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
import { DatePipe } from "@angular/common";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";

export interface HistoryPlayer {
  date: string;
  gods: string;
  quote: string;
  abilitySounds: string;
  abilityText: string;
  vgs: string;
  spl: string;
  feud: string;
}

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef
  ) {}

  text = ["Welcome to Smite-Savvy!", "Multiple Daily Games!", "Enjoy!"];
  counter: number = 0;
  imgCounter: number = 0;
  imgSrc = "../../../assets/GlaNA.jpg";
  intervalID;
  imgInterval;
  discordHref = "https://discord.gg/hvRrpuxXDv";

  countDownInterval;
  countDownDate = new Date();

  cryptKey = environment.cryptKey;
  states = [
    {
      name: "godsState",
      value: "uncompleted",
    },
    {
      name: "quoteState",
      value: "uncompleted",
    },
    {
      name: "abilitySoundsState",
      value: "uncompleted",
    },
    {
      name: "abilityTextState",
      value: "uncompleted",
    },
    {
      name: "vgsState",
      value: "uncompleted",
    },
    {
      name: "splState",
      value: "uncompleted",
    },
    {
      name: "feudState",
      value: "uncompleted",
    },
  ];

  displayedColumns: string[] = [
    "Date",
    "God",
    "Quote",
    "AbilitySound",
    "AbilityText",
    "VGS",
    "SPL",
    "Feud",
  ];
  utcDay = new Date().getUTCDay();

  history: HistoryPlayer[] = [];

  dataSource = new MatTableDataSource<HistoryPlayer>(this.history);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dateFromDay(year, day) {
    var date = new Date(year, 0); // initialize a date in `year-01-01`
    return new Date(date.setDate(day)); // add the number of days
  }

  ngOnInit() {
    // console.log(this.utcDay);
    this.utcDay = new Date().getUTCDay();
    this.counter = 0;
    document.getElementById("changeText").innerHTML = this.text[this.counter];
    this.intervalID = setInterval(this.change, 15000);

    this.countDownDate.setDate(new Date().getDate() + 1);
    this.countDownDate.setUTCHours(0, 0, 0);

    this.checkState();
    this.countDown();

    this.imgInterval = setInterval(this.changeImage, 7000);
  }
  ngAfterViewInit() {
    //
    this.paginator.pageSize = 5;
    this.dataSource.paginator = this.paginator;
    this.setHistory();

    this.countDownInterval = setInterval(() => this.countDown(), 1000);

    setTimeout(() => {
      try {
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
      } catch (e) {
        console.error("ads", e);
      }
    }, 900);
  }

  checkState() {
    this.states.forEach((gamemodeState) => {
      let localStorageState = localStorage.getItem(gamemodeState.name);
      if (localStorageState) {
        const bytes = CryptoJS.AES.decrypt(localStorageState, this.cryptKey);
        if (bytes.toString()) {
          let state = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          if (state === "completed") gamemodeState.value = "completed";
          else gamemodeState.value = "surrender";
        } else gamemodeState.value = "uncompleted";
      } else gamemodeState.value = "uncompleted";
    });
  }
  pad(num) {
    return ("0" + parseInt(num)).substr(-2);
  }

  countDown() {
    var now = new Date();

    var remain: any = (this.countDownDate.getTime() - now.getTime()) / 1000;
    var hh = this.pad((remain / 60 / 60) % 60);
    var mm = this.pad((remain / 60) % 60);
    var ss = this.pad(remain % 60);
    if (parseInt(hh) + parseInt(mm) + parseInt(ss) === 0) {
      location.reload();
    }
    if (document.getElementById("countdown")) {
      document.getElementById("countdown").innerHTML =
        "Next rotation in : " + hh + ":" + mm + ":" + ss;
    }
  }

  change = () => {
    if (document.getElementById("changeText")) {
      document.getElementById("changeText").classList.add("hide");
      setTimeout(() => {
        this.counter++;
        if (this.counter === 3) this.counter = 0;
        document.getElementById("changeText").innerHTML =
          this.text[this.counter];
        document.getElementById("changeText").classList.remove("hide");
      }, 3000);
    }
  };

  changeImage = () => {
    if (document.getElementById("glaImg")) {
      document.getElementById("glaImg").classList.add("hide");
      setTimeout(() => {
        if (this.imgCounter === 0) {
          this.imgSrc = "../../../assets/GlaEU.jpg";
          this.imgCounter++;
          this.discordHref = "https://discord.gg/glaeriasmite";
        } else {
          this.imgSrc = "../../../assets/GlaNA.jpg";
          this.imgCounter = 0;
          this.discordHref = "https://discord.gg/hvRrpuxXDv";
        }
        document.getElementById("glaImg").classList.remove("hide");
      }, 2000);
    }
  };

  navigate(route) {
    this.router.navigateByUrl("/" + route);
  }

  start() {
    this.router.navigateByUrl("/gods");
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalID);
    clearInterval(this.countDownInterval);
    clearInterval(this.imgInterval);
  }

  setHistory() {
    let history: HistoryPlayer[] = [];
    let historyStorage;

    historyStorage = localStorage.getItem("history");

    if (historyStorage) {
      const bytes = CryptoJS.AES.decrypt(historyStorage, this.cryptKey);
      if (bytes.toString()) {
        history = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        history.forEach((hist) => {
          let d = this.dateFromDay(2023, parseInt(hist.date));
          let todayString = this.datePipe.transform(d, "yyyy-MM-dd");
          hist.date = todayString;
        });
        this.history = history;
        this.dataSource.data = this.history;
        this.dataSource.paginator.length = this.history.length;

        this.cdRef.detectChanges();
      } else {
        localStorage.removeItem("history");
      }
    }
  }
}
