import { VGS } from "./../../models/VGS";
import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { startWith, map, pairwise } from "rxjs/operators";
import { vgsLines } from "src/app/models/VGS";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";
import { AngularFirestore } from "@angular/fire/firestore";
import { HistoryPlayer } from "../home/home.component";
import { allData, data } from "src/app/models/data";

@Component({
  selector: "app-vgs",
  templateUrl: "./vgs.component.html",
  styleUrls: ["./vgs.component.scss"],
})
export class VgsComponent implements OnInit {
  vgsCtrl = new FormControl("");
  testval: boolean = false;
  filteredVGS: Observable<VGS[]>;

  vgsLines: VGS[] = JSON.parse(JSON.stringify(vgsLines));

  vgsDisplayed: VGS[] = [];

  displayedColumns: string[] = ["vgs"];

  selectedPlatform: string = "PC";

  counter = 0;
  todayVGSCommands: VGS[] = [];
  fullResults: VGS[] = [];

  completedVGSCommands: string[] = [];

  selectedVGSper: string[] = [];
  currentVGS: VGS;

  dataSource = new MatTableDataSource<VGS>(this.vgsDisplayed);

  isCompleted: boolean = false;
  isSoundPlaying: boolean = false;

  fullState: string = "uncompleted";

  cryptKey = environment.cryptKey;
  currentD = localStorage.getItem("currentD");

  getDataTries: number = 0;
  isError: boolean = false;

  isSelecting: boolean = false;

  vgsCounters = [0, 0, 0, 0, 0];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator.pageSize = 5;
    this.dataSource.paginator = this.paginator;
    this.vgsLines = JSON.parse(JSON.stringify(vgsLines));
    this.vgsDisplayed = [];
    this.selectedVGSper = [];

    let alreadyCompleted: string[] = [];

    try {
      alreadyCompleted = JSON.parse(
        localStorage.getItem("completedVGSCommands")
      );
    } catch (error) {
      localStorage.removeItem("completedVGSCommands");
      localStorage.removeItem("vgsState");
    }

    if (alreadyCompleted !== null && alreadyCompleted.length > 0) {
      this.completedVGSCommands = alreadyCompleted;
      if (
        this.todayVGSCommands
          .map((todayVGSCommand) => todayVGSCommand.command)
          .every((tga) => this.completedVGSCommands.includes(tga))
      ) {
        this.isCompleted = true;
        localStorage.setItem(
          "vgsState",
          CryptoJS.AES.encrypt(
            JSON.stringify("completed"),
            this.cryptKey
          ).toString()
        );
      } else {
        for (let i = 0; i < 5; i++) {
          this.counter++;
          if (this.counter > 4) this.counter = 0;
          if (
            !this.completedVGSCommands.includes(
              this.todayVGSCommands.map(
                (todayVGSCommand) => todayVGSCommand.command
              )[i]
            )
          ) {
            this.counter = i;
            break;
          }
        }
      }
      this.cdRef.detectChanges();
    }
    let alreadySelectedVGSCommands: string[] = [];

    try {
      alreadySelectedVGSCommands = JSON.parse(
        localStorage.getItem("selectedVGSCommands_" + (this.counter + 1))
      );
    } catch (error) {
      localStorage.removeItem("selectedVGSCommands_" + (this.counter + 1));
      localStorage.removeItem("vgsState");
    }

    if (
      alreadySelectedVGSCommands !== null &&
      alreadySelectedVGSCommands.length > 0
    ) {
      this.selectedVGSper = alreadySelectedVGSCommands;
      alreadySelectedVGSCommands.forEach((alreadySelectedVGSCommand) => {
        let vgs = this.vgsLines.find(
          (vgs) => vgs.command === alreadySelectedVGSCommand
        );
        this.vgsDisplayed.push(vgs);
      });

      this.vgsLines = this.vgsLines.filter((vgs) => {
        return !this.selectedVGSper.includes(vgs.command);
      });
      this.dataSource.data = this.vgsDisplayed;
      this.dataSource.paginator.length = this.vgsDisplayed.length;
      this.cdRef.detectChanges();
    }

    this.currentVGS = this.todayVGSCommands[this.counter];

    var svgPlaystation: any = document.getElementById("PS");
    var svgXbox: any = document.getElementById("Xbox");
    var vgsOptions = document.getElementById("vgsOptions");
    if (this.selectedPlatform === "PS" && vgsOptions) {
      vgsOptions.style.color = "transparent";
      vgsOptions.style.pointerEvents = "none";
      vgsOptions.style.userSelect = "none";
      vgsOptions.style.caretColor = "transparent";
      vgsOptions.onkeydown = (e) => {
        var key = e.keyCode ? e.keyCode : e.which;
        if (key !== 8) return false;
        else {
          const oldVal = this.vgsCtrl.value;
          if (oldVal[oldVal.length - 1] === "1") {
            e.preventDefault();
            this.vgsCtrl.setValue(
              this.vgsCtrl.value.substring(0, oldVal.length - 2)
            );
          } else {
            e.preventDefault();
            this.vgsCtrl.setValue(
              this.vgsCtrl.value.substring(0, oldVal.length - 1)
            );
          }
          const val = this.vgsCtrl.value;
          var counter = 0;
          var valArray: string[] = [];
          while (true) {
            if (val[counter + 1]) {
              if (val[counter + 1] !== "1") {
                valArray.push(val[counter]);
                counter++;
              } else {
                valArray.push(val[counter] + "" + val[counter + 1]);
                counter = counter + 2;
              }
            } else {
              if (val[counter]) valArray.push(val[counter]);
              break;
            }
          }
          vgsOptions.style.backgroundImage = "";
          vgsOptions.style.backgroundRepeat = "";
          vgsOptions.style.backgroundPosition = "";
          vgsOptions.style.backgroundSize = "";
          var positionPixels = 0;
          valArray.forEach((val) => {
            if (vgsOptions.style.backgroundImage === "") {
              vgsOptions.style.backgroundImage =
                "url(assets/playstation/" + val + ".png";
              vgsOptions.style.backgroundRepeat = "no-repeat";
              vgsOptions.style.backgroundPosition = positionPixels + "px";
              vgsOptions.style.backgroundSize = "18px";
            } else {
              vgsOptions.style.backgroundImage =
                vgsOptions.style.backgroundImage +
                ",url(assets/playstation/" +
                val +
                ".png";

              vgsOptions.style.backgroundRepeat =
                vgsOptions.style.backgroundRepeat + ",no-repeat";
              vgsOptions.style.backgroundPosition =
                vgsOptions.style.backgroundPosition +
                "," +
                positionPixels +
                "px";
              vgsOptions.style.backgroundSize =
                vgsOptions.style.backgroundSize + ",18px";
            }
            positionPixels = positionPixels + 30;
          });
        }
      };
    } else if (this.selectedPlatform === "Xbox" && vgsOptions) {
      vgsOptions.style.color = "transparent";
      vgsOptions.style.pointerEvents = "none";
      vgsOptions.style.userSelect = "none";
      vgsOptions.style.caretColor = "transparent";
      vgsOptions.onkeydown = (e) => {
        var key = e.keyCode ? e.keyCode : e.which;
        if (key !== 8) return false;
        else {
          const oldVal = this.vgsCtrl.value;
          if (oldVal[oldVal.length - 1] === "1") {
            e.preventDefault();
            this.vgsCtrl.setValue(
              this.vgsCtrl.value.substring(0, oldVal.length - 2)
            );
          } else {
            e.preventDefault();
            this.vgsCtrl.setValue(
              this.vgsCtrl.value.substring(0, oldVal.length - 1)
            );
          }
          const val = this.vgsCtrl.value;
          var counter = 0;
          var valArray: string[] = [];
          while (true) {
            if (val[counter + 1]) {
              if (val[counter + 1] !== "1") {
                valArray.push(val[counter]);
                counter++;
              } else {
                valArray.push(val[counter] + "" + val[counter + 1]);
                counter = counter + 2;
              }
            } else {
              if (val[counter]) valArray.push(val[counter]);
              break;
            }
          }
          vgsOptions.style.backgroundImage = "";
          vgsOptions.style.backgroundRepeat = "";
          vgsOptions.style.backgroundPosition = "";
          var positionPixels = 0;
          valArray.forEach((val) => {
            if (vgsOptions.style.backgroundImage === "") {
              vgsOptions.style.backgroundImage =
                "url(assets/xbox/" + val + ".png";
              vgsOptions.style.backgroundRepeat = "no-repeat";
              vgsOptions.style.backgroundPosition = positionPixels + "px";
            } else {
              vgsOptions.style.backgroundImage =
                vgsOptions.style.backgroundImage +
                ",url(assets/xbox/" +
                val +
                ".png";

              vgsOptions.style.backgroundRepeat =
                vgsOptions.style.backgroundRepeat + ",no-repeat";
              vgsOptions.style.backgroundPosition =
                vgsOptions.style.backgroundPosition +
                "," +
                positionPixels +
                "px";
            }
            positionPixels = positionPixels + 30;
          });
        }
      };
    } else {
      if (vgsOptions) {
        vgsOptions.style.background = "";
        vgsOptions.style.color = "";
        vgsOptions.style.pointerEvents = "";
        vgsOptions.style.userSelect = "";
        vgsOptions.onkeydown = (e) => {
          return true;
        };
      }
    }

    if (svgPlaystation) {
      svgPlaystation.addEventListener(
        "load",
        () => {
          var svgDoc = svgPlaystation.contentDocument;
          var L1 = svgDoc.getElementById("L1");
          var R1 = svgDoc.getElementById("R1");

          var fullCircle = svgDoc.getElementById("circle-stroke");
          var circle = svgDoc.getElementById("circle");

          var fullX = svgDoc.getElementById("x-stroke");
          var x1 = svgDoc.getElementById("x1");
          var x2 = svgDoc.getElementById("x2");

          var fullRectangle = svgDoc.getElementById("rectangle-stroke");
          var rectangle = svgDoc.getElementById("rectangle");

          var fullTriangle = svgDoc.getElementById("triangle-stroke");
          var triangle = svgDoc.getElementById("triangle");

          [
            L1,
            R1,
            fullX,
            x1,
            x2,
            circle,
            fullCircle,
            fullRectangle,
            rectangle,
            fullTriangle,
            triangle,
          ].forEach((element) => {
            element.addEventListener(
              "click",
              (e) => {
                if (this.vgsCtrl.enabled && !this.isSelecting) {
                  var previousVal = this.vgsCtrl.value;

                  if (e.target.id.includes("L1"))
                    this.vgsCtrl.setValue(previousVal + "L1");
                  if (e.target.id.includes("R1"))
                    this.vgsCtrl.setValue(previousVal + "R1");
                  if (e.target.id.includes("circle"))
                    this.vgsCtrl.setValue(previousVal + "C");
                  if (e.target.id.includes("x"))
                    this.vgsCtrl.setValue(previousVal + "X");
                  if (e.target.id.includes("rectangle"))
                    this.vgsCtrl.setValue(previousVal + "R");
                  if (e.target.id.includes("triangle"))
                    this.vgsCtrl.setValue(previousVal + "T");
                  vgsOptions.click();

                  var counter = 0;
                  var val = this.vgsCtrl.value;
                  var valArray: string[] = [];

                  while (true) {
                    if (val[counter + 1]) {
                      if (val[counter + 1] !== "1") {
                        valArray.push(val[counter]);
                        counter++;
                      } else {
                        valArray.push(val[counter] + "" + val[counter + 1]);
                        counter = counter + 2;
                      }
                    } else {
                      if (val[counter]) valArray.push(val[counter]);
                      break;
                    }
                  }

                  var positionPixels = 0;
                  valArray.forEach((val) => {
                    if (vgsOptions.style.backgroundImage === "") {
                      vgsOptions.style.backgroundImage =
                        "url(assets/playstation/" + val + ".png";
                      vgsOptions.style.backgroundRepeat = "no-repeat";
                      vgsOptions.style.backgroundPosition =
                        positionPixels + "px";
                      vgsOptions.style.backgroundSize = "18px";
                    } else {
                      vgsOptions.style.backgroundImage =
                        vgsOptions.style.backgroundImage +
                        ",url(assets/playstation/" +
                        val +
                        ".png";

                      vgsOptions.style.backgroundRepeat =
                        vgsOptions.style.backgroundRepeat + ",no-repeat";
                      vgsOptions.style.backgroundPosition =
                        vgsOptions.style.backgroundPosition +
                        "," +
                        positionPixels +
                        "px";
                      vgsOptions.style.backgroundSize =
                        vgsOptions.style.backgroundSize + ",18px";
                    }
                    positionPixels = positionPixels + 30;
                  });
                }
              },
              false
            );
            element.addEventListener(
              "mouseover",
              function (e) {
                if (e.target.id.includes("L1")) L1.style.fill = "red";
                if (e.target.id.includes("R1")) R1.style.fill = "red";
                if (e.target.id.includes("circle"))
                  fullCircle.style.stroke = "red";
                if (e.target.id.includes("x")) fullX.style.stroke = "red";
                if (e.target.id.includes("rectangle"))
                  fullRectangle.style.stroke = "red";
                if (e.target.id.includes("triangle"))
                  fullTriangle.style.stroke = "red";
              },
              false
            );
            element.addEventListener(
              "mouseout",
              function (e) {
                if (e.target.id.includes("L1")) L1.style.fill = "grey";
                if (e.target.id.includes("R1")) R1.style.fill = "grey";
                if (e.target.id.includes("circle"))
                  fullCircle.style.stroke = "#000000";
                if (e.target.id.includes("x")) fullX.style.stroke = "#000000";
                if (e.target.id.includes("rectangle"))
                  fullRectangle.style.stroke = "#000000";
                if (e.target.id.includes("triangle"))
                  fullTriangle.style.stroke = "#000000";
              },
              false
            );
          });
        },
        false
      );
    }

    if (svgXbox) {
      svgXbox.addEventListener(
        "load",
        () => {
          var svgDoc = svgXbox.contentDocument;

          var LB1 = svgDoc.getElementById("LB1");
          var LB2 = svgDoc.getElementById("LB2");
          var LB3 = svgDoc.getElementById("LB3");
          var LB4 = svgDoc.getElementById("LB4");

          var RB1 = svgDoc.getElementById("RB1");
          var RB2 = svgDoc.getElementById("RB2");
          var RB3 = svgDoc.getElementById("RB3");
          var RB4 = svgDoc.getElementById("RB4");

          var y = svgDoc.getElementById("y");
          var y1 = svgDoc.getElementById("y1");
          var y2 = svgDoc.getElementById("y2");
          var y4 = svgDoc.getElementById("y4");

          var a = svgDoc.getElementById("a");
          var a1 = svgDoc.getElementById("a1");
          var a2 = svgDoc.getElementById("a2");
          var a4 = svgDoc.getElementById("a4");

          var b = svgDoc.getElementById("b");
          var b1 = svgDoc.getElementById("b1");
          var b2 = svgDoc.getElementById("b2");
          var b4 = svgDoc.getElementById("b4");

          var x = svgDoc.getElementById("x");
          var x1 = svgDoc.getElementById("x1");
          var x2 = svgDoc.getElementById("x2");
          var x4 = svgDoc.getElementById("x4");

          [
            LB1,
            LB2,
            LB3,
            LB4,
            RB1,
            RB2,
            RB3,
            RB4,
            y,
            y1,
            y2,
            y4,
            x,
            x1,
            x2,
            x4,
            a,
            a1,
            a2,
            a4,
            b,
            b1,
            b2,
            b4,
          ].forEach((element) => {
            element.addEventListener(
              "click",
              (e) => {
                if (this.vgsCtrl.enabled && !this.isSelecting) {
                  var previousVal = this.vgsCtrl.value;

                  if (e.target.id.includes("LB"))
                    this.vgsCtrl.setValue(previousVal + "LB");
                  else if (e.target.id.includes("RB"))
                    this.vgsCtrl.setValue(previousVal + "RB");
                  else if (e.target.id.includes("a"))
                    this.vgsCtrl.setValue(previousVal + "A");
                  else if (e.target.id.includes("x"))
                    this.vgsCtrl.setValue(previousVal + "X");
                  else if (e.target.id.includes("y"))
                    this.vgsCtrl.setValue(previousVal + "Y");
                  else if (e.target.id.includes("b"))
                    this.vgsCtrl.setValue(previousVal + "B");
                  vgsOptions.click();

                  var counter = 0;
                  var val = this.vgsCtrl.value;
                  var valArray: string[] = [];

                  while (true) {
                    if (val[counter + 1]) {
                      if (
                        (val[counter] === "L" || val[counter] === "R") &&
                        val[counter + 1] === "B"
                      ) {
                        valArray.push(val[counter] + "" + val[counter + 1]);
                        counter = counter + 2;
                      } else {
                        valArray.push(val[counter]);
                        counter++;
                      }
                    } else {
                      if (val[counter]) valArray.push(val[counter]);
                      break;
                    }
                  }

                  var positionPixels = 0;
                  valArray.forEach((val) => {
                    if (vgsOptions.style.backgroundImage === "") {
                      vgsOptions.style.backgroundImage =
                        "url(assets/xbox/" + val + ".png";
                      vgsOptions.style.backgroundRepeat = "no-repeat";
                      vgsOptions.style.backgroundPosition =
                        positionPixels + "px";
                    } else {
                      vgsOptions.style.backgroundImage =
                        vgsOptions.style.backgroundImage +
                        ",url(assets/xbox/" +
                        val +
                        ".png";

                      vgsOptions.style.backgroundRepeat =
                        vgsOptions.style.backgroundRepeat + ",no-repeat";
                      vgsOptions.style.backgroundPosition =
                        vgsOptions.style.backgroundPosition +
                        "," +
                        positionPixels +
                        "px";
                    }
                    positionPixels = positionPixels + 30;
                  });
                }
              },
              false
            );
            element.addEventListener(
              "mouseover",
              function (e) {
                if (e.target.id.includes("LB")) LB1.style.stroke = "red";
                else if (e.target.id.includes("RB")) RB1.style.stroke = "red";
                else if (e.target.id.includes("a")) a1.style.stroke = "red";
                else if (e.target.id.includes("x")) x1.style.stroke = "red";
                else if (e.target.id.includes("y")) y1.style.stroke = "red";
                else if (e.target.id.includes("b")) b1.style.stroke = "red";
              },
              false
            );
            element.addEventListener(
              "mouseout",
              function (e) {
                if (e.target.id.includes("LB")) LB1.style.stroke = "#000000";
                else if (e.target.id.includes("RB"))
                  RB1.style.stroke = "#000000";
                else if (e.target.id.includes("a")) a1.style.stroke = "#000000";
                else if (e.target.id.includes("x")) x1.style.stroke = "#000000";
                else if (e.target.id.includes("y")) y1.style.stroke = "#000000";
                else if (e.target.id.includes("b")) b1.style.stroke = "#000000";
              },
              false
            );
          });
        },
        false
      );
    }

    for (let i = 0; i < 5; i++) {
      let counter = 0;
      let vgsCmds;
      try {
        vgsCmds = JSON.parse(
          localStorage.getItem("selectedVGSCommands_" + (i + 1))
        );
      } catch (error) {
        localStorage.removeItem("selectedVGSCommands_" + (i + 1));
        localStorage.removeItem("vgsState");
      }

      if (vgsCmds !== null && vgsCmds.length > 0) {
        counter = vgsCmds.length;
        this.vgsCounters[i] = counter;
      }
    }
    if (document.getElementById("f6")) {
      document.getElementById("f6").addEventListener("click", () => {
        this.isCompleted = true;
        this.fullState = "surrender";
        localStorage.setItem(
          "vgsState",
          CryptoJS.AES.encrypt(
            JSON.stringify("surrender"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
        this.vgsLines = JSON.parse(JSON.stringify(vgsLines));
        this.selectedVGSper = [];
        this.vgsDisplayed = [];
        this.vgsCtrl.setValue("");
        document.getElementById("f6").remove();
      });
      if (this.isCompleted) {
        document.getElementById("f6").remove();
      }
    }
    this.cdRef.detectChanges();
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private store: AngularFirestore
  ) {
    this.filteredVGS = this.vgsCtrl.valueChanges.pipe(
      startWith(""),
      pairwise(),
      map(([prev, next]) => (next ? this.filterVGS([prev, next]) : []))
    );
    this.decryptTodayVGS();
    this.counter = 0;
    this.currentVGS = this.todayVGSCommands[this.counter];
    let alreadyCompleted: string[] = [];

    try {
      alreadyCompleted = JSON.parse(
        localStorage.getItem("completedVGSCommands")
      );
    } catch (error) {
      localStorage.getItem("completedVGSCommands");
      localStorage.removeItem("vgsState");
    }

    if (alreadyCompleted !== null && alreadyCompleted.length > 0) {
      this.completedVGSCommands = alreadyCompleted;
    }

    let localStorageSelectedPlatform = localStorage.getItem("selectedPlatform");
    if (localStorageSelectedPlatform) {
      if (
        localStorageSelectedPlatform === "PC" ||
        localStorageSelectedPlatform === "PS" ||
        localStorageSelectedPlatform === "Xbox"
      ) {
        this.selectedPlatform = localStorageSelectedPlatform;
      }
    }
  }
  ngOnInit() {
    this.fullState = "uncompleted";
    let vgsState = localStorage.getItem("vgsState");
    if (vgsState) {
      const bytes = CryptoJS.AES.decrypt(vgsState, this.cryptKey);
      if (bytes.toString()) {
        let state = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (state === "completed" || state === "surrender") {
          this.isCompleted = true;
          this.fullState = state;
        } else {
          this.isCompleted = false;
        }
      } else {
        this.isCompleted = false;
      }
    } else {
      this.isCompleted = false;
    }
  }

  decryptTodayVGS() {
    let todayVGS = localStorage.getItem("vgs_today");
    if (todayVGS) {
      const bytes = CryptoJS.AES.decrypt(todayVGS, this.cryptKey);
      if (bytes.toString()) {
        let todayVGSCommands = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        while (true) {
          if (
            todayVGSCommands.length === new Set(todayVGSCommands).size &&
            todayVGSCommands.length === 5
          )
            break;
          else {
            todayVGSCommands = Array.from(new Set(todayVGSCommands));

            const randomVGS = Math.floor(Math.random() * vgsLines.length);
            let vgsCmd = vgsLines[randomVGS];
            todayVGSCommands.push(vgsCmd.command);
          }
        }

        todayVGSCommands.forEach((todayVGSCommand) => {
          let vgs = vgsLines.find((vgs) => vgs.command === todayVGSCommand);
          if (vgs === undefined) {
            this.getDataTries++;
          } else {
            this.todayVGSCommands.push(vgs);
          }
        });
      } else {
        this.getDataTries++;
      }
    } else {
      this.getDataTries++;
    }

    if (this.getDataTries > 0) {
      if (this.getDataTries >= 2) this.isError = true;
      else this.getTodayVGS();
    }
  }
  getTodayVGS() {
    // this.store
    //   .collection("gamemodes")
    //   .doc(this.currentD)
    //   .ref.get()
    //   .then((doc) => {
    //     let data = doc.data();
    //     localStorage.setItem("vgs_today", data.vgs);
    //     this.decryptTodayVGS();
    //   });
    let allDat: data[] = JSON.parse(JSON.stringify(allData));
    let todayData: data = allDat.filter((d) => {
      return d.day === this.currentD;
    })[0];

    localStorage.setItem("vgs_today", todayData.vgs);
    this.decryptTodayVGS();
  }

  private filterVGS(test: string[]): VGS[] {
    let value = test[1];
    if (
      test[0].length === 0 &&
      test[1].length > 1 &&
      test[1] !== "R1" &&
      test[1] !== "L1" &&
      test[1] !== "RB" &&
      test[1] !== "LB"
    ) {
      this.vgsCtrl.setValue("");
      value = test[0];
    }
    const filterValue = value.toLowerCase();
    this.testval = true;
    if (this.selectedPlatform === "PC")
      return this.vgsLines.filter((vgsLine) =>
        vgsLine.command.toLowerCase().startsWith(filterValue)
      );
    else if (this.selectedPlatform === "PS") {
      let filteredLines = this.vgsLines.filter((vgsLine) =>
        vgsLine.commandPS
          .replace(/\s/g, "")
          .toLowerCase()
          .startsWith(filterValue)
      );
      if (!filterValue.endsWith("r")) return filteredLines;
      else {
        return filteredLines.filter((vgsLine) => {
          if (
            vgsLine.commandPS
              .replace(/\s/g, "")
              .toLowerCase()
              .startsWith(filterValue + "1") === false
          )
            return vgsLine;
        });
      }
    } else if (this.selectedPlatform === "Xbox")
      return this.vgsLines.filter((vgsLine) =>
        vgsLine.commandXbox
          .replace(/\s/g, "")
          .toLowerCase()
          .startsWith(filterValue)
      );
  }

  selectedVGS(event) {
    if (event.isUserInput) {
      this.isSelecting = true;
      let searchedVGSCommand = event.source.value;
      let searchedVGS: VGS = this.vgsLines.find(
        (vgs) => vgs.command === searchedVGSCommand
      );

      this.vgsDisplayed.unshift(searchedVGS);
      this.selectedVGSper.unshift(searchedVGSCommand);

      let index = this.vgsLines.indexOf(searchedVGS);
      this.vgsLines.splice(index, 1);

      this.dataSource.data = this.vgsDisplayed;

      this.vgsCtrl.setValue("");
      var vgsOptions = document.getElementById("vgsOptions");
      vgsOptions.style.backgroundImage = "";
      vgsOptions.style.backgroundRepeat = "";
      vgsOptions.style.backgroundPosition = "";

      this.dataSource.paginator.length = this.vgsDisplayed.length;

      let selectedVGSCommandsPer = "selectedVGSCommands_" + (this.counter + 1);
      localStorage.setItem(
        selectedVGSCommandsPer,
        JSON.stringify(this.selectedVGSper)
      );

      this.vgsCounters[this.counter] = this.selectedVGSper.length;

      if (searchedVGS.command === this.currentVGS.command) {
        this.completedVGSCommands.unshift(this.currentVGS.command);

        if (
          this.todayVGSCommands
            .map((todayVGSCommand) => todayVGSCommand.command)
            .every((tga) => this.completedVGSCommands.includes(tga))
        ) {
          this.fullState = "completed";
          localStorage.setItem(
            "vgsState",
            CryptoJS.AES.encrypt(
              JSON.stringify("completed"),
              this.cryptKey
            ).toString()
          );
          this.setHistory();
        }
        setTimeout(() => {
          if (
            this.todayVGSCommands
              .map((todayVGSCommand) => todayVGSCommand.command)
              .every((tga) => this.completedVGSCommands.includes(tga))
          ) {
            this.isCompleted = true;
            if (document.getElementById("f6"))
              document.getElementById("f6").remove();
          } else {
            this.counter++;
            if (this.counter > 4) this.counter = 0;

            this.currentVGS = this.todayVGSCommands[this.counter];
            this.vgsLines = JSON.parse(JSON.stringify(vgsLines));
            this.selectedVGSper = [];
            this.vgsDisplayed = [];
            this.vgsCtrl.setValue("");

            let alreadySelectedVGSCommandsCounter: string[] = [];

            try {
              alreadySelectedVGSCommandsCounter = JSON.parse(
                localStorage.getItem(
                  "selectedVGSCommands_" + (this.counter + 1)
                )
              );
            } catch (error) {
              // localStorage.removeItem("completedVGSCommands");
              localStorage.getItem("selectedVGSCommands_" + (this.counter + 1));
              localStorage.removeItem("vgsState");
              if (this.completedVGSCommands.includes(this.currentVGS.command)) {
                let index = this.completedVGSCommands.indexOf(
                  this.currentVGS.command
                );
                this.completedVGSCommands.splice(index, 1);
                localStorage.setItem(
                  "completedVGSCommands",
                  JSON.stringify(this.completedVGSCommands)
                );
              }
            }

            if (
              alreadySelectedVGSCommandsCounter !== null &&
              alreadySelectedVGSCommandsCounter.length > 0
            ) {
              this.selectedVGSper = alreadySelectedVGSCommandsCounter;
              alreadySelectedVGSCommandsCounter.forEach(
                (alreadySelectedVGSCommand) => {
                  let vgs = this.vgsLines.find(
                    (vgs) => vgs.command === alreadySelectedVGSCommand
                  );
                  this.vgsDisplayed.push(vgs);
                }
              );

              this.vgsLines = this.vgsLines.filter((vgs) => {
                return !this.selectedVGSper.includes(vgs.command);
              });
            }

            this.dataSource.data = this.vgsDisplayed;
            this.dataSource.paginator.length = this.vgsDisplayed.length;
            this.cdRef.detectChanges();
          }
          this.isSelecting = false;
        }, 1100);
      } else {
        this.isSelecting = false;
      }
    }
  }

  @HostListener("window:beforeunload")
  ngOnDestroy(): void {
    localStorage.setItem(
      "completedVGSCommands",
      JSON.stringify(this.completedVGSCommands)
    );
    let selectedVGSCommandsPer = "selectedVGSCommands_" + (this.counter + 1);
    localStorage.setItem(
      selectedVGSCommandsPer,
      JSON.stringify(this.selectedVGSper)
    );
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "F6" && !this.isCompleted) {
      this.isCompleted = true;
      this.fullState = "surrender";
      localStorage.setItem(
        "vgsState",
        CryptoJS.AES.encrypt(
          JSON.stringify("surrender"),
          this.cryptKey
        ).toString()
      );
      this.setHistory();
      this.vgsLines = JSON.parse(JSON.stringify(vgsLines));
      this.selectedVGSper = [];
      this.vgsDisplayed = [];
      this.vgsCtrl.setValue("");
    }
  }

  changeVGS(event) {
    let selectedVGSCommandsPer = "selectedVGSCommands_" + (this.counter + 1);
    localStorage.setItem(
      selectedVGSCommandsPer,
      JSON.stringify(this.selectedVGSper)
    );

    this.selectedVGSper = [];
    this.vgsDisplayed = [];

    if (event === "next") {
      this.counter++;
      if (this.counter > 4) this.counter = 0;
    } else if (event === "previous") {
      this.counter--;
      if (this.counter < 0) this.counter = 4;
    }

    this.vgsLines = JSON.parse(JSON.stringify(vgsLines));

    this.currentVGS = this.todayVGSCommands[this.counter];

    let alreadySelectedVGSCommandsCounter: string[] = [];

    try {
      alreadySelectedVGSCommandsCounter = JSON.parse(
        localStorage.getItem("selectedVGSCommands_" + (this.counter + 1))
      );
    } catch (error) {
      // localStorage.removeItem("completedVGSCommands");
      localStorage.getItem("selectedVGSCommands_" + (this.counter + 1));
      localStorage.removeItem("vgsState");
      if (this.completedVGSCommands.includes(this.currentVGS.command)) {
        let index = this.completedVGSCommands.indexOf(this.currentVGS.command);
        this.completedVGSCommands.splice(index, 1);
        localStorage.setItem(
          "completedVGSCommands",
          JSON.stringify(this.completedVGSCommands)
        );
      }
    }

    if (
      alreadySelectedVGSCommandsCounter !== null &&
      alreadySelectedVGSCommandsCounter.length > 0
    ) {
      this.selectedVGSper = alreadySelectedVGSCommandsCounter;

      alreadySelectedVGSCommandsCounter.forEach((alreadySelectedVGSCommand) => {
        let vgs = this.vgsLines.find(
          (vgs) => vgs.command === alreadySelectedVGSCommand
        );
        this.vgsDisplayed.push(vgs);
      });

      this.vgsLines = this.vgsLines.filter((vgs) => {
        return !this.selectedVGSper.includes(vgs.command);
      });
    }

    this.vgsCtrl.setValue("");
    var vgsOptions = document.getElementById("vgsOptions");
    vgsOptions.style.backgroundImage = "";
    vgsOptions.style.backgroundRepeat = "";
    vgsOptions.style.backgroundPosition = "";
    if (this.completedVGSCommands.includes(this.currentVGS.command))
      this.vgsCtrl.disable();
    else this.vgsCtrl.enable();
    this.dataSource.data = this.vgsDisplayed;
    this.dataSource.paginator.length = this.vgsDisplayed.length;
    this.cdRef.detectChanges();
  }

  navigate() {
    this.router.navigateByUrl("/spl");
  }

  splitPlaystation(playCommand: string) {
    return playCommand.split(" ");
  }
  splitXbox(xboxCommand: string) {
    return xboxCommand.split(" ");
  }
  platformChange(platformSelected) {
    this.vgsCtrl.setValue("");
    localStorage.setItem("selectedPlatform", this.selectedPlatform);

    var vgsOptions = document.getElementById("vgsOptions");
    if (vgsOptions) vgsOptions.style.background = "";
    if (this.selectedPlatform === "PS" && vgsOptions) {
      vgsOptions.style.color = "transparent";
      vgsOptions.style.pointerEvents = "none";
      vgsOptions.style.userSelect = "none";
      vgsOptions.style.caretColor = "transparent";
      vgsOptions.onkeydown = (e) => {
        var key = e.keyCode ? e.keyCode : e.which;
        if (key !== 8) return false;
        else {
          const oldVal = this.vgsCtrl.value;
          if (oldVal[oldVal.length - 1] === "1") {
            e.preventDefault();
            this.vgsCtrl.setValue(
              this.vgsCtrl.value.substring(0, oldVal.length - 2)
            );
          } else {
            e.preventDefault();
            this.vgsCtrl.setValue(
              this.vgsCtrl.value.substring(0, oldVal.length - 1)
            );
          }
          const val = this.vgsCtrl.value;
          var counter = 0;
          var valArray: string[] = [];
          while (true) {
            if (val[counter + 1]) {
              if (val[counter + 1] !== "1") {
                valArray.push(val[counter]);
                counter++;
              } else {
                valArray.push(val[counter] + "" + val[counter + 1]);
                counter = counter + 2;
              }
            } else {
              if (val[counter]) valArray.push(val[counter]);
              break;
            }
          }
          vgsOptions.style.backgroundImage = "";
          vgsOptions.style.backgroundRepeat = "";
          vgsOptions.style.backgroundPosition = "";
          vgsOptions.style.backgroundSize = "";
          var positionPixels = 0;
          valArray.forEach((val) => {
            if (vgsOptions.style.backgroundImage === "") {
              vgsOptions.style.backgroundImage =
                "url(assets/playstation/" + val + ".png";
              vgsOptions.style.backgroundRepeat = "no-repeat";
              vgsOptions.style.backgroundPosition = positionPixels + "px";
              vgsOptions.style.backgroundSize = "18px";
            } else {
              vgsOptions.style.backgroundImage =
                vgsOptions.style.backgroundImage +
                ",url(assets/playstation/" +
                val +
                ".png";

              vgsOptions.style.backgroundRepeat =
                vgsOptions.style.backgroundRepeat + ",no-repeat";
              vgsOptions.style.backgroundPosition =
                vgsOptions.style.backgroundPosition +
                "," +
                positionPixels +
                "px";
              vgsOptions.style.backgroundSize =
                vgsOptions.style.backgroundSize + ",18px";
            }
            positionPixels = positionPixels + 30;
          });
        }
      };
    } else if (this.selectedPlatform === "Xbox" && vgsOptions) {
      vgsOptions.style.color = "transparent";
      vgsOptions.style.pointerEvents = "none";
      vgsOptions.style.userSelect = "none";
      vgsOptions.style.caretColor = "transparent";
      vgsOptions.onkeydown = (e) => {
        var key = e.keyCode ? e.keyCode : e.which;
        if (key !== 8) return false;
        else {
          const oldVal = this.vgsCtrl.value;
          if (
            (oldVal[oldVal.length - 2] === "L" ||
              oldVal[oldVal.length - 2] === "R") &&
            oldVal[oldVal.length - 1] === "B"
          ) {
            e.preventDefault();
            this.vgsCtrl.setValue(
              this.vgsCtrl.value.substring(0, oldVal.length - 2)
            );
          } else {
            e.preventDefault();
            this.vgsCtrl.setValue(
              this.vgsCtrl.value.substring(0, oldVal.length - 1)
            );
          }
          const val = this.vgsCtrl.value;
          var counter = 0;
          var valArray: string[] = [];
          while (true) {
            if (val[counter + 1]) {
              if (
                (val[counter] === "L" || val[counter] === "R") &&
                val[counter + 1] === "B"
              ) {
                valArray.push(val[counter] + "" + val[counter + 1]);
                counter = counter + 2;
              } else {
                valArray.push(val[counter]);
                counter++;
              }
            } else {
              if (val[counter]) valArray.push(val[counter]);
              break;
            }
          }
          vgsOptions.style.backgroundImage = "";
          vgsOptions.style.backgroundRepeat = "";
          vgsOptions.style.backgroundPosition = "";
          var positionPixels = 0;
          valArray.forEach((val) => {
            if (vgsOptions.style.backgroundImage === "") {
              vgsOptions.style.backgroundImage =
                "url(assets/xbox/" + val + ".png";
              vgsOptions.style.backgroundRepeat = "no-repeat";
              vgsOptions.style.backgroundPosition = positionPixels + "px";
            } else {
              vgsOptions.style.backgroundImage =
                vgsOptions.style.backgroundImage +
                ",url(assets/xbox/" +
                val +
                ".png";

              vgsOptions.style.backgroundRepeat =
                vgsOptions.style.backgroundRepeat + ",no-repeat";
              vgsOptions.style.backgroundPosition =
                vgsOptions.style.backgroundPosition +
                "," +
                positionPixels +
                "px";
            }
            positionPixels = positionPixels + 30;
          });
        }
      };
    } else {
      if (vgsOptions) {
        vgsOptions.style.background = "";
        vgsOptions.style.color = "";
        vgsOptions.style.pointerEvents = "";
        vgsOptions.style.userSelect = "";
        vgsOptions.onkeydown = (e) => {
          return true;
        };
      }
    }
  }

  setHistory() {
    let history: HistoryPlayer[] = [];
    let historyStorage;

    historyStorage = localStorage.getItem("history");

    if (historyStorage) {
      const bytes = CryptoJS.AES.decrypt(historyStorage, this.cryptKey);
      if (bytes.toString()) {
        history = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let currentDate = localStorage.getItem("currentD");
        let currentHistoryDate = history.find(
          (hist) => hist.date === currentDate
        );
        if (currentHistoryDate) {
          currentHistoryDate.vgs = this.fullState === "completed" ? "W" : "L";
        } else {
          history.unshift({
            date: currentDate,
            gods: "-",
            quote: "-",
            abilitySounds: "-",
            abilityText: "-",
            vgs: this.fullState === "completed" ? "W" : "L",
            spl: "-",
            feud: "-",
          });
        }
        localStorage.setItem(
          "history",
          CryptoJS.AES.encrypt(
            JSON.stringify(history),
            this.cryptKey
          ).toString()
        );
      } else {
        localStorage.removeItem("history");
      }
    } else {
      let currentDate = localStorage.getItem("currentD");

      history.push({
        date: currentDate,
        gods: "-",
        quote: "-",
        abilitySounds: "-",
        abilityText: "-",
        vgs: this.fullState === "completed" ? "W" : "L",
        spl: "-",
        feud: "-",
      });
      localStorage.setItem(
        "history",
        CryptoJS.AES.encrypt(JSON.stringify(history), this.cryptKey).toString()
      );
    }
  }
}
