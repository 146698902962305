export interface FeudQuestion {
  question: string;
  answers: FeudAnswer[];
}

export interface FeudAnswer {
  answer: string;
  vote: number;
}

const feudQuestions: FeudQuestion[] = [
  {
    // 0 : Completed * 3
    question: "Name the easiest god to play",
    answers: [
      {
        answer: "Neith",
        vote: 24,
      },
      {
        answer: "Kukulkan",
        vote: 11,
      },
      {
        answer: "Anubis",
        vote: 7,
      },
      {
        answer: "Izanami",
        vote: 6,
      },
      {
        answer: "Chaac",
        vote: 6,
      },
      {
        answer: "Fenrir",
        vote: 3,
      },
      {
        answer: "Guan Yu",
        vote: 3,
      },
      {
        answer: "Scylla",
        vote: 3,
      },
    ],
  },
  {
    // 1 : Completed * 3
    question: "Name a god who has the most unique playstyle",
    answers: [
      {
        answer: "The Morrigan",
        vote: 20,
      },
      {
        answer: "Yemoja",
        vote: 18,
      },
      {
        answer: "King Arthur",
        vote: 7,
      },
      {
        answer: "Awilix",
        vote: 7,
      },
      {
        answer: "Cliodhna",
        vote: 6,
      },
      {
        answer: "Janus",
        vote: 5,
      },
      {
        answer: "Ao Kuang",
        vote: 4,
      },
      {
        answer: "Set",
        vote: 4,
      },
    ],
  },
  {
    // 2: Completed * 3
    question: "Name the hardest god to play",
    answers: [
      {
        answer: "The Morrigan",
        vote: 33,
      },
      {
        answer: "Yemoja",
        vote: 23,
      },
      {
        answer: "Set",
        vote: 9,
      },
      {
        answer: "Ullr",
        vote: 9,
      },
      {
        answer: "Susano",
        vote: 5,
      },
      {
        answer: "Heimdallr",
        vote: 3,
      },
      {
        answer: "Yu Huang",
        vote: 3,
      },
      {
        answer: "Tiamat",
        vote: 3,
      },
    ],
  },
  {
    // 3 : Completed * 3
    question:
      "Name which god is the most versatile, able to play multiple roles effectively",
    answers: [
      {
        answer: "Set",
        vote: 29,
      },
      {
        answer: "Baron Samedi",
        vote: 9,
      },
      {
        answer: "Fenrir",
        vote: 5,
      },
      {
        answer: "Hercules",
        vote: 4,
      },
      {
        answer: "Ne Zha",
        vote: 4,
      },
      {
        answer: "Hades",
        vote: 4,
      },
      {
        answer: "Hel",
        vote: 3,
      },
      {
        answer: "Erlang Shen",
        vote: 3,
      },
    ],
  },
  {
    // 4 : Completed * 2
    question: "Name which god is the most impactful in the late game",
    answers: [
      {
        answer: "Kali",
        vote: 17,
      },
      {
        answer: "Freya",
        vote: 6,
      },
      {
        answer: "Mercury",
        vote: 6,
      },
      {
        answer: "He Bo",
        vote: 6,
      },
      {
        answer: "Merlin",
        vote: 6,
      },
      {
        answer: "Yemoja",
        vote: 6,
      },
      {
        answer: "Jing Wei",
        vote: 5,
      },
      {
        answer: "Scylla",
        vote: 5,
      },
    ],
  },
  {
    // 5 : Completed * 3
    question: "Name which god has the most game-changing ultimate",
    answers: [
      {
        answer: "Yemoja",
        vote: 17,
      },
      {
        answer: "Olorun",
        vote: 15,
      },
      {
        answer: "The Morrigan",
        vote: 12,
      },
      {
        answer: "Hun Batz",
        vote: 9,
      },
      {
        answer: "Horus",
        vote: 6,
      },
      {
        answer: "Scylla",
        vote: 6,
      },
      {
        answer: "Terra",
        vote: 5,
      },
      {
        answer: "Maui",
        vote: 5,
      },
    ],
  },
  {
    // 6 : Completed * 3
    question: "Name the most fun god to play in Arena",
    answers: [
      {
        answer: "Ares",
        vote: 10,
      },
      {
        answer: "Zeus",
        vote: 8,
      },
      {
        answer: "Scylla",
        vote: 7,
      },
      {
        answer: "Thanatos",
        vote: 7,
      },
      {
        answer: "Merlin",
        vote: 5,
      },
      {
        answer: "Nox",
        vote: 5,
      },
      {
        answer: "Vulcan",
        vote: 4,
      },
      {
        answer: "Anubis",
        vote: 4,
      },
    ],
  },
  {
    // 7 : Completed * 3
    question: "Name the most fun god to play in Assault",
    answers: [
      {
        answer: "Ah Puch",
        vote: 20,
      },
      {
        answer: "Zeus",
        vote: 7,
      },
      {
        answer: "Hel",
        vote: 7,
      },
      {
        answer: "Hercules",
        vote: 6,
      },
      {
        answer: "Nu Wa",
        vote: 5,
      },
      {
        answer: "Thoth",
        vote: 3,
      },
      {
        answer: "Ares",
        vote: 3,
      },
      {
        answer: "Ra",
        vote: 3,
      },
    ],
  },
  {
    // 8 : Completed * 3
    question: "Name which god has the best escape ability",
    answers: [
      {
        answer: "Heimdallr",
        vote: 14,
      },
      {
        answer: "Janus",
        vote: 13,
      },
      {
        answer: "Charybdis",
        vote: 13,
      },
      {
        answer: "Jing Wei",
        vote: 5,
      },
      {
        answer: "Serqet",
        vote: 5,
      },
      {
        answer: "Yu Huang",
        vote: 5,
      },
      {
        answer: "Sun Wukong",
        vote: 5,
      },
      {
        answer: "Jormungandr",
        vote: 4,
      },
    ],
  },
  {
    // 9 : Completed * 3
    question: "Name which god is the hardest to play against",
    answers: [
      {
        answer: "Nox",
        vote: 9,
      },
      {
        answer: "Hercules",
        vote: 8,
      },
      {
        answer: "Vamana",
        vote: 7,
      },
      {
        answer: "Tyr",
        vote: 5,
      },
      {
        answer: "Susano",
        vote: 5,
      },
      {
        answer: "Loki",
        vote: 5,
      },
      {
        answer: "Aphrodite",
        vote: 4,
      },
      {
        answer: "Yemoja",
        vote: 4,
      },
    ],
  },
  {
    // 10 : Completed * 3
    question: "Name which god is the best at zoning enemies?",
    answers: [
      {
        answer: "Hercules",
        vote: 15,
      },
      {
        answer: "Yemoja",
        vote: 10,
      },
      {
        answer: "Ah Puch",
        vote: 8,
      },
      {
        answer: "Ymir",
        vote: 8,
      },
      {
        answer: "Merlin",
        vote: 6,
      },
      {
        answer: "Cu Chulainn",
        vote: 4,
      },
      {
        answer: "Cthulhu",
        vote: 4,
      },
      {
        answer: "Tyr",
        vote: 4,
      },
    ],
  },
  {
    // 11 : Completed * 3
    question: "Which god is the best at peeling for teammates?",
    answers: [
      {
        answer: "Khepri",
        vote: 27,
      },
      {
        answer: "Geb",
        vote: 22,
      },
      {
        answer: "Yemoja",
        vote: 8,
      },
      {
        answer: "Ganesha",
        vote: 7,
      },
      {
        answer: "Maui",
        vote: 7,
      },
      {
        answer: "Terra",
        vote: 4,
      },
      {
        answer: "Horus",
        vote: 3,
      },
      {
        answer: "Sylvanus",
        vote: 3,
      },
    ],
  },
  {
    // 12 : Completed * 3
    question: "Which god is the most annoying in Arena?",
    answers: [
      {
        answer: "Nox",
        vote: 26,
      },
      {
        answer: "Ares",
        vote: 13,
      },
      {
        answer: "Zeus",
        vote: 12,
      },
      {
        answer: "Loki",
        vote: 7,
      },
      {
        answer: "Khepri",
        vote: 4,
      },
      {
        answer: "Nu Wa",
        vote: 4,
      },
      {
        answer: "Ah Puch",
        vote: 4,
      },
      {
        answer: "Aphrodite",
        vote: 4,
      },
    ],
  },
  {
    // 13 : Completed * 3
    question: "Which god is the most annoying in Assault?",
    answers: [
      {
        answer: "Ah Puch",
        vote: 24,
      },
      {
        answer: "Hel",
        vote: 19,
      },
      {
        answer: "Aphrodite",
        vote: 13,
      },
      {
        answer: "Zeus",
        vote: 6,
      },
      {
        answer: "Yemoja",
        vote: 5,
      },
      {
        answer: "Nu Wa",
        vote: 5,
      },
      {
        answer: "Nox",
        vote: 4,
      },
      {
        answer: "Ares",
        vote: 3,
      },
    ],
  },
  {
    // 14 : Completed * 3
    question: "Which god is the best at poking?",
    answers: [
      {
        answer: "Thoth",
        vote: 44,
      },
      {
        answer: "Agni",
        vote: 8,
      },
      {
        answer: "Vulcan",
        vote: 6,
      },
      {
        answer: "Merlin",
        vote: 5,
      },
      {
        answer: "Morgan Le Fay",
        vote: 4,
      },
      {
        answer: "Izanami",
        vote: 4,
      },
      {
        answer: "He Bo",
        vote: 3,
      },
      {
        answer: "Ah Muzen Cab",
        vote: 3,
      },
    ],
  },
  {
    // 15 : Completed * 3
    question: "Which god is the most effective at stealing kills?",
    answers: [
      {
        answer: "Nu Wa",
        vote: 25,
      },
      {
        answer: "Thanatos",
        vote: 16,
      },
      {
        answer: "Loki",
        vote: 9,
      },
      {
        answer: "Zeus",
        vote: 5,
      },
      {
        answer: "Scylla",
        vote: 5,
      },
      {
        answer: "Neith",
        vote: 5,
      },
      {
        answer: "The Morrigan",
        vote: 4,
      },
      {
        answer: "Aphrodite",
        vote: 4,
      },
    ],
  },
  {
    // 16 : Completed * 3
    question: "Which god is the easiest to play against?",
    answers: [
      {
        answer: "Neith",
        vote: 18,
      },
      {
        answer: "Ra",
        vote: 11,
      },
      {
        answer: "Artemis",
        vote: 8,
      },
      {
        answer: "Anubis",
        vote: 6,
      },
      {
        answer: "Nu Wa",
        vote: 4,
      },
      {
        answer: "Zeus",
        vote: 4,
      },
      {
        answer: "Arachne",
        vote: 3,
      },
      {
        answer: "Ah Puch",
        vote: 3,
      },
    ],
  },
  {
    // 17 : Completed * 3
    question: "Which god is the best at initiating teamfights?",
    answers: [
      {
        answer: "Ares",
        vote: 16,
      },
      {
        answer: "Hun Batz",
        vote: 10,
      },
      {
        answer: "Hercules",
        vote: 9,
      },
      {
        answer: "Athena",
        vote: 6,
      },
      {
        answer: "Geb",
        vote: 6,
      },
      {
        answer: "Cthulhu",
        vote: 5,
      },
      {
        answer: "Odin",
        vote: 5,
      },
      {
        answer: "Thor",
        vote: 5,
      },
    ],
  },
  {
    // 18 : Completed * 3
    question: "Which god is the most fun to play?",
    answers: [
      {
        answer: "Janus",
        vote: 12,
      },
      {
        answer: "Thor",
        vote: 7,
      },
      {
        answer: "Ullr",
        vote: 5,
      },
      {
        answer: "King Arthur",
        vote: 4,
      },
      {
        answer: "Baron Samedi",
        vote: 4,
      },
      {
        answer: "Susano",
        vote: 4,
      },
      {
        answer: "Merlin",
        vote: 4,
      },
      {
        answer: "Anubis",
        vote: 4,
      },
    ],
  },
  {
    // 19 : Completed * 3
    question: "Which god has the best dance emote?",
    answers: [
      {
        answer: "Hercules",
        vote: 14,
      },
      {
        answer: "Ymir",
        vote: 8,
      },
      {
        answer: "Janus",
        vote: 6,
      },
      {
        answer: "Kuzenbo",
        vote: 6,
      },
      {
        answer: "Khepri",
        vote: 6,
      },
      {
        answer: "Hades",
        vote: 5,
      },
      {
        answer: "Neith",
        vote: 5,
      },
      {
        answer: "Anubis",
        vote: 5,
      },
    ],
  },
  {
    // 20 : Completed * 3
    question: "Which god has the best skin ?",
    answers: [
      {
        answer: "Cthulhu",
        vote: 13,
      },
      {
        answer: "Thanatos",
        vote: 12,
      },
      {
        answer: "Ullr",
        vote: 8,
      },
      {
        answer: "Merlin",
        vote: 4,
      },
      {
        answer: "Aphrodite",
        vote: 4,
      },
      {
        answer: "Tyr",
        vote: 4,
      },
      {
        answer: "Nu Wa",
        vote: 3,
      },
      {
        answer: "Kukulkan",
        vote: 3,
      },
    ],
  },
  {
    // 21 : Completed * 3
    question: "Name the god with the most satisfying ability to land ?",
    answers: [
      {
        answer: "Ra",
        vote: 31,
      },
      {
        answer: "Rama",
        vote: 10,
      },
      {
        answer: "Janus",
        vote: 8,
      },
      {
        answer: "Sylvanus",
        vote: 6,
      },
      {
        answer: "Hou Yi",
        vote: 6,
      },
      {
        answer: "Scylla",
        vote: 4,
      },
      {
        answer: "Thoth",
        vote: 4,
      },
      {
        answer: "Vulcan",
        vote: 4,
      },
    ],
  },
  {
    // 22 : Completed * 3
    question: "Which god has the coolest looking ultimate ability ?",
    answers: [
      {
        answer: "Cthulhu",
        vote: 30,
      },
      {
        answer: "Maui",
        vote: 8,
      },
      {
        answer: "Surtr",
        vote: 7,
      },
      {
        answer: "Poseidon",
        vote: 6,
      },
      {
        answer: "Ao Kuang",
        vote: 5,
      },
      {
        answer: "Yu Huang",
        vote: 5,
      },
      {
        answer: "Jormungandr",
        vote: 4,
      },
      {
        answer: "Heimdallr",
        vote: 4,
      },
    ],
  },
  {
    // 23 : Completed * 3
    question: "Which god has the best voice pack? (no skins)",
    answers: [
      {
        answer: "Khepri",
        vote: 11,
      },
      {
        answer: "Chaac",
        vote: 7,
      },
      {
        answer: "Baron Samedi",
        vote: 6,
      },
      {
        answer: "Ratatoskr",
        vote: 6,
      },
      {
        answer: "Ra",
        vote: 4,
      },
      {
        answer: "Jormungandr",
        vote: 4,
      },
      {
        answer: "King Arthur",
        vote: 4,
      },
      {
        answer: "Ymir",
        vote: 3,
      },
    ],
  },
  {
    // 24 : Completed * 3
    question: "Which god has the best passive ?",
    answers: [
      {
        answer: "Kali",
        vote: 19,
      },
      {
        answer: "Cliodhna",
        vote: 9,
      },
      {
        answer: "Yemoja",
        vote: 6,
      },
      {
        answer: "Chronos",
        vote: 5,
      },
      {
        answer: "Tyr",
        vote: 5,
      },
      {
        answer: "Jormungandr",
        vote: 4,
      },
      {
        answer: "Sol",
        vote: 3,
      },
      {
        answer: "Baron Samedi",
        vote: 3,
      },
    ],
  },
  {
    // 25 : Completed * 3
    question: "Which god has the worst passive ?",
    answers: [
      {
        answer: "Persephone",
        vote: 19,
      },
      {
        answer: "Hou Yi",
        vote: 16,
      },
      {
        answer: "Nox",
        vote: 7,
      },
      {
        answer: "Rama",
        vote: 6,
      },
      {
        answer: "Gilgamesh",
        vote: 5,
      },
      {
        answer: "Izanami",
        vote: 4,
      },
      {
        answer: "Chang'e",
        vote: 4,
      },
      {
        answer: "Ganesha",
        vote: 4,
      },
    ],
  },
  {
    // 26 : Completed * 3
    question: "Which god is the easiest to get a penta kill on ?",
    answers: [
      {
        answer: "Scylla",
        vote: 27,
      },
      {
        answer: "Kali",
        vote: 24,
      },
      {
        answer: "Thanatos",
        vote: 20,
      },
      {
        answer: "He Bo",
        vote: 4,
      },
      {
        answer: "Artemis",
        vote: 4,
      },
      {
        answer: "Bakasura",
        vote: 4,
      },
      {
        answer: "Achilles",
        vote: 3,
      },
      {
        answer: "Mercury",
        vote: 3,
      },
    ],
  },
  {
    // 27 : Completed * 3
    question: "Who is the easiest god to play for beginners ?",
    answers: [
      {
        answer: "Neith",
        vote: 29,
      },
      {
        answer: "Kukulkan",
        vote: 18,
      },
      {
        answer: "Ra",
        vote: 6,
      },
      {
        answer: "Poseidon",
        vote: 5,
      },
      {
        answer: "Izanami",
        vote: 4,
      },
      {
        answer: "Ymir",
        vote: 3,
      },
      {
        answer: "Scylla",
        vote: 2,
      },
      {
        answer: "Chaac",
        vote: 2,
      },
    ],
  },
  {
    // 28 : Completed * 3
    question: "Which god is the best at carrying games ?",
    answers: [
      {
        answer: "Thanatos",
        vote: 12,
      },
      {
        answer: "Kali",
        vote: 10,
      },
      {
        answer: "Bakasura",
        vote: 10,
      },
      {
        answer: "Susano",
        vote: 9,
      },
      {
        answer: "Mercury",
        vote: 9,
      },
      {
        answer: "He Bo",
        vote: 5,
      },
      {
        answer: "Thor",
        vote: 4,
      },
      {
        answer: "Rama",
        vote: 4,
      },
    ],
  },
  {
    // 29 : Completed
    question: "Which god do you think needs a buff the most?",
    answers: [
      {
        answer: "Erlang Shen",
        vote: 7,
      },
      {
        answer: "Kumbhakarna",
        vote: 4,
      },
      {
        answer: "Charybdis",
        vote: 4,
      },
      {
        answer: "Persephone",
        vote: 4,
      },
      {
        answer: "Tsukuyomi",
        vote: 4,
      },
      {
        answer: "Hun Batz",
        vote: 4,
      },
      {
        answer: "Nike",
        vote: 3,
      },
      {
        answer: "Cupid",
        vote: 3,
      },
    ],
  },
  {
    // 30 : Completed
    question: "Which god do you think is the most balanced currently?",
    answers: [
      {
        answer: "Agni",
        vote: 10,
      },
      {
        answer: "Anhur",
        vote: 7,
      },
      {
        answer: "Chaac",
        vote: 5,
      },
      {
        answer: "Rama",
        vote: 5,
      },
      {
        answer: "Ra",
        vote: 4,
      },
      {
        answer: "Ganesha",
        vote: 4,
      },
      {
        answer: "Fenrir",
        vote: 4,
      },
      {
        answer: "Baron Samedi",
        vote: 4,
      },
    ],
  },
  {
    // 31 : Completed * 3
    question: "Which god is the most overrated?",
    answers: [
      {
        answer: "Ao Kuang",
        vote: 7,
      },
      {
        answer: "Maman Brigitte",
        vote: 6,
      },
      {
        answer: "Anubis",
        vote: 6,
      },
      {
        answer: "Aphrodite",
        vote: 6,
      },
      {
        answer: "Loki",
        vote: 5,
      },
      {
        answer: "Kali",
        vote: 4,
      },
      {
        answer: "Martichoras",
        vote: 4,
      },
      {
        answer: "Thanatos",
        vote: 4,
      },
    ],
  },
  {
    // 32 : Completed * 3
    question: "Which god is the most underrated?",
    answers: [
      {
        answer: "Erlang Shen",
        vote: 5,
      },
      {
        answer: "Fafnir",
        vote: 5,
      },
      {
        answer: "Ix Chel",
        vote: 4,
      },
      {
        answer: "Jormungandr",
        vote: 4,
      },
      {
        answer: "Chaac",
        vote: 3,
      },
      {
        answer: "Danzaburou",
        vote: 3,
      },
      {
        answer: "Pele",
        vote: 3,
      },
      {
        answer: "Heimdallr",
        vote: 3,
      },
    ],
  },
  {
    // 33 : Completed * 3
    question: "Which god has the worst skin?",
    answers: [
      {
        answer: "Zeus",
        vote: 7,
      },
      {
        answer: "Fenrir",
        vote: 6,
      },
      {
        answer: "Cupid",
        vote: 6,
      },
      {
        answer: "Ra",
        vote: 5,
      },
      {
        answer: "He Bo",
        vote: 5,
      },
      {
        answer: "Neith",
        vote: 5,
      },
      {
        answer: "Bastet",
        vote: 4,
      },
      {
        answer: "Vamana",
        vote: 4,
      },
    ],
  },
  {
    // 34 : Completed * 3
    question: "Which god has the most memorable voice lines?",
    answers: [
      {
        answer: "Chaac",
        vote: 25,
      },
      {
        answer: "Ymir",
        vote: 4,
      },
      {
        answer: "Anubis",
        vote: 3,
      },
      {
        answer: "Anhur",
        vote: 3,
      },
      {
        answer: "Kuzenbo",
        vote: 3,
      },
      {
        answer: "Cupid",
        vote: 3,
      },
      {
        answer: "King Arthur",
        vote: 3,
      },
      {
        answer: "Scylla",
        vote: 3,
      },
    ],
  },
  {
    // 35 : Completed * 3
    question:
      "Which god is the most challenging to play effectively but rewarding when mastered ?",
    answers: [
      {
        answer: "Yemoja",
        vote: 18,
      },
      {
        answer: "Set",
        vote: 13,
      },
      {
        answer: "Ullr",
        vote: 12,
      },
      {
        answer: "The Morrigan",
        vote: 10,
      },
      {
        answer: "Susano",
        vote: 6,
      },
      {
        answer: "King Arthur",
        vote: 4,
      },
      {
        answer: "Awilix",
        vote: 4,
      },
      {
        answer: "Heimdallr",
        vote: 3,
      },
    ],
  },
  {
    // 36 : Completed * 3
    question:
      "Which god is the best at creative and unexpected strategies in conquest?",
    answers: [
      {
        answer: "Horus",
        vote: 26,
      },
      {
        answer: "The Morrigan",
        vote: 10,
      },
      {
        answer: "Cliodhna",
        vote: 5,
      },
      {
        answer: "Yemoja",
        vote: 4,
      },
      {
        answer: "Arachne",
        vote: 4,
      },
      {
        answer: "Maui",
        vote: 4,
      },
      {
        answer: "Janus",
        vote: 3,
      },
      {
        answer: "Heimdallr",
        vote: 3,
      },
    ],
  },
  {
    // 37 : Completed * 3
    question:
      "Which god has the most potential for unexpected comebacks in a match ?",
    answers: [
      {
        answer: "Kali",
        vote: 14,
      },
      {
        answer: "Ao Kuang",
        vote: 7,
      },
      {
        answer: "Freya",
        vote: 7,
      },
      {
        answer: "Chronos",
        vote: 6,
      },
      {
        answer: "Olorun",
        vote: 6,
      },
      {
        answer: "Scylla",
        vote: 5,
      },
      {
        answer: "Mercury",
        vote: 4,
      },
      {
        answer: "Janus",
        vote: 4,
      },
    ],
  },
  {
    // 38 : Completed * 3

    question: "Which god is the most annoying to counter ?",
    answers: [
      {
        answer: "Aphrodite",
        vote: 10,
      },
      {
        answer: "Maman Brigitte",
        vote: 6,
      },
      {
        answer: "Loki",
        vote: 6,
      },
      {
        answer: "Nox",
        vote: 5,
      },
      {
        answer: "Ao Kuang",
        vote: 4,
      },
      {
        answer: "Ganesha",
        vote: 4,
      },
      {
        answer: "Athena",
        vote: 4,
      },
      {
        answer: "Kali",
        vote: 4,
      },
    ],
  },
  {
    // 39 : Completed * 3
    question: "Which god is the most versatile across different game modes ?",
    answers: [
      {
        answer: "Anubis",
        vote: 7,
      },
      {
        answer: "Achilles",
        vote: 6,
      },
      {
        answer: "Aphrodite",
        vote: 5,
      },
      {
        answer: "Agni",
        vote: 5,
      },
      {
        answer: "Set",
        vote: 5,
      },
      {
        answer: "Hades",
        vote: 4,
      },
      {
        answer: "Hercules",
        vote: 4,
      },
      {
        answer: "Ah Puch",
        vote: 4,
      },
    ],
  },
  {
    // 40 : Completed * 3
    question: "Which god has the most potential to create highlight plays?",
    answers: [
      {
        answer: "Scylla",
        vote: 11,
      },
      {
        answer: "Ra",
        vote: 7,
      },
      {
        answer: "Thanatos",
        vote: 7,
      },
      {
        answer: "Tyr",
        vote: 6,
      },
      {
        answer: "Janus",
        vote: 5,
      },
      {
        answer: "Hou Yi",
        vote: 5,
      },
      {
        answer: "Thor",
        vote: 5,
      },
      {
        answer: "Susano",
        vote: 5,
      },
    ],
  },
];

export { feudQuestions };
