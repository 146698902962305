export interface God {
  name: string;
  pantheon: string;
  type: string;
  range: string;
  positions: string[];
  pros: string[];
  image: string;
  NJ_Voiceline?: string;
  taunts?: GodTaunt[];
  abilities?: Ability[];
}

var tauntsPath = "assets/taunts";
export interface GodTaunt {
  taunt: string;
  voiceline: string;
}
export interface Ability {
  name: string;
  images?: string[];
}
const gods: God[] = [
  {
    name: "Achilles",
    pantheon: "Greek",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: ["High Single Target Damage", "High Mobility"],
    image: "assets/icons/Achilles.png",
    NJ_Voiceline: "assets/voicelines_NJ/Achilles.aac",
    taunts: [
      {
        taunt: "You look out of breath. Need me to slow down?",
        voiceline: `${tauntsPath}/Achilles_Taunt_1.aac`,
      },
      {
        taunt: "Is that all? I know you can do better.",
        voiceline: `${tauntsPath}/Achilles_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Shield of Achilles",
      },
      {
        name: "Radiant Glory",
      },
      {
        name: "Combat Dodge",
      },
      {
        name: "Fatal Strike",
      },
    ],
  },
  {
    name: "Agni",
    pantheon: "Hindu",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage"],
    image: "assets/icons/Agni.png",
    NJ_Voiceline: "assets/voicelines_NJ/Agni.aac",
    taunts: [
      {
        taunt: "Like a match, I burn more than once!",
        voiceline: `${tauntsPath}/Agni_Taunt_1.aac`,
      },
      {
        taunt: "Feel that burn!",
        voiceline: `${tauntsPath}/Agni_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Noxious Fumes",
      },
      {
        name: "Flame Wave",
      },
      {
        name: "Path of Flames",
      },
      {
        name: "Rain Fire",
      },
    ],
  },
  {
    name: "Ah Muzen Cab",
    pantheon: "Maya",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Single Target Damage", "High Attack Speed"],
    image: "assets/icons/AhMuzenCab.png",
    NJ_Voiceline: "assets/voicelines_NJ/AhMuzenCab.aac",
    taunts: [
      {
        taunt: "To bee or not to bee, you don't get a choice!",
        voiceline: `${tauntsPath}/AhMuzenCab_Taunt_1.aac`,
      },
      {
        taunt: "Ay, ay, ay, that was close!",
        voiceline: `${tauntsPath}/AhMuzenCab_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Hive",
      },
      {
        name: "Swarm",
      },
      {
        name: "Honey",
      },
      {
        name: "Stinger",
      },
    ],
  },
  {
    name: "Ah Puch",
    pantheon: "Maya",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage"],
    image: "assets/icons/AhPuch.png",
    NJ_Voiceline: "assets/voicelines_NJ/AhPuch.aac",
    taunts: [
      {
        taunt:
          "I will offer you a choice: Do you want an empty life, or a meaningful death?",
        voiceline: `${tauntsPath}/AhPuch_Taunt_1.aac`,
      },
      {
        taunt: "Surely... you did not think you... could win!",
        voiceline: `${tauntsPath}/AhPuch_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Undead Surge",
      },
      {
        name: "Corpse Explosion",
      },
      {
        name: "Fleeting Breath",
      },
      {
        name: "Empty the Crypts",
      },
    ],
  },
  {
    name: "Amaterasu",
    pantheon: "Japanese",
    type: "Physical",
    range: "Melee",
    positions: ["Solo"],
    pros: ["High Mobility", "High Area Damage"],
    image: "assets/icons/Amaterasu.png",
    NJ_Voiceline: "assets/voicelines_NJ/Amaterasu.aac",
    taunts: [
      {
        taunt: "I bring the light, and I can take it away.",
        voiceline: `${tauntsPath}/Amaterasu_Taunt_1.aac`,
      },
      {
        taunt: "My warriors will rise above the rest.",
        voiceline: `${tauntsPath}/Amaterasu_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Divine Presence",
      },
      {
        name: "Heavenly Reflection",
      },
      {
        name: "Glorious Charge",
      },
      {
        name: "Dazzling Offensive",
      },
    ],
  },
  {
    name: "Anhur",
    pantheon: "Egyptian",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Single Target Damage"],
    image: "assets/icons/Anhur.png",
    NJ_Voiceline: "assets/voicelines_NJ/Anhur.aac",
    taunts: [
      {
        taunt: "We will end this now, you and I!",
        voiceline: `${tauntsPath}/Anhur_Taunt_1.aac`,
      },
      {
        taunt: "I will not be felled so easily!",
        voiceline: `${tauntsPath}/Anhur_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Shifting Sands",
      },
      {
        name: "Impale",
      },
      {
        name: "Disperse",
      },
      {
        name: "Desert Fury",
      },
    ],
  },
  {
    name: "Anubis",
    pantheon: "Egyptian",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage", "High Single Target Damage"],
    image: "assets/icons/Anubis.png",
    NJ_Voiceline: "assets/voicelines_NJ/Anubis.aac",
    taunts: [
      {
        taunt: "No! Release me, I command it!",
        voiceline: `${tauntsPath}/Anubis_Taunt_1.aac`,
      },
      {
        taunt: "Close, but not close enough!",
        voiceline: `${tauntsPath}/Anubis_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Plague of Locusts",
      },
      {
        name: "Mummify",
      },
      {
        name: "Grasping Hands",
      },
      {
        name: "Death Gaze",
      },
    ],
  },
  {
    name: "Ao Kuang",
    pantheon: "Chinese",
    type: "Magical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Single Target Damage"],
    image: "assets/icons/AoKuang.png",
    NJ_Voiceline: "assets/voicelines_NJ/AoKuang.aac",
    taunts: [
      {
        taunt: "My powers are far beyond your... limited comprehension.",
        voiceline: `${tauntsPath}/AoKuang_Taunt_1.aac`,
      },
      {
        taunt: "Only a fool would dare to face me! Ah, there you are! A fool.",
        voiceline: `${tauntsPath}/AoKuang_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Water Illusion",
      },
      {
        name: "Dragon Call",
      },
      {
        name: "Wild Storm",
      },
      {
        name: "King of the Eastern Seas",
      },
    ],
  },
  {
    name: "Aphrodite",
    pantheon: "Greek",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid", "Solo", "Support"],
    pros: ["High Sustain"],
    image: "assets/icons/Aphrodite.png",
    NJ_Voiceline: "assets/voicelines_NJ/Aphrodite.aac",
    taunts: [
      {
        taunt: "Oh... My... Gods, that was close!",
        voiceline: `${tauntsPath}/Aphrodite_Taunt_1.aac`,
      },
      {
        taunt: "You are such a hot mess!",
        voiceline: `${tauntsPath}/Aphrodite_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Kiss",
      },
      {
        name: "Back Off!",
      },
      {
        name: "Love Birds",
      },
      {
        name: "Undying Love",
      },
    ],
  },
  {
    name: "Apollo",
    pantheon: "Greek",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC"],
    pros: ["High Mobility"],
    image: "assets/icons/Apollo.png",
    NJ_Voiceline: "assets/voicelines_NJ/Apollo.aac",
    taunts: [
      {
        taunt: "Haha! They can't stop me!",
        voiceline: `${tauntsPath}/Apollo_Taunt_1.aac`,
      },
      {
        taunt: "HAH! You have the Audacity to compare yourself to me?!",
        voiceline: `${tauntsPath}/Apollo_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "So Beautiful",
      },
      {
        name: "Serenade",
      },
      {
        name: "The Moves",
      },
      {
        name: "Across The Sky",
      },
    ],
  },
  {
    name: "Arachne",
    pantheon: "Greek",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle"],
    pros: ["High Single Target Damage", "High Mobility"],
    image: "assets/icons/Arachne.png",
    NJ_Voiceline: "assets/voicelines_NJ/Arachne.aac",
    taunts: [
      {
        taunt: "So succulent! So juicy!",
        voiceline: `${tauntsPath}/Arachne_Taunt_1.aac`,
      },
      {
        taunt: "Feast, my children! There will be enough for everyone tonight!",
        voiceline: `${tauntsPath}/Arachne_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Venomous Bite",
      },
      {
        name: "Cocoon",
      },
      {
        name: "Web",
      },
      {
        name: "Night Crawler",
      },
    ],
  },
  {
    name: "Ares",
    pantheon: "Greek",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Ares.png",
    NJ_Voiceline: "assets/voicelines_NJ/Ares.aac",
    taunts: [
      {
        taunt: "I show my enemies no mercy!",
        voiceline: `${tauntsPath}/Ares_Taunt_1.aac`,
      },
      {
        taunt: "There is no escape!",
        voiceline: `${tauntsPath}/Ares_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Shackles",
      },
      {
        name: "Bolster Defenses",
      },
      {
        name: "Searing Flesh",
      },
      {
        name: "No Escape",
      },
    ],
  },
  {
    name: "Artemis",
    pantheon: "Greek",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC"],
    pros: ["High Single Target Damage", "High Attack Speed"],
    image: "assets/icons/Artemis.png",
    NJ_Voiceline: "assets/voicelines_NJ/Artemis.aac",
    taunts: [
      {
        taunt: "Come into my range if you want to be a pin cushion!",
        voiceline: `${tauntsPath}/Artemis_Taunt_1.aac`,
      },
      {
        taunt:
          "You'll leave this battle with more holes than you started with!",
        voiceline: `${tauntsPath}/Artemis_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Transgressor's Fate",
      },
      {
        name: "Vengeful Assault",
      },
      {
        name: "Suppress the Insolent",
      },
      {
        name: "Calydonian Boar",
      },
    ],
  },
  {
    name: "Artio",
    pantheon: "Celtic",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Crowd Control", "High Sustain"],
    image: "assets/icons/Artio.png",
    NJ_Voiceline: "assets/voicelines_NJ/Artio.aac",
    taunts: [
      {
        taunt: "Those who crave success must be prepared to work for it.",
        voiceline: `${tauntsPath}/Artio_Taunt_1.aac`,
      },
      {
        taunt: "I thank you for your generous donations!",
        voiceline: `${tauntsPath}/Artio_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Energy Surge",
      },
      {
        name: "Entangling Vines",
      },
      {
        name: "Life Tap",
      },
      {
        name: "Shapeshift",
      },
    ],
  },
  {
    name: "Athena",
    pantheon: "Greek",
    type: "Magical",
    range: "Melee",
    positions: ["Jungle", "Support", "Solo"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Athena.png",
    NJ_Voiceline: "assets/voicelines_NJ/Athena.aac",
    taunts: [
      {
        taunt:
          "Of course I've heard of you. You're well known for your many defeats.",
        voiceline: `${tauntsPath}/Athena_Taunt_1.aac`,
      },
      {
        taunt: "Even a perfect plan can fail..",
        voiceline: `${tauntsPath}/Athena_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Preemptive Strike",
      },
      {
        name: "Confound",
      },
      {
        name: "Shield Wall",
      },
      {
        name: "Defender of Olympus",
      },
    ],
  },
  {
    name: "Atlas",
    pantheon: "Greek",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Atlas.png",
    NJ_Voiceline: "assets/voicelines_NJ/Atlas.aac",
    taunts: [
      {
        taunt: "Let's see if you can manage a fraction of my punishment.",
        voiceline: `${tauntsPath}/Atlas_Taunt_1.aac`,
      },
      {
        taunt: "Burden yourself no longer.",
        voiceline: `${tauntsPath}/Atlas_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Unburden",
      },
      {
        name: "Gravity Pull",
      },
      {
        name: "Kinetic Charge",
      },
      {
        name: "Gamma-Ray Burst",
      },
    ],
  },
  {
    name: "Awilix",
    pantheon: "Maya",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle"],
    pros: ["High Single Target Damage", "High Mobility"],
    image: "assets/icons/Awilix.png",
    NJ_Voiceline: "assets/voicelines_NJ/Awilix.aac",
    taunts: [
      {
        taunt: "Don't worry, I'm full of energy!",
        voiceline: `${tauntsPath}/Awilix_Taunt_1.aac`,
      },
      {
        taunt:
          "I know it's been tough for you, but you should always try to smile!",
        voiceline: `${tauntsPath}/Awilix_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Summon Suku",
      },
      {
        name: "Feather Step",
      },
      {
        name: "Moonlight Charge",
      },
      {
        name: "Gravity Surge",
      },
    ],
  },
  {
    name: "Baba Yaga",
    pantheon: "Slavic",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage"],
    image: "assets/icons/BabaYaga.png",
    NJ_Voiceline: "assets/voicelines_NJ/BabaYaga.aac",
    taunts: [
      {
        taunt: "Is this what passes for a god these days?",
        voiceline: `${tauntsPath}/BabaYaga_Taunt_1.aac`,
      },
      {
        taunt: "Such boastful pride from a teeny, tiny mind.",
        voiceline: `${tauntsPath}/BabaYaga_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Wild Witchcraft",
      },
      {
        name: "Baba's Brew",
      },
      {
        name: "Blast Off!",
      },
      {
        name: "Home Sweet Home",
      },
    ],
  },
  {
    name: "Bacchus",
    pantheon: "Roman",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Bacchus.png",
    NJ_Voiceline: "assets/voicelines_NJ/Bacchus.aac",
    taunts: [
      {
        taunt: "Don't be a party pooper.",
        voiceline: `${tauntsPath}/Bacchus_Taunt_1.aac`,
      },
      {
        taunt: "Let me at 'em!",
        voiceline: `${tauntsPath}/Bacchus_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Chug",
      },
      {
        name: "Belly Flop",
      },
      {
        name: "Belch of the Gods",
      },
      {
        name: "Intoxicate",
      },
    ],
  },
  {
    name: "Bakasura",
    pantheon: "Hindu",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Single Target Damage", "High Mobility"],
    image: "assets/icons/Bakasura.png",
    NJ_Voiceline: "assets/voicelines_NJ/Bakasura.aac",
    taunts: [
      {
        taunt: "Beasting and feasting!",
        voiceline: `${tauntsPath}/Bakasura_Taunt_1.aac`,
      },
      {
        taunt: "Looks like I bit off more than I could chew...",
        voiceline: `${tauntsPath}/Bakasura_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Take Down",
      },
      {
        name: "Eat Minion",
      },
      {
        name: "Butcher Blades",
      },
      {
        name: "Regurgitate",
      },
    ],
  },
  {
    name: "Baron Samedi",
    pantheon: "Voodoo",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid", "Solo", "Support"],
    pros: ["High Crowd Control", "Medium Sustain", "Medium Area Damage"],
    image: "assets/icons/BaronSamedi.png",
    NJ_Voiceline: "assets/voicelines_NJ/BaronSamedi.aac",
    taunts: [
      {
        taunt: "Careful. Stand too close and you will lose yourself.",
        voiceline: `${tauntsPath}/BaronSamedi_Taunt_1.aac`,
      },
      {
        taunt: "The better I do, the more I bury.",
        voiceline: `${tauntsPath}/BaronSamedi_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Vivid Gaze",
      },
      {
        name: "Consign Spirits",
      },
      {
        name: "Wrap It Up",
      },
      {
        name: "Life of the Party",
      },
    ],
  },
  {
    name: "Bastet",
    pantheon: "Egyptian",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Single Target Damage"],
    image: "assets/icons/Bastet.png",
    NJ_Voiceline: "assets/voicelines_NJ/Bastet.aac",
    taunts: [
      {
        taunt: "Ah, there's almost no sport in it!",
        voiceline: `${tauntsPath}/Bastet_Taunt_1.aac`,
      },
      {
        taunt: "Ooooh, you look like fun!.. Let's play.",
        voiceline: `${tauntsPath}/Bastet_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Pounce",
      },
      {
        name: "Razor Whip",
      },
      {
        name: "Ensnaring Claw",
      },
      {
        name: "Huntress of Bast",
      },
    ],
  },
  {
    name: "Bellona",
    pantheon: "Roman",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Defense", "High Crowd Control"],
    image: "assets/icons/Bellona.png",
    NJ_Voiceline: "assets/voicelines_NJ/Bellona.aac",
    taunts: [
      {
        taunt: "Do you even know who you're fighting?!",
        voiceline: `${tauntsPath}/Bellona_Taunt_1.aac`,
      },
      {
        taunt: "Ahaha! Keep 'em comin'!",
        voiceline: `${tauntsPath}/Bellona_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Shield Bash",
      },
      {
        name: "Bludgeon",
      },
      {
        name: "Scourge",
      },
      {
        name: "Eagle's Rally",
      },
    ],
  },
  {
    name: "Cabrakan",
    pantheon: "Maya",
    type: "Magical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: ["High Crowd Control", "High Defense", "High Single Target Damage"],
    image: "assets/icons/Cabrakan.png",
    NJ_Voiceline: "assets/voicelines_NJ/Cabrakan.aac",
    taunts: [
      {
        taunt: "To beat the god, you must be the god!",
        voiceline: `${tauntsPath}/Cabrakan_Taunt_1.aac`,
      },
      {
        taunt: "Come find out what's behind door number one!",
        voiceline: `${tauntsPath}/Cabrakan_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Seismic Crush",
      },
      {
        name: "Refraction Shield",
      },
      {
        name: "Tremors",
      },
      {
        name: "Tectonic Shift",
      },
    ],
  },
  {
    name: "Camazotz",
    pantheon: "Maya",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Sustain", "High Mobility"],
    image: "assets/icons/Camazotz.png",
    NJ_Voiceline: "assets/voicelines_NJ/Camazotz.aac",
    taunts: [
      {
        taunt: "I am the monster that lurks in your nightmares!",
        voiceline: `${tauntsPath}/Camazotz_Taunt_1.aac`,
      },
      {
        taunt: "The vicious cycle begins!",
        voiceline: `${tauntsPath}/Camazotz_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Screech",
      },
      {
        name: "Vampire Bats",
      },
      {
        name: "Devour",
      },
      {
        name: "Bat Out of Hell",
      },
    ],
  },
  {
    name: "Cerberus",
    pantheon: "Greek",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Crowd Control", "Medium Area Damage"],
    image: "assets/icons/Cerberus.png",
    NJ_Voiceline: "assets/voicelines_NJ/Cerberus.aac",
    taunts: [
      {
        taunt: "Your days are numbered! And it's not a very large number.",
        voiceline: `${tauntsPath}/Cerberus_Taunt_1.aac`,
      },
      {
        taunt: "Is it death you seek? Very well! I have come for you.",
        voiceline: `${tauntsPath}/Cerberus_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Paralyzing Spit",
      },
      {
        name: "Ghastly Breath",
      },
      {
        name: "Soul Expulsion",
      },
      {
        name: "Stygian Torment",
      },
    ],
  },
  {
    name: "Cernunnos",
    pantheon: "Celtic",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Crowd Control", "High Area Damage"],
    image: "assets/icons/Cernunnos.png",
    NJ_Voiceline: "assets/voicelines_NJ/Cernunnos.aac",
    taunts: [
      {
        taunt:
          "Life and death are equal parts in our world. Today you are on death's side!",
        voiceline: `${tauntsPath}/Cernunnos_Taunt_1.aac`,
      },
      {
        taunt: "Gods in my way will be slain!",
        voiceline: `${tauntsPath}/Cernunnos_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Shifter of Seasons",
      },
      {
        name: "Bramble Blast",
      },
      {
        name: "Horn Charge",
      },
      {
        name: "The Wild Hunt",
      },
    ],
  },
  {
    name: "Chaac",
    pantheon: "Maya",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Sustain", "High Defense"],
    image: "assets/icons/Chaac.png",
    NJ_Voiceline: "assets/voicelines_NJ/Chaac.aac",
    taunts: [
      {
        taunt: "Oh, look at the little girly man!",
        voiceline: `${tauntsPath}/Chaac_Taunt_1.aac`,
      },
      {
        taunt: "When it rains, it pours... my fists!",
        voiceline: `${tauntsPath}/Chaac_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Thunder Strike",
      },
      {
        name: "Torrent",
      },
      {
        name: "Rain Dance",
      },
      {
        name: "Storm Call",
      },
    ],
  },
  {
    name: "Chang'e",
    pantheon: "Chinese",
    type: "Magical",
    range: "Ranged",
    positions: ["Jungle", "Mid", "Solo"],
    pros: ["High Sustain", "Medium Area Damage"],
    image: "assets/icons/Change.png",
    NJ_Voiceline: "assets/voicelines_NJ/Change.aac",
    taunts: [
      {
        taunt: "Who else wants some of this?!",
        voiceline: `${tauntsPath}/Change_Taunt_1.aac`,
      },
      {
        taunt: "Quick! Drink some of this!",
        voiceline: `${tauntsPath}/Change_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Crescent Moon Dance",
      },
      {
        name: "Moonlit Waltz",
      },
      {
        name: "Moonflower Dance",
      },
      {
        name: "Waxing Moon",
      },
    ],
  },
  {
    name: "Charon",
    pantheon: "Greek",
    type: "Magical",
    range: "Ranged",
    positions: ["Support"],
    pros: ["High Mobility"],
    image: "assets/icons/Charon.png",
    NJ_Voiceline: "assets/voicelines_NJ/Charon.aac",
    taunts: [
      {
        taunt: "Hahahaha, abandon all hope all ye who enter my domain!",
        voiceline: `${tauntsPath}/Charon_Taunt_1.aac`,
      },
      {
        taunt: "The time is nigh! Embrace your inevitable end!",
        voiceline: `${tauntsPath}/Charon_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Spectral Surge",
      },
      {
        name: "Damnation",
      },
      {
        name: "Swift Voyage",
      },
      {
        name: "Summon Styx",
      },
    ],
  },
  {
    name: "Charybdis",
    pantheon: "Greek",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Attack Speed", "High Movement"],
    image: "assets/icons/Charybdis.png",
    NJ_Voiceline: "assets/voicelines_NJ/Charybdis.aac",
    taunts: [
      {
        taunt:
          "If you dare attempt to cross my path, you will not live to tell the tale!",
        voiceline: `${tauntsPath}/Charybdis_Taunt_1.aac`,
      },
      {
        taunt:
          "Feel free to keep running. Or simply give up. This is fun for me either way.",
        voiceline: `${tauntsPath}/Charybdis_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Spike Shot",
      },
      {
        name: "Capsize",
      },
      {
        name: "Whirlpool Form",
      },
      {
        name: "The Maw Hungers",
      },
    ],
  },
  {
    name: "Chernobog",
    pantheon: "Slavic",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Single Target Damage", "High Attack Speed"],
    image: "assets/icons/Chernobog.png",
    NJ_Voiceline: "assets/voicelines_NJ/Chernobog.aac",
    taunts: [
      {
        taunt: "My very presence is suffocating.",
        voiceline: `${tauntsPath}/Chernobog_Taunt_1.aac`,
      },
      {
        taunt: "To get back to the light you must fight through me!",
        voiceline: `${tauntsPath}/Chernobog_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Crystallized Curses",
      },
      {
        name: "Vicious Barrage",
      },
      {
        name: "Into Darkness",
      },
      {
        name: "Living Nightmare",
      },
    ],
  },
  {
    name: "Chiron",
    pantheon: "Greek",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["Medium Sustain", "High Movement Speed", "Medium Area Damage"],
    image: "assets/icons/Chiron.png",
    NJ_Voiceline: "assets/voicelines_NJ/Chiron.aac",
    taunts: [
      {
        taunt:
          "Everyone wants to be the next hero. Very well then. Come on, let's get this over with.",
        voiceline: `${tauntsPath}/Chiron_Taunt_1.aac`,
      },
      {
        taunt:
          "Winding up to do battle with a superior foe doesn't make you brave, it makes you stupid.",
        voiceline: `${tauntsPath}/Chiron_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Training Exercise",
      },
      {
        name: "Masterful Shot",
      },
      {
        name: "Giddyup!",
      },
      {
        name: "Centaurus",
      },
    ],
  },
  {
    name: "Chronos",
    pantheon: "Greek",
    type: "Magical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["Medium Crowd Control", "High Mobility", "High Movement Speed"],
    image: "assets/icons/Chronos.png",
    NJ_Voiceline: "assets/voicelines_NJ/Chronos.aac",
    taunts: [
      {
        taunt:
          "They may be able to kill you, but I can make it as if you were never born!",
        voiceline: `${tauntsPath}/Chronos_Taunt_1.aac`,
      },
      {
        taunt: "That is not the way to kill time!",
        voiceline: `${tauntsPath}/Chronos_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Time Rift",
      },
      {
        name: "Accelerate",
      },
      {
        name: "Stop Time",
      },
      {
        name: "Rewind",
      },
    ],
  },
  {
    name: "Cliodhna",
    pantheon: "Celtic",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Single Target Damage", "High Mobility", "Medium Area Damage"],
    image: "assets/icons/Cliodhna.png",
    NJ_Voiceline: "assets/voicelines_NJ/Cliodhna.aac",
    taunts: [
      {
        taunt: "Why did you come here? Why!?",
        voiceline: `${tauntsPath}/Cliodhna_Taunt_1.aac`,
      },
      {
        taunt: "You are all alone. None will find you!",
        voiceline: `${tauntsPath}/Cliodhna_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Banshee's Wail",
      },
      {
        name: "Flickering Visions",
      },
      {
        name: "Lurching Claw",
      },
      {
        name: "Tearing The Veil",
      },
    ],
  },
  {
    name: "Cthulhu",
    pantheon: "Great Old Ones",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Area Damage", "High Crowd Control"],
    image: "assets/icons/Cthulhu.png",
    NJ_Voiceline: "assets/voicelines_NJ/Cthulhu.aac",
    taunts: [
      {
        taunt:
          "Inconsequential.. I see nothing but dust and ash filling the horizon.",
        voiceline: `${tauntsPath}/Cthulhu_Taunt_1.aac`,
      },
      {
        taunt: "You live and die for my amusement.",
        voiceline: `${tauntsPath}/Cthulhu_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Sanity Break",
      },
      {
        name: "The Mire",
      },
      {
        name: "Rushing Terror",
      },
      {
        name: "Descend into Madness",
      },
    ],
  },
  {
    name: "Cu Chulainn",
    pantheon: "Celtic",
    type: "Physical",
    range: "Melee",
    positions: ["Solo"],
    pros: ["High Crowd Control", "High Area Damage"],
    image: "assets/icons/CuChulainn.png",
    NJ_Voiceline: "assets/voicelines_NJ/CuChulainn.aac",
    taunts: [
      {
        taunt: "Haven't you heard my stories?",
        voiceline: `${tauntsPath}/CuChulainn_Taunt_1.aac`,
      },
      {
        taunt: "Run, puny god!",
        voiceline: `${tauntsPath}/CuChulainn_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Barbed Spear",
      },
      {
        name: "Vent Anger",
      },
      {
        name: "Salmon's Leap",
      },
      {
        name: "Spear of Mortal Pain",
      },
    ],
  },
  {
    name: "Cupid",
    pantheon: "Roman",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Single Target Damage", "High Sustain"],
    image: "assets/icons/Cupid.png",
    NJ_Voiceline: "assets/voicelines_NJ/Cupid.aac",
    taunts: [
      {
        taunt: "This might be too big for me!",
        voiceline: `${tauntsPath}/Cupid_Taunt_1.aac`,
      },
      {
        taunt: "Don't break my heaaart!",
        voiceline: `${tauntsPath}/Cupid_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Heart Bomb",
      },
      {
        name: "Share the Love",
      },
      {
        name: "Flutter",
      },
      {
        name: "Fields of Love",
      },
    ],
  },
  {
    name: "Da Ji",
    pantheon: "Chinese",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle"],
    pros: ["High Single Target Damage"],
    image: "assets/icons/Daji.png",
    NJ_Voiceline: "assets/voicelines_NJ/Daji.aac",
    taunts: [
      {
        taunt: "I can't wait to kill you, slowly!",
        voiceline: `${tauntsPath}/Daji_Taunt_1.aac`,
      },
      {
        taunt: "I learned long ago that compassion gets you nowhere!",
        voiceline: `${tauntsPath}/Daji_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Horrible Burns",
      },
      {
        name: "One Thousand Cuts",
      },
      {
        name: "Trickster Spirit",
      },
      {
        name: "Paolao",
      },
    ],
  },
  {
    name: "Danzaburou",
    pantheon: "Japanese",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC"],
    pros: ["High Area Damage", "High Single Target Damage"],
    image: "assets/icons/Danzaburou.png",
    NJ_Voiceline: "assets/voicelines_NJ/Danzaburou.aac",
    taunts: [
      {
        taunt:
          "Are you sure, this is the real me? Oh, okay, okay it is. Now, put down your weapon!",
        voiceline: `${tauntsPath}/Danzaburou_Taunt_1.aac`,
      },
      {
        taunt:
          "Do not underestimate me, I can be frightenly fast, deadly and uh, uh.. o-, other scary things!",
        voiceline: `${tauntsPath}/Danzaburou_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Fool’s Gold",
      },
      {
        name: "Alluring Spirits",
      },
      {
        name: "Tanuki Trickery",
      },
      {
        name: "Uproarious Rocket",
      },
    ],
  },
  {
    name: "Discordia",
    pantheon: "Roman",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage", "High Single Target Damage"],
    image: "assets/icons/Discordia.png",
    NJ_Voiceline: "assets/voicelines_NJ/Discordia.aac",
    taunts: [
      {
        taunt:
          "Hehe! Is that as hard as you can hit? I was hoping for more, a lot more...",
        voiceline: `${tauntsPath}/Discordia_Taunt_1.aac`,
      },
      {
        taunt: "I don't just want beat them, I want to humiliate them!",
        voiceline: `${tauntsPath}/Discordia_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Unruly Magic",
      },
      {
        name: "Strife",
      },
      {
        name: "Erratic Behavior",
      },
      {
        name: "Golden Apple of Discord",
      },
    ],
  },
  {
    name: "Erlang Shen",
    pantheon: "Chinese",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: [
      "High Single Target Damage",
      "High Attack Speed",
      "High Crowd Control",
    ],
    image: "assets/icons/ErlangShen.png",
    NJ_Voiceline: "assets/voicelines_NJ/ErlangShen.aac",
    taunts: [
      {
        taunt: "Bravery always prevails!",
        voiceline: `${tauntsPath}/ErlangShen_Taunt_1.aac`,
      },
      {
        taunt: "Your strength is no match for mine!",
        voiceline: `${tauntsPath}/ErlangShen_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Spot Weakness",
      },
      {
        name: "Pin",
      },
      {
        name: "72 Transformations",
      },
      {
        name: "9 Turns Blessing",
      },
    ],
  },
  {
    name: "Eset",
    pantheon: "Egyptian",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid", "Support"],
    pros: ["High Crowd Control", "High Area Damage"],
    image: "assets/icons/Eset.png",
    NJ_Voiceline: "assets/voicelines_NJ/Eset.aac",
    taunts: [
      {
        taunt: "Bow before me!",
        voiceline: `${tauntsPath}/Eset_Taunt_1.aac`,
      },
      {
        taunt: "I see dead people... Looks like you're next!",
        voiceline: `${tauntsPath}/Eset_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Wing Gust",
      },
      {
        name: "Spirit Ball",
      },
      {
        name: "Dispel Magic",
      },
      {
        name: "Circle of Protection",
      },
    ],
  },
  {
    name: "Fafnir",
    pantheon: "Norse",
    type: "Magical",
    range: "Melee",
    positions: ["Support"],
    pros: ["High Defense", "High Mobility", "High Crowd Control"],
    image: "assets/icons/Fafnir.png",
    NJ_Voiceline: "assets/voicelines_NJ/Fafnir.aac",
    taunts: [
      {
        taunt: "My only masters are wealth and power!",
        voiceline: `${tauntsPath}/Fafnir_Taunt_1.aac`,
      },
      {
        taunt: "As usual, I've been underestimated.",
        voiceline: `${tauntsPath}/Fafnir_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Cursed Strength",
      },
      {
        name: "Coerce",
      },
      {
        name: "Underhanded Tactics",
      },
      {
        name: "Cursed Strength",
      },
    ],
  },
  {
    name: "Fenrir",
    pantheon: "Norse",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: ["High Single Target Damage", "High Mobility"],
    image: "assets/icons/Fenrir.png",
    NJ_Voiceline: "assets/voicelines_NJ/Fenrir.aac",
    taunts: [
      {
        taunt: "I will have my revenge!",
        voiceline: `${tauntsPath}/Fenrir_Taunt_1.aac`,
      },
      {
        taunt: "When I look around, all I see are sheep!",
        voiceline: `${tauntsPath}/Fenrir_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Unchained",
      },
      {
        name: "Seething Howl",
      },
      {
        name: "Brutalize",
      },
      {
        name: "Ragnarok",
      },
    ],
  },
  {
    name: "Freya",
    pantheon: "Norse",
    type: "Magical",
    range: "Melee",
    positions: ["ADC"],
    pros: ["Medium Area Damage", "High Single Target Damage"],
    image: "assets/icons/Freya.png",
    NJ_Voiceline: "assets/voicelines_NJ/Freya.aac",
    taunts: [
      {
        taunt: "Another kill to add to my body markings!",
        voiceline: `${tauntsPath}/Freya_Taunt_1.aac`,
      },
      {
        taunt: "I was born ready!",
        voiceline: `${tauntsPath}/Freya_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Irradiate",
      },
      {
        name: "Pulse",
      },
      {
        name: "Banish",
      },
      {
        name: "Valkyrie's Discretion",
      },
    ],
  },
  {
    name: "Ganesha",
    pantheon: "Hindu",
    type: "Magical",
    range: "Melee",
    positions: ["Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Ganesha.png",
    NJ_Voiceline: "assets/voicelines_NJ/Ganesha.aac",
    taunts: [
      {
        taunt: "You don't want to stand in my way.",
        voiceline: `${tauntsPath}/Ganesha_Taunt_1.aac`,
      },
      {
        taunt: "My ego has been destroyed.",
        voiceline: `${tauntsPath}/Ganesha_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Turn of Fate",
      },
      {
        name: "Ohm",
      },
      {
        name: "Remove Obstacles",
      },
      {
        name: "Dharmic Pillars",
      },
    ],
  },
  {
    name: "Geb",
    pantheon: "Egyptian",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Geb.png",
    NJ_Voiceline: "assets/voicelines_NJ/Geb.aac",
    taunts: [
      {
        taunt: "Your immortality has existed, but a breath of mine!",
        voiceline: `${tauntsPath}/Geb_Taunt_1.aac`,
      },
      {
        taunt: "I must, Break you.",
        voiceline: `${tauntsPath}/Geb_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Roll Out",
      },
      {
        name: "Shock Wave",
      },
      {
        name: "Stone Shield",
      },
      {
        name: "Cataclysm",
      },
    ],
  },
  {
    name: "Gilgamesh",
    pantheon: "Babylonian",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: ["High Defense", "High Crowd Control"],
    image: "assets/icons/Gilgamesh.png",
    NJ_Voiceline: "assets/voicelines_NJ/Gilgamesh.aac",
    taunts: [
      {
        taunt:
          "Fate and destiny. Two concepts that mean little to me. I shape my own path forward.",
        voiceline: `${tauntsPath}/Gilgamesh_Taunt_1.aac`,
      },
      {
        taunt:
          "Skilled warriors can speak to each other through their combat. Your vocabulary is.. lacking.",
        voiceline: `${tauntsPath}/Gilgamesh_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Sun-Forged Scimitar",
      },
      {
        name: "Drop Kick",
      },
      {
        name: "Hero's Advance",
      },
      {
        name: "Winds of Shamash",
      },
    ],
  },
  {
    name: "Guan Yu",
    pantheon: "Chinese",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: ["High Sustain", "High Mobility", "Medium Crowd Control"],
    image: "assets/icons/GuanYu.png",
    NJ_Voiceline: "assets/voicelines_NJ/GuanYu.aac",
    taunts: [
      {
        taunt: "A new age begins now, and there is no room for evil within it!",
        voiceline: `${tauntsPath}/GuanYu_Taunt_1.aac`,
      },
      {
        taunt: "This is where training pays off!",
        voiceline: `${tauntsPath}/GuanYu_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Conviction",
      },
      {
        name: "Warrior's Will",
      },
      {
        name: "Taolu Assault",
      },
      {
        name: "Cavalry Charge",
      },
    ],
  },
  {
    name: "Hachiman",
    pantheon: "Japanese",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC"],
    pros: ["High Single Target Damage", "High Mobility"],
    image: "assets/icons/Hachiman.png",
    NJ_Voiceline: "assets/voicelines_NJ/Hachiman.aac",
    taunts: [
      {
        taunt: "Perhaps you will learn a lesson this day.",
        voiceline: `${tauntsPath}/Hachiman_Taunt_1.aac`,
      },
      {
        taunt: "True strength comes from discipline and you lack both.",
        voiceline: `${tauntsPath}/Hachiman_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Eagle Eye",
      },
      {
        name: "Heavenly Banner",
      },
      {
        name: "Iaijutsu",
      },
      {
        name: "Mounted Archery",
      },
    ],
  },
  {
    name: "Hades",
    pantheon: "Greek",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid", "Solo"],
    pros: ["High Sustain", "High Area Damage"],
    image: "assets/icons/Hades.png",
    NJ_Voiceline: "assets/voicelines_NJ/Hades.aac",
    taunts: [
      {
        taunt: "Give in now and I'll allow you a painless death!",
        voiceline: `${tauntsPath}/Hades_Taunt_1.aac`,
      },
      {
        taunt: "The battle isn't over yet!",
        voiceline: `${tauntsPath}/Hades_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Death From Below",
      },
      {
        name: "Shroud of Darkness",
      },
      {
        name: "Devour Souls",
      },
      {
        name: "Pillar of Agony",
      },
    ],
  },
  {
    name: "He Bo",
    pantheon: "Chinese",
    type: "Magical",
    range: "Ranged",
    positions: ["Jungle", "Mid"],
    pros: ["High Single Target Damage", "High Area Damage"],
    image: "assets/icons/Hebo.png",
    NJ_Voiceline: "assets/voicelines_NJ/Hebo.aac",
    taunts: [
      {
        taunt: "Assail me and find yourself broken against my banks!",
        voiceline: `${tauntsPath}/Hebo_Taunt_1.aac`,
      },
      {
        taunt: "Your fate, like a river, flows only in one direction.",
        voiceline: `${tauntsPath}/Hebo_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Water Cannon",
      },
      {
        name: "Atlas of the Yellow River",
      },
      {
        name: "Waterspout",
      },
      {
        name: "Crushing Wave",
      },
    ],
  },
  {
    name: "Heimdallr",
    pantheon: "Norse",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC"],
    pros: [
      "High Single Target Damage",
      "High Mobility",
      "Medium Crowd Control",
    ],
    image: "assets/icons/Heimdallr.png",
    NJ_Voiceline: "assets/voicelines_NJ/Heimdallr.aac",
    taunts: [
      {
        taunt: "I do not hate you. It is simply my duty to stop you.",
        voiceline: `${tauntsPath}/Heimdallr_Taunt_1.aac`,
      },
      {
        taunt:
          "We could be sharing tales over some warm mead. Huh. Such a waste of effort.",
        voiceline: `${tauntsPath}/Heimdallr_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Piercing Sight",
      },
      {
        name: "Gjallarhorn",
      },
      {
        name: "The Bifrost",
      },
      {
        name: "Through the Realms",
      },
    ],
  },
  {
    name: "Hel",
    pantheon: "Norse",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid", "Solo", "Support"],
    pros: ["High Sustain", "High Area Damage"],
    image: "assets/icons/Hel.png",
    NJ_Voiceline: "assets/voicelines_NJ/Hel.aac",
    taunts: [
      {
        taunt: "Oh, what a mess I've made!",
        voiceline: `${tauntsPath}/Hel_Taunt_1.aac`,
      },
      {
        taunt: "Down, down down! Ahahahahahahahah!",
        voiceline: `${tauntsPath}/Hel_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Decay",
      },
      {
        name: "Hinder",
      },
      {
        name: "Repulse",
      },
      {
        name: "Switch Stances",
      },
    ],
  },
  {
    name: "Hera",
    pantheon: "Greek",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage", "High Crowd Control"],
    image: "assets/icons/Hera.png",
    NJ_Voiceline: "assets/voicelines_NJ/Hera.aac",
    taunts: [
      {
        taunt: "All the pieces are falling into place. ",
        voiceline: `${tauntsPath}/Hera_Taunt_1.aac`,
      },
      {
        taunt: "A misstep? I didn't expect such ferocity.",
        voiceline: `${tauntsPath}/Hera_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Royal Assault",
      },
      {
        name: "Polymorph",
      },
      {
        name: "Divine Shroud",
      },
      {
        name: "Argus, the Defender",
      },
    ],
  },
  {
    name: "Hercules",
    pantheon: "Roman",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: ["High Single Target Damage", "High Sustain", "High Movement Speed"],
    image: "assets/icons/Hercules.png",
    NJ_Voiceline: "assets/voicelines_NJ/Hercules.aac",
    taunts: [
      {
        taunt: "When this is over, you will regret everything!",
        voiceline: `${tauntsPath}/Hercules_Taunt_1.aac`,
      },
      {
        taunt: "Another victory!",
        voiceline: `${tauntsPath}/Hercules_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Driving Strike",
      },
      {
        name: "Earthbreaker",
      },
      {
        name: "Mitigate Wounds",
      },
      {
        name: "Excavate",
      },
    ],
  },
  {
    name: "Horus",
    pantheon: "Egyptian",
    type: "Physical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Defense", "High Sustain", "High Crowd Control"],
    image: "assets/icons/Horus.png",
    NJ_Voiceline: "assets/voicelines_NJ/Horus.aac",
    taunts: [
      {
        taunt: "You're beyond reason and have left me no choice!",
        voiceline: `${tauntsPath}/Horus_Taunt_1.aac`,
      },
      {
        taunt:
          "To face me as to face an insurmountable headwind! Try as you might, you will not pass!",
        voiceline: `${tauntsPath}/Horus_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Updraft",
      },
      {
        name: "Fracture",
      },
      {
        name: "Protector's Surge",
      },
      {
        name: "To The Skies!",
      },
    ],
  },
  {
    name: "Hou Yi",
    pantheon: "Chinese",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC"],
    pros: ["High Single Target Damage", "High Area Damage"],
    image: "assets/icons/HouYi.png",
    NJ_Voiceline: "assets/voicelines_NJ/HouYi.aac",
    taunts: [
      {
        taunt: "It would be wise for you to not try your luck with me!",
        voiceline: `${tauntsPath}/HouYi_Taunt_1.aac`,
      },
      {
        taunt: "I'll make them pay!",
        voiceline: `${tauntsPath}/HouYi_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Ricochet",
      },
      {
        name: "Mark of the Golden Crow",
      },
      {
        name: "Divebomb",
      },
      {
        name: "Sunbreaker",
      },
    ],
  },
  {
    name: "Hun Batz",
    pantheon: "Maya",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle"],
    pros: ["High Single Target Damage", "High Crowd Control"],
    image: "assets/icons/HunBatz.png",
    NJ_Voiceline: "assets/voicelines_NJ/HunBatz.aac",
    taunts: [
      {
        taunt: "Death is the only certainty in this world, specially for you!",
        voiceline: `${tauntsPath}/HunBatz_Taunt_1.aac`,
      },
      {
        taunt:
          "There is time and order to all things, and it is time for you to cease to exist!",
        voiceline: `${tauntsPath}/HunBatz_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Somersault",
      },
      {
        name: "Overhand Smash",
      },
      {
        name: "Sacred Monkey",
      },
      {
        name: "Fear No Evil",
      },
    ],
  },
  {
    name: "Ishtar",
    pantheon: "Babylonian",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["Medium Area Damage", "High Attack Speed"],
    image: "assets/icons/Ishtar.png",
    NJ_Voiceline: "assets/voicelines_NJ/Ishtar.aac",
    taunts: [
      {
        taunt:
          "You want to fight me? He ha haha! Very well. I accept your challenge. Let's begin!",
        voiceline: `${tauntsPath}/Ishtar_Taunt_1.aac`,
      },
      {
        taunt: "This realm is mine!",
        voiceline: `${tauntsPath}/Ishtar_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Imbue Arrows",
      },
      {
        name: "Rolling Thunder",
      },
      {
        name: "Jolt",
      },
      {
        name: "Blades of Retribution",
      },
    ],
  },
  {
    name: "Ix Chel",
    pantheon: "Maya",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid", "Support"],
    pros: ["High Crowd Control"],
    image: "assets/icons/IxChel.png",
    NJ_Voiceline: "assets/voicelines_NJ/IxChel.aac",
    taunts: [
      {
        taunt:
          "You may take solace in the fact, that your defeat will be beautiful.",
        voiceline: `${tauntsPath}/IxChel_Taunt_1.aac`,
      },
      {
        taunt: "Today, I destroy! Tomorrow, I will create anew!",
        voiceline: `${tauntsPath}/IxChel_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Gleaming Blast",
      },
      {
        name: "Lunar Invocation",
      },
      {
        name: "Rainbow Crash",
      },
      {
        name: "Great End",
      },
    ],
  },
  {
    name: "Izanami",
    pantheon: "Japanese",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Area Damage", "High Attack Speed"],
    image: "assets/icons/Izanami.png",
    NJ_Voiceline: "assets/voicelines_NJ/Izanami.aac",
    taunts: [
      {
        taunt: "I am the last enemy!",
        voiceline: `${tauntsPath}/Izanami_Taunt_1.aac`,
      },
      {
        taunt: "Don't run, you'll just die tired!",
        voiceline: `${tauntsPath}/Izanami_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Sickle Storm",
      },
      {
        name: "Spectral Projection",
      },
      {
        name: "Fade Away",
      },
      {
        name: "Dark Portal",
      },
    ],
  },
  {
    name: "Janus",
    pantheon: "Roman",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Mobility", "High Area Damage"],
    image: "assets/icons/Janus.png",
    NJ_Voiceline: "assets/voicelines_NJ/Janus.aac",
    taunts: [
      {
        taunt: "When one door opens, another closes... and kills you!",
        voiceline: `${tauntsPath}/Janus_Taunt_1.aac`,
      },
      {
        taunt: "Curse my body, I wasn't fast enough!",
        voiceline: `${tauntsPath}/Janus_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Portal",
      },
      {
        name: "Unstable Vortex",
      },
      {
        name: "Threshold",
      },
      {
        name: "Through Space and Time",
      },
    ],
  },
  {
    name: "Jing Wei",
    pantheon: "Chinese",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Mobility", "High Single Target Damage"],
    image: "assets/icons/JingWei.png",
    NJ_Voiceline: "assets/voicelines_NJ/JingWei.aac",
    taunts: [
      {
        taunt: "I'll win even if this battle lasts for years!",
        voiceline: `${tauntsPath}/JingWei_Taunt_1.aac`,
      },
      {
        taunt: "You'll never keep up with me!",
        voiceline: `${tauntsPath}/JingWei_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Persistent Gust",
      },
      {
        name: "Explosive Bolts",
      },
      {
        name: "Agility",
      },
      {
        name: "Agility",
      },
    ],
  },
  {
    name: "Jormungandr",
    pantheon: "Norse",
    type: "Magical",
    range: "Melee",
    positions: ["Solo"],
    pros: ["High Mobility", "High Area Damage"],
    image: "assets/icons/Jormungandr.png",
    NJ_Voiceline: "assets/voicelines_NJ/Jormungandr.aac",
    taunts: [
      {
        taunt: "The end is near! This era of gods is coming to an end!",
        voiceline: `${tauntsPath}/Jormungandr_Taunt_1.aac`,
      },
      {
        taunt: "The dawn of your ending has arrived!",
        voiceline: `${tauntsPath}/Jormungandr_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Venomous Haze",
      },
      {
        name: "Consuming Bellow",
      },
      {
        name: "Submerge",
      },
      {
        name: "The World Serpent",
      },
    ],
  },
  {
    name: "Kali",
    pantheon: "Hindu",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle"],
    pros: ["High Single Target Damage", "High Sustain", "High Movement Speed"],
    image: "assets/icons/Kali.png",
    NJ_Voiceline: "assets/voicelines_NJ/Kali.aac",
    taunts: [
      {
        taunt: "Death is but a natural part of life!",
        voiceline: `${tauntsPath}/Kali_Taunt_1.aac`,
      },
      {
        taunt:
          "Don't fear that I will bring suffering, for in truth I will bring an end to it!",
        voiceline: `${tauntsPath}/Kali_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Nimble Strike",
      },
      {
        name: "Lash",
      },
      {
        name: "Incense",
      },
      {
        name: "Destruction",
      },
    ],
  },
  {
    name: "Khepri",
    pantheon: "Egyptian",
    type: "Magical",
    range: "Melee",
    positions: ["Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Khepri.png",
    NJ_Voiceline: "assets/voicelines_NJ/Khepri.aac",
    taunts: [
      {
        taunt: "For what you have done, you will not know peace.",
        voiceline: `${tauntsPath}/Khepri_Taunt_1.aac`,
      },
      {
        taunt: "We made it. That's what counts.",
        voiceline: `${tauntsPath}/Khepri_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Abduct",
      },
      {
        name: "Rising Dawn",
      },
      {
        name: "Solar Flare",
      },
      {
        name: "Scarab's Blessing",
      },
    ],
  },
  {
    name: "King Arthur",
    pantheon: "Arthurian",
    type: "Physical",
    range: "Melee",
    positions: ["Solo"],
    pros: ["High Crowd Control", "High Area Damage", "High Movement"],
    image: "assets/icons/KingArthur.png",
    NJ_Voiceline: "assets/voicelines_NJ/KingArthur.aac",
    taunts: [
      {
        taunt: "Yes, the legends are true. And yes, you will fail this day.",
        voiceline: `${tauntsPath}/KingArthur_Taunt_1.aac`,
      },
      {
        taunt: "Whilst I still have breath.. they can not win!",
        voiceline: `${tauntsPath}/KingArthur_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Overhead Slash",
      },
      {
        name: "Battle Stomp",
      },
      {
        name: "Twin Cleave",
      },
      {
        name: "Sundering Strike",
      },
    ],
  },
  {
    name: "Kukulkan",
    pantheon: "Maya",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage"],
    image: "assets/icons/Kukulkan.png",
    NJ_Voiceline: "assets/voicelines_NJ/Kukulkan.aac",
    taunts: [
      {
        taunt: "You know, I can swallow you in one go!",
        voiceline: `${tauntsPath}/Kukulkan_Taunt_1.aac`,
      },
      {
        taunt: "You've just sealed your doom!",
        voiceline: `${tauntsPath}/Kukulkan_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Zephyr",
      },
      {
        name: "Slipstream",
      },
      {
        name: "Whirlwind",
      },
      {
        name: "Spirit Of The Nine Winds",
      },
    ],
  },
  {
    name: "Kumbhakarna",
    pantheon: "Hindu",
    type: "Magical",
    range: "Melee",
    positions: ["Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Kumbhakarna.png",
    NJ_Voiceline: "assets/voicelines_NJ/Kumbhakarna.aac",
    taunts: [
      {
        taunt: "Are you sure you wanna fight?",
        voiceline: `${tauntsPath}/Kumbhakarna_Taunt_1.aac`,
      },
      {
        taunt: "Too many little ones with silly ideas.",
        voiceline: `${tauntsPath}/Kumbhakarna_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Throw Back",
      },
      {
        name: "Groggy Strike",
      },
      {
        name: "Mighty Yawn",
      },
      {
        name: "Epic Uppercut",
      },
    ],
  },
  {
    name: "Kuzenbo",
    pantheon: "Japanese",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Kuzenbo.png",
    NJ_Voiceline: "assets/voicelines_NJ/Kuzenbo.aac",
    taunts: [
      {
        taunt: "I'm gonna kick your butt!",
        voiceline: `${tauntsPath}/Kuzenbo_Taunt_1.aac`,
      },
      {
        taunt: "I was born in the dankest of streams!",
        voiceline: `${tauntsPath}/Kuzenbo_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "NeNe Kappa",
      },
      {
        name: "Shell Spikes",
      },
      {
        name: "Sumo Slam",
      },
      {
        name: "Watery Grave",
      },
    ],
  },
  {
    name: "Lancelot",
    pantheon: "Arthurian",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Mobility", "High Area Damage"],
    image: "assets/icons/Lancelot.png",
    NJ_Voiceline: "assets/voicelines_NJ/Lancelot.aac",
    taunts: [
      {
        taunt: "Put your foolish ambitions to rest.",
        voiceline: `${tauntsPath}/Lancelot_Taunt_1.aac`,
      },
      {
        taunt:
          "There can be no hesitation to strike. But there is always the remorse that follows.",
        voiceline: `${tauntsPath}/Lancelot_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Piercing Thrust",
      },
      {
        name: "Skilled Strikes",
      },
      {
        name: "Quick Turn",
      },
      {
        name: "The Grand Joust",
      },
    ],
  },
  {
    name: "Loki",
    pantheon: "Norse",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Single Target Damage", "High Mobility"],
    image: "assets/icons/Loki.png",
    NJ_Voiceline: "assets/voicelines_NJ/Loki.aac",
    taunts: [
      {
        taunt: "Come a little closer, I have a secret for you!",
        voiceline: `${tauntsPath}/Loki_Taunt_1.aac`,
      },
      {
        taunt: "That was your best trick?",
        voiceline: `${tauntsPath}/Loki_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Vanish",
      },
      {
        name: "Agonizing Visions",
      },
      {
        name: "Flurry Strike",
      },
      {
        name: "Assassinate",
      },
    ],
  },
  {
    name: "Maman Brigitte",
    pantheon: "Voodoo",
    type: "Magical",
    range: "Melee",
    positions: ["Jungle"],
    pros: ["High Single Target Damage"],
    image: "assets/icons/MamanBrigitte.png",
    NJ_Voiceline: "assets/voicelines_NJ/MamanBrigitte.aac",
    taunts: [
      {
        taunt: "",
        voiceline: `${tauntsPath}/MamanBrigitte_Taunt_1.aac`,
      },
      {
        taunt: "",
        voiceline: `${tauntsPath}/MamanBrigitte_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Party Trick",
      },
      {
        name: "Madame Fangs",
      },
      {
        name: "Spirit Seize",
      },
      {
        name: "Explosion Of Souls",
      },
    ],
  },
  {
    name: "Martichoras",
    pantheon: "Greek",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["Medium Single Target Damage", "Medium Area Damage"],
    image: "assets/icons/Martichoras.png",
    NJ_Voiceline: "assets/voicelines_NJ/Martichoras.aac",
    taunts: [
      {
        taunt:
          "I care not for the politics of the gods. My only desire is to consume and you're next!",
        voiceline: `${tauntsPath}/Martichoras_Taunt_1.aac`,
      },
      {
        taunt: "Huh. So the hunter has become the hunted!",
        voiceline: `${tauntsPath}/Martichoras_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Acid Rain",
      },
      {
        name: "Poisoned Barb",
      },
      {
        name: "Stalk Prey",
      },
      {
        name: "Death From Above",
      },
    ],
  },
  {
    name: "Maui",
    pantheon: "Polynesian",
    type: "Magical",
    range: "Melee",
    positions: ["Support"],
    pros: ["High Crowd Control", "High Mobility", "High Defense"],
    image: "assets/icons/Maui.png",
    NJ_Voiceline: "assets/voicelines_NJ/Maui.aac",
    taunts: [
      {
        taunt:
          "Hohoho, wow! I thought the other gods would be way bigger, this is gonna be a breeze!",
        voiceline: `${tauntsPath}/Maui_Taunt_1.aac`,
      },
      {
        taunt:
          "Ahh, the last two buggars who doubted me ended up as rocks. You wanna be next?",
        voiceline: `${tauntsPath}/Maui_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Master Fisherman",
      },
      {
        name: "Mystical Ulua",
      },
      {
        name: "Solar Swing",
      },
      {
        name: "Landfall",
      },
    ],
  },
  {
    name: "Medusa",
    pantheon: "Greek",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Single Target Damage", "Medium Crowd Control"],
    image: "assets/icons/Medusa.png",
    NJ_Voiceline: "assets/voicelines_NJ/Medusa.aac",
    taunts: [
      {
        taunt: "Don't tread on me!",
        voiceline: `${tauntsPath}/Medusa_Taunt_1.aac`,
      },
      {
        taunt: "Not even death can save you from me!",
        voiceline: `${tauntsPath}/Medusa_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Viper Shot",
      },
      {
        name: "Acid Spray",
      },
      {
        name: "Lacerate",
      },
      {
        name: "Petrify",
      },
    ],
  },
  {
    name: "Mercury",
    pantheon: "Roman",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle"],
    pros: ["High Single Target Damage", "High Mobility", "High Attack Speed"],
    image: "assets/icons/Mercury.png",
    NJ_Voiceline: "assets/voicelines_NJ/Mercury.aac",
    taunts: [
      {
        taunt: "You are so awesome, I love what you do!",
        voiceline: `${tauntsPath}/Mercury_Taunt_1.aac`,
      },
      {
        taunt: "Another one bites the dust...",
        voiceline: `${tauntsPath}/Mercury_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Made You Look",
      },
      {
        name: "Maximum Velocity",
      },
      {
        name: "Special Delivery",
      },
      {
        name: "Sonic Boom",
      },
    ],
  },
  {
    name: "Merlin",
    pantheon: "Arthurian",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage", "Medium Crowd Control"],
    image: "assets/icons/Merlin.png",
    NJ_Voiceline: "assets/voicelines_NJ/Merlin.aac",
    taunts: [
      {
        taunt: "Prepare yourself villain, I will not hold back!",
        voiceline: `${tauntsPath}/Merlin_Taunt_1.aac`,
      },
      {
        taunt: "Expending any effort on you would be such a waste!",
        voiceline: `${tauntsPath}/Merlin_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Eclipse",
      },
      {
        name: "Vortex",
      },
      {
        name: "Flicker",
      },
      {
        name: "Elemental Mastery",
      },
    ],
  },
  {
    name: "Morgan Le Fay",
    pantheon: "Arthurian",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage", "Medium Crowd Control"],
    image: "assets/icons/MorganLeFay.png",
    NJ_Voiceline: "assets/voicelines_NJ/MorganLeFay.aac",
    taunts: [
      {
        taunt: "I will annihilate you utterly! Mind, body and soul!",
        voiceline: `${tauntsPath}/MorganLeFay_Taunt_1.aac`,
      },
      {
        taunt: "What's the matter? Nowhere left to run?",
        voiceline: `${tauntsPath}/MorganLeFay_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Sigil Mastery",
      },
      {
        name: "Dragonflight",
      },
      {
        name: "Shroud of Wildfire",
      },
      {
        name: "Consuming Power",
      },
    ],
  },
  {
    name: "Mulan",
    pantheon: "Chinese",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: ["High Area Damage", "High Crowd Control"],
    image: "assets/icons/Mulan.png",
    NJ_Voiceline: "assets/voicelines_NJ/Mulan.aac",
    taunts: [
      {
        taunt: "You fight and fight, but you never learn!",
        voiceline: `${tauntsPath}/Mulan_Taunt_1.aac`,
      },
      {
        taunt: "There is no need to taunt those that have no hope to win.",
        voiceline: `${tauntsPath}/Mulan_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Cross Strike",
      },
      {
        name: "Spear Thrust",
      },
      {
        name: "Grapple",
      },
      {
        name: "Divine Mastery",
      },
    ],
  },
  {
    name: "Ne Zha",
    pantheon: "Chinese",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: ["High Single Target Damage", "Medium Crowd Control"],
    image: "assets/icons/NeZha.png",
    NJ_Voiceline: "assets/voicelines_NJ/NeZha.aac",
    taunts: [
      {
        taunt: "Hah! You look much too old to catch me!",
        voiceline: `${tauntsPath}/NeZha_Taunt_1.aac`,
      },
      {
        taunt: "I'll take one for the team!",
        voiceline: `${tauntsPath}/NeZha_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Universe Ring Toss",
      },
      {
        name: "Flaming Spear",
      },
      {
        name: "Armillary Sash",
      },
      {
        name: "Wind Fire Wheels",
      },
    ],
  },
  {
    name: "Neith",
    pantheon: "Egyptian",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: [
      "Medium Crowd Control",
      "Medium Sustain",
      "High Single Target Damage",
    ],
    image: "assets/icons/Neith.png",
    NJ_Voiceline: "assets/voicelines_NJ/Neith.aac",
    taunts: [
      {
        taunt: "Aw, it's ok. It happens to everyone.",
        voiceline: `${tauntsPath}/Neith_Taunt_1.aac`,
      },
      {
        taunt: "Someone is getting a bit grumpy!",
        voiceline: `${tauntsPath}/Neith_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Spirit Arrow",
      },
      {
        name: "Unravel",
      },
      {
        name: "Back Flip",
      },
      {
        name: "World Weaver",
      },
    ],
  },
  {
    name: "Nemesis",
    pantheon: "Greek",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle"],
    pros: ["High Mobility", "High Single Target Damage"],
    image: "assets/icons/Nemesis.png",
    NJ_Voiceline: "assets/voicelines_NJ/Nemesis.aac",
    taunts: [
      {
        taunt: "Your pride will not go unpunished!",
        voiceline: `${tauntsPath}/Nemesis_Taunt_1.aac`,
      },
      {
        taunt: "Such arrogance to have challenged me!",
        voiceline: `${tauntsPath}/Nemesis_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Swift Vengeance",
      },
      {
        name: "Slice and Dice",
      },
      {
        name: "Retribution",
      },
      {
        name: "Divine Judgement",
      },
    ],
  },
  {
    name: "Nike",
    pantheon: "Greek",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: ["High Defense", "High Crowd Control"],
    image: "assets/icons/Nike.png",
    NJ_Voiceline: "assets/voicelines_NJ/Nike.aac",
    taunts: [
      {
        taunt: "Surrender now and I may just let you clean my greaves.",
        voiceline: `${tauntsPath}/Nike_Taunt_1.aac`,
      },
      {
        taunt:
          "Join me, or allow me to join your head to the ground under my foot.",
        voiceline: `${tauntsPath}/Nike_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Rend",
      },
      {
        name: "Barrier Formation",
      },
      {
        name: "Valiant Leap",
      },
      {
        name: "Sentinel of Zeus",
      },
    ],
  },
  {
    name: "Nox",
    pantheon: "Roman",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid", "Support"],
    pros: ["High Crowd Control", "High Area Damage"],
    image: "assets/icons/Nox.png",
    NJ_Voiceline: "assets/voicelines_NJ/Nox.aac",
    taunts: [
      {
        taunt: "You are never truly alone in the dark!",
        voiceline: `${tauntsPath}/Nox_Taunt_1.aac`,
      },
      {
        taunt: "Too close for comfort...",
        voiceline: `${tauntsPath}/Nox_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Shadow Lock",
      },
      {
        name: "Siphon Darkness",
      },
      {
        name: "Shadow Step",
      },
      {
        name: "Night Terror",
      },
    ],
  },
  {
    name: "Nu Wa",
    pantheon: "Chinese",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Single Target Damage", "High Area Damage"],
    image: "assets/icons/NuWa.png",
    NJ_Voiceline: "assets/voicelines_NJ/NuWa.aac",
    taunts: [
      {
        taunt: "Make your choice, and pay the price!",
        voiceline: `${tauntsPath}/NuWa_Taunt_1.aac`,
      },
      {
        taunt: "Be careful, I'm a slippery one!",
        voiceline: `${tauntsPath}/NuWa_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Mysterious Fog",
      },
      {
        name: "Clay Soldiers",
      },
      {
        name: "Shining Metal",
      },
      {
        name: "Fire Shards",
      },
    ],
  },
  {
    name: "Odin",
    pantheon: "Norse",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo", "Support"],
    pros: ["High Crowd Control", "High Area Damage"],
    image: "assets/icons/Odin.png",
    NJ_Voiceline: "assets/voicelines_NJ/Odin.aac",
    taunts: [
      {
        taunt: "Go back home, pup, and tell them to send a worthy opponent!",
        voiceline: `${tauntsPath}/Odin_Taunt_1.aac`,
      },
      {
        taunt: "Come fight me, my ravens shall feast on eyeballs and entrails!",
        voiceline: `${tauntsPath}/Odin_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Lunge",
      },
      {
        name: "Raven Shout",
      },
      {
        name: "Gungnir's Might",
      },
      {
        name: "Ring of Spears",
      },
    ],
  },
  {
    name: "Olorun",
    pantheon: "Yoruba",
    type: "Magical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Attack Speed", "High Crowd Control"],
    image: "assets/icons/Olorun.png",
    NJ_Voiceline: "assets/voicelines_NJ/Olorun.aac",
    taunts: [
      {
        taunt: "There can be no salvation for you. Just.. finality.",
        voiceline: `${tauntsPath}/Olorun_Taunt_1.aac`,
      },
      {
        taunt:
          "It will not be easy to burn away your impurities. But I will prevail.",
        voiceline: `${tauntsPath}/Olorun_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Focused Light",
      },
      {
        name: "Overflowing Divinity",
      },
      {
        name: "Consecration",
      },
      {
        name: "Sanctified Field",
      },
    ],
  },
  {
    name: "Osiris",
    pantheon: "Egyptian",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Defense", "High Attack Speed", "Medium Crowd Control"],
    image: "assets/icons/Osiris.png",
    NJ_Voiceline: "assets/voicelines_NJ/Osiris.aac",
    taunts: [
      {
        taunt: "Tonight we dine in the afterlife!",
        voiceline: `${tauntsPath}/Osiris_Taunt_1.aac`,
      },
      {
        taunt: "They nearly got the better of me!",
        voiceline: `${tauntsPath}/Osiris_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Sickle Strike",
      },
      {
        name: "Spirit Flail",
      },
      {
        name: "Judgement Tether",
      },
      {
        name: "Lord of the Afterlife",
      },
    ],
  },
  {
    name: "Pele",
    pantheon: "Polynesian",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Single Target Damage", "High Movement Speed"],
    image: "assets/icons/Pele.png",
    NJ_Voiceline: "assets/voicelines_NJ/Pele.aac",
    taunts: [
      {
        taunt:
          "I've been beaten many times by mighty beings. Luckily, you're no such thing.",
        voiceline: `${tauntsPath}/Pele_Taunt_1.aac`,
      },
      {
        taunt: "Don't get too close now, you can't handle this fire.",
        voiceline: `${tauntsPath}/Pele_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Pyroclast",
      },
      {
        name: "Eruption",
      },
      {
        name: "Magma Rush",
      },
      {
        name: "Volcanic Lightning",
      },
    ],
  },
  {
    name: "Persephone",
    pantheon: "Greek",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage", "High Crowd Control"],
    image: "assets/icons/Persephone.png",
    NJ_Voiceline: "assets/voicelines_NJ/Persephone.aac",
    taunts: [
      {
        taunt: "You have sown your own defeat. I merely tend to the downfall.",
        voiceline: `${tauntsPath}/Persephone_Taunt_1.aac`,
      },
      {
        taunt:
          "Normally, I see the value in every soul. You.. you may be the exception.",
        voiceline: `${tauntsPath}/Persephone_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Bone Rush",
      },
      {
        name: "Harvest",
      },
      {
        name: "Flourish",
      },
      {
        name: "Grasp of Death",
      },
    ],
  },
  {
    name: "Poseidon",
    pantheon: "Greek",
    type: "Magical",
    range: "Ranged",
    positions: ["Jungle", "Mid", "ADC"],
    pros: ["High Area Damage", "High Movement Speed"],
    image: "assets/icons/Poseidon.png",
    NJ_Voiceline: "assets/voicelines_NJ/Poseidon.aac",
    taunts: [
      {
        taunt:
          "Mmmm... You look kind of small, I should probably throw you back!",
        voiceline: `${tauntsPath}/Poseidon_Taunt_1.aac`,
      },
      {
        taunt: "That was a bit close for comfort!",
        voiceline: `${tauntsPath}/Poseidon_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Tidal Surge",
      },
      {
        name: "Trident",
      },
      {
        name: "Whirlpool",
      },
      {
        name: "Release the Kraken!",
      },
    ],
  },
  {
    name: "Ra",
    pantheon: "Egyptian",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Sustain", "High Movement Speed", "High Area Damage"],
    image: "assets/icons/Ra.png",
    NJ_Voiceline: "assets/voicelines_NJ/Ra.aac",
    taunts: [
      {
        taunt: "Time for your daily dose of vitamin D...struction.",
        voiceline: `${tauntsPath}/Ra_Taunt_1.aac`,
      },
      {
        taunt: "Ugh, let's not do that again.",
        voiceline: `${tauntsPath}/Ra_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Celestial Beam",
      },
      {
        name: "Divine Light",
      },
      {
        name: "Solar Blessing",
      },
      {
        name: "Searing Pain",
      },
    ],
  },
  {
    name: "Raijin",
    pantheon: "Japanese",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Mobility", "High Area Damage"],
    image: "assets/icons/Raijin.png",
    NJ_Voiceline: "assets/voicelines_NJ/Raijin.aac",
    taunts: [
      {
        taunt:
          "If you surround me and attack at once, one of you may get lucky!",
        voiceline: `${tauntsPath}/Raijin_Taunt_1.aac`,
      },
      {
        taunt: "Try fighting me five-on-one.",
        voiceline: `${tauntsPath}/Raijin_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Percussive Storm",
      },
      {
        name: "Raiju",
      },
      {
        name: "Thunder Crash",
      },
      {
        name: "Taiko Drums",
      },
    ],
  },
  {
    name: "Rama",
    pantheon: "Hindu",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Mobility", "High Attack Speed", "High Single Target Damage"],
    image: "assets/icons/Rama.png",
    NJ_Voiceline: "assets/voicelines_NJ/Rama.aac",
    taunts: [
      {
        taunt: "You seem to have underestimated my ability to destroy you!",
        voiceline: `${tauntsPath}/Rama_Taunt_1.aac`,
      },
      {
        taunt: "You will regret getting in my way!",
        voiceline: `${tauntsPath}/Rama_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Astral Strike",
      },
      {
        name: "Infinite Arrows",
      },
      {
        name: "Rolling Assault",
      },
      {
        name: "Astral Barrage",
      },
    ],
  },
  {
    name: "Ratatoskr",
    pantheon: "Norse",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Mobility", "High Area Damage"],
    image: "assets/icons/Ratatoskr.png",
    NJ_Voiceline: "assets/voicelines_NJ/Ratatoskr.aac",
    taunts: [
      {
        taunt: "Wow, you're strong like an ogre!.. when it's a baby!",
        voiceline: `${tauntsPath}/Ratatoskr_Taunt_1.aac`,
      },
      {
        taunt: "Wow, I... would never have guessed you were an actual god!",
        voiceline: `${tauntsPath}/Ratatoskr_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Dart",
      },
      {
        name: "Flurry",
      },
      {
        name: "Acorn Blast",
      },
      {
        name: "Through the Cosmos",
      },
    ],
  },
  {
    name: "Ravana",
    pantheon: "Hindu",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Single Target Damage", "Medium Sustain", "High Mobility"],
    image: "assets/icons/Ravana.png",
    NJ_Voiceline: "assets/voicelines_NJ/Ravana.aac",
    taunts: [
      {
        taunt: "Why do you persist when you know it is pointless?",
        voiceline: `${tauntsPath}/Ravana_Taunt_1.aac`,
      },
      {
        taunt:
          "Don't expect me to wish you health and long life, you'll have neither.",
        voiceline: `${tauntsPath}/Ravana_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Prana Onslaught",
      },
      {
        name: "Overhead Kick",
      },
      {
        name: "10-Hand Shadow Fist",
      },
      {
        name: "Mystic Rush",
      },
    ],
  },
  {
    name: "Scylla",
    pantheon: "Greek",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage"],
    image: "assets/icons/Scylla.png",
    NJ_Voiceline: "assets/voicelines_NJ/Scylla.aac",
    taunts: [
      {
        taunt: "Is it hard to be so immortally stupid?!",
        voiceline: `${tauntsPath}/Scylla_Taunt_1.aac`,
      },
      {
        taunt: "Yay, more for me!",
        voiceline: `${tauntsPath}/Scylla_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Sic 'Em",
      },
      {
        name: "Crush",
      },
      {
        name: "Sentinel",
      },
      {
        name: "I'm a Monster",
      },
    ],
  },
  {
    name: "Serqet",
    pantheon: "Egyptian",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Support"],
    pros: ["High Single Target Damage", "High Mobility", "High Crowd Control"],
    image: "assets/icons/Serqet.png",
    NJ_Voiceline: "assets/voicelines_NJ/Serqet.aac",
    taunts: [
      {
        taunt: "Aww! Is that the best you can do?",
        voiceline: `${tauntsPath}/Serqet_Taunt_1.aac`,
      },
      {
        taunt: "I feel a bit squashed!",
        voiceline: `${tauntsPath}/Serqet_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Deathbane",
      },
      {
        name: "Cobra's Kiss",
      },
      {
        name: "Ambush",
      },
      {
        name: "Last Breath",
      },
    ],
  },
  {
    name: "Set",
    pantheon: "Egyptian",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Mid", "Solo"],
    pros: ["High Single Target Damage", "High Mobility"],
    image: "assets/icons/Set.png",
    NJ_Voiceline: "assets/voicelines_NJ/Set.aac",
    taunts: [
      {
        taunt: "I am never outnumbered. I can always rely on me, myself and I.",
        voiceline: `${tauntsPath}/Set_Taunt_1.aac`,
      },
      {
        taunt: "There is no fun in taking from a weak enemy!",
        voiceline: `${tauntsPath}/Set_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Skewer",
      },
      {
        name: "Spawn of Set",
      },
      {
        name: "Sandstorm",
      },
      {
        name: "Kingslayer",
      },
    ],
  },
  {
    name: "Shiva",
    pantheon: "Hindu",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Crowd Control", "High Mobility", "Medium Sustain"],
    image: "assets/icons/Shiva.png",
    NJ_Voiceline: "assets/voicelines_NJ/Shiva.aac",
    taunts: [
      {
        taunt: "Your arrogance will not be tolerated!",
        voiceline: `${tauntsPath}/Shiva_Taunt_1.aac`,
      },
      {
        taunt: "From peace arises destruction!",
        voiceline: `${tauntsPath}/Shiva_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Emblazoned Sweep",
      },
      {
        name: "Damaru's Tempo",
      },
      {
        name: "Pillar of Dawn",
      },
      {
        name: "Shiva Tandava",
      },
    ],
  },
  {
    name: "Skadi",
    pantheon: "Norse",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Crowd Control", "High Single Target Damage"],
    image: "assets/icons/Skadi.png",
    NJ_Voiceline: "assets/voicelines_NJ/Skadi.aac",
    taunts: [
      {
        taunt: "What doesn't kill me makes me stronger.",
        voiceline: `${tauntsPath}/Skadi_Taunt_1.aac`,
      },
      {
        taunt: "The seasons change...",
        voiceline: `${tauntsPath}/Skadi_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Piercing Cold",
      },
      {
        name: "Rune of the Hunt",
      },
      {
        name: "Permafrost",
      },
      {
        name: "Winter's Grasp",
      },
    ],
  },
  {
    name: "Sobek",
    pantheon: "Egyptian",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Sobek.png",
    NJ_Voiceline: "assets/voicelines_NJ/Sobek.aac",
    taunts: [
      {
        taunt: "I grab hold and don't let go 'til the kicking stops.",
        voiceline: `${tauntsPath}/Sobek_Taunt_1.aac`,
      },
      {
        taunt:
          "Somebody's gonna pay for all those boots and handbags... Might as well be you!",
        voiceline: `${tauntsPath}/Sobek_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Charge Prey",
      },
      {
        name: "Tail Whip",
      },
      {
        name: "Sickening Strike",
      },
      {
        name: "Lurking in the Waters",
      },
    ],
  },
  {
    name: "Sol",
    pantheon: "Norse",
    type: "Magical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Single Target Damage", "High Mobility", "Medium Sustain"],
    image: "assets/icons/Sol.png",
    NJ_Voiceline: "assets/voicelines_NJ/Sol.aac",
    taunts: [
      {
        taunt: "Do you want to play? I'm game.",
        voiceline: `${tauntsPath}/Sol_Taunt_1.aac`,
      },
      {
        taunt: "You can't kill me, I'm not even really here.",
        voiceline: `${tauntsPath}/Sol_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Radiance",
      },
      {
        name: "Stellar Burst",
      },
      {
        name: "Disapparate",
      },
      {
        name: "Supernova",
      },
    ],
  },
  {
    name: "Sun Wukong",
    pantheon: "Chinese",
    type: "Physical",
    range: "Melee",
    positions: ["Solo"],
    pros: ["High Defense", "High Mobility", "High Crowd Control"],
    image: "assets/icons/SunWukong.png",
    NJ_Voiceline: "assets/voicelines_NJ/SunWukong.aac",
    taunts: [
      {
        taunt: "You do talk big, don't you little immortal!",
        voiceline: `${tauntsPath}/SunWukong_Taunt_1.aac`,
      },
      {
        taunt: "There is nowhere I cannot go, yet I choose to be here!",
        voiceline: `${tauntsPath}/SunWukong_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "The Magic Cudgel",
      },
      {
        name: "Master's Will",
      },
      {
        name: "72 Transformations",
      },
      {
        name: "Somersault Cloud",
      },
    ],
  },
  {
    name: "Surtr",
    pantheon: "Norse",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Crowd Control", "High Area Damage"],
    image: "assets/icons/Surtr.png",
    NJ_Voiceline: "assets/voicelines_NJ/Surtr.aac",
    taunts: [
      {
        taunt: "You are but a fleeting memory to pass and be forgotten.",
        voiceline: `${tauntsPath}/Surtr_Taunt_1.aac`,
      },
      {
        taunt: "There is no escaping inevitability. I was destined to win!",
        voiceline: `${tauntsPath}/Surtr_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Flames of Muspell",
      },
      {
        name: "Giant's Grasp",
      },
      {
        name: "Emberwalk",
      },
      {
        name: "End of Days",
      },
    ],
  },
  {
    name: "Susano",
    pantheon: "Japanese",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Crowd Control", "High Mobility", "High Area Damage"],
    image: "assets/icons/Susano.png",
    NJ_Voiceline: "assets/voicelines_NJ/Susano.aac",
    taunts: [
      {
        taunt: "I'll give you this last chance to run.",
        voiceline: `${tauntsPath}/Susano_Taunt_1.aac`,
      },
      {
        taunt: "Alright, kid. You want a fight? I'll give you one.",
        voiceline: `${tauntsPath}/Susano_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Storm Kata",
      },
      {
        name: "Wind Siphon",
      },
      {
        name: "Jet Stream",
      },
      {
        name: "Typhoon",
      },
    ],
  },
  {
    name: "Sylvanus",
    pantheon: "Roman",
    type: "Magical",
    range: "Ranged",
    positions: ["Solo", "Support"],
    pros: ["High Crowd Control", "High Sustain", "High Defense"],
    image: "assets/icons/Sylvanus.png",
    NJ_Voiceline: "assets/voicelines_NJ/Sylvanus.aac",
    taunts: [
      {
        taunt: "No need to beat around the bush!",
        voiceline: `${tauntsPath}/Sylvanus_Taunt_1.aac`,
      },
      {
        taunt: "Nature will find a way...",
        voiceline: `${tauntsPath}/Sylvanus_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Verdant Growth",
      },
      {
        name: "Wisps",
      },
      {
        name: "Nature's Grasp",
      },
      {
        name: "Wrath of Terra",
      },
    ],
  },
  {
    name: "Terra",
    pantheon: "Roman",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Sustain", "High Area Damage", "High Crowd Control"],
    image: "assets/icons/Terra.png",
    NJ_Voiceline: "assets/voicelines_NJ/Terra.aac",
    taunts: [
      {
        taunt: "I am not just a god! No! I am so. much. more.",
        voiceline: `${tauntsPath}/Terra_Taunt_1.aac`,
      },
      {
        taunt:
          "These gods act like spoiled children, and will be treated as such!",
        voiceline: `${tauntsPath}/Terra_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Force of Nature",
      },
      {
        name: "Crushing Earth",
      },
      {
        name: "Monolith",
      },
      {
        name: "Earthen Fury",
      },
    ],
  },
  {
    name: "Thanatos",
    pantheon: "Greek",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle"],
    pros: ["High Sustain", "High Single Target Damage", "High Movement Speed"],
    image: "assets/icons/Thanatos.png",
    NJ_Voiceline: "assets/voicelines_NJ/Thanatos.aac",
    taunts: [
      {
        taunt: "Let me release you from your pain!",
        voiceline: `${tauntsPath}/Thanatos_Taunt_1.aac`,
      },
      {
        taunt: "Mine is the last face you will ever see!",
        voiceline: `${tauntsPath}/Thanatos_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Death Scythe",
      },
      {
        name: "Scent of Death",
      },
      {
        name: "Soul Reap",
      },
      {
        name: "Hovering Death",
      },
    ],
  },
  {
    name: "The Morrigan",
    pantheon: "Celtic",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Single Target Damage"],
    image: "assets/icons/TheMorrigan.png",
    NJ_Voiceline: "assets/voicelines_NJ/TheMorrigan.aac",
    taunts: [
      {
        taunt:
          "Take a lesson from nature: Beautiful things can be quite dangerous!",
        voiceline: `${tauntsPath}/TheMorrigan_Taunt_1.aac`,
      },
      {
        taunt: "If you've come this far, you're already doomed!",
        voiceline: `${tauntsPath}/TheMorrigan_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Deadly Aspects",
      },
      {
        name: "Dark Omen",
      },
      {
        name: "Confusion",
      },
      {
        name: "Changeling",
      },
    ],
  },
  {
    name: "Thor",
    pantheon: "Norse",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Area Damage", "Medium Crowd Control", "High Mobility"],
    image: "assets/icons/Thor.png",
    NJ_Voiceline: "assets/voicelines_NJ/Thor.aac",
    taunts: [
      {
        taunt: "By father's beard, that was close!",
        voiceline: `${tauntsPath}/Thor_Taunt_1.aac`,
      },
      {
        taunt: "This is a fight fit for song!",
        voiceline: `${tauntsPath}/Thor_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Mjolnir's Attunement",
      },
      {
        name: "Tectonic Rift",
      },
      {
        name: "Berserker Barrage",
      },
      {
        name: "Anvil of Dawn",
      },
    ],
  },
  {
    name: "Thoth",
    pantheon: "Egyptian",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage"],
    image: "assets/icons/Thoth.png",
    NJ_Voiceline: "assets/voicelines_NJ/Thoth.aac",
    taunts: [
      {
        taunt: "Your soul is heavy; allow me to unburden you!",
        voiceline: `${tauntsPath}/Thoth_Taunt_1.aac`,
      },
      {
        taunt: "I have been judged the victor!",
        voiceline: `${tauntsPath}/Thoth_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Hieroglyphic Assault",
      },
      {
        name: "Evade and Punish",
      },
      {
        name: "Glyph of Pain",
      },
      {
        name: "Final Judgement",
      },
    ],
  },
  {
    name: "Tiamat",
    pantheon: "Babylonian",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage"],
    image: "assets/icons/Tiamat.png",
    NJ_Voiceline: "assets/voicelines_NJ/Tiamat.aac",
    taunts: [
      {
        taunt: "Insufferable! Each less worthy than the last!",
        voiceline: `${tauntsPath}/Tiamat_Taunt_1.aac`,
      },
      {
        taunt:
          "I am, and have always been, a creator. But I will relish in your destruction!",
        voiceline: `${tauntsPath}/Tiamat_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Primordial Onslaught",
      },
      {
        name: "Ruination",
      },
      {
        name: "Rising Flight",
      },
      {
        name: "Tempest of Undoing",
      },
    ],
  },
  {
    name: "Tsukuyomi",
    pantheon: "Japanese",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Single Target Damage", "Medium Crowd Control"],
    image: "assets/icons/Tsukuyomi.png",
    NJ_Voiceline: "assets/voicelines_NJ/Tsukuyomi.aac",
    taunts: [
      {
        taunt: "If you grovel now, I may spare you.",
        voiceline: `${tauntsPath}/Tsukuyomi_Taunt_1.aac`,
      },
      {
        taunt:
          "My will is the only will that matters. Your petty goals will bend to me.",
        voiceline: `${tauntsPath}/Tsukuyomi_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Dark Moon Shuriken",
      },
      {
        name: "Kusarigama",
      },
      {
        name: "Silver Moon Caltrops",
      },
      {
        name: "Piercing Moonlight",
      },
    ],
  },
  {
    name: "Tyr",
    pantheon: "Norse",
    type: "Physical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: [
      "High Defense",
      "High Crowd Control",
      "High Mobility",
      "High Sustain",
    ],
    image: "assets/icons/Tyr.png",
    NJ_Voiceline: "assets/voicelines_NJ/Tyr.aac",
    taunts: [
      {
        taunt: "You have but one chance to surrender!",
        voiceline: `${tauntsPath}/Tyr_Taunt_1.aac`,
      },
      {
        taunt: "You chose this, remember that.",
        voiceline: `${tauntsPath}/Tyr_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Fearless",
      },
      {
        name: "Power Cleave",
      },
      {
        name: "Change Stance",
      },
      {
        name: "Lawbringer",
      },
    ],
  },
  {
    name: "Ullr",
    pantheon: "Norse",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC", "Mid"],
    pros: ["High Single Target Damage", "High Mobility"],
    image: "assets/icons/Ullr.png",
    NJ_Voiceline: "assets/voicelines_NJ/Ullr.aac",
    taunts: [
      {
        taunt: "Fight me with honor!",
        voiceline: `${tauntsPath}/Ullr_Taunt_1.aac`,
      },
      {
        taunt: "Your defeat will be a glorious one!",
        voiceline: `${tauntsPath}/Ullr_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Bladed Arrow",
      },
      {
        name: "Invigorate",
      },
      {
        name: "Glory Bound",
      },
      {
        name: "Wield Bow",
      },
    ],
  },
  {
    name: "Vamana",
    pantheon: "Hindu",
    type: "Physical",
    range: "Melee",
    positions: ["Jungle", "Solo"],
    pros: ["High Defense", "High Sustain", "High Single Target Damage"],
    image: "assets/icons/Vamana.png",
    NJ_Voiceline: "assets/voicelines_NJ/Vamana.aac",
    taunts: [
      {
        taunt: "It's my strength of feet!",
        voiceline: `${tauntsPath}/Vamana_Taunt_1.aac`,
      },
      {
        taunt: "Your defeat is gonna be awful!",
        voiceline: `${tauntsPath}/Vamana_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Clear the Path",
      },
      {
        name: "Armored Umbrella",
      },
      {
        name: "Armored Umbrella",
      },
      {
        name: "Colossal Fury",
      },
    ],
  },
  {
    name: "Vulcan",
    pantheon: "Roman",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage"],
    image: "assets/icons/Vulcan.png",
    NJ_Voiceline: "assets/voicelines_NJ/Vulcan.aac",
    taunts: [
      {
        taunt: "Good thing... most of me is still under warranty!",
        voiceline: `${tauntsPath}/Vulcan_Taunt_1.aac`,
      },
      {
        taunt:
          "It's said that in this world, a man must either be an anvil or hammer. You're looking at a hammer!",
        voiceline: `${tauntsPath}/Vulcan_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Backfire",
      },
      {
        name: "Inferno Cannon",
      },
      {
        name: "Magma Bomb",
      },
      {
        name: "Earthshaker",
      },
    ],
  },
  {
    name: "Xbalanque",
    pantheon: "Maya",
    type: "Physical",
    range: "Ranged",
    positions: ["ADC"],
    pros: ["High Single Target Damage", "Medium Area Damage"],
    image: "assets/icons/Xbalanque.png",
    NJ_Voiceline: "assets/voicelines_NJ/Xbalanque.aac",
    taunts: [
      {
        taunt:
          "When the world closes in on you, I will be there, and you will know fear!",
        voiceline: `${tauntsPath}/Xbalanque_Taunt_1.aac`,
      },
      {
        taunt: "Ugh, I need to rethink my moves...",
        voiceline: `${tauntsPath}/Xbalanque_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Branching Bola",
      },
      {
        name: "Poison Darts",
      },
      {
        name: "Rising Jaguar",
      },
      {
        name: "Darkest of Nights",
      },
    ],
  },
  {
    name: "Xing Tian",
    pantheon: "Chinese",
    type: "Magical",
    range: "Melee",
    positions: ["Solo", "Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/XingTian.png",
    NJ_Voiceline: "assets/voicelines_NJ/XingTian.aac",
    taunts: [
      {
        taunt: "I pity you who must fight even when you cannot win.",
        voiceline: `${tauntsPath}/XingTian_Taunt_1.aac`,
      },
      {
        taunt:
          "When riff raff join forces with cowards, what can they accomplish?",
        voiceline: `${tauntsPath}/XingTian_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Furious Roar",
      },
      {
        name: "Hook Slam",
      },
      {
        name: "Sky-Cutting Axe",
      },
      {
        name: "Whirlwind of Rage and Steel",
      },
    ],
  },
  {
    name: "Yemoja",
    pantheon: "Yoruba",
    type: "Magical",
    range: "Ranged",
    positions: ["Support"],
    pros: ["High Sustain", "High Crowd Control", "High Defense"],
    image: "assets/icons/Yemoja.png",
    NJ_Voiceline: "assets/voicelines_NJ/Yemoja.aac",
    taunts: [
      {
        taunt: "Your each step defiles the ground upon which it touches.",
        voiceline: `${tauntsPath}/Yemoja_Taunt_1.aac`,
      },
      {
        taunt:
          "Learn. Adapt. And move with the tide! Do not be so set in your ways like a stubborn rock.",
        voiceline: `${tauntsPath}/Yemoja_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Moonstrike",
      },
      {
        name: "Mending Waters",
      },
      {
        name: "Riptide",
      },
      {
        name: "River's Rebuke",
      },
    ],
  },
  {
    name: "Ymir",
    pantheon: "Norse",
    type: "Magical",
    range: "Melee",
    positions: ["Jungle", "Support"],
    pros: ["High Crowd Control", "High Defense"],
    image: "assets/icons/Ymir.png",
    NJ_Voiceline: "assets/voicelines_NJ/Ymir.aac",
    taunts: [
      {
        taunt: "I am unstoppable force and, immovable mountain.",
        voiceline: `${tauntsPath}/Ymir_Taunt_1.aac`,
      },
      {
        taunt: "That hurt!",
        voiceline: `${tauntsPath}/Ymir_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Ice Wall",
      },
      {
        name: "Glacial Strike",
      },
      {
        name: "Frost Breath",
      },
      {
        name: "Shards of Ice",
      },
    ],
  },
  {
    name: "Yu Huang",
    pantheon: "Chinese",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage", "High Mobility"],
    image: "assets/icons/YuHuang.png",
    NJ_Voiceline: "assets/voicelines_NJ/YuHuang.aac",
    taunts: [
      {
        taunt:
          "Return to me after some meditation. A few billion years should be enough time to approach me.",
        voiceline: `${tauntsPath}/YuHuang_Taunt_1.aac`,
      },
      {
        taunt:
          "I have defeated evil entities stronger than gods, who are you to challenge me?",
        voiceline: `${tauntsPath}/YuHuang_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Flames of the Phoenix",
      },
      {
        name: "Dao Cultivation",
      },
      {
        name: "Celestial Flight",
      },
      {
        name: "Dueling Dragons",
      },
    ],
  },
  {
    name: "Zeus",
    pantheon: "Greek",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid"],
    pros: ["High Area Damage"],
    image: "assets/icons/Zeus.png",
    NJ_Voiceline: "assets/voicelines_NJ/Zeus.aac",
    taunts: [
      {
        taunt: "Surrender now or fall forever!",
        voiceline: `${tauntsPath}/Zeus_Taunt_1.aac`,
      },
      {
        taunt: "You've chosen this path... and chosen poorly!",
        voiceline: `${tauntsPath}/Zeus_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Chain Lightning",
      },
      {
        name: "Aegis Assault",
      },
      {
        name: "Detonate Charge",
      },
      {
        name: "Lightning Storm",
      },
    ],
  },
  {
    name: "Zhong Kui",
    pantheon: "Chinese",
    type: "Magical",
    range: "Ranged",
    positions: ["Mid", "Solo"],
    pros: ["Medium Crowd Control", "High Area Damage"],
    image: "assets/icons/ZhongKui.png",
    NJ_Voiceline: "assets/voicelines_NJ/ZhongKui.aac",
    taunts: [
      {
        taunt: "The evil inside you is screaming to get out!",
        voiceline: `${tauntsPath}/ZhongKui_Taunt_1.aac`,
      },
      {
        taunt: "Despair, foul demons. The underworld calls!",
        voiceline: `${tauntsPath}/ZhongKui_Taunt_2.aac`,
      },
    ],
    abilities: [
      {
        name: "Expose Evil",
      },
      {
        name: "Exorcism",
      },
      {
        name: "Book of Demons",
      },
      {
        name: "Recall Demons",
      },
    ],
  },
];

export { gods };
