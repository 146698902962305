export interface VGS {
  command: string;
  commandPS?: string;
  commandXbox?: string;
  line: string;
}

export const vgsLines: VGS[] = [
  {
    command: "VA1",
    line: "Attack left lane!",
    commandPS: "X R",
    commandXbox: "A X",
  },
  {
    command: "VA2",
    line: "Attack middle lane!",
    commandPS: "X T",
    commandXbox: "A Y",
  },
  {
    command: "VA3",
    line: "Attack right lane!",
    commandPS: "X C",
    commandXbox: "A B",
  },
  {
    command: "VAA",
    line: "Attack!",
    commandPS: "X X X",
    commandXbox: "A A A",
  },
  {
    command: "VAF",
    line: "Attack Fire Giant!",
    commandPS: "X X R",
    commandXbox: "A A X",
  },
  {
    command: "VAG",
    line: "Attack the Gold Fury!",
    commandPS: "X X C",
    commandXbox: "A A B",
  },
  {
    command: "VAM",
    line: "Attack the Titan!",
    commandPS: "X X T",
    commandXbox: "A A Y",
  },
  {
    command: "VAT1",
    line: "Attack left tower/phoenix",
    commandPS: "X R1 R1 R",
    commandXbox: "A RB RB X",
  },
  {
    command: "VAT2",
    line: "Attack middle tower/phoenix",
    commandPS: "X R1 R1 T",
    commandXbox: "A RB RB Y",
  },
  {
    command: "VAT3",
    line: "Attack right tower/phoenix",
    commandPS: "X R1 R1 C",
    commandXbox: "A RB RB B",
  },

  {
    command: "VB1",
    line: "Enemies in left lane!",
    commandPS: "C R1 R R",
    commandXbox: "B RB X X",
  },
  {
    command: "VB2",
    line: "Enemies in middle lane!",
    commandPS: "C R1 R T",
    commandXbox: "B RB X Y",
  },
  {
    command: "VB3",
    line: "Enemies in right lane!",
    commandPS: "C R1 R C",
    commandXbox: "B RB X B",
  },
  {
    command: "VBA",
    line: "Enemy ultimate incoming!",
    commandPS: "L1 T C",
    commandXbox: "LB Y B",
  },
  {
    command: "VBB",
    line: "Enemies have returned to base.",
    commandPS: "C R1 T X",
    commandXbox: "B RB X A",
  },
  {
    command: "VBD",
    line: "Enemy ultimate down!",
    commandPS: "L1 T X",
    commandXbox: "LB Y A",
  },
  {
    command: "VBE",
    line: "Enemies behind us!",
    commandPS: "C R1 C X",
    commandXbox: "LB LB X",
  },
  {
    command: "VBF",
    line: "Enemies at the Fire Giant!",
    commandPS: "C R1 T R",
    commandXbox: "B RB Y X",
  },
  {
    command: "VBG",
    line: "Enemies at the Gold Fury!",
    commandPS: "C R1 T C",
    commandXbox: "B RB Y B",
  },
  {
    command: "VBJ1",
    line: "Enemies in the Left Jungle!",
    commandPS: "C R1 C R",
    commandXbox: "B RB B X",
  },
  {
    command: "VBJ3",
    line: "Enemies in the Right Jungle!",
    commandPS: "C R1 C C",
    commandXbox: "B RB B B",
  },
  {
    command: "VBJJ",
    line: "Enemies in the jungle!",
    commandPS: "C R1 C T",
    commandXbox: "B RB B Y",
  },
  {
    command: "VBM",
    line: "Enemies at our Titan!",
    commandPS: "C R1 T T",
    commandXbox: "B RB Y Y",
  },
  {
    command: "VBS",
    line: "Enemies spotted!",
    commandPS: "C R1 T X",
    commandXbox: "B RB Y A",
  },

  {
    command: "VC1",
    line: "Be careful left!",
    commandPS: "L1 R1 R",
    commandXbox: "LB RB X",
  },
  {
    command: "VC2",
    line: "Be careful middle!",
    commandPS: "L1 R1 T",
    commandXbox: "LB RB Y",
  },
  {
    command: "VC3",
    line: "Be careful right!",
    commandPS: "L1 R1 C",
    commandXbox: "LB RB B",
  },
  {
    command: "VCB",
    line: "Return to base!",
    commandPS: "L1 L1 T X",
    commandXbox: "LB LB Y A",
  },
  {
    command: "VCC",
    line: "Be careful!",
    commandPS: "L1 C X",
    commandXbox: "LB B A",
  },
  {
    command: "VCJ",
    line: "Be careful in the jungle!",
    commandPS: "L1 R1 X",
    commandXbox: "LB RB A",
  },

  {
    command: "VD1",
    line: "Defend left lane!",
    commandPS: "R1 X R",
    commandXbox: "RB A X",
  },
  {
    command: "VD2",
    line: "Defend middle lane!",
    commandPS: "R1 X T",
    commandXbox: "RB A Y",
  },
  {
    command: "VD3",
    line: "Defend right lane!",
    commandPS: "R1 X C",
    commandXbox: "RB A B",
  },
  {
    command: "VDD",
    line: "Defend!",
    commandPS: "R1 X X X",
    commandXbox: "RB A A A",
  },
  {
    command: "VDF",
    line: "Defend the Fire Giant!",
    commandPS: "R1 X X R",
    commandXbox: "RB A A X",
  },
  {
    command: "VDG",
    line: "Defend the Gold Fury!",
    commandPS: "R1 X X C",
    commandXbox: "RB A A B",
  },
  {
    command: "VDM",
    line: "Defend the Titan!",
    commandPS: "R1 X X T",
    commandXbox: "RB A A Y",
  },

  {
    command: "VEA",
    line: "Awesome!",
    commandPS: "T R R",
    commandXbox: "Y X X",
  },
  {
    command: "VEG",
    line: "I'm the greatest!",
    commandPS: "T R X",
    commandXbox: "Y X A",
  },
  {
    command: "VEJ",
    line: "Joke",
    commandPS: "R R",
    commandXbox: "X X",
  },
  {
    command: "VEL",
    line: "Laugh",
    commandPS: "R C R",
    commandXbox: "X B X",
  },
  {
    command: "VER",
    line: "You rock!",
    commandPS: "T R C",
    commandXbox: "Y X B",
  },
  {
    command: "VET",
    line: "Taunt",
    commandPS: "R T",
    commandXbox: "X Y",
  },
  {
    command: "VEW",
    line: "Woohoo!",
    commandPS: "T R T",
    commandXbox: "Y X Y",
  },

  {
    command: "VF1",
    line: "Enemy missing left!",
    commandPS: "C R",
    commandXbox: "B X",
  },
  {
    command: "VF2",
    line: "Enemy missing middle!",
    commandPS: "C T",
    commandXbox: "B Y",
  },
  {
    command: "VF3",
    line: "Enemy missing right!",
    commandPS: "C C",
    commandXbox: "B B",
  },
  {
    command: "VFF",
    line: "Enemy missing!",
    commandPS: "C R1 R1",
    commandXbox: "B RB RB",
  },

  {
    command: "VG1",
    line: "Gank left lane!",
    commandPS: "R1 R R",
    commandXbox: "RB X X",
  },
  {
    command: "VG2",
    line: "Gank middle lane!",
    commandPS: "R1 R T",
    commandXbox: "RB X Y",
  },
  {
    command: "VG3",
    line: "Gank right lane!",
    commandPS: "R1 R C",
    commandXbox: "RB X B",
  },
  {
    command: "VGG",
    line: "Gank!",
    commandPS: "L1 L1 R X",
    commandXbox: "LB LB X A",
  },

  {
    command: "VH1",
    line: "Help left lane!",
    commandPS: "R1 T R",
    commandXbox: "RB Y X",
  },
  {
    command: "VH2",
    line: "Help middle lane!",
    commandPS: "R1 T T",
    commandXbox: "RB Y Y",
  },
  {
    command: "VH3",
    line: "Help right lane!",
    commandPS: "R1 T C",
    commandXbox: "RB Y B",
  },
  {
    command: "VHH",
    line: "Help!",
    commandPS: "R1 T X",
    commandXbox: "RB Y A",
  },
  {
    command: "VHS",
    line: "Need Healing!",
    commandPS: "T L1 R",
    commandXbox: "Y LB X",
  },

  {
    command: "VI1",
    line: "Enemies incoming left!",
    commandPS: "C R1 X R",
    commandXbox: "B RB A X",
  },
  {
    command: "VI2",
    line: "Enemies incoming middle!",
    commandPS: "C R1 X T",
    commandXbox: "B RB A Y",
  },
  {
    command: "VI3",
    line: "Enemies incoming right!",
    commandPS: "C R1 X C",
    commandXbox: "B RB A B",
  },
  {
    command: "VII",
    line: "Enemies incoming!",
    commandPS: "C R1 X X",
    commandXbox: "B RB A A",
  },

  {
    command: "VQ1",
    line: "Ward left!",
    commandPS: "R1 C R",
    commandXbox: "RB B X",
  },
  {
    command: "VQ2",
    line: "Ward middle!",
    commandPS: "R1 C T",
    commandXbox: "RB B Y",
  },
  {
    command: "VQ3",
    line: "Ward right!",
    commandPS: "R1 C C",
    commandXbox: "RB B B",
  },
  {
    command: "VQF",
    line: "Ward Fire Giant!",
    commandPS: "R1 C X R",
    commandXbox: "RB B A X",
  },
  {
    command: "VQG",
    line: "Ward Gold Fury!",
    commandPS: "R1 C X C",
    commandXbox: "RB B A B",
  },
  {
    command: "VQN",
    line: "Need Wards!",
    commandPS: "R1 C X T",
    commandXbox: "RB B A Y",
  },
  {
    command: "VQQ",
    line: "Ward Here!",
    commandPS: "R1 C X X",
    commandXbox: "RB B A A",
  },

  {
    command: "VR1",
    line: "Retreat left lane!",
    commandPS: "C X R",
    commandXbox: "B A X",
  },
  {
    command: "VR2",
    line: "Retreat middle lane!",
    commandPS: "C X T",
    commandXbox: "B A Y",
  },
  {
    command: "VR3",
    line: "Retreat right lane!",
    commandPS: "C X C",
    commandXbox: "B A B",
  },
  {
    command: "VRJ",
    line: "Retreat from the Jungle!",
    commandPS: "L1 L1 T R",
    commandXbox: "LB LB Y X",
  },
  {
    command: "VRR",
    line: "Retreat!",
    commandPS: "C X X",
    commandXbox: "B A A",
  },
  {
    command: "VRS",
    line: "Save Yourself!",
    commandPS: "T C C C",
    commandXbox: "Y B B B",
  },

  {
    command: "VSA1",
    line: "I'll Attack left lane!",
    commandPS: "X R1 R",
    commandXbox: "A RB X",
  },
  {
    command: "VSA2",
    line: "I'll Attack middle lane!",
    commandPS: "X R1 T",
    commandXbox: "A RB Y",
  },
  {
    command: "VSA3",
    line: "I'll Attack right lane!",
    commandPS: "X R1 C",
    commandXbox: "A RB B",
  },
  {
    command: "VSAA",
    line: "I'll Attack!",
    commandPS: "X R1 X",
    commandXbox: "A RB A",
  },
  {
    command: "VSAF",
    line: "I'll Attack the Fire Giant!",
    commandPS: "X L1 R",
    commandXbox: "A LB X",
  },
  {
    command: "VSAG",
    line: "I'll Attack the Gold Fury!",
    commandPS: "X L1 C",
    commandXbox: "A LB B",
  },
  {
    command: "VSAM",
    line: "I'll Attack the Titan!",
    commandPS: "X L1 T",
    commandXbox: "A LB Y",
  },
  {
    command: "VSBB",
    line: "I'm going for jungle buff!",
    commandPS: "L1 R R",
    commandXbox: "LB X X",
  },

  {
    command: "VSBN",
    line: "I need the jungle buff!",
    commandPS: "L1 R T",
    commandXbox: "LB X Y",
  },
  {
    command: "VSBT",
    line: "Take this jungle buff!",
    commandPS: "L1 R C",
    commandXbox: "LB X B",
  },

  {
    command: "VSD1",
    line: "I'll defend left lane!",
    commandPS: "R1 L1 R R",
    commandXbox: "RB LB X X",
  },
  {
    command: "VSD2",
    line: "I'll defend middle lane!",
    commandPS: "R1 L1 R T",
    commandXbox: "RB LB X Y",
  },
  {
    command: "VSD3",
    line: "I'll defend right lane!",
    commandPS: "R1 L1 R C",
    commandXbox: "RB LB X B",
  },
  {
    command: "VSDD",
    line: "I'll defend!",
    commandPS: "R1 L1 R X",
    commandXbox: "RB LB X A",
  },
  {
    command: "VSDF",
    line: "I'll defend the Fire Giant!",
    commandPS: "R1 L1 T R",
    commandXbox: "RB LB Y X",
  },
  {
    command: "VSDG",
    line: "I'll defend the Gold Fury!",
    commandPS: "R1 L1 T C",
    commandXbox: "RB LB Y B",
  },
  {
    command: "VSDM",
    line: "I'll defend the Titan!",
    commandPS: "R1 L1 T T",
    commandXbox: "RB LB Y Y",
  },

  {
    command: "VSG1",
    line: "I'll gank left lane!",
    commandPS: "R1 L1 R R",
    commandXbox: "RB RB X X",
  },
  {
    command: "VSG2",
    line: "I'll gank middle lane!",
    commandPS: "R1 L1 R T",
    commandXbox: "RB RB X Y",
  },
  {
    command: "VSG3",
    line: "I'll gank right lane!",
    commandPS: "R1 L1 R C",
    commandXbox: "RB RB X B",
  },
  {
    command: "VSGG",
    line: "I'll gank!",
    commandPS: "R1 L1 R X",
    commandXbox: "RB RB X A",
  },

  {
    command: "VSO",
    line: "I'm on it!",
    commandPS: "T R1 T",
    commandXbox: "Y RB Y",
  },

  {
    command: "VSQ1",
    line: "I will ward left!",
    commandPS: "R1 R1 T R",
    commandXbox: "RB RB Y X",
  },
  {
    command: "VSQ2",
    line: "I will ward middle!",
    commandPS: "R1 R1 T T",
    commandXbox: "RB RB Y Y",
  },
  {
    command: "VSQ3",
    line: "I will ward right!",
    commandPS: "R1 R1 T C",
    commandXbox: "RB RB Y B",
  },
  {
    command: "VSQQ",
    line: "I will ward!",
    commandPS: "R1 R1 T X",
    commandXbox: "RB RB Y A",
  },

  {
    command: "VSR",
    line: "Falling Back!",
    commandPS: "T L1 T",
    commandXbox: "Y LB Y",
  },
  {
    command: "VSS",
    line: "I'm building stacks!",
    commandPS: "L1 C T",
    commandXbox: "LB B Y",
  },

  {
    command: "VST1",
    line: "I'm returning left lane!",
    commandPS: "C L1 R R",
    commandXbox: "B LB X X",
  },
  {
    command: "VST2",
    line: "I'm returning middle lane!",
    commandPS: "C L1 R T",
    commandXbox: "B LB X Y",
  },
  {
    command: "VST3",
    line: "I'm returning right lane!",
    commandPS: "C L1 R C",
    commandXbox: "B LB X B",
  },
  {
    command: "VSTB",
    line: "I'm returning to base!",
    commandPS: "C L1 R X",
    commandXbox: "B LB X A",
  },
  {
    command: "VSTT",
    line: "I have returned!",
    commandPS: "L1 L1 C T",
    commandXbox: "LB LB B Y",
  },

  {
    command: "VT1",
    line: "Enemies have returned left!",
    commandPS: "C L1 T R",
    commandXbox: "B LB Y X",
  },
  {
    command: "VT2",
    line: "Enemies have returned middle!",
    commandPS: "C L1 T T",
    commandXbox: "B LB Y Y",
  },
  {
    command: "VT3",
    line: "Enemies have returned right!",
    commandPS: "C L1 T C",
    commandXbox: "B LB Y B",
  },
  {
    command: "VTT",
    line: "Enemies have returned!",
    commandPS: "C L1 T X",
    commandXbox: "B LB Y A",
  },

  {
    command: "VVA",
    line: "Ok!",
    commandPS: "L1 X X",
    commandXbox: "LB A A",
  },
  {
    command: "VVB",
    line: "Be right back!",
    commandPS: "L1 X R",
    commandXbox: "LB A X",
  },

  {
    command: "VVC",
    line: "Completed!",
    commandPS: "T R1 C",
    commandXbox: "Y RB B",
  },

  {
    command: "VVGB",
    line: "Bye!",
    commandPS: "T T T",
    commandXbox: "Y Y Y",
  },
  {
    command: "VVGF",
    line: "Have Fun!",
    commandPS: "T T X",
    commandXbox: "Y Y A",
  },
  {
    command: "VVGG",
    line: "Good Game!",
    commandPS: "T X R",
    commandXbox: "Y A X",
  },
  {
    command: "VVGH",
    line: "Hi!",
    commandPS: "T T R",
    commandXbox: "Y Y X",
  },
  {
    command: "VVGL",
    line: "Good Luck!",
    commandPS: "T T C",
    commandXbox: "Y Y B",
  },
  {
    command: "VVGN",
    line: "Nice Job!",
    commandPS: "T X X C",
    commandXbox: "Y A A B",
  },
  {
    command: "VVGO",
    line: "Oops!",
    commandPS: "T X T",
    commandXbox: "Y A Y",
  },
  {
    command: "VVGQ",
    line: "Quiet!",
    commandPS: "T X X R",
    commandXbox: "Y A A X",
  },
  {
    command: "VVGR",
    line: "No Problem!",
    commandPS: "T C T",
    commandXbox: "Y B Y",
  },
  {
    command: "VVGS",
    line: "Curses!",
    commandPS: "T C C X",
    commandXbox: "Y B B A",
  },
  {
    command: "VVGT",
    line: "That's Too Bad!",
    commandPS: "T X X X",
    commandXbox: "Y A A A",
  },
  {
    command: "VVGW",
    line: "You're Welcome!",
    commandPS: "T R1 R",
    commandXbox: "Y RB X",
  },
  {
    command: "VVK",
    line: "Stepping away for a moment.",
    commandPS: "T R1 X",
    commandXbox: "Y RB A",
  },

  {
    command: "VVM",
    line: "Out of mana!",
    commandPS: "L1 C R",
    commandXbox: "LB B X",
  },
  {
    command: "VVN",
    line: "No!",
    commandPS: "T C C R",
    commandXbox: "Y B B X",
  },
  {
    command: "VVP",
    line: "Please?",
    commandPS: "T C C T",
    commandXbox: "Y B B Y",
  },
  {
    command: "VVS",
    line: "Sorry!",
    commandPS: "T X C",
    commandXbox: "Y A B",
  },
  {
    command: "VVT",
    line: "Thanks!",
    commandPS: "T C R",
    commandXbox: "Y B X",
  },

  {
    command: "VVVA",
    line: "Set up an ambush here!",
    commandPS: "L1 L1 C R",
    commandXbox: "LB LB B X",
  },
  {
    command: "VVVB",
    line: "Behind Us!",
    commandPS: "L1 L1 R R",
    commandXbox: "LB LB X X",
  },
  {
    command: "VVVC",
    line: "Chase the enemy!",
    commandPS: "L1 L1 R C",
    commandXbox: "LB LB X B",
  },
  {
    command: "VVVD",
    line: "Ultimate is down!",
    commandPS: "L1 T T",
    commandXbox: "LB Y Y",
  },
  {
    command: "VVVE",
    line: "On my way!",
    commandPS: "L1 X T",
    commandXbox: "LB A Y",
  },
  {
    command: "VVVF",
    line: "Follow me!",
    commandPS: "T L1 C",
    commandXbox: "Y LB X",
  },
  {
    command: "VVVG",
    line: "Group up!",
    commandPS: "L1 C C",
    commandXbox: "LB B B",
  },
  {
    command: "VVVJ",
    line: "Going into the jungle!",
    commandPS: "L1 L1 C C",
    commandXbox: "LB LB B B",
  },
  {
    command: "VVVP",
    line: "Split push!",
    commandPS: "L1 L1 T T",
    commandXbox: "LB LB Y Y",
  },
  {
    command: "VVVR",
    line: "Ultimate is ready!",
    commandPS: "L1 T R",
    commandXbox: "LB Y X",
  },
  {
    command: "VVVS",
    line: "Stay here!",
    commandPS: "L1 L1 T C",
    commandXbox: "LB LB Y B",
  },
  {
    command: "VVVT",
    line: "It's a trap!",
    commandPS: "L1 L1 R T",
    commandXbox: "LB LB X Y",
  },
  {
    command: "VVVW",
    line: "Place a Ward for teleport!",
    commandPS: "L1 L1 C X",
    commandXbox: "LB LB B A",
  },
  {
    command: "VVVX",
    line: "Spread Out!",
    commandPS: "T L1 X",
    commandXbox: "Y LB A",
  },

  {
    command: "VVW",
    line: "Wait!",
    commandPS: "T X X T",
    commandXbox: "Y A A Y",
  },
  {
    command: "VVX",
    line: "Cancel that!",
    commandPS: "L1 X C",
    commandXbox: "LB A B",
  },
  {
    command: "VVY",
    line: "Yes!",
    commandPS: "T C X",
    commandXbox: "Y B A",
  },

  {
    command: "VXW",
    line: "Wave",
    commandPS: "R C C",
    commandXbox: "X B B",
  },
  {
    command: "VXD",
    line: "Dance",
    commandPS: "R C X",
    commandXbox: "X B A",
  },
  {
    command: "VXC",
    line: "Clap",
    commandPS: "R C T",
    commandXbox: "X B Y",
  },
  {
    command: "VXS",
    line: "Special",
    commandPS: "R X R",
    commandXbox: "X A X",
  },
  {
    command: "VXF",
    line: "Furious",
    commandPS: "R X C",
    commandXbox: "X A B",
  },
  {
    command: "VXG",
    line: "Special 2",
    commandPS: "R X T",
    commandXbox: "X A Y",
  },
  {
    command: "VXE",
    line: "Global Emote",
    commandPS: "R X X",
    commandXbox: "X A A",
  },
];
