import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class AppGuard implements CanActivate {
  constructor(private router: Router, private datePipe: DatePipe) {}
  canActivate() {
    let user_id = localStorage.getItem("smitesavvy_id");

    if (user_id === null || user_id === undefined) {
      this.router.navigate(["/"]);
      return false;
    }

    let currentDate = localStorage.getItem("currentD");

    if (currentDate === null || currentDate === undefined) {
      this.router.navigate(["/"]);
      return false;
    } else {
      let today = this.daysIntoYear(new Date()).toString();
      if (currentDate !== today) {
        this.router.navigate(["/"]);
        return false;
      }
    }
    return true;
  }

  daysIntoYear(date) {
    return Math.floor(
      (Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      ) -
        Date.UTC(date.getUTCFullYear(), 0, 0, 0, 0, 0)) /
        24 /
        60 /
        60 /
        1000
    );
  }
}
