import { NavigationStart, Router } from "@angular/router";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { DeviceDetectorService } from "ngx-device-detector";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Smite-Savvy";
  constructor(
    public dialog: MatDialog,
    public router: Router,
    public deviceService: DeviceDetectorService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        setTimeout(() => {
          try {
            (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
          } catch (e) {
            console.error("ads", e);
          }
        }, 900);
      }
    });
  }
  aboutUs() {
    const dialogRef = this.dialog.open(AboutDialog, {
      height: "80%",
      backdropClass: "dialog-background",
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  howtoplay() {
    let height = "80%";
    if (this.router.url.includes("ability")) height = "60%";
    this.dialog.open(HowToDialog, {
      height: height,
      backdropClass: "dialog-background",
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  redirect(where) {
    if (where === "twitter")
      window.open("https://twitter.com/AstaThePasta", "_blank");
    if (where === "kofi")
      window.open("https://ko-fi.com/astathepasta", "_blank");
  }
  feudhelp() {
    let height = "55%";
    this.dialog.open(HowToDialog, {
      height: height,
      width: "60%",
      backdropClass: "dialog-background",
      scrollStrategy: new NoopScrollStrategy(),
      data: { title: "help" },
    });
  }
}

@Component({
  selector: "about-dialog",
  templateUrl: "about-dialog.html",
  styleUrls: ["about-dialog.scss"],
})
export class AboutDialog {}

@Component({
  selector: "howto-dialog",
  templateUrl: "howto-dialog.html",
  styleUrls: ["howto-dialog.scss"],
})
export class HowToDialog {
  constructor(
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
