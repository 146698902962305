export var abilitySoundFiles: string[] = [
  "Achilles_1",
  "Achilles_2",
  "Achilles_3",
  "Achilles_4",
  "Agni_2",
  "Agni_3",
  "Agni_4",
  "AhMuzenCab_1",
  "AhMuzenCab_2",
  "AhMuzenCab_3",
  "AhMuzenCab_4",
  "AhPuch_1",
  "AhPuch_2",
  "AhPuch_3",
  "AhPuch_4",
  "Amaterasu_1",
  "Amaterasu_2",
  "Amaterasu_3",
  "Amaterasu_4",
  "Anhur_1",
  "Anhur_2",
  "Anhur_3",
  "Anhur_4",
  "Anubis_1",
  "Anubis_2",
  "Anubis_3",
  "Anubis_4",
  "AoKuang_1",
  "AoKuang_2",
  "AoKuang_3",
  "AoKuang_4",
  "Aphrodite_1",
  "Aphrodite_2",
  "Aphrodite_3",
  "Aphrodite_4",
  "Apollo_1",
  "Apollo_2",
  "Apollo_3",
  "Apollo_4",
  "Arachne_1",
  "Arachne_2",
  "Arachne_3",
  "Ares_1",
  "Ares_2",
  "Ares_3",
  "Ares_4",
  "Artemis_1",
  "Artemis_2",
  "Artemis_3",
  "Artemis_4",
  "Artio_1",
  "Artio_2",
  "Artio_3",
  "Artio_4",
  "Athena_1",
  "Athena_2",
  "Athena_3",
  "Athena_4",
  "Awilix_1",
  "Awilix_2",
  "Awilix_3",
  "Awilix_4",
  "BabaYaga_1",
  "BabaYaga_2",
  "BabaYaga_3",
  "BabaYaga_4",
  "Bacchus_1",
  "Bacchus_2",
  "Bacchus_3",
  "Bakasura_1",
  "Bakasura_2",
  "Bakasura_3",
  "BaronSamedi_1",
  "BaronSamedi_2",
  "BaronSamedi_3",
  "Bastet_1",
  "Bastet_2",
  "Bellona_1",
  "Bellona_2",
  "Bellona_3",
  "Bellona_4",
  "Cabrakan_1",
  "Cabrakan_2",
  "Camazotz_1",
  "Camazotz_2",
  "Camazotz_4",
  "Cerberus_1",
  "Cerberus_2",
  "Cerberus_3",
  "Cerberus_4",
  "Cernunnos_2",
  "Cernunnos_3",
  "Cernunnos_4",
  "Chaac_1",
  "Chaac_2",
  "Chaac_3",
  "Chaac_4",
  "Change_1",
  "Change_2",
  "Change_3",
  "Change_4",
  "Chernobog_1",
  "Chernobog_2",
  "Chernobog_3",
  "Chernobog_4",
  "Chiron_1",
  "Chiron_2",
  "Chiron_3",
  "Chiron_4",
  "Chronos_1",
  "Chronos_2",
  "Chronos_4",
  "Cthulhu_1",
  "Cthulhu_2",
  "Cthulhu_3",
  "CuChulainn_1",
  "CuChulainn_2",
  "CuChulainn_3",
  "CuChulainn_4",
  "Cupid_1",
  "Cupid_2",
  "Cupid_3",
  "Cupid_4",
  "Daji_1",
  "Daji_2",
  "Daji_3",
  "Daji_4",
  "Danzaburou_1",
  "Danzaburou_2",
  "Danzaburou_3",
  "Danzaburou_4",
  "Discordia_1",
  "Discordia_2",
  "Discordia_3",
  "Discordia_4",
  "ErlangShen_1",
  "ErlangShen_2",
  "ErlangShen_3",
  "ErlangShen_4",
  "Eset_2",
  "Eset_3",
  "Eset_4",
  "Fafnir_1",
  "Fafnir_2",
  "Fafnir_3",
  "Fafnir_4",
  "Fenrir_1",
  "Fenrir_2",
  "Fenrir_4",
  "Freya_1",
  "Freya_2",
  "Freya_3",
  "Freya_4",
  "Ganesha_1",
  "Ganesha_2",
  "Ganesha_3",
  "Ganesha_4",
  "Geb_1",
  "Geb_2",
  "Geb_3",
  "GuanYu_1",
  "GuanYu_2",
  "GuanYu_3",
  "GuanYu_4",
  "Hachiman_1",
  "Hachiman_2",
  "Hachiman_4",
  "Hades_1",
  "Hades_2",
  "Hades_3",
  "Hades_4",
  "Hebo_1",
  "Hebo_2",
  "Hebo_3",
  "Heimdallr_1",
  "Heimdallr_2",
  "Heimdallr_3",
  "Heimdallr_4",
  "Hel_3",
  "Hel_4",
  "Hera_1",
  "Hera_2",
  "Hera_3",
  "Hera_4",
  "Hercules_1",
  "Hercules_3",
  "Hercules_4",
  "Horus_1",
  "Horus_2",
  "Horus_3",
  "Horus_4",
  "HouYi_1",
  "HouYi_2",
  "HouYi_3",
  "HouYi_4",
  "HunBatz_1",
  "HunBatz_2",
  "HunBatz_3",
  "HunBatz_4",
  "Izanami_1",
  "Izanami_2",
  "Izanami_3",
  "Izanami_4",
  "Janus_1",
  "Janus_2",
  "Janus_3",
  "Janus_4",
  "JingWei_1",
  "JingWei_2",
  "JingWei_4",
  "Jormungandr_1",
  "Jormungandr_2",
  "Jormungandr_3",
  "Jormungandr_4",
  "Kali_3",
  "Kali_4",
  "Khepri_1",
  "Khepri_2",
  "Khepri_3",
  "Khepri_4",
  "KingArthur_1",
  "KingArthur_2",
  "KingArthur_3",
  "KingArthur_4",
  "Kukulkan_1",
  "Kukulkan_2",
  "Kukulkan_3",
  "Kukulkan_4",
  "Kumbhakarna_1",
  "Kumbhakarna_3",
  "Kumbhakarna_4",
  "Kuzenbo_1",
  "Kuzenbo_2",
  "Kuzenbo_3",
  "Kuzenbo_4",
  "Loki_1",
  "Loki_2",
  "Loki_3",
  "Loki_4",
  "Medusa_1",
  "Medusa_2",
  "Medusa_3",
  "Medusa_4",
  "Mercury_1",
  "Mercury_2",
  "Mercury_3",
  "Mercury_4",
  "Merlin_1",
  "Merlin_2",
  "Mulan_1",
  "Mulan_2",
  "Mulan_3",
  "Mulan_4",
  "Neith_1",
  "Neith_2",
  "Neith_3",
  "Neith_4",
  "Nemesis_1",
  "Nemesis_2",
  "Nemesis_3",
  "Nemesis_4",
  "NeZha_1",
  "NeZha_2",
  "NeZha_3",
  "NeZha_4",
  "Nike_2",
  "Nike_3",
  "Nike_4",
  "Nox_1",
  "Nox_2",
  "Nox_3",
  "Nox_4",
  "NuWa_1",
  "NuWa_2",
  "NuWa_3",
  "NuWa_4",
  "Odin_1",
  "Odin_2",
  "Odin_3",
  "Olorun_1",
  "Olorun_2",
  "Olorun_3",
  "Olorun_4",
  "Osiris_1",
  "Osiris_2",
  "Osiris_4",
  "Pele_1",
  "Pele_4",
  "Persephone_1",
  "Persephone_2",
  "Persephone_3",
  "Persephone_4",
  "Poseidon_1",
  "Poseidon_2",
  "Poseidon_3",
  "Poseidon_4",
  "Ra_1",
  "Ra_2",
  "Ra_3",
  "Ra_4",
  "Raijin_2",
  "Raijin_3",
  "Raijin_4",
  "Rama_1",
  "Rama_2",
  "Rama_3",
  "Rama_4",
  "Ratatoskr_1",
  "Ratatoskr_2",
  "Ratatoskr_3",
  "Ratatoskr_4",
  "Ravana_1",
  "Ravana_3",
  "Ravana_4",
  "Scylla_1",
  "Scylla_2",
  "Scylla_3",
  "Scylla_4",
  "Serqet_2",
  "Serqet_3",
  "Serqet_4",
  "Set_1",
  "Set_3",
  "Set_4",
  "Skadi_2",
  "Skadi_3",
  "Skadi_4",
  "Sobek_1",
  "Sobek_2",
  "Sobek_3",
  "Sobek_4",
  "Sol_1",
  "Sol_2",
  "SunWukong_1",
  "SunWukong_2",
  "SunWukong_3",
  "SunWukong_4",
  "Susano_1",
  "Susano_2",
  "Susano_3",
  "Susano_4",
  "Sylvanus_1",
  "Sylvanus_2",
  "Sylvanus_3",
  "Sylvanus_4",
  "Terra_1",
  "Terra_3",
  "Terra_4",
  "Thanatos_1",
  "Thanatos_2",
  "Thanatos_3",
  "Thanatos_4",
  "TheMorrigan_1",
  "TheMorrigan_2",
  "TheMorrigan_3",
  "TheMorrigan_4",
  "Thor_1",
  "Thor_2",
  "Thor_3",
  "Thor_4",
  "Thoth_1",
  "Thoth_2",
  "Thoth_3",
  "Thoth_4",
  "Tiamat_1",
  "Tiamat_2",
  "Tiamat_3",
  "Tiamat_4",
  "Tsukuyomi_1",
  "Tsukuyomi_2",
  "Tsukuyomi_3",
  "Tsukuyomi_4",
  "Tyr_1",
  "Tyr_3",
  "Tyr_4",
  "Ullr_1",
  "Ullr_2",
  "Ullr_3",
  "Vamana_1",
  "Vamana_2",
  "Vamana_3",
  "Vulcan_1",
  "Vulcan_2",
  "Vulcan_3",
  "Vulcan_4",
  "Xbalanque_1",
  "Xbalanque_3",
  "Xbalanque_4",
  "XingTian_1",
  "XingTian_3",
  "Yemoja_1",
  "Yemoja_3",
  "Yemoja_4",
  "Ymir_1",
  "Ymir_2",
  "Ymir_3",
  "Ymir_4",
  "Zeus_1",
  "Zeus_2",
  "Zeus_3",
  "Zeus_4",
  "ZhongKui_1",
  "ZhongKui_2",
  "ZhongKui_3",
  "ZhongKui_4",
];
