import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { God, gods } from "src/app/models/GodModel";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { startWith, map } from "rxjs/operators";
import * as CryptoJS from "crypto-js";
import { AngularFirestore } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";
import { HistoryPlayer } from "../home/home.component";
import { data, allData } from "src/app/models/data";

@Component({
  selector: "app-quote",
  templateUrl: "./quote.component.html",
  styleUrls: ["./quote.component.scss"],
})
export class QuoteComponent implements OnInit, AfterViewInit {
  godCtrl = new FormControl("");
  filteredGods: Observable<God[]>;
  Gods: God[] = JSON.parse(JSON.stringify(gods));
  GodsDisplayed: God[] = [];

  oldDisplayed: God[] = [];

  displayedColumns: string[] = ["god"];

  tauntAudio = new Audio();
  NJAudio = new Audio();

  currentGod: God;
  currentTaunt: number = 0;

  dataSource = new MatTableDataSource<God>(this.GodsDisplayed);

  isCompleted: boolean = false;
  fullState: string = "uncompleted";

  cryptKey = environment.cryptKey;
  currentD = localStorage.getItem("currentD");
  getDataTries: number = 0;
  isError: boolean = false;

  isSoundPlaying: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator.pageSize = 5;
    this.dataSource.paginator = this.paginator;

    let alreadyGuessedGodNames: string[] = [];

    try {
      alreadyGuessedGodNames = JSON.parse(
        localStorage.getItem("guessQuoteNames")
      );
    } catch (error) {
      localStorage.removeItem("guessQuoteNames");
      localStorage.removeItem("quoteState");
    }

    if (alreadyGuessedGodNames !== null && alreadyGuessedGodNames.length > 0) {
      alreadyGuessedGodNames.forEach((godName) => {
        let godData = this.Gods.find((god) => god.name === godName);
        if (godData !== undefined) {
          this.GodsDisplayed.push(godData);
          let index = this.Gods.indexOf(godData);
          this.Gods.splice(index, 1);
        }
      });
      this.dataSource.data = this.GodsDisplayed;
      this.dataSource.paginator.length = this.GodsDisplayed.length;
      this.oldDisplayed = JSON.parse(JSON.stringify(this.GodsDisplayed));
      this.cdRef.detectChanges();
    }
    if (document.getElementById("f6")) {
      document.getElementById("f6").addEventListener("click", () => {
        this.isCompleted = true;
        this.fullState = "surrender";
        localStorage.setItem(
          "quoteState",
          CryptoJS.AES.encrypt(
            JSON.stringify("surrender"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
        this.godCtrl.setValue("");
        document.getElementById("f6").remove();
      });
      if (this.isCompleted) {
        document.getElementById("f6").remove();
      }
    }
    this.NJAudio.src = this.currentGod.NJ_Voiceline!;
    this.NJAudio.volume = 0.5;

    this.NJAudio.load();
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private store: AngularFirestore
  ) {
    this.filteredGods = this.godCtrl.valueChanges.pipe(
      startWith(""),
      map((god) => (god ? this.filterGods(god) : []))
    );
    this.decryptTodayQuote();
  }
  ngOnInit() {
    this.fullState = "uncompleted";
    let quoteState = localStorage.getItem("quoteState");
    if (quoteState) {
      const bytes = CryptoJS.AES.decrypt(quoteState, this.cryptKey);
      if (bytes.toString()) {
        let state = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (state === "completed" || state === "surrender") {
          this.isCompleted = true;
          this.fullState = state;
        } else {
          this.isCompleted = false;
        }
      } else {
        this.isCompleted = false;
      }
    } else {
      this.isCompleted = false;
    }
  }

  decryptTodayQuote() {
    let todayQuote = localStorage.getItem("quote_today");
    if (todayQuote) {
      const bytes = CryptoJS.AES.decrypt(todayQuote, this.cryptKey);
      if (bytes.toString()) {
        let todayQuoteData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        this.currentGod = this.Gods.find(
          (god) => god.name === todayQuoteData.god
        );
        if (this.currentGod === undefined) {
          this.getDataTries++;
        } else {
          let todayTaunt = parseInt(todayQuoteData.taunt);
          if (todayTaunt !== 0 && todayTaunt !== 1)
            this.currentTaunt = Math.floor(
              Math.random() * this.currentGod.taunts.length
            );
          else this.currentTaunt = parseInt(todayQuoteData.taunt);
          this.tauntAudio.src = this.currentGod.taunts[
            this.currentTaunt
          ].voiceline;
          this.tauntAudio.volume = 0.5;
          this.tauntAudio.loop = true;
        }
      } else {
        this.getDataTries++;
      }
    } else {
      this.getDataTries++;
    }

    if (this.getDataTries > 0) {
      if (this.getDataTries >= 2) this.isError = true;
      else this.getTodayQuote();
    }
  }
  getTodayQuote() {
    // this.store
    //   .collection("gamemodes")
    //   .doc(this.currentD)
    //   .ref.get()
    //   .then((doc) => {
    //     let data = doc.data();
    //     localStorage.setItem("quote_today", data.quote);
    //     this.decryptTodayQuote();
    //   });

    let allDat: data[] = JSON.parse(JSON.stringify(allData));
    let todayData: data = allDat.filter((d) => {
      return d.day === this.currentD;
    })[0];

    localStorage.setItem("quote_today", todayData.quote);
    this.decryptTodayQuote();
  }

  oldIncludes(god: God) {
    return this.oldDisplayed.map((oldGod) => oldGod.name).includes(god.name);
  }

  private filterGods(value: string): God[] {
    const filterValue = value.toLowerCase();

    return this.Gods.filter((god) =>
      god.name.toLowerCase().startsWith(filterValue)
    );
  }

  selectedGod(event) {
    if (event.isUserInput) {
      let searchedGodName = event.source.value;
      let searchedGod: God = this.Gods.find(
        (god) => god.name === searchedGodName
      );

      this.GodsDisplayed.unshift(searchedGod);

      let index = this.Gods.indexOf(searchedGod);
      this.Gods.splice(index, 1);

      this.dataSource.data = this.GodsDisplayed;
      this.godCtrl.setValue("");
      this.dataSource.paginator.length = this.GodsDisplayed.length;

      let storageGodNames = this.GodsDisplayed.map((god) => god.name);
      localStorage.setItem("guessQuoteNames", JSON.stringify(storageGodNames));

      if (searchedGodName === this.currentGod.name) {
        this.fullState = "completed";
        localStorage.setItem(
          "quoteState",
          CryptoJS.AES.encrypt(
            JSON.stringify("completed"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
      }

      setTimeout(() => {
        if (searchedGodName === this.currentGod.name) {
          this.tauntAudio.pause();
          this.tauntAudio.currentTime = 0;
          this.isCompleted = true;
          this.fullState = "completed";

          this.NJAudio.play();
          if (document.getElementById("f6"))
            document.getElementById("f6").remove();
        }
      }, 1100);
    }
  }

  paginationTest(event) {
    this.oldDisplayed = JSON.parse(JSON.stringify(this.GodsDisplayed));
  }

  @HostListener("window:beforeunload")
  ngOnDestroy(): void {
    this.tauntAudio.pause();
    this.tauntAudio.currentTime = 0;
    let storageGodNames = this.GodsDisplayed.map((god) => god.name);
    localStorage.setItem("guessQuoteNames", JSON.stringify(storageGodNames));
  }

  playSound() {
    if (this.isSoundPlaying) {
      this.isSoundPlaying = false;
      this.tauntAudio.pause();
    } else {
      this.tauntAudio.play();
      this.isSoundPlaying = true;
    }
  }

  navigate() {
    this.router.navigateByUrl("/ability-sounds");
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "F6" && !this.isCompleted) {
      this.isCompleted = true;
      this.fullState = "surrender";
      localStorage.setItem(
        "quoteState",
        CryptoJS.AES.encrypt(
          JSON.stringify("surrender"),
          this.cryptKey
        ).toString()
      );
      this.setHistory();
      this.godCtrl.setValue("");
    }
  }

  setHistory() {
    let history: HistoryPlayer[] = [];
    let historyStorage;

    historyStorage = localStorage.getItem("history");

    if (historyStorage) {
      const bytes = CryptoJS.AES.decrypt(historyStorage, this.cryptKey);
      if (bytes.toString()) {
        history = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let currentDate = localStorage.getItem("currentD");
        let currentHistoryDate = history.find(
          (hist) => hist.date === currentDate
        );
        if (currentHistoryDate) {
          currentHistoryDate.quote = this.fullState === "completed" ? "W" : "L";
        } else {
          history.unshift({
            date: currentDate,
            gods: "-",
            quote: this.fullState === "completed" ? "W" : "L",
            abilitySounds: "-",
            abilityText: "-",
            vgs: "-",
            spl: "-",
            feud: "-",
          });
        }
        localStorage.setItem(
          "history",
          CryptoJS.AES.encrypt(
            JSON.stringify(history),
            this.cryptKey
          ).toString()
        );
      } else {
        localStorage.removeItem("history");
      }
    } else {
      let currentDate = localStorage.getItem("currentD");

      history.push({
        date: currentDate,
        gods: "-",
        quote: this.fullState === "completed" ? "W" : "L",
        abilitySounds: "-",
        abilityText: "-",
        vgs: "-",
        spl: "-",
        feud: "-",
      });
      localStorage.setItem(
        "history",
        CryptoJS.AES.encrypt(JSON.stringify(history), this.cryptKey).toString()
      );
    }
  }
}
