import { FeudComponent } from "./modules/feud/feud.component";
import { HomeGuard } from "./home.guard";
import { VgsComponent } from "./modules/vgs/vgs.component";
import { AbilityTextComponent } from "./modules/ability-text/ability-text.component";
import { AbilitySoundsComponent } from "./modules/ability-sounds/ability-sounds.component";
import { QuoteComponent } from "./modules/quote/quote.component";
import { GodsComponent } from "./modules/gods/gods.component";
import { HomeComponent } from "./modules/home/home.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppGuard } from "./app.guard";
import { SplComponent } from "./modules/spl/spl.component";
import { PasswordComponent } from "./modules/password/password.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [HomeGuard],
  },
  {
    path: "gods",
    component: GodsComponent,
    canActivate: [AppGuard],
  },
  {
    path: "quote",
    component: QuoteComponent,
    canActivate: [AppGuard],
  },
  {
    path: "ability-sounds",
    component: AbilitySoundsComponent,
    canActivate: [AppGuard],
  },
  {
    path: "ability-text",
    component: AbilityTextComponent,
    canActivate: [AppGuard],
  },
  {
    path: "vgs",
    component: VgsComponent,
    canActivate: [AppGuard],
  },
  {
    path: "spl",
    component: SplComponent,
    // canActivate: [AppGuard],
  },
  {
    path: "feud",
    component: FeudComponent,
    // canActivate: [AppGuard],
  },
  // {
  //   path: "password",
  //   component: PasswordComponent,
  //   // canActivate: [AppGuard],
  // },

  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
