// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCZnZhETM087nD40bxBN4F5DeF1jplmk0s",
    authDomain: "smitesavvy.firebaseapp.com",
    projectId: "smitesavvy",
    storageBucket: "smitesavvy.appspot.com",
    messagingSenderId: "326790150101",
    appId: "1:326790150101:web:6844c89fbc0455278c6f5c",
    measurementId: "G-PD0SMJEWDQ",
  },
  cryptKey: "klab",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
