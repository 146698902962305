export interface data {
  day: string;
  gods: string;
  quote: string;
  abilitySounds: string;
  abilityText: string;
  vgs: string;
  spl: string;
  feud: string;
}

const allData: data[] = [
  // {
  //   day: "153",
  //   gods: "U2FsdGVkX1/nr1VUvhqn7w37RdCmrhy/CQ0wsv+K350=",
  //   quote: "U2FsdGVkX1/ZNnhgNcV48zV6d2z3LqiP4iF/maJUf52q+OjWhxwQclXz7Z3wF0ad",
  //   abilitySounds:
  //     "U2FsdGVkX1+ejw+ulyd1csigBmCdcZIE7LRDDxkF2I0cwys94SN8U2/lOmSOBA/dGWgW+vtEVmyG2cSJLMilnG1ilcHsCke/Ox0Jo3NKsdk=",
  //   abilityText:
  //     "U2FsdGVkX1/2mUtmytX/pzJtf6+1Z3M3pK0BZzOVFkH3/ArlJxUSH+52H73fVAPFPogd7xYfCN+cZsNLljI1x6dKVT4jw88J3eXXyycdsuU=",
  //   vgs: "U2FsdGVkX1+WDN6G/92Vcq0zcVQX++Ej6C1jt55XFn2HRpiG/QugIYx0CZy8FxRojS5YOBFmcEpAGpYW0e+isA==",
  //   spl: "U2FsdGVkX1/zZQvQ+I4MYuAQfFun61JqVUGupbBgYo5RMBPDPIzIQH4qeCbLXXm6",
  //   feud: "U2FsdGVkX1/TgdZTsjo6VVvtgzO3LA754v+1sEBQnJ8=",
  // },
  // {
  //   day: "154",
  //   gods: "U2FsdGVkX1/JU7fqXHKM5oKidE9mPIEep+kmibsIt3M=",
  //   quote: "U2FsdGVkX19eQ2mqJlEq/+qN31iueRp7KxngQKbTAeHeUX/lSVzc4C5ezeHpdE6l",
  //   abilitySounds:
  //     "U2FsdGVkX19Ps7KtreHeHnTWC2LCRKExVzGjJk3Yt6EOSH0f6ZS/MDcQyDviS39vm9LoO8SJ5vmuMsyl44TwqLr+fQL8Sy2oxdTN4cYKPOI=",
  //   abilityText:
  //     "U2FsdGVkX19f2Y72nmqkR1Zc7SJSF1u3niGdub4fDmiYvr5PbOBlbE4S2hNZn9MG7JdtbUN69ImZYAigEet1zcJHReefzwx0lvB1Ucld/DzGE/N8wXOPo0wiguCXAcxC",
  //   vgs: "U2FsdGVkX1+sMiKCy+w9OntVgtCL4uZQspvv6JsA5voY5qCe7eLrK/JZnql2aV56TjE5cmpYzDgNcUcAS+vhyg==",
  //   spl: "U2FsdGVkX19yo7rryioUrT99iNrwuuO6cA/tUQ+MCkFjzYzRMPeNQtHigxP3ftkJ",
  //   feud: "U2FsdGVkX1/nsk1ZwyEI/TjC50kPfbvg56F4ZBQIq+o=",
  // },
  // {
  //   day: "155",
  //   gods: "U2FsdGVkX18wbZu3Gx95mif7lG3If9MVvRUVgJ/Sk0o=",
  //   quote: "U2FsdGVkX18od5Y6KNLBcv6+ci41TVaRqoJDEAkTRjvOdSzrtYKQtt6C9fGoW8hK",
  //   abilitySounds:
  //     "U2FsdGVkX1+t5x8PU13lvClt3g21x2p6SqzGkatUFJoLg/gsLrqNThN5Bufof5GCSzCFQVv2xzr7Ig7g3k3FKZw5DV1BjedcGLdnzyMlZzo=",
  //   abilityText:
  //     "U2FsdGVkX18E3c/GPyE0KfpeX113j8dAPVoKQqqqoztm/Pq5D1Dwx13u8kim4WTobmtxo4A4p8XKziKXR+583pEoIxlBYV6xsaIxE+SXBBY=",
  //   vgs: "U2FsdGVkX1//ykLbgxqQZdhiLUlhlOtKJIQOHzpTObBsqP8yrHlcGzgidPfjYDpH1a34OZ8N8meGaDq1EaLiBg==",
  //   spl: "U2FsdGVkX1+DlMQDcgIsm9GYFWP8Nj2PzHwouMhZvH++STHpZgBN4Y/3x74JhPbZ",
  //   feud: "U2FsdGVkX1+wFaoTEgQRKEcTkgrR5WlYJNKCVNpo7Pg=",
  // },
  // {
  //   day: "156",
  //   gods: "U2FsdGVkX1+pdzlaKOyNfXMa/+DzrHVwsncSleFRJ2o=",
  //   quote:
  //     "U2FsdGVkX1/G6G3WVMeqCikqqh0IzZMxjBD144TUd9LW7mN3IMXZZ8NetoKyaTtRtiHHNO/rGnTY6OUXxX9dwQ==",
  //   abilitySounds:
  //     "U2FsdGVkX1+icUy+nJTG1Ch5Wi03i61921ZnhvuFSqFkD0mB1Ks0EnOpGrki+++pLp/xgwUAMOcyfo7TNo3H4ReB+z1p1mDvIVSfrViFaCs=",
  //   abilityText:
  //     "U2FsdGVkX1+r3TkQSexNTi4K2ApE6wKujZYZv5XRscj9QaeNnvh+1wyPTllNlubFqcazs7FoQF9CvpIIF39i8lhrHCTBmisuuZQSuU8fxVc=",
  //   vgs: "U2FsdGVkX1+Ruj9okLPMkyTDrnYSXcjIExA9FSAGgVsf0bixHqqbM5JQIuibuiHAXcRX85RGLwNlGreKGvsSTA==",
  //   spl: "U2FsdGVkX191AB6lkzc6J2xqc3JImGYAOvLeOfNCoEs=",
  //   feud: "",
  // },
  // {
  //   day: "157",
  //   gods: "U2FsdGVkX1+NeWwn0QwyR4/cIvoGGZO0tBz9gi+X3Eo=",
  //   quote: "U2FsdGVkX18sJZKJyy+MkM7F2jpTU+nG8GMiCRtYkpqT/GJrXZUH6Nc/r95JE2Ip",
  //   abilitySounds:
  //     "U2FsdGVkX1/MZpKEOUlftIsqQbeW8S5/RDVN7TD42sSR0jVEdtXt57E32wc6Ljig4uGOtOzJEAeQ9RF0S1C6TsA0Glgh2/O3u0qsgZ3xAdM=",
  //   abilityText:
  //     "U2FsdGVkX1+N3UbZwgLCb8du9PXET6rpxqKWd+V7G9a2qqqKBWPkqMY2/HZIav7/9O55oRdeAkGQbbkhgMqwbFvXNgk/lZB0azVowi4+F2M=",
  //   vgs: "U2FsdGVkX1+9l74ZTUfQToUDP0hlOICxxHtakzyej+te1yTlONmlhW0kZGls17XTYc/Rygl5xPRpkuf7w462MQ==",
  //   spl: "U2FsdGVkX19HNlSw6LsXBhd5OW7CXtskT9pBSKYJy50",
  //   feud: "",
  // },
  // {
  //   day: "158",
  //   gods: "U2FsdGVkX194V1xZ3AwKzwm212vtoCvuE6e4k2KKrjc=",
  //   quote: "U2FsdGVkX1/aEQDMxoaWBF1xea4l8fIwrkxgqX0rF0S5TkPgENzawDSMZ4L6w24H",
  //   abilitySounds:
  //     "U2FsdGVkX18dJUnBt/imSvQVIsKKCa36iMWZaNWbC3wj+YmxX0WddlmFzsV79CtB7w7BEN/PqIwgnMbptSuN5dTnoXs3ZeoUGD0/uMmoKCU=",
  //   abilityText:
  //     "U2FsdGVkX19Qw8xkuKDuJpqQ0+Lh5wyrgGbdjDA7UDicMdBZ8XE0Jio955zwFbU8j4HiDVmUJe9QVteoBlcKbQ4J21EA2tjg0DPBoDgsGwU=",
  //   vgs: "U2FsdGVkX19XhfOrCXU+ELOBzGL8XW/lOpTHNsdtCTmgEDq1wCphkh9ZfYWPEgi/LZITtfmKL344CO6ToB7CAw==",
  //   spl: "U2FsdGVkX18CMBBXGReSgxLwAKwcmeKFxFAEA8uAv/pAbwPvZuClvQU91UOji40V",
  //   feud: "",
  // },
  // {
  //   day: "159",
  //   gods: "U2FsdGVkX194mo0kBlnaf1BdKIaOEKP5mSnpzqPzBJg=",
  //   quote: "U2FsdGVkX1/sAJ06jI+s1GTpth+pCsTwG/SgoijSAWJcJj4qFDeU0eclozX8PNds",
  //   abilitySounds:
  //     "U2FsdGVkX19biCTUX7sYi59UE1mwKmq+kJsG9MDAQzrfEq8pf1737YEmMS7Zxcv5SBctvbyC6secYJ5rQ3YmKnIGGwTFrnM36OAyaEpTHWk=",
  //   abilityText:
  //     "U2FsdGVkX1+m5j0s3OqKjpXalRAceHzZC2RJAfNJ0/7AzfOLXY6SST0Jb8zBWcXBvcd1M/Ln5SvpeAE3mCbuX3wCtp8k+lDp35kS+wOjVAM=",
  //   vgs: "U2FsdGVkX1+H3ZdLhgLJTn96VHL8YwDndAdzWTeROYuE0TZr3vKv8zGpC9yt5EIfW4+NZBvtT++PVnymSZXzJw==",
  //   spl: "U2FsdGVkX19YY79U1vtDsjqFgYsXC59ATz5bedu1/JyUkOsy3Af4Ostn3Gu+NHwc",
  //   feud: "U2FsdGVkX19AnJ4tAqvsliY1+4ZLmdz0TIbsbOBJ0b0=",
  // },
  // {
  //   day: "160",
  //   gods: "U2FsdGVkX18OLXr7p5HFaMiV/XT8l0gZ21Y2bTYDwdY=",
  //   quote: "U2FsdGVkX1863mUO6peVLk1HnEnvsHpsEYmI43iw9T67hECtY8x60DjtFu9PhplQ",
  //   abilitySounds:
  //     "U2FsdGVkX1/QXhgqmwZTs8wVNg/B8xOrHOXSWIQpvTXtlLGrKuXeby22Fyuh7FaW6QRJoz94+qmk1rbVWqPUuT34doFuTRy5cmmtl4k/cpE=",
  //   abilityText:
  //     "U2FsdGVkX1/MABuh9HeWclj60SPU4Uu5uRbsdQ9QzSkw+M6AiPvyy3NQD7z5Sf3GOyOqme25MlzKNUmKdqRDOX2P0OFr+Gnqvb8lUP/lZhQ=",
  //   vgs: "U2FsdGVkX19ZeyQzmS3ZUG1O7NtJ8yIILXEBSvY73DfbEgFBU2bhZ/1VPVwndXg/adhWE0ZJYoOb60ofkKUrYw==",
  //   spl: "U2FsdGVkX1+uAUHI5MHLQEerLhhb771SH4iTXrcAcLnbwygslg9psDs0vxIFbu3J",
  //   feud: "U2FsdGVkX1+U/B807SOKhtq4kB7SHgo5Yx2Fdw18Pd0=",
  // },
  // {
  //   day: "161",
  //   gods: "U2FsdGVkX19YnY+AQl/bvDatG5BooXlj/cpDkkPnYLM=",
  //   quote: "U2FsdGVkX18c0/jrdjPVVw8ooJKXJm/CvcuNzOsUUf4sBAnx67QwRgmQLpwls+8J",
  //   abilitySounds:
  //     "U2FsdGVkX19A55rW4KEiUzz7+2bfmB6zjHwhV56LwFSb4901FCFHiNPVl2RNTnjqsCGcywkmxOYrgoK/2iPh9sEMf+qwT1eQWriBLVDGaTk=",
  //   abilityText:
  //     "U2FsdGVkX198f8CTOvlSky4vhB2SxOECRJOsaRm4NK1Oaiz5TcBIwfBoEuLnsAjQrBhuL7puKCHMMtcHYv2iGvCQc4kPp8e9WH4sXOrXlis=",
  //   vgs: "U2FsdGVkX1/91lAxC5qH+mFQoCEk0VektJ4vCEz7W2gRfJF5p5Uw3+u1bv+VqD5lQvcdI2V2/Wbgk4DLEH4/Tg==",
  //   spl: "U2FsdGVkX196BS8JM8uqMH5vuJe40FVaJyEmLkFCnN2rUGnvpEZ9kdJUSmkiTM5u",
  //   feud: "U2FsdGVkX1/VZL4+OyWO5pFx+Vq5fMQWTKCk4cIThWw=",
  // },
  // {
  //   day: "162",
  //   gods: "U2FsdGVkX1/rwyJrujK7UFVDMjaCPVwY3u8mX0Oqnjs=",
  //   quote: "U2FsdGVkX19WIqxiDIAU3QN3tcjI/CEwGT0Luek92HcZvsZ01MIKL42BeNNlPMa9",
  //   abilitySounds:
  //     "U2FsdGVkX19tVsuCzw3yTTUWqlgW6lXJ4PlVom6EQkLjtVLq/yx84nwh9ef+0UdRXrElFf9vv3amaYb6Xbf516quMtNLI9ov0cZhLwWfoV+QZZY38jNiqfZRw+8aAZru",
  //   abilityText:
  //     "U2FsdGVkX1/4t3K9QhzT8HcsIfX3i3x6JmYdeHBBmSpbuIAcxAByyhuAvE82EWCEIDWUAu9bvjBXfnPuIIElt+5CNwuJlUv1EJr2Wq6xYPw=",
  //   vgs: "U2FsdGVkX18J86WUeNe59NlIJYqvz+adhoKdL9ynV2fis0gGvN/aWZjySgVFxkucpGGRnAMDR6A0IMKpiveRSg==",
  //   spl: "U2FsdGVkX1/Z+4SSqWI+6aYWVPr0sEPqn80bJnDivUVECkck10VXLv8sVRlDmRoO",
  //   feud: "U2FsdGVkX1+hnVUdgiq1CHYV3JdjX+z/+o8+1TJJVq0=",
  // },
  // {
  //   day: "163",
  //   gods: "U2FsdGVkX1+nIQy8l1H1aMjruDW9NUsLk3z5DriyMhM=",
  //   quote: "U2FsdGVkX1+13dQtR1dXU1FcpydQGcMT/ki1fxc+eyKlCEyVaRgOM7MJaF2OvXVz",
  //   abilitySounds:
  //     "U2FsdGVkX19Wgnf7a6b38KRcAbC6R4zfgqcWgdGGWhFtQGYVmmNfcUecySxC87gZy00BdNA6Xcg/Gz8nIOP/y1VS1UY/TS0pbGlK49BdVrQ=",
  //   abilityText:
  //     "U2FsdGVkX19W8HqdYRiKTf4CEqqfPaayKINADPHIEVGCQvdq5/TlA2ub/t1nUkvqBS+AnkdR6rrWDygGBIAqbc/PjdoyiHSHUJ6qud4cYo6tEfhm2siO8oGPYY3svMJk",
  //   vgs: "U2FsdGVkX19f1JNSJ7hegl4NvqA8sg5BnyzUbGURAqqxLwJy9YHtLYDWGY2wP9Lw",
  //   spl: "U2FsdGVkX1/NJU1nCbtcR6Pw9HEJL8SE+i6KHqVcjjGkF9CEYiMDcbIO8SZscM2A",
  //   feud: "",
  // },
  // {
  //   day: "164",
  //   gods: "U2FsdGVkX1/lsCE/jxDM8wA+dMR/r5HjRe8wR+fMph0=",
  //   quote: "U2FsdGVkX19nKSWKxu3upNrdl7QxshM5xjWS5SJBNs8Fhmek+NEvRxeGjwrIIKIG",
  //   abilitySounds:
  //     "U2FsdGVkX19Aj71bLvN2Fuo82pJdnp0BclD8gOSMf6DHvp3ddrkN1/X/6jaspg3ZSSTHtFPwfy+kflADKPmGUUy4CT7CeQ2sjkorqpIWaDM=",
  //   abilityText:
  //     "U2FsdGVkX1+8DCuaptoE+0iPtJgl+T7+2v/lNZYFFrsa9bVCDtnShvk9MhIOHn85TgozH9L/4/35NblEMXSxQc2U1J5YJLOK58yLd+xAZh0=",
  //   vgs: "U2FsdGVkX1/aG8NS+oRE2UscWKbgsrkMpeeYFukDPuGRKcNSr8XeiuyTTMOkA4+iMBEVawC0ow4DD4bqQOHYyA==",
  //   spl: "U2FsdGVkX1/KVhNj5Sbu43QigWD2Zx0sa1tiapWVs7JVCkQvcMyFcGuKzL5T0iGe",
  //   feud: "",
  // },
  // {
  //   day: "165",
  //   gods: "U2FsdGVkX19rmJKh0aEmepFFjh7g7Ct2KYgDeoJbcPk=",
  //   quote: "U2FsdGVkX19w/AXGQWPmx9O5CANZzrFKBOM8DPwSZQDYzkVNBxnBaj6ArJXmt96O",
  //   abilitySounds:
  //     "U2FsdGVkX1+eHU0ImYtnfQl/GGKCgJzPCXOc77BWsWf59XUs3fF4MrzgF8WuU8ox4f3Cfi+3smAqpQD+BlUE61GXD24gjsK2B3I4t5tAiC0TKg7An01UsGFItCZA9Fpq",
  //   abilityText:
  //     "U2FsdGVkX1811H10xMphTgGq6Q7I/2/N/Q7hktb9OrgG/u8htFHIeWpNzEHor0ONnhKFNV8gjbOVpRqrsrVCXsZlrunllKO7jhrULb1INpo=",
  //   vgs: "U2FsdGVkX1+wlUUZ+b0sR2O+ZTV1XPg9/M27XaF+Emvn8p3TXUbYw2ftVTDsi6tZjGocdpaYINbOp/osfiZJ1w==",
  //   spl: "U2FsdGVkX1+NsGwVd+Y+j3NXnoiJ3K3A4VPUC3GN1GWyz1LIHTytsmXaqjI99KFo",
  //   feud: "",
  // },
  // {
  //   day: "166",
  //   gods: "U2FsdGVkX1+Abj8egKeVW4fMka1zZLsaSpVVD6oTL8Y=",
  //   quote: "U2FsdGVkX1/cisI2N4lMc0LQzqbfKP5AMfth/ffZSvfP/KBxX+y4pNoWmSJsCD7G",
  //   abilitySounds:
  //     "U2FsdGVkX18Om2OqOQyYYxIgHfFFO3YzX/oEEizCnUYZzk0wRxhFDDKzIi0PHKBdGrswUEOeECJ7gC2ngQ/KJIv6eVzp7ijIJkALBaDO30UF39OdudX/tQFoVBqteDd7",
  //   abilityText:
  //     "U2FsdGVkX19KB3PbJL0uVGDeIDR1gLCghn/5/vmwOkioiVLFjkLWgCPS1DKLL4knIVpAfYv7sk/ymoS5aYjDxkaw7slnTa4KpUWxbDgQt4g=",
  //   vgs: "U2FsdGVkX1+/+4gLWauyjlBgYEk7Fikxy+jkkyM3z7wMZHoGhp2ZFrlyYxJO1a6yDD5Z2zQVyib+lwJrOkCHWA==",
  //   spl: "U2FsdGVkX1+qL67dbOZqFLQCudrO4YNVPzdmWrXgnJ2IM0iv+u2nDRTqq1B7Zusq",
  //   feud: "U2FsdGVkX18Qv69WPPeGslXVd5zSK3m49uC9zmRpWUg",
  // },
  // {
  //   day: "167",
  //   gods: "U2FsdGVkX18RqWbIplhk4YhTD8GF9TTCrQIlj5lfd+s=",
  //   quote: "U2FsdGVkX1/5lpLNk1d4mINRHFuLWPpSy52BdRyr1T8nMjgE/QLxgD30mAe/frYq",
  //   abilitySounds:
  //     "U2FsdGVkX1/1sr/qro6CyiVGJAtwBN1rnKgpDHRCli/A7YgMlLGhgNfNo2sCw1OJeEKB5Rp9VgEsirt6vjlZ2kUrJ/eWecEAnaRxxIhMWz4=",
  //   abilityText:
  //     "U2FsdGVkX18j6alXy+E9lj7AsiK/uVikSAPQcZ5bNM7o3aXYrGV4iVA+PKPYb36/14UfLPhmNcwRArEA2+FWcjeJusyyjVlxsE8aYdOFjRc=",
  //   vgs: "U2FsdGVkX1+WDnooSafPTQ7PIBest//9kH/jNxCNzGy5EBoKBtsszvlK/gX8S64bxuk6cbCrKkVb1O/ddkZ2eQ==",
  //   spl: "U2FsdGVkX1/838s8Stay1rhPNAxipmerPwkuWQuwK82UoXeG5s/FmDWKX24ANgw/",
  //   feud: "U2FsdGVkX1+W6vRosSMqpm0wuz4eJeHpNXu64vjxL/0=",
  // },
  // {
  //   day: "168",
  //   gods: "U2FsdGVkX18E2Jh2bXfYBg1qrkXgeOtq1VWjrgND6t8=",
  //   quote: "U2FsdGVkX1/CRs2NV4103N1TU0HistUuF4bWdttDiDmZ/VAby6xmXO/zEJ0iXalQ",
  //   abilitySounds:
  //     "U2FsdGVkX1/DHb8MWpi08rcdFgKXHsBWskakffw5hD6sFlJsDCJZy6G9uBY3w69Auhy92GM4bfH0W02AH+gf3QF6pfW1+dOlGzBMvx6MUKI=",
  //   abilityText:
  //     "U2FsdGVkX18hVqBIR18UE2BUp/ojen3P4Yh1FG5dFljghMzPYP5zn7zBcrvvmXEDlbaxLbsKb0wsan7TOSSueCOI6CS380BZu+Y/zpM2My8=",
  //   vgs: "U2FsdGVkX1/B/k8pMzWtnpSDNh7/EugPu5Ldg8bdNVnqC9MwqVf9sFY7upPV5YqC",
  //   spl: "U2FsdGVkX1/Xu2UComQg9sdmZGDB20crApzSYX++KFCwYGCxy/4sJlECuVK8jw4p",
  //   feud: "U2FsdGVkX18+5dS4D9cJ0JX1zlG3KkGi+VA+pikacwA=",
  // },
  // {
  //   day: "169",
  //   gods: "U2FsdGVkX19o9Tf9c3lEi6w0k4poMUF/U+xeG34buyg=",
  //   quote:
  //     "U2FsdGVkX1+QfIkD/cuzCQX3fIbxcz4h1B2rz1qBm3e65lAKvxSbgGx1Tqk3DVv8EaZKW9+7Ykw6IoPKSdOSgg==",
  //   abilitySounds:
  //     "U2FsdGVkX1/P7SKfN+MHZ0dV69sve92EiGn2YiqUKFVLyLM9lUI/VpM/llOmO9MGNVyj5jDGlUCOoL3vBxKZCNgoS6wGgvIepOlmBXiEG30=",
  //   abilityText:
  //     "U2FsdGVkX18UzIfEdiTV2EHSr3yIGXJzkFquBPmSZIstG/jXerGkpN1uiUQCjb/1e60Fv1kHrgGBcH4Aco5yI3SlpRATLpuiCblaoE8MJ20=",
  //   vgs: "U2FsdGVkX18jcRWCR/xZvn9S32pfBX2PoCzeacoo0BMOpbpO3JX9RxJll7cpsehq3PEh223SHkqKl3p4QJq84w==",
  //   spl: "U2FsdGVkX183R4k8Hp6aaLvtgv7rIEjaQpAGgK49/shZAXI/brMhw3fpr/ODkxOR",
  //   feud: "U2FsdGVkX1+ddPivaWy8YxKMlJsvs7sldy6QfMWO25k=",
  // },
  // {
  //   day: "170",
  //   gods: "U2FsdGVkX1+VK8qdBwpoJZplNIAjUaPl9amv+n3hfbo=",
  //   quote: "U2FsdGVkX18kAky9he7i6f2XmbfnTntI0BrrCRuoixDbz9iZy99x6jrhIH0mqy+n",
  //   abilitySounds:
  //     "U2FsdGVkX1+QYMrUXcUwoQq+Cjnp7pGkeYS1SD8ZVwMX+M9xq/rN8RGzC7JSFf8SonF3yj1UsgGWFbualeVW7TcxGMkY/nWIQInLzoWpBKE=",
  //   abilityText:
  //     "U2FsdGVkX1/dAP9jK6nCaDCKt/B7Td3dQz2EMDjODF4f8ShCcQ6+p9Zmq6hNMhJj7TkILQOb4rZMa6VYGbE33QChKclFoLgePBjBojh8/aI=",
  //   vgs: "U2FsdGVkX19hL+LM4jsGHDH0s+4FNeaI7tydIpRyZ0DzE5OykuNZwYMsZafwmMOEK+WYdH/WzMZjmQxQz4CE3Q==",
  //   spl: "U2FsdGVkX1+z/yAyMWhxt2ZnmJdp5fJcawRnioyqMdD0CaSvtKaF+oKI2cdpCL2r",
  //   feud: "",
  // },
  // {
  //   day: "171",
  //   gods: "U2FsdGVkX1/hgbjsKCzHQSMvudFSs41hLNxJmnM7NCM=",
  //   quote: "U2FsdGVkX1/PMU09bfydMKTZClQL0YswT77hXV+z2wj6yJIUjNg5VKyGhoHvMzmH",
  //   abilitySounds:
  //     "U2FsdGVkX19JboEmL1flT6Z7lOr2DVxtpFULg/De78XQ2Za4sNLBdjAw09mbmetfy0ZMDEajqNv/nvVA39GT9wq49udSEIFSVK+6ZOY6yng=",
  //   abilityText:
  //     "U2FsdGVkX1+quJuaWda1zqF4Kw9EAD/pmc8IAxB1ByciKGsAPlTZDlpifN04yS7K75e5JDc46SxErzqe/na+uZ5GU9OAlvQ4U573B1SP76Qq/6zAs2X1J8dVv/jSZ00M",
  //   vgs: "U2FsdGVkX1+yNSyBs4Zfbazy16nCrptrbBopSDAptRFKyFskzzB/v7n9025xPgEofBKKuNuSG8elDZtiyjAijw==",
  //   spl: "U2FsdGVkX18NN5QRy6rfSJC6YOkGpAJpQR9PLaeq8jeayFQOXHfisQd81SiHb2re",
  //   feud: "",
  // },
  // {
  //   day: "172",
  //   gods: "U2FsdGVkX1+LE83e2lXwEKkg9LSOkZM9WEOeuLN+rxw=",
  //   quote: "U2FsdGVkX1/4gIAPuJ62/G4Jgm4SibQcK3vJC2xwlXhtcKpqa/f829/psBH7YV7/",
  //   abilitySounds:
  //     "U2FsdGVkX1/FnVYdFllUpq5HIGTz8PqEEe62cy8viSU8ZFthZ5kmY+nIuLBA14xZr+59tjTkvQMqsyg5878fVZKOjnZU4udA6a8Xd4XDi7c=",
  //   abilityText:
  //     "U2FsdGVkX1+El+spHB0XvpV7kyMmMhgCqE9+78hmIpJ2BJbrrqOioXkbdKcIIW9ymO97cOtqRIVvCMMIb1Ol3YzrnS4OT5lXQS3UhPM69Go=",
  //   vgs: "U2FsdGVkX1/VaSy2ayR3YCFv6Z2ojgWWar+haPQxBsS2oxJnRdbzc3wRYXBTEzKHKroKmvtQPV6hS5zjZcG7yg==",
  //   spl: "U2FsdGVkX19ouckGVZIF2y+UGYgr+afDrZbbp+0TBzek5gXpTrxycv7MvmIwftJj",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "173",
  //   gods: "U2FsdGVkX18xa1G4fcdbESaUezA7ch0D5uFs9HL34Xk=",
  //   quote: "U2FsdGVkX1+8lRFtrEv6jOaz7Pw9PGAXdIphLtgbkb2RYfyXYG30G4linQXat2By",
  //   abilitySounds:
  //     "U2FsdGVkX1/vC6viaYW3NDMYapjr9U0IQSZxUsjh8BTkMcT0D00hnBMbgJl+Q1D7IhITR2Put+XB4aiPeEiJNpIIAJpBn8SB5+vNyiNWFTI=",
  //   abilityText:
  //     "U2FsdGVkX1/igwuzIJdC7NXvCOyq1LLQyj9bCeH26JSZHfkOQA5JzyWi8D8Qclel8ecdQGvdHHSVAe70e2ZejvmccGrZWeiDxZV4wgVwPwA=",
  //   vgs: "U2FsdGVkX1+XtUnX+okowX0Jw+aHZKG0V54rtk9a5Rzf2Q3QK0jYzH/YLboX3fsS",
  //   spl: "U2FsdGVkX19basEpJeFZTStMbHg+0JvhzbDVVgO5m6hgKchOd/VCWaVLNGcHvq1q",
  //   feud: "U2FsdGVkX1+qDvKDNZLkBQqoOTom2bNCfV9FENhzzvw=",
  // },
  // // Friday
  // {
  //   day: "174",
  //   gods: "U2FsdGVkX1+3US6zEsrGAfHjEDPtU0Le6xNTu2ws0yw=",
  //   quote: "U2FsdGVkX192FP8UJ1Rx3up4cQkYbpRyo/MXgdOYf+7wOZDF9DXZ/g8hwy1I5YAE",
  //   abilitySounds:
  //     "U2FsdGVkX1+0z65KYzOUQpVH4UJ9kW0h6WkBbKdl5SHEcs8NzXnuif80S2t5FibsdabeyO0REa+Sj9UCQ8ubvekaJGBht2EaLlEmAtAeEbI=",
  //   abilityText:
  //     "U2FsdGVkX18X9nCALbdBfIHYNO9+vWZDTPtgKbVCKMuJDJbav55n6kNgIW23Eh8yyOqF5VCnUjcuU1QsnqH44InSloIPDhIoWsVFCr3kx6HMXpVbBav7vYQb1RaFyk02",
  //   vgs: "U2FsdGVkX19wnkotj1IMeEj3/3ArOBT/hGJZoTSY+R3GifgomjvBO1duLl8LwJKH4N3VMKMFGXkoVQXdQMKoOg==",
  //   spl: "U2FsdGVkX1/i5qhuI0JAMOefN5INF2WbGGZXyLmtXqH8DWjqK1BEQAHa4rnqxVzW",
  //   feud: "U2FsdGVkX18vySTm5UXu4bY1GvV5Dg3wgoqNxn+kqpo=",
  // },
  // // Saturday
  // {
  //   day: "175",
  //   gods: "U2FsdGVkX18nZgvqFWnnTj+143DchxYBQs8J4V+c6N8=",
  //   quote: "U2FsdGVkX191nwCC+wiFUwpraGFz8B073b+0ZUJlSoLwDyfL8flIdd+J02OqnQm0",
  //   abilitySounds:
  //     "U2FsdGVkX1+x+DScP16518wmw8NrGjuWuCKkaYdwfOtnyFhBKjmqOHVaughjuDyPNXjRO/r2wkoKJSPSCSPE+oNr23Cj2kdML1zDJtAOdso=",
  //   abilityText:
  //     "U2FsdGVkX1/mBZqutKeO+R/R6rASRzF0GpfDJmvoM7QktZeanRgPh0SI+qIKbhLvNTnhgeY+1FOIGL2bTe3KeA1iO3hRnrsqO0owep9zM2o=",
  //   vgs: "U2FsdGVkX18RBuUyjfkYjZPXkKXz1WapBFDeCsdLVlFrdAjKYpznOfESPTudhl/zoCj64V/X6DmU9TIC886fcg==",
  //   spl: "U2FsdGVkX19vxQr/kPt/HbI/k/CGsSYLP9AbcdzN9LA=",
  //   feud: "U2FsdGVkX1/ehOf1cUNK3Cwsgcuailwzf3uY/GJ2Jl8=",
  // },
  // // Sunday
  // {
  //   day: "176",
  //   gods: "U2FsdGVkX1+x6owb91E4CRogQWM7dFGNHEkHICMfqfI=",
  //   quote: "U2FsdGVkX1+yuHlELl7KfhvRkbQtIdIuiCA+xnx/ggLvZIsI45lYSHKh1zGpK2XX",
  //   abilitySounds:
  //     "U2FsdGVkX19eJhlItmjiTSIK5VCM9Ig88zwqLDwaG4a8LQE7VKsYM//Gyj9y31yqjN85RVQLR4b/WIuHBKCamVhLAU26se0etHRQHyEM4q0=",
  //   abilityText:
  //     "U2FsdGVkX1+N86V9o/lt5eBfjTqDKqe3q5cxgaMe60Q3yIAAcDKr2AsNjcjduJgaMFTVlc2CwGaEC7PC/0exuvH3Ol1UFbXLDGzBlvtFFiE=",
  //   vgs: "U2FsdGVkX1+XhJGV1ue7J4V8XvyMyzfHe6Bg17+iwnC/TIB59GO9FpEauUzREan5OEToggSMRVr7txjRBs1CFA==",
  //   spl: "U2FsdGVkX1974Of3310l+TpJSlV43cNGEJbqPNd2xps=",
  //   feud: "U2FsdGVkX1+7q6wemPMLDX0WH9cMS9H2B1VZ0XGRW78=",
  // },
  // // Monday
  // {
  //   day: "177",
  //   gods: "U2FsdGVkX19B93ZYF7gmOy8QXuv1ITkC/3MKMX6nbCQ=",
  //   quote: "U2FsdGVkX1+2AcW9HgZig/3nW4d4PuVIlFegeorcRbp3szP2PDifC8Jw95h/vQUy",
  //   abilitySounds:
  //     "U2FsdGVkX18X6nA7Y8cj0hxGq+/Tj+483O74Hx0I8tll1qZ3OzosYDqkQ0XjdYS1z4znrvleMSzbQgeSFOiDEekGoyhouVVE/vwsWcgEKak=",
  //   abilityText:
  //     "U2FsdGVkX19krYuFHOC9pDDx1M0YU1YxX9em7D/sS1hGQZce9+SLH81Q1BFEYt/r+au9Ii5DxvuOFoJiTEmwqB1r2X2Uz4ht3Mc0YSMuHyQ=",
  //   vgs: "U2FsdGVkX196BXv8xhgeHx3L1IU49Uf69hwxUkqVTHocUslF840MGJ+kOb8BUP6wiVnXBR6Zc9QSgKSghGVc4g==",
  //   spl: "U2FsdGVkX1/ANAEdCVamdyWx+9EsPQDhEZGQzg1kY8FEcvN4xEEcEAB93tOYpFzX",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "178",
  //   gods: "U2FsdGVkX18/UG8QyMo2fQ9fV4UMoEAQ7C8L6bpX0QQ=",
  //   quote: "U2FsdGVkX18yDmXzLMi3+2sAytNn0i0HAOCB5jvu0IhaHn3BT9ijdzu/3I0O506M",
  //   abilitySounds:
  //     "U2FsdGVkX1/MU3KqUXzabCVT1YiYc1MXwgoeXZ2aYmNOn/CjonCvaufrDzwTLBZ9ZdzMO0it8yarU1clfMZyXel3jOvmm+mJuEy4rE8YFGIr24LHJm0h/lJ2+qTX//FD",
  //   abilityText:
  //     "U2FsdGVkX1/sOsUgWGie1UrR3Urg1Z7SakqivDCdKT54thkIev3sOjUkVniN3WKsqYfMKhgetSnZ9dAXE8CNUvSPsa6DC/S7VPlVt6bk3rzedO0P71LHmiSPjTyqV44Q",
  //   vgs: "U2FsdGVkX18TGYESPZxylABrkaAPt3ADx3b5kGvm36iLCszNfzHjlC/vXt8mBk00jDG69i2d72ip5U5viiZuzQ==",
  //   spl: "U2FsdGVkX1+QQen4U19IIb+N6Lbzfyt9YzHMn7aJiaf37Fs5dQzBknek2C1LuphH",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "179",
  //   gods: "U2FsdGVkX19CaRDH9tQHD0vM8OOJy2ngJ3pnW+xYDgg=",
  //   quote: "U2FsdGVkX1+FAXbQRyrgDcCOU3S78KvX7LlgMF63WAZVYU+khwmpLx6DFwQQKBFu",
  //   abilitySounds:
  //     "U2FsdGVkX1/pACKf2pMGiHJG2xKP9Y8BJLM/IePnHjq0sdwDotx1A9XNEmnGoJURVT/MQLyDY9ttaQ2IsMp4bgGsTTFiiP8VCnjHOM101tc=",
  //   abilityText:
  //     "U2FsdGVkX1/d71aRqltKdPLUAJNBvBU8Iml2xJQej+sc/GOuu+7zV35u1CqFQn3HmDVk7c7zAvk/RRbhwxy5nrN60HBkJWYvE1q6FcSi3+A=",
  //   vgs: "U2FsdGVkX1+j3mtcL22h018iLpCy1GocSdiZapITGiKc2aUvxBBnqI6MtEvioB/PcyaXOrNS+MDoPcbAR8oKYA==",
  //   spl: "U2FsdGVkX1+e6HAuNicvAto+DGcKR7KyOqIEoJQyg0q25fV3LJmo2cPWO+PmFAAP",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "180",
  //   gods: "U2FsdGVkX1/8nXXNh1dv7K141Q4kqvHKRFl4VjxLfC4=",
  //   quote: "U2FsdGVkX19L/KRRMhoF1ShF3ogxFTwsgIsr11/WIlktbKR7RlZF/K3q3gQ2Yk0z",
  //   abilitySounds:
  //     "U2FsdGVkX1/Qu4fB7M+QazgM5U5phIaPpeFZzm8HCSFrSPlJmY4NUErXPe+8Rr73+XxAQe4g/HweJCrBZ9vZeNA3fiX5MpK6PPmhEbbHSD8=",
  //   abilityText:
  //     "U2FsdGVkX18zRA67Wg0RJ6anlLkeZx0qWLqKS8BPZoX10MMl/yY/OIeeHuJk0S6SN6YA+2VEBblzgROXJ6weZ7WhNFCjAtYAEflpVnS7rDc=",
  //   vgs: "U2FsdGVkX19jC+ainpw+xxg2xs8q29UP+rxjhefxVcY+xUzHUmBqPQsmZPQHUEHW9WLjBSpNnISmTokyIoY1pg==",
  //   spl: "U2FsdGVkX19Ma0daZOPlBr4G6n6rtjPaMrS5aTR7seY2aY/40UsiH6Q4+bas7JyA",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "181",
  //   gods: "U2FsdGVkX18fJdmX45xNCGgOi5eR6oe/z98eO/v4zFc=",
  //   quote: "U2FsdGVkX1+Aqpf1Tg2Qq5LEASgZaC4a2r6//rDdRMSmnoxus/ku+vRjUMwqBapD",
  //   abilitySounds:
  //     "U2FsdGVkX1/cmlY3aQzNpzgxzgnNLSBclpSXPSBrMg0yKl8Tce+oK9gYO07ZBlUB5tRHxKc/TZG+bRJx3NKW8KVQBeJ8b+AuazM/qwFGwzvcF66uWhxg/AEPudtJV/5q",
  //   abilityText:
  //     "U2FsdGVkX1/zr6y2hWVdLfHX4KIWLD4wqBg4NlR+15FIedQ4l8E++DSTOTzX18yP2nt5XG4c44TG+dXBUGxjRpI0xG/E+LlHawXxezJ9cJ8=",
  //   vgs: "U2FsdGVkX18Vz3pVtirLB1lNUzqD+WctoLBrZZaYgMXEx8E8Chz+2wslGf6wHEj4q3SfWMLoumYz56ubLIeQkw==",
  //   spl: "U2FsdGVkX1/kF9vkqzFFlPiwOJtzhCPIvGXCgunlbs7MY6KONJG7wD169OKWz4bF",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "182",
  //   gods: "U2FsdGVkX1/lWWQxdgYRegkRNDJ8lQ2Ako84KZ4m9sY=",
  //   quote: "U2FsdGVkX19EHMfyLbLFIGawA/lDovGs31WIQtZVgNPFZA5QgaXW4ITnR5P7wxZ1",
  //   abilitySounds:
  //     "U2FsdGVkX1+tD962Dx/ecULJquZrhEmMzNug15k3ck4pCN+nA+j67iVsKIKNVh1XTm7qeKvUNT1YfeVzCxtAACBA+kEZLWofjoO6TbxJXWAHuEgmZ3op8dRv/86kQe2y",
  //   abilityText:
  //     "U2FsdGVkX1/YPvlGWp2PIFmliyo+5i37HNG6un45s1MAgm7EoiqKbda0HwzJNcWoQKJ2LVSY6+VjsIACpCZvvQP+h39rDwqVLxEQlZjMpHU=",
  //   vgs: "U2FsdGVkX18ywZ6I4USZTEXlfA1Rb5TjIjoU7BcDdgOxBCnJ1xGrWYFMlbTgXgZXufJtpbIaBjq5iXj4DyCUCA==",
  //   spl: "U2FsdGVkX1+DUS31nzuOxrYYRAOEj9PJfVzWDmmaNfsp21Nt0tWV6CNUN0Qlpw0r",
  //   feud: "U2FsdGVkX1/12qcx1OrZ6NA7sybLmfKMks+gGlc8320=",
  // },
  // // Sunday
  // {
  //   day: "183",
  //   gods: "U2FsdGVkX19FB5CtJe4K9iuFRfb+pVTixOUgMZ9kkA8=",
  //   quote: "U2FsdGVkX195bJosBnZdIsYyOkRvB865rrAPcPgTN+j0XCeFmHHpZFyClme6NAeH",
  //   abilitySounds:
  //     "U2FsdGVkX187NR4O4fUeSwZPXrdnNgcUGJsfdbI2BJUDIHvX4fkMF3/seXw2/tFxmcpw2csPyoQNzhVDO0xkhqiJIpZrEaD5MpmM6F7N/fc=",
  //   abilityText:
  //     "U2FsdGVkX1/SphsYkgNvAHpKa5Z79DMLP+4bHw0OAmuNZwbKBBAVMLA//QNMnapFBBqs/R+un9ZLjiAFnXUl6Vc8YKORHg0aTFxqwh5HLv4=",
  //   vgs: "U2FsdGVkX19x9MnYN/IBtKUzX0FHvHaRIwOE0AcdGJdOZe2IjFZQQpLlZdexv9qrcC98UwLBGEVZ++Ela5SJ4A==",
  //   spl: "U2FsdGVkX1/C3d814HbOG3hjw2A1bqKtUOVySbIBYM9vV9EzGjk/CqNxDHvGEU/+",
  //   feud: "U2FsdGVkX1/uXJS63itC3zSl3oHC1GCp/FMUh+hOdr8=",
  // },

  // /****** *********/

  // // Monday
  // {
  //   day: "184",
  //   gods: "U2FsdGVkX1+Mqy4rArOJ+fressJdriJA0E70vSo2opM=",
  //   quote: "U2FsdGVkX1+6tdSF6rLiAwXUgDpy8kUgZvqID5cGPxzpfpW8Pr6aztl4xKfL6y3v",
  //   abilitySounds:
  //     "U2FsdGVkX19v0Sh42iO5zDxRbmBsQMdY0KXY6xinOoPfKZmV5PkX1MBdLZblL8Fc/hrkdiGQBt366YSUPnKY2hIYU1BH2hqh5vyZjZOJ35o=",
  //   abilityText:
  //     "U2FsdGVkX18fwwVu9magvIK73XufHPfjeHDAZfZKqDwsQsNP40s4q1DRe8aMSBJx07xW3otL+womc8uli9NPhue9SQ0h1CaUCf2OyIn9/Mo=",
  //   vgs: "U2FsdGVkX1//7gitWqM5csZawaOgde98QboarIrbP5tJG2WZGPT9ShIeFRITDt5TB2clwctTU9TjaoIfHSr8Aw==",
  //   spl: "U2FsdGVkX1+ihVW1WD0N9jc6nVFLdeWxI9ZrKx9hXZnwVfMbTDXrf6PL7oioKQLt",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "185",
  //   gods: "U2FsdGVkX19BmEqU/NTHKgeP3LVZjR55QwtbkIip+1Q=",
  //   quote: "U2FsdGVkX18FYMtCCMwonqu78/bn3soH65ZekU/jM7QRb8g40hC8ifvZouJhhM08",
  //   abilitySounds:
  //     "U2FsdGVkX1/k6LBpbK87KRckrFqQOT67yDyykkLFmdGVLWH/cjzs74722453FA3FZuHwtmUdhqI3Wzrwg7hRNASfmx1BnSfkwPjgI4eniFM=",
  //   abilityText:
  //     "U2FsdGVkX1+p4ctTVIfBcrGxK4JjIhmIcgmusMhCJD7qovreiX97A0aquLjAhdFTSf74vzorPmA5H4WgQExskd5lEcxsJQG9OAaCr1s+MrE=",
  //   vgs: "U2FsdGVkX19Ke0p/Mo+8+ZempN261yTFLu8lfOG3AFPXq309v2dI8/FprS98Zz4knt8VYV7ZkcEcefZJShZcEQ==",
  //   spl: "U2FsdGVkX1/Io2DT1pbXNVUr2ZUFtF8zQ6IQ6AvR9VsQ0r8mWzo9ak+RetPvN6pb",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "186",
  //   gods: "U2FsdGVkX1+AqT47SHoprnyUJvqfxgqBkRgFh0LClGE=",
  //   quote: "U2FsdGVkX1+51VeFqAa+AAEzg4zSlbB0gJHJTtU+d3eoTenbXy8eSy7k0sE/skBD",
  //   abilitySounds:
  //     "U2FsdGVkX18nl59c77p6cicEv/PrVX16vsP3eROqqPpxIP37Z6W2XqPWliS/hNwlKWwbfEmaIaeOqdf459S9PVVujaCEAZiI7QxFFlAjgrY=",
  //   abilityText:
  //     "U2FsdGVkX1/ltsmYIYmWviD3k9yuZmc3y+fNKYpf9wTdwTAmstNROD3JWrULpCk3Yr0ATOPtl4aT8/gWGlWAKvNvKYwgl3V6P4r89aqidPs=",
  //   vgs: "U2FsdGVkX1+R9FL6XBJBs+NzqNP918vrpV9zVzrT88NWWJ6eNPZg0213HPq1GMgAvDC9uubprlFvU5thsyd2Zw==",
  //   spl: "U2FsdGVkX1+GUKFLkHtb+yqZYfpbJZFP/2P91pt/TvT8Ksu8N7HuReI80ls98F9f",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "187",
  //   gods: "U2FsdGVkX184009hP5RO0ln8fbF+R1lw/qABcqwuyd8=",
  //   quote: "U2FsdGVkX1+x7Fjy71pogZ3ZcOG8RBHZDOocYc/vFOxAR7wnlHJsXTfI4nMEKGDv",
  //   abilitySounds:
  //     "U2FsdGVkX1+QhK5TJB2bgzECRSXRHc7EawXurNCp2cZf81wwADzPRErLwBOAcdOzZBdnfSFNrVtfu/3M14NU/fwtd7bcONHDoyItyv5Djp/nIFT7oDGwV/YqDe71utAh",
  //   abilityText:
  //     "U2FsdGVkX19Ht8wC3yPdplW0hzMAKJ2DwDaEV1ev9i1RfmY41su/Q52zovGso1nvUzEqacu7XbfwhrPDedtadHxkXGIdnFtzvQ93tx4Dpq4=",
  //   vgs: "U2FsdGVkX197k1AlXAGUWTcguvmHhrmh1CXPogFpFFPYvwFdv6uGTggQrnCjpYM+A7BEY51cqr6bgLgeK1xWMA==",
  //   spl: "U2FsdGVkX18K2AhfgkyBsQC1EEQfavb/iiAfaMY9zNfR6QJDeuphs9xCztDlDZzH",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "188",
  //   gods: "U2FsdGVkX1+wOqPuDKYPoNcY6pZyNWovH5yAI5OQDhQ=",
  //   quote: "U2FsdGVkX1/R49DJ2KnKUlctxs+OD73Gwl/cE1DCXEGwln6CRSwa05+7UJCHXtP1",
  //   abilitySounds:
  //     "U2FsdGVkX19a5yGAhwT4olpxcLJC+zTbhldG6szcyC2Rhx27VfG/WdzVBQa+j9mY6rgUcTz9dxNr3hN+XuG4DYFGLt/E5FYQBA/RNmwrgr4=",
  //   abilityText:
  //     "U2FsdGVkX1+7xPoR4SSzi/zAjFwSzlDAS00/jn2lnVhwLV56HNGEaqH7U2Ysm7ianuktNjbbKDOFvDJH0COwtcKgERPa24F9N1ASdbAQnIk=",
  //   vgs: "U2FsdGVkX1/yb4ESkgBwu79LIf63j7TSqfvWpDH7g02NbVhfBX4K9/g6HPbzFJGZPbDcfQG6XL+fgW7J56bHPA==",
  //   spl: "U2FsdGVkX1/Pv3WN8rE4K5M/31Nd1ByE78jpzlel5UC9/6eYp4pBXHvoGUCdYpKP",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "189",
  //   gods: "U2FsdGVkX1+ACKON9VM+ZDpiJib12v7qgD0Zo2+t8RQ=",
  //   quote:
  //     "U2FsdGVkX1+A+Vy4ac/XnJ1ry26VQ4HgB9sH1RduAHZGgok8izxXth+H8kKApoPzM0WubJR9StCP0XJtpXCx4g==",
  //   abilitySounds:
  //     "U2FsdGVkX18NeULiZYtvFauxM6eSG1PPD7YEH0hFrZCaU1WEX8te1G1BZf0HVKQi+jCWVz/uduEUVThiRAB0nnmJFcTiBOlU9jiC09kHBJ8=",
  //   abilityText:
  //     "U2FsdGVkX1+dzGMLrbdj/MPewymFqsDsVHjAgL3v95PiIMC63wKaheEUBczWmfcJdHp+M3pFpznQvab6Xn3l1GfGSvaWQDWnsLuC6FIfmaM=",
  //   vgs: "U2FsdGVkX18ccdSesElFN0SMNDPea6+cM64VlV44uL+GyoiOeqjyDZ4zhWZ5RFQcfsxlzVFcGOOsBEP4OPPpoQ==",
  //   spl: "U2FsdGVkX195ZIqoXD129Q+uqtwStUm793qP7GGWS82j6N5TfCjOaWquokSfaia6",
  //   feud: "U2FsdGVkX18QiPxLm44+i9k2Cs0hzKEnyYW3/VlTlsI=",
  // },
  // // Sunday
  // {
  //   day: "190",
  //   gods: "U2FsdGVkX1+Ckc7bo6Hd829VRSkTyASnVZU2muz8pOk=",
  //   quote: "U2FsdGVkX19Tqvk1FAC4KH/F+FJkdLKonZRf4TYSfQhLIMJqqSBW2JQvm2dUBrgK",
  //   abilitySounds:
  //     "U2FsdGVkX1/xrUeQqPhyF87LP1eJk93b9suje3oVGkvv30rMlxjPCHdbLgoWb3F2mlWRg535UlR7gz6wwdk+fVcSRNZdaE1f+OJ/5KjIYBE=",
  //   abilityText:
  //     "U2FsdGVkX1/QhXiOihgjyUJZaotqg7BxbwUUyqrnN704Qh91iTECPXf2D3D7TvXMI1MYQzc6idpndpPgMrPT67Mk7B+rH6x4lcnS8q+Ve6c=",
  //   vgs: "U2FsdGVkX199sgJKzZVPOGkhHMJhphjS6GfRkLKwvL0bhzMWpTAJhobQeaSWGuqlmuPishVfGXF9YzXiUF5HSg==",
  //   spl: "U2FsdGVkX1/wIZDb7ITBGSSH/PRoIRm/thuQLx9sLyD9eU0j68O1wqfTGxNbVFwQ",
  //   feud: "U2FsdGVkX1+9gjT8WSNvGzODNcBg0ReDM9jfltWtz84=",
  // },

  // /***  ****/

  // // Monday
  // {
  //   day: "191",
  //   gods: "U2FsdGVkX1/+8BYQYJSclhB8Urx6oPSB0wOevLox7gw=",
  //   quote: "U2FsdGVkX18pC8qZSiC5JrIN94t2xc+qtJ2nBspGj9aNI1MyDIDqHN4eONAucbpE",
  //   abilitySounds:
  //     "U2FsdGVkX1+6sZmsm1SlutO+dTPze8yRZZSjK961vD4h+/gh+I0HD8iIR4EzPmfn/N4LPyQj2070/l61a210MqdCQ0xvhVVokvW7Ng6BL3g=",
  //   abilityText:
  //     "U2FsdGVkX1/2gLYyhLPGMxdIxGp3ini/+uBGf7vuzs9RgVtY1QZ2dQnFlL3NVGAK9UoZ7La1LjXXT36F1oToOWFwhsHX4NZupcuwx9ZvQZ4=",
  //   vgs: "U2FsdGVkX18Ufq374fApqppeGABfCNzmenec8C7nXJc9FJ95hhCbfnAjvUayiWmSRb5Muh7a3xd7WbJYi0c7KA==",
  //   spl: "U2FsdGVkX18vPLwJ2+MraMJt9isN5iC9Zc/ciONBSUg=",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "192",
  //   gods: "U2FsdGVkX19+KlldnQXXlYKeevbEoXjZMMJ6jjpD8R4=",
  //   quote: "U2FsdGVkX19buPr009vRPCQoGCYhpRyzMSxY51TemQyaY9JyG2KP1yJoe81TMi3q",
  //   abilitySounds:
  //     "U2FsdGVkX19VvmpcSHZCnlnVQTkfxHwQYh04yuNux0l6wIZECLUKnmWS30o8GTfZ9Wv3CrNmpIlFtCpc2abnOq7CbEI4ATZN1SfSim08uy0=",
  //   abilityText:
  //     "U2FsdGVkX18489LQUyGQAJM8L+rTAQ7dZ964RC2ZPfneQngBGi7lI0YNIUv0V0dpt3vThNDke/ABd5zmp2NTtJ+y2FmOVuaD54w6hHegjg0=",
  //   vgs: "U2FsdGVkX1+CmwlvrEIXayenyRwr7vg3IKnyuiabyR9iRtM7Uk8FP132gLzRdOtBuEZp1YNkUR2/xNiMe4wSNw==",
  //   spl: "U2FsdGVkX1/O5w0cdiHZGY/j1Xo5v9j34zOcj+MJ96E=",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "193",
  //   gods: "U2FsdGVkX1/ZIYlUOsYz5UT5V+wneRaz26dmcQbV6tk=",
  //   quote: "U2FsdGVkX1+xgsMZcMbYHrfwgu8INtZ3N1Zl+c+N4QYe2DlH7+3saDWPa4q2doB9",
  //   abilitySounds:
  //     "U2FsdGVkX1/dLzMhQgC40xzXnIQgnqjz7IZ0Pso6wJzj1VunZJqtR6PrAtVIVpVGlOnXRKqSQ9hWDx6bcTo/+MSZzDmdTz1sGIUPwaNqaBw=",
  //   abilityText:
  //     "U2FsdGVkX18ERM9s/pplxFzNKbr6leVcKGG1Z/fwqUg1XjR8vj/H3oac6///clgy1WObEfFmhRQted0f5pfzjEYVLr5neE8X34RmFcSRBqs=",
  //   vgs: "U2FsdGVkX1+C/gctuiSF1HRKxNKEZTyKLPH/jT93cCzABO1N0jAWhG4ruV45cCi58UbKPRny4KxM+NZYdDchsg==",
  //   spl: "U2FsdGVkX18SGZR0JdrQ5H1vjes3Wq7OfY43f3E2onGD/ScWRzkR6PO+YqsN2g7X",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "194",
  //   gods: "U2FsdGVkX1+OCUzIIl0Zg00sfjwGqtG/fcwTpu9x9Zk=",
  //   quote:
  //     "U2FsdGVkX19mchHFDCIYBZTKMr5dBF/w/JpQXtpmVbPzep01vZm+CQ7xLHmZAHdKwXi+BJi4TAIQyqvxIUk3Tw==",
  //   abilitySounds:
  //     "U2FsdGVkX19fpVk7hzN5yuPwQ97KBVlbd8yuVKlU17wmVHjEFfF7VawpoGNhd5CbIZlzcp7bQLk4vmPkt37YqFbNsKMs7wM+EOAsrDJSkTLLOwYgGLOCqBlTOyWQ8EJK",
  //   abilityText:
  //     "U2FsdGVkX1+6jqnETZMpweMdoGW83sPDlznCO0hFZFfwYU2xX8CVxYl5mS8HbmIuOC0eGGIWpSuMpLvsQCG6uah2H9RsJW6/w+y8mX3q9kI=",
  //   vgs: "U2FsdGVkX1/JsIXrjrmetxdoBslWRSnkgWJlibVJbNOuzuVW+34KM1jAuCNSCIe9BXCSTSI/LfySpcVuvmZHxQ==",
  //   spl: "U2FsdGVkX18owoOXRebBLy8TFsBZekNg2fQBj5/+XZAGvR2e2lmUBAriJnoLcc6C",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "195",
  //   gods: "U2FsdGVkX1+AC/YuHRBu4u4T99HUUCWS+Pxiohj/m3c=",
  //   quote: "U2FsdGVkX194i5uAHElZQLe5+glLyKTLcOkjlwg9/jJbrdDw5RR2SrfNBC0HQ225",
  //   abilitySounds:
  //     "U2FsdGVkX1+le4yIQA3BupGMTnQ7o/d12tf9+Ev1nznnECqjC3rPFn09kW3+KV/bp2teEnyvB9PWDq0XLK6Y9UPphSL/EGGjWQX4eyKUgEc=",
  //   abilityText:
  //     "U2FsdGVkX18ZLSqJ8DpGPmzfm3vf7Xbudqsd6Hw2wTDE5ViD/Qy3E/U1UUoETeGyZ0T28L8Ygp6w88jgvpO7eeLtMgEip4kUE5hhXrQLNkE=",
  //   vgs: "U2FsdGVkX18DBcLX6kY2dHci6fpJFn6W9NXyi9r2bQIZMu2GuPuvIQ8fkBE533TVRpnIq4WDYeDrKgEAzolJEA==",
  //   spl: "U2FsdGVkX18ftfb34QPaFacSdatVLneTQ+B71l2GAKXgq+RrqUEZFn5iEvX9GU78",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "196",
  //   gods: "U2FsdGVkX1+GrbKg3jG6Z11HkHvHjMjXeftK8G+Gayw=",
  //   quote: "U2FsdGVkX1/TRxxIkcQLshKp62faux/C3ORH3NB+pzmtdpqSodvu2dEelu5aA1qL",
  //   abilitySounds:
  //     "U2FsdGVkX1/U6tBP3arFhQeTufKqR++xiCAthXzMujlOBCxZiElZWDPAjD1e3BDS91pRsHFcNk+jVC94whUlRWgCnQUDoiy1orqKUjoS6fU=",
  //   abilityText:
  //     "U2FsdGVkX1+ap29WNBf5hPiJ6rmg0scFZyte91Ok2mPWhQWFlV9NXXyTSXc0NN25B5J3NXYJslJYZdEzy1sZGgoZ9gZ7vtlELtJiwgptxiA=",
  //   vgs: "U2FsdGVkX192e0c+wdmenT9Qx0tNeSERB++5jgZxmihqwJfNo1u/67plgkIhMsbcoesxzYXfRPM45RW1sM/kQw==",
  //   spl: "U2FsdGVkX1/Zr5t59t1mLeagKuB8w8S8Lwh2tttC/GoHURts2C9Cd1D6MitPlYi/",
  //   feud: "U2FsdGVkX19no1JZzTTU7ut3UrAoG1MuBr01xiU60tY=",
  // },
  // // Sunday
  // {
  //   day: "197",
  //   gods: "U2FsdGVkX19sQ1XiUtMidFJi2e9Hf8s7IPOIL88KqsI=",
  //   quote: "U2FsdGVkX1/5qFiQGvrLC44yFUcoWzEEuRNZU6ODwzygKI16zvRaRdq/DnyGO4vD",
  //   abilitySounds:
  //     "U2FsdGVkX192U10xG9qtT0CmTTZyeausfIoRaIM8rzHy8mCGX3sJqHSd91VrumbQh7P52PyU+SWJZvzpXp7h8XvJuOMVwEm3jl0mSo0M1JU=",
  //   abilityText:
  //     "U2FsdGVkX18bzpfNzgqMIA1PrXzzKqYbsHXx/LqA5DKPgrBnI9gW4Rg/QMLVXCSQh+VRTQUGrt43O1xwBL6xPobR3kJZNnx/NG9fj6e+1Iw=",
  //   vgs: "U2FsdGVkX1/uVmstITfRqNfuVl9KpZNlflRp8qB3y0eX63PBG+Uce5J5PBNs0XFyt1sZVxmJOQ3F+gHj22DRow==",
  //   spl: "U2FsdGVkX1+rxWA6k+w4M6TqNO7IulgWGYAVEN0z7lq/5fKrtL9BqteQG/S0rmps",
  //   feud: "U2FsdGVkX18b+l7WtC6RvJVlwumxGHKcGr7ThdJPN/g=",
  // },

  // /***  ****/

  // // Monday
  // {
  //   day: "198",
  //   gods: "U2FsdGVkX1+9NFqjKENryIu5GyFJZEqfPIJ+Rlx6Ed8=",
  //   quote: "U2FsdGVkX1+Q9V0P3R1PYPJf3QlVE1OeTBIR4rVh68eLA1hhC2cYwH0Bv0CMPqkB",
  //   abilitySounds:
  //     "U2FsdGVkX19DR0b3ko8kbH/V2adiBP4i7wdHh5Awm6UVvLBBp3lrwz7tVv3vbgRy08XUuZvP79z+Y+OV16o04Lh29aWAoRvYnIP8jbs9QqM=",
  //   abilityText:
  //     "U2FsdGVkX19u3l/ltoS3A+L7DpfqKf28Ztpgx2M7vIiNi1jTdsrJ23rPieoysRJFHyqRgfRVhaICt/s9GnpgovCZjqPDz7/k/ySUIbkJD5c=",
  //   vgs: "U2FsdGVkX19qN6XElGeZrARy4uZVeH5cQWkFviRlzIDzPi7BwARyxObDf5eA9NA9SvKiPaAO+tGygV14X143bg==",
  //   spl: "U2FsdGVkX18Npq4gwcIr3pgjpAW3HLN5wMv4LWc5Wvh8Z87rKNdrEunRhURS1Mlz",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "199",
  //   gods: "U2FsdGVkX19MfMQX1Ot9WhWuoOvCQm+E/b1d06LXnTQ=",
  //   quote: "U2FsdGVkX18iQUfhkec/bpBYYwrfu/71ZSQsNYbil2Yo5083CptiYlhspo56NZCF",
  //   abilitySounds:
  //     "U2FsdGVkX18Caku95a0Ix4Nf/mJR3QCckWUKfqPPUmP0vZaVbbrctBviuib5yQ3EogHwyFu92fW0R+0lirPWOc9kulEr79htz9/gJmqzkBg=",
  //   abilityText:
  //     "U2FsdGVkX1+i3SYBsmpTyv4fw9ETisKuo9ZSb8WbcM9JiBn2mg8TkrZc/X3XY9+JnZ5fxbcdZ8wSlPsKc0kOvo5vlRdYzJ+z0RaAhW7vDsg=",
  //   vgs: "U2FsdGVkX194CZijvsx4OHfrlYA8Z5RTcIZc2xrrr/2KJOSqeyo+jfYIYlWeIWAjN3WkuRoUjNiHbAGp71d3Ng==",
  //   spl: "U2FsdGVkX1/N/OXmlSxC1hnlEXQwVw4fnTrrjlANVIqb2yLYy/m0l6+bULH8XRB1",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "200",
  //   gods: "U2FsdGVkX1+LgmGWyYE3wypoASWbscYvuVs7cRwNrFA=",
  //   quote: "U2FsdGVkX190VbdMe6nNjGeeg/5z1Mia1ebe01PASGUvrDfgIuzagE5g7/0tFbWl",
  //   abilitySounds:
  //     "U2FsdGVkX19qPqDbtZPlG9qYXww25mGcekAPmcw1MEn44saOek4TVHPRNJ4q4BjnbCRhvUWxmrFyQXtv361uPLN69S8oxkKZSuwPUQrSiAc=",
  //   abilityText:
  //     "U2FsdGVkX18h/faqALjYsAIMeg8SNhA6C1mXBcCCPL2h4ZK43bQVhrcbM92QfqwWunCba82llMjeJ+Dig36ZjU0xRERMmtGtZRuUEB+odRg=",
  //   vgs: "U2FsdGVkX196AKuOZyfEAcSDV8/O1Xn9JkVcbAsdTzPWF+zGwsZvysj/xj78621dbPY7Gdjc+Gezq0oN/kMiVw==",
  //   spl: "U2FsdGVkX1+3lpFEbyQ6kwIYEGrXGNHEklMA2FDJQ4ZxO3qKXfjXNDDSM3C9gV/U",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "201",
  //   gods: "U2FsdGVkX1+Smp+hWFqF+I/m296glbnH0IRBBEh37GI=",
  //   quote: "U2FsdGVkX19DBk0gUmkfIcoVsydUWOJZzB+tXj0Qd5TZyOGM0UUku13ABJC/um4f",
  //   abilitySounds:
  //     "U2FsdGVkX199JYBvPEjZOG5Db/OsHoNXepQIKyGe2DsRQhCclwfmk9yBQIPwjKcILHvxtOZyh3QkPOAonCRpgVtqPbDwgAiaiXS30cHTSZ0=",
  //   abilityText:
  //     "U2FsdGVkX1+RSb5BWtOLhz6qnSuamxN6sST4V/hCWziuWapKW5k/RKhfRsosIaDZcfULF660fc7VpxKiXE/s8/MGGzPDt8n9r0y7pZxYpIk=",
  //   vgs: "U2FsdGVkX184gM9/r9HOOs9rhQ4+q21zSB5B3hzEr3PFgfqzrovLPPzbHAZCbhmHEyXvJhPLf5zO3Mi7OLWZ8g==",
  //   spl: "U2FsdGVkX1/I4ey50Qgif1kfoAjYsy54n+M2UCtgRE6vJQKvHH9aJSjGYavhu+/I",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "202",
  //   gods: "U2FsdGVkX1/Yd2I5024Qf0ljLtZe6O1wO15ttLus6+A=",
  //   quote: "U2FsdGVkX19LexwXE5Q/1mRqQcGkXsFgy58fLTserlZbqUeD4Lcugc7mdd4okXC4",
  //   abilitySounds:
  //     "U2FsdGVkX1+ugVbkiPQIxKKwp1Blw7sHNT3K1L3oIziM0stsfOoT0KdtNq2vYIGHw/+26owysVC7eFjhOgJLBj2qsysM54JijKMOXBCOVdE=",
  //   abilityText:
  //     "U2FsdGVkX1/k79xHKABB8WaQkSikwej0AIC7Fh6cnwRS+1G0kBV/tUweciy9tGdC9xaLa5FayF9o2pz22SU7CoEZ/SjzBU/RL6Yu9osr3vY=",
  //   vgs: "U2FsdGVkX1/MIgUe0Cheu4s+O0+nyQBBdth91IJ/vb4pel20e68izC52y1K08bRH0PXtItQODNQR2t4fpPrPyQ==",
  //   spl: "U2FsdGVkX18fwTxEDKq4MSWnyep9YNvboGRX81p1afEP3TMXQdCkItxZAeR9h8st",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "203",
  //   gods: "U2FsdGVkX18zZv7/lxgqUprWY+FrN21QFQ1WzS2TGyI=",
  //   quote: "U2FsdGVkX1+ySFQNGNwBV7ZeSlEA4bP66HsXkoMYfx7lfPg5onnlpJbbQbVYzvGQ",
  //   abilitySounds:
  //     "U2FsdGVkX19/m53aroN0L4VtnzaYcGjhz0pJyyMcKTcFDiBx4iBqVgAd5sWVVopcy+Yky5VG/oB5auVzfrJW4TOigp9fHL0GSHLdLaIkP60=",
  //   abilityText:
  //     "U2FsdGVkX19sDFoLtSLo0AploSkEGhD0XbC/lSpTmCX5hhppDNB07na6/zvMDsEb+I90fZ2ERXldXuOAcjNbv1FVQsJvpzuuo+ycElFetbk=",
  //   vgs: "U2FsdGVkX19OdVTrzMQhEUd66DRQ9I77tvfloJH1BD5iFiVGB3yY7bN3vJ+n/9+LP8Lqrae9Jm6p7bdyp1h2sw==",
  //   spl: "U2FsdGVkX19vmMnAmY7oj3Dc8Pmi6Tb0mEA7uHA6p/sKh7MAeyg/IfKsWKXPZBaj",
  //   feud: "U2FsdGVkX181D9LM2HqCg/5QIymwMtimxPdaamSd+QA=",
  // },
  // // Sunday
  // {
  //   day: "204",
  //   gods: "U2FsdGVkX1++krHJk/x/IRFBYHGy/gDgbWikBhIm4eU=",
  //   quote: "U2FsdGVkX18NYur8rxkb4yQeRvOpB8w5exLijehIh2Ycr2UbscIm8Vx+TOtbVxNG",
  //   abilitySounds:
  //     "U2FsdGVkX1/1ZfvMGN9xBtK+Ry7+OMGveACK46H4J5uTV2Uz3PvELgauqIwRKGc0zz9qrjCIKav6FBOofvFzIw==",
  //   abilityText:
  //     "U2FsdGVkX1+IKIhH3tahHf+tJqQkC634TWEwM1iggKmC3okaY4HmMn8kfqzPP6Gr2lgopmL2pVbV88oxA8OCPfx8CUMQfkOI2GGtUUNbiMM=",
  //   vgs: "U2FsdGVkX1/wcDcXYgLc9R6TuAuAxZbknKGkPueMHFW2ntUTPJZ5L6B2nNpkvUct+vI/ufaOgPavgqB/+pldpw==",
  //   spl: "U2FsdGVkX1/Zawcb7EEqbo969Yz9aFk6y0E1D+aebKp312VkoELbDq0vP/QCwhuZ",
  //   feud: "U2FsdGVkX1/UaTx+e49qOka+b3NUmFVGGMGmhyTedGY=",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "205",
  //   gods: "U2FsdGVkX19qNJx8tjjaoVeMoN/yUSfF9y62lD9FPak=",
  //   quote: "U2FsdGVkX1+W/l+e+ihdteu1Tw2LWLhfH9wawueGttpWU3KkLO/IJGj9DaMOZU1r",
  //   abilitySounds:
  //     "U2FsdGVkX1+7i+QV+XQqplzLUbi6wv7+Ka1h5MEn6XVIiKH6XnulmYJquRDaHWtNCFlhZxwo4Fvv41vONItBoPNTR0XZGIFNfMkbHfzQ6yo=",
  //   abilityText:
  //     "U2FsdGVkX19o8T9+vjkSMGNK90k5bIoeto0MxykjT+9BmBpdMABBI60IM7AScd0D0wrY+lQhDXTBQ4/s/c4Exv8ZSNkBNe5zYqvAvoy0LhA=",
  //   vgs: "U2FsdGVkX187JTelYykbzBKgZwEM2Nzz3WP5oPwhAaO+nWG7YsUwilYF4EJQwzGM",
  //   spl: "U2FsdGVkX1/LpqV2KZAqAQlfjWPxOUftFs4Nn0wcBCSSHvTwYo+wwCi/ivVPT1Em",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "206",
  //   gods: "U2FsdGVkX190/rPhSYDeSg1Hij4tADZHkYnYdlLTKRI=",
  //   quote: "U2FsdGVkX19VBjhA/er1YLWK+4f/syuuP6CC1jCQahh8Lhh4jtDop3rsbKN2FRUq",
  //   abilitySounds:
  //     "U2FsdGVkX1+c2gUmUQM8KW9/a/0UeFxlwkj4oK1cnA1i7NtIulvy8jcWNS6CmNWCGof+RRPDPVUDRwqGaX3JVJca8uJ2/1AUFhAwJvCIDJY=",
  //   abilityText:
  //     "U2FsdGVkX1/DJ9ZtiPtik/BNC7eHgNHUfMKiFWvwFbiGjrWojkCPw30k8wHL9sLvQ0D89kfFUHsvGKQSJAV9WV1GKIx4zNQq7cRbrn6NFxk=",
  //   vgs: "U2FsdGVkX18d6C7isqgP3cvtN9hjUt5+P95ccirqU7WgmRIJiFnv4JClaQslOtVl7PbEoUdmqqAkP/ex5oDSXA==",
  //   spl: "U2FsdGVkX18tBWWXQl1cNmNQWw4VRoxRPAddxMmV0SDvAeqV0JUjIl/ZaJH8F/21",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "207",
  //   gods: "U2FsdGVkX197tn/opC5N6F0yd2lSixTivLQ2GPNejUg=",
  //   quote: "U2FsdGVkX1/Yy1rKe9twBeQnQArfqOS69lVv6ldsFEjysGfmquanjfvXjeSS6FkG",
  //   abilitySounds:
  //     "U2FsdGVkX18HrAo9PYMqj4lcs14XHwIJRQ64LBy3BBwjouGhiQk/cMB5+GtvhnMojnL8WoDNICBv/6dfLjFdpa9cjCfngeEuMdfPNSEdaw8=",
  //   abilityText:
  //     "U2FsdGVkX18xjrHJBHkvbKYWhBdvcTL1r5ebdl4etNpM1JkM/zXjAlfq9NzHzZqHipNy3Y9naDA2irPaIHHfOujOrpUmqMQGKGS8l8U5S43KC6bDoFY9/Hx1P6Yd6Ggq",
  //   vgs: "U2FsdGVkX19km6ji5ix+Uw75v2F/HIrgUkTRxdQTP+D2gFzclrVaCNMfHG/ptBt8FaWfCV7Wq4mK7E9GgaQO+Q==",
  //   spl: "U2FsdGVkX1+K4I5rC5n3b13QfZMNdujcBVzNSY0K481RN2r1qlh8D1vJwWE7D5W0",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "208",
  //   gods: "U2FsdGVkX1/68Q02Y2Hv4kaSuCXTuAt/QwRo9u1KWgY=",
  //   quote: "U2FsdGVkX19bLNzn2tNeAC3dyt/QcWvKRvvYcIcRi8N493L0KI4wihFZfueTMJrp",
  //   abilitySounds:
  //     "U2FsdGVkX1898KaCPC0M01S/4WWzWiTZr8YQY7i6w+BdfdWXtNMsvWdbNoYAgMKueJr6J5eFBg/oAG/Cc9GDI7tfmNLG7EB6Rp5HSAzTwgE=",
  //   abilityText:
  //     "U2FsdGVkX1+wHp7+9EpSeMymH/MW/L/CfHPx9em0ggm74Prf5XsjDoO7HNyBiGCA9YnKrIe91B0lTAKQ6xNn7SHttrXOR6cKkhmyhUtITi2XlGZD7rfs2RvCe2D8PPmg",
  //   vgs: "U2FsdGVkX1+e5knrquShleZ1uxd38OAje86QZjcSyVXkRmZVXSqGPObP48PS+XsQDCdnD8xHGBxIE582H1sWig==",
  //   spl: "U2FsdGVkX1/ccbX5cyu5eWujLJpq9KiZvRUwvFL4v+gWkf4Y7y/7MZrYW5zTcHlf",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "209",
  //   gods: "U2FsdGVkX19WYmqTMiEjUfd/eS2StTgsBegON+ptdPo=",
  //   quote: "U2FsdGVkX1/i0o7M7LxTHGFjBZITjWu/O9EEMlc7h5UYPlXnT83sGFjd/0U7EZWn",
  //   abilitySounds:
  //     "U2FsdGVkX1+yZnWLA3F9wBKmviJ80ik5hX+l9epU/kfqQO+ZOb41qqCAiVxd9MFAEUWjQD+/WTPWZ2lQYuYjdXO8q9OYY1sKS8M7bHFq2wI=",
  //   abilityText:
  //     "U2FsdGVkX1/9bQhwV54NptztwTnaX1h3yEBlVm0QKPSM67L86D00If+2bFWsudBvT7yG+EiVvORTqhW4lUqBikBioHkFToUHlDoMkQxJ8Jo=",
  //   vgs: "U2FsdGVkX1+6DZNm5p0PkmWksueYGSw5jcK0mVKs69yN3BEBh6r5Izglj8q1tB6lnXw/SWzy4fP5sSP3mUxt4w==",
  //   spl: "U2FsdGVkX18+JvB8B6ivICrXkpHqox9ACVdeRNutiwMvr0aJjtQrVd/rN0mbApse",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "210",
  //   gods: "U2FsdGVkX1/5IDLwla8Q16N/m0c0nUzdNymA+lQFHDQ=",
  //   quote: "U2FsdGVkX19nhTyO+EvOszQ8TOrxVdix3OaBxIadIFAG/2gxPAU3MR/R2ZZWv2/T",
  //   abilitySounds:
  //     "U2FsdGVkX19WnRYmQhuzwjZzmvCAct3fhRJbmBFwj8dqfF8qCsVfcI5hUCI3caBdpTRKCyu+uEEWokIge5KIhG2mzn8ZOXAkDIzaXimOZmA=",
  //   abilityText:
  //     "U2FsdGVkX18T3r1KfUNDsKmtqRV6ytTcrl9p7IXaeUqo4TewXO5lYJXMR+PO94T1PsU/zT7YaMdHT1hwO2JYe9KkTfw81K5oujgh+aZzrxM=",
  //   vgs: "U2FsdGVkX1+JBwsfqNqmUn0JZyfmZQJHxtkPpmJW3xUcBg8Z0A8sGiM+3j0XK3jMiIXdgNV1z+8eQw8uysqb8g==",
  //   spl: "U2FsdGVkX19shojSFOHyA/OR3fOAuTuVQpf5XTX1gEDS5woWEkuVEWEYFgrBQbGt",
  //   feud: "U2FsdGVkX1/J4q8CgGsXJJEI+XsaGBg/GVwaCtEoM/s=",
  // },
  // // Sunday
  // {
  //   day: "211",
  //   gods: "U2FsdGVkX199Afc1Id99DpOq5HcdXWP5L8Bp69VXCsI=",
  //   quote: "U2FsdGVkX1+jBwi+cp2xzBQqJL/La6f28OVl1rpWRYIREyweHxRdjkZlmkW1EwD0",
  //   abilitySounds:
  //     "U2FsdGVkX19og+LBytRZnl8fuLvwu3ELi6zq8wCSEvcq3ZcaGO4Va4PyAEYydm6DbYQRGqVjQrpc5Fal/LUgNr+w8HM1L/GkEVtuZ5NLYfs=",
  //   abilityText:
  //     "U2FsdGVkX1/fDsMWoVLus5k0xKGW+bkA9KLTWj7VB8yVuL+coyA/qixNfFqc93UckDJhpWra9CJD5Y8xY/zfhngKaqxyFp10E8TjG+mujwg=",
  //   vgs: "U2FsdGVkX19kZtOTaO1Wd2Ig4sbKvze+sD+7jMdPdIi4sGgvDFareMf1WVocE23BurrPKoVmompUNkqPWF4Tyg==",
  //   spl: "U2FsdGVkX19YP9kHxY9B5Czh8k6OhbskKK/PGnXHDDyCXWchCgjfNtfMhYgJqiTl",
  //   feud: "U2FsdGVkX19HyFg5SVnWD7/TxRgZv00ddGoPXfzAWTI=",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "212",
  //   gods: "U2FsdGVkX18FBdqVvMaq3Wc0w/vhymP8xJBZ3/LLJl0=",
  //   quote: "U2FsdGVkX1/BBvTDQxcxTOBVb4ZOGT5gm6E0LHR4WZTNBiOmJpjROYc/fG0XkLEM",
  //   abilitySounds:
  //     "U2FsdGVkX1/UvEt9qvDXFS6Qz9jdh6717/7E+IVend3S56QsDp2m8lXt3Xsybkh38ftq/3ZCelbD77/7lz7f+K/6s1q/FqxKVY5g6CzRhwI=",
  //   abilityText:
  //     "U2FsdGVkX18IBw0XfgPtHAOuq7GSX6zTMkPPFYJS+aCJETfI4X+5VdyMkOxTelA5bbMa8qzJHybQrNRccTy4VYTWszVy2WNaX7JpTsluW2o=",
  //   vgs: "U2FsdGVkX19PndxqknBBFPpYuAYYG8WM4K0Fo6Tw3Dz9yrH9QhfFlyVam4XYEgaXuP0BhzjTMcNPPr4fLewEAg==",
  //   spl: "U2FsdGVkX1+tjAR4mXzIsIB1ZMj2XQ9Hqa3xjCCgMnutS//zXO84HeteHZTafmSo",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "213",
  //   gods: "U2FsdGVkX1/3zGQWi9Ag5jQPt/EmowzXNM1sNei7COM=",
  //   quote: "U2FsdGVkX19NySJf7bTzKvnmOTieKTWND6uXX8kCdqvGqq71Zzbd3OcMxA2B1y+T",
  //   abilitySounds:
  //     "U2FsdGVkX19JkQDlyfuL+u/d7mrznF0ndQgu74LDbFcuc/SPhgVp+bjsgfSfRXwtbyysJSs4ayBEHq8UD5R7Zptd87O+A/T8S9lmKhWaLAw=",
  //   abilityText:
  //     "U2FsdGVkX1/39sTG7dOHdh3G3jrkDawsMV5QsJggPA7F8wNh2oo7jClzA1dMyhH56KXWteqPt3IHW2fEBdMtoJmeATJr96NzFynBih3E3Ds=",
  //   vgs: "U2FsdGVkX19IkyjlEplqN3ug417HtC2PJbkAEpVbt+UytS5exRAXLxBj2dgdr7E/+4Q3s08M4e+yS5Bz92dpXQ==",
  //   spl: "U2FsdGVkX1++PA9+6rylHZvusuahuha67ZtK/sUdmfMKcaGda2tmAGJNt6XfGnfW",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "214",
  //   gods: "U2FsdGVkX1+hQnoMinaqXVB8YDURodh3+rfLEgaMpSQ=",
  //   quote: "U2FsdGVkX1+2E09mCsiO5MJfq7McQ4H4stc9c0RmIWAmwwO2HU3J5GLxMOMuaXnh",
  //   abilitySounds:
  //     "U2FsdGVkX1+IDz2o8+90lIQvG66/UDYqqgP8POP2KoAqkJxul2dAVNYPSjH1zz0z4Sr0sG2DObAGIyBBY/h4M7Vs0BCeXTIyTLMyYUzu+ik=",
  //   abilityText:
  //     "U2FsdGVkX18g96Kf57vHKrHT07MBeDYlTA6+KZTWvRK75UVknswI04lIJ9qatBZJsUPpwvLETp6ULV6TAfNMKSfRQxytYjW9kNOcDQzq8iE=",
  //   vgs: "U2FsdGVkX18BPFFBEeK0ZK//uozcB89cLTgepkZZYkl/n2yZ52a0pTRnnkiDkl+j",
  //   spl: "U2FsdGVkX1/JEuvNusxb01QKx7C3sBHHlmexWr2YI67CItuPzZWIGvIrCce0iD+7",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "215",
  //   gods: "U2FsdGVkX18FjtHGqwQ4O/Eb7n1MJAlwfB9QiKSq9rU=",
  //   quote: "U2FsdGVkX18wMio9pnBjT0Yjub92d6W3uwG73q3ZR9SkiuRMUVi3P7JLC+cWPEIc",
  //   abilitySounds:
  //     "U2FsdGVkX1+Rk4Fq82PlaXU8bLLk9RA7PkOLBBGuxEwCb10PmzRxOeAoJPw3VJVmTYXGxt2MX+JfPfcrngDY1aCKoszihN/3+tKgCj8ACCA=",
  //   abilityText:
  //     "U2FsdGVkX19TEXMiTyrVbP6/52i/WYJIQI6XgDLo0B0IexES1ciVUeWMkKUAFFf2a+d8cCHrzkv2FFMuSwdp5mJvc/xyZr8QWy+xxYwKtBY=",
  //   vgs: "U2FsdGVkX1+wueZQlawuLP5SrXAZdhGxlq7NHYHC/S1hS24xEChbL6V66Fsf8kOc2cfRhaJYpN5jqF9k4zifJw==",
  //   spl: "U2FsdGVkX1+A6MGx9pVy49BoSG64xPx1YnFFQ51UEKM=",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "216",
  //   gods: "U2FsdGVkX1+hSZH8jgGNdpnapm8isDpqqU4mVjcNUUQ=",
  //   quote: "U2FsdGVkX18MKcs6igyMX3Fp2/fzLxje9T6kQPmx3057kfxiHAJgLOXee778hQCf",
  //   abilitySounds:
  //     "U2FsdGVkX1+OCijTZwjvHqbCqzVQu9eV8/L/NYmbWsyynboj2aezVcT/jIYrz0ErXBai4xIkhjJ2tQoVndJLsZpEf9yNCeaJLI7j7TQ3WPs=",
  //   abilityText:
  //     "U2FsdGVkX1/sLMsHn5fTenzQewQV+d7QSlPSLpvt2M3buR8TO31pW78ek7e53yUa2qpaxVpoyTaAY8OnUVXImeW+EVGx1VN/FKeUCXRK//M=",
  //   vgs: "U2FsdGVkX1/biGD/i20yuIBi0bk6ekHqZoCxAB59U56kboQT3KVpRctrItPZqyktkGsWcZJBvb7JQVnI4Mn7zw==",
  //   spl: "U2FsdGVkX1+HoqflNR1hH/FcNYBg7DnuYuDT8h8awK0=",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "217",
  //   gods: "U2FsdGVkX1/DgDDfSjDlO05zzr4vISJ1TppsuEAFxFc=",
  //   quote: "U2FsdGVkX1/cYAQBxFIDJb7xlGpOqjCqoL3joqpWV+cqFLqeUGykYtQz/zG7S1ov",
  //   abilitySounds:
  //     "U2FsdGVkX19Cf+GXakD43Fkm6BbxNKIyUcSChg1A2PdMn9Hw8xY2rH8cBcoy3eIBs3AWyu3kcsENQRPvejNtZQa+AfVRANoi7CFcsdBYOoQ=",
  //   abilityText:
  //     "U2FsdGVkX18XpnvVy+hS+2S8BwqpdAPyVvCibmVqP9MrbxhDwA0aXmGA3m/N5lFlQryLWX8LduwqurjB/kc+/GVKi1I9PvZxcFYS3yqcQ7E=",
  //   vgs: "U2FsdGVkX1+g8rSwTdU9iG8j2NBJweh0ulV7uERsAXz88MblQrIDkeBbzHsxyKrsVNo4cqtlTn+9Ez3ctsWXxA==",
  //   spl: "U2FsdGVkX1+Qgl6mEXHeYKnw5B0jmgkkikXh1c/LcKx3hfIn2ADGmVqCN3ci7bmN",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "218",
  //   gods: "U2FsdGVkX19HsMTOeGv8GbGE5sM9IK+Z3W8rOS4/4d0=",
  //   quote: "U2FsdGVkX18CPfAU6CfX8ndYG/LZU45fy0kjtJzCxVV9gxzcyIHwpBuHfmIvLClz",
  //   abilitySounds:
  //     "U2FsdGVkX1/ZmkaK265doQQPtkFUDBWMo563wlQ2qxnwQE73pw9Ex7hTwS+ptEj5AQSZrhGKzT0AwikL0wjHGPLaHanC0C6q4+rNXec/wFmDV5aelfxP9IqPV24S9P7m",
  //   abilityText:
  //     "U2FsdGVkX181LABZtfBs3NvLtoVMFZFgNS7NYMWtobaVTPqvj8yv1qGEFJ2TlHuiKV0GwuwUdbQpxFPVEIdriWDXIdIxkl6CBr5VatFCjY8=",
  //   vgs: "U2FsdGVkX19OT8dPuA2FHSyDdJmlNnSQE1VdPFOttJIbzYU2NaHDIURvO75dyoNtYzIr8a8GFj7c5qt+nXBVdw==",
  //   spl: "U2FsdGVkX1/zIPIIQPOH5c+CeSxeJIOm9v+FT5MymfjkL2DlWyjsKmguLpqeVWV1",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "219",
  //   gods: "U2FsdGVkX1+d+Za0YVI9WWb3maZRabjCcl5ZhhHhrbc=",
  //   quote: "U2FsdGVkX18y8AuH4yWPp1zSwm/hJYly+8lJNxE5OK+AHJol09bQKtc4tJNG/i6L",
  //   abilitySounds:
  //     "U2FsdGVkX185JsN2El0LIIQaF3svfdSz9X7dZ9ECch8vWGWZhF9uwVCN+3gFAEVz5otKrBw1gnHNC3pHxRteZ/U0mU9NMe/zMuyzLiYRCvPegiEfRwakMDcyFW9Q7+Oi",
  //   abilityText:
  //     "U2FsdGVkX18SziJAHwAXl8+MOEBNSracQjK2ZhW7/9ytKtD43knR0X/+H/NF1JJJ0jZdbUGdKL1qO89nYN1oPw6nxFMwp0akcP6qVIJaD5MiwzL7jSIxhTpDj9gXX0vf",
  //   vgs: "U2FsdGVkX1+F5Rl26Js1ldDRxUTI8tGAmnvamrnFFWBimY3NsZRWv3zbiBpx8TqBP2c5NRbsvY7/8aIc4IFVVg==",
  //   spl: "U2FsdGVkX1+HdzDqCNi2MxqJaWf5NEFyBljlstmOEnt49+4G/c5/K8UWG2gMwT/X",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "220",
  //   gods: "U2FsdGVkX1/7dQlHOaTCqBMoDprOAfvqQZfGBNEH5jU=",
  //   quote: "U2FsdGVkX18cLO50xUCioMCvI4gbxVglecLmiHbM5xe33d33LRkhDfHF6IH/2s32",
  //   abilitySounds:
  //     "U2FsdGVkX197o6p5Ziv6ObrwPqmjr0bt4nQDb+Ff3+y/KGF2/uXOCiV+S0Cmw8A59agbCUiP07zoyArmy0XCxAhLv3IsNBpEUfRN+tAzHYU=",
  //   abilityText:
  //     "U2FsdGVkX180Q5+gMhq+UPUUdSMYi/b6FT3KuC7mMlKzslil9r5f8imUmHf01GWRKLwj0lzxtJToG5rb2OqWSBYW+HLtCw7Ij2mnPFmJygY=",
  //   vgs: "U2FsdGVkX18cD/orkkMt7FHT4OV0BMIp7tXDzi5KWEe85qRdXhAH4rSI+NoaobQeI5APqlpUdwHSpNnep9V+kw==",
  //   spl: "U2FsdGVkX1/zqdtNTYJdeSlbypc7IAESKWYDcJ1qN9QwrrfUavBmt37aGtJ/v7qo",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "221",
  //   gods: "U2FsdGVkX18l10j0SaFyHapDIeSLRLMN6OOO6Fpwnbo=",
  //   quote: "U2FsdGVkX1/McLr3OXjhT4hNSzFD8aN04aLUCXW4d7xtzhp4MhPlA3sbCLz90ifz",
  //   abilitySounds:
  //     "U2FsdGVkX1/cE5lltj2VEGOUuh/Fri91VPXWNF52nSNL1XOp/raxqeyx4XpnStma7uU4AnVV5QnGK8kQjto1DEDXtU6IT626uxYMYLvjJeI=",
  //   abilityText:
  //     "U2FsdGVkX19WBSUGKNuL+pdVtsrKLnCiwkaIHNaY5HQRO4xL0lW9VNAHZrm1wdnxNKxvJRrf8qwIjlmvfjkgw9PWvKsnxWdmBsNU9kuv0S4=",
  //   vgs: "U2FsdGVkX1+pQ59O5edrVjDw00ZzOCRtnRM2G5EuogFO1bFP4Eks/K5avbaZQQGMA1sXwlwVFNe8RKgduqgbZA==",
  //   spl: "U2FsdGVkX19l+d8VPf3FxWSXcswGAFYM2c7mMFRWSeoFkbuxHgd9SAJIg5de4ZVL",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "222",
  //   gods: "U2FsdGVkX19ZGyZxBt8waaUETxKOEGSdHYOwi+DERlU=",
  //   quote: "U2FsdGVkX1/6nZEjjBqARzl37yYilhUOg9uD1R8tbv8yr4NmFgSDS1DOsEWvOnQK",
  //   abilitySounds:
  //     "U2FsdGVkX19a2r/Et9eKPXYUeUyUnGlKtZYbxTiifDeb2HLPUNmNRbhCTrAQPLVffJ0dK243pHj+ylp24qQZ4YkQ3O7KgNe1G6BvO7bXo+Q=",
  //   abilityText:
  //     "U2FsdGVkX18od0T4iKUdOwK3B5IbXP3B38Zwx0LaTgDf2cjk8n5y+gTTnkHg+E3swQl+mhmf8g3INMydcII4GIDuDhRqkbzMAAzZHODe+94=",
  //   vgs: "U2FsdGVkX1/8DjnhJ7suKjXdgabxmZur1LQ/MtRVWjelwVPHVikNaMohKNJzNys5feDRv4rv8iJzoY2iMblpDA==",
  //   spl: "U2FsdGVkX1+jr3KqnTDF4prlNpisyKjGKlhHLNmeAy4S5Db33jTVB1/fW4c3HkPr",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "223",
  //   gods: "U2FsdGVkX1/r4UymD6zeEJHquulyKVRobdd7a1UFQZA=",
  //   quote: "U2FsdGVkX18NmKo3fbNdb38YQmCC++9anCk5buP5L5cm5FvrtwJ0GwcITFnDy5JN",
  //   abilitySounds:
  //     "U2FsdGVkX1/w4bScM9BIeGlfqXiBt3E++ByLhAzdHcEQ9u6z5+QfbfRIA0CKEribtXQAbCP2BPY0ti2d2sgOaRp4JT9zk7OlDbjdG8/vME8=",
  //   abilityText:
  //     "U2FsdGVkX18DuldLfVpC6LXJeUGm8HpVkv36T/9igDYVj5GA78o5Fd1ayFVXv60I85g6uCSLC6YEUGhCJcXvSpHvcfkAVToZEiSOW452bmk=",
  //   vgs: "U2FsdGVkX1/lu8bvDJ8TYO77oLxojS6iiJv9W02S/1a//NcYk09xzDIzaKnOczVlLkxzTvCReGOGKonFBy5PRg==",
  //   spl: "U2FsdGVkX18jp/1kc8oAHjrPsrzvwhBRnYwSfURXWEH46gC2OwNm6Y3GMCsz4yL4",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "224",
  //   gods: "U2FsdGVkX1/nEelbof9921bvRSp1yDQ+eBsjPYkyk8k=",
  //   quote: "U2FsdGVkX18DugA/3B4xB2ZWOwl/L9SD8mh8nL9t1WdVeqBH2l7DSXQNdXV3NNrD",
  //   abilitySounds:
  //     "U2FsdGVkX1/+cP/OqI2HFYzo2Gm5guMf4Bk73GtsmBWGGmJKmGs9WOPLjcRbh2SFDRAGsO6VQW9aKZlKJelVDeat1ko/nngFMkXWPG11pao=",
  //   abilityText:
  //     "U2FsdGVkX1+hQ34j/aL3xhBme93MyMNiFPkcQhdCgRvNdgpwtrY7O5aHv6t3rWmFXNoZNPmNEQg1xvAs8o5ffisHLPu/3acK29u8rUwqTeQ=",
  //   vgs: "U2FsdGVkX1++HFr3BRWonykm+Zs0BpWByVBKtuMCbW1Rz1E/0BLzyYHqiSbKK0URanhRYSNdfKPuCAq6fyZR2A==",
  //   spl: "U2FsdGVkX1/Spg7+itPX1HPkx9A8u56m0njd5SKCOJIoKGVfEICau5oRxeqKHb3h",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "225",
  //   gods: "U2FsdGVkX1/uIsUPi+K6jrNr2WNtaMHI6dficdxo7oY=",
  //   quote: "U2FsdGVkX1++0QWxEpEglJsv4zvq36y1r629D52KZN0eV4etyyuN2ceofFDOl8rv",
  //   abilitySounds:
  //     "U2FsdGVkX1+XanyVW7qUSyMhawtskLWHKdh8WQ4MsuSYRZOcFP8AnvYEWqriZXs3/Cn0RLYKVWKHOzE2aYGdmuhdU3CWrPILf2doZd1vmEE=",
  //   abilityText:
  //     "U2FsdGVkX1/0RVnvcdO9fMnKQ0BF//gPtsnCQvxb5qFBvfaUQLCwaKsQ+BO51waW2WHaxlEoIC4Pal+du/LJQBF5q2I8RAEwNl+3CYRBcmk=",
  //   vgs: "U2FsdGVkX1/0lPZgxXHref4tDkRjMBNApA3yZP5LI1hz2q0MwWnJfin/v6u4nqtpXPsqB5FpJpWJkTqNw3e+dw==",
  //   spl: "U2FsdGVkX1/E8K1TmuoQS23WD+LXhZp0tZiqpHPUjUc51k9aBV0g7eEy+pwqKiIY",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "226",
  //   gods: "U2FsdGVkX19W7AtPcil2yq0+4boIBGRNoJnh2K23tSg=",
  //   quote: "U2FsdGVkX18XwhEC/pBxhma4gMmZB5sdQwxK3kPgGo7bJYmbYkGnBw08HBMNZlfk",
  //   abilitySounds:
  //     "U2FsdGVkX18wH9rrVb8lliZCZuGyaH3yC8CJoHEnPWXLfnpaa8yeTXEWrL0hmG6DcFTGTHPwSMH7hU7KuDxiGvPDHBvyXIBgj82FWVpJNwM=",
  //   abilityText:
  //     "U2FsdGVkX1+63mOTN5Rf6XZQ60rDcNyKdTGirHmrv2KCCh+u//HcJ686BEwd3se9r6aOkO2BsuxZUZcXtbhx5f9bitGP6mCg3yGbYlrU15k=",
  //   vgs: "U2FsdGVkX1+VCy7ziwOGR/BV8wM5MHYg6XDqMr10b054ber1y1VLhvBM8OSyEUTfKSyC5q4qWZbt+h4/1k/0SA==",
  //   spl: "U2FsdGVkX1++6g3u1pleT1yqtRAWcBfcCW1+irX6WRzDiEkARqzfPbvucOoheGGX",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "227",
  //   gods: "U2FsdGVkX1/8C7H+d+47WFHuGrpMENXGdcolsHBTGMM=",
  //   quote: "U2FsdGVkX18c/e2n33shCMeauKETF5uvjTgwkLQxHlzk25Nf3+ZI/0CUhMBi6OnP",
  //   abilitySounds:
  //     "U2FsdGVkX1/Xe6709adkqvJYvhuyr9zSpsamH8k1LJSQMMBHZBbowftYTs6XfK5hcnE8kQwFwz8ZoF9nfF0MddniWLKK1WikOYSwiIdxt5A=",
  //   abilityText:
  //     "U2FsdGVkX18RFvVdhpt/sr3uftLfnQ6galeU3gN8S0IGpp+wib9kz2Ymb7RKXAdZEvVQifAUTH+ihZ4LdgztNOT8G6KC7MblSaTBLGvgBQM=",
  //   vgs: "U2FsdGVkX1+wklqh/w2uAk4nRcnIz0DfcsMNvvD95y2MLbGiW3Vgf/5ce3xcVLGFbaaUG+Xj7OVkA2EBrvY8+w==",
  //   spl: "U2FsdGVkX1/QhcBZcvRbAQSDylCpjE8A1id4tEhtJSOuJl868FVL/HRuP8mWDp7C",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "228",
  //   abilitySounds:
  //     "U2FsdGVkX1/x2qGLDLc/rssB8xwHbxemGX8Ml5dNyYb9nLfwLn5zPUYW5ANLcX93yp1SGbOFNcvgj1vX+j5sJNuPV8dWXCZwtUAgsJBjZgM=",
  //   abilityText:
  //     "U2FsdGVkX19KKkTpPn8Q4ToZP61nz7wdkCIyaBf/IWAYRfOet5lEC9t8P0iD7+8bGBqqOzMBWdM+dBGTs7l0YLLySRLSWiZMYWm74VURDpQ=",
  //   gods: "U2FsdGVkX1+y6nkurtxT7lZypPqJEcSXrFOzvqfPrq0=",
  //   quote: "U2FsdGVkX1+sP1Qn9eVesOTZXFXKs4u2+NmMZs5o5KnhaGqzRcBtyYCwZyRAeh2y",
  //   vgs: "U2FsdGVkX1934PM5oCNCmDAsZrkCIJ62nc7rKm5KcM8T7WJgwr+y2GHfWh15ssU3zE8EYoIXoVRDAnOnv9o4bg==",
  //   spl: "U2FsdGVkX1/cI7rMbcExFB6mik7i0vsQctKlqxzDpXfolz2mB5RTFish5QJsFIw1",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "229",
  //   abilitySounds:
  //     "U2FsdGVkX18rt9qanR38T8z3hTalVlCofZ5Zt3YH3ngzX5fCiCnZLvLAwps6knNVkCMi4Cpx3YlaA713QFP/CLsyqhHGPphzkgljTWQ2czo=",
  //   abilityText:
  //     "U2FsdGVkX183SRkynljysxZFoQ9us2nGzgVx3OaWCh3M8LZ5C1NzJNyFCxOSLaYqFK/tXC+VscNiXxH4r50xlEhvhsUzuI0yRzkoA49A52s=",
  //   gods: "U2FsdGVkX18eMKnYvUWdbkw/IsMOXYc/Gd3FSIEe9iQ=",
  //   quote: "U2FsdGVkX1/eWoX3Qj2QoLCp/KRYSshtC3k2NRyeE5Z7yXT1+/KosP1rsa4NmItK",
  //   vgs: "U2FsdGVkX18hmMJMoncU+nNYoxd8LIUhgdiV6ist01n6eMA62M0Z7WqU+gcN+7lAWThKf0Tu6Adkly2d7GVdRw==",
  //   spl: "U2FsdGVkX1/W+M87Xl7acsu1w0g3QWz6nyYUXMQfLtzRe3mTOYgVp5TfnXBKi1CJ",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "230",
  //   abilitySounds:
  //     "U2FsdGVkX1+4VtCxy38f5sAUxNK4QZ1LSReMsPxd5dB97KVBX6nEAZtrU8d2JNvF448FISct7KvQvWdOZz6s1JkhsMqmManezzqfWRIuGPI=",
  //   abilityText:
  //     "U2FsdGVkX1+Q4QA83Zn3+DfmDx2WvBuUYioyALf/laXaCuvoV4QY4wpPQGBibS2CQBc+4q5LoGMStauMwCGFXACfofvmkyzoq4M/Ndmdjd4=",
  //   gods: "U2FsdGVkX18ReiuxPXBPM00OuQOel1FKkrQQMHZ2J3k=",
  //   quote: "U2FsdGVkX180SEVd9mpiOD+HrdVAS34uJUn9sGgAHumfMUVXQ6OJCp/U5o5KSYDf",
  //   vgs: "U2FsdGVkX18WjyV32rH3arHBqaywVCyDJEi7Gu646MOHZwKNo0yxgI38i/Ve5omjzZ8CVDHL4mbgzfNMMAkwcQ==",
  //   spl: "U2FsdGVkX1+mYD7pGPrZTzFG+HCct4vLLq5oyE3wIbwaDQnZ0vpBTWAWogBzQs87",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "231",
  //   abilitySounds:
  //     "U2FsdGVkX184dcMugPzliLRQHcuLGsKCZnrpzn32NRdgvNdjKM4fk5qqOr+R5Yed+DRJ6NtaYoxy7vvsWWJ8EPF+EhZo8Teji2Jm6j2657E=",
  //   abilityText:
  //     "U2FsdGVkX1+2P2/ceSorcO/VlaadHYdudwj2DnlnJ7l8cI/IY/+Ot6mX552aBSTEEYgWfZFdyWvJEBJGmYpZyq9BNKrnVQXrtIbr1FFfqgIFgOYsQhC6FOCodhz7eMsz",
  //   gods: "U2FsdGVkX1/NO9sgeToS4zn7USZDnlMBiUSUQ2ZkYcE=",
  //   quote: "U2FsdGVkX187BSzSCJdFlB8XPaPJN/1ms4BUA2PYmF06QRx3gaHBzh/o7WD+uQ2q",
  //   vgs: "U2FsdGVkX18qgmZzf+QGZW+FxbhjQ2FGXP35HW6Dyzk/saYG3NdjHu3324z52TN/",
  //   spl: "U2FsdGVkX19Zv1mIhsx6vlOWa8qMfMbMgMIPwowoPldAV8kmclh0ivMH6CWT3n9p",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "232",
  //   abilitySounds:
  //     "U2FsdGVkX18mcjYmJFzbAgoBoUxQpitbl4LCDLqMu0LI/pNlLvFfFv+cBsMC4BzINb+GUEZrIvbGSTQYcaN2ETtBAjLZSXremuju81CXxEY=",
  //   abilityText:
  //     "U2FsdGVkX18QWsNGywo3B3dphA1sWPbfEuarSLag/1dYRYEU5iZfXX2HpVL43RNmd/ZqQBu7BDmvLCLk7KFtevbG8NdnJEFG9AggU4FcEuM=",
  //   gods: "U2FsdGVkX19H4Nys2nFARuFLUYwohlM9GaL+CxPmwyg=",
  //   quote: "U2FsdGVkX1/GgqzI0BAtLC8RhSVwcqWso7M4BUt9bS4erJEtXTf9xm49WF0oLzyn",
  //   vgs: "U2FsdGVkX1+35GAgC7VFVNpJT5ae/tEYY3J7AlKXFpmY5o55mczDp9ppM5AMW66Y",
  //   spl: "U2FsdGVkX1+79kdnksDXXl77JRN+Bwf1fmSSPfGAqpusMRuJpLBYsy7TWjGgZRQw",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "233",
  //   abilitySounds:
  //     "U2FsdGVkX1+96eVfrvceB6nfzYHFFfhU8D3LorPkpREDLpJeb07kHSckL+pd6wRIXXvbuDV84sV3TxGFBGH35yx2JWqlRYpjxr539ir4wQM=",
  //   abilityText:
  //     "U2FsdGVkX1/UYdKC1DZB5tjqJmu0nq15EziKWZ0DxIn00H08HhhAGE51fQtOJ1JFbQkTTwR3f+BB4aGlb2Pq60j6FExtSU2yuzwqWH3vt1k=",
  //   gods: "U2FsdGVkX19ZFb4eKnnsC76w7KEs+TZbiQGkqDPwF6A=",
  //   quote: "U2FsdGVkX19sPC0AZ7WN0zQTyEdgGzd9BNp45B0uBRVgxaOMnua9xpIIuJsMDjos",
  //   vgs: "U2FsdGVkX19eNChiFdIyF/dLv4k68lZxwY6RxSGXzBZ167gwmnz8uTk8NagxntpRbV3HObLghwrPDrHkT+LLbg==",
  //   spl: "U2FsdGVkX1/vwtcyL3jToXLXywqvYAWTeIGXMmtJHkKtbn61Jtafbvhs8VBpoBJ2",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "234",
  //   abilitySounds:
  //     "U2FsdGVkX19rqofW0j9HMMCx4CJ7/QNeOKEjeu9PAvOvhVHTondilD07Ger++mf8FyMjusCnD5b1aESDHjSWEQN8YqLJRtZVkzUBtG2jhUc=",
  //   abilityText:
  //     "U2FsdGVkX1+QkwAsyfdU0APaQEFfsLCZ7YL7l0HUSlCtIV/aLS6fQzrES2wEW8bbYoIyhuNaONXAlxfg0+jGq6iAQxTWReXJ5Fh5gMfvApp/rbwPMGLFTbBxEXrrTf04",
  //   gods: "U2FsdGVkX19d6NIuqP/0cmEdsAbc3Oq+yLU2N74U/Q0=",
  //   quote: "U2FsdGVkX1+walTZIGsbKu3zcueJ5DbGGtDqrKF38w9wbeQN+/p9G6ZDrYD2EBYU",
  //   vgs: "U2FsdGVkX18h5rQx7ggKd+fMWdUowaU9yA1PmGxgiNuhDkncmsvVI44xcNh72WWe9vYDETbdyAlGsmXfx4bqzw==",
  //   spl: "U2FsdGVkX1+fbY/LfKbYiNXfz7l6SE2rHQ7/sfNsklh/fH18Zqe3O8FIcTigxogN",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "235",
  //   abilitySounds:
  //     "U2FsdGVkX1+IOs9mRpKTYbsOzyDAeV9e+i0O0xPPhFIV2AT5Llthj3e68hshlWSVKsnlGXixBOBnY1rOG6qAWFUCL5I1JWCWcP6J5+DGyKE=",
  //   abilityText:
  //     "U2FsdGVkX1+eGBJukCW+0k28HhJg4QKweB/bFtWDykT8T7NzRYxXug6OzQbqUQk/8WJbozG+z0MrsFg/1mZp9AOJDI+ClkekdpUS28XiZTw=",
  //   gods: "U2FsdGVkX184y2mvfo0tMr6tS7Iulv3Bve+DXqdRGLc=",
  //   quote: "U2FsdGVkX19b6YlqPlhzGxqS2Rfs/2Lm+/o7KNaaj7nVQDQoHzOLQ3yFmpd+Ls9P",
  //   vgs: "U2FsdGVkX19dNHZZ8n/0pHhRzW45wp3JBEGPqfHmHIG0shS6AQz2AxFpbk4N0Gvv",
  //   spl: "U2FsdGVkX1/ENw9cNDRyhoJcNQBLd5vW9YcuFsqxDPlczDIxjKt/SmyRsGp1E1Th",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "236",
  //   abilitySounds:
  //     "U2FsdGVkX18sVS1HgLDBKmSuMJ8t+0v1L6YO8FDmttLf6GFN5PK7ZLZSDiMkSUGYBp0uBsaoPPTepv4L2IL4jbvlPhcSeAaNOqFcg6uS5iI=",
  //   abilityText:
  //     "U2FsdGVkX18Ha7Mouau41Wt8+v11Uk28bfyl5WE+uQD7KEEQzyNBMJSGHxuMGhudBMTpFxB/t+pt36vnVqSFlLRSRYsdV5vMPjZ78vyHEOA=",
  //   gods: "U2FsdGVkX1+Z5FmK7Ck4xWMPsa5otyDN7io8iW9hldU=",
  //   quote: "U2FsdGVkX18GcVYwLvCnZDJKvu2yNdCTUG2Le1x4Wlf4Be/meLz7ad9l02QpSKj/",
  //   vgs: "U2FsdGVkX1+6CNS5t9gcYsG/xiZv9v1CxAyZUyR63OkQmW7Ma+74aAqGo3q15vGFzIpwhMPHGyPEc7pgNDCvow==",
  //   spl: "U2FsdGVkX19x3O3Nko41KnckonOHhWhmcBXMLYXQXvh9lvEt5yafHgqIDfHNoyFN",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "237",
  //   abilitySounds:
  //     "U2FsdGVkX18SQKE2nLkmkNcYtmZzATDdlFut3GPgLFaWRW2ktaQ2GZMsUM2Q3sYcgOHFrWPLwfxPvkbCz5Zq7U9xxW9oLsC0pSGXLghgJxs=",
  //   abilityText:
  //     "U2FsdGVkX1/RDOyjJrW0ohehg4xdt7JUwsHC0G2yWJLkXD+dCr2pWoXhiogb4LcEr2sT90raytCTwuZkwQtqw577tZipatCB2KUlQe/axnY=",
  //   gods: "U2FsdGVkX186DLvxQROHu+tnj7b8pigWAWzDUwhpk14=",
  //   quote: "U2FsdGVkX1+WC9zwNeKmTk2GMsSSizeCfcBKv6K4nxGME/OFzBfiGkVLtXcy7VZA",
  //   vgs: "U2FsdGVkX1/Tp/VfmkuV5q18NWgTbMuF9ETvIFMyAziMAZ58ZEPDiKNa/6Cn0AM4c6wh6/CpAWoIBVyVY2Da1A==",
  //   spl: "U2FsdGVkX1/4DxbuJReCXz0almXU1Kjwj3L3rGwxX+Zh+1L7e2zanqWEn2BRRHoA",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "238",
  //   abilitySounds:
  //     "U2FsdGVkX18lTpoLdRhnZsJRP/wOBQ4I9zRkuZ6eht/6he7N36GIRfbogKUr9oJQY2RGlk5B5g4OtHy4DusYnJc3zonz4VOk/5+en8HUXA4=",
  //   abilityText:
  //     "U2FsdGVkX1+BQBHRTey8yXGXybm/s5Z153ZfztWI/Jf21ENnrLzHqbQ4R/nQw/jKNBnB3H57JJIvCVNIQvAw1/kwPdIckxd7kKmAkcJ6bKYaZSIROd0JKoNWBqN6UbwQ",
  //   gods: "U2FsdGVkX1+y9f7gWecyWEPiIhe8S6ueFSiTqmq5md8=",
  //   quote: "U2FsdGVkX1/4nNhylBRa0GBe8rAaZcxQBw4UGrO2mRyNBeL5fiWWCn8maHxBy1Np",
  //   vgs: "U2FsdGVkX18wWW8FE0egfRX80LWZuMvkECEDaiwoFqTNS1FJ2HZaLFRu95CsPvH64DM6i12ZXWe+iD/bj4Y1RQ==",
  //   spl: "U2FsdGVkX1/4zSFCPHuo1+GkrKSENMIDY1ZSo2UIcBIskIva5GxpeLIGac+cHEls",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "239",
  //   abilitySounds:
  //     "U2FsdGVkX18gLt273dBvJErmf8Xg0MN+xXMWDDmI8CiQbR0vbzPwm/fVcGurrzfFgWd1LtWvicfS8+52jII3RcwoA6MeM1r8iOKDzqhg2cE=",
  //   abilityText:
  //     "U2FsdGVkX1++gjSbmOT3rVCEXf4vZvsRtOSv2zOb1N75XtqMeML3sEuo4xO9F568VeyDwDi9fQ8XqIJIvwFlRYccnVYv6BQDwpLDj2WUcaA=",
  //   gods: "U2FsdGVkX1+SvhfEOZRsoA+vnVNONDyrSrtWq+568K4=",
  //   quote: "U2FsdGVkX1+66jFfDq2b/lgEKffnwptVVLa8X5c5haTnMOuRrnM8zjluyEc726qv",
  //   vgs: "U2FsdGVkX18cb0zUQGalTDA+xie0fXjItR7B2icceFyj6HBsJhSaE8EBSva5JNrm+Mdyvu3NNIfbUGKVBn5xrw==",
  //   spl: "U2FsdGVkX1+3iSkFm9BU68hcNT01GWdt2K/lyK5D2sxULuJedomCjw0ELBq0AExr",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "240",
  //   abilitySounds:
  //     "U2FsdGVkX1/Pn1m2rt+Y4YpxNPwgglAOUl4sJ8Fn0fRVxeE8427DpBZMQ4iLeavUOA/f816v2I84VZchFrtUG7Nis1UKFeTO17qpEk7opzM=",
  //   abilityText:
  //     "U2FsdGVkX19zJ8eT4YfPMxVYl0KuFoeCVtDcxIG7Tbopc4d0DnAcHdTTStXBDKeDMA9KLuzOzPBPLPJR6fJBVuk2zVjv1vaQ/mtR81xCYf8=",
  //   gods: "U2FsdGVkX19ZbWQp3D73XYHy+RUR+LxPOQ/dWaAK/o8=",
  //   quote: "U2FsdGVkX18EJENFqxtF1+pRuYJK9wqsOFYJPV/TWp8sGXzxMhOwJpQw1lN/xPj2",
  //   vgs: "U2FsdGVkX18HXl5QjJEYUOt5IHztwhtCZSGNKQBO72DnK8sXWmcUAvfWv5eRCyRTYkKupqNlBqKtQFwEDkklGQ==",
  //   spl: "U2FsdGVkX1+3xgcvpMd5LUbhOnEho97wr0gtHQDL00WDduIjlE6zqLi0UCluox0h",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "241",
  //   abilitySounds:
  //     "U2FsdGVkX1/JgXnvhld+inL4PHcJERusVL9Cwd6Tj4Namg7noAwvK70NuAyjMRABnSeNlDmeRU+xA2SX088p1eWz2/w6HDE7Yn1QQgj2ylY=",
  //   abilityText:
  //     "U2FsdGVkX19wIhzYCDWeOJx4fg9es+WhwlRZBi6a9BOxiRXrGGLAAWCN+iQQ/XHQs/vkQW2LmHa6rJs+WKA1vIDwU8WGeP0pqBtu9zxHt7Y=",
  //   gods: "U2FsdGVkX1+xLgVzUDkxQfETMkk8f71MMrmM6FZX/po=",
  //   quote: "U2FsdGVkX19nIFfwSh45PKKqw/teCZ1nl2PkmVMbnJIohtO7hfwYPwSsV1sikadg",
  //   vgs: "U2FsdGVkX188BcUYkdOffbVfvlhlrVqSTpqhelREEiqdZBzKiLglxQI6mlYh7ESl1JL07AWr9ZPekCTtmKI8nQ==",
  //   spl: "U2FsdGVkX18smNOw2sdFg2GD28MesHRB7ghCknsLt3NeB3SViETqJWfL9tP979qu",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "242",
  //   abilitySounds:
  //     "U2FsdGVkX1/t9ANUu0TUYG84vRlTsPPFylnnYIkU78TI53WPH1sMZueKpwjMEl7w5NkE34e72Gg+R6qDPXbqaSiP8KAFKTIT8ZIqxRMdtHo=",
  //   abilityText:
  //     "U2FsdGVkX1/hBgYW12KeGuSWB+GmL5k6QeNCDO0byGainL7bHVHCkrQ7w0DMYx6rm+yMZBX85tBkIkthXFGPcWpkJH3OmXEXvlxmDT4azaA=",
  //   gods: "U2FsdGVkX18cwCFch4HA11ACvL6+Mk6UDsXh/Nu6Nu4=",
  //   quote: "U2FsdGVkX18RM/rzoaL4Y/gN3/wniAX8D4ALXGUe4tHBpsTKmKYW3JScAIhDkxZH",
  //   vgs: "U2FsdGVkX18qyvl/Q72KXYLpDCKrj0+iZnGtpDqJ/6P7YrzdzyPGv1/c0HcNg0msFNzlHSv0nkxtvJ7n0Na/EA==",
  //   spl: "U2FsdGVkX1+883xOqRVKp6kfiRc7DqcDlbT9uYHuSneMbc6pn2C6fRIN09fnqir1",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "243",
  //   abilitySounds:
  //     "U2FsdGVkX1+QAvUbI7ADO337VuY7o3sUkK9Q3XuUYr9HOfAH4yIcQdWf0kssfb/qJEjMBzMar3gvyCQoD6iZ1Ds4sU2Vfxs+LQOXYPHc7hY=",
  //   abilityText:
  //     "U2FsdGVkX19OfrqyZqJlG3O9HutCR3+GxEAFeGUpAZdTZ+lTKMZH3nWDezsTUoB/Qwr7awHH1AgdaTq7aLCGYi8ivTeOlYvba5+OjthSWgM=",
  //   gods: "U2FsdGVkX19CChkloXzeCG/4Bj68QIdnfAgHOY/iGm4=",
  //   quote: "U2FsdGVkX191VeosyAtEqwMdn1I7um/oicVl7xvFTc/IsFDdelA0Fhiw3Kuj+gOA",
  //   vgs: "U2FsdGVkX18hircG+GHV4ZlVIHK6cxPBHOIdY4uNY1/t+z7mZbrfexr91P6KRozlN5xRLRhnQVBzOjLVTQs6Qw==",
  //   spl: "U2FsdGVkX1+4YgtIQaPyOTFWFLnV/tXSzoI09BO1ug0PfILdrjLjMV3G99DDjmn9",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "244",
  //   abilitySounds:
  //     "U2FsdGVkX1/8StJZL4UVgOLVN012hQAVyogNQ8gHEp4k6WFEZsSRUtmOR02qVpmD1+o9TERmCKDfNQ8ExgoF1RajFBvCOvO2lGq7vJVnWH0=",
  //   abilityText:
  //     "U2FsdGVkX18U24DcHAK93HWGk2toDypJtqHcqChr1mqfr3lu9ifkdyrOAihK3BI/iBhfPkjOIg458a10We+BCToAcEBrPJWGqx4D0MX2KXw=",
  //   gods: "U2FsdGVkX19hP0gwQ8mTsr/BHdHf8G6TLoEs2VwCVtk=",
  //   quote: "U2FsdGVkX19FBCyh8obcrKAgDOxTulFlAxnxDjy5BCB7jbrprMJjckixTq/3P3oF",
  //   vgs: "U2FsdGVkX19OiuA8vMfxvkQHx5gZEbsIEIHt6PUIO45ywc0N4fOCzIOpICGihH1Lg9rLvTVpz3CBeZQSHRYZTA==",
  //   spl: "U2FsdGVkX180oUIT7iMWeDLEIZtl0DSPmYWHCfG+0mAD4ePOzJnTJJKz4fvV8msJ",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "245",
  //   abilitySounds:
  //     "U2FsdGVkX1+6NQCtw7DPo87h3Fhrp2WoPARJTQM/TOZwzbV1pmCuGoIoul3Ew6fv4rvHw4TXiHvqzbbwYA/Bbe5z5RQPCt7lJWb8yKhlnyKs+L3aZrgaQYNYNLkas6H/",
  //   abilityText:
  //     "U2FsdGVkX19U/G+wTurX6cUP8sPiqvBoZzHET5alR5lE+aCaIZhmbFOPJ8yse731xgfVovV0CQIy1z4dTgDvmn/sRRPLo1sHAiL3B7DHXro=",
  //   gods: "U2FsdGVkX18CytAUFaitEcpuuWAfmEui3j4AbYKNJL4=",
  //   quote: "U2FsdGVkX18ZhIzliJUOAfsOjsrVS1lG2jveMZYw3pyX607/dwRmOVS0WUY/sB6C",
  //   vgs: "U2FsdGVkX18F+AgJqk31tMKQJtPCTEAjLXFjsmYd011BOu98TQAUUsFaSBgyhHIyNZCVSYd8lunDonni5C/Zkw==",
  //   spl: "U2FsdGVkX1+ChH4uW7Ff2eVq7/oLRAPgYhS0q6JMtJyXtzgKSmjngXB7PKbXRLI7",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "246",
  //   abilitySounds:
  //     "U2FsdGVkX18RzJ0uQ03YBAdkHm5VYQDyAY7/2QllIxJ6bb0yWoCSwj3L2e9IPCCclfT6lFYlWTPZtci0G46ArEHD9EcAt+V/I4eT+fOJ13E=",
  //   abilityText:
  //     "U2FsdGVkX19YAQFzQMEKtA0lEVbzecxhX16htsyJ1ieFNR0ppDBZylKLumpPJw0F3bplarBBBN2q78kz/rrCE+WQFfecs9F2yT/RJdmWp2k=",
  //   gods: "U2FsdGVkX1/QmQ/EnIIENQHnzh2fhv4uYixAHk3TJfY=",
  //   quote: "U2FsdGVkX18fBzfpZgd5N33PaNdmx3LKz4GPHAKncPXxYmGd86JW3SAYozrybgcv",
  //   vgs: "U2FsdGVkX1/RN4UeOen4w1KBY/z7HWZXisRm+4jzmkM6yyJj8UmR1dFpYqWMhOUgR93I4FcC7KxhTwH+564WIQ==",
  //   spl: "U2FsdGVkX19M+fz1Wuh/1IHXBg+jaNc/pKosVDunKtE=",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "247",
  //   abilitySounds:
  //     "U2FsdGVkX1/jyrk8VTEzqtf9G1Zq9bYGXO1eApHKi0/zyazSDq+NHSMktVgFdeCv/liR/e3aKkgiF0ZSdHeSnNPwSz3uHZ14Mztjnw9lG6k=",
  //   abilityText:
  //     "U2FsdGVkX19J/t2BX0vzOHdI2Q1znF0WrUDHVrdDRgRQnQlSmcdpxZ1MIRgWBINMcODkCowzjUcoie36kIl/B17oWXeAkffYKkHQcUN4iXk=",
  //   gods: "U2FsdGVkX1/oY5273udhBDLFoxrcbmWTKjKSPCMDAwk=",
  //   quote: "U2FsdGVkX1/P3SLGliOrynKjCEbTfiepIN8DeXrIzzTCR+TjsU3Lo4MkMkon/pkN",
  //   vgs: "U2FsdGVkX1+OY2k52dL/+TWh44tAp2wIuTUnXnxeY2nUgQJOjTxKcXMP08Lz1rwy3T6S3/39sZwCh06zWS8xkg==",
  //   spl: "U2FsdGVkX1+ehJvLbXJ34PQRYde9W6z8+C+43NZxzf4=",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "248",
  //   abilitySounds:
  //     "U2FsdGVkX1/YyIUcMSHmdpYRQF64oP5KwJPSPcBAtfNUrn679lizLyOHiemDSR0i3HUKbJc+QOG0g8lW5iLbbg5sHVgnxvP1vWkB803bkok=",
  //   abilityText:
  //     "U2FsdGVkX19QplzQKugv0x4AibhDjtdnXzkiw0vHPM+X4J5KferjedYE0g6euQL8pnWO6SLgCadBaPn8AwvETMR3G/JVItd0r+TiYDMTizM=",
  //   gods: "U2FsdGVkX19g2HD559weRFa7LvM1Y9fLilwW4J3pDxA=",
  //   quote: "U2FsdGVkX19kPlcoShPWalVxHvAdXZRBthli6VAB+iGUzyPxt7hZU2NIfbBeAzvT",
  //   vgs: "U2FsdGVkX18w9STtZEvEE+OinnUhsEFgYGcDJ2YucePmAPYbk2yLALUycM+JoOWAA+Q68mXl1PGo5bTs4Jqx6A==",
  //   spl: "U2FsdGVkX1/E3UU4ZjGiN9VC6aD2P3q0NvteoZeedAi0C+2U51PkAZgJdrf48jFP",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "249",
  //   abilitySounds:
  //     "U2FsdGVkX1/lMBs+QSA7viwgQkxwPWs6z0Z9cTZVR03K4AktG5147veso98gnGftipVjpGZInfb7QxflMLPKvpOgGm1MhJqri7WZcKWrF5g=",
  //   abilityText:
  //     "U2FsdGVkX1/U71isq+cZzyjeypFBf8CPJVuRlST1YUZLW/OFgmxXyKgCFcxShf8St8cMklGWzBpEtQEiJ6YpwsqgAG6WtIG0G7qmi0esD0o=",
  //   gods: "U2FsdGVkX1+GV/6bI+g+a1yii6AJPQUz7B3BrGF2m9k=",
  //   quote: "U2FsdGVkX1/bcoD2ajIXzX4szKlQ/q00NG4Mg5jr3RihA+b3kbsek0DU0QToauu5",
  //   vgs: "U2FsdGVkX1+zvn3Fz0RvO5PflAQAB72eLX+cpCn3WYsUhs4ffIHVnkmeWAttDX1g6QFpGB73xnf4n1uKilU7Yw==",
  //   spl: "U2FsdGVkX1/eRr0l4KUQzkwlK6pii2Kpo/6LvI6j5VjEPCVSymYDqLtcDVViAaxz",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "250",
  //   abilitySounds:
  //     "U2FsdGVkX1/+Q3KA+zTeFjKkAbCfFSFS82bDsCM4zKKZVgecmM8lcd1jDoWUi3gq1ZHC1YzDmNnihCsgb6YJcg3kK08WLO8UypgqYcBGZVja0lFgoMHhn6oJg7scyO64",
  //   abilityText:
  //     "U2FsdGVkX1/BESA03bDUj9eeUg1U5AJ461G4ap/zcwBrfrNNMIKN+yHGGu/ibqHJTEg+2rxNAI2Fy5xHpQBTE57zldqY6tykA9XZO0iaLufWiHrMADvWNJVSb+CDbO+Z",
  //   gods: "U2FsdGVkX18o6y85PiJ6LVZH3t7KbdEzBjBbOfeqRgc=",
  //   quote: "U2FsdGVkX18Pf4VCuUzRhfYZ3fwC16drKTho8jlOLADJMN4VqH+dk1FFYX8K4kUw",
  //   vgs: "U2FsdGVkX19uxPxhFBaEn+xbimqjre5n93hQaa8qksGFW3uTR1LEytTOJYjDTQtz+4TrcofVR/ZpCxpTeXmkDA==",
  //   spl: "U2FsdGVkX194Qa/v1lIa6qBgylB8MGJ9LRbn8asAfZDGXeJpFgakdKTv1kyo2gZb",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "251",
  //   abilitySounds:
  //     "U2FsdGVkX1+oVT3NUXC4/1LofVDfeIFZabnqJvbZPQnmvXb1CR7rcha9rchUBvuWa7ZZOibW5GmxGvZO0ZZwK5QlsOdFMpwcmN7K1pGoUHI=",
  //   abilityText:
  //     "U2FsdGVkX18WiV4WveqZ7IkEINFqEVPIxm9xqgbwpSQKUkW8JqQWuQA9DCqnLasRozmA/val8Jw+7DboVJK2uVt+hv4P+8qYZbsE2thNnRS+AxELiaSPAqZyyYZ8bkcg",
  //   gods: "U2FsdGVkX18HG4KJfmGwPtNhVBlSm7NkTOCbwqDXBU4=",
  //   quote: "U2FsdGVkX1+lQsB4VseoML4guHQKEE4lvuhNl4HP2Xft2zKAGx8/0xrwTKhthhSU",
  //   vgs: "U2FsdGVkX1/v4oEsFWkLKA1rJWgg4pPFHNn7pc1moBG3/SYjl/ABJwFlIyT7PI/0",
  //   spl: "U2FsdGVkX1/KrR01dhH4QL73VjrHky2P9fVQwETK+oN5CV3lXsi+7fpS0multSS3",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "252",
  //   abilitySounds:
  //     "U2FsdGVkX1/gyCyxeClyHaoGsuqtJ7H77Hq+jpyeqmvF9tEF+zMVltqtVnzU1E1H/HSsZUK3NImf/0n+61LMeWxz29qwK4Y2pctuFzO6QxA=",
  //   abilityText:
  //     "U2FsdGVkX1+ylauQpfj3sJ0KYNMtLjmNGzNucxlUmqcc6vJXt+DtXXtNmPZyve1ZwKreksrs/FdHIK62k2o3HnmNv7gCFZCfdaHtVAW/KsU=",
  //   gods: "U2FsdGVkX19OvJouQe8vUF4ueLdhzfq1yNXdi/2c8J0=",
  //   quote: "U2FsdGVkX1+S/ZhMWfUGSJmVP6C0A/BNjuCB3WlzN7RHDkSZqofftooJ4Jm0taVy",
  //   vgs: "U2FsdGVkX1/MdAOieruP3wF2KrncqCbf6AKqH9PIua23JikI/KlMTsr8DT8DQbFi",
  //   spl: "U2FsdGVkX1+jgW1zmBV/ZAtnzLweSxu9Q+TGxrV10NKl9ct3bVzn6mVogFQcFNaY",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "253",
  //   abilitySounds:
  //     "U2FsdGVkX183ZbBR1fjZNTshmwRwYiWnpa6T+QOgqYWDZglDU06OUzfPEpPdJY22vEYOS0RkFjqs6+z1ONCgo468zbRaPgPiGxE94o0Fwjk=",
  //   abilityText:
  //     "U2FsdGVkX1/WXumjDByM9WDmsGbZKJHUGkELgh9AlB+nlYiR6o7EIOMLT5oP9IV6afIVUYYz2Xn+uhyLtBVuPeZUIeeVJ7+zoMZumta9Qu0=",
  //   gods: "U2FsdGVkX18IrmkrEwInfLjbHDb8vOzBl/OAeScAgJQ=",
  //   quote: "U2FsdGVkX19vDBU4MAf+iULnY6yGdGg/J8WxqOfWIHbs707KIvTMFCf+Sw8kmjG3",
  //   vgs: "U2FsdGVkX18J2lVOX/LS1kmyRVUCg9cvb3nNSzK4fM2iRUPLmUFtS17dqHR4Tk5vnqI+/l77ljPheP8ogNlctw==",
  //   spl: "U2FsdGVkX18e270/Z0v17AYdh4mRqrBsZTL6ifkuNKNlcbYkadEEuAMUcx75zjyB",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "254",
  //   abilitySounds:
  //     "U2FsdGVkX1/jGlYy+hSV2igjs7aaUiAEv56AqrjbOvPSRq4TksvVrGL8sOXiyJX0LhVUd0AUxUK0TaVpi3WYhlVU1XiVPvvHeE6GGhI2Pt8=",
  //   abilityText:
  //     "U2FsdGVkX1/6cC2dftNDxE0L0NFmvq+TpSfQ9sAqqvvofxLzq8JwGNbaU8AJWYlU5M4xm3moWWdb5qgn74l65yg+yJ28Gu/0XcecyDQmDpnI6p8RoxqC7YVZQ3zezqMg",
  //   gods: "U2FsdGVkX1+ZgZCRxEeQM2FqkoSJI7eAAg2hCvJtY/A=",
  //   quote: "U2FsdGVkX1/ANeAnd/9yI8EClkZtcJ5PmFOuLgbkDNi1dFlcXnehk60aKRCg+M9q",
  //   vgs: "U2FsdGVkX19tISerfm5j6scPclp5Z9c2Qm+HX/wFyOuj6cw2P78ItA0exV51njZv59UqFvhxkYAAJpgTy46KPQ==",
  //   spl: "U2FsdGVkX1++WGNqUvY21IesGgC7RJGFFDSnTbBvCkMt+Cr4m+OSi2g5L9705dXU",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "255",
  //   abilitySounds:
  //     "U2FsdGVkX1/YxkUbhiVah9FFd7tl3qt+gAa27QL5Dep6EdbexqV/ZyeI60rSNO6XibA1RLBxsZFD5N0jAr6uhC25AKyD7K5wFMLlrOWlt30=",
  //   abilityText:
  //     "U2FsdGVkX19Y8xvqT2dD4YvCkgDJKI6ZTrmMyKArzsncs+lUw8KOCzUjKpxpA1dhTtCdfyu29dxMWG8m8wC9i3lcua5A3IZPeyMsDs6MwK8=",
  //   gods: "U2FsdGVkX1+zW86qiF0rf87M+ecZJur9n+aL4dt/eZc=",
  //   quote: "U2FsdGVkX1/mQrHa0lfOvLZcFiYknaEcXdPG1DkFX7qKLtUi1d2Sd2TYxX8gGBEa",
  //   vgs: "U2FsdGVkX1/vlK/eCReIfQ8/jRhcv56fgg1cK+rIe2sqxAmP3pYIk/iUWCBX2OzaVCaafXd0cI8GTcQUloV8tA==",
  //   spl: "U2FsdGVkX1/USmJtw7n3wLxRytQOpgXyCVvuzWeTH6gsOarLWkYzOspaZPX+mWKK",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "256",
  //   abilitySounds:
  //     "U2FsdGVkX1+6V/N/ew9NV2oIVMFbmOcJc99nwI4OJlFds1XGgXS4VWWmwwZzD3302FiEjAtOAo4Gw7nIishTUYedTIU/Blz4bkv2gN5LU9g=",
  //   abilityText:
  //     "U2FsdGVkX19bZwCmpZTANvQ5dJKhVRbzycgFUgxRJt1/FvRn+K+XtxbA7Pznmgzu5RPm8BGF7AOlSbaQgFQqZhDysUFv2orSyf52uc3xse4=",
  //   gods: "U2FsdGVkX18TDAYMuA0UQn1d3o8d1zqGsQT7xBHYqZQ=",
  //   quote: "U2FsdGVkX1+u9Izpu2vnvk9vkYw0GmEm2cFp8yHgs5wUB5TY9eAegJvcBVtV9NiK",
  //   vgs: "U2FsdGVkX19Vpx3m3z/UzGZLXW+8Dvcv4eaXYyK7lIJXHFaqdyjUsUtff36kCZeUy43MJ8ABB3GXWCVtAGUVvw==",
  //   spl: "U2FsdGVkX1/AKUPFimodPUvpvjrTOxWX2zfjBrbeDrHFW1qyrgXNSR5tX6xntQls",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "257",
  //   abilitySounds:
  //     "U2FsdGVkX1+Dxbvk7YZMmUGf7rkMflw10HgV5okDuO8+vi8/06GbR9kKgrCCbL+m0VXHqbk7dCmPicjLcVHXyXC4LpgzfD7xakCuQ59+tHU=",
  //   abilityText:
  //     "U2FsdGVkX1/uGHYLvRs76epcLpmFL4yKx+eunyOUJtFrW8kk3mbyY/tD64mqpHguad+oExHyBbCJzljkKQB13L6TRhLmS5HHwBI4ZDHOfxU=",
  //   gods: "U2FsdGVkX1/mSR7F3vSH7S6hJuYVmQn3sQ078dl+f2E=",
  //   quote: "U2FsdGVkX1/Gm6/6vcR1CzF4snqXQnGik1WJ+bzoPeyXDtPnW8Tizv+qM33bZNxw",
  //   vgs: "U2FsdGVkX1+JARBRl4rhHIF1sYxDG2M09wvTan1Z6A0u7YVN39OpJbT+06GvWdPK44M63EK0KMxncEscFtFFVw==",
  //   spl: "U2FsdGVkX18Dd/i5PHMv38qEo9j45SevpU6H2/R2CKA8ukUqI8auVehUDyeG/kSo",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "258",
  //   abilitySounds:
  //     "U2FsdGVkX1/HaLaim3fujQkSZhVc9MBKUVlHpKcFgjQqlQRLdgQQaGQ5sV9F+Ec7JnOtw+Ey4boGz5eD7hNdjR26C9Oq5dmJCK+3B8niyFY=",
  //   abilityText:
  //     "U2FsdGVkX19Z+vB98ZPpDeE5enRPVIRkhF72987LUWxjL924MfxOAhFiYXVdU3PkT/iXzjdVGd0z4eJ+YVj0oIfYSlTta1UYWSvT8O//fvQ=",
  //   gods: "U2FsdGVkX18uPD7Gh8msntckXNLZAjEBCfzfSetC1SQ=",
  //   quote: "U2FsdGVkX185cr0itsr2saWJ+Cf+wdXW4eZNGx6wtmblrb1j4mMzA47Dpi03FHix",
  //   vgs: "U2FsdGVkX1/NfQr5XMMgwnQ6cJPR1CJh8DsIsvHhIWyyYvyBTEFblqS8Wlq9Irnv/EhzubDsWnGgV2Br/t/03A==",
  //   spl: "U2FsdGVkX186zb0MPNVJLAjQiXMOO2Ra592z9huti4AS7NMQ7o4S/KXS4lW9TP/t",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "259",
  //   abilitySounds:
  //     "U2FsdGVkX1+UVMwI9bDEQW1lCfTnnU0Yva7+yIkozmWfsSgAc52YlLBSXdETDM8obDiL05VkFtagDN4sxduqLdFQJZqsf0WKMzK9ZMH6MQw=",
  //   abilityText:
  //     "U2FsdGVkX1/ey1LrtXijQ5HHsU70hi/sBzWruKmdQu6zPsao64N+xgdaiohgQddGziarPHNwMD1z0qLY7zrFktjkQ1jlYufbghpQKABQSsA=",
  //   gods: "U2FsdGVkX19DWIJ5BtQQVJZ3OKm/wbYxPq4+JbjJYfI=",
  //   quote: "U2FsdGVkX18V5z4gKM3Xn+/zULgXvJDd0+COsa5kmFGfwkp6l7whZ4XzKwlhaJ9U",
  //   vgs: "U2FsdGVkX195YtUv6MeOKqwgS1nNPfffO6uJMEexLoZOHEfU17TnYU35e0bddzveL15p5jFlFWCLyvaf3SD+Dg==",
  //   spl: "U2FsdGVkX19zijApp5AM8amfGuo4s2JxZY76AAO7ICRGATJJ6z6kRVFnAfGmsCWG",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "260",
  //   abilitySounds:
  //     "U2FsdGVkX180UGkUXLJHcpNhzfV7j8vJz9x2GzZvn/JVIlkM2enEQ0IZTwGa3sGI1O4fss2mjxtyyx4bh2oCEk8xn3hrKK//aUUhJIB6bBw=",
  //   abilityText:
  //     "U2FsdGVkX1+t/WOWdcO7gG0N9f4jjPUcPW30t6QE6xbEaQzBpgXVgZcy5q8nzv/sBMGEsacArRI8YGkk0uq+gquzpjFIpV+ppM2PyMvI/NM=",
  //   gods: "U2FsdGVkX18V6/tzBDKlqOXO2n518MAZaMPnKHu+JCE=",
  //   quote: "U2FsdGVkX1+ISWrSebctYW6E+PvQ4dZkj6AvNAKrQadnlcSYEzrlCkQi3rzi0g5c",
  //   vgs: "U2FsdGVkX19nwMCUCntrYPgJQnt8ly0TcXk5v9UJMiHIQzpQB+uEHk/HR2WPfyeJl70PGAYCvRLobsnU3RkGJQ==",
  //   spl: "U2FsdGVkX1+QO17/ZVOzcCDDQEW9MmhmdzGCkjzdrtHxN2ng99Jy6kNSW/DDhVRH",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "261",
  //   abilitySounds:
  //     "U2FsdGVkX19KNVP4g8f47/qqDqUr/kNkJ4m2Oz0xBWkkSsQpYRP25Czcjmkt73h7Ck6W2t6TGSDM+KrqP+xIKtfaOFt7NqyNDfYULaMBBi4=",
  //   abilityText:
  //     "U2FsdGVkX1+EuF20l8ZXLhBb6kcqlR6mQmrfL3MmvvoI9IWkjF/Yynqm7ZK0RTDjK00mvXqECsS782WRujBH06jCoyyDfpa9jEkVaWLHyW0=",
  //   gods: "U2FsdGVkX18SuDrwk2RxfCRxvZS3j3bPdniruuGi4Tg=",
  //   quote: "U2FsdGVkX1/NZskqchN953VXu59jLQBngPmva9+GFXIeSonWe7hdCFq6ZHwI0265",
  //   vgs: "U2FsdGVkX1/MjXeKV+ZJRAfWMaULuyidcPGKhQJ1gVD7Vg8XdV0gf6dlDlvNGF1AAT2HK64E8N2QDxN/WPShBg==",
  //   spl: "U2FsdGVkX19DaseuttG/qyJGXDWUALoqmPnQYwtyrHxreCEYzb8LGUUVTo7TkotH",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "262",
  //   abilitySounds:
  //     "U2FsdGVkX1/VqGnlKxk9mhgOrDU4cRO2l+nrUQyXIZskvdncunP1aTk5OZ7mwuekfalkxtleEkQR2MdOFcK3OHQlHQHwLHHnohrDlOsNbS4=",
  //   abilityText:
  //     "U2FsdGVkX1+833Y2+C4Q9KgD55FmqXWPZLSJWITug9lMmyYcdng+4nKRo5l942XSeGPwl8O8Jn2RN07rzfxpKsqXJLbOBMUlqgbF8FTeZ8l5GaZfyNxOvjhINUrSh4jv",
  //   gods: "U2FsdGVkX18obWpYPat9v/uKFm8cb9n1ZvpX3ctIOZE=",
  //   quote: "U2FsdGVkX1+FkSIR3mjMNp4rVLAoRUTTA9kmAUhw8IFCSZhhd8N0txwsz2HG4Tvk",
  //   vgs: "U2FsdGVkX19Fr7QeBgNe8hNESfklVogPFzNpsOtvnhf0NSD5MAfsn3Kn+i/5Z3LdLvDotsnKVfDeNXL9szcyHw==",
  //   spl: "U2FsdGVkX19An3jMinj0rbUc3rtcH1sjdp0VVRWjNHW3sQaxbU10J1wEHw4iLAg4",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "263",
  //   abilitySounds:
  //     "U2FsdGVkX19Cm+H+/RTvBhl5Jb4aN5mnHSC6V9ubA2/73Rb2UcBP15wbqTLZB7rMbWiWuG/WpMshUBXkTyQYs4xZqspUfo5sVHKbA45AXRjQdJLXUq2Gs824TKaz7XOk",
  //   abilityText:
  //     "U2FsdGVkX184x80bpihGESy0JEX8/NZz42MtoB0rY5SMUJM6gvdAC7Lp4Fb1W2lQUdwy1KtG+2SnEniYFdKd/3W/wq/PL4csHtMn/9egOLQ=",
  //   gods: "U2FsdGVkX1/vup5mzU2zPhi0lx7pojd90bCYSTy8nPg=",
  //   quote: "U2FsdGVkX1+H7hdwpvUHbKAha98oBmnR9M5Y7k36gzLmJPLrguCnVZQfpfiwt+Xo",
  //   vgs: "U2FsdGVkX19fU0APE+DoH+zJstxybx9JFVwOeKQKJTGcKuGT8lv0VeENMjjg8IGSCHPFTMQqERHYYZ6febRzXA==",
  //   spl: "U2FsdGVkX19j4gcWbv7GkGQnpPyMpiSHuyDpG/ZiDq+HgICixFUtysPxjawf1/IJ",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "264",
  //   abilitySounds:
  //     "U2FsdGVkX19JkjpGdqIT+Ri9RsaaLuB1CbH4eEdp8/iCiW9lr3Gb3XL3uYJigRc59mcdgRm92ZdlB7iJ9SMkx5eg6/r9ue+97vdOqikwBdpUZKQS9e5tOcCt8FwyEPLI",
  //   abilityText:
  //     "U2FsdGVkX1/+70q4pE869aibgsnesmZsPiuEBiy9kdRW/WcUxvTJgTsVKTgdhkVUiZ7itI3lB8Kjc/mXDvzpHvEFGrQacsTNyxUkGkiwVQw=",
  //   gods: "U2FsdGVkX19uoimzq4fP9Aq0ZiEx0ZncqEAxJmmlebc=",
  //   quote: "U2FsdGVkX18Mqqs8Ly9ufslBcSqYFrOgJRcpq7ULEYa1HE61A2ujLdQx4Ey9E05M",
  //   vgs: "U2FsdGVkX19NDOXcAiMKkeOcZeAO0N3x0zSj3ErVVWohL0k58FUlfPMEhpiHbBtNzHx",
  //   spl: "U2FsdGVkX19BhGKH1E3P19sn1aLYIyhzDi6X4sJyNH3TjOlz7JaeHFupb9O9oPq9",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "265",
  //   abilitySounds:
  //     "U2FsdGVkX1+6FOwVKKh8vhqKpYq1EFl2SVxs3f9zdiTvJo57tKZ2Aa0PZUjl76DnVpMic3RqiwlSHCgRaiUeUQno0IcGqs699wqn0Et5YWc=",
  //   abilityText:
  //     "U2FsdGVkX1/tsODI02ksIKX89NiFukgNsKFmOZK7rznOrJFhFts7/LffGAdQWrlpMKbeohMn6bLIGtH5SCY/bVGK4vH09HxG71r7lt9ljmYLM4pX7mOGf+5vqHEB6HZ1",
  //   gods: "U2FsdGVkX18032i4Qt/P+I1qSKXbN+q+7dHdnugiXM4=",
  //   quote: "U2FsdGVkX19e4xO9jzVDtptAGKHp/KkiKMk7DckJI44cdTSb7fASQvHxK/+00gUa",
  //   vgs: "U2FsdGVkX1/P52yQP47kS6IzVtYeswk/+CLr1S85vCOyKxYTRWh+twADDqUnEceIVU/E6gMROqt9/7ZwNBc6Vw==",
  //   spl: "U2FsdGVkX1/hs3W0pLrUk5qvy8cnaQrhXb/WPMtZ0Ld7RUmhg+lCCDIO9XTF/WJ2",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "266",
  //   abilitySounds:
  //     "U2FsdGVkX18MRlGI5ng+hpTzEwA7d8vfIqLuKspjmdFuNEkAIa+fDsKMkgajBJ+T4m6GQkCUa665qQXC0kh6xfykhlFC0opx543CfGL4Bps=",
  //   abilityText:
  //     "U2FsdGVkX18DfTCLUTAvc4zqfFdndwBYQ7CSKoXXhoPTAbn/zzOutjg1lDialr4Ir05CnWMx8o/hZfGNb08ahe+FcM4Bg67H/RC9NVMOMlA=",
  //   gods: "U2FsdGVkX19Dy/OVtzm0EB1h6YwLok0nGMNMfRrY3DI=",
  //   quote: "U2FsdGVkX19qKYTpt7W8tMcaXHJFzmdrJhnH3fgv3DJ2BRaGj39HTcsDUFzSwiTN",
  //   vgs: "U2FsdGVkX1/SDo+PFQkJ704DEsd2T/EYfdZ7K7lzN8veb1/HvsvE7cENS0yI1yTmWtUE61PqonI+jNj+gHM7Gw==",
  //   spl: "U2FsdGVkX1/O6bIktQpLqL1BwA216Qv853DwUfR2wR+LWGppunzqWzg4WoIog0MR",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "267",
  //   abilitySounds:
  //     "U2FsdGVkX1/X35AVOdW5KaZzDDrCQI3Wf833HBA0cH441D+cJmRtxxhiTvwTfwO7Zf+90DoNT7456/SpkE6xfXPktKvutgF4LxJeKe09Eh4=",
  //   abilityText:
  //     "U2FsdGVkX19d+DDjb6ky/mgLbhaC8bR20x4unBrOOByTVLQPyVwJuJUvAxigOt62m+SPbqF4i34p+si9lkpT+HAou3nbroOLG/VS/2dGvus=",
  //   gods: "U2FsdGVkX19H0vha/BMsbMebvJJqEoTfCOitb9GK5Ww=",
  //   quote: "U2FsdGVkX1+EOSEOZkqOJ83+47C1JStfeNL0CMVqT9bIrzpN6oGrA9UKRC8faYdY",
  //   vgs: "U2FsdGVkX1+hocxq4gyBIbCE5Uumu3aOYmIe7/RqdlcP/YI6izgopEJ6H+41b4RP1pNmJTNa9F3xSaHhWlXG0A==",
  //   spl: "U2FsdGVkX1/O01wJC4mym9HXL5xtTS5VztoeCV+wD7hegiNEPyvUAnMdzlw/lI5c",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "268",
  //   abilitySounds:
  //     "U2FsdGVkX1/SFQDT8jMl/7FLBXE1MPAbepOMRPYOozoOcQbDnergRehFcLC5MbG5xhdQTnQeky0ERDMq1AM5ufiRQtSNVgK/oIa4PKpOUC0=",
  //   abilityText:
  //     "U2FsdGVkX18/xmRbNzbyNDEqBVzmX0njLuW9PyCqOW+K++ZbsQJD91b5gV8OJwETByptdbildqktqHE8A+eCLoeHivVLsB/g2R3sL/xi1Jg=",
  //   gods: "U2FsdGVkX18DFCK6kLk+6QsKgQo+SA/ysLqQLh1UHWw=",
  //   quote: "U2FsdGVkX19Yjvcvw5EI54l1FToXtGoSNcB+hIUrBdzrt+lZWcq9WZ/15R93MaoE",
  //   vgs: "U2FsdGVkX1/ILH/GGf+L5YzAk8gYnkCJRrvjbCttf3CdfjPs2ctR7hxZQA8poCjQjU1rQsLXcVAE/NETlICWdg==",
  //   spl: "U2FsdGVkX190Ii9CfdjyjB367am2RNamrK9UrQ06tnH8LcrZEhia35Hf1C2t6j7M",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "269",
  //   abilitySounds:
  //     "U2FsdGVkX1/hYn1JlzYN5AGE0hkO35sEr3p+aayRe596GUyy5E5H3QgKzfresqEJmzHmWZa0BzCnauK+Da1Bj+t9L7G30v7nhDMXOubvBjk=",
  //   abilityText:
  //     "U2FsdGVkX18UgGEe8VivhY/XmwIH8kZE0kM7BSfmP4suU4u9jS5LaVBm4zQKg7gTS42prakf0bZc8v7gDpFBGfc6HmfA/jXI5bavdH80NrCXg66erQYj6gN5QMxylSi4",
  //   gods: "U2FsdGVkX1849nKL29+kEP19B44euA/N0ezqGgVSGEc=",
  //   quote: "U2FsdGVkX18WAXBkvQYKCajmuz/JG4WlQhqdGvKqQ/i3IpHzlS+ULMWY3pydNMXS",
  //   vgs: "U2FsdGVkX1+SRwju6jqy13f5Xw5ORd/0UMimntzogN840EPoJ1tfG0vUPHBCdjXB6j0i+5uCbXJ/cBNekHzS4g==",
  //   spl: "U2FsdGVkX181eX0CE3oteYByIpcXmmPJF2V+MnuyXEX1vuPzTCy47SC2otatotqC",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "270",
  //   abilitySounds:
  //     "U2FsdGVkX1+E9nXrxPUPul4n/IEUqu71B4WaK+D1wrLmZMmMg3cVE/zSbSU+wnctJuGKtHQnW23htm4c/EQRcvQRt1BdpyO94YQ+PxM5SBjMbcmsBmjt/sNcFqRQP9c5",
  //   abilityText:
  //     "U2FsdGVkX1/ttyBwNHzKrdkfFEcGD/1c/GOCtux55RcGN8xIQrgxy7aNjZODGZhwJukiXyixDzictn/Zc1TXuOmUmBFls47ot1qUXpeqfAs=",
  //   gods: "U2FsdGVkX1/NSKlcz8F86zSWSB+DJel9p8J2JhesKJE=",
  //   quote: "U2FsdGVkX1/JoTZY1puuqqHmc65SQinigOiFE86kFj82BOEN1LfUhJUoIA2AM6pE",
  //   vgs: "U2FsdGVkX19a3nllTg+lJNG/6QtRFfwLqgEvqHk0HvMEmzdhypvfDr+dclMuwYb30YUF/arWUXJMk/2hETIhwQ==",
  //   spl: "U2FsdGVkX1+le++rntvm+BX4ky+nmBzfVIc4cn+2t/HuUnmyQzqSYwKS7SDO8Zly",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "271",
  //   abilitySounds:
  //     "U2FsdGVkX1/N1p6GrRiiC1oj3Sr3v6+Sm3Ix57TwowPJrY95v3YeC15ufoVwAqdH5PsdcOr2dOHPoJdQiAWN9GbO4aDtQ1cMNb1nD6CCJt0=",
  //   abilityText:
  //     "U2FsdGVkX1/gs3tz8Qk7WYKpN9A0JyXDnu6hI0d7llYVseVLKmnFAzYQbYr3Wqzz2ag/Sg9iGtyLDkOp1TOc3qujaF/Vfd4NM2SDUXTLHB8=",
  //   gods: "U2FsdGVkX1/6U30wBCeZW8c0W5DpGp8z5phbTHumGv0=",
  //   quote: "U2FsdGVkX1/UvtYwdBiiP4BlRus99bRnFZ11C+7ZyJv0HE4/xLsgGi00RhcYIVfT",
  //   vgs: "U2FsdGVkX19PoY2xU87o6R7A7fFLNSjIITjNXi3EM6vW+9umJmptzYbdDXFflZdMFcPCfWWkLwBcgySXNtYJew==",
  //   spl: "U2FsdGVkX18+R2uiLU1xRhROLvpO6EJ66KSmgSOXfgp1oj/+NzY+u9fxGbiFu8vK",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "272",
  //   abilitySounds:
  //     "U2FsdGVkX1/pKawCGtgjenF3B4H11PlIfjvbHSvuBHjp+c8PwYx1U9NlTrRGE5YHIgYCFYffCAf8AaizPud5HhHBYFWJ8cpOdSxyDeS4SfU=",
  //   abilityText:
  //     "U2FsdGVkX19jU7JeYY+Hy9bmsxgfdzoOHxBP9PWJhp1de4iRXJ/sxdJFPW4vT0OzxrA0b8OMezkU2LaIAhGZiA==",
  //   gods: "U2FsdGVkX1+3x/PIYUQmDzJWG73+DCfrVktUOjjynjI=",
  //   quote: "U2FsdGVkX18rbpQFWg7mbyX38lBWB5ypfoanlQEo6mdPJakF1bPOaZaIhkhiB4yw",
  //   vgs: "U2FsdGVkX18JKi92D1V7SlF5krjmBFmdPhjY/yWhAK4ACJL2fM7ViFAiCoiAjkRc54589e6kBrWCTamZ31tDhg==",
  //   spl: "U2FsdGVkX1+KClBVy5GBZ51St6bGcr//k0W8N3UJl5czP2qGxkK1WoEwvcMCsUlR",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "273",
  //   abilitySounds:
  //     "U2FsdGVkX18p/ff2UqT5V6RBzNFKPphvp4HTH7KDPXUMZDmPuZprKWNizVpF1poPgN5TB3MAfh/53+0Tw97gBWUYb3o7KXOOFF0ahIKkX2g=",
  //   abilityText:
  //     "U2FsdGVkX1+GqKW/agMB+q+X7DaNP8jXajwjUb7H51XmQyeJ5JUOT17akjJCELNsmfgSCNCQmgwI5o964MMvBqMrS9znfrICjFB4mZoQVPw=",
  //   gods: "U2FsdGVkX19FsYKW2kZnZKAGfTbSIcGwTS62TGq6AlI=",
  //   quote: "U2FsdGVkX181IgBmbfPnzijmJs4kX0XrvIs29i5awoMNwDQsBRbslIAgZaMigOu4",
  //   vgs: "U2FsdGVkX19YYPdXAoN6jb9wT4Crz9SvwxrA3X+BDv67OqSm+h5wz0LstY0RVhvSXpzjR8NOhtwuZw3qpD1sig==",
  //   spl: "U2FsdGVkX18Tm40fOSb6IGAU3KDNsXKeqVm4yHRswbbJZiEVOwXa7zCpKuEsxwDu",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "274",
  //   abilitySounds:
  //     "U2FsdGVkX1+wmZvJ+TadQbvqU/HsNwCWVEWeezYds38cSPfBUxnYUoNDZORxID74Y/tPzFT7iWQGAkPWE+LZpdhap5KYbAJRHPafSCRAnVM=",
  //   abilityText:
  //     "U2FsdGVkX1+1lMUW3b8XCCRhIWAbVx62tDXa9s6Az0lbM2Goem2GRjrB7p4s/Kl06n9U/krL+jr/i1n53PvGQZPWQVXRFhoysGpFDoi0UuA=",
  //   gods: "U2FsdGVkX1/AzQ1p2z4Xr4ZAzZEWkjZafi4hJJigCfU=",
  //   quote: "U2FsdGVkX18A8jMa3kK3lnit/MF1NGKg9pVnUpfDbK6rlp8s1zllke+IC2iEleGy",
  //   vgs: "U2FsdGVkX1+mhkv6jfpirtoJmT+aH+RhRtE0+ZF4bTBgrv0Mapa1/Sqi4LQR3tOS",
  //   spl: "U2FsdGVkX18I5DI/B7iF141UNqczEIGsYubbtKXKUMcYNg3bsbUhF4BH5/c4R8tR",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "275",
  //   abilitySounds:
  //     "U2FsdGVkX18wbPlg56NSk4F5OEA0nDSN0BtRtgUDtILvfgzH5+cYyLzfRBEMdZhwQVZKQY8Bl4lwub5yFIYKCYYT4G/2h1efkYCRDDT52wQ=",
  //   abilityText:
  //     "U2FsdGVkX1/CEjRP0PI4MD8phXZ9IMkDv9F6kv5EifWIdmZYcoYa9bWAPlmBweG5J/IGQ6djTsUIftHURUHDzrKhJ28dNZltm5pQszN+3TM=",
  //   gods: "U2FsdGVkX18YZAEaVQcOPnsx2kKsqPrtE+AQs45YuFw=",
  //   quote: "U2FsdGVkX1+SdZ5xhp43gWdIs4PTEys6pApUe7pHhgvlSK9L8xCaWI7ABOSsi+nt",
  //   vgs: "U2FsdGVkX1+ActTIrFUK0K/i8ldQ+WZoyLAbKwlR2YrcGPO5IfnDX5tDXyFDkIBpdHnvdbQTLCxWqSmELNsF/w==",
  //   spl: "U2FsdGVkX19WrgOFzirYBzH4QkBl9nqWTExuO9L5I2wcecJmt34+foMRe8EuAcsW",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "276",
  //   abilitySounds:
  //     "U2FsdGVkX18CWGy1Hn1uPz6KnVyIPZ0S/E/ReTb65plgi9+KkoaP/pzCUkiobl3G4o6+M0p+gM6N2aJQWQksSkRxP1yFgL1PASTSLT9iO5sPYv+U9VdcCBePAF1tUY2u",
  //   abilityText:
  //     "U2FsdGVkX19X8iGGdPaJxEfaWV2AZt4TktEo5UTiC4MNaPTjuVIOgffG3Zu/E/YBpvgIhHkaGyS1WwkTXFb2TUgu1OCSDnRKuJmIez7SmXM=",
  //   gods: "U2FsdGVkX19DRggkiEgn7ZhOikfjvIC9OGmPShCLI3s=",
  //   quote: "U2FsdGVkX1/AVcHsgW9EXYoQOaxtDHeaRUsAjsstYxmPAsUKfcZKn2ahq65XC8KC",
  //   vgs: "U2FsdGVkX19Ygh4EU5SSmxWYSSEqDcVnzjBwEk231gRKSU6rMMqjoesZ8mY4WBRnA62Wqv2vcyrPEiGGy1IZtA==",
  //   spl: "U2FsdGVkX1+0RG7R3LC8uZkDsizl/idwl5FB/DdG8Kkl7naKWPrLPZGbr8ue7rip",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "277",
  //   abilitySounds:
  //     "U2FsdGVkX1/k+VQW4A7qIJ1Koa59opE7HOD1QlVB9qIeJsJo2xys5+8nlvWFOn52/dD3lT2FkzF1qe//qO6dRn3tArGfXWkXUXnyfSteqbI=",
  //   abilityText:
  //     "U2FsdGVkX1+FI3QyvVGdYrRfbpJhfvM5LtKCWKXfw4f+UwLMRxGh6UrLGnWdXqB/mJH6PgKHBZJsZ1zSXaJf/Uqkvbq6l+Fzw74Ic8Veqw0=",
  //   gods: "U2FsdGVkX192xM62jzAAoiYvtKhtoeop50DzuZBcdLQ=",
  //   quote: "U2FsdGVkX19oyGeR4+d+As/9FW9RBAJLL7gym9g2e6hZ7H1H9Sxv3Mwwk57znF9j",
  //   vgs: "U2FsdGVkX18P1V9GI1Bs1Qg/CR0y2g0OKl0pJvCLrQQQS1k3qvl1s5gNRp4jaVDn35UIUKgJfWFG1pjmvHoX5Q==",
  //   spl: "U2FsdGVkX1+KCu9obxBNj2IVXpJsb+KN48EMV5lP/bE=",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "278",
  //   abilitySounds:
  //     "U2FsdGVkX1+ZWWYGDhvLSDpzpVP5VG7ODRVt6VC2m+P+UQLYdzml/lv1SvcZ+brRKeogdTpBkNbua4fyXuakV6h+QHgkA79upPXl4aGkkfs=",
  //   abilityText:
  //     "U2FsdGVkX1/fzEcKnCxgrtr2XB/lnMoN8LcObRQllvpWTyR39ML10WQ8qjwHpP2LXntQoARCkjJT4SeSogeeofPjao6q5u6rT8RxYDSeNwdVtPDWD21qPbnRFSb//+Ps",
  //   gods: "U2FsdGVkX19ovhPLI0+X3ffhsPi8LeKeddfx6UVCvjk=",
  //   quote: "U2FsdGVkX18GOLJmO13l6FaNwSHioPpuO381ajIxGUpULiPmBAmceEsK8ESRljgH",
  //   vgs: "U2FsdGVkX19dSnpO5CDjsfvUvBGjblWBBjCMwl068bbHlYa0TGPGcZ/7zGq09lxITgCg4mwjbba5towJikSKrA==",
  //   spl: "U2FsdGVkX1+iVSCxUgQuVBS+IQvrYA8Rk68NClD4hik=",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "279",
  //   abilitySounds:
  //     "U2FsdGVkX19AcgbzeO+tSY549AZpAcr/XL13gEROpMBfJW5pSP2ZdK0jp/2aq7FpbNAy0gkhvCpapSucTcbF2IZxioBLCBAt8Fiy0YyLK/I=",
  //   abilityText:
  //     "U2FsdGVkX1/piRFSWEHRH8qcEgnK4ETOJGFcmKSnsETKRpj+lC9qzo1WRO57AS5iPW8rPqQIiii+b/+/IAVACfQGldAdb8JpxHxeKjGaqoI=",
  //   gods: "U2FsdGVkX18iI+XyNfSjR8IU4ols1JGmyA2CbwmMdxw=",
  //   quote: "U2FsdGVkX19sx8ZEutoRSvmxpBidHgeJNfLw7mzOsgi2XmCOyQJMAPW2Nmnys5YA",
  //   vgs: "U2FsdGVkX1+0mBL0CfQnOZhMRaREOoKhdN0vIIV5MZ4FDhdZLkoTDQFzQ7tlvtGHM1/csm7AVq4puDx+o/+5mg==",
  //   spl: "U2FsdGVkX19UoJpt0X4kIV0VFrTH9gn3v6PhY+H36CIZbmJvjOnaO1qnTAsUK+13",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "280",
  //   abilitySounds:
  //     "U2FsdGVkX18bJxERq2fpWOPa8GDWwvQp1JLrsBxJ2lFbGouFPhn2qNFDZYF1akuUqFLbYfPMaHTw6mFS/xf9p+TTg2bX9WNJDdWPYnXhc/8=",
  //   abilityText:
  //     "U2FsdGVkX1+bUh2t4VnsSfpdLl31Jc5hLkGLr37L4RDeiLMfa2ppumogkHk3YK/U1bqHtobHDpnNyOwIRoI4yg==",
  //   gods: "U2FsdGVkX19iq9ibcf+kDZsy2iT7uAHzvKlFfJNt8uk=",
  //   quote: "U2FsdGVkX1/0p2kB8RZGmezOjUu3EEVA1WY/hdKZTDm3OWRauD0MhHEFQxXJDCOM",
  //   vgs: "U2FsdGVkX18sLJeyWCQbdjIbkJXc0t2V2I2aMZMAuugs5KQzQGLgwmbsWuEOKEqKciW2aK6f7XdnNAGOa5laRg==",
  //   spl: "U2FsdGVkX1+b8DkY5I4zCJmwo0P9N2v6kRZ2DHpeREOhq8CxUFDvbcfvx/Bebz5p",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "281",
  //   abilitySounds:
  //     "U2FsdGVkX19E5VwAc4GFBnUi5FKw9PYLttnpRV3cG5fTTsa6QOHNl3dAoZkKPqe8fR1PVBRFt2driIQ+2cTZm0hcdns2LkIS/Cw9qQ9G79bA3hd45AYDJVmuoG9RlWyw",
  //   abilityText:
  //     "U2FsdGVkX18ZmzCjLWs8fWInDT1XBQDNiKHW8aJYUH3sWnhcwYGzcZfoNHEbWscwLGiy/6NxKZJIRsqYph/0nZBCx1A3XZyt9M4eAXoNKog=",
  //   gods: "U2FsdGVkX1+PnRuP3tGnSehy/445qtKLOCwMXRLi06Y=",
  //   quote: "U2FsdGVkX189BXnpheabUUU00ST77JxZeplQrw5IHTG6Pn7eeIvQZx3P2xegEg2+",
  //   vgs: "U2FsdGVkX19B+yfi4E7CkWdf0+bJvPH61xKN+maMcEWiqcEqn00ZOBVhVUmhqlc2eLkeK8JpNz7uNvAtwo4Wbw==",
  //   spl: "U2FsdGVkX1/UJxYqvCTIPPdNNZmHxK60kt5Ta5tDQuqib7xwFLrZquZp0JxXAgB8",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "282",
  //   abilitySounds:
  //     "U2FsdGVkX195BhHH1XhWL6Ad5ynJ/vNxtlKYqmdpJqoGF75/ya40Jm3qLUJ9rNvTsTpzysPfbh//dygNjucW0KSr/k31fsD5qkxyPwbKrmk=",
  //   abilityText:
  //     "U2FsdGVkX18GzITF4jSfcQRA5adSav1D/rCmjLjckUanP8s1xRfcV6EQuIXfn7u9pYolQhfa9E3gBXVYefD8Si9PYRjVVGW+oOYWmb+LG0RZR3R0mq8eKDYg1yrStHBL",
  //   gods: "U2FsdGVkX1/YvxPGEwbyRGWYYaGblgJjIUmBKjH3oPA=",
  //   quote: "U2FsdGVkX19FMiNFLJcNuZLkjPp31ZeMXAlQKui6sHlnGTlBtVG/3y6d805NyhfH",
  //   vgs: "U2FsdGVkX1+pCxCRR047RnrXsrIPTOX2sbaNetYAtMP4VTZ3YIfF2zKkmz62T0N2ny3sHj3/6EthCsD43vjokg==",
  //   spl: "U2FsdGVkX193xHLemxYuONV/9TCT9wnzrCJCxmUIa4aI1ZF/MwKvLxxNcMdnC35f",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "283",
  //   abilitySounds:
  //     "U2FsdGVkX1++xnyc38bRTppBLRi8ACEtp2lHiTJOm3KZ3FdBxvt2bH461zLWp0t9nEI+UynwG//Yrb04sZHbuWlAwOABWuhi4pgWtpuYFog=",
  //   abilityText:
  //     "U2FsdGVkX1+dK/N3YVQUnJitvLX+dmwUCzYoNaVL2Nm6yEXlL5mVNGOZkyG92hQOhtX4YP1dqNLBreEIcRdW/NM1XoPwhaw/VpqYdb0RKLE=",
  //   gods: "U2FsdGVkX18JwsrPrCLszLZU/YahGdhmQ1LK91m3iLw=",
  //   quote: "U2FsdGVkX18mE3hkWSVCNBTN44AxGyuuISzjBwOo2h1RCPS2WvV5PHflGJKcKPUD",
  //   vgs: "U2FsdGVkX1+NXLhyUF7xKi/KhDBM3vrAR6SBJx7Qbnzwxf43Jq3MBks5Y+lrTB8lcBiSppIoVjWtiRioISP2sQ==",
  //   spl: "U2FsdGVkX1877FGTxqjOK9K7YybmTuRtAJT6YhYmCf4NdEO9Xm6JyVA2Y3zuufjc",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "284",
  //   abilitySounds:
  //     "U2FsdGVkX18bhP47lRn2QNpDRvxpBMGFTV3DcauNjjyEi2+0Q4n7l+uM1ljS4JvVk44RJLH/KL6KY7JcG0OISS8VHckGHHtwZwtTXHdQuGPvwoGhJs4O4whcgz+3q8yi",
  //   abilityText:
  //     "U2FsdGVkX1/CAOoZyw8dtqh/RzNJX1+FFAQ/1tMp/oHqPiCTIWi+yuF7wv9uZXfkpzXowqSz/fw7fbaIDe7J3w7TMS1q8NdckxQ4Czr8h+E=",
  //   gods: "U2FsdGVkX18R1miteAEyc2GVsZl/0X8CEH2znjXErTw=",
  //   quote: "U2FsdGVkX19qbf2gC7tTCBNhUMY2KLVZllKImj2+/0nr4r6HwKhZHctG+AkbrZtx",
  //   vgs: "U2FsdGVkX19Si6QvvMD8Bmb5WNSdWrTylsrk1aBxfLS/2z/ue+6sbC9zGQ7mFOL7qd51QzdfkjJuBJkvgSQtrw==",
  //   spl: "U2FsdGVkX18EuXuP4/BsXXWjbfcl6lJfyuDRvCiQtRJ7vdJTmMM/2PloeAzCJRgH",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "285",
  //   abilitySounds:
  //     "U2FsdGVkX1/Pv+XHyhjZvNd5vdheBdN0Aa7XKUmgbyR/7qCGrcmtOywbeQGCRGvjHSLzgTIyEyxGZq4pTZSq3VazohYcrCKqZRG6ojbTSmY=",
  //   abilityText:
  //     "U2FsdGVkX1/vPRtTnvu1jMct7FfWnMLV2vO7dHhzq0WESfipKSgZ9YiJ4MxFh5elgnFglCxoMwtDOgDVZ6v3MTO1cTYIMdoTvEv1SsgDEGY=",
  //   gods: "U2FsdGVkX1+35vF1Uj2OlWVqM+XZAgCHw0dQSmNbpro=",
  //   quote: "U2FsdGVkX18edPzQU3mvruUQVS9xPmYGhmWP0PF+QdzFMnHLm+rScfWAE4prk9Zs",
  //   vgs: "U2FsdGVkX1/RS/8gBZUL3hdr8d6FwwrCZf8hBJjZ9VJivms6g8X3VIjUT4MqQOC+3paLmg0EkYp2ZBgDlrCM/Q==",
  //   spl: "U2FsdGVkX19gXF3GGCVS7qW9olmBg8hbfDT3SETXeoWEH9QM+jlHtz7qGuRpR9ps",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "286",
  //   abilitySounds:
  //     "U2FsdGVkX1+dbxv7oV/qGRkSh0svd2t/c3ipuefugMm9n8efabbOaL+ubhXwgaMG40DC9q7nM9uYOx5V4A8lsrWQlYKWF0BLPnUGdt7ELp8=",
  //   abilityText:
  //     "U2FsdGVkX18yHcuYnKNj7vSznnJ4IzuURTN0Dp7VlVaui1m7CmMS5G6vgy+3Rf88g0wcclj5CkkPEg3w0UBaPjqFd5YYIEB5cPWwqgai4E4=",
  //   gods: "U2FsdGVkX19z/nXKDAJ+M/y3ACdTjEuJtqmtZHlrFpE=",
  //   quote: "U2FsdGVkX18YXvuElgrgTN90FIQ4O5RxAr8FE5qw5L9X2ZCZ1w1WzJBarEDTaQyj",
  //   vgs: "U2FsdGVkX19wF+JktMvBc7LoSy9/D31l6lTjp8gZVLGJ+9ORijChyq94F2FPbPEJWYvhQK15BQ8tCjDLaTs4gg==",
  //   spl: "U2FsdGVkX1+FJI1KGXmI9C9u1GB3SO8vjiHyrfelOoHJpgZ6DlSIBB4HABex7jzD",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "287",
  //   abilitySounds:
  //     "U2FsdGVkX1+UrHfwOEhAgMcLAMn2M5RT/mhfd+kgDUF0SEuVgzPqrV/g6Kp1qpsijRzuahjz9egBCg8lhlFUA0uCP6QcIOb05GtYYu4YW6A=",
  //   abilityText:
  //     "U2FsdGVkX196qU8aKFI1lCTBokO3gs+G0KfcK9XuiE4xkcUtQFm7IBzEetIZaZBY8g5tgTPucqzTjhlg4xh/fhyjxoNdhDGPBkK1LGsVDVk=",
  //   gods: "U2FsdGVkX18KoIlT+SLIWFsJve5GBkyfdJzRKPVLEA4=",
  //   quote: "U2FsdGVkX18T9nCCbC7BCwsHF2RGooFc3r/GGhPghem0XO9N7qjZZxzwVGQFe7Oy",
  //   vgs: "U2FsdGVkX19yXZ/ba5ESxSdJH5sJY56DVw7XZFZstIJqbemc+Flc6RewVlgmCaDMj7jGzyK6oGptpG0oyguGsQ==",
  //   spl: "U2FsdGVkX1/vwnHbWHJlARkPteZB0XKPfkrCYOywS8wYl8c6v6SP3fZvygBe0X5y",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "288",
  //   abilitySounds:
  //     "U2FsdGVkX19a0IMG58QK8nyA53mmhPlVM53SJT1kFC6AmLmizh/KUwHvEx/lIW9ykRL/4bJ/4zz8D6s9GYsWkhGoy9E5iFbmMLASLcXX2BU=",
  //   abilityText:
  //     "U2FsdGVkX18kJ6hNCVdPvqv3Qg5GUsaSwSUJxyVWq0KWhH6SkhTH74xvM0t9XAlG7JHDY/KHU7zNW+RN0hl0e3Rgc665jorpGmosqNOUAOg=",
  //   gods: "U2FsdGVkX18QffMIISYF/S5w6+CwbWVopyrzN8gOeQo=",
  //   quote: "U2FsdGVkX19vYgyJh0Zauc0teiBArPl3LNRhf0WHwU32heaBKHGHJG0BSlbUtN3F",
  //   vgs: "U2FsdGVkX19Xv6Z43xhH5/j3CugsbiUzMsAkeZnjAUhFaGfpcS1Jkn95g4zpPHWQwMQEaNvhQVSgT+mcYA9o3g==",
  //   spl: "U2FsdGVkX19/IKBtDWMcNJOiLeW7sf67NbdhqOzyeVxpEAdNdPd6Hbe91qm8aTUy",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "289",
  //   abilitySounds:
  //     "U2FsdGVkX18Rcx1vnNbUC0kNdVOsOAf9V8pOzrncexO1sSAEwV8diga/AIVrYwpnnOiyuYzPo01RR3vwS5kBVh0L2OoWz14xcMFD5b+pPro=",
  //   abilityText:
  //     "U2FsdGVkX1/Md7rKkirx8wYWBa2ChTKyuZZ27xYUeDQMaEOUYPhiYRUXinehx5Qpe5F64jGINA2HaMECzf/bsASg+urMj5Z4QF1OuWyhs/8PX4scfIqCFKMalAk6og27",
  //   gods: "U2FsdGVkX1+RHAXj/Ggba6r6ujVU04NyyEWVTHJyPIM=",
  //   quote: "U2FsdGVkX1+S+V/F3J3Je800RwyVzLBDE4u0pU56J1pTYJhA1X5MCnQXZ098bvS1",
  //   vgs: "U2FsdGVkX19OboUqfnDm/SLZFuwXQgGN+t+4+BtiZmvG1lsaEFDxR9sLDF0hvX6NhZ0TJ6N+2d81zCEtDdbafQ==",
  //   spl: "U2FsdGVkX19ep5vCB56a8nVgVfE0TQGFyVCBqxm4CU21hhadkefxglOnwGrAgb76",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "290",
  //   abilitySounds:
  //     "U2FsdGVkX1/we0b2Mz2ef/hpao/WK7pORWIM3nsa9EpoUa4OtTVVYTNbhBfHBU4ancurD3pt7Kt9fRl0YjsJ+wjQyZJa2v45Jd749ztTlzE=",
  //   abilityText:
  //     "U2FsdGVkX1+vI5GTq6sn56f1ubj3qSLKXBU7KPnvk0Dd5agWrUG/uR/lB6RpKXakhX+SbScg2SMJpuuyiePkNq/HxlaMNSFJ5MifXe/dexQ=",
  //   gods: "U2FsdGVkX19OryPvZGwrmtK9cSWrh7l4530eW+zG/+c=",
  //   quote: "U2FsdGVkX1+HjBGVykpH+SELwwoAfjkHwCVpVoloIJXeRLzpzwbbEAu8Q+u98/3x",
  //   vgs: "U2FsdGVkX1+KjqQq4nWI9ZNDHvfZsvP3/U8Y5hRueB4YhFOuLaXsQ75UG98602ff",
  //   spl: "U2FsdGVkX1+asU4v9xfahcnJXDEkfd4qxmQYTC7dnEQpa7AUqOO8xLkEy436+FGN",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "291",
  //   abilitySounds:
  //     "U2FsdGVkX1/y4Nuq5QMegftf7NnSl1xlFV++NyIRiCEPDQARkRvl1Tcma057HaogBJPq8WFtu0cRsUl5HN1CC7oXhl7KylWqV4jCeB1AXKk=",
  //   abilityText:
  //     "U2FsdGVkX19wvcvJqRPTo/gZZ/BTj1ykdvGBiJCzW5YcgQ/xzCuSz9erbYYvlT/LeUgWfE4xeyho8upjY/LIUpPorpx4xqzNqd+HoMd6hkE=",
  //   gods: "U2FsdGVkX1/s5HZn87fQffSg6u69OI7Cn/IpywxWGFY=",
  //   quote: "U2FsdGVkX1/QlHjFd/gim64K9HORcyP98DOKOj1sTvNLkbzEr+fqXw6QqqCytdJ4",
  //   vgs: "U2FsdGVkX1/v1jKCLvwjbaQw8rg+FXVYTeEIEY1a0L6ToUyCynGAB+01kwrrIgAR",
  //   spl: "U2FsdGVkX18Jhh1mVPlnE7Wf4L50ANOKcA/Ymo9uWle/ovL3bFFMMXEyKl6BgTfX",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "292",
  //   abilitySounds:
  //     "U2FsdGVkX1/0rKxtl9RD/OD1jgUeVUy/DSqnN2gN/uhYBGHnUP06GWPV8dsPM2dpnQ/hZAyG2bz6612H4VhqKVm+2RlW1gXB8YeDk4hVDTM=",
  //   abilityText:
  //     "U2FsdGVkX1/6tAHfSE3hacnUYQZQ8FfUS3QxPzPjeleGpHl7WrL1apoUS+4oQHesOwqYKnHuOR/VmTF5Fu7rUxRb0cg17pCogNwXxedI6l0=",
  //   gods: "U2FsdGVkX19/Q/JmInINi03znVuaBR2i/iFTHHAJiUo=",
  //   quote: "U2FsdGVkX18igSicLB3ZMlp5bA+Vlr5vwiHYLtnb4QWg4UAmpnabeSKG9L3rQ+cy",
  //   vgs: "U2FsdGVkX19JRz3NV0C/2vDjIMa9DBfsO/qQ1LLhWYKxlI8npDgsF6ZqKOTgP4Kon1+dHI18A161dqjKyQr4BA==",
  //   spl: "U2FsdGVkX1+pXPxiowg7mmcfTs517hqK4MoDw8j/aKWUqvy92mHW7G+chIPfNO9H",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "293",
  //   abilitySounds:
  //     "U2FsdGVkX19uc9JLwAtjTtLKUkQQCAKcxYvh+CpmOTmZhPbqFEB5QgZzQ1WZ4B4S47Dq46Yqgji1cTibEMzAo9Dh7E+Su/vZDdJ9Rb7lqv8=",
  //   abilityText:
  //     "U2FsdGVkX1+nsG442wLtmz6Au49YHvTsdvAvjFSofDd1AONzPytHgmHzXQXnvZedC5I3tQHzA1lNiwx6ZLMvS83/fGfYyYtncd65LTHrgE4=",
  //   gods: "U2FsdGVkX1+xYXPPUZ8M/vJ3exBn4bAjtr6RYrRdJhk=",
  //   quote:
  //     "U2FsdGVkX1/xf7gfuBgABaWfMchJga+D5nPFLomVW+5us67AneTPFo1pWcdN6ykgt2qzdCnnzsfi8iANHANVyg==",
  //   vgs: "U2FsdGVkX1/GTRZy3/+PzocUDfOLGmw8kaYkfARlk7pGSFattxGLAyX9bnM+QGep2E5iqEoV3tfXI4p3Pvd27w==",
  //   spl: "U2FsdGVkX18kxM96R/L1yxeVOY7ilwzmPgkBSfR8IMFGsru1lB13fV8nQs2yjOzV",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "294",
  //   abilitySounds:
  //     "U2FsdGVkX19dKxKVZLgvPVj7Iz3TjT1UZGMswKEPSN0FFOxAcMpLgGczRimleKQJqc/yYNMH74z8mhgY99kzacgfxgmajwZQv4MS0wlCtAY=",
  //   abilityText:
  //     "U2FsdGVkX19sb5REWZ64u0jcBS2Za9u+5GwI8eoOfxm3js39M3CA+tjtlXzHYHw1MF13v5UbeD0uFBaivyNEUrB4hUGs3dg00xXoLwGsQwc=",
  //   gods: "U2FsdGVkX18PsgRFkWvWkSGEq8pUi5gKIOafjcBkHx8=",
  //   quote: "U2FsdGVkX19J9Ev/L0O8JsDTKP6+SE4zB/6uPMYDyplLVCOOcbQKn/TlxQyCT3ss",
  //   vgs: "U2FsdGVkX19WsJpPzTu1ybHAn5h+hKf5D4NASGK88ZZbatQ4xUwswRpGX8xQ6H1/dlN06jX6wtFKesEHBq5u2w==",
  //   spl: "U2FsdGVkX1/E4Y61YdNtVX84e+Zn9hCzuTSMb3pjK2eZpKKCTIRENsNpkAgUfoMw",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "295",
  //   abilitySounds:
  //     "U2FsdGVkX19aDjZrrL0Zf9xuk1CRDszfJqzEc4t3qZah5NMDuGXOPL1TgQLbb9O1AVN8twg1BUX87YiqATpdLw8aNCiBi6YvbkwOnfq6pDA=",
  //   abilityText:
  //     "U2FsdGVkX1+Cp+6py6k48O4c2UtYCKTS84W1lyUFmL9fH6bqW5HD1QiMiLTKBD4+52F3NPIwnqBY/eLS6VhfarYSAh+iyJU7cH6XdMFTObQ=",
  //   gods: "U2FsdGVkX19dasCabKawcbUtiYcTAi3rq2fB622/SF0=",
  //   quote: "U2FsdGVkX195uCj8bDMBE+TBjFr5sKxepxpxEb6Igwy9Vbg2RdOPMJrzXvqpD0xg",
  //   vgs: "U2FsdGVkX1+FjDjJYUyFW1IeiHYvIhQKQXOs2mjiTdq7AsHQpvLdhSv10w9KE7+phTTFo7Mce50jOg7mPJSSLg==",
  //   spl: "U2FsdGVkX18ECZbhGPIN7tpPSNuMFNOWXpjClURHOyPtG3S3UrrvqCqTwE42HyDC",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "296",
  //   abilitySounds:
  //     "U2FsdGVkX19f9Io5ehsleLv634EQsBXxY8mDPLdjLeGWpZj5fWyDnniPPmkQTvj5r5fihUZ2pec165RY2uwKtaluuGBcQeyJkdS97MygMcc=",
  //   abilityText:
  //     "U2FsdGVkX197960zpLohZFF1Hc+x6ajatic0jJ0Pwjh2yb5GQioOzvuJ7ftvjn+vKX4AY2N/DZzelKGqh+m2tBq9yXjELl1RqeL6xJQZShI=",
  //   gods: "U2FsdGVkX18ugp+o9ROpYXUvTAW+ngF68UXOfyMiW5Y=",
  //   quote: "U2FsdGVkX18SulKn0BcjrywQtgQgw8vH7mjnLRu93s1A0BZqPexxoV49pb3K1ssw",
  //   vgs: "U2FsdGVkX18VnuzW0AyHifZloX2jbhwJX8GWcXzIQUk11TD4pOmSZ2c6QRpAlyEAANGuFlQ7Nfu37P9qnsp8Lg==",
  //   spl: "U2FsdGVkX1+BkJZ9M58utJooGGGoPhc2Ng9FslbrjiFX2cIdgewCtjy43/xK+U7S",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "297",
  //   abilitySounds:
  //     "U2FsdGVkX18tczvzTdy0E1cIYNDVyyscC4cKZESskxYutKtKgg6tnt01/zKduqmx+5veFXvc39DP+/H9uOtl7sDfXkFsnbEr+Wb0V9JjGlI=",
  //   abilityText:
  //     "U2FsdGVkX197kIV5E7ZNK5tWP3B8p29V+mAmaODrUiYCtolo3qRJrcYAzsrusj4zGLgClsx1+EAsriSrgHCPoGs9piDK+8qEdA0luhdMNf4=",
  //   gods: "U2FsdGVkX18ylGqPk+yhctkCkyPm9594vIOjJILQ8Vk=",
  //   quote: "U2FsdGVkX18sfijgwgTUoxGAe3k8ll1P6j9TCd9D+Yv5jlZOhyA7/t8kD8t+1/S+",
  //   vgs: "U2FsdGVkX1+UwXgPPKIsQqU7B1eXmvJRHpzLwBZM3WBKrgLMIw1cuKR5nAJDgGK2/MvUgQT5Ks9jB4kzvio96g==",
  //   spl: "U2FsdGVkX18FHQtUhqshSmju4f+U9T41iBBHi1G+tp3rUEW429nw+pH8l3B1gIqm",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "298",
  //   abilitySounds:
  //     "U2FsdGVkX1/tc0DrBF5rbJe+nHPG3ejxXalr/FRZHPXMpQvHQXQgrvPwPdMu33EwWq7rHMfeAWWuDNZ0EFJRlCbGpsxciGAAaWFMOYI4XEc=",
  //   abilityText:
  //     "U2FsdGVkX18kGl+Sy3uiUW1d1CJ4nedH3ncgKTG4qRgplxc8mh/VeJsdMn1JLyD/bzCyn9ejVOwoRWrsLpiABwppEA0dw51ee0r7W0yhZw8=",
  //   gods: "U2FsdGVkX19WFarbWekGIKtiYP5rwYjVJ2cicnkbois=",
  //   quote: "U2FsdGVkX1/q8lqV66mbsHvS4lQDG/uXgdj9VcZ9mnbytmTr8e4s3udHIC68R3EQ",
  //   vgs: "U2FsdGVkX195bKkFEZXFJVTrrLjpc66R/Nc6vkByYGIcA0TCto22silhAoAC7qxk",
  //   spl: "U2FsdGVkX1/Fr7RtgkTR04KnudSHQWtgaBuLS3CKg3nTGsCZdQKiPRvqnEoeonK6",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "299",
  //   abilitySounds:
  //     "U2FsdGVkX1+LIdTgie47UC0btqhspOmzEYTd/u6Lrcy+5exVdZEq0t66mbJoX558P8fqmdoHQVobFeFKKH28PbtCwejr1s0kUSN6NbHgkGQ=",
  //   abilityText:
  //     "U2FsdGVkX1+bUGU/XWXr2OlX8gUwb1FkW7S+p4dPuyjxbxcRFohjfPn7GxMuQvkGwXhEZ2XBUuFaXFPtLSPyMA==",
  //   gods: "U2FsdGVkX18PY7aKWZz6omAbQCtqlwtIbfSqVvYLRYc=",
  //   quote: "U2FsdGVkX1/S5iWAHv3O1vESIAv32/2dLP/5whodpc297uiEbkFaXio1Q+Qjav1+",
  //   vgs: "U2FsdGVkX1/qBIW3oTMcVVJOkQP29xAF2dQIqRGrgYIXLO/dKynf5SzxXo9/UCOp1qnqVxCUTYd2DIMxzotE3w==",
  //   spl: "U2FsdGVkX1+LBNj4qEzz3FEiJkV65DJg89LmDxVsFppcof+zW1kPOu5A1sWxZlM8",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "300",
  //   abilitySounds:
  //     "U2FsdGVkX1+bxjz8u1BinkBdDFOXqCxISAV15dXk5P+GZOkwjXHSR63ql/aL1N1FZ5OBBFPrW5nIidh0euYgepRvIMhv0Oo4Por4GzDLVtT+T3ARor5/R87IPNZOp27q",
  //   abilityText:
  //     "U2FsdGVkX19VXsG+21rQx2z4Q8ZG9jkgofEnbId4SU44qbyCcJvVxhGWxdEBGXroPtwSuERJZhY0pPe+erSMEycQTipUhoXeJohXJJbOcXA=",
  //   gods: "U2FsdGVkX1+cpf5eOl7RIlc/qp/nex/uNR0dB1LGwk8=",
  //   quote: "U2FsdGVkX1/cG8I6GGSOuUCfor7P4ztR1idvob94dcdL2G0aro2b2JLRIvC1EJLa",
  //   vgs: "U2FsdGVkX19wtUIrTjSNgIIkGMxN9Qf0jOP4zQ1bj3+tCAd3UuUti58keB1b1jUgYrV1sW88cA/anQgHnkxRsg==",
  //   spl: "U2FsdGVkX19H1/IrgiJBc3tYzmZq+2x4vlceQWoRAIEtRBlRp8BHvRA0H1dMm2d7",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "301",
  //   abilitySounds:
  //     "U2FsdGVkX18ybZ9lCW9UdidERYWVC71EnWn9YaS+OcD0qKzP7+AZy48Kj2g3jwkewnQkU09IKzkfJgtnSmSBiqLQroKa2vCY6+A3VEBec/4=",
  //   abilityText:
  //     "U2FsdGVkX1+IDYqWe+sH8TCSGVqnYqp5rDaTm/L3NO5CTNabJcuQYE0foTApO1Zi8dS1iTbEuLBXFS0MiEeokVYllDEPwNObcv1cco6KQG2nsYKxpI3KmkELmp8UqtBa",
  //   gods: "U2FsdGVkX1+Cm9afLMw3H45yDeaIdXBHhVCS5R2JVpw=",
  //   quote: "U2FsdGVkX19FGAoTbYdvDbtAk3+IdGks5/+NAcJJSvQjwLwbnYrm72aBNrLkzCC7",
  //   vgs: "U2FsdGVkX18tut2hJLPt+iXTlRNV192xrFuo/Nfi13nDP7OPdNvGA9IeiiilRoxF2F6DyInRxfVRbGaY2QPIcQ==",
  //   spl: "U2FsdGVkX1+0bk3Aescv9wuiXnj+hD/K7PMIQE+kJCxzXcRrq6UmkrExbwLcar9x",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "302",
  //   abilitySounds:
  //     "U2FsdGVkX1+fdtBv5DW6UHunWBcwDJmyEiiwR/SqB+Umdy/l4ewugO/op8s3WujxorsgBvEmqVZKLmd6Y+8UO/kZ89dhoZwH7V4h2Ec9WIY=",
  //   abilityText:
  //     "U2FsdGVkX18Gs9kFWYNYGIy6ZG9vqV20sWHMh5hewpxkO6c7lDOSlGEXl9SodZf5Ot8HCRJSVKEol4vFMJ+LyzGaiwdxkBRJ3gXHirmmz0c=",
  //   gods: "U2FsdGVkX1+HOzFx2JSdlrNexPWWJSC0BRisBZRxb4I=",
  //   quote: "U2FsdGVkX18VtrEeV6fRDU/jxkhrrRFgynLlKyNpvg1NOAUtT5g077bBug1LiVMn",
  //   vgs: "U2FsdGVkX19LXByldarAR3id7/dhEH8m6oh6q/pZTqlF9dcw7iPrskkzpSoeCMB/Ni6xRYtlkujFQJDhDuHojw==",
  //   spl: "U2FsdGVkX19cByMuJRtSflygjcr+IzgsuY+spcZn4l6JxBQ43wre4xP/xQRVS+BT",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday
  // {
  //   day: "303",
  //   abilitySounds:
  //     "U2FsdGVkX1/VBywYOBAp9GZaDR6bynfEzOUa52/o8c/w6+85MTykt30UgGb61DBRD3LDRqMzhkFEUPqhVPGoe+H0HA3XdbLSYzBCWriewWY=",
  //   abilityText:
  //     "U2FsdGVkX196obSeRzc7DC/QEm862Lox53gSlcacAjNU0BMT3C7UV/HzSbvaadxUADvadL25lMpqCoYzz9aBcQBSOR3lKLboSOBtmmra9Ew=",
  //   gods: "U2FsdGVkX1/HP2zqyc7vs7P+h0WopwZ9r5yE/WjUxWE=",
  //   quote: "U2FsdGVkX19MTyVHCmoPMQq+jypWlegzjAIZS2Yf5OFZ1PJ7SCxLrI9eX5/cOSi8",
  //   vgs: "U2FsdGVkX18UV1OuYM0BSxbSTqEjGYLRAhMXXyCqebHW0+QirPrlYrp+0NnDGREl6+jkI1MArZDwx3opyLcnmA==",
  //   spl: "U2FsdGVkX19IqCwOGrhRT04w9ZMHTsClBg5WhRbcFKVjTUhlwZ8s41CLpODUMEml",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "304",
  //   abilitySounds:
  //     "U2FsdGVkX183Q60X5sBnBl2fD0zNXCKuK6uZ6rEAKE3AI3T0rT2X28I1GHMzpJsuRW+XVhdhw1Ouy+xmi473kxJ6wSgFbN62EVHUqGBgwzQ=",
  //   abilityText:
  //     "U2FsdGVkX1+2/OifXobixxup54g364uvyQdIVV1XhUTzPmzeuOA6RNGMwPUjcmyZt6QeBJ2I9nKmCn77r+TTmlQkiaVNmtnEkLmLiufZmnQ=",
  //   gods: "U2FsdGVkX19F9wPAvOPtkWI4is9ueIBHsSUOmuPRKag=",
  //   quote: "U2FsdGVkX1/AMV3ulc7jlqeFPHh+rVsxj4AdZMuTp8cwc50lp44vUQ51OXGSwutK",
  //   vgs: "U2FsdGVkX18PdrsnAF6kxT/sz/T0/f2iBhP/pRMw04ixl5+Fks5iajFFH/uu5EDD6RWTDhpe/EnI/BvXfXAM0Q==",
  //   spl: "U2FsdGVkX1932piWsnJuG/2k0TsXicsMFXwfUccZRblxQY06cW+whvShJRWRLhbg",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "305",
  //   abilitySounds:
  //     "U2FsdGVkX19U8vdMXST8Gkx3AKGIx8ocJHxuwuBxBpAYYppGRlE0AAw4AtTVoc7hyrZvGguaEHHADeLMloPCkl5JwwHKLwMa2Fjcvh5LvJY=",
  //   abilityText:
  //     "U2FsdGVkX1+Kp1WVGzB+LSnxxwHayEoB8M3J7IIdG2mL7LwRaglaIsdZaOiOJMuAGTw7C3pMMXLNfcJzTT9cR4NKjBvnW4OThxGkPAQhSXE=",
  //   gods: "U2FsdGVkX19JGOvDLbZECjrAShqiVhZLLaqebxzKQP0=",
  //   quote: "U2FsdGVkX18EgrZPeJlnLlq/ws/tA3cEGl+29cBsgrw1enmusbpwcfopuccwVvpc",
  //   vgs: "U2FsdGVkX1/D76F4lR73QoqnkVHzEryNNuNWtmHB7wtmzbHU/94Df3I9God15oLleg6TZ7qk1743MK0ToRDREw==",
  //   spl: "U2FsdGVkX1+cPNNNI3wlz4ScbaJBFEG/ba7ReAu/IwPJSX69XCMm+HMyCMqtn6Y5",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "306",
  //   abilitySounds:
  //     "U2FsdGVkX18rZnjwyo1jxWg9zQMbTCIZKp2rp3yaZTQiB0On7mFMo9am/m3iyUg+FGwWsb/RNktKJWr2VTeLHdFojYkKuYdmpOvBHo+chio=",
  //   abilityText:
  //     "U2FsdGVkX18HhlSQulvaDSbWV5clu0XAw4FQ//eYQATSAFagQVokFr+xGcVG0x0IF6dO/NCy7uoFcco30yTIJls4R7q595Xk8ejR3S7XsBA=",
  //   gods: "U2FsdGVkX1/D6Xh/AtEYVYh3GsTs0iX9+zIk9WadTEQ=",
  //   quote: "U2FsdGVkX1+VuqaWteIeB6LuCrNXN4YG25BltUAumZHtcywVQojqG6LHmK5ijbZW",
  //   vgs: "U2FsdGVkX1+wHzAM8tzbbCAwapj7mL5prxpuSm3pmv87q/0o555r+JPonzNn3PaaLITSiiXP235gIe+yjMG/MQ==",
  //   spl: "U2FsdGVkX1+1QhpmO3WjlCFf78QTnBUdiusLLtlUA1VNjp7wU6kWFFsGZFnS3/7I",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "307",
  //   abilitySounds:
  //     "U2FsdGVkX18YH8VX6AufJi4J4vNwcHHhSMhbRyf/oTmV0HmTC236K8YCm9JdHy/EMIpRl1vwFYDVwEw0pCQmRvEDq+5/dl07003LQl7LjH4=",
  //   abilityText:
  //     "U2FsdGVkX1/VUAD292fb4ufFNxlzmVBxfFbIz6Bn5Hx3Y8dpfZ6KRsviH11ZIHnYBAass3he/r/VFjI9jaJ0GyLwEPywLiTIzBjX184wROE=",
  //   gods: "U2FsdGVkX18WuZm9+Nctm6/ko5HbUiRmOiOL3lIgGuo=",
  //   quote: "U2FsdGVkX198/MeCFmCLyJLm6ch5Z2p7DSpOLgR6sS3N0zESeqwO6TK7y22jPVEu",
  //   vgs: "U2FsdGVkX19Z2T6+wt14z8XNiWkOyRv1hxgmR90gIYAmxDQH5cqahDxEYX7VhlWxEqhvYhy1hobLOg3dx85XpA==",
  //   spl: "U2FsdGVkX1/sDeQInGg3rdNTCg0bHuh5P6Du7ccP1WHddA3X2BqvfCkJZKhZ9Ulw",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "308",
  //   abilitySounds:
  //     "U2FsdGVkX1/5uAEetdmrz0h4EqR5ZaYnXd0mVbXuam7xcPI7+i+M3mCpJeODnWqUDHvnov1nGOZA6fM8PJ0TqZqPxIpskUpEodmDNW0drsBeA7WhQar2WvTiBU2mDfQQ",
  //   abilityText:
  //     "U2FsdGVkX1/Nv8a1kRdg4ie3gjHMaaH5tr2GFej7KVquOF91oyyjIjECJlCv9SOc/I9KjPmW6+w/wBJDIpHC3H35pRFOBcf+MnGBDNuMXIY=",
  //   gods: "U2FsdGVkX1+afFTCAFdD43+9IDj86ICCB/jX92nKz5s=",
  //   quote: "U2FsdGVkX1//gW6zGE4G1NAvnES0+2KPCIaCzivg7FCR4g4NIdtEK63Df5AIPp8g",
  //   vgs: "U2FsdGVkX196k7ZOeeaDrHswzsMJBYvNeNHD0QfPEEWHq7dehmE5YbKr+wb/OWNRZ+7/57OwZRRs+/9/QjZbng==",
  //   spl: "U2FsdGVkX19cGUeWLKEP2TifubZsknORdfDsuMzYuto=",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "309",
  //   abilitySounds:
  //     "U2FsdGVkX19HSfjgbWx0zVsr1hIL6cKf31vDDFh4NDA6a0h/f4z+rCLRffWfCiDhx1uuTUnJ5cTvT2PLNIXc7QpTJ2QDAuNV8FdtqIVPQomGoylCIUq6nkjjpMV2YHXE",
  //   abilityText:
  //     "U2FsdGVkX19tOfja8nL3dHaMNcvpwL+i/LeiLVxOehnFWrHPbSs9trKkMT1LRQs0D514rod8QxLdswYIhNvmzeRkB8/YuLbfI5t8RBOhqaQ=",
  //   gods: "U2FsdGVkX19m6tQIIOWObMQOChNHDXTIqVQKZxSTRlA=",
  //   quote:
  //     "U2FsdGVkX1/XZ2kPhQR30UszVTN9M8HRmtJbYa0cM/5zRXwr9q8+9m9jCIYa5wShLJP4fuLFYOgY4Ww2wXiCJg==",
  //   vgs: "U2FsdGVkX18NgRCaQW+wsahhSw1cgNah5f1HG4asI7JIkUN/oi0FVXt3fcvZAqu3p0CBxUcUm1gK66O/0kEvLw==",
  //   spl: "U2FsdGVkX1+B1InivnxXYr3jnqiTgjQvoINlFn345d0=",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday
  // {
  //   day: "310",
  //   abilitySounds:
  //     "U2FsdGVkX1+9l4drmCtsOBsJuNZDe+6MSLlPSUshKbJgvUW33/iNlMBV+YDyyMdqT+EaQVAmR1neSJzBUZdUwJ0WQdiNU6RLbUyIjvYhWpw=",
  //   abilityText:
  //     "U2FsdGVkX1/LZSSh6bdAs9Wz0GAzzrZghEWyQ1TV/WvVh0T08rXlsBJw3cSSxI6FK28Ii/b1KmPlYyoV6xVOW/gR1nrOUM13q6oZIg2zgXM=",
  //   gods: "U2FsdGVkX1+5OOCRbdPPdHmm8juPiOaoWciwL+0IAlc=",
  //   quote: "U2FsdGVkX19j6TUdo9dHxhZMrix6aUC/1HfWioaH3RIe1+vIVRTBOX2EEpkmKvxM",
  //   vgs: "U2FsdGVkX1/4QSKehSZHjWPtpBCQwyDcmgkEvw8HOzarTZoUa6Ic4nHCr00D9/JjWdHgbkMV740UcziB959/og==",
  //   spl: "U2FsdGVkX1+5Mbg/mjbT6kAn7FPKcezY1HDdOjzzqk0CFzXJhxn9erahPZ7V8kdg",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "311",
  //   abilitySounds:
  //     "U2FsdGVkX1+05fRKq3FPiIQTe6a9ybIOm2ms3yFROSgPxgizVuWviEHz4h2I9zfS3lCDfYuv0m+RdN4LSpOkWMqd6JBt8ugmA66Fy97Pm0t+ZTNWeLoYFBrvahLM1+Xi",
  //   abilityText:
  //     "U2FsdGVkX1/WEEPhXPFU2lb21Gwl6h9zcjbdMygacqvDSsNnmmOR2yZ16uW3JFz2TS1+x+h/BH1uBprISj2KABMdCyOApLTi5NJD7OAjYpo=",
  //   gods: "U2FsdGVkX18bNeb+5DiO+LZAdYYZ/jz7I6RsXYlLh1Y=",
  //   quote: "U2FsdGVkX18XVp9ODU1ejsy3KKMqRm9e5PVjyq7jJ/Xd+fFRQTPlnV7ZmOAapCKP",
  //   vgs: "U2FsdGVkX19e6v4TeawN1b2mM2/7pwhsd8UhGULSUp7udH9JFRPiQV9VmDa/EAnIwvbrqGJURYtAO4H1NpHXWQ==",
  //   spl: "U2FsdGVkX1/FblVzzPVA5RaSrAdwF7U3KVby/iOYjT7f3B6VxVyLVUiY6OAgkW/u",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "312",
  //   abilitySounds:
  //     "U2FsdGVkX1++ESEwB6jr/tB18xeAR76NleF47SZ8T/2jL6zbwqnYlgdI2DCMPjEjyCsNTcSheMqTf4S9RSI31E3+Q86kW0XH3rCX2yuV2hA=",
  //   abilityText:
  //     "U2FsdGVkX18saVKdkVh6UpUpdPhqugp+2M7Q1dVCx6HyawHr3OdlJIi7Klw2+MD7LggtK0pvf1cTurZjxL+mp39DGXglXUMwDSx/P0HhWM8=",
  //   gods: "U2FsdGVkX1834MXJWfoFpfktmYONkHHGVKS4+7ldbgI=",
  //   quote: "U2FsdGVkX1/H3ZdzYKYlm8w+6ikGE/xhdmMN3YlhaENnPRjyssNSQfL/+U+2o0bc",
  //   vgs: "U2FsdGVkX18mh8jzwWHNL0P/mc/G+pkzmEsH87wcu6fUw8DooSas0RvKbpKMO1a97G3b+IIWoASMTs4mLpEUmQ==",
  //   spl: "U2FsdGVkX1+s0diT1tEucEkKy4CPnlhYdwNlPIwrWhp72WrnofL60GdDL6ULUq2c",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "313",
  //   abilitySounds:
  //     "U2FsdGVkX1/5Ie9L+1LoeiY059TyWomzL/AawvFMrFafhb6dWI+k/EeUNEXHBmfpljnXQXhuW13iltuOU8bB1h3uACDLDhG/YAv2HqC84XM=",
  //   abilityText:
  //     "U2FsdGVkX18TVsEa9rmkgAN4uM2DqzO0/KIUmNhO47Lk3jz+GYVr5ssswaLogrGLXDD0Dplo3eTp64ZFGcsy6GDFa1JvS0fgQjAJv2QW8vY=",
  //   gods: "U2FsdGVkX197ZYyYoCU7Hv6lyH/Ac63LUQOCN3j6Gt4=",
  //   quote: "U2FsdGVkX1+g5NWR954mDLtnovwUqMYsHbnbFBN3DLvfqiPkQVytjg7QKW4tWOLa",
  //   vgs: "U2FsdGVkX185/3yTcW9j8fHWwH2adycHv66ZDhSKp2thy6fVjE/7ivXn/rCqhIiG",
  //   spl: "U2FsdGVkX1/OGQsqlhbgtLW7zwMGlA0AHwZgmYstg8RjYHhW7gbVX/b92HFcxVNA",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "314",
  //   abilitySounds:
  //     "U2FsdGVkX194ljEKjGW5alG/ItV/NViVCyqJ7xc639tgf+C04WoOrVlSLax5As8zQlkT+7vQuQO6nT7/tx4bToJ56kDhwfWBym/LGMllKYE=",
  //   abilityText:
  //     "U2FsdGVkX19fzZv6HbdaxAapvSTLWkFPlArNc1h8SuvhrPdBkkTC2b9egQnoMPWCUeN3kVHPG/Mt4PfHZV9pIUbH8RuQdqNqx0hOKSAKumU=",
  //   gods: "U2FsdGVkX1+Ou9D6lWPV11lvE1o87ixe4SBQUo3ysHo=",
  //   quote: "U2FsdGVkX1+KUUMYOgj2sHDb4jGKsgItLOjL3ldOVwOUM3qgoz6bTbqzADZkgxJe",
  //   vgs: "U2FsdGVkX1/7ugoo2llgHAAV6AHcX1NMYGLbHhXPtGnySkCOBJNEJtN+CKVtRLGDmSVnGdie9LoV/ZfasEujWQ==",
  //   spl: "U2FsdGVkX1+w5wf+esN79nWK9evJXYc18pzPY51mLhHJOJJO4CQTyWXU8nOv6nuR",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "315",
  //   abilitySounds:
  //     "U2FsdGVkX19PgnsfUtyMd3M7bp7egK9cvPUJmNIpUe9PAAk+wQ9Hn6N97i9zaMtREeAHDSAfcRy45QZiu5W0XMsQ4XtgsFuNazl306+Pscs=",
  //   abilityText:
  //     "U2FsdGVkX1+AXkzKVyYWO0DGSHTuPyvWu3MJ9PEYmURvCao9TfW+8EzFr1kV+bX/fcyDacoNdWUifrYcGPxdKt7aeoiqksA+uJaFzekiT6s=",
  //   gods: "U2FsdGVkX1/R+66JfVH1DHexGb74VDwgSIgtJZZUPG8=",
  //   quote: "U2FsdGVkX19uq6Us9h26PtakTWLPFiTiMgFUCIN/2SKACl1+LDImZt9bU5cgmjE8",
  //   vgs: "U2FsdGVkX1/DOMVuDeo7rtrZBwsST1K95lHYWlKleeR2vlKwrkQ5Teku0oisxjBiFlv/cosi31cb3+/tLa2TjQ==",
  //   spl: "U2FsdGVkX1+9n3e9NmHMvil5bCQQBFcG0k/pplseH1jVbJYBsZR66gg3/PIyvTpd",
  //   feud: "",
  // },
  // // Sunday
  // {
  //   day: "316",
  //   abilitySounds:
  //     "U2FsdGVkX19rqUqONEw31utT7D5XdUjXHc2rwe8XuI+6Lz/Q23+rtZnLohY5OBlA18HnOVsh9zOO/JVoCXR1EA5KkPo9o1187mhuXHL/gPM=",
  //   abilityText:
  //     "U2FsdGVkX187M3GE22tSkyFnlbWPcEgj9mWpKTArLfwCRR4INpPIONqUGlgdrFuz12YYy+tXcCFXwDgLSy+F5oGy0NylwibStZTJ820wBM4=",
  //   gods: "U2FsdGVkX1+El1Fr7AZGypJ/lgCY2gR6HPtnysIGjFE=",
  //   quote: "U2FsdGVkX18Ym0laj30+g8fY90B1PElvvEaLo51poWWZUZQe5K+/4T/GO3EtHYNV",
  //   vgs: "U2FsdGVkX18/uYecCsF7EoIbK9knmGFjK4kh8ZMdzteAJIYudFGx9Volwkz2c9vOHq3Fjnpq1v/3yCQ4IOnlJw==",
  //   spl: "U2FsdGVkX19wZ6w1Ftj5WyXgTKAc2QgZ9ECIHEnQ6zGBql99QC/kcCS3BkfJpar4",
  //   feud: "",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "317",
  //   abilitySounds:
  //     "U2FsdGVkX18ZWpj5vRce8YCGSOiGyIZSZRoGIXWaAF4V9Sy/Khth2Z3pwkF1h8x6DHsUnRz2CmJLlcT0EON/TfjnMItQKCh8QO9pe0pIYIE=",
  //   abilityText:
  //     "U2FsdGVkX1/sgTloXmCV8eoA0GlLcNV28L7McbrSvjhRX5EwJPLvn+cmXsURTlRXe9G+8v0i8c9gX+oyu/jJKgCGpojJV/yh1a0vhKJ1lIE=",
  //   gods: "U2FsdGVkX19eb3FhtsBnpHpwS0vTAZu+gZ4mH1+Agog=",
  //   quote: "U2FsdGVkX1/wiwfDS/nPJvu2ObeQKsZhNVj8NqeDFsq6AgwAO9Nov7U5AK3FItrg",
  //   vgs: "U2FsdGVkX1/VFCk168p7Fj5v0w2HHiBmvYMfOKKwI6tPsy7sG5Q6wvi0rfKf6nGFudYU7E4N5CZm1KuAaZrUXw==",
  //   spl: "U2FsdGVkX18ISheiTpYE9G/09Q5fCHh3KdEgSiLV2xEKJ05RKk9dJ4fAZexMaJra",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "318",
  //   abilitySounds:
  //     "U2FsdGVkX1+g9Q19YwdoELsh7a0KytxLtMj+bYW3AZESYRo+F8EMN3V54GN2GVrxiaVds2cpsWmSvqrLnuaGRfrqPbIUf3pu0zmRxLijNbw=",
  //   abilityText:
  //     "U2FsdGVkX1/q+kNChXi2xZ23Ae4hfI2Vj9TaKR1Dop/Bkwx5J9/bth96+U+t6bo1bqYYxN9BoJqwjlrX6SZmlS1Q7Ym2bhgNylh1BSIpP3JyY2r5neikF/+zaxzcas0M",
  //   gods: "U2FsdGVkX1+h1TCthxcpoQ2WynDthpVc50S735ZliXc=",
  //   quote: "U2FsdGVkX19zBLOJmzrXyMRHk2F+MlZFs6mqQWzIpOcPXzh8Pu/vSIsLP65ExTXN",
  //   vgs: "U2FsdGVkX1+Rzk3heBicGfKioP3MBP5VFi9dNrS6rWoYmn0UXc3m0TRhmJ7mmDlf",
  //   spl: "U2FsdGVkX1+mapG0XU51326yrQ7h7bkKlTyTJVYi8yLFnPNJHjV5mQ23fWZuJ6zD",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "319",
  //   abilitySounds:
  //     "U2FsdGVkX19u3XX8rm+KOcGAZ4b1af7uH1RiUDeDlOeibkp5ejkrXLMD3IUwy95Q5Thv17SV9XLaZ/FNyUSwHrysF6qglkTGSlm8CpBpmp0=",
  //   abilityText:
  //     "U2FsdGVkX1/j3qTJL0dFOmnHWRfnmVexh9MYXGWgsKkenNM03C3YA1DTUfLNvV3IEQgaoXFHTWbGqYh5pAIml/eK/v5q7Yk4hxVaog5e49Y=",
  //   gods: "U2FsdGVkX19aQyLU49r9P8//BTxdvKUrAadaj42F/P0=",
  //   quote: "U2FsdGVkX1/bnZUbTxl5mqt5TF9EhQeELoQEUPa16gCpU3/ZiXMDQuYzlX3jj2NS",
  //   vgs: "U2FsdGVkX1/lPtAQRIqWVZxdbvvyKD8Q23VlZrwARpnehJJ3nJh2BGB23lguMgBc2YfYBpdbLMFNo55oDM4RyQ==",
  //   spl: "U2FsdGVkX19F1S5wiPezaz3oDCCb/mLkkdWB1zzYTPGeeG4sDIlSrXi9vy/snPWF",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "320",
  //   abilitySounds:
  //     "U2FsdGVkX1+kJNThcjmvUaLqViyGmvtjxawwg8CQxT3e7JkI7rzzKyNQmz96MB7fN3BbCNOqR3OLJEjPOBZGEYcaOVd7FDqeScB2KXukW6k=",
  //   abilityText:
  //     "U2FsdGVkX19ERa8nyJpJvCU9uGHpa0pxpjisUKNsNZTYflS9b7Map/lq6lpmBeqygQIbM2FMC53DB3ZEVp8YBuG+2QbMxKiaY8fTSyzCPzE=",
  //   gods: "U2FsdGVkX18xrklEjG7FlnoG7UvZgBZL8W5Z88tEL8Q=",
  //   quote: "U2FsdGVkX19qlhFnZfVp8ftjdESK7yiiXCN6IFvPeLjE0z2ZaaGJ6XMuCw0oQk1V",
  //   vgs: "U2FsdGVkX18sdbnDW/jTUnqloDpyoJe+UUMlICkb1KcYVxPhYmxLe9KqXjFyKzScvxcBPswuOebkuvJ3XJeGMw==",
  //   spl: "U2FsdGVkX1/zNWwJ6WIeW7PMvwkFh8x5qmjtfVh5kzYGEVLYh7NsOf4Ym5rl9YKT",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "321",
  //   abilitySounds:
  //     "U2FsdGVkX19ZwM1gtputufuo0GEPH3BVxs4/4rAKwNqKULJtLjnJeVHy6d+3NFUyDL3QubzdTlEYgqqSH0gTpEaScLgDuhWk5RkWQ9Yfc3w=",
  //   abilityText:
  //     "U2FsdGVkX1/ZBmPXaLcYFmazigc33K28rlcDVAL92quWS0qT5VFdKoBA1kVf8QKxRLXNDyLsUeq+VF8yBr7LldU2euH/DsKnD7CONrCoaAU=",
  //   gods: "U2FsdGVkX1+EBnwzu1mIY5CAG4BschYZA+gESM7zb8w=",
  //   quote: "U2FsdGVkX19jQN2b9XP53+UC9uc0rXciFeFiAJYnOemigPogZCx2299brwf2+P2c",
  //   vgs: "U2FsdGVkX18usbZBr00wn3VgXgViWPFQcJdCrY6l9VskEDvi0cKYV7O97OtbUI+fEPo+N8cQ03ym7vEOthr0jg==",
  //   spl: "U2FsdGVkX1+0dYgbwYt5BZG7UnDaAFNFs2xxdAXN6Y28AuPq3kYWfdFw4/7XoaPQ",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "322",
  //   abilitySounds:
  //     "U2FsdGVkX1/+TrSSUuQllzUk+7cHJFcJYBE+5f0QnFcMzDOadyzWCAYOLkvz0wGicxneXKd/yNvhxKZGWuyw42KvZlFNL1P6s8kEKkjGGPw=",
  //   abilityText:
  //     "U2FsdGVkX1+eBMxF0MXv6IZvgGiN2N05TuEcPtzab9AYbCpR9sMrq1mTIO90++WtLM8OskHeQneb+4U+dR4t1LfWbTpPR0VXcMS46C/sWok=",
  //   gods: "U2FsdGVkX1+6s0X2MvdKe3foP9jhjCMUZiJSJYfQbzo=",
  //   quote: "U2FsdGVkX1+oxgg97vVJr0dOEN5IZN0OuO9v3MK32eFm8yQscRBUzNhSoo7jdYgi",
  //   vgs: "U2FsdGVkX1/ZSaGPmK65q6ZGJmsNI0vlaO8xFzFTGvurqLeENjz6SUZAIpMC9TuSrG6P8D+4HSHqAYbl9cE7Xw==",
  //   spl: "U2FsdGVkX18CZ9WZxk35nz/aXjlKx+VutL9A/IWa8AvozQsh0tqJirY6llxupb1M",
  //   feud: "U2FsdGVkX19hOjEoZPFstSTXFG4THUagLmsFj0Ev2Bg=",
  // },
  // // Sunday
  // {
  //   day: "323",
  //   abilitySounds:
  //     "U2FsdGVkX18fNWj3/RO6pkwsze3WvOv9iIRmyknbdoCFlYBknQjgKJ4zFMyqQQOKXVhxQcrgk+gSE5Duo24d2ac++Q8HGQCuioo19x5GMNg=",
  //   abilityText:
  //     "U2FsdGVkX1+54/vnHccScIbgCZNBpy286Vlz7beACL4Y0klQV+AuSavXE+AXsLLtUgnZG04OFuTwZmNmFx7S55JZOwnZ3to+XMeO2ByQ63s=",
  //   gods: "U2FsdGVkX1+CaZuv8OnvhMOSmfM4YCplA6aJg1wepqM=",
  //   quote: "U2FsdGVkX1881SIet8qjdlXiT5IaammSXStSKaEqM3qCyiQbqLn91NfZmzwCQ6l5",
  //   vgs: "U2FsdGVkX19TvO8XYR1BuQkBdNjfG2uSI5wSTGvlc9b7B5G+NaI9SOZixCL/EzMU8cDe5ykzEBFdb/Ddm5JWYw==",
  //   spl: "U2FsdGVkX19cQ7xE7g83ecJiGGf0IcctZpCc4+rtjOxRYBmH/ZyJJQSXD9Epe3jX",
  //   feud: "U2FsdGVkX1/ZrNh+PKoNVDuLQ8kKGrwk+GcqlzmFY6I=",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "324",
  //   abilitySounds:
  //     "U2FsdGVkX1/RiJ3pV6vXh2PeDkCX0+AJq619cIZtjQMaDMyyXbWYte4wXcSWRbsi7i+75xqve35JQG4SxUfCaVhI3Q2gv5fCLObvTdVOhbY=",
  //   abilityText:
  //     "U2FsdGVkX1/u+mQ6hncj9i9ctq/1/SYS0hnAWYA42kViChIYrcpqG8a9Xqtj6UgvnuIMcA+obzFwUUWeJIt7EA==",
  //   gods: "U2FsdGVkX19zABLFDDhQnMJY35un7ECBdt3Vlda6Ml8=",
  //   quote: "U2FsdGVkX182hGayTZ2V0crvpv+2icWk6K7WWavakZEiyz9DenOOIysrzxpXAxPr",
  //   vgs: "U2FsdGVkX19BTSVE1NQQo8KZ17aBqNpADjty1QseEj6j0Lsw+AeEYWY65kyc7VkKEY18xLD1/7O3aeMQtRpf+Q==",
  //   spl: "U2FsdGVkX1+XDdG+V4prFiN/zrjbIXMaFDhEL9hEJtu6+cCwA2+DBmAmpKTkIHVj",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "325",
  //   abilitySounds:
  //     "U2FsdGVkX19q9l+wEhaBXdRzboPG9pfOo9MkHmtivdKai6Zj28SOxz8tv79OMNi8dWnUPkF0afxKjngCaohMxkHqkIWpFkWZOtlNuhvU2tM=",
  //   abilityText:
  //     "U2FsdGVkX18wsurgqH0L6QowDKpQXTf8qTR3HjWxXBnaX4qGvvl8WVkYGdz7sMneZZJGRqIoh2Lv2vK62d4Jze7Rv0BS6tUJiIOgxUwufhE=",
  //   gods: "U2FsdGVkX18+jI5H6Ko4N4ta7ImudOYVqKtHoc6qjE4=",
  //   quote: "U2FsdGVkX1+Ppo2/A6BOOwQE+6YiyhJcZe66KOM3ox6b9FKGr+2y8pYI8tMkSfNv",
  //   vgs: "U2FsdGVkX1+bY62qu2JKDyhAUA3j+Ph1k3Q+xFJMIxZG/VUxNGSo008yY1B79zlru2wZaIw7983awAS4GprDcg==",
  //   spl: "U2FsdGVkX1/hjQu1CmG6WaVLFglc7KHcYU1AOEzAX5Qg6ANKTDO3sKw6dni00A8X",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "326",
  //   abilitySounds:
  //     "U2FsdGVkX1+7jNzK3OglGv1zC1zkkErS/XSIyjoBBAWgcolrkcKrQyf0DWk84ErER3pEhuvQ3egP3Nee++f8SWPy/PlPrlDkjZ/9SMx5xkM=",
  //   abilityText:
  //     "U2FsdGVkX19wWXV+vuC85cOtEkg7ZHhfbHN7hm1Pm16CQx+sb8wM3MYdbDQgJ2uKupuWP6SRcnPppXzS3hnoniBnvRWW1MH3Tm7THOzGc9E=",
  //   gods: "U2FsdGVkX1+AYrhdNXl0b3HNT/FUMrp4EqGO2YKuCWg=",
  //   quote: "U2FsdGVkX18w+rMgApUCIyNbbijr/nW+o0B2WIYlMC8rYxTitCWrEsVWHq9hSajX",
  //   vgs: "U2FsdGVkX1+P6GDUce+pEpvl7PZxbIh/cLJAT0SnFAfi3vlczp4hZph6g/VfM5XQ",
  //   spl: "U2FsdGVkX1+HtsC79TFgnXfERCeAnG/hEu9MkkaLBCv/dFmTIJ4nrG0YjIiYwnUp",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "327",
  //   abilitySounds:
  //     "U2FsdGVkX19/xsEaNqlWqZzXB01s7/ss+qV8ckUpHhUqsAv2A+Gu4+MqSOabZnigUGpiGTBrJ42VEp7koKesgnINinAufPr8cZTptcL9wOmlZDUiWVlYW2bpQLFWwlVL",
  //   abilityText:
  //     "U2FsdGVkX18GOVfnTOiZd1WiKO2SHHCFVVi8+np6ZNJFUrdRoJ9FXz8GumakSE2xMgT6TzQFmNkeDYU4IIg9gJFWK855SuhWUuIvrIUUktI=",
  //   gods: "U2FsdGVkX19tRw7oFefGuI92liM5O70of22DQEZhG60=",
  //   quote: "U2FsdGVkX19IfGoTwgBCDgVzwY83+Dg5B4okIXlCxc3fH8EP1Fj+S2mDjl7VJbXL",
  //   vgs: "U2FsdGVkX1+BcPcn4S9KBxQtvRGLfWmXL6K1v/viegVmvuHjOfTG0LgN9ZHUcze7PNVZm6n3ILDYGiY/z8ErWQ==",
  //   spl: "U2FsdGVkX1+XvMvP718928xjMVE+K1v6EN3uSH9iU2hVCABA+/NAm8rn1a0Sr66A",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "328",
  //   abilitySounds:
  //     "U2FsdGVkX19aPG1r5mDeAp+mjAJjrw2nXmNKvo+1shfzu69alje+uLKELJogODFTkGsKJYH8HI7ji0IU7aUnjCyf9i65xEUhdmEvzjWLNYU=",
  //   abilityText:
  //     "U2FsdGVkX1/zBbYa8qbex7m9Us6SGL6DXe7R5J3cyL5TdmRHT2v4C4apRI5+tqr8qdVcjr8bjCF/TKQ5l3Is3+tWhuNWENWqsVcpuDEaSQ2Rph6EkBy5wpnZ81ncmgMb",
  //   gods: "U2FsdGVkX1/1Q42zxRwFNaPDWwyWBi8LYvgJT/Ko+6g=",
  //   quote: "U2FsdGVkX1+cHwnBb1vGLNPfsNRExjMi31zEEaJoI2iiW1pWplFMkZwJvRqP0HUu",
  //   vgs: "U2FsdGVkX19Vkpq8/EIq8+pVq4llEk/LcVZk6ifZ86oiwv2Zr6pORhN0NSIsfU1hRrqa5k+mV/FZPlGH6CRqpw==",
  //   spl: "U2FsdGVkX18QhsHwEGYTeuhyPcmmuts+CUHbsYZQsqJN3HBnPBDNxq2ALQeHUlBH",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "329",
  //   abilitySounds:
  //     "U2FsdGVkX19M1bvrfeDqagVH8bHp4bPX+e//QzsNuwKwSyi1l94RkCa57qHMMhrnTO5D2H5ud4CMKmYwigdH/h56U69TT4jcjWWKfNOGm48=",
  //   abilityText:
  //     "U2FsdGVkX19IQzWDBahEdhbMB+t5k/YWcoImdrUyiT2RoBf3XMwS6yQZJH+2YM4bf+n+k6Ip+yy6jjY34NR1ZbaRf6lzgHkO3CoppJBZjnA=",
  //   gods: "U2FsdGVkX19UX0zEzN2z+C9HlEs+7zSAEyHOKijeB+M=",
  //   quote: "U2FsdGVkX19nBvLSN3TrAM9w9vQ2c+s0tugIely9Ei40koN7ZvHs7Vv92bTZtZC1",
  //   vgs: "U2FsdGVkX1/Y+af+EqQKzY81vei9y0h8UC/SwWJIiODpra40mU3WMdvoIeXPY4ueEpmJvH6MfHTijrCmQUdfSw==",
  //   spl: "U2FsdGVkX19OgbXmYsfs+LsduIJ5WV6AiPZ/tgk08Ft+0pe4eVvAYUVz2a3RPbYX",
  //   feud: "U2FsdGVkX18+NY9qfixro/BW2zP7ylvjexaU1z3CYRM=",
  // },
  // // Sunday
  // {
  //   day: "330",
  //   abilitySounds:
  //     "U2FsdGVkX1+snJs2zvVICBHdmGeePFXtB8a0075Otd2KWxktez+tSoHlvK1f70PFewscPJO408VvONeSb5samCCVFpGW/MfCsfuWecqL0LI=",
  //   abilityText:
  //     "U2FsdGVkX19BahJ5hnd0YWLIqbEb7CWqEboN8iyNWy3ZjKba9UMxDUKXHqj5U3M0iVrgl5E9jpmMWEbfZtp8HftV+IZFcZUaq1xGQAOainI=",
  //   gods: "U2FsdGVkX19RPETqw5JnXP1rk1TR4Rh3OL5e/GvYAHI=",
  //   quote: "U2FsdGVkX18uHc0saKvFAQntrozLYWAyureX2pkzEqB0Prhla53ikV2ECWFUlrh5",
  //   vgs: "U2FsdGVkX1/dnawWuKAIIy4dLa2FBLBZtBFpbQTJbyUK1u9afuWbZO2ISBVc+Os7aD1iNtLBVcsQF4iJ7aQrlw==",
  //   spl: "U2FsdGVkX1/tpVMk+Bx6pXb9SIMTcIe/+fYNzBmxnWykButfcv73YrQhyoEc+Mkm",
  //   feud: "U2FsdGVkX1+1uH95YhDo89tFUCAVfzGxrPCIOI0ofy8=",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "331",
  //   abilitySounds:
  //     "U2FsdGVkX1/gJIpbRkmeOz1cWVI1AtV9amyAJOuJO7739Ef8sJ81xTszVDfSKq546eNDnSG92y/O+4vcqKIFfzcbF97qukVL7jadSnrtQnI=",
  //   abilityText:
  //     "U2FsdGVkX18F9Uz5riZcU9i9TVVmpC/SwumlW3d9z4vrWt4fo42PXDcR4EkqLD5ZIZ7T8sC+bGJzfNUS8+vVehSE5+o+oUQmY7S9uqObJLk=",
  //   gods: "U2FsdGVkX196uHdIZRkyitArKIZQANq6/n/hlr2OcDg=",
  //   quote: "U2FsdGVkX18J4yTyJNEzILbGPbDUFMnjFfJm+0uHyzeqc/NVUbzH75ZMhe/EKStw",
  //   vgs: "U2FsdGVkX18Kkx+yyag5wlFfFmRlac4keQE2FYkiFxPvUS4HQUC8D0FrX3y2tARxKE8lhq+YrenuRS5xq2l+rQ==",
  //   spl: "U2FsdGVkX1/Z++dDs4z2Cb2cstH4zJUaMN8mfsv1tqBp8lE3kC8HZkX03xbnpY9W",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "332",
  //   abilitySounds:
  //     "U2FsdGVkX1+nKl/DAUpYJMRak/aL0SxU+oWr4IPIMdC5WBJBgC11E8RNbsuIBP1qm35ODZoejSLATNcilm4QXEtDbqeHJMxy9OE9kS1a/ww=",
  //   abilityText:
  //     "U2FsdGVkX1+It+4QgBXc7OJxygd5ei/MFrAI+BvKRoZFyIAfPCahxAtiiHCpVLcPvfnjiRBCRGYXYZ0Pu3IYVjnPbpxWfAMuwqei0FIOYZM=",
  //   gods: "U2FsdGVkX1/us+xvihZT8p+wsfMfzmfo4arQTy1l5+Q=",
  //   quote: "U2FsdGVkX19Tqx6yL/ox6AxJFcyISpnq+bRUqVQPRr1fGp71RF7U0okBo5oIGuvN",
  //   vgs: "U2FsdGVkX19YQ/VGxP0RYo4A9kr/3b0/uw7dCqDfDf9E5ksWZBLsud1Z2xOHQIoGfmE1XGiHH2PUfRYfaitlQA==",
  //   spl: "U2FsdGVkX1/xb7adx+Hx+72hS+0C1/14bU03Bv52+NV9w8NY3DJeFDoqv2GzOVio",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "333",
  //   abilitySounds:
  //     "U2FsdGVkX1+E0fF6deakyRkOInr+JM/OWVAXjRAYCZ9PI7oH/7tCFQRMkXcenC6giNnRcLuJAlBei0MVmsEu7fu9qL5Wo7wDCjVmpoiSFN8=",
  //   abilityText:
  //     "U2FsdGVkX1+OW9Eq9s31KLJhHD5zx53nk1pTAZQnw8H1oDm7J2aMpxu0EAOfkxuGiad423yAGFOtMyTriAP0p//gzgAB+Sp33YP7Cu1bm2VCvL5n3zIUJ8/qu6NtPIDz",
  //   gods: "U2FsdGVkX1+asLYewsoLKNLKCaFtjfgbDDXwxyOmnxc=",
  //   quote: "U2FsdGVkX1/FtsAj+8kCNNrhDhrlitTpeX9bype7fYCXFfjTQris0o/v16FRLpxj",
  //   vgs: "U2FsdGVkX1/f6fRV4caRVyJSqMPzZZ0x8Mt4SaQjzWBCUdfT/Fd68KhQr0q2KH2UrNrj2bOptBAh35PZg5DCIA==",
  //   spl: "U2FsdGVkX18ymzLGPP0RhCkpHWVE/7WczPjY0S7M6nEg8nJqpiqqRvdlw3pb+rcR",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "334",
  //   abilitySounds:
  //     "U2FsdGVkX19BfzxVCjNE8GBpQFSBz8Ka+SWjLYYT4ocXLSsjFOLtUHWTlS31JtOE5j6zyT8QGkvOHinKgYbzI6wGdGUTbjEyLBQkf6tnb2E=",
  //   abilityText:
  //     "U2FsdGVkX19fnvq/646wsmEGiyC4zgXE1+m+L3IZ2q7jkjowob5P0Xw7rvj1VudLPaM+Xp5617ZVjyNW7seO4A5eaWAs+YyL7ScwaupHW/B8TfhxaidgxAmqV0zQWPJV",
  //   gods: "U2FsdGVkX1/vfu1Qx1RqVHRG7a7BI9ngzZCv6gz8RHM=",
  //   quote: "U2FsdGVkX1/MjUJS8aw6V9syO4k4Vfvh/NazV51Vfyb+4YGVdgQJY6HUj6P4mn7k",
  //   vgs: "U2FsdGVkX18kD6dRTJZMnojPhrq9r0FnnZLP9dfu8VxR/zg3VRYeCUXZKHycMhHPmVU1zg0JDtEhNmADVfPLuQ==",
  //   spl: "U2FsdGVkX18vE9fy8MPWpktu9Gola71XJ/Q5huW3WJjr4kf7EyZKte1D2NYu4UbG",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "335",
  //   abilitySounds:
  //     "U2FsdGVkX1+OaVnCKo1XagnDbJ9v9qgh3cJ5eelYpH4cfBXLIWVHFUVRg8IhCOqaLTYTyZ0acorbXyk1jXReMQjN+uU2ut+8pA78K+lRDzk=",
  //   abilityText:
  //     "U2FsdGVkX1/EZC8I4AUfT6JngSClH6bwkbOAeTu59sxUc83PVt8H5ISuAPmF/PNERnZ5xykvAHtiPqiAbq3T0nL+JHAs9Yw2nCi0u20yX/ma8I1i9xi1pI0ebN5YM449",
  //   gods: "U2FsdGVkX1/EA6ezXCM9r/LoDyGBKkmaJpbiVL6E4B4=",
  //   quote: "U2FsdGVkX1/KyBp6n2h342204FGXzpK8mDrSIjiq0kaMBLCn4Iq/FFACFEQiuUyj",
  //   vgs: "U2FsdGVkX1/hlSz8ZrpDpFvC/0dDTfb60I4pG/5GBWY0s1x2uKFl/TL6JqGf5mivBK4HaPd+YMeGnl9RJkCrAQ==",
  //   spl: "U2FsdGVkX181fNLYy21ZLHUrs6GWCp9SQjNqnMkIyVaGlWtx+RxXyShIjhyKkrAa",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "336",
  //   abilitySounds:
  //     "U2FsdGVkX1+gmsCWHuLEo2SFGUTMB5Bvb1/JnaIBrlw7pxIu90aUNKoRoGzGkMHhr+HCY5ZLMPNbyQb7IKuCc4mXT3hakN1dEU7dopc2a4bROQvQGrCCSPpwRLUyiCTv",
  //   abilityText:
  //     "U2FsdGVkX1+qr3bi54pnOmPd6E718KH8vb2ejGDodmeZCquQXZkfZN42u/XaPdlc9nIEQsItwAo9TeV5m/HzG5st2mr7NGGFqFOm5SzBKlw=",
  //   gods: "U2FsdGVkX1+Zy7iGfffG05iBBevF3mifIRhPArPxvVc=",
  //   quote: "U2FsdGVkX18fftRe9g6ep/IYe6HTbtMDe5wPcJ3JJ0McSeGf5t0nikxyflnzhDm5",
  //   vgs: "U2FsdGVkX1+o693HSWxkYA1J7lleujmAQAKglAwCGvk7Yn+G+KdHLAb4M1vqxunJ+hhzTwpA2zluT+nPW3QYNQ==",
  //   spl: "U2FsdGVkX1923PlJKykfu54tZcLgu1KHXMvoV9yWsBrxB6qqHfO/Q1uFxFhrMPua",
  //   feud: "U2FsdGVkX19brWLUhSJsUDl56XP6ZY/1FkuIak4IsCk=",
  // },
  // // Sunday
  // {
  //   day: "337",
  //   abilitySounds:
  //     "U2FsdGVkX18hHmi/+IZBVnNscLI6J5o7crl1FdJlgbsLCnLa7f21RSern+gUfJ3GNmo4CoifREqTyav6iFvxwtMNlXWW1RBPGmU3oAeDXXI=",
  //   abilityText:
  //     "U2FsdGVkX1/MAH8F4NeoVJ2ztvPyHeu13kQ3feYLKDKp87ojNCClCK+kJz+dCedV3eBZrehgMgARHCW7tGxZDHscWHbXvNW6AqmjC9+e6CI=",
  //   gods: "U2FsdGVkX19dOxov5Yc9gcTKfAjfymdznN6Gb2Q2qbY=",
  //   quote: "U2FsdGVkX18ape4+4lVklRj2i/5KJYQwD86KFc42Alrdi1DPC9+vuQ6dFvFKQkDv",
  //   vgs: "U2FsdGVkX1+qo4vmt2uw1SjQnEYATfKeUoBn/LVI9TKT3VbIaGTOa+HUSLXdF3mmuKcYMS4SIwQ8Q268fyXD2g==",
  //   spl: "U2FsdGVkX19WjvzJDf1y+Otj9OPkitjTUBO4lwccYOV97Lh461o7Y+qt4q5bJWFM",
  //   feud: "U2FsdGVkX19YGhmkhvSa131cF7njd5p5Wr7yU5tOBqA=",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "338",
  //   abilitySounds:
  //     "U2FsdGVkX1+QNGp5+8W3VU+LNzdGX1Gt08TqewVQ1JGwQHxWlLSLK8vO8plZjzcOWCvdvC/zEONX8VXA+WPLc9xnwOZXVoORZamNAG+N6K0=",
  //   abilityText:
  //     "U2FsdGVkX19BzO9w4K7oEcWzUvk59B1aBb/ud9y0KbIquq6iwL6/yadmNDbPYiOa99UEviasUx5cf+OUEQ44Q9KMDtiS5767hnJtyqdv7Qs=",
  //   gods: "U2FsdGVkX1/puGyB8BkmcBTLmX9GtJq/YMZIh3yF/Gc=",
  //   quote: "U2FsdGVkX1+Y/hJIpj80lrTLcPzUdOwFdiP/UqGRMpXcsXIOaVPs6veoqA+6Z3k+",
  //   vgs: "U2FsdGVkX1/iBZyfJPtOD5pKs3agH3NOm3JACtZZpClZ+35IR5C532NujLJk3RlBwnI9CJcMsYwEmJmF2QCRng==",
  //   spl: "U2FsdGVkX18I4TMZF0jnNV+ZaIAwWOJsh2qfYENPMYiFMbvPKLjugLPv9gBQ2HvP",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "339",
  //   abilitySounds:
  //     "U2FsdGVkX18/SsNJlpF9LS8jJfXoRPCB9x9xE3b1p0B1Vl1u/jS4GfZcqgrAk3D6okgg+cWGwRALDAykDu4njLfOikb8REwpcbzAX5YY5uA=",
  //   abilityText:
  //     "U2FsdGVkX18mQZg9yTdguh/TYmr2iN4OD6Zo9+uWXraGN2tsINtakzB9iB8CwyP25gfECfeFdxh4yTH9qkEzw3zx8J1b4EGWbuk0+LKI2xM=",
  //   gods: "U2FsdGVkX18v61+o/kDTAj48llUXhtK4WkqY/ffB93Y=",
  //   quote: "U2FsdGVkX1+u3K1Gl/rtmGi4F/WkdSCUqrSFJaO6Np/YWU268YmRGf7YPFPSQ1MT",
  //   vgs: "U2FsdGVkX18NPxQ9ad/4CtkGEE2zwsXOgnEqKknrxG4mQ5VcdAGdQRms3FvO/nI0pvJjt7qMQpCEz1nNlWzeFA==",
  //   spl: "U2FsdGVkX18vsHxPH2chsGTPJ+fc8JdJ9IwF+tWPAuU=",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "340",
  //   abilitySounds:
  //     "U2FsdGVkX1+pnx6ChAF/yojJ22wytQzbE9XO0w21zV2edcWN+gLI3kxGIgynVJtlSpYvJA4QG+U1pl8Ne8BPepJa43/CJvkHNIKZczERuF4=",
  //   abilityText:
  //     "U2FsdGVkX19FmfLpdmBKNqv9sj26H787DcbaDmlZvs7/40Y0RSZAI0Vx7IOay8+8kJwW1FKCuuJjsLf7P7rZBdiMk8E96ZCoTm8xLOnsFCY=",
  //   gods: "U2FsdGVkX1+r04flVh51NJj1xnM2KDutPax7EkOUmTg=",
  //   quote: "U2FsdGVkX193ol6+PhuttrwZLTyl2BwVxr3Zv/kRZTwkKYqXXSQEUuZPA6U0suJG",
  //   vgs: "U2FsdGVkX1+XD2mMXdQCPiOISaKTbt6vWRosMhU7JJ9I9Oau8RTsX2OA2A64CWUgi2FrmqeE7ewfKhDBkP9/bw==",
  //   spl: "U2FsdGVkX1+9/tJoNzVEG182McIVnbA/TXFMi3D8e3Q=",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "341",
  //   abilitySounds:
  //     "U2FsdGVkX19x9fooNXMz5Mbmk3chiZQjYwYVUCTNkreZ9g77C4vrqY18XB3wYwI0LVV5sLf/+ojKxKHBeNeZDOmMpDgDm232XWfmfavF7QI=",
  //   abilityText:
  //     "U2FsdGVkX19MRtGUi7d8y0i3t4Ki8N04//2E3jAz2OnfJjznsePJmEP5eC24DxUhEN4B8n1jHo8euzyLU0gKGRVS+QwOBBST1xmiIZh8WN0=",
  //   gods: "U2FsdGVkX19o37LIlCOGqewRPU7wgspdWtDf2EAGy1U=",
  //   quote:
  //     "U2FsdGVkX1+hxlZgW4dwq12hkGeXmNcQe+hpeYXeHJopfRaC779RsM/Tkh/uBbwu1gFy9B81yW3WmwgFI+Ifhg==",
  //   vgs: "U2FsdGVkX1/IeNjpQPLwGqSTornRv+Odn+cQmaKLK0hRdS+yeudFWyCO+3GS6K7Pe/waecXmVfkUKt8GQ+PRrA==",
  //   spl: "U2FsdGVkX1++i+sGgOMuA85830zTtJDLXwddnqEaOQEt+tlZch5wKdpZbLXgneMd",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "342",
  //   abilitySounds:
  //     "U2FsdGVkX1/O8COeyRx6DNDIfAtnvirqVbvqYLG/0o0YXtNACZJRZBTr4Iw+pX+fJ8+v0STK2O8mAu4ep+J5qlMIdsoPMdeDfyjSginekg8=",
  //   abilityText:
  //     "U2FsdGVkX19lbKn2FVsI0DNXHhvMefMic8/N51nukvNRtSs1JGi+w+7z3GaurNlxnGRDHRXtFdc9ZERFtaIia49ggct4mK4UohjbxIk2sz0=",
  //   gods: "U2FsdGVkX1/nA4rXXWWTseeSYlde5vtg/Vox4uzOjCU=",
  //   quote: "U2FsdGVkX1+mjxy56hoPJxUpsz6OXwsWtg5+O3Dy2OqMMjoKq56nuBfuUycPQDP+",
  //   vgs: "U2FsdGVkX1/3OKnEChHCmLhmb22zgYk+2VhKZVB7LwhJnxlqEQ929tyXAYRZqoFFDa2OeeKciYHn/L0xWxOMzQ==",
  //   spl: "U2FsdGVkX188NJ1V96ObSKUDlGZZsFNTM8UYoeEnH21eWgU5GTe8ZB5KBn7EtZ+o",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "343",
  //   abilitySounds:
  //     "U2FsdGVkX19rrkdr7xLjv2CROZPDHs6Uk803iPIjgLqMWM3boAJxKM0VRRiCYexVjC+6HuGN8D9ww2mnnPlRncY7DogGBkyl7w6npS/RbUk=",
  //   abilityText:
  //     "U2FsdGVkX1/oINqjAknfmfkVAEuJiTllAA74DIC3fRJ+5SRe7mxZZLuoEFnwoGChVyA7YSnE7ENLX4zVnrjCFIvT9VVhfVw1WuYp4MV12UE=",
  //   gods: "U2FsdGVkX1+jQDYN6vRA7MHDp5EqLM/tT/W9BQ7ppK0=",
  //   quote: "U2FsdGVkX1+aHf27g1VqA6DHqydTZ/6gXtDNWWgdMzfCOCJLzxX5dKKdad8O+ItJ",
  //   vgs: "U2FsdGVkX18+5bNh8/ZLGK3dqEb0PxP/qkwHq6cVc/oFw8b2EAlsZb1CqrUJLCSOUIz404GfClIvcH1TQdq1eQ==",
  //   spl: "U2FsdGVkX18mtq82Ptqq04xcop4hY86Gtpitx2GQeO2wnc8bkSspbgF2cvFPGwkD",
  //   feud: "U2FsdGVkX19CQkQNizh3+Hz9voJAPJsts1G3e8LG+mg=",
  // },
  // // Sunday
  // {
  //   day: "344",
  //   abilitySounds:
  //     "U2FsdGVkX1+3AzIb+he6ja9kuWU8bsPIoJXCCI8rN6jTyY0mZys0qo8meUMZAYQ76F+JU7ArN7/U9OgVaiUbANF75xDfk+V2Qf46ZHIQIeo=",
  //   abilityText:
  //     "U2FsdGVkX19ZcB1i7lzamV6jLRSohNZ9RlHpBHpPaqRoWzsqhMzfNmlGZsB/ger1/tNrjwu5B63w4vw/ijebIlOB0u86MGt0yd4fjSHTPos=",
  //   gods: "U2FsdGVkX182Mx6ZNGQbc83Zs0UCrN0VERz3wijtpLg=",
  //   quote: "U2FsdGVkX19Oa19eyOv0KOSyBwXlq9w08UzPXErdXw3W7O2kylMk4qtqxoU8V+N6",
  //   vgs: "U2FsdGVkX1+GLoVxp/TB0npB+EQlR/6B0+3N7D3bu4QUrOkje0s43oeprneyB1YZ",
  //   spl: "U2FsdGVkX19RCFXx51UTsqiZ6bNx4b/+7LtbVel7VU5ehzppNkvh3/46HZWlByNn",
  //   feud: "U2FsdGVkX1/y0BpJz8CFpTAp8YpUMPNXyTv6N/2mDH0=",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "345",
  //   abilitySounds:
  //     "U2FsdGVkX1+BfdxETjRp7Ew4TyKJ34+JXsWToqLDL9odWzQ2niCQ96MSNTxBpNSNedwCQ6mqbp0+QLDnDq4Yu7hxW2QKmSe0zW172A5PqKk=",
  //   abilityText:
  //     "U2FsdGVkX196ws6SA20D+es0ymxfe2z5vqT3WcxGGXevHjLlbKUyZjJwQIg7CtLw2bHu3acQstIKTaQbf1khBxl2fsITg8v6ixBgtp6+wHo=",
  //   gods: "U2FsdGVkX1/Wdtcxks3oSoILKRJndWyHX7iV/SmrapM=",
  //   quote: "U2FsdGVkX1+5MZB7/nIklVgpPc1cIqq2j2fEfQ0J7PDxAIaYdxoRQMLaPUv50msI",
  //   vgs: "U2FsdGVkX19AF7Zn7x0xRg/3vYV+nC27acHusvFvtCZzV11ehdsChRzY4n7LXskfgSIDJtAHQ3JK7RRA1QRK8Q==",
  //   spl: "U2FsdGVkX18QIfrv89m/pJCOk2LLewmVYBQ9wQSS8/IM/riFA3UeEqZua44yYqAv",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "346",
  //   abilitySounds:
  //     "U2FsdGVkX1/1EFwSQlVkHyx5fWBIQJ288R7sPN4nMFuJf9PsZSpzolgKTaiJk3eG3R6cAteTfuB0yUhnToeZC7CY5y9K4LrAVzhboVdoviE=",
  //   abilityText:
  //     "U2FsdGVkX1+sWrXYpe5KwWIIpApmB/A3jhOO12U1wMKGBMh77EwDCs7Os8oILHk9o7PvJsJfnc9Y8vOmyStXlzqHzcHu1vr2+QHhn6KhU2Y=",
  //   gods: "U2FsdGVkX1/Qny0pRMSa1MkAweTinE7csQWE2rGYfsY=",
  //   quote: "U2FsdGVkX186ZpwJMQMTcS6dknmWl1JNnlIIST+iXBFqrSS3ya9PvVWYuquAHb0z",
  //   vgs: "U2FsdGVkX19wz2DzdTG3dQ/PAJBDkNnRtEGBNGXoPVd3BWsPJmbPLPYQq56vNSj/xy9BRiqp1TpCHtcBb/HypQ==",
  //   spl: "U2FsdGVkX19FNy8Oee5AXy1MmhtY6zugihAjPKE1tLRIEYNccbHHaaWci57WOqvv",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "347",
  //   abilitySounds:
  //     "U2FsdGVkX196WGWawAJBGhCo3X3RIsRruSWJrNIptx+ztYFJj59/UX8zr4NWoE17MelPqAw8lwss13xQgo12j6HZ0x7r7VVePm1gIuxQxWw=",
  //   abilityText:
  //     "U2FsdGVkX1+aTxdVaOvQYW1533f60PaGB3eYIIr67zzIA/mjoqITZTJXd49aX4ReEcbi58QZ7/aVmlhv0Hh1QWG6aweLr2Uc1pL45AIPBcM=",
  //   gods: "U2FsdGVkX19q52bnmnGIE37/cK099aqQ9r5jyuhZDic=",
  //   quote: "U2FsdGVkX1/UvVSCrgUlfRAVsKSqos0ISIqLeQjjM4JNkitvPBYtc2EF+3Ay/yr1",
  //   vgs: "U2FsdGVkX18WwUGUQOQ1PoJGzeUE8IyoCxtEFjELR+0erdSdTQCGvXDJJZBkTMu/RzN/X6zdFEcgbgOId6Iusg==",
  //   spl: "U2FsdGVkX1/wSOLcxBJ7hDVAGdG7//fjSyINRHO5F7QsEpF2Wx6nW0E/G14E27le",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "348",
  //   abilitySounds:
  //     "U2FsdGVkX19s+XSvFoYN3vfmWgO39mGRM0FtyfEO+okPMxoQrXjX+1ehadLy1ypufinz7M/9EqtOYI69uymcI4g8wPv8L6cQAnsGJ/wt6fg=",
  //   abilityText:
  //     "U2FsdGVkX19ao5u3NJ34eHS0RzqTyFigrihYTKNGhua8/oXBV+1RSrPMlED3jzAUm1o5c0OrIkIndfiVkY1NEufK6k5TTKvmOI9DyuBYF+w=",
  //   gods: "U2FsdGVkX1+RyqJKgGJ7RfgTTFkFwR16lsF06A2XgDw=",
  //   quote: "U2FsdGVkX18BOA2dXbITF7My8whUNpyuLVpBg8LkqJbVufY2kvJvJL7200MDJaeB",
  //   vgs: "U2FsdGVkX1+bZUZpNJL7H676zOFtwA8SPygTlx9a3l8c8iCLJ63m8jeUkuoy4H0mY35IGZ9DozsgVZpswS8LHw==",
  //   spl: "U2FsdGVkX1+fIN3CmI8VbUT3KNbmeVYBKDmPcxx44dX6ptXOMYVg58DhINPcaa36",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "349",
  //   abilitySounds:
  //     "U2FsdGVkX1+fbRIRFz8U7DzfR4h82poIGx7bYszem7Y5AqN8Hp61uU4t0DZ4H+1Mp4oEUYkjWp+rS/Ow1fgzYqoofZ9XzlNPoz7bV8Qol+4=",
  //   abilityText:
  //     "U2FsdGVkX1/yOK+p7r2ms59d4I7MAq0xzmfSOcgBdcBsyZ1Ta52IGHHwzljOz/PYgQSMLCbJme8CkKb3GnBkbM034rQEmt0+vpTc4M36PFE=",
  //   gods: "U2FsdGVkX18XXZG6F84X4OVK/h9m7X87wH5513QgQgM=",
  //   quote: "U2FsdGVkX19QXuX3xwPzdn2K6sTBY6jm/FD5kU/H0Pw2GFzhKzrynQ3WSwYzKirr",
  //   vgs: "U2FsdGVkX1/BlQ7asbbkQtXWkItW/o4q0UZGQsXO14wiYYkdpY4TVKH/RebcWl1+14ixZTxB8kwUtMsPzfNjXw==",
  //   spl: "U2FsdGVkX1/nC/JZU1Fce5RmPeyKUMVbYtajalx3w50cotEOA7X9dqaBNiTONXf9",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "350",
  //   abilitySounds:
  //     "U2FsdGVkX18m/iDrbXtwLZiM6fw2mosoQIxZGh480PjLiUZtP/6R0BlifQzI2F43u3HKDVHj5Fajz3avMVCH5iDcNY23FHUFKBTdW4n6n8Y=",
  //   abilityText:
  //     "U2FsdGVkX18oi7H/LtJgmjFFHAcQdyFdvL4ZXue22BNObDfG/ZUe7jpBNiiM7qO16zoKeO4KXyNYRkblrldJttdBfVu4YpLkRTfxnjkw/q/v0KkgIvwU0qBDsC/CsTaG",
  //   gods: "U2FsdGVkX1816k5lyFkHthyccMnKbhZR41NzHl0F3ro=",
  //   quote: "U2FsdGVkX1+Jv4ATRYJE/n3J8f9pnjUiJx8XXERKLzqG69ewAe2cQKeooGUL6oGg",
  //   vgs: "U2FsdGVkX1/4VvwwZyr2nuXL2QQ8CgHqc0jim+SHsANmgBUEyN3BgUEKcL3/q+ESRAyM84eeiLUiIf1nsW7xnw==",
  //   spl: "U2FsdGVkX19/OzVjuqEkOEfiMFN9tfG5W2Po8NzLCUr12Yzxy7ESyHfCsOFA12W2",
  //   feud: "U2FsdGVkX1+uabI0EvFPgFNAoK/C0mVhCG5F9o7SPcU=",
  // },
  // // Sunday
  // {
  //   day: "351",
  //   abilitySounds:
  //     "U2FsdGVkX19IKpK2Rzhhp7EsEseNdacinkh1PVxR3sdRTtnN719P20WhovX/CrCwKMcIstnIlMcxxIrEEsVodLN6LIWpSitKFR6SFDaX/+EFdW5agP0raelH+br2I3QT",
  //   abilityText:
  //     "U2FsdGVkX1+LpUG7SM5pdU869HcyVKQTI/ysRw0GVKJ3/Z8SBZ19IPScExRbx5Am9s0OMy3oVt1fTZ6NDQHbIQhzOITaBtZ7l8uCRCPcJvRDNkJ8mBG65+7V41DIIuAA",
  //   gods: "U2FsdGVkX1/z3Ka2QKFVyF22JRDzpy0Q6I8RCk6Arog=",
  //   quote: "U2FsdGVkX1/n13msNJDXDlUh+NkleoSGlaJwcThJWWK0wTGxPtNLH9gALWzwIDq4",
  //   vgs: "U2FsdGVkX18UXfVBf6JzyaObMKwBL7ysYL2E5lzUKVI6FkjUYOAalVW8dlDthktZw6s5t1n/cE3rLDvXCO8gPA==",
  //   spl: "U2FsdGVkX19gQivKezGzVh6TKJhlUG9fHWKkkn+ILmmQImD/nK/NEK8O6/2RKnIM",
  //   feud: "U2FsdGVkX1/2RcUDvPAEyld1WKfW8UzBl8un8rtKLdM=",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "352",
  //   abilitySounds:
  //     "U2FsdGVkX1/NRLYPbSyZt7CxA6lu08qBQ7VBNKPhaFjNy+g1d4kJYDM20ykyRwBtQlzoSFjT9hankSfOpDTv2/OKcdYS5jM+3McjUcscY+U=",
  //   abilityText:
  //     "U2FsdGVkX19cupPOXNvqZN/MrD2RhwUEafSzZppv6q699+ut83h2bDrw/Llxub31wUamr7CHNY3blRuD6rToEi+CAH+Y7jjL/o9P8YaEOAN9iscuwZHSlj1+eI+gawhi",
  //   gods: "U2FsdGVkX18nsl/MZeRxU5HDoQTqNadhWvyPx4wmMnY=",
  //   quote: "U2FsdGVkX18MhmQV8qHsKSLzD0TWQg/XANeX0tqTZS/lOA5xNKZnY7/3safejsxt",
  //   vgs: "U2FsdGVkX1+DzRQiqQw6kcApZVEwE4Iy4b2PCzfsNe+jZhVAgbGzB5t81GqRIqTACnPjEaZezaOx0mVSu8ikyw==",
  //   spl: "U2FsdGVkX18A8Dsl9OEvGinMZdQcx/pG+v2tIatnHY8tG7Gjs5oH/7u1FIstKXZM",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "353",
  //   abilitySounds:
  //     "U2FsdGVkX1+7Pf5RAZTlAh4JnMT0AQzB/PoIeRcfuqcOf2czDHAyDGI04q9HF/LDKUv+XB78R4o5+WVBe4LryVn2ISFNUbNowjL06mimG68=",
  //   abilityText:
  //     "U2FsdGVkX19g6A8sm6q7NFvIUdqCQdeEDS2ry9y5nP6qiQrAy9zgDtRDZA50WZQvmnrP4wEiDyclNDt/1CfEycViXF9d++Nu767XUxHcIU8=",
  //   gods: "U2FsdGVkX1/N7F1uy4EtN/hJ1+5ixWjJF/Ajkk40bog=",
  //   quote: "U2FsdGVkX18EPOj8nxCeJ0dpyfrSqD4T5f6ILA7RiiXVgBWYvnSXqCEuNLvOL2v1",
  //   vgs: "U2FsdGVkX18x11Bec1OU2GnnL10InN9vK1Xaucb7RKQ3KZVaIOixrmZYGQgV3g2uBEFN31UO9aCo9U7dOSqu6w==",
  //   spl: "U2FsdGVkX1+SODXVVDlCjuptFBSUkUfLEjFg8OU9cGShbFd3seRt/0znZjzwtuhO",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "354",
  //   abilitySounds:
  //     "U2FsdGVkX1/gdDm14cv3kuCVa39dckTCK9dHRCLawD6siilJmt/gcahq+MbujAeiFEcc6oieZkZAmvUPer6cpQzehsIfkUrpF7Rm+03Be1M=",
  //   abilityText:
  //     "U2FsdGVkX19wSdlJkqoA3N51P9DqvqV600+tkf9MvZcKd9NQgkovJikGu8SWLGnR/76ssA75hdcWnXwmd4AcFRT2CVuP1MGJaBg3gg0o4XLq93NhFRIzLaf3mN/+DPmy",
  //   gods: "U2FsdGVkX18EOOPtoXxDub4eFi3HexKSMLDVy4TegH0=",
  //   quote: "U2FsdGVkX1+I4yMLP38ZAOgk1z9u2KPOBJF/u/PL8Z7YIBFbrLSuhIKPGJjP9BmV",
  //   vgs: "U2FsdGVkX1++lCjTH0AsI29GKJlj8L6FAYjWa2WzUUt4UTTKBlsL17pJvaPwYHpn",
  //   spl: "U2FsdGVkX1/IvRlqdzZQ3pd/NJj5PpGql3NwPJ3f5oJdE3sCOqm/u44l6SN78GGG",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "355",
  //   abilitySounds:
  //     "U2FsdGVkX1+gtt7P7V+AKb/5xVojOd23RUCEh08xwTFgaz5sVXhW/gFAfti36CZ2OLVyOwKAhm50SuMRbYf5vMZWT5O1WqnRX/bBeYBvvfk=",
  //   abilityText:
  //     "U2FsdGVkX1/slcf2lmqBlaAz9o/hwDd5NVFHvxKlu5L3gerJ28CuiXbV15oZjnK3+hmvuyy0g6PGGyoKsiqtqCwQCEmD4YDkY971WQCK5iP0F1foTUkiXOGERfVoDEVo",
  //   gods: "U2FsdGVkX1+bTuRr0G5mW6Z+6Eq5hDDWuD0Obu590kw=",
  //   quote: "U2FsdGVkX19BLvT39B664Pi5l0WzRX+mWVEBN1F9vfq3KgPKrSaqx2O8p6/mqCPS",
  //   vgs: "U2FsdGVkX1+1fQqg7KMDSwo/d/SqS+eaExUsDPmUBI9+jK7KP4lhqj59TJOsF2eO26qpblw/NCTpjVZTOA1Nhg==",
  //   spl: "U2FsdGVkX19wf0wj24pcsTKhJYiHv0Eb1MMuWMC8K/oWYNmZ2Q4OUy61T3gXi5bj",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "356",
  //   abilitySounds:
  //     "U2FsdGVkX18LsgLV1hxHU0ug3oy1EubCjrXFk36a3spg12JUwa8k0hkHmDdm1dkagUaQnvG2LXIVSP0RFXGBSFbYGfYJ35vuj/8XDpUO9eQ=",
  //   abilityText:
  //     "U2FsdGVkX1+VmogEWwdbNQeYnw8nAQpOxs7y0rRQLjyEpUxJnujpG4Cm5R9/VFec3sVgTBpkdan8gZ09cN9nJuiImCdFPDU86VXm4buYPBw=",
  //   gods: "U2FsdGVkX18TNzoi2P0F8WmN3SiECojzs7o1+vJZ6eI=",
  //   quote: "U2FsdGVkX18chr3dF19egNvCnt6PS2NP4tzryyYb5xngfy2Xf8JgOiRMGY7tglfx",
  //   vgs: "U2FsdGVkX18JiqeOfHVFqbCQNv0Bb5RnE+Kq/ZretU0//Gb7UjexSdLq92yChvkN5izOCfu3UgS3M2F70nSB2g==",
  //   spl: "U2FsdGVkX189KJiDGXaOi3vm2BtlcOVqBmZuJGYJcGrVDLl0iD9NkELSkK+nRWYu",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "357",
  //   abilitySounds:
  //     "U2FsdGVkX1/3xyNGlQEt0ljwfHXfKFIosIHVlHmypAr/Zb0Z0oFEFjByy9Yw9y85LiFcnvzbyn6om25zGI7BQfei2e0YKIYRsPdfVT65Y5c=",
  //   abilityText:
  //     "U2FsdGVkX1/mZ39e/MUVRt5JTiEmoOM2CaQw0l+mgkrJeWkW88L/jIMMBOX9kk3prRxCM87MAjrUXtyNcHW6GEL5PI1O197xDlkT/kXqZME=",
  //   gods: "U2FsdGVkX1+fTRfm28O2zBCZ4l/8t/lwJz071PdjDhw=",
  //   quote: "U2FsdGVkX18mUhxrN2hI777L5WhJsV9QqpVF995g+nUg23qon8XknSbHzwtI64Xb",
  //   vgs: "U2FsdGVkX1+LQshLV2GRnAvKYe4CntKLl/bpWQmcvy3I0mQE8BGp12vfimijDfg61RhJ48LE3KViEWD5x/YtUQ==",
  //   spl: "U2FsdGVkX184R0mp4fTQZIDsBN4+ZfPs+dam5xPVfBqhqmTE3QDpiGawkUBYy1og",
  //   feud: "U2FsdGVkX1+4teZQzegC1WM/tuX8nXOHixyNRWkOfIg=",
  // },
  // // Sunday
  // {
  //   day: "358",
  //   abilitySounds:
  //     "U2FsdGVkX18+1owKb9Ul5EL778nmimpNrPyft2D9c18FUL047oze9EhHsolSgvCx4ZM4k6kT4oHi/P8ZpzKyjrRMp+pgp1SAzS0mI8fngio=",
  //   abilityText:
  //     "U2FsdGVkX194UVkm96NqKl6JsLVNQRebEHI+aC3RDKOsqoEMb+9kMp3fy7eW2MQlgkMF8KpSzLFNCTWX+zuwU1EamNhPXEA9kH1YIoZI4ec=",
  //   gods: "U2FsdGVkX19wvDCPt2Eirqf3PicnuEC8FV2+J1i261w=",
  //   quote: "U2FsdGVkX189i4h0+AwMNOkHrKLhP6/ZWVBqdoQVmRWhAbFO8EiiQD5HSWOrApDU",
  //   vgs: "U2FsdGVkX18J7lQ5zvF1RMotqY4qJ7310RMB1uWizuOYKLIZ9FHyzFKzI3uq/Z/32t/t9Ayzsyg0tUyqcdWFjw==",
  //   spl: "U2FsdGVkX193ZaRlh6U/wmrEeqRcrawHT5fK6fTfdSQxj8Z9K+xYntMmt5DZzvN7",
  //   feud: "U2FsdGVkX1/HeRVLFV7Ujri56REsg2t3dRwrKAOK6bY=",
  // },

  // /***  ****/

  // // Monday

  // {
  //   day: "359",
  //   abilitySounds:
  //     "U2FsdGVkX1+3Lk907C2Y9m7RDIyVw8oZ9kpI7bJR/mv31p9xzyoSkMASI+xHnKasHlsFyRmEzijC5OtacUzjDPdtfabwyqBZ8saFOjHkwHA=",
  //   abilityText:
  //     "U2FsdGVkX1/z0BShcgc6mYTnGSaWzl17bCBsjKmQ5NsXD8h7W7WYgLzvaf7K5GI7yuv6/YK1U0bsJomwNawky6Hi5sLoh1zXXW/TgMtbU3s=",
  //   gods: "U2FsdGVkX18BudcWFlJUQoTijjC5Km0FRdRlvwRUFeI=",
  //   quote: "U2FsdGVkX19TJLEJb00/ddnRQfwkf6fUsNPM1MpFrJp5jan2Tqi+rWUaWwgn2BHp",
  //   vgs: "U2FsdGVkX18fEyVSOJeOuQX7Jw6RqZSWFJTTZa//8F7ZzzbuU+KJ4EjXzRdtXzEtid21OqMtmuk58wpQKNfY6g==",
  //   spl: "U2FsdGVkX1/zwe1G85XiKg5o0WrVYuK5ZFspUpFVAyd0A8yNrf8YC9F2r+FIt/pF",
  //   feud: "",
  // },
  // // Tuesday
  // {
  //   day: "360",
  //   abilitySounds:
  //     "U2FsdGVkX19Cjpmqfo7XHsah7c6AkDgqNO562s1qK2KAQk7obbL0gZub6Zpw3S2R6rT5laMr168wd/ZhxGl2jMOygBmUoMYXBKhsHnarYvI=",
  //   abilityText:
  //     "U2FsdGVkX18kMhsiyjqqHBNt6VTqC1CaQPerZ064f/w3y+7prldgSLWM+zYIvk1UU4Rgcg+0MdYxaYZy26+WD+7hM7568pbAtGAjWXF+5nDgPhEjUsL5TpjzjgdefWGQ",
  //   gods: "U2FsdGVkX19FONdSfYrvF8dtpfEM3hpU6Jx4VEj70JY=",
  //   quote: "U2FsdGVkX19/09ZKp4WEekuK6yG2W/15kP1VzDpksSQ0qGjD6QCQg47EptbAB3du",
  //   vgs: "U2FsdGVkX1/CVGwxmd8I4Ae+XHUtOa5obx5lLBFFK0w8qkAcqoA+qh+Frs1ll7zD",
  //   spl: "U2FsdGVkX19B1jRf9mVKb1W3AVY5wd52EyK22nZMHORcBt8FraAVSRhOSOh9yLlT",
  //   feud: "",
  // },
  // // Wednesday
  // {
  //   day: "361",
  //   abilitySounds:
  //     "U2FsdGVkX1/SmDkoDh+vY+xA4Ax7kYE2Tztl7+0qevVO4HTiL4gCydYoUB0wo89tvHc7vjmDdhb+M2JtQpooyXEoLilXt7DY9ZazL2e80iQ=",
  //   abilityText:
  //     "U2FsdGVkX1+UwcRuXctsNq20hZ652NkBBnJl4UhPIZxsXvkCNM30BqMvfHnhq+RwvZhc5SBXgdD5wRMDU80M3EMYCM5UJoo1wmrDOuIIToI=",
  //   gods: "U2FsdGVkX1+eFoZiKTIQssDpEt/DT6VoKsHR879Tylw=",
  //   quote: "U2FsdGVkX1+EhSHESizGFk4pgkVsN+A7Unzx7HsPJpTw5UNCMi3RXhWtkcKkvoHX",
  //   vgs: "U2FsdGVkX18WT8kSn1y4QVdlAXU5LpqwBBS6HfYwjPk17MqcyJM4b9H+RTlcfOY6Ue7rMoCIbWOgFvoJFVzvVw==",
  //   spl: "U2FsdGVkX1/TKeW69kZvYQfJJytKmsUmux1Bf3+ITwRJYf3XPaYxr+CJ9zl434YO",
  //   feud: "",
  // },
  // // Thursday
  // {
  //   day: "362",
  //   abilitySounds:
  //     "U2FsdGVkX1/xPe5YuHW0CGgHP6qutc9vZKMSNld6ei5JvMwMTrRjfqr42UCvoipqQh5pTuOXz7IT23kCmHvdIuaqI8rgwiOmPkKulAf1HAE=",
  //   abilityText:
  //     "U2FsdGVkX1+rNTFIZ+ePY+0vBHCEVQ0m7oz5faWd7g6vyyAHsyFz83rB7QCcq2t5tVPUgK5mZISrJRDImqHmc2eb/xwNV5bLA/cwnkueQ8I=",
  //   gods: "U2FsdGVkX18j60tYCJ2i7Pu9fKF8f2zm8CyUZphA62U=",
  //   quote: "U2FsdGVkX19kROaNlQ2MidD62I4bYpAgaeozzkb8NHLySwHkE4u9YY27TCQjzbie",
  //   vgs: "U2FsdGVkX197XidqSJuUeULF6h2/lacHlbJZwPmEsryx9Pp0kU/KwEzEczB3LOEzkFBz/HYiYolMrcAL0sKr/w==",
  //   spl: "U2FsdGVkX1/fUSdUUA6ig/wMDciVaPk6dEfWf6QhffEu9anOylQF6FkFNZnf607/",
  //   feud: "",
  // },
  // // Friday
  // {
  //   day: "363",
  //   abilitySounds:
  //     "U2FsdGVkX19t55g/QYGsP9+V55LM9FEBME3UhYeVcK2BnCGk2x9HcupSkXajvq216T8JYksqcYQxRKUZIKlzV1CWjkwXqa3kVRBSKzmRdIXv2Fbf2lq+Cn3n3W4JbyAB",
  //   abilityText:
  //     "U2FsdGVkX1+fo494g4g97W/fm9e6J1AhNRY0XdHYa8C9ZEgU1l0LhXnJAVmtOVT3LFr0ObvuSkW1NrOF4ODDhcb72lezwG6OSq+dwsvpecw=",
  //   gods: "U2FsdGVkX18oLSzhzn4joSnN0/m2kDCYPpl0nO9r+As=",
  //   quote: "U2FsdGVkX1/WRqIDguCQDMyPYu43jtSXf3XjG/Gu00ogrZcVTLiOQphEFdUD2Mk2",
  //   vgs: "U2FsdGVkX1/tEMIFwibFnXpccYXrbKXHhhO6HDEoEsVew8iQaJLJuY2ETeebyXcWJ9I4K33xPsLxgx2p45NbSA==",
  //   spl: "U2FsdGVkX19VVkpQryhKPOM0sE44rSRsesDY5LrJgOqff61ljw1ylxwCWXW7Fqqy",
  //   feud: "",
  // },
  // // Saturday
  // {
  //   day: "364",
  //   abilitySounds:
  //     "U2FsdGVkX1+MPQfuJ+NUpF7d55mjmVA5fs2M71iqR/1851mH60dX7R13fwabmucs2UMIoCfxr+XniLcy5WCVCkyaGFot7mpvuBHWKK7vSlgbrA+ZNUDjXFQaqB6V8fon",
  //   abilityText:
  //     "U2FsdGVkX18ayhjD9JUNotFL0StiL1wx91GflP69Lu1s3+7sLch3MutmBQJudnjINo/lf2oZxB9wEwlb+MrPYX/9O0CiM1wCHJCDE0kgq8Y=",
  //   gods: "U2FsdGVkX1/GnIMAyuWNXKva+cRSIum5WXPuhe5Wh5w=",
  //   quote: "U2FsdGVkX19R19aQaAUOlIvXa7oD8WzOKPnay3D7oazYX5UWgwH/oCcRIdWTtfao",
  //   vgs: "U2FsdGVkX18GCtF6YjR3aXuCMKniGAUPIuUSBwnmxm0kcmaCk6B22BuW4SpK8VZh71376IwtjYzT6Nmfjw247A==",
  //   spl: "U2FsdGVkX1+ON1L4CJKwe/c/RnxUd2y5NlRsuxLT8GLWcRw2+KW8gQ3ojISb5EEa",
  //   feud: "U2FsdGVkX1/TgdZTsjo6VVvtgzO3LA754v+1sEBQnJ8=",
  // },
  // // Sunday
  // {
  //   day: "365",
  //   abilitySounds:
  //     "U2FsdGVkX1+uXC6rpd+9IYjALNeIGLbvYug35a7+jiLodqSv65gl/iU2fVhFTmuHbwEMGh8PPE0vWat4n1lqjP/Mah0eabNgfToM/kji2yw=",
  //   abilityText:
  //     "U2FsdGVkX1+Az+elyfrhLosHFINWTnvpn/WVVn01NcBx1hBhnGKbd5wAkmJVI4uxWOS8B18Gbz8Mi0w7gUDGbBQfkmPpqQGZAeWrdze3cXo=",
  //   gods: "U2FsdGVkX19IswZuc1cfYKEM8mFD+aL4dVvLKxqLo9w=",
  //   quote: "U2FsdGVkX18YG/PhVegtuuyRyrZzD5I8ZgnBmFk+Jw5TaUjTtqYyfBsvIkM7u2FF",
  //   vgs: "U2FsdGVkX18vE+6UpmwbTKFHmIU1pdKM5xiddHFTYUx68tEHbJ/QlA9RIWzS76enJYrh9B0NMeFpXRiNGCYCKQ==",
  //   spl: "U2FsdGVkX1/NaVin6WcRz7c9K6ntt61KF7cgYUXvPlQ5WqU1bizZPEWbcZG2Qh2a",
  //   feud: "U2FsdGVkX1/nsk1ZwyEI/TjC50kPfbvg56F4ZBQIq+o=",
  // },

  /***  ****/

  // Monday

  {
    day: "1",
    abilitySounds:
      "U2FsdGVkX1+4VtCxy38f5sAUxNK4QZ1LSReMsPxd5dB97KVBX6nEAZtrU8d2JNvF448FISct7KvQvWdOZz6s1JkhsMqmManezzqfWRIuGPI=",
    abilityText:
      "U2FsdGVkX1+Q4QA83Zn3+DfmDx2WvBuUYioyALf/laXaCuvoV4QY4wpPQGBibS2CQBc+4q5LoGMStauMwCGFXACfofvmkyzoq4M/Ndmdjd4=",
    gods: "U2FsdGVkX18ReiuxPXBPM00OuQOel1FKkrQQMHZ2J3k=",
    quote: "U2FsdGVkX180SEVd9mpiOD+HrdVAS34uJUn9sGgAHumfMUVXQ6OJCp/U5o5KSYDf",
    vgs: "U2FsdGVkX18WjyV32rH3arHBqaywVCyDJEi7Gu646MOHZwKNo0yxgI38i/Ve5omjzZ8CVDHL4mbgzfNMMAkwcQ==",
    spl: "U2FsdGVkX19fudBU3AArToNAI4q2g7brxPlgbuwSVf9mg0oAfETVJZ1veXDkpC9x",
    feud: "",
  },
  // Tuesday
  {
    day: "2",
    abilitySounds:
      "U2FsdGVkX184dcMugPzliLRQHcuLGsKCZnrpzn32NRdgvNdjKM4fk5qqOr+R5Yed+DRJ6NtaYoxy7vvsWWJ8EPF+EhZo8Teji2Jm6j2657E=",
    abilityText:
      "U2FsdGVkX1+2P2/ceSorcO/VlaadHYdudwj2DnlnJ7l8cI/IY/+Ot6mX552aBSTEEYgWfZFdyWvJEBJGmYpZyq9BNKrnVQXrtIbr1FFfqgIFgOYsQhC6FOCodhz7eMsz",
    gods: "U2FsdGVkX1/NO9sgeToS4zn7USZDnlMBiUSUQ2ZkYcE=",
    quote: "U2FsdGVkX187BSzSCJdFlB8XPaPJN/1ms4BUA2PYmF06QRx3gaHBzh/o7WD+uQ2q",
    vgs: "U2FsdGVkX18qgmZzf+QGZW+FxbhjQ2FGXP35HW6Dyzk/saYG3NdjHu3324z52TN/",
    spl: "U2FsdGVkX19Zv1mIhsx6vlOWa8qMfMbMgMIPwowoPldAV8kmclh0ivMH6CWT3n9p",
    feud: "",
  },
  // Wednesday
  {
    day: "3",
    abilitySounds:
      "U2FsdGVkX18mcjYmJFzbAgoBoUxQpitbl4LCDLqMu0LI/pNlLvFfFv+cBsMC4BzINb+GUEZrIvbGSTQYcaN2ETtBAjLZSXremuju81CXxEY=",
    abilityText:
      "U2FsdGVkX18QWsNGywo3B3dphA1sWPbfEuarSLag/1dYRYEU5iZfXX2HpVL43RNmd/ZqQBu7BDmvLCLk7KFtevbG8NdnJEFG9AggU4FcEuM=",
    gods: "U2FsdGVkX19H4Nys2nFARuFLUYwohlM9GaL+CxPmwyg=",
    quote: "U2FsdGVkX1/GgqzI0BAtLC8RhSVwcqWso7M4BUt9bS4erJEtXTf9xm49WF0oLzyn",
    vgs: "U2FsdGVkX1+35GAgC7VFVNpJT5ae/tEYY3J7AlKXFpmY5o55mczDp9ppM5AMW66Y",
    spl: "U2FsdGVkX19AJwMlfad4kVce8727VKXWUJc4+GHEeS0Wnjtob7QXKGGpESr5owgg",
    feud: "",
  },
  // Thursday
  {
    day: "4",
    abilitySounds:
      "U2FsdGVkX1+96eVfrvceB6nfzYHFFfhU8D3LorPkpREDLpJeb07kHSckL+pd6wRIXXvbuDV84sV3TxGFBGH35yx2JWqlRYpjxr539ir4wQM=",
    abilityText:
      "U2FsdGVkX1/UYdKC1DZB5tjqJmu0nq15EziKWZ0DxIn00H08HhhAGE51fQtOJ1JFbQkTTwR3f+BB4aGlb2Pq60j6FExtSU2yuzwqWH3vt1k=",
    gods: "U2FsdGVkX19ZFb4eKnnsC76w7KEs+TZbiQGkqDPwF6A=",
    quote: "U2FsdGVkX19sPC0AZ7WN0zQTyEdgGzd9BNp45B0uBRVgxaOMnua9xpIIuJsMDjos",
    vgs: "U2FsdGVkX19eNChiFdIyF/dLv4k68lZxwY6RxSGXzBZ167gwmnz8uTk8NagxntpRbV3HObLghwrPDrHkT+LLbg==",
    spl: "U2FsdGVkX19kybvH8+U34EfZWEi4tadz/yX8J1lcPW6HMfWJrtaisEvNFQNlAwRE",
    feud: "",
  },
  // Friday
  {
    day: "5",
    abilitySounds:
      "U2FsdGVkX19rqofW0j9HMMCx4CJ7/QNeOKEjeu9PAvOvhVHTondilD07Ger++mf8FyMjusCnD5b1aESDHjSWEQN8YqLJRtZVkzUBtG2jhUc=",
    abilityText:
      "U2FsdGVkX1+QkwAsyfdU0APaQEFfsLCZ7YL7l0HUSlCtIV/aLS6fQzrES2wEW8bbYoIyhuNaONXAlxfg0+jGq6iAQxTWReXJ5Fh5gMfvApp/rbwPMGLFTbBxEXrrTf04",
    gods: "U2FsdGVkX19d6NIuqP/0cmEdsAbc3Oq+yLU2N74U/Q0=",
    quote: "U2FsdGVkX1+walTZIGsbKu3zcueJ5DbGGtDqrKF38w9wbeQN+/p9G6ZDrYD2EBYU",
    vgs: "U2FsdGVkX18h5rQx7ggKd+fMWdUowaU9yA1PmGxgiNuhDkncmsvVI44xcNh72WWe9vYDETbdyAlGsmXfx4bqzw==",
    spl: "U2FsdGVkX1+cMMeeRCwojjf4AWZO+JDtM+iQJHOavs3hx6I2VRnLTVbjjVVdQzCw",
    feud: "",
  },
  // Saturday
  {
    day: "6",
    abilitySounds:
      "U2FsdGVkX1+IOs9mRpKTYbsOzyDAeV9e+i0O0xPPhFIV2AT5Llthj3e68hshlWSVKsnlGXixBOBnY1rOG6qAWFUCL5I1JWCWcP6J5+DGyKE=",
    abilityText:
      "U2FsdGVkX1+eGBJukCW+0k28HhJg4QKweB/bFtWDykT8T7NzRYxXug6OzQbqUQk/8WJbozG+z0MrsFg/1mZp9AOJDI+ClkekdpUS28XiZTw=",
    gods: "U2FsdGVkX184y2mvfo0tMr6tS7Iulv3Bve+DXqdRGLc=",
    quote: "U2FsdGVkX19b6YlqPlhzGxqS2Rfs/2Lm+/o7KNaaj7nVQDQoHzOLQ3yFmpd+Ls9P",
    vgs: "U2FsdGVkX19dNHZZ8n/0pHhRzW45wp3JBEGPqfHmHIG0shS6AQz2AxFpbk4N0Gvv",
    spl: "U2FsdGVkX19OuRL587d5rbQNHH40C2PZkmcveT9T29E=",
    feud: "U2FsdGVkX180KOuvnLit0IZlfoF/33LMSRe4Dl1S33Y=",
  },
  // Sunday
  {
    day: "7",
    abilitySounds:
      "U2FsdGVkX18sVS1HgLDBKmSuMJ8t+0v1L6YO8FDmttLf6GFN5PK7ZLZSDiMkSUGYBp0uBsaoPPTepv4L2IL4jbvlPhcSeAaNOqFcg6uS5iI=",
    abilityText:
      "U2FsdGVkX18Ha7Mouau41Wt8+v11Uk28bfyl5WE+uQD7KEEQzyNBMJSGHxuMGhudBMTpFxB/t+pt36vnVqSFlLRSRYsdV5vMPjZ78vyHEOA=",
    gods: "U2FsdGVkX1+Z5FmK7Ck4xWMPsa5otyDN7io8iW9hldU=",
    quote: "U2FsdGVkX18GcVYwLvCnZDJKvu2yNdCTUG2Le1x4Wlf4Be/meLz7ad9l02QpSKj/",
    vgs: "U2FsdGVkX1+6CNS5t9gcYsG/xiZv9v1CxAyZUyR63OkQmW7Ma+74aAqGo3q15vGFzIpwhMPHGyPEc7pgNDCvow==",
    spl: "U2FsdGVkX197gKMJ4g1AmuTGh0wag9/RyqE6eYfLoco=",
    feud: "U2FsdGVkX1/JDTtcGFQtYzD0bW2Ou0DVHwN98kdfRa4=",
  },

  /***  ****/

  // Monday

  {
    day: "8",
    abilitySounds:
      "U2FsdGVkX18SQKE2nLkmkNcYtmZzATDdlFut3GPgLFaWRW2ktaQ2GZMsUM2Q3sYcgOHFrWPLwfxPvkbCz5Zq7U9xxW9oLsC0pSGXLghgJxs=",
    abilityText:
      "U2FsdGVkX1/RDOyjJrW0ohehg4xdt7JUwsHC0G2yWJLkXD+dCr2pWoXhiogb4LcEr2sT90raytCTwuZkwQtqw577tZipatCB2KUlQe/axnY=",
    gods: "U2FsdGVkX186DLvxQROHu+tnj7b8pigWAWzDUwhpk14=",
    quote: "U2FsdGVkX1+WC9zwNeKmTk2GMsSSizeCfcBKv6K4nxGME/OFzBfiGkVLtXcy7VZA",
    vgs: "U2FsdGVkX1/Tp/VfmkuV5q18NWgTbMuF9ETvIFMyAziMAZ58ZEPDiKNa/6Cn0AM4c6wh6/CpAWoIBVyVY2Da1A==",
    spl: "U2FsdGVkX18FpNWDBRXxAIT09+56YEDHu3VngJoMeeh5KRN511eES0XTpfsQEnIL",
    feud: "",
  },
  // Tuesday
  {
    day: "9",
    abilitySounds:
      "U2FsdGVkX18lTpoLdRhnZsJRP/wOBQ4I9zRkuZ6eht/6he7N36GIRfbogKUr9oJQY2RGlk5B5g4OtHy4DusYnJc3zonz4VOk/5+en8HUXA4=",
    abilityText:
      "U2FsdGVkX1+BQBHRTey8yXGXybm/s5Z153ZfztWI/Jf21ENnrLzHqbQ4R/nQw/jKNBnB3H57JJIvCVNIQvAw1/kwPdIckxd7kKmAkcJ6bKYaZSIROd0JKoNWBqN6UbwQ",
    gods: "U2FsdGVkX1+y9f7gWecyWEPiIhe8S6ueFSiTqmq5md8=",
    quote: "U2FsdGVkX1/4nNhylBRa0GBe8rAaZcxQBw4UGrO2mRyNBeL5fiWWCn8maHxBy1Np",
    vgs: "U2FsdGVkX18wWW8FE0egfRX80LWZuMvkECEDaiwoFqTNS1FJ2HZaLFRu95CsPvH64DM6i12ZXWe+iD/bj4Y1RQ==",
    spl: "U2FsdGVkX19Xko3Hbm5/Wmrd29RyJzthS+H7XW4UxTj0e7yP1iT09wVVBWPkpddR",
    feud: "",
  },
  // Wednesday
  {
    day: "10",
    abilitySounds:
      "U2FsdGVkX18gLt273dBvJErmf8Xg0MN+xXMWDDmI8CiQbR0vbzPwm/fVcGurrzfFgWd1LtWvicfS8+52jII3RcwoA6MeM1r8iOKDzqhg2cE=",
    abilityText:
      "U2FsdGVkX1++gjSbmOT3rVCEXf4vZvsRtOSv2zOb1N75XtqMeML3sEuo4xO9F568VeyDwDi9fQ8XqIJIvwFlRYccnVYv6BQDwpLDj2WUcaA=",
    gods: "U2FsdGVkX1+SvhfEOZRsoA+vnVNONDyrSrtWq+568K4=",
    quote: "U2FsdGVkX1+66jFfDq2b/lgEKffnwptVVLa8X5c5haTnMOuRrnM8zjluyEc726qv",
    vgs: "U2FsdGVkX18cb0zUQGalTDA+xie0fXjItR7B2icceFyj6HBsJhSaE8EBSva5JNrm+Mdyvu3NNIfbUGKVBn5xrw==",
    spl: "U2FsdGVkX1/q6XeNnQeLTYaC5SAPrTYflUt1vLga4pb+YD+wyGiVrHAUzGFXrCm9",
    feud: "",
  },
  // Thursday
  {
    day: "11",
    abilitySounds:
      "U2FsdGVkX1/Pn1m2rt+Y4YpxNPwgglAOUl4sJ8Fn0fRVxeE8427DpBZMQ4iLeavUOA/f816v2I84VZchFrtUG7Nis1UKFeTO17qpEk7opzM=",
    abilityText:
      "U2FsdGVkX19zJ8eT4YfPMxVYl0KuFoeCVtDcxIG7Tbopc4d0DnAcHdTTStXBDKeDMA9KLuzOzPBPLPJR6fJBVuk2zVjv1vaQ/mtR81xCYf8=",
    gods: "U2FsdGVkX19ZbWQp3D73XYHy+RUR+LxPOQ/dWaAK/o8=",
    quote: "U2FsdGVkX18EJENFqxtF1+pRuYJK9wqsOFYJPV/TWp8sGXzxMhOwJpQw1lN/xPj2",
    vgs: "U2FsdGVkX18HXl5QjJEYUOt5IHztwhtCZSGNKQBO72DnK8sXWmcUAvfWv5eRCyRTYkKupqNlBqKtQFwEDkklGQ==",
    spl: "U2FsdGVkX18/yoxJ2pEecYb692fmRFvlVuztV+/IPQrNGusMxoobRe5W1r7dL4Sr",
    feud: "",
  },
  // Friday
  {
    day: "12",
    abilitySounds:
      "U2FsdGVkX1/JgXnvhld+inL4PHcJERusVL9Cwd6Tj4Namg7noAwvK70NuAyjMRABnSeNlDmeRU+xA2SX088p1eWz2/w6HDE7Yn1QQgj2ylY=",
    abilityText:
      "U2FsdGVkX19wIhzYCDWeOJx4fg9es+WhwlRZBi6a9BOxiRXrGGLAAWCN+iQQ/XHQs/vkQW2LmHa6rJs+WKA1vIDwU8WGeP0pqBtu9zxHt7Y=",
    gods: "U2FsdGVkX1+xLgVzUDkxQfETMkk8f71MMrmM6FZX/po=",
    quote: "U2FsdGVkX19nIFfwSh45PKKqw/teCZ1nl2PkmVMbnJIohtO7hfwYPwSsV1sikadg",
    vgs: "U2FsdGVkX188BcUYkdOffbVfvlhlrVqSTpqhelREEiqdZBzKiLglxQI6mlYh7ESl1JL07AWr9ZPekCTtmKI8nQ==",
    spl: "U2FsdGVkX19Klg43waLWMubr1IggBrRafesGLua+mLqp0po1xAr9gPha4hj+14wZ",
    feud: "",
  },
  // Saturday
  {
    day: "13",
    abilitySounds:
      "U2FsdGVkX1/t9ANUu0TUYG84vRlTsPPFylnnYIkU78TI53WPH1sMZueKpwjMEl7w5NkE34e72Gg+R6qDPXbqaSiP8KAFKTIT8ZIqxRMdtHo=",
    abilityText:
      "U2FsdGVkX1/hBgYW12KeGuSWB+GmL5k6QeNCDO0byGainL7bHVHCkrQ7w0DMYx6rm+yMZBX85tBkIkthXFGPcWpkJH3OmXEXvlxmDT4azaA=",
    gods: "U2FsdGVkX18cwCFch4HA11ACvL6+Mk6UDsXh/Nu6Nu4=",
    quote: "U2FsdGVkX18RM/rzoaL4Y/gN3/wniAX8D4ALXGUe4tHBpsTKmKYW3JScAIhDkxZH",
    vgs: "U2FsdGVkX18qyvl/Q72KXYLpDCKrj0+iZnGtpDqJ/6P7YrzdzyPGv1/c0HcNg0msFNzlHSv0nkxtvJ7n0Na/EA==",
    spl: "U2FsdGVkX18hZ2kXKjvKpuREnfLCdkDO98/41XV1K1TQfK9ZRufVCPDW4fZZsCB/",
    feud: "U2FsdGVkX196GKRNb4onK19MFfDQJyDG2Xf/5v9sWKw=",
  },
  // Sunday
  {
    day: "14",
    abilitySounds:
      "U2FsdGVkX1+QAvUbI7ADO337VuY7o3sUkK9Q3XuUYr9HOfAH4yIcQdWf0kssfb/qJEjMBzMar3gvyCQoD6iZ1Ds4sU2Vfxs+LQOXYPHc7hY=",
    abilityText:
      "U2FsdGVkX19OfrqyZqJlG3O9HutCR3+GxEAFeGUpAZdTZ+lTKMZH3nWDezsTUoB/Qwr7awHH1AgdaTq7aLCGYi8ivTeOlYvba5+OjthSWgM=",
    gods: "U2FsdGVkX19CChkloXzeCG/4Bj68QIdnfAgHOY/iGm4=",
    quote: "U2FsdGVkX191VeosyAtEqwMdn1I7um/oicVl7xvFTc/IsFDdelA0Fhiw3Kuj+gOA",
    vgs: "U2FsdGVkX18hircG+GHV4ZlVIHK6cxPBHOIdY4uNY1/t+z7mZbrfexr91P6KRozlN5xRLRhnQVBzOjLVTQs6Qw==",
    spl: "U2FsdGVkX1/bZNRgy9BdVi4HIVTKB3TVIx4y6NixDHNB7Y/dVrMbBEU0lzNM/XiJ",
    feud: "U2FsdGVkX1/FIr4ItzqA5jNuwZc52La7AkcNUSe6zHk=",
  },

  /***  ****/

  // Monday

  {
    day: "15",
    abilitySounds:
      "U2FsdGVkX1/8StJZL4UVgOLVN012hQAVyogNQ8gHEp4k6WFEZsSRUtmOR02qVpmD1+o9TERmCKDfNQ8ExgoF1RajFBvCOvO2lGq7vJVnWH0=",
    abilityText:
      "U2FsdGVkX18U24DcHAK93HWGk2toDypJtqHcqChr1mqfr3lu9ifkdyrOAihK3BI/iBhfPkjOIg458a10We+BCToAcEBrPJWGqx4D0MX2KXw=",
    gods: "U2FsdGVkX19hP0gwQ8mTsr/BHdHf8G6TLoEs2VwCVtk=",
    quote: "U2FsdGVkX19FBCyh8obcrKAgDOxTulFlAxnxDjy5BCB7jbrprMJjckixTq/3P3oF",
    vgs: "U2FsdGVkX19OiuA8vMfxvkQHx5gZEbsIEIHt6PUIO45ywc0N4fOCzIOpICGihH1Lg9rLvTVpz3CBeZQSHRYZTA==",
    spl: "U2FsdGVkX1/yi4SLiTmg7OW75jOkp4agn9lB9v2Gb98nhub14SIrNHBYdAJ94y5Y",
    feud: "",
  },
  // Tuesday
  {
    day: "16",
    abilitySounds:
      "U2FsdGVkX1/8StJZL4UVgOLVN012hQAVyogNQ8gHEp4k6WFEZsSRUtmOR02qVpmD1+o9TERmCKDfNQ8ExgoF1RajFBvCOvO2lGq7vJVnWH0=",
    abilityText:
      "U2FsdGVkX18U24DcHAK93HWGk2toDypJtqHcqChr1mqfr3lu9ifkdyrOAihK3BI/iBhfPkjOIg458a10We+BCToAcEBrPJWGqx4D0MX2KXw=",
    gods: "U2FsdGVkX19hP0gwQ8mTsr/BHdHf8G6TLoEs2VwCVtk=",
    quote: "U2FsdGVkX19FBCyh8obcrKAgDOxTulFlAxnxDjy5BCB7jbrprMJjckixTq/3P3oF",
    vgs: "U2FsdGVkX19OiuA8vMfxvkQHx5gZEbsIEIHt6PUIO45ywc0N4fOCzIOpICGihH1Lg9rLvTVpz3CBeZQSHRYZTA==",
    spl: "U2FsdGVkX1/3+K2OlQk/s/PpOseoGPhv2Yef4aGNl8m0YV8t8FBN+KjJcjwlVFpu",
    feud: "",
  },
  // Wednesday
  {
    day: "17",
    abilitySounds:
      "U2FsdGVkX1+6NQCtw7DPo87h3Fhrp2WoPARJTQM/TOZwzbV1pmCuGoIoul3Ew6fv4rvHw4TXiHvqzbbwYA/Bbe5z5RQPCt7lJWb8yKhlnyKs+L3aZrgaQYNYNLkas6H/",
    abilityText:
      "U2FsdGVkX19U/G+wTurX6cUP8sPiqvBoZzHET5alR5lE+aCaIZhmbFOPJ8yse731xgfVovV0CQIy1z4dTgDvmn/sRRPLo1sHAiL3B7DHXro=",
    gods: "U2FsdGVkX18CytAUFaitEcpuuWAfmEui3j4AbYKNJL4=",
    quote: "U2FsdGVkX18ZhIzliJUOAfsOjsrVS1lG2jveMZYw3pyX607/dwRmOVS0WUY/sB6C",
    vgs: "U2FsdGVkX18F+AgJqk31tMKQJtPCTEAjLXFjsmYd011BOu98TQAUUsFaSBgyhHIyNZCVSYd8lunDonni5C/Zkw==",
    spl: "U2FsdGVkX194Pmo4om19eMw0vdrv9m+vxKW3/jIpHTS3gimGdq082V/JUGH5hrif",
    feud: "",
  },
  // Thursday
  {
    day: "18",
    abilitySounds:
      "U2FsdGVkX18RzJ0uQ03YBAdkHm5VYQDyAY7/2QllIxJ6bb0yWoCSwj3L2e9IPCCclfT6lFYlWTPZtci0G46ArEHD9EcAt+V/I4eT+fOJ13E=",
    abilityText:
      "U2FsdGVkX19YAQFzQMEKtA0lEVbzecxhX16htsyJ1ieFNR0ppDBZylKLumpPJw0F3bplarBBBN2q78kz/rrCE+WQFfecs9F2yT/RJdmWp2k=",
    gods: "U2FsdGVkX1/QmQ/EnIIENQHnzh2fhv4uYixAHk3TJfY=",
    quote: "U2FsdGVkX18fBzfpZgd5N33PaNdmx3LKz4GPHAKncPXxYmGd86JW3SAYozrybgcv",
    vgs: "U2FsdGVkX1/RN4UeOen4w1KBY/z7HWZXisRm+4jzmkM6yyJj8UmR1dFpYqWMhOUgR93I4FcC7KxhTwH+564WIQ==",
    spl: "U2FsdGVkX1+S/BHXvUW5Tf8xXF8IwXuPwbrFgrCJ2AcJMqR/HVun7O2IJPohmtho",
    feud: "",
  },
  // Friday
  {
    day: "19",
    abilitySounds:
      "U2FsdGVkX1/jyrk8VTEzqtf9G1Zq9bYGXO1eApHKi0/zyazSDq+NHSMktVgFdeCv/liR/e3aKkgiF0ZSdHeSnNPwSz3uHZ14Mztjnw9lG6k=",
    abilityText:
      "U2FsdGVkX19J/t2BX0vzOHdI2Q1znF0WrUDHVrdDRgRQnQlSmcdpxZ1MIRgWBINMcODkCowzjUcoie36kIl/B17oWXeAkffYKkHQcUN4iXk=",
    gods: "U2FsdGVkX1/oY5273udhBDLFoxrcbmWTKjKSPCMDAwk=",
    quote: "U2FsdGVkX1/P3SLGliOrynKjCEbTfiepIN8DeXrIzzTCR+TjsU3Lo4MkMkon/pkN",
    vgs: "U2FsdGVkX1+OY2k52dL/+TWh44tAp2wIuTUnXnxeY2nUgQJOjTxKcXMP08Lz1rwy3T6S3/39sZwCh06zWS8xkg==",
    spl: "U2FsdGVkX1/hvQYrBS9O136z5kmGGkq4AsOYz+APnCUVuYMskheynyorZ68sNeGC",
    feud: "",
  },
  // Saturday
  {
    day: "20",
    abilitySounds:
      "U2FsdGVkX1/YyIUcMSHmdpYRQF64oP5KwJPSPcBAtfNUrn679lizLyOHiemDSR0i3HUKbJc+QOG0g8lW5iLbbg5sHVgnxvP1vWkB803bkok=",
    abilityText:
      "U2FsdGVkX19QplzQKugv0x4AibhDjtdnXzkiw0vHPM+X4J5KferjedYE0g6euQL8pnWO6SLgCadBaPn8AwvETMR3G/JVItd0r+TiYDMTizM=",
    gods: "U2FsdGVkX19g2HD559weRFa7LvM1Y9fLilwW4J3pDxA=",
    quote: "U2FsdGVkX19kPlcoShPWalVxHvAdXZRBthli6VAB+iGUzyPxt7hZU2NIfbBeAzvT",
    vgs: "U2FsdGVkX18w9STtZEvEE+OinnUhsEFgYGcDJ2YucePmAPYbk2yLALUycM+JoOWAA+Q68mXl1PGo5bTs4Jqx6A==",
    spl: "U2FsdGVkX1+/N9K2kBQDnlUaWLY5KxYInXNmGPPyfMPCfSmyxct2I+N/aYY8i2au",
    feud: "U2FsdGVkX19xskaug0IFTnWlPhsnPHpVdX0ZeDm+Ff8=",
  },
  // Sunday
  {
    day: "21",
    abilitySounds:
      "U2FsdGVkX1/lMBs+QSA7viwgQkxwPWs6z0Z9cTZVR03K4AktG5147veso98gnGftipVjpGZInfb7QxflMLPKvpOgGm1MhJqri7WZcKWrF5g=",
    abilityText:
      "U2FsdGVkX1/U71isq+cZzyjeypFBf8CPJVuRlST1YUZLW/OFgmxXyKgCFcxShf8St8cMklGWzBpEtQEiJ6YpwsqgAG6WtIG0G7qmi0esD0o=",
    gods: "U2FsdGVkX1+GV/6bI+g+a1yii6AJPQUz7B3BrGF2m9k=",
    quote: "U2FsdGVkX1/bcoD2ajIXzX4szKlQ/q00NG4Mg5jr3RihA+b3kbsek0DU0QToauu5",
    vgs: "U2FsdGVkX1+zvn3Fz0RvO5PflAQAB72eLX+cpCn3WYsUhs4ffIHVnkmeWAttDX1g6QFpGB73xnf4n1uKilU7Yw==",
    spl: "U2FsdGVkX18u2Oa4N2N3g86pf+0DviI5ESVWuhOccowmsVj484vpNe2+T22FJORG",
    feud: "U2FsdGVkX18eOtdWc/pQAv9AV/cyr2P+pU1TdBJE5NE=",
  },

  /***  ****/

  // Monday

  {
    day: "22",
    abilitySounds:
      "U2FsdGVkX1/+Q3KA+zTeFjKkAbCfFSFS82bDsCM4zKKZVgecmM8lcd1jDoWUi3gq1ZHC1YzDmNnihCsgb6YJcg3kK08WLO8UypgqYcBGZVja0lFgoMHhn6oJg7scyO64",
    abilityText:
      "U2FsdGVkX1/BESA03bDUj9eeUg1U5AJ461G4ap/zcwBrfrNNMIKN+yHGGu/ibqHJTEg+2rxNAI2Fy5xHpQBTE57zldqY6tykA9XZO0iaLufWiHrMADvWNJVSb+CDbO+Z",
    gods: "U2FsdGVkX18o6y85PiJ6LVZH3t7KbdEzBjBbOfeqRgc=",
    quote: "U2FsdGVkX18Pf4VCuUzRhfYZ3fwC16drKTho8jlOLADJMN4VqH+dk1FFYX8K4kUw",
    vgs: "U2FsdGVkX19uxPxhFBaEn+xbimqjre5n93hQaa8qksGFW3uTR1LEytTOJYjDTQtz+4TrcofVR/ZpCxpTeXmkDA==",
    spl: "U2FsdGVkX19howa9V4W73mp5nnCQfYyNAj0htbwRO/iI6X+WA1F1vq4NSLxnEwAZ",
    feud: "",
  },
  // Tuesday
  {
    day: "23",
    abilitySounds:
      "U2FsdGVkX1+oVT3NUXC4/1LofVDfeIFZabnqJvbZPQnmvXb1CR7rcha9rchUBvuWa7ZZOibW5GmxGvZO0ZZwK5QlsOdFMpwcmN7K1pGoUHI=",
    abilityText:
      "U2FsdGVkX18WiV4WveqZ7IkEINFqEVPIxm9xqgbwpSQKUkW8JqQWuQA9DCqnLasRozmA/val8Jw+7DboVJK2uVt+hv4P+8qYZbsE2thNnRS+AxELiaSPAqZyyYZ8bkcg",
    gods: "U2FsdGVkX18HG4KJfmGwPtNhVBlSm7NkTOCbwqDXBU4=",
    quote: "U2FsdGVkX1+lQsB4VseoML4guHQKEE4lvuhNl4HP2Xft2zKAGx8/0xrwTKhthhSU",
    vgs: "U2FsdGVkX1/v4oEsFWkLKA1rJWgg4pPFHNn7pc1moBG3/SYjl/ABJwFlIyT7PI/0",
    spl: "U2FsdGVkX18TsKuw46MRcWbqIMcxUFYmbn/ALJKAxFs48K2O0pyRrnx6r2W5ZKzI",
    feud: "",
  },
  // Wednesday
  {
    day: "24",
    abilitySounds:
      "U2FsdGVkX1/gyCyxeClyHaoGsuqtJ7H77Hq+jpyeqmvF9tEF+zMVltqtVnzU1E1H/HSsZUK3NImf/0n+61LMeWxz29qwK4Y2pctuFzO6QxA=",
    abilityText:
      "U2FsdGVkX1+ylauQpfj3sJ0KYNMtLjmNGzNucxlUmqcc6vJXt+DtXXtNmPZyve1ZwKreksrs/FdHIK62k2o3HnmNv7gCFZCfdaHtVAW/KsU=",
    gods: "U2FsdGVkX19OvJouQe8vUF4ueLdhzfq1yNXdi/2c8J0=",
    quote: "U2FsdGVkX1+S/ZhMWfUGSJmVP6C0A/BNjuCB3WlzN7RHDkSZqofftooJ4Jm0taVy",
    vgs: "U2FsdGVkX1/MdAOieruP3wF2KrncqCbf6AKqH9PIua23JikI/KlMTsr8DT8DQbFi",
    spl: "U2FsdGVkX18c1WBC981gZA4lSLi0+7pCKyOtXo5MpjxLELpkHji8/K3QmSWz/30V",
    feud: "",
  },
  // Thursday
  {
    day: "25",
    abilitySounds:
      "U2FsdGVkX183ZbBR1fjZNTshmwRwYiWnpa6T+QOgqYWDZglDU06OUzfPEpPdJY22vEYOS0RkFjqs6+z1ONCgo468zbRaPgPiGxE94o0Fwjk=",
    abilityText:
      "U2FsdGVkX1/WXumjDByM9WDmsGbZKJHUGkELgh9AlB+nlYiR6o7EIOMLT5oP9IV6afIVUYYz2Xn+uhyLtBVuPeZUIeeVJ7+zoMZumta9Qu0=",
    gods: "U2FsdGVkX18IrmkrEwInfLjbHDb8vOzBl/OAeScAgJQ=",
    quote: "U2FsdGVkX19vDBU4MAf+iULnY6yGdGg/J8WxqOfWIHbs707KIvTMFCf+Sw8kmjG3",
    vgs: "U2FsdGVkX18J2lVOX/LS1kmyRVUCg9cvb3nNSzK4fM2iRUPLmUFtS17dqHR4Tk5vnqI+/l77ljPheP8ogNlctw==",
    spl: "U2FsdGVkX18o00FcEonWymYoQKHdzf2WWJJTmGilvmjFTYvDVMPA6xIya582XPWu",
    feud: "",
  },
  // Friday
  {
    day: "26",
    abilitySounds:
      "U2FsdGVkX1/jGlYy+hSV2igjs7aaUiAEv56AqrjbOvPSRq4TksvVrGL8sOXiyJX0LhVUd0AUxUK0TaVpi3WYhlVU1XiVPvvHeE6GGhI2Pt8=",
    abilityText:
      "U2FsdGVkX1/6cC2dftNDxE0L0NFmvq+TpSfQ9sAqqvvofxLzq8JwGNbaU8AJWYlU5M4xm3moWWdb5qgn74l65yg+yJ28Gu/0XcecyDQmDpnI6p8RoxqC7YVZQ3zezqMg",
    gods: "U2FsdGVkX1+ZgZCRxEeQM2FqkoSJI7eAAg2hCvJtY/A=",
    quote: "U2FsdGVkX1/ANeAnd/9yI8EClkZtcJ5PmFOuLgbkDNi1dFlcXnehk60aKRCg+M9q",
    vgs: "U2FsdGVkX19tISerfm5j6scPclp5Z9c2Qm+HX/wFyOuj6cw2P78ItA0exV51njZv59UqFvhxkYAAJpgTy46KPQ==",
    spl: "U2FsdGVkX180iNE3aMMCuT4LmsfAfgISsf2kLP+KXkbS4uAt6XDzi4ucZbmEJsCn",
    feud: "",
  },
  // Saturday
  {
    day: "27",
    abilitySounds:
      "U2FsdGVkX1/YxkUbhiVah9FFd7tl3qt+gAa27QL5Dep6EdbexqV/ZyeI60rSNO6XibA1RLBxsZFD5N0jAr6uhC25AKyD7K5wFMLlrOWlt30=",
    abilityText:
      "U2FsdGVkX19Y8xvqT2dD4YvCkgDJKI6ZTrmMyKArzsncs+lUw8KOCzUjKpxpA1dhTtCdfyu29dxMWG8m8wC9i3lcua5A3IZPeyMsDs6MwK8=",
    gods: "U2FsdGVkX1+zW86qiF0rf87M+ecZJur9n+aL4dt/eZc=",
    quote: "U2FsdGVkX1/mQrHa0lfOvLZcFiYknaEcXdPG1DkFX7qKLtUi1d2Sd2TYxX8gGBEa",
    vgs: "U2FsdGVkX1/vlK/eCReIfQ8/jRhcv56fgg1cK+rIe2sqxAmP3pYIk/iUWCBX2OzaVCaafXd0cI8GTcQUloV8tA==",
    spl: "U2FsdGVkX1+Nr+3b6sfOGMvSFf85z0u4ndw0miLifUfQURHpP9oNXzxALda6W5fk",
    feud: "U2FsdGVkX186bahaLfm5rsZ16OblMvXfUsj/41UlPRM=",
  },
  // Sunday
  {
    day: "28",
    abilitySounds:
      "U2FsdGVkX1+6V/N/ew9NV2oIVMFbmOcJc99nwI4OJlFds1XGgXS4VWWmwwZzD3302FiEjAtOAo4Gw7nIishTUYedTIU/Blz4bkv2gN5LU9g=",
    abilityText:
      "U2FsdGVkX19bZwCmpZTANvQ5dJKhVRbzycgFUgxRJt1/FvRn+K+XtxbA7Pznmgzu5RPm8BGF7AOlSbaQgFQqZhDysUFv2orSyf52uc3xse4=",
    gods: "U2FsdGVkX18TDAYMuA0UQn1d3o8d1zqGsQT7xBHYqZQ=",
    quote: "U2FsdGVkX1+u9Izpu2vnvk9vkYw0GmEm2cFp8yHgs5wUB5TY9eAegJvcBVtV9NiK",
    vgs: "U2FsdGVkX19Vpx3m3z/UzGZLXW+8Dvcv4eaXYyK7lIJXHFaqdyjUsUtff36kCZeUy43MJ8ABB3GXWCVtAGUVvw==",
    spl: "U2FsdGVkX18xAQGai1D7w9TyiawzIMudPbuV6nPE9PCckvwz427e3ObDgK0dQaPV",
    feud: "U2FsdGVkX19i9/VEQ82lKJFP2xBKJXFQxMlvJEl1gQ4=",
  },

  /***  ****/

  // Monday

  {
    day: "29",
    abilitySounds:
      "U2FsdGVkX1+Dxbvk7YZMmUGf7rkMflw10HgV5okDuO8+vi8/06GbR9kKgrCCbL+m0VXHqbk7dCmPicjLcVHXyXC4LpgzfD7xakCuQ59+tHU=",
    abilityText:
      "U2FsdGVkX1/uGHYLvRs76epcLpmFL4yKx+eunyOUJtFrW8kk3mbyY/tD64mqpHguad+oExHyBbCJzljkKQB13L6TRhLmS5HHwBI4ZDHOfxU=",
    gods: "U2FsdGVkX1/mSR7F3vSH7S6hJuYVmQn3sQ078dl+f2E=",
    quote: "U2FsdGVkX1/Gm6/6vcR1CzF4snqXQnGik1WJ+bzoPeyXDtPnW8Tizv+qM33bZNxw",
    vgs: "U2FsdGVkX1+JARBRl4rhHIF1sYxDG2M09wvTan1Z6A0u7YVN39OpJbT+06GvWdPK44M63EK0KMxncEscFtFFVw==",
    spl: "U2FsdGVkX195w7aqU9SDe5LLsLCk2Xw3Sfnl7S+MtAP9fXMpPAG7T4n7ei4ZqXDM",
    feud: "",
  },
  // Tuesday
  {
    day: "30",
    abilitySounds:
      "U2FsdGVkX1/HaLaim3fujQkSZhVc9MBKUVlHpKcFgjQqlQRLdgQQaGQ5sV9F+Ec7JnOtw+Ey4boGz5eD7hNdjR26C9Oq5dmJCK+3B8niyFY=",
    abilityText:
      "U2FsdGVkX19Z+vB98ZPpDeE5enRPVIRkhF72987LUWxjL924MfxOAhFiYXVdU3PkT/iXzjdVGd0z4eJ+YVj0oIfYSlTta1UYWSvT8O//fvQ=",
    gods: "U2FsdGVkX18uPD7Gh8msntckXNLZAjEBCfzfSetC1SQ=",
    quote: "U2FsdGVkX185cr0itsr2saWJ+Cf+wdXW4eZNGx6wtmblrb1j4mMzA47Dpi03FHix",
    vgs: "U2FsdGVkX1/NfQr5XMMgwnQ6cJPR1CJh8DsIsvHhIWyyYvyBTEFblqS8Wlq9Irnv/EhzubDsWnGgV2Br/t/03A==",
    spl: "U2FsdGVkX1+D47HvAJwL37qrLR0/kzgZ2NW3jLdqr6lyIMJSPKUi/BsRi9JWEgYb",
    feud: "",
  },
  // Wednesday
  {
    day: "31",
    abilitySounds:
      "U2FsdGVkX1+UVMwI9bDEQW1lCfTnnU0Yva7+yIkozmWfsSgAc52YlLBSXdETDM8obDiL05VkFtagDN4sxduqLdFQJZqsf0WKMzK9ZMH6MQw=",
    abilityText:
      "U2FsdGVkX1/ey1LrtXijQ5HHsU70hi/sBzWruKmdQu6zPsao64N+xgdaiohgQddGziarPHNwMD1z0qLY7zrFktjkQ1jlYufbghpQKABQSsA=",
    gods: "U2FsdGVkX19DWIJ5BtQQVJZ3OKm/wbYxPq4+JbjJYfI=",
    quote: "U2FsdGVkX18V5z4gKM3Xn+/zULgXvJDd0+COsa5kmFGfwkp6l7whZ4XzKwlhaJ9U",
    vgs: "U2FsdGVkX195YtUv6MeOKqwgS1nNPfffO6uJMEexLoZOHEfU17TnYU35e0bddzveL15p5jFlFWCLyvaf3SD+Dg==",
    spl: "U2FsdGVkX1+AUVVgrAAU2yQsSFbHYvEWACdNdo7vYO/o1YCQcT3iWi6AvCwdox2s",
    feud: "",
  },
  // Thursday
  {
    day: "32",
    abilitySounds:
      "U2FsdGVkX180UGkUXLJHcpNhzfV7j8vJz9x2GzZvn/JVIlkM2enEQ0IZTwGa3sGI1O4fss2mjxtyyx4bh2oCEk8xn3hrKK//aUUhJIB6bBw=",
    abilityText:
      "U2FsdGVkX1+t/WOWdcO7gG0N9f4jjPUcPW30t6QE6xbEaQzBpgXVgZcy5q8nzv/sBMGEsacArRI8YGkk0uq+gquzpjFIpV+ppM2PyMvI/NM=",
    gods: "U2FsdGVkX18V6/tzBDKlqOXO2n518MAZaMPnKHu+JCE=",
    quote: "U2FsdGVkX1+ISWrSebctYW6E+PvQ4dZkj6AvNAKrQadnlcSYEzrlCkQi3rzi0g5c",
    vgs: "U2FsdGVkX19nwMCUCntrYPgJQnt8ly0TcXk5v9UJMiHIQzpQB+uEHk/HR2WPfyeJl70PGAYCvRLobsnU3RkGJQ==",
    spl: "U2FsdGVkX1+2ieK+XKykyRB0rQ35plVEeWOqn7nxnP+NAR9OGGGM6UK6Wz+GpflE",
    feud: "",
  },
  // Friday
  {
    day: "33",
    abilitySounds:
      "U2FsdGVkX19KNVP4g8f47/qqDqUr/kNkJ4m2Oz0xBWkkSsQpYRP25Czcjmkt73h7Ck6W2t6TGSDM+KrqP+xIKtfaOFt7NqyNDfYULaMBBi4=",
    abilityText:
      "U2FsdGVkX1+EuF20l8ZXLhBb6kcqlR6mQmrfL3MmvvoI9IWkjF/Yynqm7ZK0RTDjK00mvXqECsS782WRujBH06jCoyyDfpa9jEkVaWLHyW0=",
    gods: "U2FsdGVkX18SuDrwk2RxfCRxvZS3j3bPdniruuGi4Tg=",
    quote: "U2FsdGVkX1/NZskqchN953VXu59jLQBngPmva9+GFXIeSonWe7hdCFq6ZHwI0265",
    vgs: "U2FsdGVkX1/MjXeKV+ZJRAfWMaULuyidcPGKhQJ1gVD7Vg8XdV0gf6dlDlvNGF1AAT2HK64E8N2QDxN/WPShBg==",
    spl: "U2FsdGVkX1/B6n168PDLnUlo23pfw0bFWkto7dwKh3Ik33I+oiaHTEJOawVlzcc6",
    feud: "",
  },
  // Saturday
  {
    day: "34",
    abilitySounds:
      "U2FsdGVkX1/VqGnlKxk9mhgOrDU4cRO2l+nrUQyXIZskvdncunP1aTk5OZ7mwuekfalkxtleEkQR2MdOFcK3OHQlHQHwLHHnohrDlOsNbS4=",
    abilityText:
      "U2FsdGVkX1+833Y2+C4Q9KgD55FmqXWPZLSJWITug9lMmyYcdng+4nKRo5l942XSeGPwl8O8Jn2RN07rzfxpKsqXJLbOBMUlqgbF8FTeZ8l5GaZfyNxOvjhINUrSh4jv",
    gods: "U2FsdGVkX18obWpYPat9v/uKFm8cb9n1ZvpX3ctIOZE=",
    quote: "U2FsdGVkX1+FkSIR3mjMNp4rVLAoRUTTA9kmAUhw8IFCSZhhd8N0txwsz2HG4Tvk",
    vgs: "U2FsdGVkX19Fr7QeBgNe8hNESfklVogPFzNpsOtvnhf0NSD5MAfsn3Kn+i/5Z3LdLvDotsnKVfDeNXL9szcyHw==",
    spl: "U2FsdGVkX1+fQ0WQcXaDn/ziJEvEMMx35pxUYKBl1K2iBNoLwdBOWBMlF8O4z9p1",
    feud: "U2FsdGVkX1/CzhXNFhYiFf+ZPv/cdGfKLd50iCUugPo=",
  },
  // Sunday
  {
    day: "35",
    abilitySounds:
      "U2FsdGVkX19Cm+H+/RTvBhl5Jb4aN5mnHSC6V9ubA2/73Rb2UcBP15wbqTLZB7rMbWiWuG/WpMshUBXkTyQYs4xZqspUfo5sVHKbA45AXRjQdJLXUq2Gs824TKaz7XOk",
    abilityText:
      "U2FsdGVkX184x80bpihGESy0JEX8/NZz42MtoB0rY5SMUJM6gvdAC7Lp4Fb1W2lQUdwy1KtG+2SnEniYFdKd/3W/wq/PL4csHtMn/9egOLQ=",
    gods: "U2FsdGVkX1/vup5mzU2zPhi0lx7pojd90bCYSTy8nPg=",
    quote: "U2FsdGVkX1+H7hdwpvUHbKAha98oBmnR9M5Y7k36gzLmJPLrguCnVZQfpfiwt+Xo",
    vgs: "U2FsdGVkX19fU0APE+DoH+zJstxybx9JFVwOeKQKJTGcKuGT8lv0VeENMjjg8IGSCHPFTMQqERHYYZ6febRzXA==",
    spl: "U2FsdGVkX19WwOx+chSALyJ96GzdOhLDNKQchtX6YMScd7+wOc46buOT8oQfxMKx",
    feud: "U2FsdGVkX19+eWS+ddx2llNfhCSfdWFfCeJqxgeqWP8=",
  },

  /***  ****/

  // Monday

  {
    day: "36",
    abilitySounds:
      "U2FsdGVkX19JkjpGdqIT+Ri9RsaaLuB1CbH4eEdp8/iCiW9lr3Gb3XL3uYJigRc59mcdgRm92ZdlB7iJ9SMkx5eg6/r9ue+97vdOqikwBdpUZKQS9e5tOcCt8FwyEPLI",
    abilityText:
      "U2FsdGVkX1/+70q4pE869aibgsnesmZsPiuEBiy9kdRW/WcUxvTJgTsVKTgdhkVUiZ7itI3lB8Kjc/mXDvzpHvEFGrQacsTNyxUkGkiwVQw=",
    gods: "U2FsdGVkX19uoimzq4fP9Aq0ZiEx0ZncqEAxJmmlebc=",
    quote: "U2FsdGVkX18Mqqs8Ly9ufslBcSqYFrOgJRcpq7ULEYa1HE61A2ujLdQx4Ey9E05M",
    vgs: "U2FsdGVkX19NDOXcAiMKkeOcZeAO0N3x0zSj3ErVVWohL0k58FUlfPMEhpiHbBtNzHx",
    spl: "U2FsdGVkX18+n/Hwd0jjx05rI72gCgS6d03RwoyD6z0J6vGneZNNRFwtgamyvzIv",
    feud: "",
  },
  // Tuesday
  {
    day: "37",
    abilitySounds:
      "U2FsdGVkX1+6FOwVKKh8vhqKpYq1EFl2SVxs3f9zdiTvJo57tKZ2Aa0PZUjl76DnVpMic3RqiwlSHCgRaiUeUQno0IcGqs699wqn0Et5YWc=",
    abilityText:
      "U2FsdGVkX1/tsODI02ksIKX89NiFukgNsKFmOZK7rznOrJFhFts7/LffGAdQWrlpMKbeohMn6bLIGtH5SCY/bVGK4vH09HxG71r7lt9ljmYLM4pX7mOGf+5vqHEB6HZ1",
    gods: "U2FsdGVkX18032i4Qt/P+I1qSKXbN+q+7dHdnugiXM4=",
    quote: "U2FsdGVkX19e4xO9jzVDtptAGKHp/KkiKMk7DckJI44cdTSb7fASQvHxK/+00gUa",
    vgs: "U2FsdGVkX1/P52yQP47kS6IzVtYeswk/+CLr1S85vCOyKxYTRWh+twADDqUnEceIVU/E6gMROqt9/7ZwNBc6Vw==",
    spl: "U2FsdGVkX1+RrzTg9TFs6EDy3ilNvICgqpu2CA+letc=",
    feud: "",
  },
  // Wednesday
  {
    day: "38",
    abilitySounds:
      "U2FsdGVkX18MRlGI5ng+hpTzEwA7d8vfIqLuKspjmdFuNEkAIa+fDsKMkgajBJ+T4m6GQkCUa665qQXC0kh6xfykhlFC0opx543CfGL4Bps=",
    abilityText:
      "U2FsdGVkX18DfTCLUTAvc4zqfFdndwBYQ7CSKoXXhoPTAbn/zzOutjg1lDialr4Ir05CnWMx8o/hZfGNb08ahe+FcM4Bg67H/RC9NVMOMlA=",
    gods: "U2FsdGVkX19Dy/OVtzm0EB1h6YwLok0nGMNMfRrY3DI=",
    quote: "U2FsdGVkX19qKYTpt7W8tMcaXHJFzmdrJhnH3fgv3DJ2BRaGj39HTcsDUFzSwiTN",
    vgs: "U2FsdGVkX1/SDo+PFQkJ704DEsd2T/EYfdZ7K7lzN8veb1/HvsvE7cENS0yI1yTmWtUE61PqonI+jNj+gHM7Gw==",
    spl: "U2FsdGVkX186zazwOFa/JuUOLJNHtI+YpPaCrjQXTJE=",
    feud: "",
  },
  // Thursday
  {
    day: "39",
    abilitySounds:
      "U2FsdGVkX1/X35AVOdW5KaZzDDrCQI3Wf833HBA0cH441D+cJmRtxxhiTvwTfwO7Zf+90DoNT7456/SpkE6xfXPktKvutgF4LxJeKe09Eh4=",
    abilityText:
      "U2FsdGVkX19d+DDjb6ky/mgLbhaC8bR20x4unBrOOByTVLQPyVwJuJUvAxigOt62m+SPbqF4i34p+si9lkpT+HAou3nbroOLG/VS/2dGvus=",
    gods: "U2FsdGVkX19H0vha/BMsbMebvJJqEoTfCOitb9GK5Ww=",
    quote: "U2FsdGVkX1+EOSEOZkqOJ83+47C1JStfeNL0CMVqT9bIrzpN6oGrA9UKRC8faYdY",
    vgs: "U2FsdGVkX1+hocxq4gyBIbCE5Uumu3aOYmIe7/RqdlcP/YI6izgopEJ6H+41b4RP1pNmJTNa9F3xSaHhWlXG0A==",
    spl: "U2FsdGVkX1/8iN74ESGbLEm5cOBOGCa/5fhx+//ew/Qu1+EQpBref4w2TqznmiQv",
    feud: "",
  },
  // Friday
  {
    day: "40",
    abilitySounds:
      "U2FsdGVkX1/SFQDT8jMl/7FLBXE1MPAbepOMRPYOozoOcQbDnergRehFcLC5MbG5xhdQTnQeky0ERDMq1AM5ufiRQtSNVgK/oIa4PKpOUC0=",
    abilityText:
      "U2FsdGVkX18/xmRbNzbyNDEqBVzmX0njLuW9PyCqOW+K++ZbsQJD91b5gV8OJwETByptdbildqktqHE8A+eCLoeHivVLsB/g2R3sL/xi1Jg=",
    gods: "U2FsdGVkX18DFCK6kLk+6QsKgQo+SA/ysLqQLh1UHWw=",
    quote: "U2FsdGVkX19Yjvcvw5EI54l1FToXtGoSNcB+hIUrBdzrt+lZWcq9WZ/15R93MaoE",
    vgs: "U2FsdGVkX1/ILH/GGf+L5YzAk8gYnkCJRrvjbCttf3CdfjPs2ctR7hxZQA8poCjQjU1rQsLXcVAE/NETlICWdg==",
    spl: "U2FsdGVkX19E98qTv1cSEgBjZNFAAUpppqby3KI7ASunNhvOHbUptwgF5SqbaeHq",
    feud: "",
  },
  // Saturday
  {
    day: "41",
    abilitySounds:
      "U2FsdGVkX1/hYn1JlzYN5AGE0hkO35sEr3p+aayRe596GUyy5E5H3QgKzfresqEJmzHmWZa0BzCnauK+Da1Bj+t9L7G30v7nhDMXOubvBjk=",
    abilityText:
      "U2FsdGVkX18UgGEe8VivhY/XmwIH8kZE0kM7BSfmP4suU4u9jS5LaVBm4zQKg7gTS42prakf0bZc8v7gDpFBGfc6HmfA/jXI5bavdH80NrCXg66erQYj6gN5QMxylSi4",
    gods: "U2FsdGVkX1849nKL29+kEP19B44euA/N0ezqGgVSGEc=",
    quote: "U2FsdGVkX18WAXBkvQYKCajmuz/JG4WlQhqdGvKqQ/i3IpHzlS+ULMWY3pydNMXS",
    vgs: "U2FsdGVkX1+SRwju6jqy13f5Xw5ORd/0UMimntzogN840EPoJ1tfG0vUPHBCdjXB6j0i+5uCbXJ/cBNekHzS4g==",
    spl: "U2FsdGVkX19nqZ9pgGYj4p36JqhXZcpSfptU+U7K7tBYEUOXkwVVIvrZEADAtUlU",
    feud: "U2FsdGVkX1/ouyCnJ31qA3Vof7xvURyN5SRw5t//P+I=",
  },
  // Sunday
  {
    day: "42",
    abilitySounds:
      "U2FsdGVkX1+E9nXrxPUPul4n/IEUqu71B4WaK+D1wrLmZMmMg3cVE/zSbSU+wnctJuGKtHQnW23htm4c/EQRcvQRt1BdpyO94YQ+PxM5SBjMbcmsBmjt/sNcFqRQP9c5",
    abilityText:
      "U2FsdGVkX1/ttyBwNHzKrdkfFEcGD/1c/GOCtux55RcGN8xIQrgxy7aNjZODGZhwJukiXyixDzictn/Zc1TXuOmUmBFls47ot1qUXpeqfAs=",
    gods: "U2FsdGVkX1/NSKlcz8F86zSWSB+DJel9p8J2JhesKJE=",
    quote: "U2FsdGVkX1/JoTZY1puuqqHmc65SQinigOiFE86kFj82BOEN1LfUhJUoIA2AM6pE",
    vgs: "U2FsdGVkX19a3nllTg+lJNG/6QtRFfwLqgEvqHk0HvMEmzdhypvfDr+dclMuwYb30YUF/arWUXJMk/2hETIhwQ==",
    spl: "U2FsdGVkX19MVc9PY/UK2A7qxHVmZmUGwgMqxfW5sSUDN8kK19to0yqodNklsDPj",
    feud: "U2FsdGVkX1/QCEaR2TTAfJyhPag17VVVw7xRzUfOrHc=",
  },

  /***  ****/

  // Monday

  {
    day: "43",
    abilitySounds:
      "U2FsdGVkX1/N1p6GrRiiC1oj3Sr3v6+Sm3Ix57TwowPJrY95v3YeC15ufoVwAqdH5PsdcOr2dOHPoJdQiAWN9GbO4aDtQ1cMNb1nD6CCJt0=",
    abilityText:
      "U2FsdGVkX1/gs3tz8Qk7WYKpN9A0JyXDnu6hI0d7llYVseVLKmnFAzYQbYr3Wqzz2ag/Sg9iGtyLDkOp1TOc3qujaF/Vfd4NM2SDUXTLHB8=",
    gods: "U2FsdGVkX1/6U30wBCeZW8c0W5DpGp8z5phbTHumGv0=",
    quote: "U2FsdGVkX1/UvtYwdBiiP4BlRus99bRnFZ11C+7ZyJv0HE4/xLsgGi00RhcYIVfT",
    vgs: "U2FsdGVkX19PoY2xU87o6R7A7fFLNSjIITjNXi3EM6vW+9umJmptzYbdDXFflZdMFcPCfWWkLwBcgySXNtYJew==",
    spl: "U2FsdGVkX19JM+gJCX8RPayBJJY0Qx+3Em1UUTMcDvoz9mi7mYjkJb7GscxYboA6",
    feud: "",
  },
  // Tuesday
  {
    day: "44",
    abilitySounds:
      "U2FsdGVkX1/pKawCGtgjenF3B4H11PlIfjvbHSvuBHjp+c8PwYx1U9NlTrRGE5YHIgYCFYffCAf8AaizPud5HhHBYFWJ8cpOdSxyDeS4SfU=",
    abilityText:
      "U2FsdGVkX19jU7JeYY+Hy9bmsxgfdzoOHxBP9PWJhp1de4iRXJ/sxdJFPW4vT0OzxrA0b8OMezkU2LaIAhGZiA==",
    gods: "U2FsdGVkX1+3x/PIYUQmDzJWG73+DCfrVktUOjjynjI=",
    quote: "U2FsdGVkX18rbpQFWg7mbyX38lBWB5ypfoanlQEo6mdPJakF1bPOaZaIhkhiB4yw",
    vgs: "U2FsdGVkX18JKi92D1V7SlF5krjmBFmdPhjY/yWhAK4ACJL2fM7ViFAiCoiAjkRc54589e6kBrWCTamZ31tDhg==",
    spl: "U2FsdGVkX19mjPhbIaOcJN5uVGpQlhvzPtLcj2BlrOBu1TASAoZno7RjSUsLV/10",
    feud: "",
  },
  // Wednesday
  {
    day: "45",
    abilitySounds:
      "U2FsdGVkX18p/ff2UqT5V6RBzNFKPphvp4HTH7KDPXUMZDmPuZprKWNizVpF1poPgN5TB3MAfh/53+0Tw97gBWUYb3o7KXOOFF0ahIKkX2g=",
    abilityText:
      "U2FsdGVkX1+GqKW/agMB+q+X7DaNP8jXajwjUb7H51XmQyeJ5JUOT17akjJCELNsmfgSCNCQmgwI5o964MMvBqMrS9znfrICjFB4mZoQVPw=",
    gods: "U2FsdGVkX19FsYKW2kZnZKAGfTbSIcGwTS62TGq6AlI=",
    quote: "U2FsdGVkX181IgBmbfPnzijmJs4kX0XrvIs29i5awoMNwDQsBRbslIAgZaMigOu4",
    vgs: "U2FsdGVkX19YYPdXAoN6jb9wT4Crz9SvwxrA3X+BDv67OqSm+h5wz0LstY0RVhvSXpzjR8NOhtwuZw3qpD1sig==",
    spl: "U2FsdGVkX1/zU2LmykytQ1TLtT4F55+ivQ1wxlVya/knfhJNg2x9cqDlhCafO7cd",
    feud: "",
  },
  // Thursday
  {
    day: "46",
    abilitySounds:
      "U2FsdGVkX1+wmZvJ+TadQbvqU/HsNwCWVEWeezYds38cSPfBUxnYUoNDZORxID74Y/tPzFT7iWQGAkPWE+LZpdhap5KYbAJRHPafSCRAnVM=",
    abilityText:
      "U2FsdGVkX1+1lMUW3b8XCCRhIWAbVx62tDXa9s6Az0lbM2Goem2GRjrB7p4s/Kl06n9U/krL+jr/i1n53PvGQZPWQVXRFhoysGpFDoi0UuA=",
    gods: "U2FsdGVkX1/AzQ1p2z4Xr4ZAzZEWkjZafi4hJJigCfU=",
    quote: "U2FsdGVkX18A8jMa3kK3lnit/MF1NGKg9pVnUpfDbK6rlp8s1zllke+IC2iEleGy",
    vgs: "U2FsdGVkX1+mhkv6jfpirtoJmT+aH+RhRtE0+ZF4bTBgrv0Mapa1/Sqi4LQR3tOS",
    spl: "U2FsdGVkX1/4BQuHxsT4vbBVPiBtwpCyW2vaYHAIfq79LHD6QlH4D70V3YS3XOtP",
    feud: "",
  },
  // Friday
  {
    day: "47",
    abilitySounds:
      "U2FsdGVkX18wbPlg56NSk4F5OEA0nDSN0BtRtgUDtILvfgzH5+cYyLzfRBEMdZhwQVZKQY8Bl4lwub5yFIYKCYYT4G/2h1efkYCRDDT52wQ=",
    abilityText:
      "U2FsdGVkX1/CEjRP0PI4MD8phXZ9IMkDv9F6kv5EifWIdmZYcoYa9bWAPlmBweG5J/IGQ6djTsUIftHURUHDzrKhJ28dNZltm5pQszN+3TM=",
    gods: "U2FsdGVkX18YZAEaVQcOPnsx2kKsqPrtE+AQs45YuFw=",
    quote: "U2FsdGVkX1+SdZ5xhp43gWdIs4PTEys6pApUe7pHhgvlSK9L8xCaWI7ABOSsi+nt",
    vgs: "U2FsdGVkX1+ActTIrFUK0K/i8ldQ+WZoyLAbKwlR2YrcGPO5IfnDX5tDXyFDkIBpdHnvdbQTLCxWqSmELNsF/w==",
    spl: "U2FsdGVkX18fOySJUxuUw7YP5INRq9Fa7D+CLv4xQW3GLl5tisUUDlZmtpf76gVM",
    feud: "",
  },
  // Saturday
  {
    day: "48",
    abilitySounds:
      "U2FsdGVkX18CWGy1Hn1uPz6KnVyIPZ0S/E/ReTb65plgi9+KkoaP/pzCUkiobl3G4o6+M0p+gM6N2aJQWQksSkRxP1yFgL1PASTSLT9iO5sPYv+U9VdcCBePAF1tUY2u",
    abilityText:
      "U2FsdGVkX19X8iGGdPaJxEfaWV2AZt4TktEo5UTiC4MNaPTjuVIOgffG3Zu/E/YBpvgIhHkaGyS1WwkTXFb2TUgu1OCSDnRKuJmIez7SmXM=",
    gods: "U2FsdGVkX19DRggkiEgn7ZhOikfjvIC9OGmPShCLI3s=",
    quote: "U2FsdGVkX1/AVcHsgW9EXYoQOaxtDHeaRUsAjsstYxmPAsUKfcZKn2ahq65XC8KC",
    vgs: "U2FsdGVkX19Ygh4EU5SSmxWYSSEqDcVnzjBwEk231gRKSU6rMMqjoesZ8mY4WBRnA62Wqv2vcyrPEiGGy1IZtA==",
    spl: "U2FsdGVkX18Q1uxlCxE4G4v/8cVGRKLAaQXvUDkVz+ESM9Ujm5BQKVYG2z6PtRvB",
    feud: "U2FsdGVkX19JBQJFP3ZGBfr3Oc5HMFvwDH/MBRYVfVY=",
  },
  // Sunday
  {
    day: "49",
    abilitySounds:
      "U2FsdGVkX1/k+VQW4A7qIJ1Koa59opE7HOD1QlVB9qIeJsJo2xys5+8nlvWFOn52/dD3lT2FkzF1qe//qO6dRn3tArGfXWkXUXnyfSteqbI=",
    abilityText:
      "U2FsdGVkX1+FI3QyvVGdYrRfbpJhfvM5LtKCWKXfw4f+UwLMRxGh6UrLGnWdXqB/mJH6PgKHBZJsZ1zSXaJf/Uqkvbq6l+Fzw74Ic8Veqw0=",
    gods: "U2FsdGVkX192xM62jzAAoiYvtKhtoeop50DzuZBcdLQ=",
    quote: "U2FsdGVkX19oyGeR4+d+As/9FW9RBAJLL7gym9g2e6hZ7H1H9Sxv3Mwwk57znF9j",
    vgs: "U2FsdGVkX18P1V9GI1Bs1Qg/CR0y2g0OKl0pJvCLrQQQS1k3qvl1s5gNRp4jaVDn35UIUKgJfWFG1pjmvHoX5Q==",
    spl: "U2FsdGVkX18GuZCRvjqCjxanoK9Z0wvmySxZTDT5D4Y8HOvSVMY4ysviDWrF4xn9",
    feud: "U2FsdGVkX18MR+0ykznrtdeC56w5izdByHs1WVY8MG8=",
  },

  /***  ****/

  // Monday

  {
    day: "50",
    abilitySounds:
      "U2FsdGVkX1+ZWWYGDhvLSDpzpVP5VG7ODRVt6VC2m+P+UQLYdzml/lv1SvcZ+brRKeogdTpBkNbua4fyXuakV6h+QHgkA79upPXl4aGkkfs=",
    abilityText:
      "U2FsdGVkX1/fzEcKnCxgrtr2XB/lnMoN8LcObRQllvpWTyR39ML10WQ8qjwHpP2LXntQoARCkjJT4SeSogeeofPjao6q5u6rT8RxYDSeNwdVtPDWD21qPbnRFSb//+Ps",
    gods: "U2FsdGVkX19ovhPLI0+X3ffhsPi8LeKeddfx6UVCvjk=",
    quote: "U2FsdGVkX18GOLJmO13l6FaNwSHioPpuO381ajIxGUpULiPmBAmceEsK8ESRljgH",
    vgs: "U2FsdGVkX19dSnpO5CDjsfvUvBGjblWBBjCMwl068bbHlYa0TGPGcZ/7zGq09lxITgCg4mwjbba5towJikSKrA==",
    spl: "U2FsdGVkX18XHYYxsSGjb/qin4YC4nM2g8cVs3mzsdBIEQoVhqqSfziI5+gXa7nH",
    feud: "",
  },
  // Tuesday
  {
    day: "51",
    abilitySounds:
      "U2FsdGVkX19AcgbzeO+tSY549AZpAcr/XL13gEROpMBfJW5pSP2ZdK0jp/2aq7FpbNAy0gkhvCpapSucTcbF2IZxioBLCBAt8Fiy0YyLK/I=",
    abilityText:
      "U2FsdGVkX1/piRFSWEHRH8qcEgnK4ETOJGFcmKSnsETKRpj+lC9qzo1WRO57AS5iPW8rPqQIiii+b/+/IAVACfQGldAdb8JpxHxeKjGaqoI=",
    gods: "U2FsdGVkX18iI+XyNfSjR8IU4ols1JGmyA2CbwmMdxw=",
    quote: "U2FsdGVkX19sx8ZEutoRSvmxpBidHgeJNfLw7mzOsgi2XmCOyQJMAPW2Nmnys5YA",
    vgs: "U2FsdGVkX1+0mBL0CfQnOZhMRaREOoKhdN0vIIV5MZ4FDhdZLkoTDQFzQ7tlvtGHM1/csm7AVq4puDx+o/+5mg==",
    spl: "U2FsdGVkX19IuBGhgS8f2OBEeO+Y/0hshsgJS/rH5Qtb2FSD91h/3ofcQdr11NU1",
    feud: "",
  },
  // Wednesday
  {
    day: "52",
    abilitySounds:
      "U2FsdGVkX18bJxERq2fpWOPa8GDWwvQp1JLrsBxJ2lFbGouFPhn2qNFDZYF1akuUqFLbYfPMaHTw6mFS/xf9p+TTg2bX9WNJDdWPYnXhc/8=",
    abilityText:
      "U2FsdGVkX1+bUh2t4VnsSfpdLl31Jc5hLkGLr37L4RDeiLMfa2ppumogkHk3YK/U1bqHtobHDpnNyOwIRoI4yg==",
    gods: "U2FsdGVkX19iq9ibcf+kDZsy2iT7uAHzvKlFfJNt8uk=",
    quote: "U2FsdGVkX1/0p2kB8RZGmezOjUu3EEVA1WY/hdKZTDm3OWRauD0MhHEFQxXJDCOM",
    vgs: "U2FsdGVkX18sLJeyWCQbdjIbkJXc0t2V2I2aMZMAuugs5KQzQGLgwmbsWuEOKEqKciW2aK6f7XdnNAGOa5laRg==",
    spl: "U2FsdGVkX18HH0AY0FZisvj2apTHWK+RSIpWCTcpEFCNrSf1Ib5xTkjWWoZIGBrH",
    feud: "",
  },
  // Thursday
  {
    day: "53",
    abilitySounds:
      "U2FsdGVkX19E5VwAc4GFBnUi5FKw9PYLttnpRV3cG5fTTsa6QOHNl3dAoZkKPqe8fR1PVBRFt2driIQ+2cTZm0hcdns2LkIS/Cw9qQ9G79bA3hd45AYDJVmuoG9RlWyw",
    abilityText:
      "U2FsdGVkX18ZmzCjLWs8fWInDT1XBQDNiKHW8aJYUH3sWnhcwYGzcZfoNHEbWscwLGiy/6NxKZJIRsqYph/0nZBCx1A3XZyt9M4eAXoNKog=",
    gods: "U2FsdGVkX1+PnRuP3tGnSehy/445qtKLOCwMXRLi06Y=",
    quote: "U2FsdGVkX189BXnpheabUUU00ST77JxZeplQrw5IHTG6Pn7eeIvQZx3P2xegEg2+",
    vgs: "U2FsdGVkX19B+yfi4E7CkWdf0+bJvPH61xKN+maMcEWiqcEqn00ZOBVhVUmhqlc2eLkeK8JpNz7uNvAtwo4Wbw==",
    spl: "U2FsdGVkX1/SBXGFTrQLa5ArK93oQIxE2ieBbbz227xrXKP7TLrO2b2RL7VE57rF",
    feud: "",
  },
  // Friday
  {
    day: "54",
    abilitySounds:
      "U2FsdGVkX195BhHH1XhWL6Ad5ynJ/vNxtlKYqmdpJqoGF75/ya40Jm3qLUJ9rNvTsTpzysPfbh//dygNjucW0KSr/k31fsD5qkxyPwbKrmk=",
    abilityText:
      "U2FsdGVkX18GzITF4jSfcQRA5adSav1D/rCmjLjckUanP8s1xRfcV6EQuIXfn7u9pYolQhfa9E3gBXVYefD8Si9PYRjVVGW+oOYWmb+LG0RZR3R0mq8eKDYg1yrStHBL",
    gods: "U2FsdGVkX1/YvxPGEwbyRGWYYaGblgJjIUmBKjH3oPA=",
    quote: "U2FsdGVkX19FMiNFLJcNuZLkjPp31ZeMXAlQKui6sHlnGTlBtVG/3y6d805NyhfH",
    vgs: "U2FsdGVkX1+pCxCRR047RnrXsrIPTOX2sbaNetYAtMP4VTZ3YIfF2zKkmz62T0N2ny3sHj3/6EthCsD43vjokg==",
    spl: "U2FsdGVkX18BQ27ONUV1Nrh8/qFrhxLzTo5+b0QmQYcZ/jcnUCVkjXsKqRl3B6MV",
    feud: "",
  },
  // Saturday
  {
    day: "55",
    abilitySounds:
      "U2FsdGVkX1++xnyc38bRTppBLRi8ACEtp2lHiTJOm3KZ3FdBxvt2bH461zLWp0t9nEI+UynwG//Yrb04sZHbuWlAwOABWuhi4pgWtpuYFog=",
    abilityText:
      "U2FsdGVkX1+dK/N3YVQUnJitvLX+dmwUCzYoNaVL2Nm6yEXlL5mVNGOZkyG92hQOhtX4YP1dqNLBreEIcRdW/NM1XoPwhaw/VpqYdb0RKLE=",
    gods: "U2FsdGVkX18JwsrPrCLszLZU/YahGdhmQ1LK91m3iLw=",
    quote: "U2FsdGVkX18mE3hkWSVCNBTN44AxGyuuISzjBwOo2h1RCPS2WvV5PHflGJKcKPUD",
    vgs: "U2FsdGVkX1+NXLhyUF7xKi/KhDBM3vrAR6SBJx7Qbnzwxf43Jq3MBks5Y+lrTB8lcBiSppIoVjWtiRioISP2sQ==",
    spl: "U2FsdGVkX19cdFKvygLcw2iOsR7UQSvvk1XXdpNJmPv3xBOkApYTflXQqth8HfZC",
    feud: "U2FsdGVkX19PnPggVB+Ki1Ih5OFm0NMIVzjQeiqS2As=",
  },
  // Sunday
  {
    day: "56",
    abilitySounds:
      "U2FsdGVkX18bhP47lRn2QNpDRvxpBMGFTV3DcauNjjyEi2+0Q4n7l+uM1ljS4JvVk44RJLH/KL6KY7JcG0OISS8VHckGHHtwZwtTXHdQuGPvwoGhJs4O4whcgz+3q8yi",
    abilityText:
      "U2FsdGVkX1/CAOoZyw8dtqh/RzNJX1+FFAQ/1tMp/oHqPiCTIWi+yuF7wv9uZXfkpzXowqSz/fw7fbaIDe7J3w7TMS1q8NdckxQ4Czr8h+E=",
    gods: "U2FsdGVkX18R1miteAEyc2GVsZl/0X8CEH2znjXErTw=",
    quote: "U2FsdGVkX19qbf2gC7tTCBNhUMY2KLVZllKImj2+/0nr4r6HwKhZHctG+AkbrZtx",
    vgs: "U2FsdGVkX19Si6QvvMD8Bmb5WNSdWrTylsrk1aBxfLS/2z/ue+6sbC9zGQ7mFOL7qd51QzdfkjJuBJkvgSQtrw==",
    spl: "U2FsdGVkX1/kgHCuwdhviTYbIE+pIJVToU3+PdUG2d9GmNcFEMDrEbAwmP0hDTS1",
    feud: "U2FsdGVkX18DfIjTyG5sQ/BENZgvCy9mMqPq/TyXj7M=",
  },

  /***  ****/

  // Monday

  {
    day: "57",
    abilitySounds:
      "U2FsdGVkX1/Pv+XHyhjZvNd5vdheBdN0Aa7XKUmgbyR/7qCGrcmtOywbeQGCRGvjHSLzgTIyEyxGZq4pTZSq3VazohYcrCKqZRG6ojbTSmY=",
    abilityText:
      "U2FsdGVkX1/vPRtTnvu1jMct7FfWnMLV2vO7dHhzq0WESfipKSgZ9YiJ4MxFh5elgnFglCxoMwtDOgDVZ6v3MTO1cTYIMdoTvEv1SsgDEGY=",
    gods: "U2FsdGVkX1+35vF1Uj2OlWVqM+XZAgCHw0dQSmNbpro=",
    quote: "U2FsdGVkX18edPzQU3mvruUQVS9xPmYGhmWP0PF+QdzFMnHLm+rScfWAE4prk9Zs",
    vgs: "U2FsdGVkX1/RS/8gBZUL3hdr8d6FwwrCZf8hBJjZ9VJivms6g8X3VIjUT4MqQOC+3paLmg0EkYp2ZBgDlrCM/Q==",
    spl: "U2FsdGVkX19AxW/2OfNahzAVf+3Sy9n8FciqGWtvf5cWLW5L9VzJNR+hoKru6uhZ",
    feud: "",
  },
  // Tuesday
  {
    day: "58",
    abilitySounds:
      "U2FsdGVkX1+dbxv7oV/qGRkSh0svd2t/c3ipuefugMm9n8efabbOaL+ubhXwgaMG40DC9q7nM9uYOx5V4A8lsrWQlYKWF0BLPnUGdt7ELp8=",
    abilityText:
      "U2FsdGVkX18yHcuYnKNj7vSznnJ4IzuURTN0Dp7VlVaui1m7CmMS5G6vgy+3Rf88g0wcclj5CkkPEg3w0UBaPjqFd5YYIEB5cPWwqgai4E4=",
    gods: "U2FsdGVkX19z/nXKDAJ+M/y3ACdTjEuJtqmtZHlrFpE=",
    quote: "U2FsdGVkX18YXvuElgrgTN90FIQ4O5RxAr8FE5qw5L9X2ZCZ1w1WzJBarEDTaQyj",
    vgs: "U2FsdGVkX19wF+JktMvBc7LoSy9/D31l6lTjp8gZVLGJ+9ORijChyq94F2FPbPEJWYvhQK15BQ8tCjDLaTs4gg==",
    spl: "U2FsdGVkX1+Njvy++kwPPt8iXfGjl0tVgT+tWJoTbQXMj9wIwlgKw5MI/vktvWr0",
    feud: "",
  },
  // Wednesday
  {
    day: "59",
    abilitySounds:
      "U2FsdGVkX1+UrHfwOEhAgMcLAMn2M5RT/mhfd+kgDUF0SEuVgzPqrV/g6Kp1qpsijRzuahjz9egBCg8lhlFUA0uCP6QcIOb05GtYYu4YW6A=",
    abilityText:
      "U2FsdGVkX196qU8aKFI1lCTBokO3gs+G0KfcK9XuiE4xkcUtQFm7IBzEetIZaZBY8g5tgTPucqzTjhlg4xh/fhyjxoNdhDGPBkK1LGsVDVk=",
    gods: "U2FsdGVkX18KoIlT+SLIWFsJve5GBkyfdJzRKPVLEA4=",
    quote: "U2FsdGVkX18T9nCCbC7BCwsHF2RGooFc3r/GGhPghem0XO9N7qjZZxzwVGQFe7Oy",
    vgs: "U2FsdGVkX19yXZ/ba5ESxSdJH5sJY56DVw7XZFZstIJqbemc+Flc6RewVlgmCaDMj7jGzyK6oGptpG0oyguGsQ==",
    spl: "U2FsdGVkX18iHWfw/KebqRMHjA4RH/WKO2yaN4oRWhVmu6LtlPDigIpWwJn8m5Ug",
    feud: "",
  },
  // Thursday
  {
    day: "60",
    abilitySounds:
      "U2FsdGVkX19a0IMG58QK8nyA53mmhPlVM53SJT1kFC6AmLmizh/KUwHvEx/lIW9ykRL/4bJ/4zz8D6s9GYsWkhGoy9E5iFbmMLASLcXX2BU=",
    abilityText:
      "U2FsdGVkX18kJ6hNCVdPvqv3Qg5GUsaSwSUJxyVWq0KWhH6SkhTH74xvM0t9XAlG7JHDY/KHU7zNW+RN0hl0e3Rgc665jorpGmosqNOUAOg=",
    gods: "U2FsdGVkX18QffMIISYF/S5w6+CwbWVopyrzN8gOeQo=",
    quote: "U2FsdGVkX19vYgyJh0Zauc0teiBArPl3LNRhf0WHwU32heaBKHGHJG0BSlbUtN3F",
    vgs: "U2FsdGVkX19Xv6Z43xhH5/j3CugsbiUzMsAkeZnjAUhFaGfpcS1Jkn95g4zpPHWQwMQEaNvhQVSgT+mcYA9o3g==",
    spl: "U2FsdGVkX1/+KxQCoeTYz/pk8f30bHBZgqri574P3f8E8YCUPHpDpt3rdDpCKpim",
    feud: "",
  },
  // Friday
  {
    day: "61",
    abilitySounds:
      "U2FsdGVkX18Rcx1vnNbUC0kNdVOsOAf9V8pOzrncexO1sSAEwV8diga/AIVrYwpnnOiyuYzPo01RR3vwS5kBVh0L2OoWz14xcMFD5b+pPro=",
    abilityText:
      "U2FsdGVkX1/Md7rKkirx8wYWBa2ChTKyuZZ27xYUeDQMaEOUYPhiYRUXinehx5Qpe5F64jGINA2HaMECzf/bsASg+urMj5Z4QF1OuWyhs/8PX4scfIqCFKMalAk6og27",
    gods: "U2FsdGVkX1+RHAXj/Ggba6r6ujVU04NyyEWVTHJyPIM=",
    quote: "U2FsdGVkX1+S+V/F3J3Je800RwyVzLBDE4u0pU56J1pTYJhA1X5MCnQXZ098bvS1",
    vgs: "U2FsdGVkX19OboUqfnDm/SLZFuwXQgGN+t+4+BtiZmvG1lsaEFDxR9sLDF0hvX6NhZ0TJ6N+2d81zCEtDdbafQ==",
    spl: "U2FsdGVkX19O34ti1ZjcS4Sa5PVnRYJZP4tXnotZTqfFlsxSRuTopbBgzAOSsBbj",
    feud: "",
  },
  // Saturday
  {
    day: "62",
    abilitySounds:
      "U2FsdGVkX1/we0b2Mz2ef/hpao/WK7pORWIM3nsa9EpoUa4OtTVVYTNbhBfHBU4ancurD3pt7Kt9fRl0YjsJ+wjQyZJa2v45Jd749ztTlzE=",
    abilityText:
      "U2FsdGVkX1+vI5GTq6sn56f1ubj3qSLKXBU7KPnvk0Dd5agWrUG/uR/lB6RpKXakhX+SbScg2SMJpuuyiePkNq/HxlaMNSFJ5MifXe/dexQ=",
    gods: "U2FsdGVkX19OryPvZGwrmtK9cSWrh7l4530eW+zG/+c=",
    quote: "U2FsdGVkX1+HjBGVykpH+SELwwoAfjkHwCVpVoloIJXeRLzpzwbbEAu8Q+u98/3x",
    vgs: "U2FsdGVkX1+KjqQq4nWI9ZNDHvfZsvP3/U8Y5hRueB4YhFOuLaXsQ75UG98602ff",
    spl: "U2FsdGVkX18d/JeRhMCDr2MhOf2WbqNRRrYY5ZRuybybEbZXisgSSfEyBBTDbolJ",
    feud: "U2FsdGVkX182Rea5p0TwVg2Vqd6cLuSnxG/EbR92gz8=",
  },
  // Sunday
  {
    day: "63",
    abilitySounds:
      "U2FsdGVkX1/y4Nuq5QMegftf7NnSl1xlFV++NyIRiCEPDQARkRvl1Tcma057HaogBJPq8WFtu0cRsUl5HN1CC7oXhl7KylWqV4jCeB1AXKk=",
    abilityText:
      "U2FsdGVkX19wvcvJqRPTo/gZZ/BTj1ykdvGBiJCzW5YcgQ/xzCuSz9erbYYvlT/LeUgWfE4xeyho8upjY/LIUpPorpx4xqzNqd+HoMd6hkE=",
    gods: "U2FsdGVkX1/s5HZn87fQffSg6u69OI7Cn/IpywxWGFY=",
    quote: "U2FsdGVkX1/QlHjFd/gim64K9HORcyP98DOKOj1sTvNLkbzEr+fqXw6QqqCytdJ4",
    vgs: "U2FsdGVkX1/v1jKCLvwjbaQw8rg+FXVYTeEIEY1a0L6ToUyCynGAB+01kwrrIgAR",
    spl: "U2FsdGVkX18H839IbvQI0Yug4Kkq/CH1dal/9LJFXl7zZjgF6DkeSpX1/v+otCi/",
    feud: "U2FsdGVkX19iwKQDqrXcD5Dge0ys0EhRdVBrVFPaJFk=",
  },

  /***  ****/

  // Monday

  {
    day: "64",
    abilitySounds:
      "U2FsdGVkX1/0rKxtl9RD/OD1jgUeVUy/DSqnN2gN/uhYBGHnUP06GWPV8dsPM2dpnQ/hZAyG2bz6612H4VhqKVm+2RlW1gXB8YeDk4hVDTM=",
    abilityText:
      "U2FsdGVkX1/6tAHfSE3hacnUYQZQ8FfUS3QxPzPjeleGpHl7WrL1apoUS+4oQHesOwqYKnHuOR/VmTF5Fu7rUxRb0cg17pCogNwXxedI6l0=",
    gods: "U2FsdGVkX19/Q/JmInINi03znVuaBR2i/iFTHHAJiUo=",
    quote: "U2FsdGVkX18igSicLB3ZMlp5bA+Vlr5vwiHYLtnb4QWg4UAmpnabeSKG9L3rQ+cy",
    vgs: "U2FsdGVkX19JRz3NV0C/2vDjIMa9DBfsO/qQ1LLhWYKxlI8npDgsF6ZqKOTgP4Kon1+dHI18A161dqjKyQr4BA==",
    spl: "U2FsdGVkX19ekwjL6jV5YqHNeDO195dac0d6sJ9rYE3peclYJXgjsNSnTIWB5GrS",
    feud: "",
  },
  // Tuesday
  {
    day: "65",
    abilitySounds:
      "U2FsdGVkX19uc9JLwAtjTtLKUkQQCAKcxYvh+CpmOTmZhPbqFEB5QgZzQ1WZ4B4S47Dq46Yqgji1cTibEMzAo9Dh7E+Su/vZDdJ9Rb7lqv8=",
    abilityText:
      "U2FsdGVkX1+nsG442wLtmz6Au49YHvTsdvAvjFSofDd1AONzPytHgmHzXQXnvZedC5I3tQHzA1lNiwx6ZLMvS83/fGfYyYtncd65LTHrgE4=",
    gods: "U2FsdGVkX1+xYXPPUZ8M/vJ3exBn4bAjtr6RYrRdJhk=",
    quote:
      "U2FsdGVkX1/xf7gfuBgABaWfMchJga+D5nPFLomVW+5us67AneTPFo1pWcdN6ykgt2qzdCnnzsfi8iANHANVyg==",
    vgs: "U2FsdGVkX1/GTRZy3/+PzocUDfOLGmw8kaYkfARlk7pGSFattxGLAyX9bnM+QGep2E5iqEoV3tfXI4p3Pvd27w==",
    spl: "U2FsdGVkX19Y+aRfh43bZA6J3WU9aZwcG+QDkK4ah9j95U1B7raL2yPeLuBUEsKf",
    feud: "",
  },
  // Wednesday
  {
    day: "66",
    abilitySounds:
      "U2FsdGVkX19dKxKVZLgvPVj7Iz3TjT1UZGMswKEPSN0FFOxAcMpLgGczRimleKQJqc/yYNMH74z8mhgY99kzacgfxgmajwZQv4MS0wlCtAY=",
    abilityText:
      "U2FsdGVkX19sb5REWZ64u0jcBS2Za9u+5GwI8eoOfxm3js39M3CA+tjtlXzHYHw1MF13v5UbeD0uFBaivyNEUrB4hUGs3dg00xXoLwGsQwc=",
    gods: "U2FsdGVkX18PsgRFkWvWkSGEq8pUi5gKIOafjcBkHx8=",
    quote: "U2FsdGVkX19J9Ev/L0O8JsDTKP6+SE4zB/6uPMYDyplLVCOOcbQKn/TlxQyCT3ss",
    vgs: "U2FsdGVkX19WsJpPzTu1ybHAn5h+hKf5D4NASGK88ZZbatQ4xUwswRpGX8xQ6H1/dlN06jX6wtFKesEHBq5u2w==",
    spl: "U2FsdGVkX1+2ybDQ1FdiKmkooB2kTb4aeZf832d0ThFZU3q1Z2blQJscwkmzYSrV",
    feud: "",
  },
  // Thursday
  {
    day: "67",
    abilitySounds:
      "U2FsdGVkX19aDjZrrL0Zf9xuk1CRDszfJqzEc4t3qZah5NMDuGXOPL1TgQLbb9O1AVN8twg1BUX87YiqATpdLw8aNCiBi6YvbkwOnfq6pDA=",
    abilityText:
      "U2FsdGVkX1+Cp+6py6k48O4c2UtYCKTS84W1lyUFmL9fH6bqW5HD1QiMiLTKBD4+52F3NPIwnqBY/eLS6VhfarYSAh+iyJU7cH6XdMFTObQ=",
    gods: "U2FsdGVkX19dasCabKawcbUtiYcTAi3rq2fB622/SF0=",
    quote: "U2FsdGVkX195uCj8bDMBE+TBjFr5sKxepxpxEb6Igwy9Vbg2RdOPMJrzXvqpD0xg",
    vgs: "U2FsdGVkX1+FjDjJYUyFW1IeiHYvIhQKQXOs2mjiTdq7AsHQpvLdhSv10w9KE7+phTTFo7Mce50jOg7mPJSSLg==",
    spl: "U2FsdGVkX1+2zJQoAvUsojEoHBr5Iv4xurGxX4X358uO5p0YwqSJ5NL82U/fJS6Z",
    feud: "",
  },
  // Friday
  {
    day: "68",
    abilitySounds:
      "U2FsdGVkX19f9Io5ehsleLv634EQsBXxY8mDPLdjLeGWpZj5fWyDnniPPmkQTvj5r5fihUZ2pec165RY2uwKtaluuGBcQeyJkdS97MygMcc=",
    abilityText:
      "U2FsdGVkX197960zpLohZFF1Hc+x6ajatic0jJ0Pwjh2yb5GQioOzvuJ7ftvjn+vKX4AY2N/DZzelKGqh+m2tBq9yXjELl1RqeL6xJQZShI=",
    gods: "U2FsdGVkX18ugp+o9ROpYXUvTAW+ngF68UXOfyMiW5Y=",
    quote: "U2FsdGVkX18SulKn0BcjrywQtgQgw8vH7mjnLRu93s1A0BZqPexxoV49pb3K1ssw",
    vgs: "U2FsdGVkX18VnuzW0AyHifZloX2jbhwJX8GWcXzIQUk11TD4pOmSZ2c6QRpAlyEAANGuFlQ7Nfu37P9qnsp8Lg==",
    spl: "U2FsdGVkX1/vdQLPe53SdKLfJ+byJyeC27h9UO1NP04=",
    feud: "",
  },
  // Saturday
  {
    day: "69",
    abilitySounds:
      "U2FsdGVkX18tczvzTdy0E1cIYNDVyyscC4cKZESskxYutKtKgg6tnt01/zKduqmx+5veFXvc39DP+/H9uOtl7sDfXkFsnbEr+Wb0V9JjGlI=",
    abilityText:
      "U2FsdGVkX197kIV5E7ZNK5tWP3B8p29V+mAmaODrUiYCtolo3qRJrcYAzsrusj4zGLgClsx1+EAsriSrgHCPoGs9piDK+8qEdA0luhdMNf4=",
    gods: "U2FsdGVkX18ylGqPk+yhctkCkyPm9594vIOjJILQ8Vk=",
    quote: "U2FsdGVkX18sfijgwgTUoxGAe3k8ll1P6j9TCd9D+Yv5jlZOhyA7/t8kD8t+1/S+",
    vgs: "U2FsdGVkX1+UwXgPPKIsQqU7B1eXmvJRHpzLwBZM3WBKrgLMIw1cuKR5nAJDgGK2/MvUgQT5Ks9jB4kzvio96g==",
    spl: "U2FsdGVkX19SygHLMkw6rT1DOc1izsJgzFr03aPqqDc=",
    feud: "U2FsdGVkX18liJNQ4SzUxQJNGwAtKAXIN72SyKIdcU4=",
  },
  // Sunday
  {
    day: "70",
    abilitySounds:
      "U2FsdGVkX1/tc0DrBF5rbJe+nHPG3ejxXalr/FRZHPXMpQvHQXQgrvPwPdMu33EwWq7rHMfeAWWuDNZ0EFJRlCbGpsxciGAAaWFMOYI4XEc=",
    abilityText:
      "U2FsdGVkX18kGl+Sy3uiUW1d1CJ4nedH3ncgKTG4qRgplxc8mh/VeJsdMn1JLyD/bzCyn9ejVOwoRWrsLpiABwppEA0dw51ee0r7W0yhZw8=",
    gods: "U2FsdGVkX19WFarbWekGIKtiYP5rwYjVJ2cicnkbois=",
    quote: "U2FsdGVkX1/q8lqV66mbsHvS4lQDG/uXgdj9VcZ9mnbytmTr8e4s3udHIC68R3EQ",
    vgs: "U2FsdGVkX195bKkFEZXFJVTrrLjpc66R/Nc6vkByYGIcA0TCto22silhAoAC7qxk",
    spl: "U2FsdGVkX1/GOuvMQPqeIqF4YVibktjHNpxqTocaJsz31zjj3XYB9ZyQeC4fHoAM",
    feud: "U2FsdGVkX18biUExY4l3kwUsdaGPjSnXt/njbT6FNsc=",
  },

  /***  ****/

  // Monday

  {
    day: "71",
    abilitySounds:
      "U2FsdGVkX1+LIdTgie47UC0btqhspOmzEYTd/u6Lrcy+5exVdZEq0t66mbJoX558P8fqmdoHQVobFeFKKH28PbtCwejr1s0kUSN6NbHgkGQ=",
    abilityText:
      "U2FsdGVkX1+bUGU/XWXr2OlX8gUwb1FkW7S+p4dPuyjxbxcRFohjfPn7GxMuQvkGwXhEZ2XBUuFaXFPtLSPyMA==",
    gods: "U2FsdGVkX18PY7aKWZz6omAbQCtqlwtIbfSqVvYLRYc=",
    quote: "U2FsdGVkX1/S5iWAHv3O1vESIAv32/2dLP/5whodpc297uiEbkFaXio1Q+Qjav1+",
    vgs: "U2FsdGVkX1/qBIW3oTMcVVJOkQP29xAF2dQIqRGrgYIXLO/dKynf5SzxXo9/UCOp1qnqVxCUTYd2DIMxzotE3w==",
    spl: "U2FsdGVkX18RzHxuHvf+Tp8bzwvCJJbsCyfD4IflpyFuon/C8NkcYJ1NrjvxeGM4",
    feud: "",
  },
  // Tuesday
  {
    day: "72",
    abilitySounds:
      "U2FsdGVkX1+bxjz8u1BinkBdDFOXqCxISAV15dXk5P+GZOkwjXHSR63ql/aL1N1FZ5OBBFPrW5nIidh0euYgepRvIMhv0Oo4Por4GzDLVtT+T3ARor5/R87IPNZOp27q",
    abilityText:
      "U2FsdGVkX19VXsG+21rQx2z4Q8ZG9jkgofEnbId4SU44qbyCcJvVxhGWxdEBGXroPtwSuERJZhY0pPe+erSMEycQTipUhoXeJohXJJbOcXA=",
    gods: "U2FsdGVkX1+cpf5eOl7RIlc/qp/nex/uNR0dB1LGwk8=",
    quote: "U2FsdGVkX1/cG8I6GGSOuUCfor7P4ztR1idvob94dcdL2G0aro2b2JLRIvC1EJLa",
    vgs: "U2FsdGVkX19wtUIrTjSNgIIkGMxN9Qf0jOP4zQ1bj3+tCAd3UuUti58keB1b1jUgYrV1sW88cA/anQgHnkxRsg==",
    spl: "U2FsdGVkX1+5RQivipgab/74e6Xbvp/aVBRv5rpshPZI/C+J6Zhbj0F7b3Q9jsN1",
    feud: "",
  },
  // Wednesday
  {
    day: "73",
    abilitySounds:
      "U2FsdGVkX18ybZ9lCW9UdidERYWVC71EnWn9YaS+OcD0qKzP7+AZy48Kj2g3jwkewnQkU09IKzkfJgtnSmSBiqLQroKa2vCY6+A3VEBec/4=",
    abilityText:
      "U2FsdGVkX1+IDYqWe+sH8TCSGVqnYqp5rDaTm/L3NO5CTNabJcuQYE0foTApO1Zi8dS1iTbEuLBXFS0MiEeokVYllDEPwNObcv1cco6KQG2nsYKxpI3KmkELmp8UqtBa",
    gods: "U2FsdGVkX1+Cm9afLMw3H45yDeaIdXBHhVCS5R2JVpw=",
    quote: "U2FsdGVkX19FGAoTbYdvDbtAk3+IdGks5/+NAcJJSvQjwLwbnYrm72aBNrLkzCC7",
    vgs: "U2FsdGVkX18tut2hJLPt+iXTlRNV192xrFuo/Nfi13nDP7OPdNvGA9IeiiilRoxF2F6DyInRxfVRbGaY2QPIcQ==",
    spl: "U2FsdGVkX18YmcXKZWGh7y38Kwq96rfsrE2jSBe2/Qq4i43VMSFGQR7w0k1QzBrw",
    feud: "",
  },
  // Thursday
  {
    day: "74",
    abilitySounds:
      "U2FsdGVkX1+fdtBv5DW6UHunWBcwDJmyEiiwR/SqB+Umdy/l4ewugO/op8s3WujxorsgBvEmqVZKLmd6Y+8UO/kZ89dhoZwH7V4h2Ec9WIY=",
    abilityText:
      "U2FsdGVkX18Gs9kFWYNYGIy6ZG9vqV20sWHMh5hewpxkO6c7lDOSlGEXl9SodZf5Ot8HCRJSVKEol4vFMJ+LyzGaiwdxkBRJ3gXHirmmz0c=",
    gods: "U2FsdGVkX1+HOzFx2JSdlrNexPWWJSC0BRisBZRxb4I=",
    quote: "U2FsdGVkX18VtrEeV6fRDU/jxkhrrRFgynLlKyNpvg1NOAUtT5g077bBug1LiVMn",
    vgs: "U2FsdGVkX19LXByldarAR3id7/dhEH8m6oh6q/pZTqlF9dcw7iPrskkzpSoeCMB/Ni6xRYtlkujFQJDhDuHojw==",
    spl: "U2FsdGVkX19WZMowsXlPX4Tq3onaENs37bnGTghPwsATlPlaLN5wU04y3n2aBpri",
    feud: "",
  },
  // Friday
  {
    day: "75",
    abilitySounds:
      "U2FsdGVkX1/VBywYOBAp9GZaDR6bynfEzOUa52/o8c/w6+85MTykt30UgGb61DBRD3LDRqMzhkFEUPqhVPGoe+H0HA3XdbLSYzBCWriewWY=",
    abilityText:
      "U2FsdGVkX196obSeRzc7DC/QEm862Lox53gSlcacAjNU0BMT3C7UV/HzSbvaadxUADvadL25lMpqCoYzz9aBcQBSOR3lKLboSOBtmmra9Ew=",
    gods: "U2FsdGVkX1/HP2zqyc7vs7P+h0WopwZ9r5yE/WjUxWE=",
    quote: "U2FsdGVkX19MTyVHCmoPMQq+jypWlegzjAIZS2Yf5OFZ1PJ7SCxLrI9eX5/cOSi8",
    vgs: "U2FsdGVkX18UV1OuYM0BSxbSTqEjGYLRAhMXXyCqebHW0+QirPrlYrp+0NnDGREl6+jkI1MArZDwx3opyLcnmA==",
    spl: "U2FsdGVkX1/feqF/88SCF4vVueYovyYGL0JEEbp/IalGRnuSIOL3fguqGbWdczFP",
    feud: "",
  },
  // Saturday
  {
    day: "76",
    abilitySounds:
      "U2FsdGVkX183Q60X5sBnBl2fD0zNXCKuK6uZ6rEAKE3AI3T0rT2X28I1GHMzpJsuRW+XVhdhw1Ouy+xmi473kxJ6wSgFbN62EVHUqGBgwzQ=",
    abilityText:
      "U2FsdGVkX1+2/OifXobixxup54g364uvyQdIVV1XhUTzPmzeuOA6RNGMwPUjcmyZt6QeBJ2I9nKmCn77r+TTmlQkiaVNmtnEkLmLiufZmnQ=",
    gods: "U2FsdGVkX19F9wPAvOPtkWI4is9ueIBHsSUOmuPRKag=",
    quote: "U2FsdGVkX1/AMV3ulc7jlqeFPHh+rVsxj4AdZMuTp8cwc50lp44vUQ51OXGSwutK",
    vgs: "U2FsdGVkX18PdrsnAF6kxT/sz/T0/f2iBhP/pRMw04ixl5+Fks5iajFFH/uu5EDD6RWTDhpe/EnI/BvXfXAM0Q==",
    spl: "U2FsdGVkX19C1j7rXhP7dnutU0pn9Zh17GZWrBNn8tiG54zVgClG6MB9+Nu2SUfY",
    feud: "U2FsdGVkX1/E8lCV1ZfPm8EBDgFG+5/utKTgCJ+9OD0=",
  },
  // Sunday
  {
    day: "77",
    abilitySounds:
      "U2FsdGVkX19U8vdMXST8Gkx3AKGIx8ocJHxuwuBxBpAYYppGRlE0AAw4AtTVoc7hyrZvGguaEHHADeLMloPCkl5JwwHKLwMa2Fjcvh5LvJY=",
    abilityText:
      "U2FsdGVkX1+Kp1WVGzB+LSnxxwHayEoB8M3J7IIdG2mL7LwRaglaIsdZaOiOJMuAGTw7C3pMMXLNfcJzTT9cR4NKjBvnW4OThxGkPAQhSXE=",
    gods: "U2FsdGVkX19JGOvDLbZECjrAShqiVhZLLaqebxzKQP0=",
    quote: "U2FsdGVkX18EgrZPeJlnLlq/ws/tA3cEGl+29cBsgrw1enmusbpwcfopuccwVvpc",
    vgs: "U2FsdGVkX1/D76F4lR73QoqnkVHzEryNNuNWtmHB7wtmzbHU/94Df3I9God15oLleg6TZ7qk1743MK0ToRDREw==",
    spl: "U2FsdGVkX18YOuDYJS499l6jNbVLyO4i/3FYUjeXZVQdP4BanU9oZUA4pC5fDlIh",
    feud: "U2FsdGVkX19dJR67DcHliNvM7c3Qsi6jWeEVTw5cwLk=",
  },

  /***  ****/

  // Monday

  {
    day: "78",
    abilitySounds:
      "U2FsdGVkX18rZnjwyo1jxWg9zQMbTCIZKp2rp3yaZTQiB0On7mFMo9am/m3iyUg+FGwWsb/RNktKJWr2VTeLHdFojYkKuYdmpOvBHo+chio=",
    abilityText:
      "U2FsdGVkX18HhlSQulvaDSbWV5clu0XAw4FQ//eYQATSAFagQVokFr+xGcVG0x0IF6dO/NCy7uoFcco30yTIJls4R7q595Xk8ejR3S7XsBA=",
    gods: "U2FsdGVkX1/D6Xh/AtEYVYh3GsTs0iX9+zIk9WadTEQ=",
    quote: "U2FsdGVkX1+VuqaWteIeB6LuCrNXN4YG25BltUAumZHtcywVQojqG6LHmK5ijbZW",
    vgs: "U2FsdGVkX1+wHzAM8tzbbCAwapj7mL5prxpuSm3pmv87q/0o555r+JPonzNn3PaaLITSiiXP235gIe+yjMG/MQ==",
    spl: "U2FsdGVkX1+jRHBnyPJymcPf5Aoj7Tp6SKAfrhbkCNkwZ/Enm5zv1ke2qfC6DMhZ",
    feud: "",
  },
  // Tuesday
  {
    day: "79",
    abilitySounds:
      "U2FsdGVkX18YH8VX6AufJi4J4vNwcHHhSMhbRyf/oTmV0HmTC236K8YCm9JdHy/EMIpRl1vwFYDVwEw0pCQmRvEDq+5/dl07003LQl7LjH4=",
    abilityText:
      "U2FsdGVkX1/VUAD292fb4ufFNxlzmVBxfFbIz6Bn5Hx3Y8dpfZ6KRsviH11ZIHnYBAass3he/r/VFjI9jaJ0GyLwEPywLiTIzBjX184wROE=",
    gods: "U2FsdGVkX18WuZm9+Nctm6/ko5HbUiRmOiOL3lIgGuo=",
    quote: "U2FsdGVkX198/MeCFmCLyJLm6ch5Z2p7DSpOLgR6sS3N0zESeqwO6TK7y22jPVEu",
    vgs: "U2FsdGVkX19Z2T6+wt14z8XNiWkOyRv1hxgmR90gIYAmxDQH5cqahDxEYX7VhlWxEqhvYhy1hobLOg3dx85XpA==",
    spl: "U2FsdGVkX19z7sccX5szfbVQnq2deyl4c4CcuWHXMAThzJ+g1MJkBfVBH1R7JXLN",
    feud: "",
  },
  // Wednesday
  {
    day: "80",
    abilitySounds:
      "U2FsdGVkX1/5uAEetdmrz0h4EqR5ZaYnXd0mVbXuam7xcPI7+i+M3mCpJeODnWqUDHvnov1nGOZA6fM8PJ0TqZqPxIpskUpEodmDNW0drsBeA7WhQar2WvTiBU2mDfQQ",
    abilityText:
      "U2FsdGVkX1/Nv8a1kRdg4ie3gjHMaaH5tr2GFej7KVquOF91oyyjIjECJlCv9SOc/I9KjPmW6+w/wBJDIpHC3H35pRFOBcf+MnGBDNuMXIY=",
    gods: "U2FsdGVkX1+afFTCAFdD43+9IDj86ICCB/jX92nKz5s=",
    quote: "U2FsdGVkX1//gW6zGE4G1NAvnES0+2KPCIaCzivg7FCR4g4NIdtEK63Df5AIPp8g",
    vgs: "U2FsdGVkX196k7ZOeeaDrHswzsMJBYvNeNHD0QfPEEWHq7dehmE5YbKr+wb/OWNRZ+7/57OwZRRs+/9/QjZbng==",
    spl: "U2FsdGVkX19CqFnVjtjfT2RWJMQm/scJ7xYl41IxloI37MRHxOMWn2limbNGuCYK",
    feud: "",
  },
  // Thursday
  {
    day: "81",
    abilitySounds:
      "U2FsdGVkX19HSfjgbWx0zVsr1hIL6cKf31vDDFh4NDA6a0h/f4z+rCLRffWfCiDhx1uuTUnJ5cTvT2PLNIXc7QpTJ2QDAuNV8FdtqIVPQomGoylCIUq6nkjjpMV2YHXE",
    abilityText:
      "U2FsdGVkX19tOfja8nL3dHaMNcvpwL+i/LeiLVxOehnFWrHPbSs9trKkMT1LRQs0D514rod8QxLdswYIhNvmzeRkB8/YuLbfI5t8RBOhqaQ=",
    gods: "U2FsdGVkX19m6tQIIOWObMQOChNHDXTIqVQKZxSTRlA=",
    quote:
      "U2FsdGVkX1/XZ2kPhQR30UszVTN9M8HRmtJbYa0cM/5zRXwr9q8+9m9jCIYa5wShLJP4fuLFYOgY4Ww2wXiCJg==",
    vgs: "U2FsdGVkX18NgRCaQW+wsahhSw1cgNah5f1HG4asI7JIkUN/oi0FVXt3fcvZAqu3p0CBxUcUm1gK66O/0kEvLw==",
    spl: "U2FsdGVkX1+VGhzmXGh2o34ZsJ89PVIwpWY4ICKHb8KQBpEbp5+jmMn7Q1MSeO/x",
    feud: "",
  },
  // Friday
  {
    day: "82",
    abilitySounds:
      "U2FsdGVkX1+9l4drmCtsOBsJuNZDe+6MSLlPSUshKbJgvUW33/iNlMBV+YDyyMdqT+EaQVAmR1neSJzBUZdUwJ0WQdiNU6RLbUyIjvYhWpw=",
    abilityText:
      "U2FsdGVkX1/LZSSh6bdAs9Wz0GAzzrZghEWyQ1TV/WvVh0T08rXlsBJw3cSSxI6FK28Ii/b1KmPlYyoV6xVOW/gR1nrOUM13q6oZIg2zgXM=",
    gods: "U2FsdGVkX1+5OOCRbdPPdHmm8juPiOaoWciwL+0IAlc=",
    quote: "U2FsdGVkX19j6TUdo9dHxhZMrix6aUC/1HfWioaH3RIe1+vIVRTBOX2EEpkmKvxM",
    vgs: "U2FsdGVkX1/4QSKehSZHjWPtpBCQwyDcmgkEvw8HOzarTZoUa6Ic4nHCr00D9/JjWdHgbkMV740UcziB959/og==",
    spl: "U2FsdGVkX1/IFBOT4pNoHwDeuYtTfYa1Hs1EJuycZWCIEULnEgA2FIlmP6Ib9p+9",
    feud: "",
  },
  // Saturday
  {
    day: "83",
    abilitySounds:
      "U2FsdGVkX1+05fRKq3FPiIQTe6a9ybIOm2ms3yFROSgPxgizVuWviEHz4h2I9zfS3lCDfYuv0m+RdN4LSpOkWMqd6JBt8ugmA66Fy97Pm0t+ZTNWeLoYFBrvahLM1+Xi",
    abilityText:
      "U2FsdGVkX1/WEEPhXPFU2lb21Gwl6h9zcjbdMygacqvDSsNnmmOR2yZ16uW3JFz2TS1+x+h/BH1uBprISj2KABMdCyOApLTi5NJD7OAjYpo=",
    gods: "U2FsdGVkX18bNeb+5DiO+LZAdYYZ/jz7I6RsXYlLh1Y=",
    quote: "U2FsdGVkX18XVp9ODU1ejsy3KKMqRm9e5PVjyq7jJ/Xd+fFRQTPlnV7ZmOAapCKP",
    vgs: "U2FsdGVkX19e6v4TeawN1b2mM2/7pwhsd8UhGULSUp7udH9JFRPiQV9VmDa/EAnIwvbrqGJURYtAO4H1NpHXWQ==",
    spl: "U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L",
    feud: "U2FsdGVkX18qoCOm23/DXOHIQ8+Y7H4Pn7G7yohtW5w=",
  },
  // Sunday
  {
    day: "84",
    abilitySounds:
      "U2FsdGVkX1++ESEwB6jr/tB18xeAR76NleF47SZ8T/2jL6zbwqnYlgdI2DCMPjEjyCsNTcSheMqTf4S9RSI31E3+Q86kW0XH3rCX2yuV2hA=",
    abilityText:
      "U2FsdGVkX18saVKdkVh6UpUpdPhqugp+2M7Q1dVCx6HyawHr3OdlJIi7Klw2+MD7LggtK0pvf1cTurZjxL+mp39DGXglXUMwDSx/P0HhWM8=",
    gods: "U2FsdGVkX1834MXJWfoFpfktmYONkHHGVKS4+7ldbgI=",
    quote: "U2FsdGVkX1/H3ZdzYKYlm8w+6ikGE/xhdmMN3YlhaENnPRjyssNSQfL/+U+2o0bc",
    vgs: "U2FsdGVkX18mh8jzwWHNL0P/mc/G+pkzmEsH87wcu6fUw8DooSas0RvKbpKMO1a97G3b+IIWoASMTs4mLpEUmQ==",
    spl: "U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG",
    feud: "U2FsdGVkX187EhhOnKp5WKqxUd8DYb7KQA+CxrooysU=",
  },

  /***  ****/

  // Monday

  {
    day: "85",
    abilitySounds:
      "U2FsdGVkX1/5Ie9L+1LoeiY059TyWomzL/AawvFMrFafhb6dWI+k/EeUNEXHBmfpljnXQXhuW13iltuOU8bB1h3uACDLDhG/YAv2HqC84XM=",
    abilityText:
      "U2FsdGVkX18TVsEa9rmkgAN4uM2DqzO0/KIUmNhO47Lk3jz+GYVr5ssswaLogrGLXDD0Dplo3eTp64ZFGcsy6GDFa1JvS0fgQjAJv2QW8vY=",
    gods: "U2FsdGVkX197ZYyYoCU7Hv6lyH/Ac63LUQOCN3j6Gt4=",
    quote: "U2FsdGVkX1+g5NWR954mDLtnovwUqMYsHbnbFBN3DLvfqiPkQVytjg7QKW4tWOLa",
    vgs: "U2FsdGVkX185/3yTcW9j8fHWwH2adycHv66ZDhSKp2thy6fVjE/7ivXn/rCqhIiG",
    spl: "U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce",
    feud: "",
  },
  // Tuesday
  {
    day: "86",
    abilitySounds:
      "U2FsdGVkX194ljEKjGW5alG/ItV/NViVCyqJ7xc639tgf+C04WoOrVlSLax5As8zQlkT+7vQuQO6nT7/tx4bToJ56kDhwfWBym/LGMllKYE=",
    abilityText:
      "U2FsdGVkX19fzZv6HbdaxAapvSTLWkFPlArNc1h8SuvhrPdBkkTC2b9egQnoMPWCUeN3kVHPG/Mt4PfHZV9pIUbH8RuQdqNqx0hOKSAKumU=",
    gods: "U2FsdGVkX1+Ou9D6lWPV11lvE1o87ixe4SBQUo3ysHo=",
    quote: "U2FsdGVkX1+KUUMYOgj2sHDb4jGKsgItLOjL3ldOVwOUM3qgoz6bTbqzADZkgxJe",
    vgs: "U2FsdGVkX1/7ugoo2llgHAAV6AHcX1NMYGLbHhXPtGnySkCOBJNEJtN+CKVtRLGDmSVnGdie9LoV/ZfasEujWQ==",
    spl: "U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx",
    feud: "",
  },
  // Wednesday
  {
    day: "87",
    abilitySounds:
      "U2FsdGVkX19PgnsfUtyMd3M7bp7egK9cvPUJmNIpUe9PAAk+wQ9Hn6N97i9zaMtREeAHDSAfcRy45QZiu5W0XMsQ4XtgsFuNazl306+Pscs=",
    abilityText:
      "U2FsdGVkX1+AXkzKVyYWO0DGSHTuPyvWu3MJ9PEYmURvCao9TfW+8EzFr1kV+bX/fcyDacoNdWUifrYcGPxdKt7aeoiqksA+uJaFzekiT6s=",
    gods: "U2FsdGVkX1/R+66JfVH1DHexGb74VDwgSIgtJZZUPG8=",
    quote: "U2FsdGVkX19uq6Us9h26PtakTWLPFiTiMgFUCIN/2SKACl1+LDImZt9bU5cgmjE8",
    vgs: "U2FsdGVkX1/DOMVuDeo7rtrZBwsST1K95lHYWlKleeR2vlKwrkQ5Teku0oisxjBiFlv/cosi31cb3+/tLa2TjQ==",
    spl: "U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh",
    feud: "",
  },
  // Thursday
  {
    day: "88",
    abilitySounds:
      "U2FsdGVkX19rqUqONEw31utT7D5XdUjXHc2rwe8XuI+6Lz/Q23+rtZnLohY5OBlA18HnOVsh9zOO/JVoCXR1EA5KkPo9o1187mhuXHL/gPM=",
    abilityText:
      "U2FsdGVkX187M3GE22tSkyFnlbWPcEgj9mWpKTArLfwCRR4INpPIONqUGlgdrFuz12YYy+tXcCFXwDgLSy+F5oGy0NylwibStZTJ820wBM4=",
    gods: "U2FsdGVkX1+El1Fr7AZGypJ/lgCY2gR6HPtnysIGjFE=",
    quote: "U2FsdGVkX18Ym0laj30+g8fY90B1PElvvEaLo51poWWZUZQe5K+/4T/GO3EtHYNV",
    vgs: "U2FsdGVkX18/uYecCsF7EoIbK9knmGFjK4kh8ZMdzteAJIYudFGx9Volwkz2c9vOHq3Fjnpq1v/3yCQ4IOnlJw==",
    spl: "U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW",
    feud: "",
  },
  // Friday
  {
    day: "89",
    abilitySounds:
      "U2FsdGVkX18ZWpj5vRce8YCGSOiGyIZSZRoGIXWaAF4V9Sy/Khth2Z3pwkF1h8x6DHsUnRz2CmJLlcT0EON/TfjnMItQKCh8QO9pe0pIYIE=",
    abilityText:
      "U2FsdGVkX1/sgTloXmCV8eoA0GlLcNV28L7McbrSvjhRX5EwJPLvn+cmXsURTlRXe9G+8v0i8c9gX+oyu/jJKgCGpojJV/yh1a0vhKJ1lIE=",
    gods: "U2FsdGVkX19eb3FhtsBnpHpwS0vTAZu+gZ4mH1+Agog=",
    quote: "U2FsdGVkX1/wiwfDS/nPJvu2ObeQKsZhNVj8NqeDFsq6AgwAO9Nov7U5AK3FItrg",
    vgs: "U2FsdGVkX1/VFCk168p7Fj5v0w2HHiBmvYMfOKKwI6tPsy7sG5Q6wvi0rfKf6nGFudYU7E4N5CZm1KuAaZrUXw==",
    spl: "U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND",
    feud: "",
  },
  // Saturday
  {
    day: "90",
    abilitySounds:
      "U2FsdGVkX1+g9Q19YwdoELsh7a0KytxLtMj+bYW3AZESYRo+F8EMN3V54GN2GVrxiaVds2cpsWmSvqrLnuaGRfrqPbIUf3pu0zmRxLijNbw=",
    abilityText:
      "U2FsdGVkX1/q+kNChXi2xZ23Ae4hfI2Vj9TaKR1Dop/Bkwx5J9/bth96+U+t6bo1bqYYxN9BoJqwjlrX6SZmlS1Q7Ym2bhgNylh1BSIpP3JyY2r5neikF/+zaxzcas0M",
    gods: "U2FsdGVkX1+h1TCthxcpoQ2WynDthpVc50S735ZliXc=",
    quote: "U2FsdGVkX19zBLOJmzrXyMRHk2F+MlZFs6mqQWzIpOcPXzh8Pu/vSIsLP65ExTXN",
    vgs: "U2FsdGVkX1+Rzk3heBicGfKioP3MBP5VFi9dNrS6rWoYmn0UXc3m0TRhmJ7mmDlf",
    spl: "U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU",
    feud: "U2FsdGVkX1+wh63ecGzCYwVa6EqNwnzozia+lqfZpSI=",
  },
  // Sunday
  {
    day: "91",
    abilitySounds:
      "U2FsdGVkX19u3XX8rm+KOcGAZ4b1af7uH1RiUDeDlOeibkp5ejkrXLMD3IUwy95Q5Thv17SV9XLaZ/FNyUSwHrysF6qglkTGSlm8CpBpmp0=",
    abilityText:
      "U2FsdGVkX1/j3qTJL0dFOmnHWRfnmVexh9MYXGWgsKkenNM03C3YA1DTUfLNvV3IEQgaoXFHTWbGqYh5pAIml/eK/v5q7Yk4hxVaog5e49Y=",
    gods: "U2FsdGVkX19aQyLU49r9P8//BTxdvKUrAadaj42F/P0=",
    quote: "U2FsdGVkX1/bnZUbTxl5mqt5TF9EhQeELoQEUPa16gCpU3/ZiXMDQuYzlX3jj2NS",
    vgs: "U2FsdGVkX1/lPtAQRIqWVZxdbvvyKD8Q23VlZrwARpnehJJ3nJh2BGB23lguMgBc2YfYBpdbLMFNo55oDM4RyQ==",
    spl: "U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC",
    feud: "U2FsdGVkX19MjOykehtsUfGwjZlIENoiUIiVyuTWnAI=",
  },

  /***  ****/

  // Monday

  {
    day: "92",
    abilitySounds:
      "U2FsdGVkX1+kJNThcjmvUaLqViyGmvtjxawwg8CQxT3e7JkI7rzzKyNQmz96MB7fN3BbCNOqR3OLJEjPOBZGEYcaOVd7FDqeScB2KXukW6k=",
    abilityText:
      "U2FsdGVkX19ERa8nyJpJvCU9uGHpa0pxpjisUKNsNZTYflS9b7Map/lq6lpmBeqygQIbM2FMC53DB3ZEVp8YBuG+2QbMxKiaY8fTSyzCPzE=",
    gods: "U2FsdGVkX18xrklEjG7FlnoG7UvZgBZL8W5Z88tEL8Q=",
    quote: "U2FsdGVkX19qlhFnZfVp8ftjdESK7yiiXCN6IFvPeLjE0z2ZaaGJ6XMuCw0oQk1V",
    vgs: "U2FsdGVkX18sdbnDW/jTUnqloDpyoJe+UUMlICkb1KcYVxPhYmxLe9KqXjFyKzScvxcBPswuOebkuvJ3XJeGMw==",
    spl: "U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7",
    feud: "",
  },
  // Tuesday
  {
    day: "93",
    abilitySounds:
      "U2FsdGVkX19ZwM1gtputufuo0GEPH3BVxs4/4rAKwNqKULJtLjnJeVHy6d+3NFUyDL3QubzdTlEYgqqSH0gTpEaScLgDuhWk5RkWQ9Yfc3w=",
    abilityText:
      "U2FsdGVkX1/ZBmPXaLcYFmazigc33K28rlcDVAL92quWS0qT5VFdKoBA1kVf8QKxRLXNDyLsUeq+VF8yBr7LldU2euH/DsKnD7CONrCoaAU=",
    gods: "U2FsdGVkX1+EBnwzu1mIY5CAG4BschYZA+gESM7zb8w=",
    quote: "U2FsdGVkX19jQN2b9XP53+UC9uc0rXciFeFiAJYnOemigPogZCx2299brwf2+P2c",
    vgs: "U2FsdGVkX18usbZBr00wn3VgXgViWPFQcJdCrY6l9VskEDvi0cKYV7O97OtbUI+fEPo+N8cQ03ym7vEOthr0jg==",
    spl: "U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs",
    feud: "",
  },
  // Wednesday
  {
    day: "94",
    abilitySounds:
      "U2FsdGVkX1/+TrSSUuQllzUk+7cHJFcJYBE+5f0QnFcMzDOadyzWCAYOLkvz0wGicxneXKd/yNvhxKZGWuyw42KvZlFNL1P6s8kEKkjGGPw=",
    abilityText:
      "U2FsdGVkX1+eBMxF0MXv6IZvgGiN2N05TuEcPtzab9AYbCpR9sMrq1mTIO90++WtLM8OskHeQneb+4U+dR4t1LfWbTpPR0VXcMS46C/sWok=",
    gods: "U2FsdGVkX1+6s0X2MvdKe3foP9jhjCMUZiJSJYfQbzo=",
    quote: "U2FsdGVkX1+oxgg97vVJr0dOEN5IZN0OuO9v3MK32eFm8yQscRBUzNhSoo7jdYgi",
    vgs: "U2FsdGVkX1/ZSaGPmK65q6ZGJmsNI0vlaO8xFzFTGvurqLeENjz6SUZAIpMC9TuSrG6P8D+4HSHqAYbl9cE7Xw==",
    spl: "U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR",
    feud: "",
  },
  // Thursday
  {
    day: "95",
    abilitySounds:
      "U2FsdGVkX18fNWj3/RO6pkwsze3WvOv9iIRmyknbdoCFlYBknQjgKJ4zFMyqQQOKXVhxQcrgk+gSE5Duo24d2ac++Q8HGQCuioo19x5GMNg=",
    abilityText:
      "U2FsdGVkX1+54/vnHccScIbgCZNBpy286Vlz7beACL4Y0klQV+AuSavXE+AXsLLtUgnZG04OFuTwZmNmFx7S55JZOwnZ3to+XMeO2ByQ63s=",
    gods: "U2FsdGVkX1+CaZuv8OnvhMOSmfM4YCplA6aJg1wepqM=",
    quote: "U2FsdGVkX1881SIet8qjdlXiT5IaammSXStSKaEqM3qCyiQbqLn91NfZmzwCQ6l5",
    vgs: "U2FsdGVkX19TvO8XYR1BuQkBdNjfG2uSI5wSTGvlc9b7B5G+NaI9SOZixCL/EzMU8cDe5ykzEBFdb/Ddm5JWYw==",
    spl: "U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb",
    feud: "",
  },
  // Friday
  {
    day: "96",
    abilitySounds:
      "U2FsdGVkX1/RiJ3pV6vXh2PeDkCX0+AJq619cIZtjQMaDMyyXbWYte4wXcSWRbsi7i+75xqve35JQG4SxUfCaVhI3Q2gv5fCLObvTdVOhbY=",
    abilityText:
      "U2FsdGVkX1/u+mQ6hncj9i9ctq/1/SYS0hnAWYA42kViChIYrcpqG8a9Xqtj6UgvnuIMcA+obzFwUUWeJIt7EA==",
    gods: "U2FsdGVkX19zABLFDDhQnMJY35un7ECBdt3Vlda6Ml8=",
    quote: "U2FsdGVkX182hGayTZ2V0crvpv+2icWk6K7WWavakZEiyz9DenOOIysrzxpXAxPr",
    vgs: "U2FsdGVkX19BTSVE1NQQo8KZ17aBqNpADjty1QseEj6j0Lsw+AeEYWY65kyc7VkKEY18xLD1/7O3aeMQtRpf+Q==",
    spl: "U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY",
    feud: "",
  },
  // Saturday
  {
    day: "97",
    abilitySounds:
      "U2FsdGVkX19q9l+wEhaBXdRzboPG9pfOo9MkHmtivdKai6Zj28SOxz8tv79OMNi8dWnUPkF0afxKjngCaohMxkHqkIWpFkWZOtlNuhvU2tM=",
    abilityText:
      "U2FsdGVkX18wsurgqH0L6QowDKpQXTf8qTR3HjWxXBnaX4qGvvl8WVkYGdz7sMneZZJGRqIoh2Lv2vK62d4Jze7Rv0BS6tUJiIOgxUwufhE=",
    gods: "U2FsdGVkX18+jI5H6Ko4N4ta7ImudOYVqKtHoc6qjE4=",
    quote: "U2FsdGVkX1+Ppo2/A6BOOwQE+6YiyhJcZe66KOM3ox6b9FKGr+2y8pYI8tMkSfNv",
    vgs: "U2FsdGVkX1+bY62qu2JKDyhAUA3j+Ph1k3Q+xFJMIxZG/VUxNGSo008yY1B79zlru2wZaIw7983awAS4GprDcg==",
    spl: "U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X",
    feud: "U2FsdGVkX19/LdNfeOEtvx6/MWqtEQDsdeO0wCGA/1w=",
  },
  // Sunday
  {
    day: "98",
    abilitySounds:
      "U2FsdGVkX1+7jNzK3OglGv1zC1zkkErS/XSIyjoBBAWgcolrkcKrQyf0DWk84ErER3pEhuvQ3egP3Nee++f8SWPy/PlPrlDkjZ/9SMx5xkM=",
    abilityText:
      "U2FsdGVkX19wWXV+vuC85cOtEkg7ZHhfbHN7hm1Pm16CQx+sb8wM3MYdbDQgJ2uKupuWP6SRcnPppXzS3hnoniBnvRWW1MH3Tm7THOzGc9E=",
    gods: "U2FsdGVkX1+AYrhdNXl0b3HNT/FUMrp4EqGO2YKuCWg=",
    quote: "U2FsdGVkX18w+rMgApUCIyNbbijr/nW+o0B2WIYlMC8rYxTitCWrEsVWHq9hSajX",
    vgs: "U2FsdGVkX1+P6GDUce+pEpvl7PZxbIh/cLJAT0SnFAfi3vlczp4hZph6g/VfM5XQ",
    spl: "U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5",
    feud: "U2FsdGVkX19/l46+ODV9cq4j+gtKEYCo7LvdCrwmJW0=",
  },

  /***  ****/

  // Monday

  {
    day: "99",
    abilitySounds:
      "U2FsdGVkX19/xsEaNqlWqZzXB01s7/ss+qV8ckUpHhUqsAv2A+Gu4+MqSOabZnigUGpiGTBrJ42VEp7koKesgnINinAufPr8cZTptcL9wOmlZDUiWVlYW2bpQLFWwlVL",
    abilityText:
      "U2FsdGVkX18GOVfnTOiZd1WiKO2SHHCFVVi8+np6ZNJFUrdRoJ9FXz8GumakSE2xMgT6TzQFmNkeDYU4IIg9gJFWK855SuhWUuIvrIUUktI=",
    gods: "U2FsdGVkX19tRw7oFefGuI92liM5O70of22DQEZhG60=",
    quote: "U2FsdGVkX19IfGoTwgBCDgVzwY83+Dg5B4okIXlCxc3fH8EP1Fj+S2mDjl7VJbXL",
    vgs: "U2FsdGVkX1+BcPcn4S9KBxQtvRGLfWmXL6K1v/viegVmvuHjOfTG0LgN9ZHUcze7PNVZm6n3ILDYGiY/z8ErWQ==",
    spl: "U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=",
    feud: "",
  },
  // Tuesday
  {
    day: "100",
    abilitySounds:
      "U2FsdGVkX19aPG1r5mDeAp+mjAJjrw2nXmNKvo+1shfzu69alje+uLKELJogODFTkGsKJYH8HI7ji0IU7aUnjCyf9i65xEUhdmEvzjWLNYU=",
    abilityText:
      "U2FsdGVkX1/zBbYa8qbex7m9Us6SGL6DXe7R5J3cyL5TdmRHT2v4C4apRI5+tqr8qdVcjr8bjCF/TKQ5l3Is3+tWhuNWENWqsVcpuDEaSQ2Rph6EkBy5wpnZ81ncmgMb",
    gods: "U2FsdGVkX1/1Q42zxRwFNaPDWwyWBi8LYvgJT/Ko+6g=",
    quote: "U2FsdGVkX1+cHwnBb1vGLNPfsNRExjMi31zEEaJoI2iiW1pWplFMkZwJvRqP0HUu",
    vgs: "U2FsdGVkX19Vkpq8/EIq8+pVq4llEk/LcVZk6ifZ86oiwv2Zr6pORhN0NSIsfU1hRrqa5k+mV/FZPlGH6CRqpw==",
    spl: "U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=",
    feud: "",
  },
  // Wednesday
  {
    day: "101",
    abilitySounds:
      "U2FsdGVkX19M1bvrfeDqagVH8bHp4bPX+e//QzsNuwKwSyi1l94RkCa57qHMMhrnTO5D2H5ud4CMKmYwigdH/h56U69TT4jcjWWKfNOGm48=",
    abilityText:
      "U2FsdGVkX19IQzWDBahEdhbMB+t5k/YWcoImdrUyiT2RoBf3XMwS6yQZJH+2YM4bf+n+k6Ip+yy6jjY34NR1ZbaRf6lzgHkO3CoppJBZjnA=",
    gods: "U2FsdGVkX19UX0zEzN2z+C9HlEs+7zSAEyHOKijeB+M=",
    quote: "U2FsdGVkX19nBvLSN3TrAM9w9vQ2c+s0tugIely9Ei40koN7ZvHs7Vv92bTZtZC1",
    vgs: "U2FsdGVkX1/Y+af+EqQKzY81vei9y0h8UC/SwWJIiODpra40mU3WMdvoIeXPY4ueEpmJvH6MfHTijrCmQUdfSw==",
    spl: "U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo",
    feud: "",
  },
  // Thursday
  {
    day: "102",
    abilitySounds:
      "U2FsdGVkX1+snJs2zvVICBHdmGeePFXtB8a0075Otd2KWxktez+tSoHlvK1f70PFewscPJO408VvONeSb5samCCVFpGW/MfCsfuWecqL0LI=",
    abilityText:
      "U2FsdGVkX19BahJ5hnd0YWLIqbEb7CWqEboN8iyNWy3ZjKba9UMxDUKXHqj5U3M0iVrgl5E9jpmMWEbfZtp8HftV+IZFcZUaq1xGQAOainI=",
    gods: "U2FsdGVkX19RPETqw5JnXP1rk1TR4Rh3OL5e/GvYAHI=",
    quote: "U2FsdGVkX18uHc0saKvFAQntrozLYWAyureX2pkzEqB0Prhla53ikV2ECWFUlrh5",
    vgs: "U2FsdGVkX1/dnawWuKAIIy4dLa2FBLBZtBFpbQTJbyUK1u9afuWbZO2ISBVc+Os7aD1iNtLBVcsQF4iJ7aQrlw==",
    spl: "U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB",
    feud: "",
  },
  // Friday
  {
    day: "103",
    abilitySounds:
      "U2FsdGVkX1/gJIpbRkmeOz1cWVI1AtV9amyAJOuJO7739Ef8sJ81xTszVDfSKq546eNDnSG92y/O+4vcqKIFfzcbF97qukVL7jadSnrtQnI=",
    abilityText:
      "U2FsdGVkX18F9Uz5riZcU9i9TVVmpC/SwumlW3d9z4vrWt4fo42PXDcR4EkqLD5ZIZ7T8sC+bGJzfNUS8+vVehSE5+o+oUQmY7S9uqObJLk=",
    gods: "U2FsdGVkX196uHdIZRkyitArKIZQANq6/n/hlr2OcDg=",
    quote: "U2FsdGVkX18J4yTyJNEzILbGPbDUFMnjFfJm+0uHyzeqc/NVUbzH75ZMhe/EKStw",
    vgs: "U2FsdGVkX18Kkx+yyag5wlFfFmRlac4keQE2FYkiFxPvUS4HQUC8D0FrX3y2tARxKE8lhq+YrenuRS5xq2l+rQ==",
    spl: "U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm",
    feud: "",
  },
  // Saturday
  {
    day: "104",
    abilitySounds:
      "U2FsdGVkX1+nKl/DAUpYJMRak/aL0SxU+oWr4IPIMdC5WBJBgC11E8RNbsuIBP1qm35ODZoejSLATNcilm4QXEtDbqeHJMxy9OE9kS1a/ww=",
    abilityText:
      "U2FsdGVkX1+It+4QgBXc7OJxygd5ei/MFrAI+BvKRoZFyIAfPCahxAtiiHCpVLcPvfnjiRBCRGYXYZ0Pu3IYVjnPbpxWfAMuwqei0FIOYZM=",
    gods: "U2FsdGVkX1/us+xvihZT8p+wsfMfzmfo4arQTy1l5+Q=",
    quote: "U2FsdGVkX19Tqx6yL/ox6AxJFcyISpnq+bRUqVQPRr1fGp71RF7U0okBo5oIGuvN",
    vgs: "U2FsdGVkX19YQ/VGxP0RYo4A9kr/3b0/uw7dCqDfDf9E5ksWZBLsud1Z2xOHQIoGfmE1XGiHH2PUfRYfaitlQA==",
    spl: "U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL",
    feud: "U2FsdGVkX19IQ4afLkJ0Ploz2rkF2jv6cRgCt8/oRPQ=",
  },
  // Sunday
  {
    day: "105",
    abilitySounds:
      "U2FsdGVkX1+E0fF6deakyRkOInr+JM/OWVAXjRAYCZ9PI7oH/7tCFQRMkXcenC6giNnRcLuJAlBei0MVmsEu7fu9qL5Wo7wDCjVmpoiSFN8=",
    abilityText:
      "U2FsdGVkX1+OW9Eq9s31KLJhHD5zx53nk1pTAZQnw8H1oDm7J2aMpxu0EAOfkxuGiad423yAGFOtMyTriAP0p//gzgAB+Sp33YP7Cu1bm2VCvL5n3zIUJ8/qu6NtPIDz",
    gods: "U2FsdGVkX1+asLYewsoLKNLKCaFtjfgbDDXwxyOmnxc=",
    quote: "U2FsdGVkX1/FtsAj+8kCNNrhDhrlitTpeX9bype7fYCXFfjTQris0o/v16FRLpxj",
    vgs: "U2FsdGVkX1/f6fRV4caRVyJSqMPzZZ0x8Mt4SaQjzWBCUdfT/Fd68KhQr0q2KH2UrNrj2bOptBAh35PZg5DCIA==",
    spl: "U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE",
    feud: "U2FsdGVkX1/IudmfUhpVNDor6N7owWwi7NtCl3fkhkk=",
  },

  /***  ****/

  // Monday

  {
    day: "106",
    abilitySounds:
      "U2FsdGVkX19BfzxVCjNE8GBpQFSBz8Ka+SWjLYYT4ocXLSsjFOLtUHWTlS31JtOE5j6zyT8QGkvOHinKgYbzI6wGdGUTbjEyLBQkf6tnb2E=",
    abilityText:
      "U2FsdGVkX19fnvq/646wsmEGiyC4zgXE1+m+L3IZ2q7jkjowob5P0Xw7rvj1VudLPaM+Xp5617ZVjyNW7seO4A5eaWAs+YyL7ScwaupHW/B8TfhxaidgxAmqV0zQWPJV",
    gods: "U2FsdGVkX1/vfu1Qx1RqVHRG7a7BI9ngzZCv6gz8RHM=",
    quote: "U2FsdGVkX1/MjUJS8aw6V9syO4k4Vfvh/NazV51Vfyb+4YGVdgQJY6HUj6P4mn7k",
    vgs: "U2FsdGVkX18kD6dRTJZMnojPhrq9r0FnnZLP9dfu8VxR/zg3VRYeCUXZKHycMhHPmVU1zg0JDtEhNmADVfPLuQ==",
    spl: "U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS",
    feud: "",
  },
  // Tuesday
  {
    day: "107",
    abilitySounds:
      "U2FsdGVkX1+OaVnCKo1XagnDbJ9v9qgh3cJ5eelYpH4cfBXLIWVHFUVRg8IhCOqaLTYTyZ0acorbXyk1jXReMQjN+uU2ut+8pA78K+lRDzk=",
    abilityText:
      "U2FsdGVkX1/EZC8I4AUfT6JngSClH6bwkbOAeTu59sxUc83PVt8H5ISuAPmF/PNERnZ5xykvAHtiPqiAbq3T0nL+JHAs9Yw2nCi0u20yX/ma8I1i9xi1pI0ebN5YM449",
    gods: "U2FsdGVkX1/EA6ezXCM9r/LoDyGBKkmaJpbiVL6E4B4=",
    quote: "U2FsdGVkX1/KyBp6n2h342204FGXzpK8mDrSIjiq0kaMBLCn4Iq/FFACFEQiuUyj",
    vgs: "U2FsdGVkX1/hlSz8ZrpDpFvC/0dDTfb60I4pG/5GBWY0s1x2uKFl/TL6JqGf5mivBK4HaPd+YMeGnl9RJkCrAQ==",
    spl: "U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT",
    feud: "",
  },
  // Wednesday
  {
    day: "108",
    abilitySounds:
      "U2FsdGVkX1+gmsCWHuLEo2SFGUTMB5Bvb1/JnaIBrlw7pxIu90aUNKoRoGzGkMHhr+HCY5ZLMPNbyQb7IKuCc4mXT3hakN1dEU7dopc2a4bROQvQGrCCSPpwRLUyiCTv",
    abilityText:
      "U2FsdGVkX1+qr3bi54pnOmPd6E718KH8vb2ejGDodmeZCquQXZkfZN42u/XaPdlc9nIEQsItwAo9TeV5m/HzG5st2mr7NGGFqFOm5SzBKlw=",
    gods: "U2FsdGVkX1+Zy7iGfffG05iBBevF3mifIRhPArPxvVc=",
    quote: "U2FsdGVkX18fftRe9g6ep/IYe6HTbtMDe5wPcJ3JJ0McSeGf5t0nikxyflnzhDm5",
    vgs: "U2FsdGVkX1+o693HSWxkYA1J7lleujmAQAKglAwCGvk7Yn+G+KdHLAb4M1vqxunJ+hhzTwpA2zluT+nPW3QYNQ==",
    spl: "U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ",
    feud: "",
  },
  // Thursday
  {
    day: "109",
    abilitySounds:
      "U2FsdGVkX18hHmi/+IZBVnNscLI6J5o7crl1FdJlgbsLCnLa7f21RSern+gUfJ3GNmo4CoifREqTyav6iFvxwtMNlXWW1RBPGmU3oAeDXXI=",
    abilityText:
      "U2FsdGVkX1/MAH8F4NeoVJ2ztvPyHeu13kQ3feYLKDKp87ojNCClCK+kJz+dCedV3eBZrehgMgARHCW7tGxZDHscWHbXvNW6AqmjC9+e6CI=",
    gods: "U2FsdGVkX19dOxov5Yc9gcTKfAjfymdznN6Gb2Q2qbY=",
    quote: "U2FsdGVkX18ape4+4lVklRj2i/5KJYQwD86KFc42Alrdi1DPC9+vuQ6dFvFKQkDv",
    vgs: "U2FsdGVkX1+qo4vmt2uw1SjQnEYATfKeUoBn/LVI9TKT3VbIaGTOa+HUSLXdF3mmuKcYMS4SIwQ8Q268fyXD2g==",
    spl: "U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih",
    feud: "",
  },
  // Friday
  {
    day: "110",
    abilitySounds:
      "U2FsdGVkX1+QNGp5+8W3VU+LNzdGX1Gt08TqewVQ1JGwQHxWlLSLK8vO8plZjzcOWCvdvC/zEONX8VXA+WPLc9xnwOZXVoORZamNAG+N6K0=",
    abilityText:
      "U2FsdGVkX19BzO9w4K7oEcWzUvk59B1aBb/ud9y0KbIquq6iwL6/yadmNDbPYiOa99UEviasUx5cf+OUEQ44Q9KMDtiS5767hnJtyqdv7Qs=",
    gods: "U2FsdGVkX1/puGyB8BkmcBTLmX9GtJq/YMZIh3yF/Gc=",
    quote: "U2FsdGVkX1+Y/hJIpj80lrTLcPzUdOwFdiP/UqGRMpXcsXIOaVPs6veoqA+6Z3k+",
    vgs: "U2FsdGVkX1/iBZyfJPtOD5pKs3agH3NOm3JACtZZpClZ+35IR5C532NujLJk3RlBwnI9CJcMsYwEmJmF2QCRng==",
    spl: "U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B",
    feud: "",
  },
  // Saturday
  {
    day: "111",
    abilitySounds:
      "U2FsdGVkX18/SsNJlpF9LS8jJfXoRPCB9x9xE3b1p0B1Vl1u/jS4GfZcqgrAk3D6okgg+cWGwRALDAykDu4njLfOikb8REwpcbzAX5YY5uA=",
    abilityText:
      "U2FsdGVkX18mQZg9yTdguh/TYmr2iN4OD6Zo9+uWXraGN2tsINtakzB9iB8CwyP25gfECfeFdxh4yTH9qkEzw3zx8J1b4EGWbuk0+LKI2xM=",
    gods: "U2FsdGVkX18v61+o/kDTAj48llUXhtK4WkqY/ffB93Y=",
    quote: "U2FsdGVkX1+u3K1Gl/rtmGi4F/WkdSCUqrSFJaO6Np/YWU268YmRGf7YPFPSQ1MT",
    vgs: "U2FsdGVkX18NPxQ9ad/4CtkGEE2zwsXOgnEqKknrxG4mQ5VcdAGdQRms3FvO/nI0pvJjt7qMQpCEz1nNlWzeFA==",
    spl: "U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR",
    feud: "U2FsdGVkX1+/VDubqTiNlTotRKmMX/tG3812jILka/o=",
  },
  // Sunday
  {
    day: "112",
    abilitySounds:
      "U2FsdGVkX1+pnx6ChAF/yojJ22wytQzbE9XO0w21zV2edcWN+gLI3kxGIgynVJtlSpYvJA4QG+U1pl8Ne8BPepJa43/CJvkHNIKZczERuF4=",
    abilityText:
      "U2FsdGVkX19FmfLpdmBKNqv9sj26H787DcbaDmlZvs7/40Y0RSZAI0Vx7IOay8+8kJwW1FKCuuJjsLf7P7rZBdiMk8E96ZCoTm8xLOnsFCY=",
    gods: "U2FsdGVkX1+r04flVh51NJj1xnM2KDutPax7EkOUmTg=",
    quote: "U2FsdGVkX193ol6+PhuttrwZLTyl2BwVxr3Zv/kRZTwkKYqXXSQEUuZPA6U0suJG",
    vgs: "U2FsdGVkX1+XD2mMXdQCPiOISaKTbt6vWRosMhU7JJ9I9Oau8RTsX2OA2A64CWUgi2FrmqeE7ewfKhDBkP9/bw==",
    spl: "U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y",
    feud: "U2FsdGVkX18W0C6WKp7H5HcZ4hn5DDwPWZfxOv5BiWo=",
  },

  /***  ****/

  // Monday

  {
    day: "113",
    abilitySounds:
      "U2FsdGVkX19x9fooNXMz5Mbmk3chiZQjYwYVUCTNkreZ9g77C4vrqY18XB3wYwI0LVV5sLf/+ojKxKHBeNeZDOmMpDgDm232XWfmfavF7QI=",
    abilityText:
      "U2FsdGVkX19MRtGUi7d8y0i3t4Ki8N04//2E3jAz2OnfJjznsePJmEP5eC24DxUhEN4B8n1jHo8euzyLU0gKGRVS+QwOBBST1xmiIZh8WN0=",
    gods: "U2FsdGVkX19o37LIlCOGqewRPU7wgspdWtDf2EAGy1U=",
    quote:
      "U2FsdGVkX1+hxlZgW4dwq12hkGeXmNcQe+hpeYXeHJopfRaC779RsM/Tkh/uBbwu1gFy9B81yW3WmwgFI+Ifhg==",
    vgs: "U2FsdGVkX1/IeNjpQPLwGqSTornRv+Odn+cQmaKLK0hRdS+yeudFWyCO+3GS6K7Pe/waecXmVfkUKt8GQ+PRrA==",
    spl: "U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s",
    feud: "",
  },
  // Tuesday
  {
    day: "114",
    abilitySounds:
      "U2FsdGVkX1/O8COeyRx6DNDIfAtnvirqVbvqYLG/0o0YXtNACZJRZBTr4Iw+pX+fJ8+v0STK2O8mAu4ep+J5qlMIdsoPMdeDfyjSginekg8=",
    abilityText:
      "U2FsdGVkX19lbKn2FVsI0DNXHhvMefMic8/N51nukvNRtSs1JGi+w+7z3GaurNlxnGRDHRXtFdc9ZERFtaIia49ggct4mK4UohjbxIk2sz0=",
    gods: "U2FsdGVkX1/nA4rXXWWTseeSYlde5vtg/Vox4uzOjCU=",
    quote: "U2FsdGVkX1+mjxy56hoPJxUpsz6OXwsWtg5+O3Dy2OqMMjoKq56nuBfuUycPQDP+",
    vgs: "U2FsdGVkX1/3OKnEChHCmLhmb22zgYk+2VhKZVB7LwhJnxlqEQ929tyXAYRZqoFFDa2OeeKciYHn/L0xWxOMzQ==",
    spl: "U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L",
    feud: "",
  },
  // Wednesday
  {
    day: "115",
    abilitySounds:
      "U2FsdGVkX19rrkdr7xLjv2CROZPDHs6Uk803iPIjgLqMWM3boAJxKM0VRRiCYexVjC+6HuGN8D9ww2mnnPlRncY7DogGBkyl7w6npS/RbUk=",
    abilityText:
      "U2FsdGVkX1/oINqjAknfmfkVAEuJiTllAA74DIC3fRJ+5SRe7mxZZLuoEFnwoGChVyA7YSnE7ENLX4zVnrjCFIvT9VVhfVw1WuYp4MV12UE=",
    gods: "U2FsdGVkX1+jQDYN6vRA7MHDp5EqLM/tT/W9BQ7ppK0=",
    quote: "U2FsdGVkX1+aHf27g1VqA6DHqydTZ/6gXtDNWWgdMzfCOCJLzxX5dKKdad8O+ItJ",
    vgs: "U2FsdGVkX18+5bNh8/ZLGK3dqEb0PxP/qkwHq6cVc/oFw8b2EAlsZb1CqrUJLCSOUIz404GfClIvcH1TQdq1eQ==",
    spl: "U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG",
    feud: "",
  },
  // Thursday
  {
    day: "116",
    abilitySounds:
      "U2FsdGVkX1+3AzIb+he6ja9kuWU8bsPIoJXCCI8rN6jTyY0mZys0qo8meUMZAYQ76F+JU7ArN7/U9OgVaiUbANF75xDfk+V2Qf46ZHIQIeo=",
    abilityText:
      "U2FsdGVkX19ZcB1i7lzamV6jLRSohNZ9RlHpBHpPaqRoWzsqhMzfNmlGZsB/ger1/tNrjwu5B63w4vw/ijebIlOB0u86MGt0yd4fjSHTPos=",
    gods: "U2FsdGVkX182Mx6ZNGQbc83Zs0UCrN0VERz3wijtpLg=",
    quote: "U2FsdGVkX19Oa19eyOv0KOSyBwXlq9w08UzPXErdXw3W7O2kylMk4qtqxoU8V+N6",
    vgs: "U2FsdGVkX1+GLoVxp/TB0npB+EQlR/6B0+3N7D3bu4QUrOkje0s43oeprneyB1YZ",
    spl: "U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce",
    feud: "",
  },
  // Friday
  {
    day: "117",
    abilitySounds:
      "U2FsdGVkX1+BfdxETjRp7Ew4TyKJ34+JXsWToqLDL9odWzQ2niCQ96MSNTxBpNSNedwCQ6mqbp0+QLDnDq4Yu7hxW2QKmSe0zW172A5PqKk=",
    abilityText:
      "U2FsdGVkX196ws6SA20D+es0ymxfe2z5vqT3WcxGGXevHjLlbKUyZjJwQIg7CtLw2bHu3acQstIKTaQbf1khBxl2fsITg8v6ixBgtp6+wHo=",
    gods: "U2FsdGVkX1/Wdtcxks3oSoILKRJndWyHX7iV/SmrapM=",
    quote: "U2FsdGVkX1+5MZB7/nIklVgpPc1cIqq2j2fEfQ0J7PDxAIaYdxoRQMLaPUv50msI",
    vgs: "U2FsdGVkX19AF7Zn7x0xRg/3vYV+nC27acHusvFvtCZzV11ehdsChRzY4n7LXskfgSIDJtAHQ3JK7RRA1QRK8Q==",
    spl: "U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx",
    feud: "",
  },
  // Saturday
  {
    day: "118",
    abilitySounds:
      "U2FsdGVkX1/1EFwSQlVkHyx5fWBIQJ288R7sPN4nMFuJf9PsZSpzolgKTaiJk3eG3R6cAteTfuB0yUhnToeZC7CY5y9K4LrAVzhboVdoviE=",
    abilityText:
      "U2FsdGVkX1+sWrXYpe5KwWIIpApmB/A3jhOO12U1wMKGBMh77EwDCs7Os8oILHk9o7PvJsJfnc9Y8vOmyStXlzqHzcHu1vr2+QHhn6KhU2Y=",
    gods: "U2FsdGVkX1/Qny0pRMSa1MkAweTinE7csQWE2rGYfsY=",
    quote: "U2FsdGVkX186ZpwJMQMTcS6dknmWl1JNnlIIST+iXBFqrSS3ya9PvVWYuquAHb0z",
    vgs: "U2FsdGVkX19wz2DzdTG3dQ/PAJBDkNnRtEGBNGXoPVd3BWsPJmbPLPYQq56vNSj/xy9BRiqp1TpCHtcBb/HypQ==",
    spl: "U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh",
    feud: "U2FsdGVkX18ty1IDr7nOqueL1W4ltoge3636NimFjfc=",
  },
  // Sunday
  {
    day: "119",
    abilitySounds:
      "U2FsdGVkX196WGWawAJBGhCo3X3RIsRruSWJrNIptx+ztYFJj59/UX8zr4NWoE17MelPqAw8lwss13xQgo12j6HZ0x7r7VVePm1gIuxQxWw=",
    abilityText:
      "U2FsdGVkX1+aTxdVaOvQYW1533f60PaGB3eYIIr67zzIA/mjoqITZTJXd49aX4ReEcbi58QZ7/aVmlhv0Hh1QWG6aweLr2Uc1pL45AIPBcM=",
    gods: "U2FsdGVkX19q52bnmnGIE37/cK099aqQ9r5jyuhZDic=",
    quote: "U2FsdGVkX1/UvVSCrgUlfRAVsKSqos0ISIqLeQjjM4JNkitvPBYtc2EF+3Ay/yr1",
    vgs: "U2FsdGVkX18WwUGUQOQ1PoJGzeUE8IyoCxtEFjELR+0erdSdTQCGvXDJJZBkTMu/RzN/X6zdFEcgbgOId6Iusg==",
    spl: "U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW",
    feud: "U2FsdGVkX18WlkfcIXqJrASSBN8bvXdFTP2pr0ADpZ4=",
  },

  /***  ****/

  // Monday

  {
    day: "120",
    abilitySounds:
      "U2FsdGVkX19s+XSvFoYN3vfmWgO39mGRM0FtyfEO+okPMxoQrXjX+1ehadLy1ypufinz7M/9EqtOYI69uymcI4g8wPv8L6cQAnsGJ/wt6fg=",
    abilityText:
      "U2FsdGVkX19ao5u3NJ34eHS0RzqTyFigrihYTKNGhua8/oXBV+1RSrPMlED3jzAUm1o5c0OrIkIndfiVkY1NEufK6k5TTKvmOI9DyuBYF+w=",
    gods: "U2FsdGVkX1+RyqJKgGJ7RfgTTFkFwR16lsF06A2XgDw=",
    quote: "U2FsdGVkX18BOA2dXbITF7My8whUNpyuLVpBg8LkqJbVufY2kvJvJL7200MDJaeB",
    vgs: "U2FsdGVkX1+bZUZpNJL7H676zOFtwA8SPygTlx9a3l8c8iCLJ63m8jeUkuoy4H0mY35IGZ9DozsgVZpswS8LHw==",
    spl: "U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND",
    feud: "",
  },
  // Tuesday
  {
    day: "121",
    abilitySounds:
      "U2FsdGVkX1+fbRIRFz8U7DzfR4h82poIGx7bYszem7Y5AqN8Hp61uU4t0DZ4H+1Mp4oEUYkjWp+rS/Ow1fgzYqoofZ9XzlNPoz7bV8Qol+4=",
    abilityText:
      "U2FsdGVkX1/yOK+p7r2ms59d4I7MAq0xzmfSOcgBdcBsyZ1Ta52IGHHwzljOz/PYgQSMLCbJme8CkKb3GnBkbM034rQEmt0+vpTc4M36PFE=",
    gods: "U2FsdGVkX18XXZG6F84X4OVK/h9m7X87wH5513QgQgM=",
    quote: "U2FsdGVkX19QXuX3xwPzdn2K6sTBY6jm/FD5kU/H0Pw2GFzhKzrynQ3WSwYzKirr",
    vgs: "U2FsdGVkX1/BlQ7asbbkQtXWkItW/o4q0UZGQsXO14wiYYkdpY4TVKH/RebcWl1+14ixZTxB8kwUtMsPzfNjXw==",
    spl: "U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU",
    feud: "",
  },
  // Wednesday
  {
    day: "122",
    abilitySounds:
      "U2FsdGVkX18m/iDrbXtwLZiM6fw2mosoQIxZGh480PjLiUZtP/6R0BlifQzI2F43u3HKDVHj5Fajz3avMVCH5iDcNY23FHUFKBTdW4n6n8Y=",
    abilityText:
      "U2FsdGVkX18oi7H/LtJgmjFFHAcQdyFdvL4ZXue22BNObDfG/ZUe7jpBNiiM7qO16zoKeO4KXyNYRkblrldJttdBfVu4YpLkRTfxnjkw/q/v0KkgIvwU0qBDsC/CsTaG",
    gods: "U2FsdGVkX1816k5lyFkHthyccMnKbhZR41NzHl0F3ro=",
    quote: "U2FsdGVkX1+Jv4ATRYJE/n3J8f9pnjUiJx8XXERKLzqG69ewAe2cQKeooGUL6oGg",
    vgs: "U2FsdGVkX1/4VvwwZyr2nuXL2QQ8CgHqc0jim+SHsANmgBUEyN3BgUEKcL3/q+ESRAyM84eeiLUiIf1nsW7xnw==",
    spl: "U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC",
    feud: "",
  },
  // Thursday
  {
    day: "123",
    abilitySounds:
      "U2FsdGVkX19IKpK2Rzhhp7EsEseNdacinkh1PVxR3sdRTtnN719P20WhovX/CrCwKMcIstnIlMcxxIrEEsVodLN6LIWpSitKFR6SFDaX/+EFdW5agP0raelH+br2I3QT",
    abilityText:
      "U2FsdGVkX1+LpUG7SM5pdU869HcyVKQTI/ysRw0GVKJ3/Z8SBZ19IPScExRbx5Am9s0OMy3oVt1fTZ6NDQHbIQhzOITaBtZ7l8uCRCPcJvRDNkJ8mBG65+7V41DIIuAA",
    gods: "U2FsdGVkX1/z3Ka2QKFVyF22JRDzpy0Q6I8RCk6Arog=",
    quote: "U2FsdGVkX1/n13msNJDXDlUh+NkleoSGlaJwcThJWWK0wTGxPtNLH9gALWzwIDq4",
    vgs: "U2FsdGVkX18UXfVBf6JzyaObMKwBL7ysYL2E5lzUKVI6FkjUYOAalVW8dlDthktZw6s5t1n/cE3rLDvXCO8gPA==",
    spl: "U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7",
    feud: "",
  },
  // Friday
  {
    day: "124",
    abilitySounds:
      "U2FsdGVkX1/NRLYPbSyZt7CxA6lu08qBQ7VBNKPhaFjNy+g1d4kJYDM20ykyRwBtQlzoSFjT9hankSfOpDTv2/OKcdYS5jM+3McjUcscY+U=",
    abilityText:
      "U2FsdGVkX19cupPOXNvqZN/MrD2RhwUEafSzZppv6q699+ut83h2bDrw/Llxub31wUamr7CHNY3blRuD6rToEi+CAH+Y7jjL/o9P8YaEOAN9iscuwZHSlj1+eI+gawhi",
    gods: "U2FsdGVkX18nsl/MZeRxU5HDoQTqNadhWvyPx4wmMnY=",
    quote: "U2FsdGVkX18MhmQV8qHsKSLzD0TWQg/XANeX0tqTZS/lOA5xNKZnY7/3safejsxt",
    vgs: "U2FsdGVkX1+DzRQiqQw6kcApZVEwE4Iy4b2PCzfsNe+jZhVAgbGzB5t81GqRIqTACnPjEaZezaOx0mVSu8ikyw==",
    spl: "U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs",
    feud: "",
  },
  // Saturday
  {
    day: "125",
    abilitySounds:
      "U2FsdGVkX1+7Pf5RAZTlAh4JnMT0AQzB/PoIeRcfuqcOf2czDHAyDGI04q9HF/LDKUv+XB78R4o5+WVBe4LryVn2ISFNUbNowjL06mimG68=",
    abilityText:
      "U2FsdGVkX19g6A8sm6q7NFvIUdqCQdeEDS2ry9y5nP6qiQrAy9zgDtRDZA50WZQvmnrP4wEiDyclNDt/1CfEycViXF9d++Nu767XUxHcIU8=",
    gods: "U2FsdGVkX1/N7F1uy4EtN/hJ1+5ixWjJF/Ajkk40bog=",
    quote: "U2FsdGVkX18EPOj8nxCeJ0dpyfrSqD4T5f6ILA7RiiXVgBWYvnSXqCEuNLvOL2v1",
    vgs: "U2FsdGVkX18x11Bec1OU2GnnL10InN9vK1Xaucb7RKQ3KZVaIOixrmZYGQgV3g2uBEFN31UO9aCo9U7dOSqu6w==",
    spl: "U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR",
    feud: "",
  },
  // Sunday
  {
    day: "126",
    abilitySounds:
      "U2FsdGVkX1/gdDm14cv3kuCVa39dckTCK9dHRCLawD6siilJmt/gcahq+MbujAeiFEcc6oieZkZAmvUPer6cpQzehsIfkUrpF7Rm+03Be1M=",
    abilityText:
      "U2FsdGVkX19wSdlJkqoA3N51P9DqvqV600+tkf9MvZcKd9NQgkovJikGu8SWLGnR/76ssA75hdcWnXwmd4AcFRT2CVuP1MGJaBg3gg0o4XLq93NhFRIzLaf3mN/+DPmy",
    gods: "U2FsdGVkX18EOOPtoXxDub4eFi3HexKSMLDVy4TegH0=",
    quote: "U2FsdGVkX1+I4yMLP38ZAOgk1z9u2KPOBJF/u/PL8Z7YIBFbrLSuhIKPGJjP9BmV",
    vgs: "U2FsdGVkX1++lCjTH0AsI29GKJlj8L6FAYjWa2WzUUt4UTTKBlsL17pJvaPwYHpn",
    spl: "U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb",
    feud: "U2FsdGVkX1+hQBlpZaYYR/7wkGYpPqL+9rPaidDbstE=",
  },

  /***  ****/

  // Monday

  {
    day: "127",
    abilitySounds:
      "U2FsdGVkX1+gtt7P7V+AKb/5xVojOd23RUCEh08xwTFgaz5sVXhW/gFAfti36CZ2OLVyOwKAhm50SuMRbYf5vMZWT5O1WqnRX/bBeYBvvfk=",
    abilityText:
      "U2FsdGVkX1/slcf2lmqBlaAz9o/hwDd5NVFHvxKlu5L3gerJ28CuiXbV15oZjnK3+hmvuyy0g6PGGyoKsiqtqCwQCEmD4YDkY971WQCK5iP0F1foTUkiXOGERfVoDEVo",
    gods: "U2FsdGVkX1+bTuRr0G5mW6Z+6Eq5hDDWuD0Obu590kw=",
    quote: "U2FsdGVkX19BLvT39B664Pi5l0WzRX+mWVEBN1F9vfq3KgPKrSaqx2O8p6/mqCPS",
    vgs: "U2FsdGVkX1+1fQqg7KMDSwo/d/SqS+eaExUsDPmUBI9+jK7KP4lhqj59TJOsF2eO26qpblw/NCTpjVZTOA1Nhg==",
    spl: "U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY",
    feud: "U2FsdGVkX1+/gvW9ygGB4BF2G90QObqdNHEvlkfCAkc=",
  },
  // Tuesday
  {
    day: "128",
    abilitySounds:
      "U2FsdGVkX18LsgLV1hxHU0ug3oy1EubCjrXFk36a3spg12JUwa8k0hkHmDdm1dkagUaQnvG2LXIVSP0RFXGBSFbYGfYJ35vuj/8XDpUO9eQ=",
    abilityText:
      "U2FsdGVkX1+VmogEWwdbNQeYnw8nAQpOxs7y0rRQLjyEpUxJnujpG4Cm5R9/VFec3sVgTBpkdan8gZ09cN9nJuiImCdFPDU86VXm4buYPBw=",
    gods: "U2FsdGVkX18TNzoi2P0F8WmN3SiECojzs7o1+vJZ6eI=",
    quote: "U2FsdGVkX18chr3dF19egNvCnt6PS2NP4tzryyYb5xngfy2Xf8JgOiRMGY7tglfx",
    vgs: "U2FsdGVkX18JiqeOfHVFqbCQNv0Bb5RnE+Kq/ZretU0//Gb7UjexSdLq92yChvkN5izOCfu3UgS3M2F70nSB2g==",
    spl: "U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X",
    feud: "",
  },
  // Wednesday
  {
    day: "129",
    abilitySounds:
      "U2FsdGVkX1/3xyNGlQEt0ljwfHXfKFIosIHVlHmypAr/Zb0Z0oFEFjByy9Yw9y85LiFcnvzbyn6om25zGI7BQfei2e0YKIYRsPdfVT65Y5c=",
    abilityText:
      "U2FsdGVkX1/mZ39e/MUVRt5JTiEmoOM2CaQw0l+mgkrJeWkW88L/jIMMBOX9kk3prRxCM87MAjrUXtyNcHW6GEL5PI1O197xDlkT/kXqZME=",
    gods: "U2FsdGVkX1+fTRfm28O2zBCZ4l/8t/lwJz071PdjDhw=",
    quote: "U2FsdGVkX18mUhxrN2hI777L5WhJsV9QqpVF995g+nUg23qon8XknSbHzwtI64Xb",
    vgs: "U2FsdGVkX1+LQshLV2GRnAvKYe4CntKLl/bpWQmcvy3I0mQE8BGp12vfimijDfg61RhJ48LE3KViEWD5x/YtUQ==",
    spl: "U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5",
    feud: "",
  },
  // Thursday
  {
    day: "130",
    abilitySounds:
      "U2FsdGVkX18+1owKb9Ul5EL778nmimpNrPyft2D9c18FUL047oze9EhHsolSgvCx4ZM4k6kT4oHi/P8ZpzKyjrRMp+pgp1SAzS0mI8fngio=",
    abilityText:
      "U2FsdGVkX194UVkm96NqKl6JsLVNQRebEHI+aC3RDKOsqoEMb+9kMp3fy7eW2MQlgkMF8KpSzLFNCTWX+zuwU1EamNhPXEA9kH1YIoZI4ec=",
    gods: "U2FsdGVkX19wvDCPt2Eirqf3PicnuEC8FV2+J1i261w=",
    quote: "U2FsdGVkX189i4h0+AwMNOkHrKLhP6/ZWVBqdoQVmRWhAbFO8EiiQD5HSWOrApDU",
    vgs: "U2FsdGVkX18J7lQ5zvF1RMotqY4qJ7310RMB1uWizuOYKLIZ9FHyzFKzI3uq/Z/32t/t9Ayzsyg0tUyqcdWFjw==",
    spl: "U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=",
    feud: "",
  },
  // Friday
  {
    day: "131",
    abilitySounds:
      "U2FsdGVkX1+3Lk907C2Y9m7RDIyVw8oZ9kpI7bJR/mv31p9xzyoSkMASI+xHnKasHlsFyRmEzijC5OtacUzjDPdtfabwyqBZ8saFOjHkwHA=",
    abilityText:
      "U2FsdGVkX1/z0BShcgc6mYTnGSaWzl17bCBsjKmQ5NsXD8h7W7WYgLzvaf7K5GI7yuv6/YK1U0bsJomwNawky6Hi5sLoh1zXXW/TgMtbU3s=",
    gods: "U2FsdGVkX18BudcWFlJUQoTijjC5Km0FRdRlvwRUFeI=",
    quote: "U2FsdGVkX19TJLEJb00/ddnRQfwkf6fUsNPM1MpFrJp5jan2Tqi+rWUaWwgn2BHp",
    vgs: "U2FsdGVkX18fEyVSOJeOuQX7Jw6RqZSWFJTTZa//8F7ZzzbuU+KJ4EjXzRdtXzEtid21OqMtmuk58wpQKNfY6g==",
    spl: "U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=",
    feud: "",
  },
  // Saturday
  {
    day: "132",
    abilitySounds:
      "U2FsdGVkX19Cjpmqfo7XHsah7c6AkDgqNO562s1qK2KAQk7obbL0gZub6Zpw3S2R6rT5laMr168wd/ZhxGl2jMOygBmUoMYXBKhsHnarYvI=",
    abilityText:
      "U2FsdGVkX18kMhsiyjqqHBNt6VTqC1CaQPerZ064f/w3y+7prldgSLWM+zYIvk1UU4Rgcg+0MdYxaYZy26+WD+7hM7568pbAtGAjWXF+5nDgPhEjUsL5TpjzjgdefWGQ",
    gods: "U2FsdGVkX19FONdSfYrvF8dtpfEM3hpU6Jx4VEj70JY=",
    quote: "U2FsdGVkX19/09ZKp4WEekuK6yG2W/15kP1VzDpksSQ0qGjD6QCQg47EptbAB3du",
    vgs: "U2FsdGVkX1/CVGwxmd8I4Ae+XHUtOa5obx5lLBFFK0w8qkAcqoA+qh+Frs1ll7zD",
    spl: "U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo",
    feud: "U2FsdGVkX1/F25ArcmiluUJcVsDnB+8fsBXET7JXvmQ=",
  },
  // Sunday
  {
    day: "133",
    abilitySounds:
      "U2FsdGVkX1/SmDkoDh+vY+xA4Ax7kYE2Tztl7+0qevVO4HTiL4gCydYoUB0wo89tvHc7vjmDdhb+M2JtQpooyXEoLilXt7DY9ZazL2e80iQ=",
    abilityText:
      "U2FsdGVkX1+UwcRuXctsNq20hZ652NkBBnJl4UhPIZxsXvkCNM30BqMvfHnhq+RwvZhc5SBXgdD5wRMDU80M3EMYCM5UJoo1wmrDOuIIToI=",
    gods: "U2FsdGVkX1+eFoZiKTIQssDpEt/DT6VoKsHR879Tylw=",
    quote: "U2FsdGVkX1+EhSHESizGFk4pgkVsN+A7Unzx7HsPJpTw5UNCMi3RXhWtkcKkvoHX",
    vgs: "U2FsdGVkX18WT8kSn1y4QVdlAXU5LpqwBBS6HfYwjPk17MqcyJM4b9H+RTlcfOY6Ue7rMoCIbWOgFvoJFVzvVw==",
    spl: "U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB",
    feud: "U2FsdGVkX1/LrrrA5wEwAomSbDFPmH7f0q23w1u4vTE=",
  },

  /***  ****/

  // Monday

  {
    day: "134",
    abilitySounds:
      "U2FsdGVkX1/xPe5YuHW0CGgHP6qutc9vZKMSNld6ei5JvMwMTrRjfqr42UCvoipqQh5pTuOXz7IT23kCmHvdIuaqI8rgwiOmPkKulAf1HAE=",
    abilityText:
      "U2FsdGVkX1+rNTFIZ+ePY+0vBHCEVQ0m7oz5faWd7g6vyyAHsyFz83rB7QCcq2t5tVPUgK5mZISrJRDImqHmc2eb/xwNV5bLA/cwnkueQ8I=",
    gods: "U2FsdGVkX18j60tYCJ2i7Pu9fKF8f2zm8CyUZphA62U=",
    quote: "U2FsdGVkX19kROaNlQ2MidD62I4bYpAgaeozzkb8NHLySwHkE4u9YY27TCQjzbie",
    vgs: "U2FsdGVkX197XidqSJuUeULF6h2/lacHlbJZwPmEsryx9Pp0kU/KwEzEczB3LOEzkFBz/HYiYolMrcAL0sKr/w==",
    spl: "U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm",
    feud: "",
  },
  // Tuesday
  {
    day: "135",
    abilitySounds:
      "U2FsdGVkX19t55g/QYGsP9+V55LM9FEBME3UhYeVcK2BnCGk2x9HcupSkXajvq216T8JYksqcYQxRKUZIKlzV1CWjkwXqa3kVRBSKzmRdIXv2Fbf2lq+Cn3n3W4JbyAB",
    abilityText:
      "U2FsdGVkX1+fo494g4g97W/fm9e6J1AhNRY0XdHYa8C9ZEgU1l0LhXnJAVmtOVT3LFr0ObvuSkW1NrOF4ODDhcb72lezwG6OSq+dwsvpecw=",
    gods: "U2FsdGVkX18oLSzhzn4joSnN0/m2kDCYPpl0nO9r+As=",
    quote: "U2FsdGVkX1/WRqIDguCQDMyPYu43jtSXf3XjG/Gu00ogrZcVTLiOQphEFdUD2Mk2",
    vgs: "U2FsdGVkX1/tEMIFwibFnXpccYXrbKXHhhO6HDEoEsVew8iQaJLJuY2ETeebyXcWJ9I4K33xPsLxgx2p45NbSA==",
    spl: "U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL",
    feud: "",
  },
  // Wednesday
  {
    day: "136",
    abilitySounds:
      "U2FsdGVkX1+MPQfuJ+NUpF7d55mjmVA5fs2M71iqR/1851mH60dX7R13fwabmucs2UMIoCfxr+XniLcy5WCVCkyaGFot7mpvuBHWKK7vSlgbrA+ZNUDjXFQaqB6V8fon",
    abilityText:
      "U2FsdGVkX18ayhjD9JUNotFL0StiL1wx91GflP69Lu1s3+7sLch3MutmBQJudnjINo/lf2oZxB9wEwlb+MrPYX/9O0CiM1wCHJCDE0kgq8Y=",
    gods: "U2FsdGVkX1/GnIMAyuWNXKva+cRSIum5WXPuhe5Wh5w=",
    quote: "U2FsdGVkX19R19aQaAUOlIvXa7oD8WzOKPnay3D7oazYX5UWgwH/oCcRIdWTtfao",
    vgs: "U2FsdGVkX18GCtF6YjR3aXuCMKniGAUPIuUSBwnmxm0kcmaCk6B22BuW4SpK8VZh71376IwtjYzT6Nmfjw247A==",
    spl: "U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE",
    feud: "",
  },
  // Thursday
  {
    day: "137",
    abilitySounds:
      "U2FsdGVkX1+uXC6rpd+9IYjALNeIGLbvYug35a7+jiLodqSv65gl/iU2fVhFTmuHbwEMGh8PPE0vWat4n1lqjP/Mah0eabNgfToM/kji2yw=",
    abilityText:
      "U2FsdGVkX1+Az+elyfrhLosHFINWTnvpn/WVVn01NcBx1hBhnGKbd5wAkmJVI4uxWOS8B18Gbz8Mi0w7gUDGbBQfkmPpqQGZAeWrdze3cXo=",
    gods: "U2FsdGVkX19IswZuc1cfYKEM8mFD+aL4dVvLKxqLo9w=",
    quote: "U2FsdGVkX18YG/PhVegtuuyRyrZzD5I8ZgnBmFk+Jw5TaUjTtqYyfBsvIkM7u2FF",
    vgs: "U2FsdGVkX18vE+6UpmwbTKFHmIU1pdKM5xiddHFTYUx68tEHbJ/QlA9RIWzS76enJYrh9B0NMeFpXRiNGCYCKQ==",
    spl: "U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS",
    feud: "",
  },
  // Friday
  {
    day: "138",
    abilitySounds:
      "U2FsdGVkX1+4VtCxy38f5sAUxNK4QZ1LSReMsPxd5dB97KVBX6nEAZtrU8d2JNvF448FISct7KvQvWdOZz6s1JkhsMqmManezzqfWRIuGPI=",
    abilityText:
      "U2FsdGVkX1+Q4QA83Zn3+DfmDx2WvBuUYioyALf/laXaCuvoV4QY4wpPQGBibS2CQBc+4q5LoGMStauMwCGFXACfofvmkyzoq4M/Ndmdjd4=",
    gods: "U2FsdGVkX18ReiuxPXBPM00OuQOel1FKkrQQMHZ2J3k=",
    quote: "U2FsdGVkX180SEVd9mpiOD+HrdVAS34uJUn9sGgAHumfMUVXQ6OJCp/U5o5KSYDf",
    vgs: "U2FsdGVkX18WjyV32rH3arHBqaywVCyDJEi7Gu646MOHZwKNo0yxgI38i/Ve5omjzZ8CVDHL4mbgzfNMMAkwcQ==",
    spl: "U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT",
    feud: "",
  },
  // Saturday
  {
    day: "139",
    abilitySounds:
      "U2FsdGVkX184dcMugPzliLRQHcuLGsKCZnrpzn32NRdgvNdjKM4fk5qqOr+R5Yed+DRJ6NtaYoxy7vvsWWJ8EPF+EhZo8Teji2Jm6j2657E=",
    abilityText:
      "U2FsdGVkX1+2P2/ceSorcO/VlaadHYdudwj2DnlnJ7l8cI/IY/+Ot6mX552aBSTEEYgWfZFdyWvJEBJGmYpZyq9BNKrnVQXrtIbr1FFfqgIFgOYsQhC6FOCodhz7eMsz",
    gods: "U2FsdGVkX1/NO9sgeToS4zn7USZDnlMBiUSUQ2ZkYcE=",
    quote: "U2FsdGVkX187BSzSCJdFlB8XPaPJN/1ms4BUA2PYmF06QRx3gaHBzh/o7WD+uQ2q",
    vgs: "U2FsdGVkX18qgmZzf+QGZW+FxbhjQ2FGXP35HW6Dyzk/saYG3NdjHu3324z52TN/",
    spl: "U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ",
    feud: "U2FsdGVkX18JXfu99jFMkb5OwzBcuMWxpVGuXdb4Xls=",
  },
  // Sunday
  {
    day: "140",
    abilitySounds:
      "U2FsdGVkX18mcjYmJFzbAgoBoUxQpitbl4LCDLqMu0LI/pNlLvFfFv+cBsMC4BzINb+GUEZrIvbGSTQYcaN2ETtBAjLZSXremuju81CXxEY=",
    abilityText:
      "U2FsdGVkX18QWsNGywo3B3dphA1sWPbfEuarSLag/1dYRYEU5iZfXX2HpVL43RNmd/ZqQBu7BDmvLCLk7KFtevbG8NdnJEFG9AggU4FcEuM=",
    gods: "U2FsdGVkX19H4Nys2nFARuFLUYwohlM9GaL+CxPmwyg=",
    quote: "U2FsdGVkX1/GgqzI0BAtLC8RhSVwcqWso7M4BUt9bS4erJEtXTf9xm49WF0oLzyn",
    vgs: "U2FsdGVkX1+35GAgC7VFVNpJT5ae/tEYY3J7AlKXFpmY5o55mczDp9ppM5AMW66Y",
    spl: "U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih",
    feud: "U2FsdGVkX19eslJNPBJPOLs0phuu1POtkP2A9ZX3cWw=",
  },

  /***  ****/

  // Monday

  {
    day: "141",
    abilitySounds:
      "U2FsdGVkX1+96eVfrvceB6nfzYHFFfhU8D3LorPkpREDLpJeb07kHSckL+pd6wRIXXvbuDV84sV3TxGFBGH35yx2JWqlRYpjxr539ir4wQM=",
    abilityText:
      "U2FsdGVkX1/UYdKC1DZB5tjqJmu0nq15EziKWZ0DxIn00H08HhhAGE51fQtOJ1JFbQkTTwR3f+BB4aGlb2Pq60j6FExtSU2yuzwqWH3vt1k=",
    gods: "U2FsdGVkX19ZFb4eKnnsC76w7KEs+TZbiQGkqDPwF6A=",
    quote: "U2FsdGVkX19sPC0AZ7WN0zQTyEdgGzd9BNp45B0uBRVgxaOMnua9xpIIuJsMDjos",
    vgs: "U2FsdGVkX19eNChiFdIyF/dLv4k68lZxwY6RxSGXzBZ167gwmnz8uTk8NagxntpRbV3HObLghwrPDrHkT+LLbg==",
    spl: "U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B",
    feud: "",
  },
  // Tuesday
  {
    day: "142",
    abilitySounds:
      "U2FsdGVkX1/x2qGLDLc/rssB8xwHbxemGX8Ml5dNyYb9nLfwLn5zPUYW5ANLcX93yp1SGbOFNcvgj1vX+j5sJNuPV8dWXCZwtUAgsJBjZgM=",
    abilityText:
      "U2FsdGVkX19KKkTpPn8Q4ToZP61nz7wdkCIyaBf/IWAYRfOet5lEC9t8P0iD7+8bGBqqOzMBWdM+dBGTs7l0YLLySRLSWiZMYWm74VURDpQ=",
    gods: "U2FsdGVkX1+y6nkurtxT7lZypPqJEcSXrFOzvqfPrq0=",
    quote: "U2FsdGVkX1+sP1Qn9eVesOTZXFXKs4u2+NmMZs5o5KnhaGqzRcBtyYCwZyRAeh2y",
    vgs: "U2FsdGVkX1934PM5oCNCmDAsZrkCIJ62nc7rKm5KcM8T7WJgwr+y2GHfWh15ssU3zE8EYoIXoVRDAnOnv9o4bg==",
    spl: "U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR",
    feud: "",
  },
  // Wednesday
  {
    day: "143",
    abilitySounds:
      "U2FsdGVkX18rt9qanR38T8z3hTalVlCofZ5Zt3YH3ngzX5fCiCnZLvLAwps6knNVkCMi4Cpx3YlaA713QFP/CLsyqhHGPphzkgljTWQ2czo=",
    abilityText:
      "U2FsdGVkX183SRkynljysxZFoQ9us2nGzgVx3OaWCh3M8LZ5C1NzJNyFCxOSLaYqFK/tXC+VscNiXxH4r50xlEhvhsUzuI0yRzkoA49A52s=",
    gods: "U2FsdGVkX18eMKnYvUWdbkw/IsMOXYc/Gd3FSIEe9iQ=",
    quote: "U2FsdGVkX1/eWoX3Qj2QoLCp/KRYSshtC3k2NRyeE5Z7yXT1+/KosP1rsa4NmItK",
    vgs: "U2FsdGVkX18hmMJMoncU+nNYoxd8LIUhgdiV6ist01n6eMA62M0Z7WqU+gcN+7lAWThKf0Tu6Adkly2d7GVdRw==",
    spl: "U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y",
    feud: "",
  },
  // Thursday
  {
    day: "144",
    abilitySounds:
      "U2FsdGVkX1+4VtCxy38f5sAUxNK4QZ1LSReMsPxd5dB97KVBX6nEAZtrU8d2JNvF448FISct7KvQvWdOZz6s1JkhsMqmManezzqfWRIuGPI=",
    abilityText:
      "U2FsdGVkX1+Q4QA83Zn3+DfmDx2WvBuUYioyALf/laXaCuvoV4QY4wpPQGBibS2CQBc+4q5LoGMStauMwCGFXACfofvmkyzoq4M/Ndmdjd4=",
    gods: "U2FsdGVkX18ReiuxPXBPM00OuQOel1FKkrQQMHZ2J3k=",
    quote: "U2FsdGVkX180SEVd9mpiOD+HrdVAS34uJUn9sGgAHumfMUVXQ6OJCp/U5o5KSYDf",
    vgs: "U2FsdGVkX18WjyV32rH3arHBqaywVCyDJEi7Gu646MOHZwKNo0yxgI38i/Ve5omjzZ8CVDHL4mbgzfNMMAkwcQ==",
    spl: "U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s",
    feud: "",
  },
  // Friday
  {
    day: "145",
    abilitySounds:
      "U2FsdGVkX184dcMugPzliLRQHcuLGsKCZnrpzn32NRdgvNdjKM4fk5qqOr+R5Yed+DRJ6NtaYoxy7vvsWWJ8EPF+EhZo8Teji2Jm6j2657E=",
    abilityText:
      "U2FsdGVkX1+2P2/ceSorcO/VlaadHYdudwj2DnlnJ7l8cI/IY/+Ot6mX552aBSTEEYgWfZFdyWvJEBJGmYpZyq9BNKrnVQXrtIbr1FFfqgIFgOYsQhC6FOCodhz7eMsz",
    gods: "U2FsdGVkX1/NO9sgeToS4zn7USZDnlMBiUSUQ2ZkYcE=",
    quote: "U2FsdGVkX187BSzSCJdFlB8XPaPJN/1ms4BUA2PYmF06QRx3gaHBzh/o7WD+uQ2q",
    vgs: "U2FsdGVkX18qgmZzf+QGZW+FxbhjQ2FGXP35HW6Dyzk/saYG3NdjHu3324z52TN/",
    spl: "U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L",
    feud: "",
  },
  // Saturday
  {
    day: "146",
    abilitySounds:
      "U2FsdGVkX18mcjYmJFzbAgoBoUxQpitbl4LCDLqMu0LI/pNlLvFfFv+cBsMC4BzINb+GUEZrIvbGSTQYcaN2ETtBAjLZSXremuju81CXxEY=",
    abilityText:
      "U2FsdGVkX18QWsNGywo3B3dphA1sWPbfEuarSLag/1dYRYEU5iZfXX2HpVL43RNmd/ZqQBu7BDmvLCLk7KFtevbG8NdnJEFG9AggU4FcEuM=",
    gods: "U2FsdGVkX19H4Nys2nFARuFLUYwohlM9GaL+CxPmwyg=",
    quote: "U2FsdGVkX1/GgqzI0BAtLC8RhSVwcqWso7M4BUt9bS4erJEtXTf9xm49WF0oLzyn",
    vgs: "U2FsdGVkX1+35GAgC7VFVNpJT5ae/tEYY3J7AlKXFpmY5o55mczDp9ppM5AMW66Y",
    spl: "U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG",
    feud: "U2FsdGVkX1+1mz/XfoKEKZTcuMKMCwgVhUOwYdwkjUQ=",
  },
  // Sunday
  {
    day: "147",
    abilitySounds:
      "U2FsdGVkX1+96eVfrvceB6nfzYHFFfhU8D3LorPkpREDLpJeb07kHSckL+pd6wRIXXvbuDV84sV3TxGFBGH35yx2JWqlRYpjxr539ir4wQM=",
    abilityText:
      "U2FsdGVkX1/UYdKC1DZB5tjqJmu0nq15EziKWZ0DxIn00H08HhhAGE51fQtOJ1JFbQkTTwR3f+BB4aGlb2Pq60j6FExtSU2yuzwqWH3vt1k=",
    gods: "U2FsdGVkX19ZFb4eKnnsC76w7KEs+TZbiQGkqDPwF6A=",
    quote: "U2FsdGVkX19sPC0AZ7WN0zQTyEdgGzd9BNp45B0uBRVgxaOMnua9xpIIuJsMDjos",
    vgs: "U2FsdGVkX19eNChiFdIyF/dLv4k68lZxwY6RxSGXzBZ167gwmnz8uTk8NagxntpRbV3HObLghwrPDrHkT+LLbg==",
    spl: "U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce",
    feud: "U2FsdGVkX1/CruV5KmVe0OgC0e++rkiYYM4GGrqswIc=",
  },

  /***  ****/

  // Monday

  {
    day: "148",
    abilitySounds:
      "U2FsdGVkX19rqofW0j9HMMCx4CJ7/QNeOKEjeu9PAvOvhVHTondilD07Ger++mf8FyMjusCnD5b1aESDHjSWEQN8YqLJRtZVkzUBtG2jhUc=",
    abilityText:
      "U2FsdGVkX1+QkwAsyfdU0APaQEFfsLCZ7YL7l0HUSlCtIV/aLS6fQzrES2wEW8bbYoIyhuNaONXAlxfg0+jGq6iAQxTWReXJ5Fh5gMfvApp/rbwPMGLFTbBxEXrrTf04",
    gods: "U2FsdGVkX19d6NIuqP/0cmEdsAbc3Oq+yLU2N74U/Q0=",
    quote: "U2FsdGVkX1+walTZIGsbKu3zcueJ5DbGGtDqrKF38w9wbeQN+/p9G6ZDrYD2EBYU",
    vgs: "U2FsdGVkX18h5rQx7ggKd+fMWdUowaU9yA1PmGxgiNuhDkncmsvVI44xcNh72WWe9vYDETbdyAlGsmXfx4bqzw==",
    spl: "U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx",
    feud: "",
  },
  // Tuesday
  {
    day: "149",
    abilitySounds:
      "U2FsdGVkX1+IOs9mRpKTYbsOzyDAeV9e+i0O0xPPhFIV2AT5Llthj3e68hshlWSVKsnlGXixBOBnY1rOG6qAWFUCL5I1JWCWcP6J5+DGyKE=",
    abilityText:
      "U2FsdGVkX1+eGBJukCW+0k28HhJg4QKweB/bFtWDykT8T7NzRYxXug6OzQbqUQk/8WJbozG+z0MrsFg/1mZp9AOJDI+ClkekdpUS28XiZTw=",
    gods: "U2FsdGVkX184y2mvfo0tMr6tS7Iulv3Bve+DXqdRGLc=",
    quote: "U2FsdGVkX19b6YlqPlhzGxqS2Rfs/2Lm+/o7KNaaj7nVQDQoHzOLQ3yFmpd+Ls9P",
    vgs: "U2FsdGVkX19dNHZZ8n/0pHhRzW45wp3JBEGPqfHmHIG0shS6AQz2AxFpbk4N0Gvv",
    spl: "U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh",
    feud: "",
  },
  // Wednesday
  {
    day: "150",
    abilitySounds:
      "U2FsdGVkX18sVS1HgLDBKmSuMJ8t+0v1L6YO8FDmttLf6GFN5PK7ZLZSDiMkSUGYBp0uBsaoPPTepv4L2IL4jbvlPhcSeAaNOqFcg6uS5iI=",
    abilityText:
      "U2FsdGVkX18Ha7Mouau41Wt8+v11Uk28bfyl5WE+uQD7KEEQzyNBMJSGHxuMGhudBMTpFxB/t+pt36vnVqSFlLRSRYsdV5vMPjZ78vyHEOA=",
    gods: "U2FsdGVkX1+Z5FmK7Ck4xWMPsa5otyDN7io8iW9hldU=",
    quote: "U2FsdGVkX18GcVYwLvCnZDJKvu2yNdCTUG2Le1x4Wlf4Be/meLz7ad9l02QpSKj/",
    vgs: "U2FsdGVkX1+6CNS5t9gcYsG/xiZv9v1CxAyZUyR63OkQmW7Ma+74aAqGo3q15vGFzIpwhMPHGyPEc7pgNDCvow==",
    spl: "U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW",
    feud: "",
  },
  // Thursday
  {
    day: "151",
    abilitySounds:
      "U2FsdGVkX18SQKE2nLkmkNcYtmZzATDdlFut3GPgLFaWRW2ktaQ2GZMsUM2Q3sYcgOHFrWPLwfxPvkbCz5Zq7U9xxW9oLsC0pSGXLghgJxs=",
    abilityText:
      "U2FsdGVkX1/RDOyjJrW0ohehg4xdt7JUwsHC0G2yWJLkXD+dCr2pWoXhiogb4LcEr2sT90raytCTwuZkwQtqw577tZipatCB2KUlQe/axnY=",
    gods: "U2FsdGVkX186DLvxQROHu+tnj7b8pigWAWzDUwhpk14=",
    quote: "U2FsdGVkX1+WC9zwNeKmTk2GMsSSizeCfcBKv6K4nxGME/OFzBfiGkVLtXcy7VZA",
    vgs: "U2FsdGVkX1/Tp/VfmkuV5q18NWgTbMuF9ETvIFMyAziMAZ58ZEPDiKNa/6Cn0AM4c6wh6/CpAWoIBVyVY2Da1A==",
    spl: "U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND",
    feud: "",
  },
  // Friday
  {
    day: "152",
    abilitySounds:
      "U2FsdGVkX18lTpoLdRhnZsJRP/wOBQ4I9zRkuZ6eht/6he7N36GIRfbogKUr9oJQY2RGlk5B5g4OtHy4DusYnJc3zonz4VOk/5+en8HUXA4=",
    abilityText:
      "U2FsdGVkX1+BQBHRTey8yXGXybm/s5Z153ZfztWI/Jf21ENnrLzHqbQ4R/nQw/jKNBnB3H57JJIvCVNIQvAw1/kwPdIckxd7kKmAkcJ6bKYaZSIROd0JKoNWBqN6UbwQ",
    gods: "U2FsdGVkX1+y9f7gWecyWEPiIhe8S6ueFSiTqmq5md8=",
    quote: "U2FsdGVkX1/4nNhylBRa0GBe8rAaZcxQBw4UGrO2mRyNBeL5fiWWCn8maHxBy1Np",
    vgs: "U2FsdGVkX18wWW8FE0egfRX80LWZuMvkECEDaiwoFqTNS1FJ2HZaLFRu95CsPvH64DM6i12ZXWe+iD/bj4Y1RQ==",
    spl: "U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU",
    feud: "",
  },
  // Saturday
  {
    day: "153",
    abilitySounds:
      "U2FsdGVkX18gLt273dBvJErmf8Xg0MN+xXMWDDmI8CiQbR0vbzPwm/fVcGurrzfFgWd1LtWvicfS8+52jII3RcwoA6MeM1r8iOKDzqhg2cE=",
    abilityText:
      "U2FsdGVkX1++gjSbmOT3rVCEXf4vZvsRtOSv2zOb1N75XtqMeML3sEuo4xO9F568VeyDwDi9fQ8XqIJIvwFlRYccnVYv6BQDwpLDj2WUcaA=",
    gods: "U2FsdGVkX1+SvhfEOZRsoA+vnVNONDyrSrtWq+568K4=",
    quote: "U2FsdGVkX1+66jFfDq2b/lgEKffnwptVVLa8X5c5haTnMOuRrnM8zjluyEc726qv",
    vgs: "U2FsdGVkX18cb0zUQGalTDA+xie0fXjItR7B2icceFyj6HBsJhSaE8EBSva5JNrm+Mdyvu3NNIfbUGKVBn5xrw==",
    spl: "U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC",
    feud: "U2FsdGVkX19Q0q09MAeyuoHhvyyOm9m40aDWNhbhJHY=",
  },
  // Sunday
  {
    day: "154",
    abilitySounds:
      "U2FsdGVkX1/Pn1m2rt+Y4YpxNPwgglAOUl4sJ8Fn0fRVxeE8427DpBZMQ4iLeavUOA/f816v2I84VZchFrtUG7Nis1UKFeTO17qpEk7opzM=",
    abilityText:
      "U2FsdGVkX19zJ8eT4YfPMxVYl0KuFoeCVtDcxIG7Tbopc4d0DnAcHdTTStXBDKeDMA9KLuzOzPBPLPJR6fJBVuk2zVjv1vaQ/mtR81xCYf8=",
    gods: "U2FsdGVkX19ZbWQp3D73XYHy+RUR+LxPOQ/dWaAK/o8=",
    quote: "U2FsdGVkX18EJENFqxtF1+pRuYJK9wqsOFYJPV/TWp8sGXzxMhOwJpQw1lN/xPj2",
    vgs: "U2FsdGVkX18HXl5QjJEYUOt5IHztwhtCZSGNKQBO72DnK8sXWmcUAvfWv5eRCyRTYkKupqNlBqKtQFwEDkklGQ==",
    spl: "U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7",
    feud: "U2FsdGVkX186wvN6Rta+m2K/CkA3k2CZ6B6UOkExCA0=",
  },

  /***  ****/

  // Monday

  {
    day: "155",
    abilitySounds:
      "U2FsdGVkX1/JgXnvhld+inL4PHcJERusVL9Cwd6Tj4Namg7noAwvK70NuAyjMRABnSeNlDmeRU+xA2SX088p1eWz2/w6HDE7Yn1QQgj2ylY=",
    abilityText:
      "U2FsdGVkX19wIhzYCDWeOJx4fg9es+WhwlRZBi6a9BOxiRXrGGLAAWCN+iQQ/XHQs/vkQW2LmHa6rJs+WKA1vIDwU8WGeP0pqBtu9zxHt7Y=",
    gods: "U2FsdGVkX1+xLgVzUDkxQfETMkk8f71MMrmM6FZX/po=",
    quote: "U2FsdGVkX19nIFfwSh45PKKqw/teCZ1nl2PkmVMbnJIohtO7hfwYPwSsV1sikadg",
    vgs: "U2FsdGVkX188BcUYkdOffbVfvlhlrVqSTpqhelREEiqdZBzKiLglxQI6mlYh7ESl1JL07AWr9ZPekCTtmKI8nQ==",
    spl: "U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs",
    feud: "",
  },
  // Tuesday
  {
    day: "156",
    abilitySounds:
      "U2FsdGVkX1/t9ANUu0TUYG84vRlTsPPFylnnYIkU78TI53WPH1sMZueKpwjMEl7w5NkE34e72Gg+R6qDPXbqaSiP8KAFKTIT8ZIqxRMdtHo=",
    abilityText:
      "U2FsdGVkX1/hBgYW12KeGuSWB+GmL5k6QeNCDO0byGainL7bHVHCkrQ7w0DMYx6rm+yMZBX85tBkIkthXFGPcWpkJH3OmXEXvlxmDT4azaA=",
    gods: "U2FsdGVkX18cwCFch4HA11ACvL6+Mk6UDsXh/Nu6Nu4=",
    quote: "U2FsdGVkX18RM/rzoaL4Y/gN3/wniAX8D4ALXGUe4tHBpsTKmKYW3JScAIhDkxZH",
    vgs: "U2FsdGVkX18qyvl/Q72KXYLpDCKrj0+iZnGtpDqJ/6P7YrzdzyPGv1/c0HcNg0msFNzlHSv0nkxtvJ7n0Na/EA==",
    spl: "U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR",
    feud: "",
  },
  // Wednesday
  {
    day: "157",
    abilitySounds:
      "U2FsdGVkX1+QAvUbI7ADO337VuY7o3sUkK9Q3XuUYr9HOfAH4yIcQdWf0kssfb/qJEjMBzMar3gvyCQoD6iZ1Ds4sU2Vfxs+LQOXYPHc7hY=",
    abilityText:
      "U2FsdGVkX19OfrqyZqJlG3O9HutCR3+GxEAFeGUpAZdTZ+lTKMZH3nWDezsTUoB/Qwr7awHH1AgdaTq7aLCGYi8ivTeOlYvba5+OjthSWgM=",
    gods: "U2FsdGVkX19CChkloXzeCG/4Bj68QIdnfAgHOY/iGm4=",
    quote: "U2FsdGVkX191VeosyAtEqwMdn1I7um/oicVl7xvFTc/IsFDdelA0Fhiw3Kuj+gOA",
    vgs: "U2FsdGVkX18hircG+GHV4ZlVIHK6cxPBHOIdY4uNY1/t+z7mZbrfexr91P6KRozlN5xRLRhnQVBzOjLVTQs6Qw==",
    spl: "U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb",
    feud: "",
  },
  // Thursday
  {
    day: "158",
    abilitySounds:
      "U2FsdGVkX1/8StJZL4UVgOLVN012hQAVyogNQ8gHEp4k6WFEZsSRUtmOR02qVpmD1+o9TERmCKDfNQ8ExgoF1RajFBvCOvO2lGq7vJVnWH0=",
    abilityText:
      "U2FsdGVkX18U24DcHAK93HWGk2toDypJtqHcqChr1mqfr3lu9ifkdyrOAihK3BI/iBhfPkjOIg458a10We+BCToAcEBrPJWGqx4D0MX2KXw=",
    gods: "U2FsdGVkX19hP0gwQ8mTsr/BHdHf8G6TLoEs2VwCVtk=",
    quote: "U2FsdGVkX19FBCyh8obcrKAgDOxTulFlAxnxDjy5BCB7jbrprMJjckixTq/3P3oF",
    vgs: "U2FsdGVkX19OiuA8vMfxvkQHx5gZEbsIEIHt6PUIO45ywc0N4fOCzIOpICGihH1Lg9rLvTVpz3CBeZQSHRYZTA==",
    spl: "U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY",
    feud: "",
  },
  // Friday
  {
    day: "159",
    abilitySounds:
      "U2FsdGVkX1+6NQCtw7DPo87h3Fhrp2WoPARJTQM/TOZwzbV1pmCuGoIoul3Ew6fv4rvHw4TXiHvqzbbwYA/Bbe5z5RQPCt7lJWb8yKhlnyKs+L3aZrgaQYNYNLkas6H/",
    abilityText:
      "U2FsdGVkX19U/G+wTurX6cUP8sPiqvBoZzHET5alR5lE+aCaIZhmbFOPJ8yse731xgfVovV0CQIy1z4dTgDvmn/sRRPLo1sHAiL3B7DHXro=",
    gods: "U2FsdGVkX18CytAUFaitEcpuuWAfmEui3j4AbYKNJL4=",
    quote: "U2FsdGVkX18ZhIzliJUOAfsOjsrVS1lG2jveMZYw3pyX607/dwRmOVS0WUY/sB6C",
    vgs: "U2FsdGVkX18F+AgJqk31tMKQJtPCTEAjLXFjsmYd011BOu98TQAUUsFaSBgyhHIyNZCVSYd8lunDonni5C/Zkw==",
    spl: "U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X",
    feud: "",
  },
  // Saturday
  {
    day: "160",
    abilitySounds:
      "U2FsdGVkX18RzJ0uQ03YBAdkHm5VYQDyAY7/2QllIxJ6bb0yWoCSwj3L2e9IPCCclfT6lFYlWTPZtci0G46ArEHD9EcAt+V/I4eT+fOJ13E=",
    abilityText:
      "U2FsdGVkX19YAQFzQMEKtA0lEVbzecxhX16htsyJ1ieFNR0ppDBZylKLumpPJw0F3bplarBBBN2q78kz/rrCE+WQFfecs9F2yT/RJdmWp2k=",
    gods: "U2FsdGVkX1/QmQ/EnIIENQHnzh2fhv4uYixAHk3TJfY=",
    quote: "U2FsdGVkX18fBzfpZgd5N33PaNdmx3LKz4GPHAKncPXxYmGd86JW3SAYozrybgcv",
    vgs: "U2FsdGVkX1/RN4UeOen4w1KBY/z7HWZXisRm+4jzmkM6yyJj8UmR1dFpYqWMhOUgR93I4FcC7KxhTwH+564WIQ==",
    spl: "U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5",
    feud: "U2FsdGVkX18hcwnA2XYQo6jZRGZgSQIBwMBE3Xi01I8=",
  },
  // Sunday
  {
    day: "161",
    abilitySounds:
      "U2FsdGVkX1/jyrk8VTEzqtf9G1Zq9bYGXO1eApHKi0/zyazSDq+NHSMktVgFdeCv/liR/e3aKkgiF0ZSdHeSnNPwSz3uHZ14Mztjnw9lG6k=",
    abilityText:
      "U2FsdGVkX19J/t2BX0vzOHdI2Q1znF0WrUDHVrdDRgRQnQlSmcdpxZ1MIRgWBINMcODkCowzjUcoie36kIl/B17oWXeAkffYKkHQcUN4iXk=",
    gods: "U2FsdGVkX1/oY5273udhBDLFoxrcbmWTKjKSPCMDAwk=",
    quote: "U2FsdGVkX1/P3SLGliOrynKjCEbTfiepIN8DeXrIzzTCR+TjsU3Lo4MkMkon/pkN",
    vgs: "U2FsdGVkX1+OY2k52dL/+TWh44tAp2wIuTUnXnxeY2nUgQJOjTxKcXMP08Lz1rwy3T6S3/39sZwCh06zWS8xkg==",
    spl: "U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=",
    feud: "U2FsdGVkX18Szz4+LCcovcaDm1s5qIPdOa9vE9XbnZE=",
  },

  /***  ****/

  // Monday

  {
    day: "162",
    abilitySounds:
      "U2FsdGVkX1/YyIUcMSHmdpYRQF64oP5KwJPSPcBAtfNUrn679lizLyOHiemDSR0i3HUKbJc+QOG0g8lW5iLbbg5sHVgnxvP1vWkB803bkok=",
    abilityText:
      "U2FsdGVkX19QplzQKugv0x4AibhDjtdnXzkiw0vHPM+X4J5KferjedYE0g6euQL8pnWO6SLgCadBaPn8AwvETMR3G/JVItd0r+TiYDMTizM=",
    gods: "U2FsdGVkX19g2HD559weRFa7LvM1Y9fLilwW4J3pDxA=",
    quote: "U2FsdGVkX19kPlcoShPWalVxHvAdXZRBthli6VAB+iGUzyPxt7hZU2NIfbBeAzvT",
    vgs: "U2FsdGVkX18w9STtZEvEE+OinnUhsEFgYGcDJ2YucePmAPYbk2yLALUycM+JoOWAA+Q68mXl1PGo5bTs4Jqx6A==",
    spl: "U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=",
    feud: "",
  },
  // Tuesday
  {
    day: "163",
    abilitySounds:
      "U2FsdGVkX1/lMBs+QSA7viwgQkxwPWs6z0Z9cTZVR03K4AktG5147veso98gnGftipVjpGZInfb7QxflMLPKvpOgGm1MhJqri7WZcKWrF5g=",
    abilityText:
      "U2FsdGVkX1/U71isq+cZzyjeypFBf8CPJVuRlST1YUZLW/OFgmxXyKgCFcxShf8St8cMklGWzBpEtQEiJ6YpwsqgAG6WtIG0G7qmi0esD0o=",
    gods: "U2FsdGVkX1+GV/6bI+g+a1yii6AJPQUz7B3BrGF2m9k=",
    quote: "U2FsdGVkX1/bcoD2ajIXzX4szKlQ/q00NG4Mg5jr3RihA+b3kbsek0DU0QToauu5",
    vgs: "U2FsdGVkX1+zvn3Fz0RvO5PflAQAB72eLX+cpCn3WYsUhs4ffIHVnkmeWAttDX1g6QFpGB73xnf4n1uKilU7Yw==",
    spl: "U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo",
    feud: "",
  },
  // Wednesday
  {
    day: "164",
    abilitySounds:
      "U2FsdGVkX1/+Q3KA+zTeFjKkAbCfFSFS82bDsCM4zKKZVgecmM8lcd1jDoWUi3gq1ZHC1YzDmNnihCsgb6YJcg3kK08WLO8UypgqYcBGZVja0lFgoMHhn6oJg7scyO64",
    abilityText:
      "U2FsdGVkX1/BESA03bDUj9eeUg1U5AJ461G4ap/zcwBrfrNNMIKN+yHGGu/ibqHJTEg+2rxNAI2Fy5xHpQBTE57zldqY6tykA9XZO0iaLufWiHrMADvWNJVSb+CDbO+Z",
    gods: "U2FsdGVkX18o6y85PiJ6LVZH3t7KbdEzBjBbOfeqRgc=",
    quote: "U2FsdGVkX18Pf4VCuUzRhfYZ3fwC16drKTho8jlOLADJMN4VqH+dk1FFYX8K4kUw",
    vgs: "U2FsdGVkX19uxPxhFBaEn+xbimqjre5n93hQaa8qksGFW3uTR1LEytTOJYjDTQtz+4TrcofVR/ZpCxpTeXmkDA==",
    spl: "U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB",
    feud: "",
  },
  // Thursday
  {
    day: "165",
    abilitySounds:
      "U2FsdGVkX1+oVT3NUXC4/1LofVDfeIFZabnqJvbZPQnmvXb1CR7rcha9rchUBvuWa7ZZOibW5GmxGvZO0ZZwK5QlsOdFMpwcmN7K1pGoUHI=",
    abilityText:
      "U2FsdGVkX18WiV4WveqZ7IkEINFqEVPIxm9xqgbwpSQKUkW8JqQWuQA9DCqnLasRozmA/val8Jw+7DboVJK2uVt+hv4P+8qYZbsE2thNnRS+AxELiaSPAqZyyYZ8bkcg",
    gods: "U2FsdGVkX18HG4KJfmGwPtNhVBlSm7NkTOCbwqDXBU4=",
    quote: "U2FsdGVkX1+lQsB4VseoML4guHQKEE4lvuhNl4HP2Xft2zKAGx8/0xrwTKhthhSU",
    vgs: "U2FsdGVkX1/v4oEsFWkLKA1rJWgg4pPFHNn7pc1moBG3/SYjl/ABJwFlIyT7PI/0",
    spl: "U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm",
    feud: "",
  },
  // Friday
  {
    day: "166",
    abilitySounds:
      "U2FsdGVkX1/gyCyxeClyHaoGsuqtJ7H77Hq+jpyeqmvF9tEF+zMVltqtVnzU1E1H/HSsZUK3NImf/0n+61LMeWxz29qwK4Y2pctuFzO6QxA=",
    abilityText:
      "U2FsdGVkX1+ylauQpfj3sJ0KYNMtLjmNGzNucxlUmqcc6vJXt+DtXXtNmPZyve1ZwKreksrs/FdHIK62k2o3HnmNv7gCFZCfdaHtVAW/KsU=",
    gods: "U2FsdGVkX19OvJouQe8vUF4ueLdhzfq1yNXdi/2c8J0=",
    quote: "U2FsdGVkX1+S/ZhMWfUGSJmVP6C0A/BNjuCB3WlzN7RHDkSZqofftooJ4Jm0taVy",
    vgs: "U2FsdGVkX1/MdAOieruP3wF2KrncqCbf6AKqH9PIua23JikI/KlMTsr8DT8DQbFi",
    spl: "U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL",
    feud: "",
  },
  // Saturday
  {
    day: "167",
    abilitySounds:
      "U2FsdGVkX183ZbBR1fjZNTshmwRwYiWnpa6T+QOgqYWDZglDU06OUzfPEpPdJY22vEYOS0RkFjqs6+z1ONCgo468zbRaPgPiGxE94o0Fwjk=",
    abilityText:
      "U2FsdGVkX1/WXumjDByM9WDmsGbZKJHUGkELgh9AlB+nlYiR6o7EIOMLT5oP9IV6afIVUYYz2Xn+uhyLtBVuPeZUIeeVJ7+zoMZumta9Qu0=",
    gods: "U2FsdGVkX18IrmkrEwInfLjbHDb8vOzBl/OAeScAgJQ=",
    quote: "U2FsdGVkX19vDBU4MAf+iULnY6yGdGg/J8WxqOfWIHbs707KIvTMFCf+Sw8kmjG3",
    vgs: "U2FsdGVkX18J2lVOX/LS1kmyRVUCg9cvb3nNSzK4fM2iRUPLmUFtS17dqHR4Tk5vnqI+/l77ljPheP8ogNlctw==",
    spl: "U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE",
    feud: "U2FsdGVkX19+cLCrNpjOJnc8O/Szn4LEpVi3Ibri1BY=",
  },
  // Sunday
  {
    day: "168",
    abilitySounds:
      "U2FsdGVkX1/jGlYy+hSV2igjs7aaUiAEv56AqrjbOvPSRq4TksvVrGL8sOXiyJX0LhVUd0AUxUK0TaVpi3WYhlVU1XiVPvvHeE6GGhI2Pt8=",
    abilityText:
      "U2FsdGVkX1/6cC2dftNDxE0L0NFmvq+TpSfQ9sAqqvvofxLzq8JwGNbaU8AJWYlU5M4xm3moWWdb5qgn74l65yg+yJ28Gu/0XcecyDQmDpnI6p8RoxqC7YVZQ3zezqMg",
    gods: "U2FsdGVkX1+ZgZCRxEeQM2FqkoSJI7eAAg2hCvJtY/A=",
    quote: "U2FsdGVkX1/ANeAnd/9yI8EClkZtcJ5PmFOuLgbkDNi1dFlcXnehk60aKRCg+M9q",
    vgs: "U2FsdGVkX19tISerfm5j6scPclp5Z9c2Qm+HX/wFyOuj6cw2P78ItA0exV51njZv59UqFvhxkYAAJpgTy46KPQ==",
    spl: "U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS",
    feud: "U2FsdGVkX18gYZCF8SDBFcTnFl0YLrVS/6miu80iMr8=",
  },

  /***  ****/

  // Monday

  {
    day: "169",
    abilitySounds:
      "U2FsdGVkX1/YxkUbhiVah9FFd7tl3qt+gAa27QL5Dep6EdbexqV/ZyeI60rSNO6XibA1RLBxsZFD5N0jAr6uhC25AKyD7K5wFMLlrOWlt30=",
    abilityText:
      "U2FsdGVkX19Y8xvqT2dD4YvCkgDJKI6ZTrmMyKArzsncs+lUw8KOCzUjKpxpA1dhTtCdfyu29dxMWG8m8wC9i3lcua5A3IZPeyMsDs6MwK8=",
    gods: "U2FsdGVkX1+zW86qiF0rf87M+ecZJur9n+aL4dt/eZc=",
    quote: "U2FsdGVkX1/mQrHa0lfOvLZcFiYknaEcXdPG1DkFX7qKLtUi1d2Sd2TYxX8gGBEa",
    vgs: "U2FsdGVkX1/vlK/eCReIfQ8/jRhcv56fgg1cK+rIe2sqxAmP3pYIk/iUWCBX2OzaVCaafXd0cI8GTcQUloV8tA==",
    spl: "U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT",
    feud: "",
  },
  // Tuesday
  {
    day: "170",
    abilitySounds:
      "U2FsdGVkX1+6V/N/ew9NV2oIVMFbmOcJc99nwI4OJlFds1XGgXS4VWWmwwZzD3302FiEjAtOAo4Gw7nIishTUYedTIU/Blz4bkv2gN5LU9g=",
    abilityText:
      "U2FsdGVkX19bZwCmpZTANvQ5dJKhVRbzycgFUgxRJt1/FvRn+K+XtxbA7Pznmgzu5RPm8BGF7AOlSbaQgFQqZhDysUFv2orSyf52uc3xse4=",
    gods: "U2FsdGVkX18TDAYMuA0UQn1d3o8d1zqGsQT7xBHYqZQ=",
    quote: "U2FsdGVkX1+u9Izpu2vnvk9vkYw0GmEm2cFp8yHgs5wUB5TY9eAegJvcBVtV9NiK",
    vgs: "U2FsdGVkX19Vpx3m3z/UzGZLXW+8Dvcv4eaXYyK7lIJXHFaqdyjUsUtff36kCZeUy43MJ8ABB3GXWCVtAGUVvw==",
    spl: "U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ",
    feud: "",
  },
  // Wednesday
  {
    day: "171",
    abilitySounds:
      "U2FsdGVkX1+Dxbvk7YZMmUGf7rkMflw10HgV5okDuO8+vi8/06GbR9kKgrCCbL+m0VXHqbk7dCmPicjLcVHXyXC4LpgzfD7xakCuQ59+tHU=",
    abilityText:
      "U2FsdGVkX1/uGHYLvRs76epcLpmFL4yKx+eunyOUJtFrW8kk3mbyY/tD64mqpHguad+oExHyBbCJzljkKQB13L6TRhLmS5HHwBI4ZDHOfxU=",
    gods: "U2FsdGVkX1/mSR7F3vSH7S6hJuYVmQn3sQ078dl+f2E=",
    quote: "U2FsdGVkX1/Gm6/6vcR1CzF4snqXQnGik1WJ+bzoPeyXDtPnW8Tizv+qM33bZNxw",
    vgs: "U2FsdGVkX1+JARBRl4rhHIF1sYxDG2M09wvTan1Z6A0u7YVN39OpJbT+06GvWdPK44M63EK0KMxncEscFtFFVw==",
    spl: "U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih",
    feud: "",
  },
  // Thursday
  {
    day: "172",
    abilitySounds:
      "U2FsdGVkX1/HaLaim3fujQkSZhVc9MBKUVlHpKcFgjQqlQRLdgQQaGQ5sV9F+Ec7JnOtw+Ey4boGz5eD7hNdjR26C9Oq5dmJCK+3B8niyFY=",
    abilityText:
      "U2FsdGVkX19Z+vB98ZPpDeE5enRPVIRkhF72987LUWxjL924MfxOAhFiYXVdU3PkT/iXzjdVGd0z4eJ+YVj0oIfYSlTta1UYWSvT8O//fvQ=",
    gods: "U2FsdGVkX18uPD7Gh8msntckXNLZAjEBCfzfSetC1SQ=",
    quote: "U2FsdGVkX185cr0itsr2saWJ+Cf+wdXW4eZNGx6wtmblrb1j4mMzA47Dpi03FHix",
    vgs: "U2FsdGVkX1/NfQr5XMMgwnQ6cJPR1CJh8DsIsvHhIWyyYvyBTEFblqS8Wlq9Irnv/EhzubDsWnGgV2Br/t/03A==",
    spl: "U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B",
    feud: "",
  },
  // Friday
  {
    day: "173",
    abilitySounds:
      "U2FsdGVkX1+UVMwI9bDEQW1lCfTnnU0Yva7+yIkozmWfsSgAc52YlLBSXdETDM8obDiL05VkFtagDN4sxduqLdFQJZqsf0WKMzK9ZMH6MQw=",
    abilityText:
      "U2FsdGVkX1/ey1LrtXijQ5HHsU70hi/sBzWruKmdQu6zPsao64N+xgdaiohgQddGziarPHNwMD1z0qLY7zrFktjkQ1jlYufbghpQKABQSsA=",
    gods: "U2FsdGVkX19DWIJ5BtQQVJZ3OKm/wbYxPq4+JbjJYfI=",
    quote: "U2FsdGVkX18V5z4gKM3Xn+/zULgXvJDd0+COsa5kmFGfwkp6l7whZ4XzKwlhaJ9U",
    vgs: "U2FsdGVkX195YtUv6MeOKqwgS1nNPfffO6uJMEexLoZOHEfU17TnYU35e0bddzveL15p5jFlFWCLyvaf3SD+Dg==",
    spl: "U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR",
    feud: "",
  },
  // Saturday
  {
    day: "174",
    abilitySounds:
      "U2FsdGVkX180UGkUXLJHcpNhzfV7j8vJz9x2GzZvn/JVIlkM2enEQ0IZTwGa3sGI1O4fss2mjxtyyx4bh2oCEk8xn3hrKK//aUUhJIB6bBw=",
    abilityText:
      "U2FsdGVkX1+t/WOWdcO7gG0N9f4jjPUcPW30t6QE6xbEaQzBpgXVgZcy5q8nzv/sBMGEsacArRI8YGkk0uq+gquzpjFIpV+ppM2PyMvI/NM=",
    gods: "U2FsdGVkX18V6/tzBDKlqOXO2n518MAZaMPnKHu+JCE=",
    quote: "U2FsdGVkX1+ISWrSebctYW6E+PvQ4dZkj6AvNAKrQadnlcSYEzrlCkQi3rzi0g5c",
    vgs: "U2FsdGVkX19nwMCUCntrYPgJQnt8ly0TcXk5v9UJMiHIQzpQB+uEHk/HR2WPfyeJl70PGAYCvRLobsnU3RkGJQ==",
    spl: "U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y",
    feud: "U2FsdGVkX194ado+hAm4hhpYISHO3C9ZbHLCUcwJBco=",
  },
  // Sunday
  {
    day: "175",
    abilitySounds:
      "U2FsdGVkX19KNVP4g8f47/qqDqUr/kNkJ4m2Oz0xBWkkSsQpYRP25Czcjmkt73h7Ck6W2t6TGSDM+KrqP+xIKtfaOFt7NqyNDfYULaMBBi4=",
    abilityText:
      "U2FsdGVkX1+EuF20l8ZXLhBb6kcqlR6mQmrfL3MmvvoI9IWkjF/Yynqm7ZK0RTDjK00mvXqECsS782WRujBH06jCoyyDfpa9jEkVaWLHyW0=",
    gods: "U2FsdGVkX18SuDrwk2RxfCRxvZS3j3bPdniruuGi4Tg=",
    quote: "U2FsdGVkX1/NZskqchN953VXu59jLQBngPmva9+GFXIeSonWe7hdCFq6ZHwI0265",
    vgs: "U2FsdGVkX1/MjXeKV+ZJRAfWMaULuyidcPGKhQJ1gVD7Vg8XdV0gf6dlDlvNGF1AAT2HK64E8N2QDxN/WPShBg==",
    spl: "U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s",
    feud: "U2FsdGVkX1/0oTdnp6lN2gUIm73innqOhzLWClIGeK8=",
  },

  /***  ****/

  // Monday

  {
    day: "176",
    abilitySounds:
      "U2FsdGVkX1/VqGnlKxk9mhgOrDU4cRO2l+nrUQyXIZskvdncunP1aTk5OZ7mwuekfalkxtleEkQR2MdOFcK3OHQlHQHwLHHnohrDlOsNbS4=",
    abilityText:
      "U2FsdGVkX1+833Y2+C4Q9KgD55FmqXWPZLSJWITug9lMmyYcdng+4nKRo5l942XSeGPwl8O8Jn2RN07rzfxpKsqXJLbOBMUlqgbF8FTeZ8l5GaZfyNxOvjhINUrSh4jv",
    gods: "U2FsdGVkX18obWpYPat9v/uKFm8cb9n1ZvpX3ctIOZE=",
    quote: "U2FsdGVkX1+FkSIR3mjMNp4rVLAoRUTTA9kmAUhw8IFCSZhhd8N0txwsz2HG4Tvk",
    vgs: "U2FsdGVkX19Fr7QeBgNe8hNESfklVogPFzNpsOtvnhf0NSD5MAfsn3Kn+i/5Z3LdLvDotsnKVfDeNXL9szcyHw==",
    spl: "U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L",
    feud: "",
  },
  // Tuesday
  {
    day: "177",
    abilitySounds:
      "U2FsdGVkX19Cm+H+/RTvBhl5Jb4aN5mnHSC6V9ubA2/73Rb2UcBP15wbqTLZB7rMbWiWuG/WpMshUBXkTyQYs4xZqspUfo5sVHKbA45AXRjQdJLXUq2Gs824TKaz7XOk",
    abilityText:
      "U2FsdGVkX184x80bpihGESy0JEX8/NZz42MtoB0rY5SMUJM6gvdAC7Lp4Fb1W2lQUdwy1KtG+2SnEniYFdKd/3W/wq/PL4csHtMn/9egOLQ=",
    gods: "U2FsdGVkX1/vup5mzU2zPhi0lx7pojd90bCYSTy8nPg=",
    quote: "U2FsdGVkX1+H7hdwpvUHbKAha98oBmnR9M5Y7k36gzLmJPLrguCnVZQfpfiwt+Xo",
    vgs: "U2FsdGVkX19fU0APE+DoH+zJstxybx9JFVwOeKQKJTGcKuGT8lv0VeENMjjg8IGSCHPFTMQqERHYYZ6febRzXA==",
    spl: "U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG",
    feud: "",
  },
  // Wednesday
  {
    day: "178",
    abilitySounds:
      "U2FsdGVkX19JkjpGdqIT+Ri9RsaaLuB1CbH4eEdp8/iCiW9lr3Gb3XL3uYJigRc59mcdgRm92ZdlB7iJ9SMkx5eg6/r9ue+97vdOqikwBdpUZKQS9e5tOcCt8FwyEPLI",
    abilityText:
      "U2FsdGVkX1/+70q4pE869aibgsnesmZsPiuEBiy9kdRW/WcUxvTJgTsVKTgdhkVUiZ7itI3lB8Kjc/mXDvzpHvEFGrQacsTNyxUkGkiwVQw=",
    gods: "U2FsdGVkX19uoimzq4fP9Aq0ZiEx0ZncqEAxJmmlebc=",
    quote: "U2FsdGVkX18Mqqs8Ly9ufslBcSqYFrOgJRcpq7ULEYa1HE61A2ujLdQx4Ey9E05M",
    vgs: "U2FsdGVkX19NDOXcAiMKkeOcZeAO0N3x0zSj3ErVVWohL0k58FUlfPMEhpiHbBtNzHx",
    spl: "U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce",
    feud: "",
  },
  // Thursday
  {
    day: "179",
    abilitySounds:
      "U2FsdGVkX1+6FOwVKKh8vhqKpYq1EFl2SVxs3f9zdiTvJo57tKZ2Aa0PZUjl76DnVpMic3RqiwlSHCgRaiUeUQno0IcGqs699wqn0Et5YWc=",
    abilityText:
      "U2FsdGVkX1/tsODI02ksIKX89NiFukgNsKFmOZK7rznOrJFhFts7/LffGAdQWrlpMKbeohMn6bLIGtH5SCY/bVGK4vH09HxG71r7lt9ljmYLM4pX7mOGf+5vqHEB6HZ1",
    gods: "U2FsdGVkX18032i4Qt/P+I1qSKXbN+q+7dHdnugiXM4=",
    quote: "U2FsdGVkX19e4xO9jzVDtptAGKHp/KkiKMk7DckJI44cdTSb7fASQvHxK/+00gUa",
    vgs: "U2FsdGVkX1/P52yQP47kS6IzVtYeswk/+CLr1S85vCOyKxYTRWh+twADDqUnEceIVU/E6gMROqt9/7ZwNBc6Vw==",
    spl: "U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx",
    feud: "",
  },
  // Friday
  {
    day: "180",
    abilitySounds:
      "U2FsdGVkX18MRlGI5ng+hpTzEwA7d8vfIqLuKspjmdFuNEkAIa+fDsKMkgajBJ+T4m6GQkCUa665qQXC0kh6xfykhlFC0opx543CfGL4Bps=",
    abilityText:
      "U2FsdGVkX18DfTCLUTAvc4zqfFdndwBYQ7CSKoXXhoPTAbn/zzOutjg1lDialr4Ir05CnWMx8o/hZfGNb08ahe+FcM4Bg67H/RC9NVMOMlA=",
    gods: "U2FsdGVkX19Dy/OVtzm0EB1h6YwLok0nGMNMfRrY3DI=",
    quote: "U2FsdGVkX19qKYTpt7W8tMcaXHJFzmdrJhnH3fgv3DJ2BRaGj39HTcsDUFzSwiTN",
    vgs: "U2FsdGVkX1/SDo+PFQkJ704DEsd2T/EYfdZ7K7lzN8veb1/HvsvE7cENS0yI1yTmWtUE61PqonI+jNj+gHM7Gw==",
    spl: "U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh",
    feud: "",
  },
  // Saturday
  {
    day: "181",
    abilitySounds:
      "U2FsdGVkX1/X35AVOdW5KaZzDDrCQI3Wf833HBA0cH441D+cJmRtxxhiTvwTfwO7Zf+90DoNT7456/SpkE6xfXPktKvutgF4LxJeKe09Eh4=",
    abilityText:
      "U2FsdGVkX19d+DDjb6ky/mgLbhaC8bR20x4unBrOOByTVLQPyVwJuJUvAxigOt62m+SPbqF4i34p+si9lkpT+HAou3nbroOLG/VS/2dGvus=",
    gods: "U2FsdGVkX19H0vha/BMsbMebvJJqEoTfCOitb9GK5Ww=",
    quote: "U2FsdGVkX1+EOSEOZkqOJ83+47C1JStfeNL0CMVqT9bIrzpN6oGrA9UKRC8faYdY",
    vgs: "U2FsdGVkX1+hocxq4gyBIbCE5Uumu3aOYmIe7/RqdlcP/YI6izgopEJ6H+41b4RP1pNmJTNa9F3xSaHhWlXG0A==",
    spl: "U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW",
    feud: "U2FsdGVkX18N6MxjcDd2CHPHx+cHjoXm5u2VhBKjMjQ=",
  },
  // Sunday
  {
    day: "182",
    abilitySounds:
      "U2FsdGVkX1/SFQDT8jMl/7FLBXE1MPAbepOMRPYOozoOcQbDnergRehFcLC5MbG5xhdQTnQeky0ERDMq1AM5ufiRQtSNVgK/oIa4PKpOUC0=",
    abilityText:
      "U2FsdGVkX18/xmRbNzbyNDEqBVzmX0njLuW9PyCqOW+K++ZbsQJD91b5gV8OJwETByptdbildqktqHE8A+eCLoeHivVLsB/g2R3sL/xi1Jg=",
    gods: "U2FsdGVkX18DFCK6kLk+6QsKgQo+SA/ysLqQLh1UHWw=",
    quote: "U2FsdGVkX19Yjvcvw5EI54l1FToXtGoSNcB+hIUrBdzrt+lZWcq9WZ/15R93MaoE",
    vgs: "U2FsdGVkX1/ILH/GGf+L5YzAk8gYnkCJRrvjbCttf3CdfjPs2ctR7hxZQA8poCjQjU1rQsLXcVAE/NETlICWdg==",
    spl: "U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND",
    feud: "U2FsdGVkX1/qeD5c03Ca+euCATiJSl4bSAixEIbHJaE=",
  },

  /***  ****/

  // Monday

  {
    day: "183",
    abilitySounds:
      "U2FsdGVkX1/hYn1JlzYN5AGE0hkO35sEr3p+aayRe596GUyy5E5H3QgKzfresqEJmzHmWZa0BzCnauK+Da1Bj+t9L7G30v7nhDMXOubvBjk=",
    abilityText:
      "U2FsdGVkX18UgGEe8VivhY/XmwIH8kZE0kM7BSfmP4suU4u9jS5LaVBm4zQKg7gTS42prakf0bZc8v7gDpFBGfc6HmfA/jXI5bavdH80NrCXg66erQYj6gN5QMxylSi4",
    gods: "U2FsdGVkX1849nKL29+kEP19B44euA/N0ezqGgVSGEc=",
    quote: "U2FsdGVkX18WAXBkvQYKCajmuz/JG4WlQhqdGvKqQ/i3IpHzlS+ULMWY3pydNMXS",
    vgs: "U2FsdGVkX1+SRwju6jqy13f5Xw5ORd/0UMimntzogN840EPoJ1tfG0vUPHBCdjXB6j0i+5uCbXJ/cBNekHzS4g==",
    spl: "U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU",
    feud: "",
  },
  // Tuesday
  {
    day: "184",
    abilitySounds:
      "U2FsdGVkX1+E9nXrxPUPul4n/IEUqu71B4WaK+D1wrLmZMmMg3cVE/zSbSU+wnctJuGKtHQnW23htm4c/EQRcvQRt1BdpyO94YQ+PxM5SBjMbcmsBmjt/sNcFqRQP9c5",
    abilityText:
      "U2FsdGVkX1/ttyBwNHzKrdkfFEcGD/1c/GOCtux55RcGN8xIQrgxy7aNjZODGZhwJukiXyixDzictn/Zc1TXuOmUmBFls47ot1qUXpeqfAs=",
    gods: "U2FsdGVkX1/NSKlcz8F86zSWSB+DJel9p8J2JhesKJE=",
    quote: "U2FsdGVkX1/JoTZY1puuqqHmc65SQinigOiFE86kFj82BOEN1LfUhJUoIA2AM6pE",
    vgs: "U2FsdGVkX19a3nllTg+lJNG/6QtRFfwLqgEvqHk0HvMEmzdhypvfDr+dclMuwYb30YUF/arWUXJMk/2hETIhwQ==",
    spl: "U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC",
    feud: "",
  },
  // Wednesday
  {
    day: "185",
    abilitySounds:
      "U2FsdGVkX1/N1p6GrRiiC1oj3Sr3v6+Sm3Ix57TwowPJrY95v3YeC15ufoVwAqdH5PsdcOr2dOHPoJdQiAWN9GbO4aDtQ1cMNb1nD6CCJt0=",
    abilityText:
      "U2FsdGVkX1/gs3tz8Qk7WYKpN9A0JyXDnu6hI0d7llYVseVLKmnFAzYQbYr3Wqzz2ag/Sg9iGtyLDkOp1TOc3qujaF/Vfd4NM2SDUXTLHB8=",
    gods: "U2FsdGVkX1/6U30wBCeZW8c0W5DpGp8z5phbTHumGv0=",
    quote: "U2FsdGVkX1/UvtYwdBiiP4BlRus99bRnFZ11C+7ZyJv0HE4/xLsgGi00RhcYIVfT",
    vgs: "U2FsdGVkX19PoY2xU87o6R7A7fFLNSjIITjNXi3EM6vW+9umJmptzYbdDXFflZdMFcPCfWWkLwBcgySXNtYJew==",
    spl: "U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7",
    feud: "",
  },
  // Thursday
  {
    day: "186",
    abilitySounds:
      "U2FsdGVkX1/pKawCGtgjenF3B4H11PlIfjvbHSvuBHjp+c8PwYx1U9NlTrRGE5YHIgYCFYffCAf8AaizPud5HhHBYFWJ8cpOdSxyDeS4SfU=",
    abilityText:
      "U2FsdGVkX19jU7JeYY+Hy9bmsxgfdzoOHxBP9PWJhp1de4iRXJ/sxdJFPW4vT0OzxrA0b8OMezkU2LaIAhGZiA==",
    gods: "U2FsdGVkX1+3x/PIYUQmDzJWG73+DCfrVktUOjjynjI=",
    quote: "U2FsdGVkX18rbpQFWg7mbyX38lBWB5ypfoanlQEo6mdPJakF1bPOaZaIhkhiB4yw",
    vgs: "U2FsdGVkX18JKi92D1V7SlF5krjmBFmdPhjY/yWhAK4ACJL2fM7ViFAiCoiAjkRc54589e6kBrWCTamZ31tDhg==",
    spl: "U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs",
    feud: "",
  },
  // Friday
  {
    day: "187",
    abilitySounds:
      "U2FsdGVkX18p/ff2UqT5V6RBzNFKPphvp4HTH7KDPXUMZDmPuZprKWNizVpF1poPgN5TB3MAfh/53+0Tw97gBWUYb3o7KXOOFF0ahIKkX2g=",
    abilityText:
      "U2FsdGVkX1+GqKW/agMB+q+X7DaNP8jXajwjUb7H51XmQyeJ5JUOT17akjJCELNsmfgSCNCQmgwI5o964MMvBqMrS9znfrICjFB4mZoQVPw=",
    gods: "U2FsdGVkX19FsYKW2kZnZKAGfTbSIcGwTS62TGq6AlI=",
    quote: "U2FsdGVkX181IgBmbfPnzijmJs4kX0XrvIs29i5awoMNwDQsBRbslIAgZaMigOu4",
    vgs: "U2FsdGVkX19YYPdXAoN6jb9wT4Crz9SvwxrA3X+BDv67OqSm+h5wz0LstY0RVhvSXpzjR8NOhtwuZw3qpD1sig==",
    spl: "U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR",
    feud: "",
  },
  // Saturday
  {
    day: "188",
    abilitySounds:
      "U2FsdGVkX1+wmZvJ+TadQbvqU/HsNwCWVEWeezYds38cSPfBUxnYUoNDZORxID74Y/tPzFT7iWQGAkPWE+LZpdhap5KYbAJRHPafSCRAnVM=",
    abilityText:
      "U2FsdGVkX1+1lMUW3b8XCCRhIWAbVx62tDXa9s6Az0lbM2Goem2GRjrB7p4s/Kl06n9U/krL+jr/i1n53PvGQZPWQVXRFhoysGpFDoi0UuA=",
    gods: "U2FsdGVkX1/AzQ1p2z4Xr4ZAzZEWkjZafi4hJJigCfU=",
    quote: "U2FsdGVkX18A8jMa3kK3lnit/MF1NGKg9pVnUpfDbK6rlp8s1zllke+IC2iEleGy",
    vgs: "U2FsdGVkX1+mhkv6jfpirtoJmT+aH+RhRtE0+ZF4bTBgrv0Mapa1/Sqi4LQR3tOS",
    spl: "U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb",
    feud: "U2FsdGVkX1+mjYAbmxeMawrJrW/7CeGWd4dgjB5F/uM=",
  },
  // Sunday
  {
    day: "189",
    abilitySounds:
      "U2FsdGVkX18wbPlg56NSk4F5OEA0nDSN0BtRtgUDtILvfgzH5+cYyLzfRBEMdZhwQVZKQY8Bl4lwub5yFIYKCYYT4G/2h1efkYCRDDT52wQ=",
    abilityText:
      "U2FsdGVkX1/CEjRP0PI4MD8phXZ9IMkDv9F6kv5EifWIdmZYcoYa9bWAPlmBweG5J/IGQ6djTsUIftHURUHDzrKhJ28dNZltm5pQszN+3TM=",
    gods: "U2FsdGVkX18YZAEaVQcOPnsx2kKsqPrtE+AQs45YuFw=",
    quote: "U2FsdGVkX1+SdZ5xhp43gWdIs4PTEys6pApUe7pHhgvlSK9L8xCaWI7ABOSsi+nt",
    vgs: "U2FsdGVkX1+ActTIrFUK0K/i8ldQ+WZoyLAbKwlR2YrcGPO5IfnDX5tDXyFDkIBpdHnvdbQTLCxWqSmELNsF/w==",
    spl: "U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY",
    feud: "U2FsdGVkX18xXQZB38QzNgRL5tt1t1OQ2RlkrYn6bAk=",
  },

  /***  ****/

  // Monday

  {
    day: "190",
    abilitySounds:
      "U2FsdGVkX18CWGy1Hn1uPz6KnVyIPZ0S/E/ReTb65plgi9+KkoaP/pzCUkiobl3G4o6+M0p+gM6N2aJQWQksSkRxP1yFgL1PASTSLT9iO5sPYv+U9VdcCBePAF1tUY2u",
    abilityText:
      "U2FsdGVkX19X8iGGdPaJxEfaWV2AZt4TktEo5UTiC4MNaPTjuVIOgffG3Zu/E/YBpvgIhHkaGyS1WwkTXFb2TUgu1OCSDnRKuJmIez7SmXM=",
    gods: "U2FsdGVkX19DRggkiEgn7ZhOikfjvIC9OGmPShCLI3s=",
    quote: "U2FsdGVkX1/AVcHsgW9EXYoQOaxtDHeaRUsAjsstYxmPAsUKfcZKn2ahq65XC8KC",
    vgs: "U2FsdGVkX19Ygh4EU5SSmxWYSSEqDcVnzjBwEk231gRKSU6rMMqjoesZ8mY4WBRnA62Wqv2vcyrPEiGGy1IZtA==",
    spl: "U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X",
    feud: "",
  },
  // Tuesday
  {
    day: "191",
    abilitySounds:
      "U2FsdGVkX1/k+VQW4A7qIJ1Koa59opE7HOD1QlVB9qIeJsJo2xys5+8nlvWFOn52/dD3lT2FkzF1qe//qO6dRn3tArGfXWkXUXnyfSteqbI=",
    abilityText:
      "U2FsdGVkX1+FI3QyvVGdYrRfbpJhfvM5LtKCWKXfw4f+UwLMRxGh6UrLGnWdXqB/mJH6PgKHBZJsZ1zSXaJf/Uqkvbq6l+Fzw74Ic8Veqw0=",
    gods: "U2FsdGVkX192xM62jzAAoiYvtKhtoeop50DzuZBcdLQ=",
    quote: "U2FsdGVkX19oyGeR4+d+As/9FW9RBAJLL7gym9g2e6hZ7H1H9Sxv3Mwwk57znF9j",
    vgs: "U2FsdGVkX18P1V9GI1Bs1Qg/CR0y2g0OKl0pJvCLrQQQS1k3qvl1s5gNRp4jaVDn35UIUKgJfWFG1pjmvHoX5Q==",
    spl: "U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5",
    feud: "",
  },
  // Wednesday
  {
    day: "192",
    abilitySounds:
      "U2FsdGVkX1+ZWWYGDhvLSDpzpVP5VG7ODRVt6VC2m+P+UQLYdzml/lv1SvcZ+brRKeogdTpBkNbua4fyXuakV6h+QHgkA79upPXl4aGkkfs=",
    abilityText:
      "U2FsdGVkX1/fzEcKnCxgrtr2XB/lnMoN8LcObRQllvpWTyR39ML10WQ8qjwHpP2LXntQoARCkjJT4SeSogeeofPjao6q5u6rT8RxYDSeNwdVtPDWD21qPbnRFSb//+Ps",
    gods: "U2FsdGVkX19ovhPLI0+X3ffhsPi8LeKeddfx6UVCvjk=",
    quote: "U2FsdGVkX18GOLJmO13l6FaNwSHioPpuO381ajIxGUpULiPmBAmceEsK8ESRljgH",
    vgs: "U2FsdGVkX19dSnpO5CDjsfvUvBGjblWBBjCMwl068bbHlYa0TGPGcZ/7zGq09lxITgCg4mwjbba5towJikSKrA==",
    spl: "U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=",
    feud: "",
  },
  // Thursday
  {
    day: "193",
    abilitySounds:
      "U2FsdGVkX19AcgbzeO+tSY549AZpAcr/XL13gEROpMBfJW5pSP2ZdK0jp/2aq7FpbNAy0gkhvCpapSucTcbF2IZxioBLCBAt8Fiy0YyLK/I=",
    abilityText:
      "U2FsdGVkX1/piRFSWEHRH8qcEgnK4ETOJGFcmKSnsETKRpj+lC9qzo1WRO57AS5iPW8rPqQIiii+b/+/IAVACfQGldAdb8JpxHxeKjGaqoI=",
    gods: "U2FsdGVkX18iI+XyNfSjR8IU4ols1JGmyA2CbwmMdxw=",
    quote: "U2FsdGVkX19sx8ZEutoRSvmxpBidHgeJNfLw7mzOsgi2XmCOyQJMAPW2Nmnys5YA",
    vgs: "U2FsdGVkX1+0mBL0CfQnOZhMRaREOoKhdN0vIIV5MZ4FDhdZLkoTDQFzQ7tlvtGHM1/csm7AVq4puDx+o/+5mg==",
    spl: "U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=",
    feud: "",
  },
  // Friday
  {
    day: "194",
    abilitySounds:
      "U2FsdGVkX18bJxERq2fpWOPa8GDWwvQp1JLrsBxJ2lFbGouFPhn2qNFDZYF1akuUqFLbYfPMaHTw6mFS/xf9p+TTg2bX9WNJDdWPYnXhc/8=",
    abilityText:
      "U2FsdGVkX1+bUh2t4VnsSfpdLl31Jc5hLkGLr37L4RDeiLMfa2ppumogkHk3YK/U1bqHtobHDpnNyOwIRoI4yg==",
    gods: "U2FsdGVkX19iq9ibcf+kDZsy2iT7uAHzvKlFfJNt8uk=",
    quote: "U2FsdGVkX1/0p2kB8RZGmezOjUu3EEVA1WY/hdKZTDm3OWRauD0MhHEFQxXJDCOM",
    vgs: "U2FsdGVkX18sLJeyWCQbdjIbkJXc0t2V2I2aMZMAuugs5KQzQGLgwmbsWuEOKEqKciW2aK6f7XdnNAGOa5laRg==",
    spl: "U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo",
    feud: "",
  },
  // Saturday
  {
    day: "195",
    abilitySounds:
      "U2FsdGVkX19E5VwAc4GFBnUi5FKw9PYLttnpRV3cG5fTTsa6QOHNl3dAoZkKPqe8fR1PVBRFt2driIQ+2cTZm0hcdns2LkIS/Cw9qQ9G79bA3hd45AYDJVmuoG9RlWyw",
    abilityText:
      "U2FsdGVkX18ZmzCjLWs8fWInDT1XBQDNiKHW8aJYUH3sWnhcwYGzcZfoNHEbWscwLGiy/6NxKZJIRsqYph/0nZBCx1A3XZyt9M4eAXoNKog=",
    gods: "U2FsdGVkX1+PnRuP3tGnSehy/445qtKLOCwMXRLi06Y=",
    quote: "U2FsdGVkX189BXnpheabUUU00ST77JxZeplQrw5IHTG6Pn7eeIvQZx3P2xegEg2+",
    vgs: "U2FsdGVkX19B+yfi4E7CkWdf0+bJvPH61xKN+maMcEWiqcEqn00ZOBVhVUmhqlc2eLkeK8JpNz7uNvAtwo4Wbw==",
    spl: "U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB",
    feud: "U2FsdGVkX1+6Q5PD+DJCHrbTqiswH12B1kupOfmDVcI=",
  },
  // Sunday
  {
    day: "196",
    abilitySounds:
      "U2FsdGVkX195BhHH1XhWL6Ad5ynJ/vNxtlKYqmdpJqoGF75/ya40Jm3qLUJ9rNvTsTpzysPfbh//dygNjucW0KSr/k31fsD5qkxyPwbKrmk=",
    abilityText:
      "U2FsdGVkX18GzITF4jSfcQRA5adSav1D/rCmjLjckUanP8s1xRfcV6EQuIXfn7u9pYolQhfa9E3gBXVYefD8Si9PYRjVVGW+oOYWmb+LG0RZR3R0mq8eKDYg1yrStHBL",
    gods: "U2FsdGVkX1/YvxPGEwbyRGWYYaGblgJjIUmBKjH3oPA=",
    quote: "U2FsdGVkX19FMiNFLJcNuZLkjPp31ZeMXAlQKui6sHlnGTlBtVG/3y6d805NyhfH",
    vgs: "U2FsdGVkX1+pCxCRR047RnrXsrIPTOX2sbaNetYAtMP4VTZ3YIfF2zKkmz62T0N2ny3sHj3/6EthCsD43vjokg==",
    spl: "U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm",
    feud: "U2FsdGVkX18aLh8amRlZCfnDvHMPpvRxNdq9/8lOpTo=",
  },

  /***  ****/

  // Monday

  {
    day: "197",
    abilitySounds:
      "U2FsdGVkX1++xnyc38bRTppBLRi8ACEtp2lHiTJOm3KZ3FdBxvt2bH461zLWp0t9nEI+UynwG//Yrb04sZHbuWlAwOABWuhi4pgWtpuYFog=",
    abilityText:
      "U2FsdGVkX1+dK/N3YVQUnJitvLX+dmwUCzYoNaVL2Nm6yEXlL5mVNGOZkyG92hQOhtX4YP1dqNLBreEIcRdW/NM1XoPwhaw/VpqYdb0RKLE=",
    gods: "U2FsdGVkX18JwsrPrCLszLZU/YahGdhmQ1LK91m3iLw=",
    quote: "U2FsdGVkX18mE3hkWSVCNBTN44AxGyuuISzjBwOo2h1RCPS2WvV5PHflGJKcKPUD",
    vgs: "U2FsdGVkX1+NXLhyUF7xKi/KhDBM3vrAR6SBJx7Qbnzwxf43Jq3MBks5Y+lrTB8lcBiSppIoVjWtiRioISP2sQ==",
    spl: "U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL",
    feud: "",
  },
  // Tuesday
  {
    day: "198",
    abilitySounds:
      "U2FsdGVkX18bhP47lRn2QNpDRvxpBMGFTV3DcauNjjyEi2+0Q4n7l+uM1ljS4JvVk44RJLH/KL6KY7JcG0OISS8VHckGHHtwZwtTXHdQuGPvwoGhJs4O4whcgz+3q8yi",
    abilityText:
      "U2FsdGVkX1/CAOoZyw8dtqh/RzNJX1+FFAQ/1tMp/oHqPiCTIWi+yuF7wv9uZXfkpzXowqSz/fw7fbaIDe7J3w7TMS1q8NdckxQ4Czr8h+E=",
    gods: "U2FsdGVkX18R1miteAEyc2GVsZl/0X8CEH2znjXErTw=",
    quote: "U2FsdGVkX19qbf2gC7tTCBNhUMY2KLVZllKImj2+/0nr4r6HwKhZHctG+AkbrZtx",
    vgs: "U2FsdGVkX19Si6QvvMD8Bmb5WNSdWrTylsrk1aBxfLS/2z/ue+6sbC9zGQ7mFOL7qd51QzdfkjJuBJkvgSQtrw==",
    spl: "U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE",
    feud: "",
  },
  // Wednesday
  {
    day: "199",
    abilitySounds:
      "U2FsdGVkX1/Pv+XHyhjZvNd5vdheBdN0Aa7XKUmgbyR/7qCGrcmtOywbeQGCRGvjHSLzgTIyEyxGZq4pTZSq3VazohYcrCKqZRG6ojbTSmY=",
    abilityText:
      "U2FsdGVkX1/vPRtTnvu1jMct7FfWnMLV2vO7dHhzq0WESfipKSgZ9YiJ4MxFh5elgnFglCxoMwtDOgDVZ6v3MTO1cTYIMdoTvEv1SsgDEGY=",
    gods: "U2FsdGVkX1+35vF1Uj2OlWVqM+XZAgCHw0dQSmNbpro=",
    quote: "U2FsdGVkX18edPzQU3mvruUQVS9xPmYGhmWP0PF+QdzFMnHLm+rScfWAE4prk9Zs",
    vgs: "U2FsdGVkX1/RS/8gBZUL3hdr8d6FwwrCZf8hBJjZ9VJivms6g8X3VIjUT4MqQOC+3paLmg0EkYp2ZBgDlrCM/Q==",
    spl: "U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS",
    feud: "",
  },
  // Thursday
  {
    day: "200",
    abilitySounds:
      "U2FsdGVkX1+dbxv7oV/qGRkSh0svd2t/c3ipuefugMm9n8efabbOaL+ubhXwgaMG40DC9q7nM9uYOx5V4A8lsrWQlYKWF0BLPnUGdt7ELp8=",
    abilityText:
      "U2FsdGVkX18yHcuYnKNj7vSznnJ4IzuURTN0Dp7VlVaui1m7CmMS5G6vgy+3Rf88g0wcclj5CkkPEg3w0UBaPjqFd5YYIEB5cPWwqgai4E4=",
    gods: "U2FsdGVkX19z/nXKDAJ+M/y3ACdTjEuJtqmtZHlrFpE=",
    quote: "U2FsdGVkX18YXvuElgrgTN90FIQ4O5RxAr8FE5qw5L9X2ZCZ1w1WzJBarEDTaQyj",
    vgs: "U2FsdGVkX19wF+JktMvBc7LoSy9/D31l6lTjp8gZVLGJ+9ORijChyq94F2FPbPEJWYvhQK15BQ8tCjDLaTs4gg==",
    spl: "U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT",
    feud: "",
  },
  // Friday
  {
    day: "201",
    abilitySounds:
      "U2FsdGVkX1+UrHfwOEhAgMcLAMn2M5RT/mhfd+kgDUF0SEuVgzPqrV/g6Kp1qpsijRzuahjz9egBCg8lhlFUA0uCP6QcIOb05GtYYu4YW6A=",
    abilityText:
      "U2FsdGVkX196qU8aKFI1lCTBokO3gs+G0KfcK9XuiE4xkcUtQFm7IBzEetIZaZBY8g5tgTPucqzTjhlg4xh/fhyjxoNdhDGPBkK1LGsVDVk=",
    gods: "U2FsdGVkX18KoIlT+SLIWFsJve5GBkyfdJzRKPVLEA4=",
    quote: "U2FsdGVkX18T9nCCbC7BCwsHF2RGooFc3r/GGhPghem0XO9N7qjZZxzwVGQFe7Oy",
    vgs: "U2FsdGVkX19yXZ/ba5ESxSdJH5sJY56DVw7XZFZstIJqbemc+Flc6RewVlgmCaDMj7jGzyK6oGptpG0oyguGsQ==",
    spl: "U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ",
    feud: "",
  },
  // Saturday
  {
    day: "202",
    abilitySounds:
      "U2FsdGVkX19a0IMG58QK8nyA53mmhPlVM53SJT1kFC6AmLmizh/KUwHvEx/lIW9ykRL/4bJ/4zz8D6s9GYsWkhGoy9E5iFbmMLASLcXX2BU=",
    abilityText:
      "U2FsdGVkX18kJ6hNCVdPvqv3Qg5GUsaSwSUJxyVWq0KWhH6SkhTH74xvM0t9XAlG7JHDY/KHU7zNW+RN0hl0e3Rgc665jorpGmosqNOUAOg=",
    gods: "U2FsdGVkX18QffMIISYF/S5w6+CwbWVopyrzN8gOeQo=",
    quote: "U2FsdGVkX19vYgyJh0Zauc0teiBArPl3LNRhf0WHwU32heaBKHGHJG0BSlbUtN3F",
    vgs: "U2FsdGVkX19Xv6Z43xhH5/j3CugsbiUzMsAkeZnjAUhFaGfpcS1Jkn95g4zpPHWQwMQEaNvhQVSgT+mcYA9o3g==",
    spl: "U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih",
    feud: "U2FsdGVkX18CJzrOSvNqwfZflw4LQspOqpTkmfhOxic=",
  },
  // Sunday
  {
    day: "203",
    abilitySounds:
      "U2FsdGVkX18Rcx1vnNbUC0kNdVOsOAf9V8pOzrncexO1sSAEwV8diga/AIVrYwpnnOiyuYzPo01RR3vwS5kBVh0L2OoWz14xcMFD5b+pPro=",
    abilityText:
      "U2FsdGVkX1/Md7rKkirx8wYWBa2ChTKyuZZ27xYUeDQMaEOUYPhiYRUXinehx5Qpe5F64jGINA2HaMECzf/bsASg+urMj5Z4QF1OuWyhs/8PX4scfIqCFKMalAk6og27",
    gods: "U2FsdGVkX1+RHAXj/Ggba6r6ujVU04NyyEWVTHJyPIM=",
    quote: "U2FsdGVkX1+S+V/F3J3Je800RwyVzLBDE4u0pU56J1pTYJhA1X5MCnQXZ098bvS1",
    vgs: "U2FsdGVkX19OboUqfnDm/SLZFuwXQgGN+t+4+BtiZmvG1lsaEFDxR9sLDF0hvX6NhZ0TJ6N+2d81zCEtDdbafQ==",
    spl: "U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B",
    feud: "U2FsdGVkX18iNSwGPpv1GhCrcLY7b8PGTtYqNvNrhNs=",
  },

  /***  ****/

  // Monday

  {
    day: "204",
    abilitySounds:
      "U2FsdGVkX1/we0b2Mz2ef/hpao/WK7pORWIM3nsa9EpoUa4OtTVVYTNbhBfHBU4ancurD3pt7Kt9fRl0YjsJ+wjQyZJa2v45Jd749ztTlzE=",
    abilityText:
      "U2FsdGVkX1+vI5GTq6sn56f1ubj3qSLKXBU7KPnvk0Dd5agWrUG/uR/lB6RpKXakhX+SbScg2SMJpuuyiePkNq/HxlaMNSFJ5MifXe/dexQ=",
    gods: "U2FsdGVkX19OryPvZGwrmtK9cSWrh7l4530eW+zG/+c=",
    quote: "U2FsdGVkX1+HjBGVykpH+SELwwoAfjkHwCVpVoloIJXeRLzpzwbbEAu8Q+u98/3x",
    vgs: "U2FsdGVkX1+KjqQq4nWI9ZNDHvfZsvP3/U8Y5hRueB4YhFOuLaXsQ75UG98602ff",
    spl: "U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR",
    feud: "",
  },
  // Tuesday
  {
    day: "205",
    abilitySounds:
      "U2FsdGVkX1/y4Nuq5QMegftf7NnSl1xlFV++NyIRiCEPDQARkRvl1Tcma057HaogBJPq8WFtu0cRsUl5HN1CC7oXhl7KylWqV4jCeB1AXKk=",
    abilityText:
      "U2FsdGVkX19wvcvJqRPTo/gZZ/BTj1ykdvGBiJCzW5YcgQ/xzCuSz9erbYYvlT/LeUgWfE4xeyho8upjY/LIUpPorpx4xqzNqd+HoMd6hkE=",
    gods: "U2FsdGVkX1/s5HZn87fQffSg6u69OI7Cn/IpywxWGFY=",
    quote: "U2FsdGVkX1/QlHjFd/gim64K9HORcyP98DOKOj1sTvNLkbzEr+fqXw6QqqCytdJ4",
    vgs: "U2FsdGVkX1/v1jKCLvwjbaQw8rg+FXVYTeEIEY1a0L6ToUyCynGAB+01kwrrIgAR",
    spl: "U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y",
    feud: "",
  },
  // Wednesday
  {
    day: "206",
    abilitySounds:
      "U2FsdGVkX1/0rKxtl9RD/OD1jgUeVUy/DSqnN2gN/uhYBGHnUP06GWPV8dsPM2dpnQ/hZAyG2bz6612H4VhqKVm+2RlW1gXB8YeDk4hVDTM=",
    abilityText:
      "U2FsdGVkX1/6tAHfSE3hacnUYQZQ8FfUS3QxPzPjeleGpHl7WrL1apoUS+4oQHesOwqYKnHuOR/VmTF5Fu7rUxRb0cg17pCogNwXxedI6l0=",
    gods: "U2FsdGVkX19/Q/JmInINi03znVuaBR2i/iFTHHAJiUo=",
    quote: "U2FsdGVkX18igSicLB3ZMlp5bA+Vlr5vwiHYLtnb4QWg4UAmpnabeSKG9L3rQ+cy",
    vgs: "U2FsdGVkX19JRz3NV0C/2vDjIMa9DBfsO/qQ1LLhWYKxlI8npDgsF6ZqKOTgP4Kon1+dHI18A161dqjKyQr4BA==",
    spl: "U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s",
    feud: "",
  },
  // Thursday
  {
    day: "207",
    abilitySounds:
      "U2FsdGVkX19uc9JLwAtjTtLKUkQQCAKcxYvh+CpmOTmZhPbqFEB5QgZzQ1WZ4B4S47Dq46Yqgji1cTibEMzAo9Dh7E+Su/vZDdJ9Rb7lqv8=",
    abilityText:
      "U2FsdGVkX1+nsG442wLtmz6Au49YHvTsdvAvjFSofDd1AONzPytHgmHzXQXnvZedC5I3tQHzA1lNiwx6ZLMvS83/fGfYyYtncd65LTHrgE4=",
    gods: "U2FsdGVkX1+xYXPPUZ8M/vJ3exBn4bAjtr6RYrRdJhk=",
    quote:
      "U2FsdGVkX1/xf7gfuBgABaWfMchJga+D5nPFLomVW+5us67AneTPFo1pWcdN6ykgt2qzdCnnzsfi8iANHANVyg==",
    vgs: "U2FsdGVkX1/GTRZy3/+PzocUDfOLGmw8kaYkfARlk7pGSFattxGLAyX9bnM+QGep2E5iqEoV3tfXI4p3Pvd27w==",
    spl: "U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L",
    feud: "",
  },
  // Friday
  {
    day: "208",
    abilitySounds:
      "U2FsdGVkX19dKxKVZLgvPVj7Iz3TjT1UZGMswKEPSN0FFOxAcMpLgGczRimleKQJqc/yYNMH74z8mhgY99kzacgfxgmajwZQv4MS0wlCtAY=",
    abilityText:
      "U2FsdGVkX19sb5REWZ64u0jcBS2Za9u+5GwI8eoOfxm3js39M3CA+tjtlXzHYHw1MF13v5UbeD0uFBaivyNEUrB4hUGs3dg00xXoLwGsQwc=",
    gods: "U2FsdGVkX18PsgRFkWvWkSGEq8pUi5gKIOafjcBkHx8=",
    quote: "U2FsdGVkX19J9Ev/L0O8JsDTKP6+SE4zB/6uPMYDyplLVCOOcbQKn/TlxQyCT3ss",
    vgs: "U2FsdGVkX19WsJpPzTu1ybHAn5h+hKf5D4NASGK88ZZbatQ4xUwswRpGX8xQ6H1/dlN06jX6wtFKesEHBq5u2w==",
    spl: "U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG",
    feud: "",
  },
  // Saturday
  {
    day: "209",
    abilitySounds:
      "U2FsdGVkX19aDjZrrL0Zf9xuk1CRDszfJqzEc4t3qZah5NMDuGXOPL1TgQLbb9O1AVN8twg1BUX87YiqATpdLw8aNCiBi6YvbkwOnfq6pDA=",
    abilityText:
      "U2FsdGVkX1+Cp+6py6k48O4c2UtYCKTS84W1lyUFmL9fH6bqW5HD1QiMiLTKBD4+52F3NPIwnqBY/eLS6VhfarYSAh+iyJU7cH6XdMFTObQ=",
    gods: "U2FsdGVkX19dasCabKawcbUtiYcTAi3rq2fB622/SF0=",
    quote: "U2FsdGVkX195uCj8bDMBE+TBjFr5sKxepxpxEb6Igwy9Vbg2RdOPMJrzXvqpD0xg",
    vgs: "U2FsdGVkX1+FjDjJYUyFW1IeiHYvIhQKQXOs2mjiTdq7AsHQpvLdhSv10w9KE7+phTTFo7Mce50jOg7mPJSSLg==",
    spl: "U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce",
    feud: "U2FsdGVkX18WdL+nq3zuzJNN5eIpfrQuG0LBOu86+6U=",
  },
  // Sunday
  {
    day: "210",
    abilitySounds:
      "U2FsdGVkX19f9Io5ehsleLv634EQsBXxY8mDPLdjLeGWpZj5fWyDnniPPmkQTvj5r5fihUZ2pec165RY2uwKtaluuGBcQeyJkdS97MygMcc=",
    abilityText:
      "U2FsdGVkX197960zpLohZFF1Hc+x6ajatic0jJ0Pwjh2yb5GQioOzvuJ7ftvjn+vKX4AY2N/DZzelKGqh+m2tBq9yXjELl1RqeL6xJQZShI=",
    gods: "U2FsdGVkX18ugp+o9ROpYXUvTAW+ngF68UXOfyMiW5Y=",
    quote: "U2FsdGVkX18SulKn0BcjrywQtgQgw8vH7mjnLRu93s1A0BZqPexxoV49pb3K1ssw",
    vgs: "U2FsdGVkX18VnuzW0AyHifZloX2jbhwJX8GWcXzIQUk11TD4pOmSZ2c6QRpAlyEAANGuFlQ7Nfu37P9qnsp8Lg==",
    spl: "U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx",
    feud: "U2FsdGVkX1/wM58tf5TG5/4JQaT6KqWF4fjpGiSgs44=",
  },

  /***  ****/

  // Monday

  {
    day: "211",
    abilitySounds:
      "U2FsdGVkX18tczvzTdy0E1cIYNDVyyscC4cKZESskxYutKtKgg6tnt01/zKduqmx+5veFXvc39DP+/H9uOtl7sDfXkFsnbEr+Wb0V9JjGlI=",
    abilityText:
      "U2FsdGVkX197kIV5E7ZNK5tWP3B8p29V+mAmaODrUiYCtolo3qRJrcYAzsrusj4zGLgClsx1+EAsriSrgHCPoGs9piDK+8qEdA0luhdMNf4=",
    gods: "U2FsdGVkX18ylGqPk+yhctkCkyPm9594vIOjJILQ8Vk=",
    quote: "U2FsdGVkX18sfijgwgTUoxGAe3k8ll1P6j9TCd9D+Yv5jlZOhyA7/t8kD8t+1/S+",
    vgs: "U2FsdGVkX1+UwXgPPKIsQqU7B1eXmvJRHpzLwBZM3WBKrgLMIw1cuKR5nAJDgGK2/MvUgQT5Ks9jB4kzvio96g==",
    spl: "U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh",
    feud: "",
  },
  // Tuesday
  {
    day: "212",
    abilitySounds:
      "U2FsdGVkX1/tc0DrBF5rbJe+nHPG3ejxXalr/FRZHPXMpQvHQXQgrvPwPdMu33EwWq7rHMfeAWWuDNZ0EFJRlCbGpsxciGAAaWFMOYI4XEc=",
    abilityText:
      "U2FsdGVkX18kGl+Sy3uiUW1d1CJ4nedH3ncgKTG4qRgplxc8mh/VeJsdMn1JLyD/bzCyn9ejVOwoRWrsLpiABwppEA0dw51ee0r7W0yhZw8=",
    gods: "U2FsdGVkX19WFarbWekGIKtiYP5rwYjVJ2cicnkbois=",
    quote: "U2FsdGVkX1/q8lqV66mbsHvS4lQDG/uXgdj9VcZ9mnbytmTr8e4s3udHIC68R3EQ",
    vgs: "U2FsdGVkX195bKkFEZXFJVTrrLjpc66R/Nc6vkByYGIcA0TCto22silhAoAC7qxk",
    spl: "U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW",
    feud: "",
  },
  // Wednesday
  {
    day: "213",
    abilitySounds:
      "U2FsdGVkX1+LIdTgie47UC0btqhspOmzEYTd/u6Lrcy+5exVdZEq0t66mbJoX558P8fqmdoHQVobFeFKKH28PbtCwejr1s0kUSN6NbHgkGQ=",
    abilityText:
      "U2FsdGVkX1+bUGU/XWXr2OlX8gUwb1FkW7S+p4dPuyjxbxcRFohjfPn7GxMuQvkGwXhEZ2XBUuFaXFPtLSPyMA==",
    gods: "U2FsdGVkX18PY7aKWZz6omAbQCtqlwtIbfSqVvYLRYc=",
    quote: "U2FsdGVkX1/S5iWAHv3O1vESIAv32/2dLP/5whodpc297uiEbkFaXio1Q+Qjav1+",
    vgs: "U2FsdGVkX1/qBIW3oTMcVVJOkQP29xAF2dQIqRGrgYIXLO/dKynf5SzxXo9/UCOp1qnqVxCUTYd2DIMxzotE3w==",
    spl: "U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND",
    feud: "",
  },
  // Thursday
  {
    day: "214",
    abilitySounds:
      "U2FsdGVkX1+bxjz8u1BinkBdDFOXqCxISAV15dXk5P+GZOkwjXHSR63ql/aL1N1FZ5OBBFPrW5nIidh0euYgepRvIMhv0Oo4Por4GzDLVtT+T3ARor5/R87IPNZOp27q",
    abilityText:
      "U2FsdGVkX19VXsG+21rQx2z4Q8ZG9jkgofEnbId4SU44qbyCcJvVxhGWxdEBGXroPtwSuERJZhY0pPe+erSMEycQTipUhoXeJohXJJbOcXA=",
    gods: "U2FsdGVkX1+cpf5eOl7RIlc/qp/nex/uNR0dB1LGwk8=",
    quote: "U2FsdGVkX1/cG8I6GGSOuUCfor7P4ztR1idvob94dcdL2G0aro2b2JLRIvC1EJLa",
    vgs: "U2FsdGVkX19wtUIrTjSNgIIkGMxN9Qf0jOP4zQ1bj3+tCAd3UuUti58keB1b1jUgYrV1sW88cA/anQgHnkxRsg==",
    spl: "U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU",
    feud: "",
  },
  // Friday
  {
    day: "215",
    abilitySounds:
      "U2FsdGVkX18ybZ9lCW9UdidERYWVC71EnWn9YaS+OcD0qKzP7+AZy48Kj2g3jwkewnQkU09IKzkfJgtnSmSBiqLQroKa2vCY6+A3VEBec/4=",
    abilityText:
      "U2FsdGVkX1+IDYqWe+sH8TCSGVqnYqp5rDaTm/L3NO5CTNabJcuQYE0foTApO1Zi8dS1iTbEuLBXFS0MiEeokVYllDEPwNObcv1cco6KQG2nsYKxpI3KmkELmp8UqtBa",
    gods: "U2FsdGVkX1+Cm9afLMw3H45yDeaIdXBHhVCS5R2JVpw=",
    quote: "U2FsdGVkX19FGAoTbYdvDbtAk3+IdGks5/+NAcJJSvQjwLwbnYrm72aBNrLkzCC7",
    vgs: "U2FsdGVkX18tut2hJLPt+iXTlRNV192xrFuo/Nfi13nDP7OPdNvGA9IeiiilRoxF2F6DyInRxfVRbGaY2QPIcQ==",
    spl: "U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC",
    feud: "",
  },
  // Saturday
  {
    day: "216",
    abilitySounds:
      "U2FsdGVkX1+fdtBv5DW6UHunWBcwDJmyEiiwR/SqB+Umdy/l4ewugO/op8s3WujxorsgBvEmqVZKLmd6Y+8UO/kZ89dhoZwH7V4h2Ec9WIY=",
    abilityText:
      "U2FsdGVkX18Gs9kFWYNYGIy6ZG9vqV20sWHMh5hewpxkO6c7lDOSlGEXl9SodZf5Ot8HCRJSVKEol4vFMJ+LyzGaiwdxkBRJ3gXHirmmz0c=",
    gods: "U2FsdGVkX1+HOzFx2JSdlrNexPWWJSC0BRisBZRxb4I=",
    quote: "U2FsdGVkX18VtrEeV6fRDU/jxkhrrRFgynLlKyNpvg1NOAUtT5g077bBug1LiVMn",
    vgs: "U2FsdGVkX19LXByldarAR3id7/dhEH8m6oh6q/pZTqlF9dcw7iPrskkzpSoeCMB/Ni6xRYtlkujFQJDhDuHojw==",
    spl: "U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7",
    feud: "U2FsdGVkX1/BKaOROfUKSEAlpHgn83JxSCzrCd1yJHU=",
  },
  // Sunday
  {
    day: "217",
    abilitySounds:
      "U2FsdGVkX1/VBywYOBAp9GZaDR6bynfEzOUa52/o8c/w6+85MTykt30UgGb61DBRD3LDRqMzhkFEUPqhVPGoe+H0HA3XdbLSYzBCWriewWY=",
    abilityText:
      "U2FsdGVkX196obSeRzc7DC/QEm862Lox53gSlcacAjNU0BMT3C7UV/HzSbvaadxUADvadL25lMpqCoYzz9aBcQBSOR3lKLboSOBtmmra9Ew=",
    gods: "U2FsdGVkX1/HP2zqyc7vs7P+h0WopwZ9r5yE/WjUxWE=",
    quote: "U2FsdGVkX19MTyVHCmoPMQq+jypWlegzjAIZS2Yf5OFZ1PJ7SCxLrI9eX5/cOSi8",
    vgs: "U2FsdGVkX18UV1OuYM0BSxbSTqEjGYLRAhMXXyCqebHW0+QirPrlYrp+0NnDGREl6+jkI1MArZDwx3opyLcnmA==",
    spl: "U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs",
    feud: "U2FsdGVkX1/rMpkHd9bjz+r5FbB+LPu4R767UdUQZ7k=",
  },

  /***  ****/

  // Monday

  {
    day: "218",
    abilitySounds:
      "U2FsdGVkX183Q60X5sBnBl2fD0zNXCKuK6uZ6rEAKE3AI3T0rT2X28I1GHMzpJsuRW+XVhdhw1Ouy+xmi473kxJ6wSgFbN62EVHUqGBgwzQ=",
    abilityText:
      "U2FsdGVkX1+2/OifXobixxup54g364uvyQdIVV1XhUTzPmzeuOA6RNGMwPUjcmyZt6QeBJ2I9nKmCn77r+TTmlQkiaVNmtnEkLmLiufZmnQ=",
    gods: "U2FsdGVkX19F9wPAvOPtkWI4is9ueIBHsSUOmuPRKag=",
    quote: "U2FsdGVkX1/AMV3ulc7jlqeFPHh+rVsxj4AdZMuTp8cwc50lp44vUQ51OXGSwutK",
    vgs: "U2FsdGVkX18PdrsnAF6kxT/sz/T0/f2iBhP/pRMw04ixl5+Fks5iajFFH/uu5EDD6RWTDhpe/EnI/BvXfXAM0Q==",
    spl: "U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR",
    feud: "",
  },
  // Tuesday
  {
    day: "219",
    abilitySounds:
      "U2FsdGVkX19U8vdMXST8Gkx3AKGIx8ocJHxuwuBxBpAYYppGRlE0AAw4AtTVoc7hyrZvGguaEHHADeLMloPCkl5JwwHKLwMa2Fjcvh5LvJY=",
    abilityText:
      "U2FsdGVkX1+Kp1WVGzB+LSnxxwHayEoB8M3J7IIdG2mL7LwRaglaIsdZaOiOJMuAGTw7C3pMMXLNfcJzTT9cR4NKjBvnW4OThxGkPAQhSXE=",
    gods: "U2FsdGVkX19JGOvDLbZECjrAShqiVhZLLaqebxzKQP0=",
    quote: "U2FsdGVkX18EgrZPeJlnLlq/ws/tA3cEGl+29cBsgrw1enmusbpwcfopuccwVvpc",
    vgs: "U2FsdGVkX1/D76F4lR73QoqnkVHzEryNNuNWtmHB7wtmzbHU/94Df3I9God15oLleg6TZ7qk1743MK0ToRDREw==",
    spl: "U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb",
    feud: "",
  },
  // Wednesday
  {
    day: "220",
    abilitySounds:
      "U2FsdGVkX18rZnjwyo1jxWg9zQMbTCIZKp2rp3yaZTQiB0On7mFMo9am/m3iyUg+FGwWsb/RNktKJWr2VTeLHdFojYkKuYdmpOvBHo+chio=",
    abilityText:
      "U2FsdGVkX18HhlSQulvaDSbWV5clu0XAw4FQ//eYQATSAFagQVokFr+xGcVG0x0IF6dO/NCy7uoFcco30yTIJls4R7q595Xk8ejR3S7XsBA=",
    gods: "U2FsdGVkX1/D6Xh/AtEYVYh3GsTs0iX9+zIk9WadTEQ=",
    quote: "U2FsdGVkX1+VuqaWteIeB6LuCrNXN4YG25BltUAumZHtcywVQojqG6LHmK5ijbZW",
    vgs: "U2FsdGVkX1+wHzAM8tzbbCAwapj7mL5prxpuSm3pmv87q/0o555r+JPonzNn3PaaLITSiiXP235gIe+yjMG/MQ==",
    spl: "U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY",
    feud: "",
  },
  // Thursday
  {
    day: "221",
    abilitySounds:
      "U2FsdGVkX18YH8VX6AufJi4J4vNwcHHhSMhbRyf/oTmV0HmTC236K8YCm9JdHy/EMIpRl1vwFYDVwEw0pCQmRvEDq+5/dl07003LQl7LjH4=",
    abilityText:
      "U2FsdGVkX1/VUAD292fb4ufFNxlzmVBxfFbIz6Bn5Hx3Y8dpfZ6KRsviH11ZIHnYBAass3he/r/VFjI9jaJ0GyLwEPywLiTIzBjX184wROE=",
    gods: "U2FsdGVkX18WuZm9+Nctm6/ko5HbUiRmOiOL3lIgGuo=",
    quote: "U2FsdGVkX198/MeCFmCLyJLm6ch5Z2p7DSpOLgR6sS3N0zESeqwO6TK7y22jPVEu",
    vgs: "U2FsdGVkX19Z2T6+wt14z8XNiWkOyRv1hxgmR90gIYAmxDQH5cqahDxEYX7VhlWxEqhvYhy1hobLOg3dx85XpA==",
    spl: "U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X",
    feud: "",
  },
  // Friday
  {
    day: "222",
    abilitySounds:
      "U2FsdGVkX1/5uAEetdmrz0h4EqR5ZaYnXd0mVbXuam7xcPI7+i+M3mCpJeODnWqUDHvnov1nGOZA6fM8PJ0TqZqPxIpskUpEodmDNW0drsBeA7WhQar2WvTiBU2mDfQQ",
    abilityText:
      "U2FsdGVkX1/Nv8a1kRdg4ie3gjHMaaH5tr2GFej7KVquOF91oyyjIjECJlCv9SOc/I9KjPmW6+w/wBJDIpHC3H35pRFOBcf+MnGBDNuMXIY=",
    gods: "U2FsdGVkX1+afFTCAFdD43+9IDj86ICCB/jX92nKz5s=",
    quote: "U2FsdGVkX1//gW6zGE4G1NAvnES0+2KPCIaCzivg7FCR4g4NIdtEK63Df5AIPp8g",
    vgs: "U2FsdGVkX196k7ZOeeaDrHswzsMJBYvNeNHD0QfPEEWHq7dehmE5YbKr+wb/OWNRZ+7/57OwZRRs+/9/QjZbng==",
    spl: "U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5",
    feud: "",
  },
  // Saturday
  {
    day: "223",
    abilitySounds:
      "U2FsdGVkX19HSfjgbWx0zVsr1hIL6cKf31vDDFh4NDA6a0h/f4z+rCLRffWfCiDhx1uuTUnJ5cTvT2PLNIXc7QpTJ2QDAuNV8FdtqIVPQomGoylCIUq6nkjjpMV2YHXE",
    abilityText:
      "U2FsdGVkX19tOfja8nL3dHaMNcvpwL+i/LeiLVxOehnFWrHPbSs9trKkMT1LRQs0D514rod8QxLdswYIhNvmzeRkB8/YuLbfI5t8RBOhqaQ=",
    gods: "U2FsdGVkX19m6tQIIOWObMQOChNHDXTIqVQKZxSTRlA=",
    quote:
      "U2FsdGVkX1/XZ2kPhQR30UszVTN9M8HRmtJbYa0cM/5zRXwr9q8+9m9jCIYa5wShLJP4fuLFYOgY4Ww2wXiCJg==",
    vgs: "U2FsdGVkX18NgRCaQW+wsahhSw1cgNah5f1HG4asI7JIkUN/oi0FVXt3fcvZAqu3p0CBxUcUm1gK66O/0kEvLw==",
    spl: "U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=",
    feud: "U2FsdGVkX19fTdrrwG9eFj+uAk0SBGsIeftZ1bAXwa0=",
  },
  // Sunday
  {
    day: "224",
    abilitySounds:
      "U2FsdGVkX1+9l4drmCtsOBsJuNZDe+6MSLlPSUshKbJgvUW33/iNlMBV+YDyyMdqT+EaQVAmR1neSJzBUZdUwJ0WQdiNU6RLbUyIjvYhWpw=",
    abilityText:
      "U2FsdGVkX1/LZSSh6bdAs9Wz0GAzzrZghEWyQ1TV/WvVh0T08rXlsBJw3cSSxI6FK28Ii/b1KmPlYyoV6xVOW/gR1nrOUM13q6oZIg2zgXM=",
    gods: "U2FsdGVkX1+5OOCRbdPPdHmm8juPiOaoWciwL+0IAlc=",
    quote: "U2FsdGVkX19j6TUdo9dHxhZMrix6aUC/1HfWioaH3RIe1+vIVRTBOX2EEpkmKvxM",
    vgs: "U2FsdGVkX1/4QSKehSZHjWPtpBCQwyDcmgkEvw8HOzarTZoUa6Ic4nHCr00D9/JjWdHgbkMV740UcziB959/og==",
    spl: "U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=",
    feud: "U2FsdGVkX19grzLRNoW5/vNAHNxg7yXCjfHJW7iJURk=",
  },

  /***  ****/

  // Monday

  {
    day: "225",
    abilitySounds:
      "U2FsdGVkX1+05fRKq3FPiIQTe6a9ybIOm2ms3yFROSgPxgizVuWviEHz4h2I9zfS3lCDfYuv0m+RdN4LSpOkWMqd6JBt8ugmA66Fy97Pm0t+ZTNWeLoYFBrvahLM1+Xi",
    abilityText:
      "U2FsdGVkX1/WEEPhXPFU2lb21Gwl6h9zcjbdMygacqvDSsNnmmOR2yZ16uW3JFz2TS1+x+h/BH1uBprISj2KABMdCyOApLTi5NJD7OAjYpo=",
    gods: "U2FsdGVkX18bNeb+5DiO+LZAdYYZ/jz7I6RsXYlLh1Y=",
    quote: "U2FsdGVkX18XVp9ODU1ejsy3KKMqRm9e5PVjyq7jJ/Xd+fFRQTPlnV7ZmOAapCKP",
    vgs: "U2FsdGVkX19e6v4TeawN1b2mM2/7pwhsd8UhGULSUp7udH9JFRPiQV9VmDa/EAnIwvbrqGJURYtAO4H1NpHXWQ==",
    spl: "U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo",
    feud: "",
  },
  // Tuesday
  {
    day: "226",
    abilitySounds:
      "U2FsdGVkX1++ESEwB6jr/tB18xeAR76NleF47SZ8T/2jL6zbwqnYlgdI2DCMPjEjyCsNTcSheMqTf4S9RSI31E3+Q86kW0XH3rCX2yuV2hA=",
    abilityText:
      "U2FsdGVkX18saVKdkVh6UpUpdPhqugp+2M7Q1dVCx6HyawHr3OdlJIi7Klw2+MD7LggtK0pvf1cTurZjxL+mp39DGXglXUMwDSx/P0HhWM8=",
    gods: "U2FsdGVkX1834MXJWfoFpfktmYONkHHGVKS4+7ldbgI=",
    quote: "U2FsdGVkX1/H3ZdzYKYlm8w+6ikGE/xhdmMN3YlhaENnPRjyssNSQfL/+U+2o0bc",
    vgs: "U2FsdGVkX18mh8jzwWHNL0P/mc/G+pkzmEsH87wcu6fUw8DooSas0RvKbpKMO1a97G3b+IIWoASMTs4mLpEUmQ==",
    spl: "U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB",
    feud: "",
  },
  // Wednesday
  {
    day: "227",
    abilitySounds:
      "U2FsdGVkX1/5Ie9L+1LoeiY059TyWomzL/AawvFMrFafhb6dWI+k/EeUNEXHBmfpljnXQXhuW13iltuOU8bB1h3uACDLDhG/YAv2HqC84XM=",
    abilityText:
      "U2FsdGVkX18TVsEa9rmkgAN4uM2DqzO0/KIUmNhO47Lk3jz+GYVr5ssswaLogrGLXDD0Dplo3eTp64ZFGcsy6GDFa1JvS0fgQjAJv2QW8vY=",
    gods: "U2FsdGVkX197ZYyYoCU7Hv6lyH/Ac63LUQOCN3j6Gt4=",
    quote: "U2FsdGVkX1+g5NWR954mDLtnovwUqMYsHbnbFBN3DLvfqiPkQVytjg7QKW4tWOLa",
    vgs: "U2FsdGVkX185/3yTcW9j8fHWwH2adycHv66ZDhSKp2thy6fVjE/7ivXn/rCqhIiG",
    spl: "U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm",
    feud: "",
  },
  // Thursday
  {
    day: "228",
    abilitySounds:
      "U2FsdGVkX194ljEKjGW5alG/ItV/NViVCyqJ7xc639tgf+C04WoOrVlSLax5As8zQlkT+7vQuQO6nT7/tx4bToJ56kDhwfWBym/LGMllKYE=",
    abilityText:
      "U2FsdGVkX19fzZv6HbdaxAapvSTLWkFPlArNc1h8SuvhrPdBkkTC2b9egQnoMPWCUeN3kVHPG/Mt4PfHZV9pIUbH8RuQdqNqx0hOKSAKumU=",
    gods: "U2FsdGVkX1+Ou9D6lWPV11lvE1o87ixe4SBQUo3ysHo=",
    quote: "U2FsdGVkX1+KUUMYOgj2sHDb4jGKsgItLOjL3ldOVwOUM3qgoz6bTbqzADZkgxJe",
    vgs: "U2FsdGVkX1/7ugoo2llgHAAV6AHcX1NMYGLbHhXPtGnySkCOBJNEJtN+CKVtRLGDmSVnGdie9LoV/ZfasEujWQ==",
    spl: "U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL",
    feud: "",
  },
  // Friday
  {
    day: "229",
    abilitySounds:
      "U2FsdGVkX19PgnsfUtyMd3M7bp7egK9cvPUJmNIpUe9PAAk+wQ9Hn6N97i9zaMtREeAHDSAfcRy45QZiu5W0XMsQ4XtgsFuNazl306+Pscs=",
    abilityText:
      "U2FsdGVkX1+AXkzKVyYWO0DGSHTuPyvWu3MJ9PEYmURvCao9TfW+8EzFr1kV+bX/fcyDacoNdWUifrYcGPxdKt7aeoiqksA+uJaFzekiT6s=",
    gods: "U2FsdGVkX1/R+66JfVH1DHexGb74VDwgSIgtJZZUPG8=",
    quote: "U2FsdGVkX19uq6Us9h26PtakTWLPFiTiMgFUCIN/2SKACl1+LDImZt9bU5cgmjE8",
    vgs: "U2FsdGVkX1/DOMVuDeo7rtrZBwsST1K95lHYWlKleeR2vlKwrkQ5Teku0oisxjBiFlv/cosi31cb3+/tLa2TjQ==",
    spl: "U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE",
    feud: "",
  },
  // Saturday
  {
    day: "230",
    abilitySounds:
      "U2FsdGVkX19rqUqONEw31utT7D5XdUjXHc2rwe8XuI+6Lz/Q23+rtZnLohY5OBlA18HnOVsh9zOO/JVoCXR1EA5KkPo9o1187mhuXHL/gPM=",
    abilityText:
      "U2FsdGVkX187M3GE22tSkyFnlbWPcEgj9mWpKTArLfwCRR4INpPIONqUGlgdrFuz12YYy+tXcCFXwDgLSy+F5oGy0NylwibStZTJ820wBM4=",
    gods: "U2FsdGVkX1+El1Fr7AZGypJ/lgCY2gR6HPtnysIGjFE=",
    quote: "U2FsdGVkX18Ym0laj30+g8fY90B1PElvvEaLo51poWWZUZQe5K+/4T/GO3EtHYNV",
    vgs: "U2FsdGVkX18/uYecCsF7EoIbK9knmGFjK4kh8ZMdzteAJIYudFGx9Volwkz2c9vOHq3Fjnpq1v/3yCQ4IOnlJw==",
    spl: "U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS",
    feud: "U2FsdGVkX19Jc2uU2WLNPDfFmTPsZa5Zj5iiXVIZy+Y=",
  },
  // Sunday
  {
    day: "231",
    abilitySounds:
      "U2FsdGVkX18ZWpj5vRce8YCGSOiGyIZSZRoGIXWaAF4V9Sy/Khth2Z3pwkF1h8x6DHsUnRz2CmJLlcT0EON/TfjnMItQKCh8QO9pe0pIYIE=",
    abilityText:
      "U2FsdGVkX1/sgTloXmCV8eoA0GlLcNV28L7McbrSvjhRX5EwJPLvn+cmXsURTlRXe9G+8v0i8c9gX+oyu/jJKgCGpojJV/yh1a0vhKJ1lIE=",
    gods: "U2FsdGVkX19eb3FhtsBnpHpwS0vTAZu+gZ4mH1+Agog=",
    quote: "U2FsdGVkX1/wiwfDS/nPJvu2ObeQKsZhNVj8NqeDFsq6AgwAO9Nov7U5AK3FItrg",
    vgs: "U2FsdGVkX1/VFCk168p7Fj5v0w2HHiBmvYMfOKKwI6tPsy7sG5Q6wvi0rfKf6nGFudYU7E4N5CZm1KuAaZrUXw==",
    spl: "U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT",
    feud: "U2FsdGVkX1/dH/P8cTnq10KQZmlAOBv+kH32RNikuQ8=",
  },

  /***  ****/

  // Monday

  {
    day: "232",
    abilitySounds:
      "U2FsdGVkX1+g9Q19YwdoELsh7a0KytxLtMj+bYW3AZESYRo+F8EMN3V54GN2GVrxiaVds2cpsWmSvqrLnuaGRfrqPbIUf3pu0zmRxLijNbw=",
    abilityText:
      "U2FsdGVkX1/q+kNChXi2xZ23Ae4hfI2Vj9TaKR1Dop/Bkwx5J9/bth96+U+t6bo1bqYYxN9BoJqwjlrX6SZmlS1Q7Ym2bhgNylh1BSIpP3JyY2r5neikF/+zaxzcas0M",
    gods: "U2FsdGVkX1+h1TCthxcpoQ2WynDthpVc50S735ZliXc=",
    quote: "U2FsdGVkX19zBLOJmzrXyMRHk2F+MlZFs6mqQWzIpOcPXzh8Pu/vSIsLP65ExTXN",
    vgs: "U2FsdGVkX1+Rzk3heBicGfKioP3MBP5VFi9dNrS6rWoYmn0UXc3m0TRhmJ7mmDlf",
    spl: "U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ",
    feud: "",
  },
  // Tuesday
  {
    day: "233",
    abilitySounds:
      "U2FsdGVkX19u3XX8rm+KOcGAZ4b1af7uH1RiUDeDlOeibkp5ejkrXLMD3IUwy95Q5Thv17SV9XLaZ/FNyUSwHrysF6qglkTGSlm8CpBpmp0=",
    abilityText:
      "U2FsdGVkX1/j3qTJL0dFOmnHWRfnmVexh9MYXGWgsKkenNM03C3YA1DTUfLNvV3IEQgaoXFHTWbGqYh5pAIml/eK/v5q7Yk4hxVaog5e49Y=",
    gods: "U2FsdGVkX19aQyLU49r9P8//BTxdvKUrAadaj42F/P0=",
    quote: "U2FsdGVkX1/bnZUbTxl5mqt5TF9EhQeELoQEUPa16gCpU3/ZiXMDQuYzlX3jj2NS",
    vgs: "U2FsdGVkX1/lPtAQRIqWVZxdbvvyKD8Q23VlZrwARpnehJJ3nJh2BGB23lguMgBc2YfYBpdbLMFNo55oDM4RyQ==",
    spl: "U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih",
    feud: "",
  },
  // Wednesday
  {
    day: "234",
    abilitySounds:
      "U2FsdGVkX1+kJNThcjmvUaLqViyGmvtjxawwg8CQxT3e7JkI7rzzKyNQmz96MB7fN3BbCNOqR3OLJEjPOBZGEYcaOVd7FDqeScB2KXukW6k=",
    abilityText:
      "U2FsdGVkX19ERa8nyJpJvCU9uGHpa0pxpjisUKNsNZTYflS9b7Map/lq6lpmBeqygQIbM2FMC53DB3ZEVp8YBuG+2QbMxKiaY8fTSyzCPzE=",
    gods: "U2FsdGVkX18xrklEjG7FlnoG7UvZgBZL8W5Z88tEL8Q=",
    quote: "U2FsdGVkX19qlhFnZfVp8ftjdESK7yiiXCN6IFvPeLjE0z2ZaaGJ6XMuCw0oQk1V",
    vgs: "U2FsdGVkX18sdbnDW/jTUnqloDpyoJe+UUMlICkb1KcYVxPhYmxLe9KqXjFyKzScvxcBPswuOebkuvJ3XJeGMw==",
    spl: "U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B",
    feud: "",
  },
  // Thursday
  {
    day: "235",
    abilitySounds:
      "U2FsdGVkX19ZwM1gtputufuo0GEPH3BVxs4/4rAKwNqKULJtLjnJeVHy6d+3NFUyDL3QubzdTlEYgqqSH0gTpEaScLgDuhWk5RkWQ9Yfc3w=",
    abilityText:
      "U2FsdGVkX1/ZBmPXaLcYFmazigc33K28rlcDVAL92quWS0qT5VFdKoBA1kVf8QKxRLXNDyLsUeq+VF8yBr7LldU2euH/DsKnD7CONrCoaAU=",
    gods: "U2FsdGVkX1+EBnwzu1mIY5CAG4BschYZA+gESM7zb8w=",
    quote: "U2FsdGVkX19jQN2b9XP53+UC9uc0rXciFeFiAJYnOemigPogZCx2299brwf2+P2c",
    vgs: "U2FsdGVkX18usbZBr00wn3VgXgViWPFQcJdCrY6l9VskEDvi0cKYV7O97OtbUI+fEPo+N8cQ03ym7vEOthr0jg==",
    spl: "U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR",
    feud: "",
  },
  // Friday
  {
    day: "236",
    abilitySounds:
      "U2FsdGVkX1/+TrSSUuQllzUk+7cHJFcJYBE+5f0QnFcMzDOadyzWCAYOLkvz0wGicxneXKd/yNvhxKZGWuyw42KvZlFNL1P6s8kEKkjGGPw=",
    abilityText:
      "U2FsdGVkX1+eBMxF0MXv6IZvgGiN2N05TuEcPtzab9AYbCpR9sMrq1mTIO90++WtLM8OskHeQneb+4U+dR4t1LfWbTpPR0VXcMS46C/sWok=",
    gods: "U2FsdGVkX1+6s0X2MvdKe3foP9jhjCMUZiJSJYfQbzo=",
    quote: "U2FsdGVkX1+oxgg97vVJr0dOEN5IZN0OuO9v3MK32eFm8yQscRBUzNhSoo7jdYgi",
    vgs: "U2FsdGVkX1/ZSaGPmK65q6ZGJmsNI0vlaO8xFzFTGvurqLeENjz6SUZAIpMC9TuSrG6P8D+4HSHqAYbl9cE7Xw==",
    spl: "U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y",
    feud: "",
  },
  // Saturday
  {
    day: "237",
    abilitySounds:
      "U2FsdGVkX18fNWj3/RO6pkwsze3WvOv9iIRmyknbdoCFlYBknQjgKJ4zFMyqQQOKXVhxQcrgk+gSE5Duo24d2ac++Q8HGQCuioo19x5GMNg=",
    abilityText:
      "U2FsdGVkX1+54/vnHccScIbgCZNBpy286Vlz7beACL4Y0klQV+AuSavXE+AXsLLtUgnZG04OFuTwZmNmFx7S55JZOwnZ3to+XMeO2ByQ63s=",
    gods: "U2FsdGVkX1+CaZuv8OnvhMOSmfM4YCplA6aJg1wepqM=",
    quote: "U2FsdGVkX1881SIet8qjdlXiT5IaammSXStSKaEqM3qCyiQbqLn91NfZmzwCQ6l5",
    vgs: "U2FsdGVkX19TvO8XYR1BuQkBdNjfG2uSI5wSTGvlc9b7B5G+NaI9SOZixCL/EzMU8cDe5ykzEBFdb/Ddm5JWYw==",
    spl: "U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s",
    feud: "U2FsdGVkX1/CIoi2AXgAaOT6pE/qwYoES1B57u770vk=",
  },
  // Sunday
  {
    day: "238",
    abilitySounds:
      "U2FsdGVkX1/RiJ3pV6vXh2PeDkCX0+AJq619cIZtjQMaDMyyXbWYte4wXcSWRbsi7i+75xqve35JQG4SxUfCaVhI3Q2gv5fCLObvTdVOhbY=",
    abilityText:
      "U2FsdGVkX1/u+mQ6hncj9i9ctq/1/SYS0hnAWYA42kViChIYrcpqG8a9Xqtj6UgvnuIMcA+obzFwUUWeJIt7EA==",
    gods: "U2FsdGVkX19zABLFDDhQnMJY35un7ECBdt3Vlda6Ml8=",
    quote: "U2FsdGVkX182hGayTZ2V0crvpv+2icWk6K7WWavakZEiyz9DenOOIysrzxpXAxPr",
    vgs: "U2FsdGVkX19BTSVE1NQQo8KZ17aBqNpADjty1QseEj6j0Lsw+AeEYWY65kyc7VkKEY18xLD1/7O3aeMQtRpf+Q==",
    spl: "U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L",
    feud: "U2FsdGVkX1+oPP5OjXgcTJXuHeuGqUdY5LFaePIH2ow=",
  },

  /***  ****/

  // Monday

  {
    day: "239",
    abilitySounds:
      "U2FsdGVkX19q9l+wEhaBXdRzboPG9pfOo9MkHmtivdKai6Zj28SOxz8tv79OMNi8dWnUPkF0afxKjngCaohMxkHqkIWpFkWZOtlNuhvU2tM=",
    abilityText:
      "U2FsdGVkX18wsurgqH0L6QowDKpQXTf8qTR3HjWxXBnaX4qGvvl8WVkYGdz7sMneZZJGRqIoh2Lv2vK62d4Jze7Rv0BS6tUJiIOgxUwufhE=",
    gods: "U2FsdGVkX18+jI5H6Ko4N4ta7ImudOYVqKtHoc6qjE4=",
    quote: "U2FsdGVkX1+Ppo2/A6BOOwQE+6YiyhJcZe66KOM3ox6b9FKGr+2y8pYI8tMkSfNv",
    vgs: "U2FsdGVkX1+bY62qu2JKDyhAUA3j+Ph1k3Q+xFJMIxZG/VUxNGSo008yY1B79zlru2wZaIw7983awAS4GprDcg==",
    spl: "U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG",
    feud: "",
  },
  // Tuesday
  {
    day: "240",
    abilitySounds:
      "U2FsdGVkX1+7jNzK3OglGv1zC1zkkErS/XSIyjoBBAWgcolrkcKrQyf0DWk84ErER3pEhuvQ3egP3Nee++f8SWPy/PlPrlDkjZ/9SMx5xkM=",
    abilityText:
      "U2FsdGVkX19wWXV+vuC85cOtEkg7ZHhfbHN7hm1Pm16CQx+sb8wM3MYdbDQgJ2uKupuWP6SRcnPppXzS3hnoniBnvRWW1MH3Tm7THOzGc9E=",
    gods: "U2FsdGVkX1+AYrhdNXl0b3HNT/FUMrp4EqGO2YKuCWg=",
    quote: "U2FsdGVkX18w+rMgApUCIyNbbijr/nW+o0B2WIYlMC8rYxTitCWrEsVWHq9hSajX",
    vgs: "U2FsdGVkX1+P6GDUce+pEpvl7PZxbIh/cLJAT0SnFAfi3vlczp4hZph6g/VfM5XQ",
    spl: "U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce",
    feud: "",
  },
  // Wednesday
  {
    day: "241",
    abilitySounds:
      "U2FsdGVkX19/xsEaNqlWqZzXB01s7/ss+qV8ckUpHhUqsAv2A+Gu4+MqSOabZnigUGpiGTBrJ42VEp7koKesgnINinAufPr8cZTptcL9wOmlZDUiWVlYW2bpQLFWwlVL",
    abilityText:
      "U2FsdGVkX18GOVfnTOiZd1WiKO2SHHCFVVi8+np6ZNJFUrdRoJ9FXz8GumakSE2xMgT6TzQFmNkeDYU4IIg9gJFWK855SuhWUuIvrIUUktI=",
    gods: "U2FsdGVkX19tRw7oFefGuI92liM5O70of22DQEZhG60=",
    quote: "U2FsdGVkX19IfGoTwgBCDgVzwY83+Dg5B4okIXlCxc3fH8EP1Fj+S2mDjl7VJbXL",
    vgs: "U2FsdGVkX1+BcPcn4S9KBxQtvRGLfWmXL6K1v/viegVmvuHjOfTG0LgN9ZHUcze7PNVZm6n3ILDYGiY/z8ErWQ==",
    spl: "U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx",
    feud: "",
  },
  // Thursday
  {
    day: "242",
    abilitySounds:
      "U2FsdGVkX19aPG1r5mDeAp+mjAJjrw2nXmNKvo+1shfzu69alje+uLKELJogODFTkGsKJYH8HI7ji0IU7aUnjCyf9i65xEUhdmEvzjWLNYU=",
    abilityText:
      "U2FsdGVkX1/zBbYa8qbex7m9Us6SGL6DXe7R5J3cyL5TdmRHT2v4C4apRI5+tqr8qdVcjr8bjCF/TKQ5l3Is3+tWhuNWENWqsVcpuDEaSQ2Rph6EkBy5wpnZ81ncmgMb",
    gods: "U2FsdGVkX1/1Q42zxRwFNaPDWwyWBi8LYvgJT/Ko+6g=",
    quote: "U2FsdGVkX1+cHwnBb1vGLNPfsNRExjMi31zEEaJoI2iiW1pWplFMkZwJvRqP0HUu",
    vgs: "U2FsdGVkX19Vkpq8/EIq8+pVq4llEk/LcVZk6ifZ86oiwv2Zr6pORhN0NSIsfU1hRrqa5k+mV/FZPlGH6CRqpw==",
    spl: "U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh",
    feud: "",
  },
  // Friday
  {
    day: "243",
    abilitySounds:
      "U2FsdGVkX19M1bvrfeDqagVH8bHp4bPX+e//QzsNuwKwSyi1l94RkCa57qHMMhrnTO5D2H5ud4CMKmYwigdH/h56U69TT4jcjWWKfNOGm48=",
    abilityText:
      "U2FsdGVkX19IQzWDBahEdhbMB+t5k/YWcoImdrUyiT2RoBf3XMwS6yQZJH+2YM4bf+n+k6Ip+yy6jjY34NR1ZbaRf6lzgHkO3CoppJBZjnA=",
    gods: "U2FsdGVkX19UX0zEzN2z+C9HlEs+7zSAEyHOKijeB+M=",
    quote: "U2FsdGVkX19nBvLSN3TrAM9w9vQ2c+s0tugIely9Ei40koN7ZvHs7Vv92bTZtZC1",
    vgs: "U2FsdGVkX1/Y+af+EqQKzY81vei9y0h8UC/SwWJIiODpra40mU3WMdvoIeXPY4ueEpmJvH6MfHTijrCmQUdfSw==",
    spl: "U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW",
    feud: "",
  },
  // Saturday
  {
    day: "244",
    abilitySounds:
      "U2FsdGVkX1+snJs2zvVICBHdmGeePFXtB8a0075Otd2KWxktez+tSoHlvK1f70PFewscPJO408VvONeSb5samCCVFpGW/MfCsfuWecqL0LI=",
    abilityText:
      "U2FsdGVkX19BahJ5hnd0YWLIqbEb7CWqEboN8iyNWy3ZjKba9UMxDUKXHqj5U3M0iVrgl5E9jpmMWEbfZtp8HftV+IZFcZUaq1xGQAOainI=",
    gods: "U2FsdGVkX19RPETqw5JnXP1rk1TR4Rh3OL5e/GvYAHI=",
    quote: "U2FsdGVkX18uHc0saKvFAQntrozLYWAyureX2pkzEqB0Prhla53ikV2ECWFUlrh5",
    vgs: "U2FsdGVkX1/dnawWuKAIIy4dLa2FBLBZtBFpbQTJbyUK1u9afuWbZO2ISBVc+Os7aD1iNtLBVcsQF4iJ7aQrlw==",
    spl: "U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND",
    feud: "U2FsdGVkX18ehNIbBu/i+DBgwr9gRLFYzr8jKxGKozw=",
  },
  // Sunday
  {
    day: "245",
    abilitySounds:
      "U2FsdGVkX1/gJIpbRkmeOz1cWVI1AtV9amyAJOuJO7739Ef8sJ81xTszVDfSKq546eNDnSG92y/O+4vcqKIFfzcbF97qukVL7jadSnrtQnI=",
    abilityText:
      "U2FsdGVkX18F9Uz5riZcU9i9TVVmpC/SwumlW3d9z4vrWt4fo42PXDcR4EkqLD5ZIZ7T8sC+bGJzfNUS8+vVehSE5+o+oUQmY7S9uqObJLk=",
    gods: "U2FsdGVkX196uHdIZRkyitArKIZQANq6/n/hlr2OcDg=",
    quote: "U2FsdGVkX18J4yTyJNEzILbGPbDUFMnjFfJm+0uHyzeqc/NVUbzH75ZMhe/EKStw",
    vgs: "U2FsdGVkX18Kkx+yyag5wlFfFmRlac4keQE2FYkiFxPvUS4HQUC8D0FrX3y2tARxKE8lhq+YrenuRS5xq2l+rQ==",
    spl: "U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU",
    feud: "U2FsdGVkX1+9tTL+w8OsBefWDAvq5e92EGPZJIxT/Jk=",
  },

  /***  ****/

  // Monday

  {
    day: "246",
    abilitySounds:
      "U2FsdGVkX1+nKl/DAUpYJMRak/aL0SxU+oWr4IPIMdC5WBJBgC11E8RNbsuIBP1qm35ODZoejSLATNcilm4QXEtDbqeHJMxy9OE9kS1a/ww=",
    abilityText:
      "U2FsdGVkX1+It+4QgBXc7OJxygd5ei/MFrAI+BvKRoZFyIAfPCahxAtiiHCpVLcPvfnjiRBCRGYXYZ0Pu3IYVjnPbpxWfAMuwqei0FIOYZM=",
    gods: "U2FsdGVkX1/us+xvihZT8p+wsfMfzmfo4arQTy1l5+Q=",
    quote: "U2FsdGVkX19Tqx6yL/ox6AxJFcyISpnq+bRUqVQPRr1fGp71RF7U0okBo5oIGuvN",
    vgs: "U2FsdGVkX19YQ/VGxP0RYo4A9kr/3b0/uw7dCqDfDf9E5ksWZBLsud1Z2xOHQIoGfmE1XGiHH2PUfRYfaitlQA==",
    spl: "U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC",
    feud: "",
  },
  // Tuesday
  {
    day: "247",
    abilitySounds:
      "U2FsdGVkX1+E0fF6deakyRkOInr+JM/OWVAXjRAYCZ9PI7oH/7tCFQRMkXcenC6giNnRcLuJAlBei0MVmsEu7fu9qL5Wo7wDCjVmpoiSFN8=",
    abilityText:
      "U2FsdGVkX1+OW9Eq9s31KLJhHD5zx53nk1pTAZQnw8H1oDm7J2aMpxu0EAOfkxuGiad423yAGFOtMyTriAP0p//gzgAB+Sp33YP7Cu1bm2VCvL5n3zIUJ8/qu6NtPIDz",
    gods: "U2FsdGVkX1+asLYewsoLKNLKCaFtjfgbDDXwxyOmnxc=",
    quote: "U2FsdGVkX1/FtsAj+8kCNNrhDhrlitTpeX9bype7fYCXFfjTQris0o/v16FRLpxj",
    vgs: "U2FsdGVkX1/f6fRV4caRVyJSqMPzZZ0x8Mt4SaQjzWBCUdfT/Fd68KhQr0q2KH2UrNrj2bOptBAh35PZg5DCIA==",
    spl: "U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7",
    feud: "",
  },
  // Wednesday
  {
    day: "248",
    abilitySounds:
      "U2FsdGVkX19BfzxVCjNE8GBpQFSBz8Ka+SWjLYYT4ocXLSsjFOLtUHWTlS31JtOE5j6zyT8QGkvOHinKgYbzI6wGdGUTbjEyLBQkf6tnb2E=",
    abilityText:
      "U2FsdGVkX19fnvq/646wsmEGiyC4zgXE1+m+L3IZ2q7jkjowob5P0Xw7rvj1VudLPaM+Xp5617ZVjyNW7seO4A5eaWAs+YyL7ScwaupHW/B8TfhxaidgxAmqV0zQWPJV",
    gods: "U2FsdGVkX1/vfu1Qx1RqVHRG7a7BI9ngzZCv6gz8RHM=",
    quote: "U2FsdGVkX1/MjUJS8aw6V9syO4k4Vfvh/NazV51Vfyb+4YGVdgQJY6HUj6P4mn7k",
    vgs: "U2FsdGVkX18kD6dRTJZMnojPhrq9r0FnnZLP9dfu8VxR/zg3VRYeCUXZKHycMhHPmVU1zg0JDtEhNmADVfPLuQ==",
    spl: "U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs",
    feud: "",
  },
  // Thursday
  {
    day: "249",
    abilitySounds:
      "U2FsdGVkX1+OaVnCKo1XagnDbJ9v9qgh3cJ5eelYpH4cfBXLIWVHFUVRg8IhCOqaLTYTyZ0acorbXyk1jXReMQjN+uU2ut+8pA78K+lRDzk=",
    abilityText:
      "U2FsdGVkX1/EZC8I4AUfT6JngSClH6bwkbOAeTu59sxUc83PVt8H5ISuAPmF/PNERnZ5xykvAHtiPqiAbq3T0nL+JHAs9Yw2nCi0u20yX/ma8I1i9xi1pI0ebN5YM449",
    gods: "U2FsdGVkX1/EA6ezXCM9r/LoDyGBKkmaJpbiVL6E4B4=",
    quote: "U2FsdGVkX1/KyBp6n2h342204FGXzpK8mDrSIjiq0kaMBLCn4Iq/FFACFEQiuUyj",
    vgs: "U2FsdGVkX1/hlSz8ZrpDpFvC/0dDTfb60I4pG/5GBWY0s1x2uKFl/TL6JqGf5mivBK4HaPd+YMeGnl9RJkCrAQ==",
    spl: "U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR",
    feud: "",
  },
  // Friday
  {
    day: "250",
    abilitySounds:
      "U2FsdGVkX1+gmsCWHuLEo2SFGUTMB5Bvb1/JnaIBrlw7pxIu90aUNKoRoGzGkMHhr+HCY5ZLMPNbyQb7IKuCc4mXT3hakN1dEU7dopc2a4bROQvQGrCCSPpwRLUyiCTv",
    abilityText:
      "U2FsdGVkX1+qr3bi54pnOmPd6E718KH8vb2ejGDodmeZCquQXZkfZN42u/XaPdlc9nIEQsItwAo9TeV5m/HzG5st2mr7NGGFqFOm5SzBKlw=",
    gods: "U2FsdGVkX1+Zy7iGfffG05iBBevF3mifIRhPArPxvVc=",
    quote: "U2FsdGVkX18fftRe9g6ep/IYe6HTbtMDe5wPcJ3JJ0McSeGf5t0nikxyflnzhDm5",
    vgs: "U2FsdGVkX1+o693HSWxkYA1J7lleujmAQAKglAwCGvk7Yn+G+KdHLAb4M1vqxunJ+hhzTwpA2zluT+nPW3QYNQ==",
    spl: "U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb",
    feud: "",
  },
  // Saturday
  {
    day: "251",
    abilitySounds:
      "U2FsdGVkX18hHmi/+IZBVnNscLI6J5o7crl1FdJlgbsLCnLa7f21RSern+gUfJ3GNmo4CoifREqTyav6iFvxwtMNlXWW1RBPGmU3oAeDXXI=",
    abilityText:
      "U2FsdGVkX1/MAH8F4NeoVJ2ztvPyHeu13kQ3feYLKDKp87ojNCClCK+kJz+dCedV3eBZrehgMgARHCW7tGxZDHscWHbXvNW6AqmjC9+e6CI=",
    gods: "U2FsdGVkX19dOxov5Yc9gcTKfAjfymdznN6Gb2Q2qbY=",
    quote: "U2FsdGVkX18ape4+4lVklRj2i/5KJYQwD86KFc42Alrdi1DPC9+vuQ6dFvFKQkDv",
    vgs: "U2FsdGVkX1+qo4vmt2uw1SjQnEYATfKeUoBn/LVI9TKT3VbIaGTOa+HUSLXdF3mmuKcYMS4SIwQ8Q268fyXD2g==",
    spl: "U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY",
    feud: "U2FsdGVkX1/MJlMiA8meknvHrcPH/zX7pwukJin2rLU=",
  },
  // Sunday
  {
    day: "252",
    abilitySounds:
      "U2FsdGVkX1+QNGp5+8W3VU+LNzdGX1Gt08TqewVQ1JGwQHxWlLSLK8vO8plZjzcOWCvdvC/zEONX8VXA+WPLc9xnwOZXVoORZamNAG+N6K0=",
    abilityText:
      "U2FsdGVkX19BzO9w4K7oEcWzUvk59B1aBb/ud9y0KbIquq6iwL6/yadmNDbPYiOa99UEviasUx5cf+OUEQ44Q9KMDtiS5767hnJtyqdv7Qs=",
    gods: "U2FsdGVkX1/puGyB8BkmcBTLmX9GtJq/YMZIh3yF/Gc=",
    quote: "U2FsdGVkX1+Y/hJIpj80lrTLcPzUdOwFdiP/UqGRMpXcsXIOaVPs6veoqA+6Z3k+",
    vgs: "U2FsdGVkX1/iBZyfJPtOD5pKs3agH3NOm3JACtZZpClZ+35IR5C532NujLJk3RlBwnI9CJcMsYwEmJmF2QCRng==",
    spl: "U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X",
    feud: "U2FsdGVkX1/dMPiPLNx6UygQR/RaS0vgIg/CzLx4sQc=",
  },

  /***  ****/

  // Monday

  {
    day: "253",
    abilitySounds:
      "U2FsdGVkX18/SsNJlpF9LS8jJfXoRPCB9x9xE3b1p0B1Vl1u/jS4GfZcqgrAk3D6okgg+cWGwRALDAykDu4njLfOikb8REwpcbzAX5YY5uA=",
    abilityText:
      "U2FsdGVkX18mQZg9yTdguh/TYmr2iN4OD6Zo9+uWXraGN2tsINtakzB9iB8CwyP25gfECfeFdxh4yTH9qkEzw3zx8J1b4EGWbuk0+LKI2xM=",
    gods: "U2FsdGVkX18v61+o/kDTAj48llUXhtK4WkqY/ffB93Y=",
    quote: "U2FsdGVkX1+u3K1Gl/rtmGi4F/WkdSCUqrSFJaO6Np/YWU268YmRGf7YPFPSQ1MT",
    vgs: "U2FsdGVkX18NPxQ9ad/4CtkGEE2zwsXOgnEqKknrxG4mQ5VcdAGdQRms3FvO/nI0pvJjt7qMQpCEz1nNlWzeFA==",
    spl: "U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5",
    feud: "",
  },
  // Tuesday
  {
    day: "254",
    abilitySounds:
      "U2FsdGVkX1+pnx6ChAF/yojJ22wytQzbE9XO0w21zV2edcWN+gLI3kxGIgynVJtlSpYvJA4QG+U1pl8Ne8BPepJa43/CJvkHNIKZczERuF4=",
    abilityText:
      "U2FsdGVkX19FmfLpdmBKNqv9sj26H787DcbaDmlZvs7/40Y0RSZAI0Vx7IOay8+8kJwW1FKCuuJjsLf7P7rZBdiMk8E96ZCoTm8xLOnsFCY=",
    gods: "U2FsdGVkX1+r04flVh51NJj1xnM2KDutPax7EkOUmTg=",
    quote: "U2FsdGVkX193ol6+PhuttrwZLTyl2BwVxr3Zv/kRZTwkKYqXXSQEUuZPA6U0suJG",
    vgs: "U2FsdGVkX1+XD2mMXdQCPiOISaKTbt6vWRosMhU7JJ9I9Oau8RTsX2OA2A64CWUgi2FrmqeE7ewfKhDBkP9/bw==",
    spl: "U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=",
    feud: "",
  },
  // Wednesday
  {
    day: "255",
    abilitySounds:
      "U2FsdGVkX19x9fooNXMz5Mbmk3chiZQjYwYVUCTNkreZ9g77C4vrqY18XB3wYwI0LVV5sLf/+ojKxKHBeNeZDOmMpDgDm232XWfmfavF7QI=",
    abilityText:
      "U2FsdGVkX19MRtGUi7d8y0i3t4Ki8N04//2E3jAz2OnfJjznsePJmEP5eC24DxUhEN4B8n1jHo8euzyLU0gKGRVS+QwOBBST1xmiIZh8WN0=",
    gods: "U2FsdGVkX19o37LIlCOGqewRPU7wgspdWtDf2EAGy1U=",
    quote:
      "U2FsdGVkX1+hxlZgW4dwq12hkGeXmNcQe+hpeYXeHJopfRaC779RsM/Tkh/uBbwu1gFy9B81yW3WmwgFI+Ifhg==",
    vgs: "U2FsdGVkX1/IeNjpQPLwGqSTornRv+Odn+cQmaKLK0hRdS+yeudFWyCO+3GS6K7Pe/waecXmVfkUKt8GQ+PRrA==",
    spl: "U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=",
    feud: "",
  },
  // Thursday
  {
    day: "256",
    abilitySounds:
      "U2FsdGVkX1/O8COeyRx6DNDIfAtnvirqVbvqYLG/0o0YXtNACZJRZBTr4Iw+pX+fJ8+v0STK2O8mAu4ep+J5qlMIdsoPMdeDfyjSginekg8=",
    abilityText:
      "U2FsdGVkX19lbKn2FVsI0DNXHhvMefMic8/N51nukvNRtSs1JGi+w+7z3GaurNlxnGRDHRXtFdc9ZERFtaIia49ggct4mK4UohjbxIk2sz0=",
    gods: "U2FsdGVkX1/nA4rXXWWTseeSYlde5vtg/Vox4uzOjCU=",
    quote: "U2FsdGVkX1+mjxy56hoPJxUpsz6OXwsWtg5+O3Dy2OqMMjoKq56nuBfuUycPQDP+",
    vgs: "U2FsdGVkX1/3OKnEChHCmLhmb22zgYk+2VhKZVB7LwhJnxlqEQ929tyXAYRZqoFFDa2OeeKciYHn/L0xWxOMzQ==",
    spl: "U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo",
    feud: "",
  },
  // Friday
  {
    day: "257",
    abilitySounds:
      "U2FsdGVkX19rrkdr7xLjv2CROZPDHs6Uk803iPIjgLqMWM3boAJxKM0VRRiCYexVjC+6HuGN8D9ww2mnnPlRncY7DogGBkyl7w6npS/RbUk=",
    abilityText:
      "U2FsdGVkX1/oINqjAknfmfkVAEuJiTllAA74DIC3fRJ+5SRe7mxZZLuoEFnwoGChVyA7YSnE7ENLX4zVnrjCFIvT9VVhfVw1WuYp4MV12UE=",
    gods: "U2FsdGVkX1+jQDYN6vRA7MHDp5EqLM/tT/W9BQ7ppK0=",
    quote: "U2FsdGVkX1+aHf27g1VqA6DHqydTZ/6gXtDNWWgdMzfCOCJLzxX5dKKdad8O+ItJ",
    vgs: "U2FsdGVkX18+5bNh8/ZLGK3dqEb0PxP/qkwHq6cVc/oFw8b2EAlsZb1CqrUJLCSOUIz404GfClIvcH1TQdq1eQ==",
    spl: "U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB",
    feud: "",
  },
  // Saturday
  {
    day: "258",
    abilitySounds:
      "U2FsdGVkX1+3AzIb+he6ja9kuWU8bsPIoJXCCI8rN6jTyY0mZys0qo8meUMZAYQ76F+JU7ArN7/U9OgVaiUbANF75xDfk+V2Qf46ZHIQIeo=",
    abilityText:
      "U2FsdGVkX19ZcB1i7lzamV6jLRSohNZ9RlHpBHpPaqRoWzsqhMzfNmlGZsB/ger1/tNrjwu5B63w4vw/ijebIlOB0u86MGt0yd4fjSHTPos=",
    gods: "U2FsdGVkX182Mx6ZNGQbc83Zs0UCrN0VERz3wijtpLg=",
    quote: "U2FsdGVkX19Oa19eyOv0KOSyBwXlq9w08UzPXErdXw3W7O2kylMk4qtqxoU8V+N6",
    vgs: "U2FsdGVkX1+GLoVxp/TB0npB+EQlR/6B0+3N7D3bu4QUrOkje0s43oeprneyB1YZ",
    spl: "U2FsdGVkX1/YkO6l72rRT+sOqYDZim8lRK/XTSBuBC3Tf2eMjj8/j87SCXur/Ktm",
    feud: "U2FsdGVkX18STJIASTojaI6HUvePLE9Ma002KkcuiQo=",
  },
  // Sunday
  {
    day: "259",
    abilitySounds:
      "U2FsdGVkX1+BfdxETjRp7Ew4TyKJ34+JXsWToqLDL9odWzQ2niCQ96MSNTxBpNSNedwCQ6mqbp0+QLDnDq4Yu7hxW2QKmSe0zW172A5PqKk=",
    abilityText:
      "U2FsdGVkX196ws6SA20D+es0ymxfe2z5vqT3WcxGGXevHjLlbKUyZjJwQIg7CtLw2bHu3acQstIKTaQbf1khBxl2fsITg8v6ixBgtp6+wHo=",
    gods: "U2FsdGVkX1/Wdtcxks3oSoILKRJndWyHX7iV/SmrapM=",
    quote: "U2FsdGVkX1+5MZB7/nIklVgpPc1cIqq2j2fEfQ0J7PDxAIaYdxoRQMLaPUv50msI",
    vgs: "U2FsdGVkX19AF7Zn7x0xRg/3vYV+nC27acHusvFvtCZzV11ehdsChRzY4n7LXskfgSIDJtAHQ3JK7RRA1QRK8Q==",
    spl: "U2FsdGVkX1+fc7y9QlUKB2wPPirQOQf2ZXYwBt7KA/dshnUFLgQ9ZWfDk0NNCCDL",
    feud: "U2FsdGVkX19T4L7IThWXkUMhHDaVj5j7iikOMG4koZI=",
  },

  /***  ****/

  // Monday

  {
    day: "260",
    abilitySounds:
      "U2FsdGVkX1/1EFwSQlVkHyx5fWBIQJ288R7sPN4nMFuJf9PsZSpzolgKTaiJk3eG3R6cAteTfuB0yUhnToeZC7CY5y9K4LrAVzhboVdoviE=",
    abilityText:
      "U2FsdGVkX1+sWrXYpe5KwWIIpApmB/A3jhOO12U1wMKGBMh77EwDCs7Os8oILHk9o7PvJsJfnc9Y8vOmyStXlzqHzcHu1vr2+QHhn6KhU2Y=",
    gods: "U2FsdGVkX1/Qny0pRMSa1MkAweTinE7csQWE2rGYfsY=",
    quote: "U2FsdGVkX186ZpwJMQMTcS6dknmWl1JNnlIIST+iXBFqrSS3ya9PvVWYuquAHb0z",
    vgs: "U2FsdGVkX19wz2DzdTG3dQ/PAJBDkNnRtEGBNGXoPVd3BWsPJmbPLPYQq56vNSj/xy9BRiqp1TpCHtcBb/HypQ==",
    spl: "U2FsdGVkX1+Qah0M/gJIEIXpyOPQX2PGaymvLoE94Tqd5ffpmsTP+5die3iD6TvE",
    feud: "",
  },
  // Tuesday
  {
    day: "261",
    abilitySounds:
      "U2FsdGVkX196WGWawAJBGhCo3X3RIsRruSWJrNIptx+ztYFJj59/UX8zr4NWoE17MelPqAw8lwss13xQgo12j6HZ0x7r7VVePm1gIuxQxWw=",
    abilityText:
      "U2FsdGVkX1+aTxdVaOvQYW1533f60PaGB3eYIIr67zzIA/mjoqITZTJXd49aX4ReEcbi58QZ7/aVmlhv0Hh1QWG6aweLr2Uc1pL45AIPBcM=",
    gods: "U2FsdGVkX19q52bnmnGIE37/cK099aqQ9r5jyuhZDic=",
    quote: "U2FsdGVkX1/UvVSCrgUlfRAVsKSqos0ISIqLeQjjM4JNkitvPBYtc2EF+3Ay/yr1",
    vgs: "U2FsdGVkX18WwUGUQOQ1PoJGzeUE8IyoCxtEFjELR+0erdSdTQCGvXDJJZBkTMu/RzN/X6zdFEcgbgOId6Iusg==",
    spl: "U2FsdGVkX1/wNQTeipc+pHJErJgpRxIq7fy6FmL9P6n80v0wUq27E2dPNWPGbUfS",
    feud: "",
  },
  // Wednesday
  {
    day: "262",
    abilitySounds:
      "U2FsdGVkX19s+XSvFoYN3vfmWgO39mGRM0FtyfEO+okPMxoQrXjX+1ehadLy1ypufinz7M/9EqtOYI69uymcI4g8wPv8L6cQAnsGJ/wt6fg=",
    abilityText:
      "U2FsdGVkX19ao5u3NJ34eHS0RzqTyFigrihYTKNGhua8/oXBV+1RSrPMlED3jzAUm1o5c0OrIkIndfiVkY1NEufK6k5TTKvmOI9DyuBYF+w=",
    gods: "U2FsdGVkX1+RyqJKgGJ7RfgTTFkFwR16lsF06A2XgDw=",
    quote: "U2FsdGVkX18BOA2dXbITF7My8whUNpyuLVpBg8LkqJbVufY2kvJvJL7200MDJaeB",
    vgs: "U2FsdGVkX1+bZUZpNJL7H676zOFtwA8SPygTlx9a3l8c8iCLJ63m8jeUkuoy4H0mY35IGZ9DozsgVZpswS8LHw==",
    spl: "U2FsdGVkX1/1hl6BYkt9uqSR0+6Bir9gQz24NvZ26sPmw1515BgYp7Nz70NKGNpT",
    feud: "",
  },
  // Thursday
  {
    day: "263",
    abilitySounds:
      "U2FsdGVkX1+fbRIRFz8U7DzfR4h82poIGx7bYszem7Y5AqN8Hp61uU4t0DZ4H+1Mp4oEUYkjWp+rS/Ow1fgzYqoofZ9XzlNPoz7bV8Qol+4=",
    abilityText:
      "U2FsdGVkX1/yOK+p7r2ms59d4I7MAq0xzmfSOcgBdcBsyZ1Ta52IGHHwzljOz/PYgQSMLCbJme8CkKb3GnBkbM034rQEmt0+vpTc4M36PFE=",
    gods: "U2FsdGVkX18XXZG6F84X4OVK/h9m7X87wH5513QgQgM=",
    quote: "U2FsdGVkX19QXuX3xwPzdn2K6sTBY6jm/FD5kU/H0Pw2GFzhKzrynQ3WSwYzKirr",
    vgs: "U2FsdGVkX1/BlQ7asbbkQtXWkItW/o4q0UZGQsXO14wiYYkdpY4TVKH/RebcWl1+14ixZTxB8kwUtMsPzfNjXw==",
    spl: "U2FsdGVkX19PGSDOz+s9o2nEmNzdT3M5MkdgyeyTp8kTMFGy5AL5eoL6k/D1zaPZ",
    feud: "",
  },
  // Friday
  {
    day: "264",
    abilitySounds:
      "U2FsdGVkX18m/iDrbXtwLZiM6fw2mosoQIxZGh480PjLiUZtP/6R0BlifQzI2F43u3HKDVHj5Fajz3avMVCH5iDcNY23FHUFKBTdW4n6n8Y=",
    abilityText:
      "U2FsdGVkX18oi7H/LtJgmjFFHAcQdyFdvL4ZXue22BNObDfG/ZUe7jpBNiiM7qO16zoKeO4KXyNYRkblrldJttdBfVu4YpLkRTfxnjkw/q/v0KkgIvwU0qBDsC/CsTaG",
    gods: "U2FsdGVkX1816k5lyFkHthyccMnKbhZR41NzHl0F3ro=",
    quote: "U2FsdGVkX1+Jv4ATRYJE/n3J8f9pnjUiJx8XXERKLzqG69ewAe2cQKeooGUL6oGg",
    vgs: "U2FsdGVkX1/4VvwwZyr2nuXL2QQ8CgHqc0jim+SHsANmgBUEyN3BgUEKcL3/q+ESRAyM84eeiLUiIf1nsW7xnw==",
    spl: "U2FsdGVkX19qlYNsW/PrLoQJDtHx/CCmYhSnKcDjak5awjy202DE+Wj9SdfPGfih",
    feud: "",
  },
  // Saturday
  {
    day: "265",
    abilitySounds:
      "U2FsdGVkX19IKpK2Rzhhp7EsEseNdacinkh1PVxR3sdRTtnN719P20WhovX/CrCwKMcIstnIlMcxxIrEEsVodLN6LIWpSitKFR6SFDaX/+EFdW5agP0raelH+br2I3QT",
    abilityText:
      "U2FsdGVkX1+LpUG7SM5pdU869HcyVKQTI/ysRw0GVKJ3/Z8SBZ19IPScExRbx5Am9s0OMy3oVt1fTZ6NDQHbIQhzOITaBtZ7l8uCRCPcJvRDNkJ8mBG65+7V41DIIuAA",
    gods: "U2FsdGVkX1/z3Ka2QKFVyF22JRDzpy0Q6I8RCk6Arog=",
    quote: "U2FsdGVkX1/n13msNJDXDlUh+NkleoSGlaJwcThJWWK0wTGxPtNLH9gALWzwIDq4",
    vgs: "U2FsdGVkX18UXfVBf6JzyaObMKwBL7ysYL2E5lzUKVI6FkjUYOAalVW8dlDthktZw6s5t1n/cE3rLDvXCO8gPA==",
    spl: "U2FsdGVkX19mA9pXJClXJfMbKfhJ9Q5tNEehS/BA8yi15/Y9PvYDIJ6n5ucudK3B",
    feud: "U2FsdGVkX1+msZLETCPVMBOdq4F+WVG4U88DKShZVz0=",
  },
  // Sunday
  {
    day: "266",
    abilitySounds:
      "U2FsdGVkX1/NRLYPbSyZt7CxA6lu08qBQ7VBNKPhaFjNy+g1d4kJYDM20ykyRwBtQlzoSFjT9hankSfOpDTv2/OKcdYS5jM+3McjUcscY+U=",
    abilityText:
      "U2FsdGVkX19cupPOXNvqZN/MrD2RhwUEafSzZppv6q699+ut83h2bDrw/Llxub31wUamr7CHNY3blRuD6rToEi+CAH+Y7jjL/o9P8YaEOAN9iscuwZHSlj1+eI+gawhi",
    gods: "U2FsdGVkX18nsl/MZeRxU5HDoQTqNadhWvyPx4wmMnY=",
    quote: "U2FsdGVkX18MhmQV8qHsKSLzD0TWQg/XANeX0tqTZS/lOA5xNKZnY7/3safejsxt",
    vgs: "U2FsdGVkX1+DzRQiqQw6kcApZVEwE4Iy4b2PCzfsNe+jZhVAgbGzB5t81GqRIqTACnPjEaZezaOx0mVSu8ikyw==",
    spl: "U2FsdGVkX19Rgwtsvs55MRJNgFflRrXfHHnb4nRmTm+G1KNeGnS2CCvVwMQWjWHR",
    feud: "U2FsdGVkX1/WqULMTIVC0Bk7YOGXA/UpVRthQK1uCaI=",
  },

  /***  ****/

  // Monday

  {
    day: "267",
    abilitySounds:
      "U2FsdGVkX1+7Pf5RAZTlAh4JnMT0AQzB/PoIeRcfuqcOf2czDHAyDGI04q9HF/LDKUv+XB78R4o5+WVBe4LryVn2ISFNUbNowjL06mimG68=",
    abilityText:
      "U2FsdGVkX19g6A8sm6q7NFvIUdqCQdeEDS2ry9y5nP6qiQrAy9zgDtRDZA50WZQvmnrP4wEiDyclNDt/1CfEycViXF9d++Nu767XUxHcIU8=",
    gods: "U2FsdGVkX1/N7F1uy4EtN/hJ1+5ixWjJF/Ajkk40bog=",
    quote: "U2FsdGVkX18EPOj8nxCeJ0dpyfrSqD4T5f6ILA7RiiXVgBWYvnSXqCEuNLvOL2v1",
    vgs: "U2FsdGVkX18x11Bec1OU2GnnL10InN9vK1Xaucb7RKQ3KZVaIOixrmZYGQgV3g2uBEFN31UO9aCo9U7dOSqu6w==",
    spl: "U2FsdGVkX18qN9gngprjUyhlzzt7BhrATYGgHqesr+sZjKJjURzh+j0pEa1Ejm9y",
    feud: "",
  },
  // Tuesday
  {
    day: "268",
    abilitySounds:
      "U2FsdGVkX1/gdDm14cv3kuCVa39dckTCK9dHRCLawD6siilJmt/gcahq+MbujAeiFEcc6oieZkZAmvUPer6cpQzehsIfkUrpF7Rm+03Be1M=",
    abilityText:
      "U2FsdGVkX19wSdlJkqoA3N51P9DqvqV600+tkf9MvZcKd9NQgkovJikGu8SWLGnR/76ssA75hdcWnXwmd4AcFRT2CVuP1MGJaBg3gg0o4XLq93NhFRIzLaf3mN/+DPmy",
    gods: "U2FsdGVkX18EOOPtoXxDub4eFi3HexKSMLDVy4TegH0=",
    quote: "U2FsdGVkX1+I4yMLP38ZAOgk1z9u2KPOBJF/u/PL8Z7YIBFbrLSuhIKPGJjP9BmV",
    vgs: "U2FsdGVkX1++lCjTH0AsI29GKJlj8L6FAYjWa2WzUUt4UTTKBlsL17pJvaPwYHpn",
    spl: "U2FsdGVkX18+r7TR1E45OcLvh63D8bVGfIVm8aG4e8j61PkcU3WQNN51wLIFfW8s",
    feud: "",
  },
  // Wednesday
  {
    day: "269",
    abilitySounds:
      "U2FsdGVkX1+gtt7P7V+AKb/5xVojOd23RUCEh08xwTFgaz5sVXhW/gFAfti36CZ2OLVyOwKAhm50SuMRbYf5vMZWT5O1WqnRX/bBeYBvvfk=",
    abilityText:
      "U2FsdGVkX1/slcf2lmqBlaAz9o/hwDd5NVFHvxKlu5L3gerJ28CuiXbV15oZjnK3+hmvuyy0g6PGGyoKsiqtqCwQCEmD4YDkY971WQCK5iP0F1foTUkiXOGERfVoDEVo",
    gods: "U2FsdGVkX1+bTuRr0G5mW6Z+6Eq5hDDWuD0Obu590kw=",
    quote: "U2FsdGVkX19BLvT39B664Pi5l0WzRX+mWVEBN1F9vfq3KgPKrSaqx2O8p6/mqCPS",
    vgs: "U2FsdGVkX1+1fQqg7KMDSwo/d/SqS+eaExUsDPmUBI9+jK7KP4lhqj59TJOsF2eO26qpblw/NCTpjVZTOA1Nhg==",
    spl: "U2FsdGVkX1+ZWhOlzwm3mtRf0EQLnRj+Prm4KWrjkJH+y8QwYAqR9k0fRtqQxn2L",
    feud: "",
  },
  // Thursday
  {
    day: "270",
    abilitySounds:
      "U2FsdGVkX18LsgLV1hxHU0ug3oy1EubCjrXFk36a3spg12JUwa8k0hkHmDdm1dkagUaQnvG2LXIVSP0RFXGBSFbYGfYJ35vuj/8XDpUO9eQ=",
    abilityText:
      "U2FsdGVkX1+VmogEWwdbNQeYnw8nAQpOxs7y0rRQLjyEpUxJnujpG4Cm5R9/VFec3sVgTBpkdan8gZ09cN9nJuiImCdFPDU86VXm4buYPBw=",
    gods: "U2FsdGVkX18TNzoi2P0F8WmN3SiECojzs7o1+vJZ6eI=",
    quote: "U2FsdGVkX18chr3dF19egNvCnt6PS2NP4tzryyYb5xngfy2Xf8JgOiRMGY7tglfx",
    vgs: "U2FsdGVkX18JiqeOfHVFqbCQNv0Bb5RnE+Kq/ZretU0//Gb7UjexSdLq92yChvkN5izOCfu3UgS3M2F70nSB2g==",
    spl: "U2FsdGVkX19vnWpIw/BeINdJU58L18NQz97WumjUZsriZ+BAYi2gLLLZiZmpotkG",
    feud: "",
  },
  // Friday
  {
    day: "271",
    abilitySounds:
      "U2FsdGVkX1/3xyNGlQEt0ljwfHXfKFIosIHVlHmypAr/Zb0Z0oFEFjByy9Yw9y85LiFcnvzbyn6om25zGI7BQfei2e0YKIYRsPdfVT65Y5c=",
    abilityText:
      "U2FsdGVkX1/mZ39e/MUVRt5JTiEmoOM2CaQw0l+mgkrJeWkW88L/jIMMBOX9kk3prRxCM87MAjrUXtyNcHW6GEL5PI1O197xDlkT/kXqZME=",
    gods: "U2FsdGVkX1+fTRfm28O2zBCZ4l/8t/lwJz071PdjDhw=",
    quote: "U2FsdGVkX18mUhxrN2hI777L5WhJsV9QqpVF995g+nUg23qon8XknSbHzwtI64Xb",
    vgs: "U2FsdGVkX1+LQshLV2GRnAvKYe4CntKLl/bpWQmcvy3I0mQE8BGp12vfimijDfg61RhJ48LE3KViEWD5x/YtUQ==",
    spl: "U2FsdGVkX19uQ9nJm6+S9ySiAVs6ZpiKxywBCVLdiOF8BUJwl/B71bouiYhdMzce",
    feud: "",
  },
  // Saturday
  {
    day: "272",
    abilitySounds:
      "U2FsdGVkX18+1owKb9Ul5EL778nmimpNrPyft2D9c18FUL047oze9EhHsolSgvCx4ZM4k6kT4oHi/P8ZpzKyjrRMp+pgp1SAzS0mI8fngio=",
    abilityText:
      "U2FsdGVkX194UVkm96NqKl6JsLVNQRebEHI+aC3RDKOsqoEMb+9kMp3fy7eW2MQlgkMF8KpSzLFNCTWX+zuwU1EamNhPXEA9kH1YIoZI4ec=",
    gods: "U2FsdGVkX19wvDCPt2Eirqf3PicnuEC8FV2+J1i261w=",
    quote: "U2FsdGVkX189i4h0+AwMNOkHrKLhP6/ZWVBqdoQVmRWhAbFO8EiiQD5HSWOrApDU",
    vgs: "U2FsdGVkX18J7lQ5zvF1RMotqY4qJ7310RMB1uWizuOYKLIZ9FHyzFKzI3uq/Z/32t/t9Ayzsyg0tUyqcdWFjw==",
    spl: "U2FsdGVkX19p8eSbKiudPRn/L0514fxoOYYg72HjfxBrAtaFGhQ3NTj+va3NN/wx",
    feud: "U2FsdGVkX19vVULyTECmihdufkVWpTlFroIRMJItgek=",
  },
  // Sunday
  {
    day: "273",
    abilitySounds:
      "U2FsdGVkX1+3Lk907C2Y9m7RDIyVw8oZ9kpI7bJR/mv31p9xzyoSkMASI+xHnKasHlsFyRmEzijC5OtacUzjDPdtfabwyqBZ8saFOjHkwHA=",
    abilityText:
      "U2FsdGVkX1/z0BShcgc6mYTnGSaWzl17bCBsjKmQ5NsXD8h7W7WYgLzvaf7K5GI7yuv6/YK1U0bsJomwNawky6Hi5sLoh1zXXW/TgMtbU3s=",
    gods: "U2FsdGVkX18BudcWFlJUQoTijjC5Km0FRdRlvwRUFeI=",
    quote: "U2FsdGVkX19TJLEJb00/ddnRQfwkf6fUsNPM1MpFrJp5jan2Tqi+rWUaWwgn2BHp",
    vgs: "U2FsdGVkX18fEyVSOJeOuQX7Jw6RqZSWFJTTZa//8F7ZzzbuU+KJ4EjXzRdtXzEtid21OqMtmuk58wpQKNfY6g==",
    spl: "U2FsdGVkX18x9bHJ/aakBzeiiwZg+T3Nq1M8pSKSE+C3QQxb44ozJcLpldWhGPvh",
    feud: "U2FsdGVkX1+uLeYu6I7sy+9II/lqF6fmZe61DgG9IQI=",
  },

  /***  ****/

  // Monday

  {
    day: "274",
    abilitySounds:
      "U2FsdGVkX19Cjpmqfo7XHsah7c6AkDgqNO562s1qK2KAQk7obbL0gZub6Zpw3S2R6rT5laMr168wd/ZhxGl2jMOygBmUoMYXBKhsHnarYvI=",
    abilityText:
      "U2FsdGVkX18kMhsiyjqqHBNt6VTqC1CaQPerZ064f/w3y+7prldgSLWM+zYIvk1UU4Rgcg+0MdYxaYZy26+WD+7hM7568pbAtGAjWXF+5nDgPhEjUsL5TpjzjgdefWGQ",
    gods: "U2FsdGVkX19FONdSfYrvF8dtpfEM3hpU6Jx4VEj70JY=",
    quote: "U2FsdGVkX19/09ZKp4WEekuK6yG2W/15kP1VzDpksSQ0qGjD6QCQg47EptbAB3du",
    vgs: "U2FsdGVkX1/CVGwxmd8I4Ae+XHUtOa5obx5lLBFFK0w8qkAcqoA+qh+Frs1ll7zD",
    spl: "U2FsdGVkX1+4ydc2HhNl3DAj69cv1dnidWdyDdBR5ASr5Yoc2Ee8OElQfjvyV2NW",
    feud: "",
  },
  // Tuesday
  {
    day: "275",
    abilitySounds:
      "U2FsdGVkX1/SmDkoDh+vY+xA4Ax7kYE2Tztl7+0qevVO4HTiL4gCydYoUB0wo89tvHc7vjmDdhb+M2JtQpooyXEoLilXt7DY9ZazL2e80iQ=",
    abilityText:
      "U2FsdGVkX1+UwcRuXctsNq20hZ652NkBBnJl4UhPIZxsXvkCNM30BqMvfHnhq+RwvZhc5SBXgdD5wRMDU80M3EMYCM5UJoo1wmrDOuIIToI=",
    gods: "U2FsdGVkX1+eFoZiKTIQssDpEt/DT6VoKsHR879Tylw=",
    quote: "U2FsdGVkX1+EhSHESizGFk4pgkVsN+A7Unzx7HsPJpTw5UNCMi3RXhWtkcKkvoHX",
    vgs: "U2FsdGVkX18WT8kSn1y4QVdlAXU5LpqwBBS6HfYwjPk17MqcyJM4b9H+RTlcfOY6Ue7rMoCIbWOgFvoJFVzvVw==",
    spl: "U2FsdGVkX18hpPG1LuLMPBrPqyqXuiMaGSzN9omMLSrEqfsVCpnbXMAX+kjIV9ND",
    feud: "",
  },
  // Wednesday
  {
    day: "276",
    abilitySounds:
      "U2FsdGVkX1/xPe5YuHW0CGgHP6qutc9vZKMSNld6ei5JvMwMTrRjfqr42UCvoipqQh5pTuOXz7IT23kCmHvdIuaqI8rgwiOmPkKulAf1HAE=",
    abilityText:
      "U2FsdGVkX1+rNTFIZ+ePY+0vBHCEVQ0m7oz5faWd7g6vyyAHsyFz83rB7QCcq2t5tVPUgK5mZISrJRDImqHmc2eb/xwNV5bLA/cwnkueQ8I=",
    gods: "U2FsdGVkX18j60tYCJ2i7Pu9fKF8f2zm8CyUZphA62U=",
    quote: "U2FsdGVkX19kROaNlQ2MidD62I4bYpAgaeozzkb8NHLySwHkE4u9YY27TCQjzbie",
    vgs: "U2FsdGVkX197XidqSJuUeULF6h2/lacHlbJZwPmEsryx9Pp0kU/KwEzEczB3LOEzkFBz/HYiYolMrcAL0sKr/w==",
    spl: "U2FsdGVkX1/vO5xgNiWr0uTrBTHR8Z5CPdXQAcUE+x8dUueD7R2sIafKPG/dZ9dU",
    feud: "",
  },
  // Thursday
  {
    day: "277",
    abilitySounds:
      "U2FsdGVkX19t55g/QYGsP9+V55LM9FEBME3UhYeVcK2BnCGk2x9HcupSkXajvq216T8JYksqcYQxRKUZIKlzV1CWjkwXqa3kVRBSKzmRdIXv2Fbf2lq+Cn3n3W4JbyAB",
    abilityText:
      "U2FsdGVkX1+fo494g4g97W/fm9e6J1AhNRY0XdHYa8C9ZEgU1l0LhXnJAVmtOVT3LFr0ObvuSkW1NrOF4ODDhcb72lezwG6OSq+dwsvpecw=",
    gods: "U2FsdGVkX18oLSzhzn4joSnN0/m2kDCYPpl0nO9r+As=",
    quote: "U2FsdGVkX1/WRqIDguCQDMyPYu43jtSXf3XjG/Gu00ogrZcVTLiOQphEFdUD2Mk2",
    vgs: "U2FsdGVkX1/tEMIFwibFnXpccYXrbKXHhhO6HDEoEsVew8iQaJLJuY2ETeebyXcWJ9I4K33xPsLxgx2p45NbSA==",
    spl: "U2FsdGVkX19FQe4qGzNhLxegjogeFwN4hi6RgZm+xgb+E0UeoZoFGWe9TYUMuvhC",
    feud: "",
  },
  // Friday
  {
    day: "278",
    abilitySounds:
      "U2FsdGVkX1+MPQfuJ+NUpF7d55mjmVA5fs2M71iqR/1851mH60dX7R13fwabmucs2UMIoCfxr+XniLcy5WCVCkyaGFot7mpvuBHWKK7vSlgbrA+ZNUDjXFQaqB6V8fon",
    abilityText:
      "U2FsdGVkX18ayhjD9JUNotFL0StiL1wx91GflP69Lu1s3+7sLch3MutmBQJudnjINo/lf2oZxB9wEwlb+MrPYX/9O0CiM1wCHJCDE0kgq8Y=",
    gods: "U2FsdGVkX1/GnIMAyuWNXKva+cRSIum5WXPuhe5Wh5w=",
    quote: "U2FsdGVkX19R19aQaAUOlIvXa7oD8WzOKPnay3D7oazYX5UWgwH/oCcRIdWTtfao",
    vgs: "U2FsdGVkX18GCtF6YjR3aXuCMKniGAUPIuUSBwnmxm0kcmaCk6B22BuW4SpK8VZh71376IwtjYzT6Nmfjw247A==",
    spl: "U2FsdGVkX1/vycf3HZFchlepFUOjDrKEBBvGrXZkEBwgfb5A2D5IIQG7U1nLONt7",
    feud: "",
  },
  // Saturday
  {
    day: "279",
    abilitySounds:
      "U2FsdGVkX1+uXC6rpd+9IYjALNeIGLbvYug35a7+jiLodqSv65gl/iU2fVhFTmuHbwEMGh8PPE0vWat4n1lqjP/Mah0eabNgfToM/kji2yw=",
    abilityText:
      "U2FsdGVkX1+Az+elyfrhLosHFINWTnvpn/WVVn01NcBx1hBhnGKbd5wAkmJVI4uxWOS8B18Gbz8Mi0w7gUDGbBQfkmPpqQGZAeWrdze3cXo=",
    gods: "U2FsdGVkX19IswZuc1cfYKEM8mFD+aL4dVvLKxqLo9w=",
    quote: "U2FsdGVkX18YG/PhVegtuuyRyrZzD5I8ZgnBmFk+Jw5TaUjTtqYyfBsvIkM7u2FF",
    vgs: "U2FsdGVkX18vE+6UpmwbTKFHmIU1pdKM5xiddHFTYUx68tEHbJ/QlA9RIWzS76enJYrh9B0NMeFpXRiNGCYCKQ==",
    spl: "U2FsdGVkX18RWIx3rP3b0r8tBNKZgvyx92PWZmTD1OU9tj/8l/j2CkjGCnlBcDGs",
    feud: "U2FsdGVkX1+MMF3qnJzfh0eUrgOvQMWlCuteA6+dTg8=",
  },
  // Sunday
  {
    day: "280",
    abilitySounds:
      "U2FsdGVkX1+4VtCxy38f5sAUxNK4QZ1LSReMsPxd5dB97KVBX6nEAZtrU8d2JNvF448FISct7KvQvWdOZz6s1JkhsMqmManezzqfWRIuGPI=",
    abilityText:
      "U2FsdGVkX1+Q4QA83Zn3+DfmDx2WvBuUYioyALf/laXaCuvoV4QY4wpPQGBibS2CQBc+4q5LoGMStauMwCGFXACfofvmkyzoq4M/Ndmdjd4=",
    gods: "U2FsdGVkX18ReiuxPXBPM00OuQOel1FKkrQQMHZ2J3k=",
    quote: "U2FsdGVkX180SEVd9mpiOD+HrdVAS34uJUn9sGgAHumfMUVXQ6OJCp/U5o5KSYDf",
    vgs: "U2FsdGVkX18WjyV32rH3arHBqaywVCyDJEi7Gu646MOHZwKNo0yxgI38i/Ve5omjzZ8CVDHL4mbgzfNMMAkwcQ==",
    spl: "U2FsdGVkX19XdM2iwP1DjBFXtSCkHw6ffHfYLS4hkQz34PHWdG0I80AQZDs8suLR",
    feud: "U2FsdGVkX1+1mz/XfoKEKZTcuMKMCwgVhUOwYdwkjUQ=",
  },

  /***  ****/

  // Monday

  {
    day: "281",
    abilitySounds:
      "U2FsdGVkX184dcMugPzliLRQHcuLGsKCZnrpzn32NRdgvNdjKM4fk5qqOr+R5Yed+DRJ6NtaYoxy7vvsWWJ8EPF+EhZo8Teji2Jm6j2657E=",
    abilityText:
      "U2FsdGVkX1+2P2/ceSorcO/VlaadHYdudwj2DnlnJ7l8cI/IY/+Ot6mX552aBSTEEYgWfZFdyWvJEBJGmYpZyq9BNKrnVQXrtIbr1FFfqgIFgOYsQhC6FOCodhz7eMsz",
    gods: "U2FsdGVkX1/NO9sgeToS4zn7USZDnlMBiUSUQ2ZkYcE=",
    quote: "U2FsdGVkX187BSzSCJdFlB8XPaPJN/1ms4BUA2PYmF06QRx3gaHBzh/o7WD+uQ2q",
    vgs: "U2FsdGVkX18qgmZzf+QGZW+FxbhjQ2FGXP35HW6Dyzk/saYG3NdjHu3324z52TN/",
    spl: "U2FsdGVkX19fHs7is8nmi2UtGMcLwwwEDMd7qc1xuVyOq4HcNe3Xi1UPwyRmnMnb",
    feud: "",
  },
  // Tuesday
  {
    day: "282",
    abilitySounds:
      "U2FsdGVkX18mcjYmJFzbAgoBoUxQpitbl4LCDLqMu0LI/pNlLvFfFv+cBsMC4BzINb+GUEZrIvbGSTQYcaN2ETtBAjLZSXremuju81CXxEY=",
    abilityText:
      "U2FsdGVkX18QWsNGywo3B3dphA1sWPbfEuarSLag/1dYRYEU5iZfXX2HpVL43RNmd/ZqQBu7BDmvLCLk7KFtevbG8NdnJEFG9AggU4FcEuM=",
    gods: "U2FsdGVkX19H4Nys2nFARuFLUYwohlM9GaL+CxPmwyg=",
    quote: "U2FsdGVkX1/GgqzI0BAtLC8RhSVwcqWso7M4BUt9bS4erJEtXTf9xm49WF0oLzyn",
    vgs: "U2FsdGVkX1+35GAgC7VFVNpJT5ae/tEYY3J7AlKXFpmY5o55mczDp9ppM5AMW66Y",
    spl: "U2FsdGVkX1/tDM1JFT6te3G2WN3W9tYbrbAQw4YV0wkYH0sOgXsdDZnPtKjSpPOY",
    feud: "",
  },
  // Wednesday
  {
    day: "283",
    abilitySounds:
      "U2FsdGVkX1+96eVfrvceB6nfzYHFFfhU8D3LorPkpREDLpJeb07kHSckL+pd6wRIXXvbuDV84sV3TxGFBGH35yx2JWqlRYpjxr539ir4wQM=",
    abilityText:
      "U2FsdGVkX1/UYdKC1DZB5tjqJmu0nq15EziKWZ0DxIn00H08HhhAGE51fQtOJ1JFbQkTTwR3f+BB4aGlb2Pq60j6FExtSU2yuzwqWH3vt1k=",
    gods: "U2FsdGVkX19ZFb4eKnnsC76w7KEs+TZbiQGkqDPwF6A=",
    quote: "U2FsdGVkX19sPC0AZ7WN0zQTyEdgGzd9BNp45B0uBRVgxaOMnua9xpIIuJsMDjos",
    vgs: "U2FsdGVkX19eNChiFdIyF/dLv4k68lZxwY6RxSGXzBZ167gwmnz8uTk8NagxntpRbV3HObLghwrPDrHkT+LLbg==",
    spl: "U2FsdGVkX1/yTAwcQvn3s0RswYz89DcZoV7orK7g4rpntVzHbsq9lnbkIWp7bh4X",
    feud: "",
  },
  // Thursday
  {
    day: "284",
    abilitySounds:
      "U2FsdGVkX19rqofW0j9HMMCx4CJ7/QNeOKEjeu9PAvOvhVHTondilD07Ger++mf8FyMjusCnD5b1aESDHjSWEQN8YqLJRtZVkzUBtG2jhUc=",
    abilityText:
      "U2FsdGVkX1+QkwAsyfdU0APaQEFfsLCZ7YL7l0HUSlCtIV/aLS6fQzrES2wEW8bbYoIyhuNaONXAlxfg0+jGq6iAQxTWReXJ5Fh5gMfvApp/rbwPMGLFTbBxEXrrTf04",
    gods: "U2FsdGVkX19d6NIuqP/0cmEdsAbc3Oq+yLU2N74U/Q0=",
    quote: "U2FsdGVkX1+walTZIGsbKu3zcueJ5DbGGtDqrKF38w9wbeQN+/p9G6ZDrYD2EBYU",
    vgs: "U2FsdGVkX18h5rQx7ggKd+fMWdUowaU9yA1PmGxgiNuhDkncmsvVI44xcNh72WWe9vYDETbdyAlGsmXfx4bqzw==",
    spl: "U2FsdGVkX18k1Kjp/4kk339cIGamCt9AIgB96J6t/M4dncH+zBoq+j9oR1x5XDg5",
    feud: "",
  },
  // Friday
  {
    day: "285",
    abilitySounds:
      "U2FsdGVkX1+IOs9mRpKTYbsOzyDAeV9e+i0O0xPPhFIV2AT5Llthj3e68hshlWSVKsnlGXixBOBnY1rOG6qAWFUCL5I1JWCWcP6J5+DGyKE=",
    abilityText:
      "U2FsdGVkX1+eGBJukCW+0k28HhJg4QKweB/bFtWDykT8T7NzRYxXug6OzQbqUQk/8WJbozG+z0MrsFg/1mZp9AOJDI+ClkekdpUS28XiZTw=",
    gods: "U2FsdGVkX184y2mvfo0tMr6tS7Iulv3Bve+DXqdRGLc=",
    quote: "U2FsdGVkX19b6YlqPlhzGxqS2Rfs/2Lm+/o7KNaaj7nVQDQoHzOLQ3yFmpd+Ls9P",
    vgs: "U2FsdGVkX19dNHZZ8n/0pHhRzW45wp3JBEGPqfHmHIG0shS6AQz2AxFpbk4N0Gvv",
    spl: "U2FsdGVkX187llfx7Jlq5pj6vTtKRcMpIOXpMBsCRNs=",
    feud: "",
  },
  // Saturday
  {
    day: "286",
    abilitySounds:
      "U2FsdGVkX18sVS1HgLDBKmSuMJ8t+0v1L6YO8FDmttLf6GFN5PK7ZLZSDiMkSUGYBp0uBsaoPPTepv4L2IL4jbvlPhcSeAaNOqFcg6uS5iI=",
    abilityText:
      "U2FsdGVkX18Ha7Mouau41Wt8+v11Uk28bfyl5WE+uQD7KEEQzyNBMJSGHxuMGhudBMTpFxB/t+pt36vnVqSFlLRSRYsdV5vMPjZ78vyHEOA=",
    gods: "U2FsdGVkX1+Z5FmK7Ck4xWMPsa5otyDN7io8iW9hldU=",
    quote: "U2FsdGVkX18GcVYwLvCnZDJKvu2yNdCTUG2Le1x4Wlf4Be/meLz7ad9l02QpSKj/",
    vgs: "U2FsdGVkX1+6CNS5t9gcYsG/xiZv9v1CxAyZUyR63OkQmW7Ma+74aAqGo3q15vGFzIpwhMPHGyPEc7pgNDCvow==",
    spl: "U2FsdGVkX1+nGysXxTzuaYR4O/NFJALiNhEwyW+Si6c=",
    feud: "U2FsdGVkX1/CruV5KmVe0OgC0e++rkiYYM4GGrqswIc=",
  },
  // Sunday
  {
    day: "287",
    abilitySounds:
      "U2FsdGVkX18SQKE2nLkmkNcYtmZzATDdlFut3GPgLFaWRW2ktaQ2GZMsUM2Q3sYcgOHFrWPLwfxPvkbCz5Zq7U9xxW9oLsC0pSGXLghgJxs=",
    abilityText:
      "U2FsdGVkX1/RDOyjJrW0ohehg4xdt7JUwsHC0G2yWJLkXD+dCr2pWoXhiogb4LcEr2sT90raytCTwuZkwQtqw577tZipatCB2KUlQe/axnY=",
    gods: "U2FsdGVkX186DLvxQROHu+tnj7b8pigWAWzDUwhpk14=",
    quote: "U2FsdGVkX1+WC9zwNeKmTk2GMsSSizeCfcBKv6K4nxGME/OFzBfiGkVLtXcy7VZA",
    vgs: "U2FsdGVkX1/Tp/VfmkuV5q18NWgTbMuF9ETvIFMyAziMAZ58ZEPDiKNa/6Cn0AM4c6wh6/CpAWoIBVyVY2Da1A==",
    spl: "U2FsdGVkX1+Vj6ttFZcRcjfh+K1wPE3luge3a4ixyF3zrbYnYnmXTx0k/pmnKNdo",
    feud: "U2FsdGVkX19Q0q09MAeyuoHhvyyOm9m40aDWNhbhJHY=",
  },

  /***  ****/

  // Monday

  {
    day: "288",
    abilitySounds:
      "U2FsdGVkX18lTpoLdRhnZsJRP/wOBQ4I9zRkuZ6eht/6he7N36GIRfbogKUr9oJQY2RGlk5B5g4OtHy4DusYnJc3zonz4VOk/5+en8HUXA4=",
    abilityText:
      "U2FsdGVkX1+BQBHRTey8yXGXybm/s5Z153ZfztWI/Jf21ENnrLzHqbQ4R/nQw/jKNBnB3H57JJIvCVNIQvAw1/kwPdIckxd7kKmAkcJ6bKYaZSIROd0JKoNWBqN6UbwQ",
    gods: "U2FsdGVkX1+y9f7gWecyWEPiIhe8S6ueFSiTqmq5md8=",
    quote: "U2FsdGVkX1/4nNhylBRa0GBe8rAaZcxQBw4UGrO2mRyNBeL5fiWWCn8maHxBy1Np",
    vgs: "U2FsdGVkX18wWW8FE0egfRX80LWZuMvkECEDaiwoFqTNS1FJ2HZaLFRu95CsPvH64DM6i12ZXWe+iD/bj4Y1RQ==",
    spl: "U2FsdGVkX190QP/L4kdN2mPZ+2sf5DfS4jEnPYu0FICUG0MrpPWz46SBV92JPLAB",
    feud: "",
  },
];

export { allData };
